import { Circle } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { MutableRefObject } from 'react';
import { TFunction } from 'translations/hook';

import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
  formatRatioToPercentage,
} from 'technical/currency/formatters';

import { AggregatedValuesType } from './types';

interface Props {
  totalAmount: number;
  t: TFunction;
  colorRange: string[];
  apiRef: MutableRefObject<GridApiPremium>;
}

export function getCommitmentSynthesisColumnDefinition({
  totalAmount,
  t,
  apiRef,
  colorRange,
}: Props): GridColDef<AggregatedValuesType>[] {
  return [
    {
      field: 'fund',
      flex: 1,
      headerName: t('pages.commitment.table.fundName'),
      renderCell: ({ row: { fund } }) => {
        return (
          <Stack direction={'row'} spacing={'1rem'}>
            <Circle
              sx={{
                color: `${
                  colorRange[
                    apiRef.current.getRowIndexRelativeToVisibleRows(fund)
                  ]
                }`,
              }}
              fontSize="small"
            />
            <span>{fund}</span>
          </Stack>
        );
      },
    },
    {
      field: 'amount',
      flex: 1,
      headerName: t('pages.commitment.synthesis.table.amount'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row: { amount } }) => {
        return formatToMonetaryAmount(convertFrom10X8toNumber(amount));
      },
    },
    {
      field: 'percent',
      flex: 1,
      headerName: t('pages.commitment.synthesis.table.percent'),
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row: { amount } }) => {
        return formatRatioToPercentage(amount / totalAmount, { precision: 2 });
      },
    },
  ];
}
