import { isDefined } from 'technical/is-defined';
import { removeDuplicates } from 'technical/remove-duplicates';

import {
  LpCommitmentAndOperations,
  LpSubscriptionsFilteredFields,
} from './types';

export function getLpSubscriptionCustomFilters(
  data: LpCommitmentAndOperations[],
): LpSubscriptionsFilteredFields {
  if (!data) {
    return {
      fundNames: [],
      shareNames: [],
      status: [],
    };
  }

  const mappedLps = data
    .map((commitment) => {
      if (commitment?.fundName) {
        return { lpName: commitment.fundName };
      }
      return null;
    })
    .filter(isDefined);

  const mappedShares = data
    .map((commitment) => {
      if (commitment.shareName) {
        return { shareName: commitment.shareName };
      }
      return null;
    })
    .filter(isDefined);

  const mappedStatus = data
    .map((commitment) => {
      if (commitment.status) {
        return { status: commitment.status };
      }
      return null;
    })
    .filter(isDefined);

  return {
    fundNames: removeDuplicates(mappedLps, 'lpName'),
    shareNames: removeDuplicates(mappedShares, 'shareName'),
    status: removeDuplicates(mappedStatus, 'status'),
  };
}
