import { Navigate } from 'react-router-dom';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useAppContext } from 'business/providers/app-provider';
import { hasRole } from 'business/user/helpers/roles';
import { Roles } from 'business/user/types/user';
import ReportingNoMatch from 'technical/router/switch/reporting-no-match';

export const Home = () => {
  const { user } = useAppContext();
  const isLpPlatform = useIsLpPlatform();

  if (user === undefined) {
    return <ReportingNoMatch />;
  }

  if (hasRole(user.roles, Roles.FUND_MANAGER)) {
    return <Navigate to={FundManagerRoutes.Home} />;
  }

  if (isLpPlatform) {
    return <Navigate to={LpPlatformRoutes.Home} />;
  }

  return <ReportingNoMatch />;
};
