import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { LpFormProvider } from 'business/shared/lp-creation-module/components/lp-form-provider/lp-form-provider';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { CustomAnswers } from 'business/shared/lp-creation-module/services/types';
import { customAnswersSchema } from 'business/shared/lp-creation-module/services/validation';
import { useUpsertLpInvestorProfileMutation } from 'generated/graphql';

export const LpCreationInvestorProfile = () => {
  const { lpId } = useParams();

  const { onNextStep: onCompleted } = useDraftEditionStepsContext();

  const [upsertLpInvestorProfile, { loading: saving }] =
    useUpsertLpInvestorProfileMutation({
      onCompleted,
    });

  const methods = useForm<CustomAnswers>({
    resolver: yupResolver<CustomAnswers>(customAnswersSchema),
  });

  if (!lpId) {
    return null;
  }

  const onSubmit = async ({ customAnswers }: CustomAnswers) =>
    upsertLpInvestorProfile({
      variables: {
        input: {
          lpId,
          customAnswers: customAnswers ?? [],
        },
      },
    });

  return (
    <DraftEditionContainer
      onSubmit={!saving ? methods.handleSubmit(onSubmit) : undefined}
    >
      <LpFormProvider<CustomAnswers> methods={methods} lpId={lpId} />
    </DraftEditionContainer>
  );
};
