import { Navigate, useParams } from 'react-router';

import { LegalEntityKYB } from 'business/fund-manager/lp/components/legal-entity-kyb';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { ClientTypeEnum, useLpViewQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpKYB = () => {
  const { lpId = '' } = useParams();
  const { data, loading, error } = useLpViewQuery({ variables: { lpId } });

  if (loading || error || !data?.lpView?.[0].client) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const clientType = data.lpView[0].client;

  if (clientType === ClientTypeEnum.Individual) {
    return (
      <Navigate to={FundManagerRoutes.LpIdEditProfile.replace(':lpId', lpId)} />
    );
  }

  return <LegalEntityKYB lpId={lpId} />;
};
