import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { CompanyInstrumentProceedForm } from 'business/fund-manager/portfolio/valuation/components/instrument-valuation-proceed-form';
import { getValuationProceedColumnDefinition } from 'business/fund-manager/portfolio/valuation/services/get-valuation-procced-column-definition';
import {
  InstrumentProceedsQuery,
  useInstrumentProceedsQuery,
} from 'generated/graphql';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import { MainSecondaryItem } from 'ui/main-secondary-item';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SimpleTable } from 'ui/simple-table/simple-table';

interface Props {
  companyInstrumentId: string;
}

export const CompanyInstrumentProceed = ({ companyInstrumentId }: Props) => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useInstrumentProceedsQuery({
    variables: {
      input: {
        companyInstrumentId,
      },
    },
  });

  if (loading || error || !data?.instrumentProceeds) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const headers = getValuationProceedColumnDefinition(t);

  const instrumentValuationTotalAmount =
    data.instrumentProceeds.proceeds.reduce(
      (acc, proceed) => acc + proceed.amount,
      0,
    );

  const onFormCompleted = () => {
    refetch();
  };

  const valuationCreationDate =
    data.instrumentProceeds.companyInstrumentValuations[0]?.valuation?.date;
  const valuationLastDate =
    data.instrumentProceeds.companyInstrumentValuations[1]?.valuation?.date;

  return (
    <Stack>
      <Stack padding={2} spacing={3}>
        <Stack justifyContent="center" direction="row" padding={2}>
          <MainSecondaryItem
            main={t(
              'pages.fundManager.portfolio.valuationInstrument.labels.total_amount',
            )}
            mainVariant="overline"
            secondaryVariant="h4"
            secondaryColor="primary"
            secondary={formatToMonetaryAmount(instrumentValuationTotalAmount)}
          />
        </Stack>
        <CompanyInstrumentProceedForm
          companyInstrumentId={companyInstrumentId}
          onCompleted={onFormCompleted}
          valuationCreationDate={valuationCreationDate}
          valuationLastDate={valuationLastDate ? valuationLastDate : undefined}
        />
        <Stack padding={3}>
          <SimpleTable<
            InstrumentProceedsQuery['instrumentProceeds']['proceeds'][number]
          >
            rowData={data ? data.instrumentProceeds.proceeds : []}
            columnDefs={headers}
            loading={loading}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
