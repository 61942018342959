import { ApolloError } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { AddressFormPart } from 'business/shared/components/address-form-part';
import { LpGeneralInformationForm } from 'business/shared/lp-creation-module/components/lp-general-information-form';
import { LpLegalEntityLogoFormPart } from 'business/shared/lp-creation-module/components/lp-legal-entity-logo-form-part';
import { useGeneralProfileAutocomplete } from 'business/shared/lp-creation-module/services/hooks/use-general-profile-autocomplete';
import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import { ClientTypeEnum } from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { FormSection } from 'ui/form';
import { Typo } from 'ui/typo';

type LpProfileFormActionProps = {
  action?: ReactNode;
  disabled?: boolean;
  onClick: () => void;
  autocompleteLoading?: boolean;
};

const LpProfileFormAction = ({
  action,
  disabled,
  onClick,
  autocompleteLoading,
}: LpProfileFormActionProps) => {
  const { t } = useTranslation();
  if (action) {
    return <>{action}</>;
  }

  return (
    <Stack alignItems="center" spacing={1} direction="row" justifyContent="end">
      <ActionButton
        size="small"
        variant={'primary'}
        disabled={disabled}
        onClick={onClick}
        loading={autocompleteLoading}
      >
        {t('common.autocomplete')}
      </ActionButton>
      <Typo color="neutral-light">
        <Tooltip title={t('common.autocomplete-tooltip')}>
          <InfoOutlined fontSize="inherit" />
        </Tooltip>
      </Typo>
    </Stack>
  );
};

type LpProfileFormProps = {
  error?: ApolloError;
  saving: boolean;
  isUpdate?: boolean;
  action?: ReactNode;
};

export const LpProfileForm = ({
  error,
  saving,
  isUpdate,
  action,
}: LpProfileFormProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<LpProfile>();

  const {
    fetchAndSetAutocompleteResult,
    disabled,
    loading: autocompleteLoading,
    error: autocompleteError,
  } = useGeneralProfileAutocomplete({
    setValue,
  });

  const { field: client } = useController({
    control,
    name: 'client',
  });

  const { field: logoFilePath } = useController({
    control,
    name: 'logoFilePath',
  });

  const { field: existingLogoId } = useController({
    control,
    name: 'logoDocumentId',
  });

  const errorLabel =
    autocompleteError ?? error?.message ?? ValidationErrors.GENERIC;

  return (
    <>
      {error || autocompleteError ? <ErrorLabel label={errorLabel} /> : null}

      <LpGeneralInformationForm
        control={control}
        saving={saving}
        isUpdate={isUpdate}
        action={
          action || client.value === ClientTypeEnum.LegalEntity ? (
            <LpProfileFormAction
              action={action}
              disabled={disabled || saving}
              onClick={fetchAndSetAutocompleteResult}
              autocompleteLoading={autocompleteLoading}
            />
          ) : undefined
        }
      />

      {client.value === ClientTypeEnum.LegalEntity ? (
        <>
          <FormSection
            title={t('pages.fundManager.lpCreation.form.address.title')}
          >
            <AddressFormPart
              name="address"
              control={control}
              required
              hiddenCountry
            />
          </FormSection>
          {/* at the moment the logo upload is only for the legal entity  */}
          <LpLegalEntityLogoFormPart
            existingLogoId={existingLogoId.value}
            onExisingLogoRemove={() => existingLogoId.onChange('')}
            onFilePathUpdate={(filePath: string) =>
              logoFilePath.onChange(filePath)
            }
          />
        </>
      ) : null}
    </>
  );
};
