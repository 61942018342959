import { format } from 'date-fns';

import {
  LockedEmailError,
  WrongCredentialsError,
} from 'technical/auth/providers';
import logger from 'technical/logger';

const NO_ERROR_MESSAGE = 'Error is not an instance of error';
const NO_ERROR = new Error('not_an_error');

export interface ErrorInfo {
  context: string;
  variables?: { [key: string]: unknown };
}

/**
 * @name getAuthErrorContextFromAuthProviderError
 * @description Extract error message from param if it's an instance of
 * Error or report the inconsistancy and returns `unknown`
 *
 * @param {unknown} error Error catched for the authProvider context
 * @returns the error message if available or `unknown`
 */
export function getAuthErrorContextFromAuthProviderError(error: unknown) {
  if (error instanceof LockedEmailError) {
    return {
      context: error.message,
      variables: {
        lockedUntil: format(new Date(Number(error.lockedUntil)), 'Ppp'),
      },
    };
  } else if (error instanceof WrongCredentialsError) {
    return {
      context: error.message,
      variables: { count: error.remainingTrials },
    };
  }
  if (error instanceof Error) {
    if (error.message) {
      return { context: error.message };
    }
    logger.error('Error message not defined', { message: error.message });
  } else {
    let stringifiedError = null;
    try {
      stringifiedError = JSON.stringify(error);
    } catch {
      logger.error(NO_ERROR, {
        message: NO_ERROR_MESSAGE,
        context: stringifiedError ?? 'Unable to stringify initial error',
      });
    }
  }

  return { context: 'unknown' };
}
