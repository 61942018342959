import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyTransactionCreationInput } from 'business/fund-manager/portfolio/company/services/types';
import {
  GetFundCompaniesInfosQuery,
  WebCompanyInstrumentTypeEnum,
  WebCompanyTransactionCreationModeEnum,
} from 'generated/graphql';
import { FormToggleButton } from 'ui/form';
import { SelectInput } from 'ui/form/select-input';

interface Props {
  initialValues: GetFundCompaniesInfosQuery['companies'];
  loading: boolean;
}

export const InstrumentTransactionSocietyForm = ({
  initialValues,
  loading,
}: Props) => {
  const { control, setValue, resetField } =
    useFormContext<CompanyTransactionCreationInput>();
  const { t } = useTranslation();

  const handleInstrumentCompanyIdChange = (value: string | null) => {
    setValue('instrument.companyId', value ?? '');
    // Since 'instrument.type' validation accept only values contains WebCompanyInstrumentTypeEnum.
    // For reset the field value, we need to set 'instrument.type' to an empty string as a WebCompanyInstrumentTypeEnum.
    resetField('instrument.name');
    resetField('instrument.identifier');
    setValue('instrument.type', '' as WebCompanyInstrumentTypeEnum);
  };

  const handleFormModeChange = (mode: string) => {
    resetField('instrument.id');
    resetField('instrument.name');
    resetField('instrument.identifier');
    resetField('transaction.totalQuantity');
    setValue('instrument.type', '' as WebCompanyInstrumentTypeEnum);
    setValue('transaction.mode', mode as WebCompanyTransactionCreationModeEnum);
  };

  return (
    <>
      <SelectInput
        dataTestId="select-company-transaction"
        control={control}
        options={initialValues.map(({ id, name }) => {
          return {
            id,
            value: name,
          };
        })}
        label={t('pages.fundManager.portfolio.companyTransaction.form.company')}
        name="instrument.companyId"
        onChange={(value: string | null) =>
          handleInstrumentCompanyIdChange(value)
        }
        required
        disabled={loading}
        fullWidth
      />
      <FormToggleButton
        name="mode"
        label={t(
          'pages.fundManager.portfolio.companyTransaction.form.security',
        )}
        control={control}
        fullWidth
        options={Object.values(WebCompanyTransactionCreationModeEnum).map(
          (value) => ({
            label: t(
              `pages.fundManager.portfolio.companyTransaction.form.mode.${value}`,
            ),
            value,
          }),
        )}
        readOnly
        onChange={handleFormModeChange}
      />
    </>
  );
};
