import { Navigate, useParams } from 'react-router';

import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { LpFormProvider } from 'business/shared/lp-creation-module/components/lp-form-provider/lp-form-provider';
import { LpProfileForm } from 'business/shared/lp-creation-module/components/lp-profile-form';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { useEditLpGeneralProfileForm } from 'business/shared/lp-creation-module/services/hooks/use-edit-lp-general-profile-form';
import { useLpCreationModuleNavigationContext } from 'business/shared/lp-creation-module/services/hooks/use-lp-creation-module-navigation-context';
import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import {
  GetLpFormDataQuery,
  Web_Portal_PublicationStatus_Enum,
  useGetLpFormDataQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

type Props = {
  lpProfile: NonNullable<GetLpFormDataQuery['lp']>;
  lpId: string;
};

const LpDraftEditionDisplay = ({ lpProfile, lpId }: Props) => {
  const { onNextStep } = useDraftEditionStepsContext();

  const { methods, onSubmit, error, saving } = useEditLpGeneralProfileForm({
    lpId,
    lpProfile,
    onCompleted: onNextStep,
  });

  return (
    <DraftEditionContainer onSubmit={!saving ? onSubmit : undefined}>
      <LpFormProvider<LpProfile> methods={methods} lpId={lpId}>
        <LpProfileForm error={error} saving={saving} isUpdate />
      </LpFormProvider>
    </DraftEditionContainer>
  );
};

export const LpDraftEdition = () => {
  const { lpId = '' } = useParams();
  const { editPath } = useLpCreationModuleNavigationContext();
  const isOnboardingUser = useIsLpOnboardingUser();

  const { data, loading, error } = useGetLpFormDataQuery({
    variables: { input: { id: lpId } },
    fetchPolicy: 'network-only', // ignore cache
  });

  const lpProfile = data?.lp;

  if (loading || error || !lpProfile) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (
    !isOnboardingUser &&
    lpProfile?.publicationStatus === Web_Portal_PublicationStatus_Enum.Validated
  ) {
    return <Navigate to={editPath.replace(':lpId', lpId)} replace />;
  }

  return <LpDraftEditionDisplay lpProfile={lpProfile} lpId={lpId} />;
};
