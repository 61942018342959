import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { FinancialFlowsFilterType } from 'business/lp-platform/financial-flows/types';
import { GetOperationsQuery } from 'generated/graphql';

import {
  dateColumn,
  distributedColumn,
  distributedRecallableColumn,
  drawnColumn,
  fundColumn,
  operationColumn,
  operationIdentifierColumn,
  shareTypeColumn,
  statusColumn,
} from './columns';

export function getFinancialFlowsColumnDefinition(
  t: TFunction,
  filters: FinancialFlowsFilterType,
): GridColDef<GetOperationsQuery['operations'][number]>[] {
  return [
    dateColumn(t),
    fundColumn(t, filters.funds),
    shareTypeColumn(t, filters.shares),
    operationIdentifierColumn(t, filters.identifiers),
    drawnColumn(t),
    distributedColumn(t),
    distributedRecallableColumn(t),
    statusColumn(t),
    operationColumn(t),
  ];
}
