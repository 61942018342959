import { useTranslation } from 'translations/hook';

import { GetFundShareValuationQuery } from 'generated/graphql';
import { formatRatioToPercentage } from 'technical/currency/formatters';
import { InfoCard } from 'ui/info-card';
import SynthesisItem from 'ui/synthesis-item';

interface Props {
  selectedShareId: string;
  data: GetFundShareValuationQuery['fundNetIrrByShare'];
  className?: string;
}

export const DisplayFundIrr = ({ selectedShareId, data, className }: Props) => {
  const { t } = useTranslation();

  const netIrr =
    data
      .filter((value): value is Exclude<typeof value, null> => !!value)
      .find(({ id }) => id === selectedShareId)?.netIrr ?? NaN;

  const formattedIrr = isNaN(netIrr)
    ? 'N/A'
    : formatRatioToPercentage(netIrr, { precision: 2 });

  return (
    <InfoCard className={className}>
      <SynthesisItem
        value={formattedIrr}
        description={t('pages.fundManager.fundShareValuation.netIrr')}
      />
    </InfoCard>
  );
};
