import {
  RiskAssessmentCountryType,
  riskAssessmentCountries,
} from './risk-assessment-constants';

export function getCountryRiskRegion(
  countryCode?: string | null,
): RiskAssessmentCountryType | undefined {
  if (!countryCode) {
    return undefined;
  }

  // These are in order of decreasing risk value, some countries being in multiple lists.
  // Do not change this order for no reason.
  if (riskAssessmentCountries.blacklist.includes(countryCode)) {
    return RiskAssessmentCountryType.BLACKLIST;
  }
  if (riskAssessmentCountries.offshoreCenter.includes(countryCode)) {
    return RiskAssessmentCountryType.OFFSHORE_CENTER;
  }
  if (riskAssessmentCountries.articleL561Targeted.includes(countryCode)) {
    return RiskAssessmentCountryType.ART_L561_TARGETED_COUNTRIES;
  }
  if (riskAssessmentCountries.nonEuWithAMLArrangement.includes(countryCode)) {
    return RiskAssessmentCountryType.NON_EU_WITH_AML_ARRANGEMENT;
  }
  if (riskAssessmentCountries.euCountry.includes(countryCode)) {
    return RiskAssessmentCountryType.EU_COUNTRIES;
  }
  return undefined;
}
