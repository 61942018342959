import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const LogOffIcon = ({ primaryColor, secondaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <circle cx="12.3372" cy="15.9963" r="7.00317" fill={secondaryColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5051 3.16765C13.5054 2.52303 12.983 2.00026 12.3384 2C11.6937 1.99975 11.171 2.52212 11.1707 3.16674L11.167 12.5084C11.1668 13.153 11.6892 13.6758 12.3338 13.676C12.9784 13.6763 13.5012 13.1538 13.5014 12.5093L13.5051 3.16765Z"
        fill={primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13.6525C3 11.074 4.04512 8.73965 5.73483 7.04989L7.3855 8.70056C6.11822 9.96788 5.33439 11.7186 5.33439 13.6525C5.33439 17.5203 8.46982 20.6557 12.3376 20.6557C16.2053 20.6557 19.3407 17.5203 19.3407 13.6525C19.3407 11.7186 18.5569 9.96779 17.2895 8.70048L18.9402 7.0498C20.63 8.73958 21.6751 11.074 21.6751 13.6525C21.6751 18.8094 17.4946 22.9901 12.3376 22.9901C7.18057 22.9901 3 18.8094 3 13.6525Z"
        fill="#363636"
      />
    </SvgIcon>
  );
};
