import * as yup from 'yup';

import { nonEmptyStringSchema } from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

export const uploadedFileSchema = yup.object({
  filePath: nonEmptyStringSchema,
  name: nonEmptyStringSchema,
  type: nonEmptyStringSchema,
  size: yup.number().positive().required(ValidationErrors.REQUIRED),
});
