import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Divider, Stack } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'translations/hook';

import { computeAmlVigilanceAverage } from 'business/fund-manager/lp/services/compute-aml-vigilance-average';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { CompletionGaugeGraph } from 'business/shared/components/completion-gauge-graph/completion-gauge-graph';
import { FundLpDocumentUpToDatenessQuery } from 'generated/graphql';
import { useRiskAssessmentColors } from 'technical/risk-assessment-colors/risk-assessment-colors';
import { CheckmarkRoundedIcon } from 'ui/icons/checkmark-rounded';
import { WarningIcon } from 'ui/icons/warning';
import { InfoCard } from 'ui/info-card';
import { InfoDescriptionItem } from 'ui/info-description-item';
import { InfoItem } from 'ui/info-item';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';
interface Props {
  title: string;
  path?: string;
  riskScore?: number;
  conformity?: boolean;
  numberOfdocumentsToUpdate?: number;
  completionPercent?: number;
  fundLpDocumentUpToDateness?: FundLpDocumentUpToDatenessQuery['fundLpDocumentUpToDateness'];
}

const ValidityDocumentInfoItem = ({
  numberOfdocumentsToUpdate,
}: {
  numberOfdocumentsToUpdate: number;
}) => {
  const { t } = useTranslation();

  return numberOfdocumentsToUpdate > 0 ? (
    <InfoDescriptionItem
      description={`${t(
        'pages.dashboard.cards.diligence.document.updateRequired',
      )} (${numberOfdocumentsToUpdate})`}
      imageSource={<WarningAmberIcon />}
      iconColor="danger"
      size="dense"
    />
  ) : (
    <InfoDescriptionItem
      description={t('pages.dashboard.cards.diligence.document.ok')}
      imageSource={<CheckCircleOutlineIcon />}
      iconColor="primary"
      size="dense"
    />
  );
};

const Conformity = ({ isLpComplete }: { isLpComplete: boolean }) => {
  const palette = usePalette();
  const { t } = useTranslation();

  return isLpComplete ? (
    <InfoItem
      hideValue
      description={t('pages.dashboard.cards.riskAssessment.noMissingKeyData')}
      imageSource={<CheckmarkRoundedIcon />}
      size="dense"
      sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}
    />
  ) : (
    <InfoItem
      hideValue
      description={t('pages.dashboard.cards.riskAssessment.missingKeyData')}
      imageSource={<WarningIcon />}
      size="dense"
      sx={{ color: palette.dangerMain, bgcolor: palette.dangerLighter }}
    />
  );
};

export const DiligenceCard = ({
  title,
  path,
  riskScore,
  conformity,
  numberOfdocumentsToUpdate,
  completionPercent,
  fundLpDocumentUpToDateness,
}: Props) => {
  const riskAssessmentColors = useRiskAssessmentColors();
  const vigilanceScore = computeAmlVigilanceAverage(riskScore);
  const { t } = useTranslation();

  const items: ReactElement[] = [
    ...(riskScore
      ? [
          <InfoItem
            key="risk"
            value={t('pages.dashboard.cards.riskAssessment.risk')}
            description={t(
              'pages.fundManager.lpCreation.aml.table.averages.riskAverageMessage',
              {
                context: riskScore,
              },
            )}
            imageSource={<>{riskScore}</>}
            size="dense"
            sx={{
              bgcolor: riskAssessmentColors[riskScore].background,
              color: riskAssessmentColors[riskScore].text,
            }}
          />,
          <InfoItem
            key="vigilance"
            value={t('pages.dashboard.cards.riskAssessment.vigilance')}
            description={t(
              'pages.fundManager.lpCreation.aml.table.averages.vigilanceAverageMessage',
              {
                context: vigilanceScore,
              },
            )}
            imageSource={<>{vigilanceScore}</>}
            sx={{
              bgcolor: riskAssessmentColors[riskScore].background,
              color: riskAssessmentColors[riskScore].text,
            }}
          />,
        ]
      : []),
    ...(numberOfdocumentsToUpdate !== undefined
      ? [
          <ValidityDocumentInfoItem
            key="documents"
            numberOfdocumentsToUpdate={numberOfdocumentsToUpdate}
          />,
        ]
      : []),
    ...(conformity !== undefined
      ? [<Conformity key="conformity" isLpComplete={conformity} />]
      : []),
  ];

  return (
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>
      <Stack direction={'row'} justifyContent={'center'}>
        {completionPercent !== undefined ? (
          <Stack
            key="lpDataCompletionGauge"
            alignItems="center"
            padding={1}
            sx={{ width: '50%' }}
          >
            <div className={styles.graphContainer}>
              <CompletionGaugeGraph
                fileName={'lp-completion'}
                percent={completionPercent}
              />
            </div>
            <Typo size="sm" textAlign="center">
              {t('pages.dashboard.cards.riskAssessment.lpCompletion')}
            </Typo>
          </Stack>
        ) : null}
        {fundLpDocumentUpToDateness !== undefined ? (
          <Stack
            key="LpDocumentUpToDatenessGauge"
            alignItems="center"
            padding={1}
            sx={{ width: '50%' }}
          >
            <div className={styles.graphContainer}>
              <CompletionGaugeGraph
                fileName={'lp-completion'}
                percent={fundLpDocumentUpToDateness.upToDateRatio * 100}
                displayNumber={fundLpDocumentUpToDateness.upToDate}
              />
            </div>
            <Typo size="sm" textAlign="center">
              {t('pages.dashboard.cards.riskAssessment.lpDocumentUpToDateness')}
            </Typo>
          </Stack>
        ) : null}
      </Stack>
      {items.length ? (
        <>
          {completionPercent !== undefined &&
          fundLpDocumentUpToDateness !== undefined ? (
            <Divider />
          ) : null}
          <InfoCard.Items size="auto">{items}</InfoCard.Items>
        </>
      ) : null}
    </InfoCard>
  );
};
