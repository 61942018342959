import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { CompanyMetricsTypeForm } from 'business/fund-manager/settings/company-metrics-type/components/company-metrics-type-form';
import { getCompanyMetricsTypeColumnDefinition } from 'business/fund-manager/settings/company-metrics-type/services/get-company-metrics-type-column-definition';
import { CompanyMetricsTypeFormContextEnum } from 'business/fund-manager/settings/company-metrics-type/services/types';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import {
  GetManagementCompanyMetricsTypesQuery,
  useGetManagementCompanyMetricsTypesQuery,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { SimpleTable } from 'ui/simple-table/simple-table';
import Spacer from 'ui/spacer';

export const CompanyMetricsTypeList = () => {
  const { t } = useTranslation();
  const { managementCompanyId } = useNavigateSettings();
  const { data, loading, refetch } = useGetManagementCompanyMetricsTypesQuery({
    variables: { id: managementCompanyId },
  });
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const headers = getCompanyMetricsTypeColumnDefinition(t, refetch);
  return (
    <>
      <Stack spacing={2}>
        <Spacer justify="end">
          <ActionButton onClick={handleOpenModal} variant="submit">
            {t('pages.fundManager.settings.companyMetrics.table.action.create')}
          </ActionButton>
        </Spacer>
        <SimpleTable<
          GetManagementCompanyMetricsTypesQuery['managementCompanyMetricsTypes'][number]
        >
          rowData={data?.managementCompanyMetricsTypes ?? []}
          columnDefs={headers}
          loading={loading}
          domLayout="autoHeight"
        />
      </Stack>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <CompanyMetricsTypeForm
            closeForm={handleCloseModal}
            context={CompanyMetricsTypeFormContextEnum.Add}
            initialValues={{ managementCompanyId: managementCompanyId }}
            onCompleted={refetch}
          />
        }
      />
    </>
  );
};
