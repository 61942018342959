import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useRef, useState } from 'react';
import { useTranslation } from 'translations/hook';

import GraphAndSynthesisPanel from 'business/lp-platform/commitment/components/graph-synthesis-panel';
import { buildCommitmentNestedClauses } from 'business/lp-platform/commitment/services/commitment-clause-builder';
import { getCommitmentColumnDefinition } from 'business/lp-platform/commitment/services/get-commitment-column-definition';
import {
  CommitmentFilteredFields,
  isGetCommitmentsQuery,
} from 'business/lp-platform/commitment/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import { GetCommitmentsQuery, useGetCommitmentsQuery } from 'generated/graphql';
import { DEFAULT_WHERE_CLAUSE } from 'technical/filter/constants';
import { handleMultipleFilter } from 'technical/filter/filters';
import {
  FuzzyFilterDataKey,
  FuzzyFilterEnabledColumn,
} from 'technical/filter/fuzzy-filter/constants';
import { handleFuzzyFiltering } from 'technical/filter/fuzzy-filter/fuzzy-filter';
import { removeDuplicates } from 'technical/remove-duplicates';
import { Table, ToolbarContainer } from 'ui/table';

const Commitment = () => {
  const { t } = useTranslation();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [whereClause, setWhereClause] = useState(DEFAULT_WHERE_CLAUSE);
  const [customFilters, setCustomFilters] = useState<CommitmentFilteredFields>({
    shareTypes: [],
    fundNames: [],
  });
  const headers: GridColDef<
    GetCommitmentsQuery['dataviz_commitment'][number]
  >[] = getCommitmentColumnDefinition(t, customFilters);
  const [searchedTerm, setSearchedTerm] = useState('');

  const { data, loading, refetch } = useGetCommitmentsQuery({
    variables: DEFAULT_WHERE_CLAUSE,
  });

  if (data && !customFilters.fundNames.length) {
    setCustomFilters({
      shareTypes: removeDuplicates(
        data.dataviz_commitment.map(({ share }) => share),
        'type',
      ),
      fundNames: removeDuplicates(
        data.dataviz_commitment
          .map(({ share }) => share)
          .map(({ fund }) => fund),
        'name',
      ),
    });
  }

  const rows =
    handleFuzzyFiltering({
      data,
      searchedTerm,
      dataKeys: [FuzzyFilterDataKey.Commitments],
      enabledColumns: [
        FuzzyFilterEnabledColumn.ShareType,
        FuzzyFilterEnabledColumn.FundName,
      ],
    }) || data;

  if (whereClause) {
    refetch(whereClause);
  }

  return (
    <ConnectedPage title={t('pages.commitment.title')}>
      <Stack spacing={2}>
        <ToolbarContainer
          toolbarRef={toolbarRef}
          filterPlaceholders={[
            t('pages.commitment.table.fundName'),
            t('pages.commitment.table.shareType'),
          ]}
        />
        <GraphAndSynthesisPanel
          loading={loading}
          commitments={
            rows && isGetCommitmentsQuery(rows) ? rows.dataviz_commitment : []
          }
        />
        <Table<GetCommitmentsQuery['dataviz_commitment'][number]>
          toolbarAnchor={toolbarRef.current}
          title={t('pages.commitment.table.title')}
          columns={headers}
          rows={
            rows && isGetCommitmentsQuery(rows) ? rows.dataviz_commitment : []
          }
          loading={loading}
          filterMode="server"
          onFilterModelChange={(model) => {
            handleMultipleFilter(
              model,
              setWhereClause,
              buildCommitmentNestedClauses,
              setSearchedTerm,
            );
          }}
          initialState={{
            aggregation: {
              model: {
                numericAmount: 'sum',
                numericUnfundedCommitment: 'sum',
                numericTotalDrawn: 'sum',
                numericTotalDistributed: 'sum',
                numericTotalDistributedRecallable: 'sum',
              },
            },
          }}
          columnHeaderHeight={60}
        />
      </Stack>
    </ConnectedPage>
  );
};

export default Commitment;
