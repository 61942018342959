import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import {
  DownloadOnboardingSubscriptionGenerationQuery,
  useDownloadOnboardingSubscriptionGenerationQuery,
} from 'generated/graphql';
import { downloadFile } from 'technical/download-file';
import { DownloadButton } from 'ui/download-button';
import { QueryStateDisplay } from 'ui/query-state-display';

import { LaunchSignatureButton } from './launch-signature-button';
import styles from './onboarding-subscription-ready-for-signature.module.scss';
import { OverrideTemplateButton } from './override-template-button';
import { UploadSignedDocumentButton } from './upload-signed-document-button';

interface OnboardingSubscriptionReadyForSignatureProps {
  onboardingSubscriptionId: string;
}
export const OnboardingSubscriptionReadyForSignature = ({
  onboardingSubscriptionId,
}: OnboardingSubscriptionReadyForSignatureProps) => {
  const { t } = useTranslation();

  const { data, loading, error, refetch } =
    useDownloadOnboardingSubscriptionGenerationQuery({
      variables: { id: onboardingSubscriptionId },
    });

  const onClickDownload = ({
    downloadOnboardingSubscriptionGeneration,
  }: DownloadOnboardingSubscriptionGenerationQuery) => {
    downloadFile(
      `${downloadOnboardingSubscriptionGeneration.url}&response-content-disposition=attachment;`,
      downloadOnboardingSubscriptionGeneration.fileName,
    );
  };

  const isDocumentLoading = loading || error || !data ? true : false;

  return (
    <Stack padding={4} spacing={4}>
      <Stack direction="row-reverse" flexWrap="wrap" spacing={2} useFlexGap>
        <LaunchSignatureButton
          disabled={isDocumentLoading}
          onboardingSubscriptionId={onboardingSubscriptionId}
        />
        <UploadSignedDocumentButton
          onboardingSubscriptionId={onboardingSubscriptionId}
        />
        <DownloadButton
          label={t(
            'pages.onboardingSubscription.readyForSignature.downloadButton',
          )}
          onClick={data ? () => onClickDownload(data) : undefined}
        />
        <OverrideTemplateButton
          disabled={loading}
          onboardingSubscriptionId={onboardingSubscriptionId}
          onCompleted={refetch}
        />
      </Stack>
      <Stack className={styles.iframe}>
        {loading || error || !data ? (
          <QueryStateDisplay
            loading={loading}
            error={Error(
              'pages.onboardingSubscription.readyForSignature.templateInterpretationError',
            )}
          />
        ) : (
          <iframe
            title={t(
              'pages.onboardingSubscription.readyForSignature.previewReaderTitle',
            )}
            src={data.downloadOnboardingSubscriptionGeneration.url}
            className={styles.iframe}
          />
        )}
      </Stack>
    </Stack>
  );
};
