import { PropsWithChildren } from 'react';
import { useParams } from 'react-router';

import { LpCreationModuleNavigationContext } from './context';

type LpCreationModuleNavigationProviderProps = PropsWithChildren<{
  basename: string;
  editPath?: string;
  previousPath: string;
  finalPath?: string;
  getBasenameWithParams?: (args: {
    basename: string;
    params: Record<string, string | undefined>;
  }) => string;
}>;

export const LpCreationModuleNavigationProvider = ({
  basename,
  editPath,
  previousPath,
  finalPath,
  children,
  getBasenameWithParams = (args) => args.basename,
}: LpCreationModuleNavigationProviderProps) => {
  const params = useParams();
  const getAbsolutePath = (subpath: string) =>
    `${getBasenameWithParams({ basename, params })}${subpath}`;

  return (
    <LpCreationModuleNavigationContext.Provider
      value={{
        getAbsolutePath,
        editPath: editPath ?? previousPath,
        previousPath,
        finalPath,
      }}
    >
      {children}
    </LpCreationModuleNavigationContext.Provider>
  );
};
