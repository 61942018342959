import {
  Box,
  CircularProgress,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';

import { defaultPalette } from 'business/providers/theme/services/default-palette.constant';
import { getPalette } from 'business/providers/theme/services/get-palette';
import { useThemeConfigQuery } from 'generated/graphql';

import styles from './index.module.scss';

export const CustomThemeProvider = ({ children }: any) => {
  const { data, loading, error } = useThemeConfigQuery({
    context: { customServer: true },
  });

  if (loading || error || !data) {
    return (
      <Box className={styles.container}>
        <CircularProgress />
      </Box>
    );
  }

  const configColors = data.themeConfig?.colors;
  const palette = getPalette({ configColors, defaultPalette });

  const configuredTheme = createTheme({
    typography: {
      fontFamily: 'Source Sans Pro',
    },
    palette,
    components: {
      MuiCard: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              borderRadius: 1.5,
              bgcolor: palette.pe3.backgroundForm,
              padding: 0,
            }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            whiteSpace: 'preserve-breaks',
            overflow: 'auto',
          },
        },
      },
    },
  });

  document.documentElement.style.setProperty(
    '--light-other-divider',
    palette.pe3.tableCellDivider,
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={configuredTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
