import { RouteObject } from 'react-router';

import { Home } from 'business/home/pages/home';
import { MFAPage } from 'business/shared/mfa/pages';
import SharedRoutes from 'business/shared/router/routes';
import {
  AnonymousRoute,
  ProtectedRoute,
} from 'business/user/helpers/protected-route-container';
import { CreatePasswordPage } from 'business/user/pages/create-password';
import ForgotPasswordPage from 'business/user/pages/forgot-password';
import LoginProviderCallbackScreen from 'business/user/pages/login-provider-callback';
import { ResetPasswordPage } from 'business/user/pages/reset-password';
import { SignInPage } from 'business/user/pages/sign-in';
import ValidateMFAPage from 'business/user/pages/validate-mfa';
import { ErrorPage } from 'technical/error-page/error-page';
import ReportingNoMatch from 'technical/router/switch/reporting-no-match';

export const sharedRoutesProps: RouteObject[] = [
  {
    path: SharedRoutes.LoginCallback,
    element: (
      <AnonymousRoute>
        <LoginProviderCallbackScreen />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.SignIn,
    element: (
      <AnonymousRoute>
        <SignInPage />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.ValidateMFA,
    element: (
      <AnonymousRoute>
        <ValidateMFAPage />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.ForgotPassword,
    element: (
      <AnonymousRoute>
        <ForgotPasswordPage />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.ResetPassword,
    element: (
      <AnonymousRoute>
        <ResetPasswordPage />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.CreatePassword,
    element: (
      <AnonymousRoute>
        <CreatePasswordPage />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.Error,
    element: (
      <AnonymousRoute>
        <ErrorPage />
      </AnonymousRoute>
    ),
  },
  {
    path: SharedRoutes.ConnectedResetPassword,
    element: (
      <ProtectedRoute>
        <ResetPasswordPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SharedRoutes.SetupMFA,
    element: (
      <AnonymousRoute>
        <MFAPage />
      </AnonymousRoute>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <ReportingNoMatch />
      </ProtectedRoute>
    ),
  },
];
