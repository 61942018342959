import { simpleClauseBuilder } from 'technical/filter/build-where-condition';
import { QueryParams } from 'technical/filter/types';

export const metricsClause = (
  field: string,
  gqlOperator: { [key: string]: unknown },
) => {
  return {
    _and: [
      {
        company_metrics: {
          type: { _eq: field },
        },
      },
      { company_metrics: { amount: gqlOperator } },
    ],
  };
};

function portfolioFlowFundNameBuilder(gqlOperator: {
  [key: string]: unknown;
}): QueryParams {
  return {
    fund: {
      name: gqlOperator,
    },
  };
}

export const buildPortfolioNestedClauses = (
  field: string,
  gqlOperator: {
    [key: string]: unknown;
  },
): QueryParams => {
  if (field === 'fundName') {
    return portfolioFlowFundNameBuilder(gqlOperator);
  }
  if (['headcount', 'turnover'].includes(field)) {
    return metricsClause(field, gqlOperator);
  }
  return simpleClauseBuilder(field, gqlOperator);
};
