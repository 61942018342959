import { IFile } from 'technical/file-management/types';
import { PreviewFile } from 'ui/preview-file';

interface Props {
  file: IFile;
  originalFile?: File;
  handleRemoveFile: (file: IFile) => void;
  previewFile?: boolean;
  className?: string;
}

export const UploadedFile = ({
  file,
  originalFile,
  previewFile,
  handleRemoveFile,
  className,
}: Props) => {
  const { name, size } = file;

  const fileUrl =
    previewFile && originalFile ? URL.createObjectURL(originalFile) : null;

  const handleRemove = () => {
    handleRemoveFile(file);
  };

  return (
    <PreviewFile
      url={fileUrl}
      name={name}
      size={size}
      handleDelete={handleRemove}
      className={className}
    />
  );
};
