import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { getKYBFormValues } from 'business/shared/lp-creation-module/services/get-kyb-form-values';
import { LpKYB } from 'business/shared/lp-creation-module/services/types';
import { lpKYBSchema } from 'business/shared/lp-creation-module/services/validation';
import {
  LpKybFormDataQuery,
  useUpsertLpLegalEntityKybMutation,
} from 'generated/graphql';

type Props = {
  data: NonNullable<LpKybFormDataQuery>;
  lpId: string;
  onCompleted: () => void;
};

export const useLegalEntityKYBForm = ({ data, lpId, onCompleted }: Props) => {
  const [upsertLpKybMutation, { loading: saving, error }] =
    useUpsertLpLegalEntityKybMutation({
      onCompleted,
    });

  const defaultValues = getKYBFormValues(data);

  const methods = useForm<LpKYB>({
    resolver: yupResolver<LpKYB>(lpKYBSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async ({
    USRelatedPerson,
    ampereReporting,
    frenchEquitySavingsPlan,
    taxOption,
    professionnalClient,
    customAnswers,
    ...rest
  }: LpKYB) =>
    upsertLpKybMutation({
      variables: {
        input: {
          lpId,
          data: {
            ...rest,
            customAnswers: customAnswers ?? [],
            USRelatedPerson: USRelatedPerson ?? false,
            ampereReporting: ampereReporting ?? false,
            frenchEquitySavingsPlan: frenchEquitySavingsPlan ?? false,
            taxOption: taxOption ?? false,
            professionnalClient: professionnalClient ?? false,
          },
        },
      },
      // catch to avoid react-hook-form error
    }).catch(() => undefined);

  return {
    methods,
    onSubmit: handleSubmit(onSubmit),
    saving,
    error,
  };
};
