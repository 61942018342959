import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

import {
  LpCommitmentAndOperations,
  LpSubscriptionsFilteredFields,
} from './types';

export function getLpSubscriptionListColumnDefinition(
  t: TFunction,
  customFilters: LpSubscriptionsFilteredFields,
): ColDef<LpCommitmentAndOperations>[] {
  const columns: ColDef<LpCommitmentAndOperations>[] = [
    {
      field: 'fundName',
      headerName: t('pages.fundManager.subscription.table.fundName'),
      filter: true,
      filterParams: customFilters.fundNames,
    },
    {
      field: 'shareName',
      headerName: t('pages.fundManager.subscription.table.shareName'),
      filter: true,
      filterParams: customFilters.shareNames,
    },
    {
      field: 'status',
      headerName: t('pages.fundManager.subscription.table.status'),
      filter: true,
      filterParams: customFilters.status,
      valueFormatter: ({ value: status }) => {
        if (!status) {
          return '';
        }
        return t('pages.fundManager.subscription.table.status', {
          context: status,
        });
      },
    },
    {
      field: 'numericCommitment',
      headerName: t('pages.fundManager.subscription.table.commitment'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'numericUnfundedCommitment',
      headerName: t('pages.fundManager.subscription.table.unfundedCommitment'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'numericPaidIn',
      headerName: t('pages.fundManager.subscription.table.paidIn'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'numericDistributedNonRecallable',
      headerName: t(
        'pages.fundManager.subscription.table.distributedNonRecallable',
      ),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'numericDistributed',
      headerName: t('pages.fundManager.subscription.table.distributed'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
  ];
  return columns;
}
