import { ColDef } from 'ag-grid-community';

import { DocumentStatusEnum } from 'business/fund-manager/fund/services/types';
import { DocumentStatus } from 'ui/document-status';
import { KycDocumentationToolTip } from 'ui/kyc-documentation-tooltip/kyc-documentation-tooltip';

import { LpDocumentTypeWithDocument } from './types';

type Params = {
  onSelect: (typeId: string) => void;
};

export const getLpDocumentTypeWithDocColumnDefinition = ({
  onSelect,
}: Params): ColDef<LpDocumentTypeWithDocument>[] => {
  return [
    {
      field: 'documentType',
      headerComponent: () => {
        return <KycDocumentationToolTip />;
      },
      cellRenderer: ({ data }: { data: LpDocumentTypeWithDocument }) => {
        const shouldDisplayAddDocument = !!(
          data.status === DocumentStatusEnum.Expired ||
          data.status === DocumentStatusEnum.Requested
        );

        const handleClick = shouldDisplayAddDocument
          ? () => onSelect(data.documentTypeId)
          : undefined;

        return (
          <DocumentStatus
            status={data.status}
            text={data.documentType}
            onAdd={handleClick}
          />
        );
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  ];
};
