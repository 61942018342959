import { MouseEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { useGetOperationDocumentDownloadUrlLazyQuery } from 'generated/graphql';
import { DownloadButton } from 'ui/download-button';

interface Props {
  operationId: string;
  disabled?: boolean;
  loading?: boolean;
}

export const OperationDownloadButton = ({ operationId, disabled }: Props) => {
  const { t } = useTranslation();

  const [getOperationDocumentUrl] = useGetOperationDocumentDownloadUrlLazyQuery(
    {
      variables: { input: { operationId } },
    },
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    getOperationDocumentUrl().then(({ data }) => {
      if (
        data?.getOperationDocumentDownloadUrl.success &&
        data?.getOperationDocumentDownloadUrl.url
      ) {
        window.open(data.getOperationDocumentDownloadUrl.url);
      }
    });
  };

  return (
    <DownloadButton
      onClick={handleClick}
      disabled={disabled}
      label={t('pages.document.table.downloadButton')}
    />
  );
};
