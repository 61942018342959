import {
  OnboardingFunnelCustomData,
  OnboardingFunnelStatsByStatus,
} from 'business/fund-manager/home/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';
import variables from 'ui/variables.module.scss';

type Props = {
  fileName: string;
  data: {
    stats: OnboardingFunnelStatsByStatus;
    customData: OnboardingFunnelCustomData;
  };
};

export const OnboardingGraph = ({ fileName, data }: Props) => {
  const palette = usePalette();

  const colorRange = [
    palette.primary[900],
    palette.primary[800],
    palette.secondary[900],
    palette.secondary[800],
    palette.secondary[500],
    palette.tertiary[300],
  ];

  const plotLayout: PlotLayout = {
    colorway: colorRange,
    autosize: true,
    barmode: 'stack',
    showlegend: false,
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    hoverlabel: {
      bgcolor: palette.backgroundForm,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTextSize),
        color: palette.text,
      },
      bordercolor: palette.backgroundForm,
    },
    margin: {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
    padding: {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
  };

  const onboardingTrace = [
    {
      type: PlotChartTypeEnum.funnelarea,
      values: data.stats.map((stat) => stat.count),
      customdata: data.customData,
      texttemplate: `<b>%{value}</b> %{customdata.translation}`,
      hovertemplate: `<b>%{value}</b> %{customdata[0].translation} <br>%{customdata[0].amount} <br> %{percent}<extra></extra>`,
    },
  ];

  return (
    <PlotChart
      dataArray={onboardingTrace}
      layout={plotLayout}
      fileName={fileName}
      displayModeBar={false}
    />
  );
};
