import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpContactManager } from 'business/shared/components/lp-contact-manager';
import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { DeclineReason } from 'business/shared/lp-creation-module/components/lp-decline-reason/lp-decline-reason';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { useLpContacts } from 'business/shared/lp-creation-module/services/hooks/use-lp-contacts';
import { ContactWithId } from 'business/shared/services/contact/types';
import { useCompleteLpProfileMutation } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

type Props = {
  lpId: string;
  contacts: ContactWithId[];
  hasLegalRepresentative: boolean;
  refetch: () => void;
};

const LpCreationContactsFormPage = ({
  lpId,
  refetch,
  hasLegalRepresentative,
  contacts,
}: Props) => {
  const { onNextStep: onCompleted } = useDraftEditionStepsContext();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [completeLpProfileMutation] = useCompleteLpProfileMutation({
    onError: () => {
      enqueueSnackbar(t('errors.completeLpProfile'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.completeLpProfile'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onCompleted();
    },
  });

  const onSubmit = () => {
    completeLpProfileMutation({
      variables: {
        input: {
          id: lpId,
        },
      },
    });
  };

  return (
    <DraftEditionContainer
      onSubmit={hasLegalRepresentative ? onSubmit : undefined}
      rawChildren={true}
    >
      <DeclineReason />
      <LpContactManager
        lpId={lpId}
        contacts={contacts}
        onAdded={refetch}
        onDeleted={refetch}
      />
    </DraftEditionContainer>
  );
};

export const LpCreationContacts = () => {
  const { lpId = '' } = useParams();

  const { loading, error, contacts, refetch } = useLpContacts({
    lpId,
    readOnlyForRoles: true,
  });

  // TODO remove this limitation for legal entity contact
  const hasLegalRepresentative = contacts.some(
    (contact) => contact.isLegalRepresentative,
  );

  if (loading || error || !contacts) {
    return (
      <DraftEditionContainer>
        <QueryStateDisplay loading={loading} error={error} />
      </DraftEditionContainer>
    );
  }

  return (
    <LpCreationContactsFormPage
      lpId={lpId}
      contacts={contacts}
      hasLegalRepresentative={hasLegalRepresentative}
      refetch={refetch}
    />
  );
};
