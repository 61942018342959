import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';
import * as yup from 'yup';

import { PortfolioToolbarLinks } from 'business/fund-manager/portfolio/components/portfolio-toolbar-links';
import { nonEmptyStringSchema } from 'technical/validation';
import { Card } from 'ui/card';
import { SelectInput } from 'ui/form';

import styles from './index.module.scss';

const PortfolioSchema = yup.object({
  fundId: nonEmptyStringSchema,
});

type PortfolioForm = yup.InferType<typeof PortfolioSchema>;

type Props = {
  fundId?: string;
  onChange: (fundId: string) => void;
  funds: {
    id: string;
    name: string;
  }[];
};

export const PortfolioToolbar = ({ funds, fundId, onChange }: Props) => {
  const { t } = useTranslation();
  const fundData = funds
    ? funds.map((fund) => ({
        id: fund.id,
        value: fund.name,
      }))
    : [{ id: '', value: 'No data found' }];

  const { control, setValue } = useForm<PortfolioForm>({
    resolver: yupResolver<PortfolioForm>(PortfolioSchema),
    defaultValues: {
      fundId,
    },
  });

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={10}
        padding={1}
      >
        <SelectInput
          name="fundId"
          dataTestId="portofolio-fund-id-selector"
          label={t('pages.fundManager.portfolio.form.fund')}
          control={control}
          options={fundData}
          size="small"
          className={styles.selector}
          onChange={(value: string | null) => {
            if (value) {
              setValue('fundId', value);
              onChange(value);
            }
          }}
        />
        {fundId ? <PortfolioToolbarLinks fundId={fundId} /> : null}
      </Stack>
    </Card>
  );
};
