import { RcFile } from './types';

export const uploadObjectAsync = async (
  file: RcFile | File,
  uploadUrl: string,
) => {
  const headers = new Headers();
  headers.append('Content-Type', file.type);
  headers.append('Content-Length', `${file.size}`);
  headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');

  const options = {
    method: 'PUT',
    body: file,
    headers,
  };

  return fetch(uploadUrl, options);
};
