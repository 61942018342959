import { simpleClauseBuilder } from 'technical/filter/build-where-condition';
import { QueryParams } from 'technical/filter/types';

export const buildDocumentClauses = (
  field: string,
  gqlOperator: {
    [key: string]: unknown;
  },
): QueryParams => {
  if (field === 'type') {
    return {
      documentType: {
        _or: [{ labelEn: gqlOperator }, { labelFr: gqlOperator }],
      },
    };
  }
  return simpleClauseBuilder(field, gqlOperator);
};
