import { SvgIcon } from '@mui/material';

import { SvgProps } from './types/props';

export const CommitmentsMenuIcon = ({
  primaryColor,
  secondaryColor,
}: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M12.4232 9.07655H3.96161C3.26064 9.07655 2.69238 8.50829 2.69238 7.80732V7.80732C2.69238 7.10634 3.26064 6.53809 3.96161 6.53809H15.8078C16.5087 6.53809 17.077 7.10634 17.077 7.80732V7.80732"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.5"
      />
      <rect
        x="3.53809"
        y="4"
        width="14.3846"
        height="2.53846"
        rx="1.26923"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.5"
      />
      <path
        d="M9.88461 11.6156H2.26923C1.56825 11.6156 1 11.0474 1 10.3464V10.3464C1 9.6454 1.56825 9.07715 2.26923 9.07715H12.4231"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.5"
      />
      <path
        d="M10.3078 11.6152H3.96161C3.26064 11.6152 2.69238 12.1835 2.69238 12.8845V12.8845C2.69238 13.5854 3.26064 14.1537 3.96161 14.1537H9.03854"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.5"
      />
      <path
        d="M9.88469 16.6924H3.96161C3.26064 16.6924 2.69238 17.2606 2.69238 17.9616V17.9616C2.69238 18.6626 3.26064 19.2308 3.96161 19.2308H11.1539"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.5"
      />
      <path
        d="M9.46169 14.1543H5.654C4.95302 14.1543 4.38477 14.7226 4.38477 15.4235V15.4235V15.4235C4.38477 16.1245 4.95302 16.6928 5.654 16.6928H9.46169"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.5"
      />
      <circle cx="16.6533" cy="15.4229" r="5.5" fill={secondaryColor} />
      <circle
        cx="15.8075"
        cy="14.5771"
        r="6.44231"
        fill="none"
        stroke="#363636"
        strokeWidth="1.5"
      />
      <path
        d="M16.1327 17.3099C15.5497 17.3099 15.0649 17.1478 14.6784 16.8235C14.2919 16.4993 14.0658 16.0598 14.0003 15.5051C14.0003 15.5051 13.973 15.2394 14.4092 15.2394C14.8454 15.2394 14.8454 15.5051 14.8454 15.5051C14.8978 15.7861 15.0321 16.031 15.2483 16.24C15.4711 16.4417 15.7691 16.5426 16.1426 16.5426C16.4898 16.5426 16.7453 16.4633 16.909 16.3048C17.0728 16.1391 17.1547 15.9446 17.1547 15.7212C17.1547 15.397 17.0466 15.1809 16.8304 15.0728C16.6208 14.9647 16.3227 14.8674 15.9362 14.781C15.6742 14.7233 15.4121 14.6405 15.1501 14.5324C14.888 14.4243 14.6686 14.273 14.4917 14.0785C14.3148 13.8768 14.2264 13.6138 14.2264 13.2896C14.2264 12.8213 14.3836 12.4394 14.698 12.144C15.019 11.8414 15.4514 11.6901 15.9952 11.6901C16.5127 11.6901 16.9353 11.8342 17.2628 12.1224C17.5969 12.4034 17.7902 12.8069 17.8426 13.3328C17.8426 13.3328 17.8426 13.5485 17.4987 13.5634C17.1547 13.5783 17.027 13.3328 17.027 13.3328C16.9942 13.059 16.8861 12.8465 16.7027 12.6952C16.5258 12.5367 16.2867 12.4574 15.9853 12.4574C15.6905 12.4574 15.4612 12.5259 15.2975 12.6628C15.1402 12.7997 15.0616 12.9798 15.0616 13.2031C15.0616 13.4193 15.1632 13.5886 15.3663 13.7111C15.5759 13.8336 15.8576 13.938 16.2114 14.0245C16.5127 14.0965 16.7977 14.1866 17.0663 14.2947C17.3414 14.3955 17.5642 14.5504 17.7345 14.7594C17.9114 14.9611 17.9998 15.2565 17.9998 15.6456C18.0064 16.1283 17.8393 16.5281 17.4987 16.8452C17.1645 17.155 16.7092 17.3099 16.1327 17.3099Z"
        fill="#363636"
      />
      <path
        d="M15.6362 11.4437C15.6362 11.1986 15.8194 11 16.0453 11C16.2712 11 16.4543 11.1986 16.4543 11.4437V11.7394C16.4543 11.9845 16.2712 12.1831 16.0453 12.1831C15.8194 12.1831 15.6362 11.9845 15.6362 11.7394V11.4437Z"
        fill="#363636"
      />
      <path
        d="M15.6362 17.2606C15.6362 17.0155 15.8194 16.8169 16.0453 16.8169C16.2712 16.8169 16.4543 17.0155 16.4543 17.2606V17.5563C16.4543 17.8014 16.2712 18 16.0453 18C15.8194 18 15.6362 17.8014 15.6362 17.5563V17.2606Z"
        fill="#363636"
      />
      <path
        d="M16.1327 17.3099C15.5497 17.3099 15.0649 17.1478 14.6784 16.8235C14.2919 16.4993 14.0658 16.0598 14.0003 15.5051C14.0003 15.5051 13.973 15.2394 14.4092 15.2394C14.8454 15.2394 14.8454 15.5051 14.8454 15.5051C14.8978 15.7861 15.0321 16.031 15.2483 16.24C15.4711 16.4417 15.7691 16.5426 16.1426 16.5426C16.4898 16.5426 16.7453 16.4633 16.909 16.3048C17.0728 16.1391 17.1547 15.9446 17.1547 15.7212C17.1547 15.397 17.0466 15.1809 16.8304 15.0728C16.6208 14.9647 16.3227 14.8674 15.9362 14.781C15.6742 14.7233 15.4121 14.6405 15.1501 14.5324C14.888 14.4243 14.6686 14.273 14.4917 14.0785C14.3148 13.8768 14.2264 13.6138 14.2264 13.2896C14.2264 12.8213 14.3836 12.4394 14.698 12.144C15.019 11.8414 15.4514 11.6901 15.9952 11.6901C16.5127 11.6901 16.9353 11.8342 17.2628 12.1224C17.5969 12.4034 17.7902 12.8069 17.8426 13.3328C17.8426 13.3328 17.8426 13.5485 17.4987 13.5634C17.1547 13.5783 17.027 13.3328 17.027 13.3328C16.9942 13.059 16.8861 12.8465 16.7027 12.6952C16.5258 12.5367 16.2867 12.4574 15.9853 12.4574C15.6905 12.4574 15.4612 12.5259 15.2975 12.6628C15.1402 12.7997 15.0616 12.9798 15.0616 13.2031C15.0616 13.4193 15.1632 13.5886 15.3663 13.7111C15.5759 13.8336 15.8576 13.938 16.2114 14.0245C16.5127 14.0965 16.7977 14.1866 17.0663 14.2947C17.3414 14.3955 17.5642 14.5504 17.7345 14.7594C17.9114 14.9611 17.9998 15.2565 17.9998 15.6456C18.0064 16.1283 17.8393 16.5281 17.4987 16.8452C17.1645 17.155 16.7092 17.3099 16.1327 17.3099Z"
        fill="none"
        stroke="#363636"
        strokeWidth="0.5"
      />
      <path
        d="M15.6362 11.4437C15.6362 11.1986 15.8194 11 16.0453 11C16.2712 11 16.4543 11.1986 16.4543 11.4437V11.7394C16.4543 11.9845 16.2712 12.1831 16.0453 12.1831C15.8194 12.1831 15.6362 11.9845 15.6362 11.7394V11.4437Z"
        fill="none"
        stroke="#363636"
        strokeWidth="0.5"
      />
      <path
        d="M15.6362 17.2606C15.6362 17.0155 15.8194 16.8169 16.0453 16.8169C16.2712 16.8169 16.4543 17.0155 16.4543 17.2606V17.5563C16.4543 17.8014 16.2712 18 16.0453 18C15.8194 18 15.6362 17.8014 15.6362 17.5563V17.2606Z"
        fill="none"
        stroke="#363636"
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};
