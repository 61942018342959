import { GetFundProfileQuery } from 'generated/graphql';

import { FundProfile } from './types';
import { currencySchema, fromDateSchema, stageSchema } from './validation';

export const getProfileFormValues = (
  profile: NonNullable<GetFundProfileQuery['fundProfile']>,
): FundProfile => ({
  termDelay: profile.termDelay,
  // joy of generated type custom-server-type and hasura type aren't compatible
  termFromDateOf: fromDateSchema
    .required()
    .validateSync(profile.termFromDateOf),
  termExtension: profile.termExtension ?? undefined,
  name: profile.name,
  firstClosingDate: new Date(profile.firstClosingDate),
  finalClosingDate: profile.finalClosingDate
    ? new Date(profile.finalClosingDate)
    : undefined,
  vintageYear: profile.vintageYear,
  firstInvestmentDate: profile.firstInvestmentDate
    ? new Date(profile.firstInvestmentDate)
    : undefined,
  currency: currencySchema.required().validateSync(profile.currency),
  valuationPolicy: profile.valuationPolicy ?? undefined,
  stage: profile.stage ? stageSchema.validateSync(profile.stage) : undefined,
  sector: profile.sector ?? undefined,
  geography: profile.geography ?? undefined,
  duration: profile.duration ?? undefined,
  investmentPeriodFromDateOf: fromDateSchema.validateSync(
    profile.investmentPeriodFromDateOf,
  ),
  investmentPeriodExtension: profile.investmentPeriodExtension ?? undefined,
  // key economic
  managementFees: profile.managementFees ?? undefined,
  feeOffsets: profile.feeOffsets ?? undefined,
  carriedInterest: profile.carriedInterest ?? undefined,
  // other
  managerRegulator: profile.managerRegulator ?? undefined,
  auditor: profile.auditor ?? undefined,
  outlineOfStructure: profile.outlineOfStructure ?? undefined,
  managementCompanyId: profile.managementCompanyId,
});
