import { useParams } from 'react-router-dom';

import { SetPassword } from 'business/user/components/set-password';
import authService from 'business/user/services/auth-service';
import { SetPasswordForm } from 'business/user/services/types';

export const CreatePasswordPage = () => {
  const params = useParams();

  const token = params['*'] || null;

  const handleSubmit = (values: SetPasswordForm) =>
    authService.createPassword(
      token,
      values.password,
      values.passwordConfirmation,
    );

  return (
    <SetPassword
      titleKey="pages.createPassword.title"
      submitLabelKey="pages.createPassword.submit"
      onSubmit={handleSubmit}
    />
  );
};
