import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { FundDocumentModalForm } from 'business/fund-manager/fund/components/fund-document-upload-modal-form';
import { getDocumentColumnDefinition } from 'business/fund-manager/shared/services/get-documents-column-definition';
import { useFund } from 'business/providers/fund-provider/use-fund';
import {
  WebDocumentWithType,
  useFundRelatedWebDocumentsWithTypeQuery,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { CustomModal } from 'ui/custom-modal';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundDocumentList = () => {
  const { t } = useTranslation();

  const { fundId = '' } = useParams();
  const { managementCompanyId } = useFund();

  if (!managementCompanyId) {
    throw new Error('A management company is required');
  }

  const { data, error, loading, refetch } =
    useFundRelatedWebDocumentsWithTypeQuery({
      variables: {
        fundInput: { fundId },
        managementCompanyInput: { id: managementCompanyId },
      },
    });

  const { open, handleCloseModal, handleOpenModal } = useModal();

  const onCompleted = () => {
    handleCloseModal();
    refetch();
  };

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const rowData = (data?.fundRelatedWebDocumentsWithType ?? []).filter(
    (document): document is WebDocumentWithType => !!document,
  );
  const headers = getDocumentColumnDefinition(t);

  return (
    <Stack spacing={2}>
      <Stack alignItems="end">
        <ActionButton variant="primary" onClick={handleOpenModal}>
          {t('common.actions.addDocument')}
        </ActionButton>
      </Stack>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <FundDocumentModalForm
            onClose={handleCloseModal}
            fundId={fundId}
            onCompleted={onCompleted}
            fundDocumentTypes={data?.fundDocumentTypes ?? []}
          />
        }
      />
      <AlternativeTable<WebDocumentWithType>
        fileName="fund-documents"
        rowData={rowData}
        columnDefs={headers}
        loading={loading}
        domLayout="autoHeight"
      />
    </Stack>
  );
};
