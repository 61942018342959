import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { PersonTitle } from 'technical/get-person-full-name/get-person-full-name.types';
import { SelectInput } from 'ui/form';

type SelectTitleInputProps = Omit<
  ComponentProps<typeof SelectInput>,
  'options' | 'onChange'
>;

export function SelectTitleInput(props: SelectTitleInputProps) {
  const { t } = useTranslation();

  const options = Object.values(PersonTitle).map((id) => ({
    id,
    value: t('common.personTitle', { context: id }),
  }));

  return <SelectInput {...props} options={options} />;
}
