import { deprecatedComputeOperationStats } from 'business/fund-manager/shared/services/compute-operation-values';
import {
  CashflowEmittedByEnum,
  GetOperationStatusRecapQuery,
  OperationTypeEnum,
} from 'generated/graphql';

import { OperationStatusRecap } from './types';

const computeCashFlowTotal = (
  cashflows: {
    numericAmount: number;
    emittedBy: string;
  }[],
  type: OperationTypeEnum,
) => {
  const cashflowBalanceFromFundSide = cashflows.reduce(
    (acc, { numericAmount, emittedBy }) =>
      emittedBy === CashflowEmittedByEnum.Fund
        ? acc - numericAmount
        : acc + numericAmount,
    0,
  );

  return type === OperationTypeEnum.Drawdown
    ? cashflowBalanceFromFundSide
    : cashflowBalanceFromFundSide * -1;
};

export const computeOperationStatusRecap = (
  data: GetOperationStatusRecapQuery | undefined,
): OperationStatusRecap | undefined => {
  if (!data) {
    return undefined;
  }
  const { operation, operationShareLpsAggregate: aggr } = data;
  if (!operation || !operation.status || !aggr) {
    return undefined;
  }

  const sum = aggr?.aggregate?.sum ?? {};

  const { amount: totalCalled } = deprecatedComputeOperationStats({
    investment: sum.numericInvestment,
    fees: sum.numericFees,
    other: sum.numericOther,
    returnOfCost: sum.numericReturnOfCost,
    capitalGain: sum.numericCapitalGain,
    interest: sum.numericInterest,
    dividend: sum.numericDividend,
    currentDistributed: sum.numericCurrentDistributed,
  });

  const totalPaid = computeCashFlowTotal(
    data.cashflows,
    totalCalled > 0
      ? OperationTypeEnum.Drawdown
      : OperationTypeEnum.Distribution,
  );

  return {
    status: operation.status,
    totalCalled,
    premium: sum.numericPremium ?? 0,
    totalPaid,
    remain: Math.abs(totalCalled) + (sum.numericPremium ?? 0) - totalPaid,
  };
};
