import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { AddInstrumentValuationFormInput } from 'business/fund-manager/portfolio/valuation/services/types';
import { addInstrumentValuationFormSchema } from 'business/fund-manager/portfolio/valuation/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useUpdateInstrumentValuationMutation } from 'generated/graphql';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import { ActionButton } from 'ui/action-button';
import { FormInputText } from 'ui/form';
import { MainSecondaryItem } from 'ui/main-secondary-item';

import styles from './index.module.scss';

interface InstrumentValuationFormProps {
  id: string;
  quantity: number;
  productToDate: number;
  fairValue: number;
  accruedCoupon: number;
  onCompleted: () => void;
}
export const InstrumentValuationForm = ({
  id,
  quantity,
  fairValue,
  accruedCoupon,
  productToDate,
  onCompleted,
}: InstrumentValuationFormProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<AddInstrumentValuationFormInput>({
    resolver: yupResolver(addInstrumentValuationFormSchema),
    defaultValues: {
      fairValue,
      accruedCoupon,
    },
  });

  const [newFairValue, newAccruedCoupon] =
    useWatch<AddInstrumentValuationFormInput>({
      name: ['fairValue', 'accruedCoupon'],
      control,
    });

  const [updateInstrumentValuation] = useUpdateInstrumentValuationMutation();

  const onSubmit: SubmitHandler<AddInstrumentValuationFormInput> = (data) => {
    updateInstrumentValuation({
      variables: {
        id,
        fairValue: data.fairValue,
        accruedCoupon: data.accruedCoupon,
      },
      refetchQueries: ['getPortfolioValuationDetails'],
      onCompleted: () => {
        onCompleted();
        enqueueSnackbar(t('successMessage.updateInstrumentValuation'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: (error) => {
        enqueueSnackbar(t(error.message), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack justifyContent="flex-start" spacing={5}>
        <MainSecondaryItem
          main={t(
            'pages.fundManager.portfolio.valuationInstrument.labels.quantity',
          )}
          secondary={quantity}
          alignItems="center"
          mainVariant="overline"
          secondaryVariant="body1"
          className={styles.container}
          mainClassName={styles.title}
          secondaryClassName={styles.mainNumber}
        />
        <Stack direction="row" justifyContent="space-around">
          <Stack spacing={2} justifyContent="space-between">
            <FormInputText
              name="fairValue"
              type="number"
              label={t(
                'pages.fundManager.portfolio.valuationInstrument.form.fairValue.label',
              )}
              control={control}
            />
            <FormInputText
              name="accruedCoupon"
              type="number"
              label={t(
                'pages.fundManager.portfolio.valuationInstrument.form.accruedCoupon.label',
              )}
              control={control}
            />
            <ActionButton type="submit" variant="submit">
              {t('pages.fundManager.portfolio.valuationInstrument.form.submit')}
            </ActionButton>
          </Stack>
          <Stack spacing={2} justifyContent="space-between">
            <MainSecondaryItem
              main={t(
                'pages.fundManager.portfolio.valuationInstrument.form.fairValue.newFairValue',
              )}
              secondary={formatToMonetaryAmount(quantity * newFairValue)}
              alignItems="center"
              mainVariant="overline"
              secondaryVariant="body1"
              className={styles.container}
              mainClassName={styles.title}
              secondaryClassName={styles.number}
            />
            <MainSecondaryItem
              main={t(
                'pages.fundManager.portfolio.valuationInstrument.form.accruedCoupon.newFairValue',
              )}
              secondary={formatToMonetaryAmount(quantity * newAccruedCoupon)}
              alignItems="center"
              mainVariant="overline"
              secondaryVariant="body1"
              className={styles.container}
              mainClassName={styles.title}
              secondaryClassName={styles.number}
            />
            <MainSecondaryItem
              main={t(
                'pages.fundManager.portfolio.valuationInstrument.form.productToDate.label',
              )}
              secondary={formatToMonetaryAmount(productToDate)}
              alignItems="center"
              mainVariant="overline"
              secondaryVariant="body1"
              className={styles.container}
              mainClassName={styles.title}
              secondaryClassName={styles.number}
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
