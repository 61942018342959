import { useParams } from 'react-router';

import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { LpFormProvider } from 'business/shared/lp-creation-module/components/lp-form-provider/lp-form-provider';
import { LpIndividualKYCFormUI } from 'business/shared/lp-creation-module/components/lp-individual-kyc-form-ui';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { useIndividualKYCForm } from 'business/shared/lp-creation-module/services/hooks/use-individual-kyc-form';
import { LpKYC } from 'business/shared/lp-creation-module/services/types';
import {
  GetLpIndividualKycQuery,
  useGetLpIndividualKycQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

type Props = {
  data: GetLpIndividualKycQuery['lpKYC'];
  lpId: string;
};

const LpCreationKYCData = ({ data, lpId }: Props) => {
  const { onNextStep: onCompleted } = useDraftEditionStepsContext();

  const { methods, onSubmit, saving, error } = useIndividualKYCForm({
    onCompleted,
    data,
    lpId,
  });

  return (
    <DraftEditionContainer onSubmit={!saving ? onSubmit : undefined}>
      <LpFormProvider<LpKYC> methods={methods} lpId={lpId}>
        <LpIndividualKYCFormUI error={error} saving={saving} />
      </LpFormProvider>
    </DraftEditionContainer>
  );
};

export const LpCreationIndividualKYC = () => {
  const { lpId = '' } = useParams();

  const { data, loading, error } = useGetLpIndividualKycQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  if (loading || error || !data) {
    return (
      <DraftEditionContainer>
        <QueryStateDisplay loading={loading} error={error} />
      </DraftEditionContainer>
    );
  }

  return <LpCreationKYCData data={data.lpKYC} lpId={lpId} />;
};
