import { useTranslation } from 'translations/hook';

import CreateCashflowForm from 'business/fund-manager/operation/components/create-cashflow-form';
import useModal from 'technical/modal/use-modal';
import { Button } from 'ui/button';
import { CustomModal } from 'ui/custom-modal';

import styles from './index.module.scss';

interface CreateCashflowModalProps {
  onCreated: () => void;
  operationShareLpId: string;
}

export const CreateCashflowModal = ({
  onCreated,
  operationShareLpId,
}: CreateCashflowModalProps) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <Button
        className={styles.button}
        variant="contained"
        onClick={handleOpenModal}
      >
        {t('pages.fundManager.createFinancialFlow.openModal')}
      </Button>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <CreateCashflowForm
            handleClose={handleCloseModal}
            operationShareLpId={operationShareLpId}
            onCreated={onCreated}
          />
        }
      />
    </>
  );
};
