import { useParams } from 'react-router';

import { KYCDocumentation } from 'business/shared/lp-creation-module/components/kyc-documentation/kyc-documentation';
import {
  useGetLpKycDocumentsQuery,
  useLpDocTypeWithDocumentQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpKYCDocumentation = () => {
  const { lpId = '' } = useParams();

  // copied and pasted from `front/src/business/shared/lp-creation-module/pages/lp-creation-kyc-documentation/lp-creation-kyc-documentation.tsx`
  // please refacto if you need to use it again
  const { data, loading, error, refetch } = useGetLpKycDocumentsQuery({
    variables: {
      input: {
        lpId,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  const {
    data: lpDocumentTypes,
    loading: lpDocumentTypesLoading,
    error: lpDocumentTypeError,
    refetch: refetchDocumentsTypes,
  } = useLpDocTypeWithDocumentQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
  });

  if (
    loading ||
    lpDocumentTypesLoading ||
    error ||
    lpDocumentTypeError ||
    !data ||
    !lpDocumentTypes
  ) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <KYCDocumentation
      lpDocuments={data}
      loading={loading}
      lpId={lpId}
      lpDocumentTypes={lpDocumentTypes}
      refetchDocuments={refetch}
      refetchDocumentsTypes={refetchDocumentsTypes}
    />
  );
};
