import { Stack, Typography } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { getFundSharesColumnDefinition } from 'business/fund-manager/fund/services/get-fund-shares-column-definition';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import {
  SharesQuery,
  useUpdateShareStatusMutation,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { EmptyTableView } from 'ui/empty-table-view';
import { SimpleTable } from 'ui/simple-table/simple-table';

import AddShareButton from './action-buttons';
import styles from './index.module.scss';

interface SharesListProps {
  data: SharesQuery;
  handleOpenModal: () => void;
  title?: string;
}
export const SharesList = ({
  title,
  data,
  handleOpenModal,
}: SharesListProps) => {
  const isFundManager = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.FundAdmin,
  );

  const { t } = useTranslation();
  const palette = usePalette();
  const { enqueueSnackbar } = useSnackbar();

  const [updateShareStatus] = useUpdateShareStatusMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.updateShare'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      enqueueSnackbar(t('errors.updateShare'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const handleSharePublicationStatus = async (id: string) => {
    await updateShareStatus({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const headers: ColDef<SharesQuery['shares'][number]>[] =
    getFundSharesColumnDefinition({
      t,
      validateShare: handleSharePublicationStatus,
      isFundManager,
    });

  if (!data.shares.length) {
    return (
      <Stack spacing={10}>
        <Typography
          className={styles.largeText}
          sx={{ color: palette.primary[900] }}
        >
          {title ?? t('pages.fundManager.fundShares.shareList.table.title')}
        </Typography>
        <EmptyTableView
          text={{
            main: t(
              'pages.fundManager.fundShares.shareList.emptyTable.message.main',
            ),
            secondary: t(
              'pages.fundManager.fundShares.shareList.emptyTable.message.secondary',
            ),
          }}
          component={<AddShareButton handleOpenModal={handleOpenModal} />}
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={4}>
      <Stack direction="row" className={styles.topStack}>
        <Typography
          className={styles.largeText}
          sx={{ color: palette.primary[900] }}
        >
          {title ?? t('pages.fundManager.fundShares.shareList.table.title')}
        </Typography>
        <AddShareButton handleOpenModal={handleOpenModal} />
      </Stack>
      <SimpleTable<SharesQuery['shares'][number]>
        rowData={data.shares}
        columnDefs={headers}
        // We need to set domLayout to autoHeight to avoid a bug for Safari users
        // It might be related to a wrapper component
        domLayout="autoHeight"
      />
    </Stack>
  );
};
