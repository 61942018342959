import CloseIcon from '@mui/icons-material/Close';

import { ActionButton } from 'ui/action-button';

interface Props {
  onClick: () => void;
}

export const ButtonClose = ({ onClick }: Props) => {
  return (
    <ActionButton variant="secondary" onClick={onClick}>
      <CloseIcon />
    </ActionButton>
  );
};
