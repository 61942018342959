export function getLocalStorage(item: string) {
  return localStorage.getItem(item);
}

export function parseLocalStorage<T>(item: string): T | null {
  const storedItem: string | null = getLocalStorage(item);
  if (!storedItem) {
    return null;
  }
  return JSON.parse(storedItem);
}

export function removeItem(item: string) {
  localStorage.removeItem(item);
}
