import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { EMPTY_MANAGER_REGULATOR } from 'business/fund-manager/fund/services/constants';
import { FundProfile } from 'business/fund-manager/fund/services/types';
import { fundProfileSchema } from 'business/fund-manager/fund/services/validation';
import { useAppContext } from 'business/providers/app-provider';
import {
  CurrencyEnum,
  FundFromDateEnum,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';

type UseProfileFormProps = {
  onSubmit: (args: FundProfile) => void;
  defaultValues?: FundProfile;
};

export const useProfileForm = ({
  onSubmit,
  defaultValues,
}: UseProfileFormProps) => {
  const { user } = useAppContext();
  const methods = useForm<FundProfile>({
    resolver: yupResolver<FundProfile>(fundProfileSchema),
    defaultValues: defaultValues ?? {
      currency: CurrencyEnum.Eur,
      investmentPeriodFromDateOf: FundFromDateEnum.FirstClosingDate,
      termFromDateOf: FundFromDateEnum.FirstClosingDate,
      // to avoid error : A component is changing an uncontrolled input to be controlled
      // initialize managerRegulator to empty string
      managerRegulator: EMPTY_MANAGER_REGULATOR,
      managementCompanyId:
        user?.managementCompany?.type ===
        Web_Portal_ManagementCompanyType_Enum.GeneralPartner
          ? user?.managementCompany.id
          : '',
    },
  });
  const { handleSubmit } = methods;

  const onFormSubmit = (value: FundProfile) => {
    onSubmit(value);
  };

  return {
    onSubmit: handleSubmit(onFormSubmit),
    methods,
  };
};
