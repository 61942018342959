import { Divider, Stack, Grid } from '@mui/material';
import { useId } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { OnboardingSubscriptionTypeEnum } from 'generated/graphql';
import { getFieldName } from 'technical/get-field-name';
import { CheckBox } from 'ui/check-box';
import { FormInputCheckBox, FormInputText, FormToggleButton } from 'ui/form';
import { Option } from 'ui/types';

import { ShareForm } from './types';

const ToggleButtonTypeValues = Object.values(
  OnboardingSubscriptionTypeEnum,
).map((typeValue) => ({
  label: typeValue,
  value: typeValue,
}));

interface ShareFormPartProps extends Option {
  taxOption?: boolean;
  name: string;
  index?: number;
  onAdd: (value: ShareForm) => void;
  onRemove: (sharedId: string) => void;
  defaultShareValue: ShareForm;
}

export const ShareFormPart = ({
  index,
  value,
  name,
  onAdd,
  onRemove,
  defaultShareValue,
}: ShareFormPartProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const checkboxId = useId();

  const typeFieldName = getFieldName('type', name);

  const type = useWatch({ name: typeFieldName });
  const selected = index !== undefined;

  const onSelectedChange = () => {
    if (!selected) {
      onAdd(defaultShareValue);
      return;
    }
    onRemove(defaultShareValue.shareId);
  };

  return (
    <Stack alignItems="stretch">
      <Divider />
      <Grid container spacing={2} padding={2} alignItems="center">
        <Grid item md={2}>
          <CheckBox
            label={value}
            field={checkboxId}
            checked={selected}
            onChange={onSelectedChange}
          />
        </Grid>
        {selected ? (
          <>
            <Grid item md={2}>
              <FormInputCheckBox
                label={t('pages.fundManager.subscription.form.taxOption')}
                name={getFieldName('taxOption', name)}
              />
            </Grid>

            <Grid item md={2}>
              <FormToggleButton
                size="small"
                name={typeFieldName}
                options={ToggleButtonTypeValues.map(({ label, ...rest }) => ({
                  ...rest,
                  label: t(`pages.onboardingSubscription.type.${label}`),
                }))}
                onChange={(typeValue: string) => {
                  setValue(name, { ...defaultShareValue, type: typeValue });
                }}
                required
              />
            </Grid>

            <Grid item md={3}>
              {type === OnboardingSubscriptionTypeEnum.Range ? (
                <Stack direction="row" spacing={2}>
                  <FormInputText
                    size="small"
                    label={t(
                      'components.onboardingSubscriptionLpFormPart.amountMin',
                    )}
                    name={getFieldName('amountMin', name)}
                    required
                    type="number"
                  />
                  <FormInputText
                    size="small"
                    label={t(
                      'components.onboardingSubscriptionLpFormPart.amountMax',
                    )}
                    required
                    name={getFieldName('amountMax', name)}
                    type="number"
                  />
                </Stack>
              ) : (
                <FormInputText
                  label={t(
                    'components.onboardingSubscriptionLpFormPart.amount',
                  )}
                  name={getFieldName('amount', name)}
                  type="number"
                  size="small"
                  required
                />
              )}
            </Grid>
          </>
        ) : null}
      </Grid>
    </Stack>
  );
};
