import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { FundProfileData } from 'business/fund-manager/fund/dashboard/services/get-fund-profile-data';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { InfoCard } from 'ui/info-card';
import { PeriodBlock } from 'ui/period-block/period-block';

import styles from './index.module.scss';

interface Props {
  title: string;
  fundName: string;
  fundId: string;
  data?: FundProfileData;
}

export default function FundProfileCard({
  title,
  fundName,
  fundId,
  data,
}: Props) {
  const { t } = useTranslation();

  return (
    <InfoCard className={styles.stack} dataTestId="fund-profile-card">
      <InfoCard.Title
        path={FundManagerRoutes.FundIdEditProfile.replace(':fundId', fundId)}
        chipContent={data?.countPendingValidationShares}
      >
        {title}
      </InfoCard.Title>
      <InfoCard.Items>
        <Typography className={styles.text}>{fundName}</Typography>
      </InfoCard.Items>
      <Divider />
      {data?.periods ? (
        <Stack padding={3} spacing={2}>
          <PeriodBlock
            title={t('pages.dashboard.cards.fundProfile.investmentPeriod')}
            period={data.periods.investment}
          />
          <PeriodBlock
            title={t('pages.dashboard.cards.fundProfile.liquidationPeriod')}
            period={data.periods.liquidation}
          />
        </Stack>
      ) : null}
    </InfoCard>
  );
}
