import { Avatar, Stack } from '@mui/material';

import { Typo } from 'ui/typo';

type RiskAssessmentTableAverageCellProps = {
  title: string;
  description: string;
  displayedAverage: number | string;
  colorIndex: number;
  rowColors: { [key: number]: { text: string; background: string } };
};

export const RiskAssessmentTableAverageCell = ({
  title,
  description,
  displayedAverage,
  colorIndex,
  rowColors,
}: RiskAssessmentTableAverageCellProps) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Stack>
          <Typo size="2xl">{title}</Typo>
          <Typo size="sm">{description}</Typo>
        </Stack>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: rowColors[colorIndex].background,
            color: rowColors[colorIndex].text,
          }}
        >
          {displayedAverage}
        </Avatar>
      </Stack>
    </>
  );
};
