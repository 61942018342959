export interface PlotData {
  x?: number[] | string[];
  y?: number[] | string[];
  type: PlotChartTypeEnum;
  mode?: string;
  marker?:
    | { color: string }
    | {
        colors?: string[]; // WARNING CAN GENERATE COLORS OUT OF THE DEFINED ARRAY (USE colorway if possible)
        cornerradius: number;
      };
  labels?: string[];
  parents?: string[];
  values?: number[];
  textposition?: string;
  texttemplate?: string;
  hovertemplate?: string;
}

type Xaxis = {
  dtick?: string;
  showgrid?: boolean;
  anchor?: string;
  tickformat?: string;
  matches?: string;
  overlaying?: string;
  showticklabels?: boolean;
  rangeslider?: {
    thickness?: number;
  };
};

// NOTE: homemade typing, add whatever property you need if it does not exists yet
export interface PlotLayout {
  autosize?: boolean;
  width?: number;
  height?: number;
  title?:
    | string
    | {
        text?: string;
        automargin?: boolean;
        font?: {
          family?: string;
          size?: number;
          color?: string;
        };
        x?: number;
        y?: number;
        xref?: 'paper' | '/^x([2-9]|[1-9][0-9]+)?( domain)?$/';
        yref?: 'paper' | '/^x([2-9]|[1-9][0-9]+)?( domain)?$/';
      };
  annotations?: any;
  barmode?: string;
  bargap?: number;
  bargroupgap?: number;
  font?: {
    family?: string;
    size?: number;
    color?: string;
  };
  legend?: {
    orientation?: 'h' | 'v';
    x?: number;
    y?: number;
    xref?: 'paper' | '/^x([2-9]|[1-9][0-9]+)?( domain)?$/';
    yref?: 'paper' | '/^x([2-9]|[1-9][0-9]+)?( domain)?$/';
  };
  margin?: {
    t?: number; // TOP
    b?: number; // BOTTOM
    l?: number; // LEFT
    r?: number; // RIGHT
    pad?: number; // PADDING between the plotting area and the axis lines
  };
  padding?: {
    t?: number; // TOP
    b?: number; // BOTTOM
    l?: number; // LEFT
    r?: number; // RIGHT
    pad?: number; // PADDING between the plotting area and the axis lines
  };
  hovermode?: string;
  config?: { responsive: boolean };
  colorway?: string[];
  showlegend?: boolean;
  xaxis?: Xaxis;
  xaxis2?: Xaxis;
  yaxis?: {
    tickformat?: string;
    ticksuffix?: string;
    showgrid?: boolean;
    title?: string;
    showticklabels?: boolean;
    visible?: boolean;
  };
  hoverlabel?: {
    bgcolor?: string;
    font?: {
      family?: string;
      size?: number;
      color?: string;
    };
    bordercolor?: string;
  };
}

export enum PlotChartTypeEnum {
  scatter = 'scatter',
  bar = 'bar',
  treemap = 'treemap',
  pie = 'pie',
  indicator = 'indicator',
  funnel = 'funnel',
  funnelarea = 'funnelarea',
}

export enum PlotChartTextPosition {
  topLeft = 'top left',
  topCenter = 'top center',
  topRight = 'top right',
  middleLeft = 'middle left',
  middleCenter = 'middle center',
  middleRight = 'middle right',
  bottomLeft = 'bottom left',
  bottomCenter = 'bottom center',
  bottomRight = 'bottom right',
}
