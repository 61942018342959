import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { WebCompanyStatusEnum } from 'generated/graphql';
import { Select } from 'ui/select';
import { Option } from 'ui/types';

export type ComapnyStatus = Option<WebCompanyStatusEnum>;

interface CompanyStatusSelectorProps
  extends Omit<
    ComponentProps<typeof Select>,
    'onChange' | 'value' | 'label' | 'options'
  > {
  onChange: (status: WebCompanyStatusEnum | undefined) => void;
  value?: WebCompanyStatusEnum;
}

export const CompanyStatusSelector = ({
  onChange,
  value,
  size = 'small',
  ...rest
}: CompanyStatusSelectorProps) => {
  const { t } = useTranslation();
  const handleChange = (newValue: WebCompanyStatusEnum | null) => {
    if (!newValue) {
      onChange(undefined);
      return;
    }

    onChange(newValue);
  };

  const options = Object.values(WebCompanyStatusEnum)
    .map((status) => {
      return {
        id: status,
        value: t('pages.fundManager.portfolio.company.status', {
          context: status,
        }),
      };
    })
    .sort((a, b) => {
      if (a.value > b.value) {
        return -1;
      }
      if (a.value < b.value) {
        return 1;
      }
      return 0;
    });

  return (
    <Select<WebCompanyStatusEnum>
      {...rest}
      size={size}
      id="CompanyStatusSelector"
      label={t('pages.fundManager.portfolio.company.status')}
      value={value}
      options={options}
      disabled={false}
      onChange={handleChange}
    />
  );
};
