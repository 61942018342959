import { Navigate, Outlet, RouteObject } from 'react-router';

import {
  OnboardingSubscriptionPage,
  OnboardingSubscriptionsPage,
} from 'business/fund-manager/onboarding-subscription/components/onboarding-subscription-page/onboarding-subscription-page';
import { OnboardingSubscription } from 'business/fund-manager/onboarding-subscription/pages/onboarding-subscription/onboarding-subscription';
import { OnboardingSubscriptionList } from 'business/fund-manager/onboarding-subscription/pages/onboarding-subscription-list/onboarding-subscription-list';
import { OnboardingSubscriptionsCreationPage } from 'business/fund-manager/onboarding-subscription/pages/onboarding-subscriptions-create';
import { OnboardingSubscriptionNavBarTabEnum } from 'business/fund-manager/onboarding-subscription/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { ProtectedRoute } from 'business/user/helpers/protected-route-container';

export const onboardingSubscriptionRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.OnboardingSubscription,
    element: (
      <ProtectedRoute
        featureFlags={[FeatureFlagEnum.MODULE_ONBOARDING_SUBSCRIPTION]}
      >
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: FundManagerRoutes.OnboardingSubscriptionId,
        element: (
          <OnboardingSubscriptionPage>
            <OnboardingSubscription />
          </OnboardingSubscriptionPage>
        ),
      },
      {
        path: FundManagerRoutes.OnboardingSubscription,
        element: (
          <OnboardingSubscriptionsPage>
            <Outlet />
          </OnboardingSubscriptionsPage>
        ),
        children: [
          {
            path: FundManagerRoutes.OnboardingSubscriptionRequested,
            element: (
              <OnboardingSubscriptionList
                activeTab={OnboardingSubscriptionNavBarTabEnum.Requested}
              />
            ),
          },
          {
            path: FundManagerRoutes.OnboardingSubscriptionUnsuccessful,
            element: (
              <OnboardingSubscriptionList
                activeTab={OnboardingSubscriptionNavBarTabEnum.Unsuccessful}
              />
            ),
          },
          {
            path: FundManagerRoutes.OnboardingSubscriptionHistory,
            element: (
              <OnboardingSubscriptionList
                activeTab={OnboardingSubscriptionNavBarTabEnum.History}
              />
            ),
          },
          {
            path: FundManagerRoutes.OnboardingSubscriptionCreate,
            element: <OnboardingSubscriptionsCreationPage />,
          },
          {
            index: true,
            element: (
              <Navigate
                to={FundManagerRoutes.OnboardingSubscriptionRequested}
              />
            ),
          },
        ],
      },
    ],
  },
];
