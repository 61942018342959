import { useTranslation } from 'translations/hook';

import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { useIsLpUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-user';
import { DashboardLpUser } from 'business/lp-platform/home/components/dashboard-lp-user/dashboard-lp-user';
import { DashboardOnboardingUser } from 'business/lp-platform/home/components/dashboard-onboarding-user/dashboard-onboarding-user';
import { useDashBoardInfosQuery } from 'business/lp-platform/home/hooks/use-dash-board-infos-query';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  OnboardingSubscriptionStatusEnum,
  useLpOnboardingSubscriptionsQuery,
} from 'generated/graphql';
import { DashboardGrid } from 'ui/dashboard-grid/dashboard-grid';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

const DashboardLpUserLoader = () => {
  const { data, loading, error } = useDashBoardInfosQuery();

  if (loading || error || !data) {
    return (
      <div className={styles.gridFullLine}>
        <QueryStateDisplay loading={loading} error={error} />
      </div>
    );
  }

  return <DashboardLpUser data={data} />;
};

const activeOnboardingStatuses = Object.values(
  OnboardingSubscriptionStatusEnum,
).filter(
  (v: OnboardingSubscriptionStatusEnum) =>
    ![
      OnboardingSubscriptionStatusEnum.Activated,
      OnboardingSubscriptionStatusEnum.Rejected,
    ].includes(v),
);

const OnboardingDashboard = () => {
  const { data, loading, error } = useLpOnboardingSubscriptionsQuery({
    variables: {
      input: {
        onboardingStatus: activeOnboardingStatuses,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data) {
    return (
      <div className={styles.gridFullLine}>
        <QueryStateDisplay loading={loading} error={error} />
      </div>
    );
  }

  return (
    <div className={styles.gridFullLine}>
      <DashboardOnboardingUser data={data} />
    </div>
  );
};

export const Home = () => {
  const { t } = useTranslation();

  const isOnboardingUser = useIsLpOnboardingUser();
  const isOnboardingUserModuleAvailable = useFeatureFlag(
    FeatureFlagEnum.MODULE_ONBOARDING_SUBSCRIPTION,
  );
  const isLpUser = useIsLpUser();

  return (
    <ConnectedPage title={t('pages.dashboard.title')}>
      <DashboardGrid>
        {isOnboardingUserModuleAvailable && isOnboardingUser ? (
          <OnboardingDashboard />
        ) : null}
        {isLpUser ? <DashboardLpUserLoader /> : null}
      </DashboardGrid>
    </ConnectedPage>
  );
};
