import { SvgIcon } from '@mui/material';

import { SvgProps } from './types/props';

export const DrawdownMenuIcon = ({
  primaryColor,
  secondaryColor,
}: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M19.4621 3.98171H20.8999C21.1936 3.98171 21.4255 3.76142 21.4255 3.49325C21.4255 3.22507 21.1936 3 20.8999 3H18.1067C17.8181 3 17.5811 3.22029 17.5811 3.48846V6.084C17.5811 6.35696 17.8181 6.57246 18.1067 6.57246C18.3953 6.57246 18.6324 6.35217 18.6324 6.084V4.59468C23.0694 8.80884 23.0437 15.585 18.5499 19.7657C18.3438 19.9572 18.3438 20.2685 18.5499 20.46C18.756 20.6516 19.091 20.6516 19.2971 20.46C24.1826 15.925 24.229 8.57897 19.4621 3.98171Z"
        fill={primaryColor}
        stroke={primaryColor}
        strokeWidth="0.4"
      />
      <path
        d="M4.70099 4.01732C-0.199916 8.57148 -0.230837 15.951 4.59276 20.5483H3.07251C2.77876 20.5483 2.54686 20.7686 2.54686 21.0368C2.54686 21.3097 2.78391 21.5252 3.07251 21.5252H5.86566C6.1594 21.5252 6.39131 21.3049 6.39131 21.0368V18.4412C6.39131 18.1683 6.15425 17.9528 5.86566 17.9528C5.57707 17.9528 5.34001 18.1731 5.34001 18.4412V19.8491C0.928683 15.635 0.959604 8.87318 5.44823 4.70691C5.65437 4.51535 5.65437 4.20408 5.44823 4.01253C5.2421 3.82576 4.90712 3.82576 4.70099 4.01732Z"
        fill={primaryColor}
        stroke={primaryColor}
        strokeWidth="0.4"
      />
      <ellipse
        cx="12.5496"
        cy="13.0211"
        rx="5.66191"
        ry="5.19008"
        fill={secondaryColor}
      />
      <path
        d="M12.2108 14.969C11.6606 14.969 11.2031 14.816 10.8384 14.51C10.4737 14.2041 10.2604 13.7894 10.1986 13.2659C10.1986 13.2659 10.1728 13.0152 10.5844 13.0152C10.996 13.0152 10.996 13.2659 10.996 13.2659C11.0455 13.531 11.1722 13.7622 11.3762 13.9593C11.5864 14.1497 11.8677 14.2449 12.2201 14.2449C12.5477 14.2449 12.7888 14.1701 12.9434 14.0205C13.0979 13.8642 13.1752 13.6806 13.1752 13.4698C13.1752 13.1639 13.0732 12.9599 12.8692 12.8579C12.6714 12.756 12.3901 12.6642 12.0253 12.5826C11.7781 12.5282 11.5308 12.45 11.2835 12.348C11.0362 12.246 10.8291 12.1033 10.6622 11.9197C10.4953 11.7293 10.4118 11.4812 10.4118 11.1752C10.4118 10.7333 10.5602 10.373 10.8569 10.0942C11.1599 9.80868 11.5679 9.6659 12.081 9.6659C12.5694 9.6659 12.9681 9.80188 13.2772 10.0738C13.5925 10.339 13.7748 10.7197 13.8243 11.216C13.8243 11.216 13.8243 11.4195 13.4998 11.4336C13.1752 11.4477 13.0546 11.216 13.0546 11.216C13.0237 10.9577 12.9217 10.7571 12.7486 10.6143C12.5817 10.4648 12.3561 10.39 12.0717 10.39C11.7935 10.39 11.5771 10.4546 11.4226 10.5837C11.2742 10.7129 11.2 10.8829 11.2 11.0936C11.2 11.2976 11.2959 11.4574 11.4875 11.573C11.6853 11.6885 11.9512 11.7871 12.285 11.8687C12.5694 11.9367 12.8383 12.0217 13.0917 12.1237C13.3514 12.2188 13.5616 12.365 13.7223 12.5622C13.8892 12.7526 13.9727 13.0313 13.9727 13.3984C13.9789 13.854 13.8212 14.2313 13.4998 14.5304C13.1845 14.8228 12.7548 14.969 12.2108 14.969Z"
        fill="#363636"
      />
      <path
        d="M11.7423 9.43331C11.7423 9.20209 11.9151 9.01465 12.1283 9.01465C12.3415 9.01465 12.5143 9.20209 12.5143 9.43331V9.71242C12.5143 9.94364 12.3415 10.1311 12.1283 10.1311C11.9151 10.1311 11.7423 9.94364 11.7423 9.71242V9.43331Z"
        fill="#363636"
      />
      <path
        d="M11.7423 14.9224C11.7423 14.6912 11.9151 14.5038 12.1283 14.5038C12.3415 14.5038 12.5143 14.6912 12.5143 14.9224V15.2015C12.5143 15.4328 12.3415 15.6202 12.1283 15.6202C11.9151 15.6202 11.7423 15.4328 11.7423 15.2015V14.9224Z"
        fill="#363636"
      />
      <path
        d="M12.2108 14.969C11.6606 14.969 11.2031 14.816 10.8384 14.51C10.4737 14.2041 10.2604 13.7894 10.1986 13.2659C10.1986 13.2659 10.1728 13.0152 10.5844 13.0152C10.996 13.0152 10.996 13.2659 10.996 13.2659C11.0455 13.531 11.1722 13.7622 11.3762 13.9593C11.5864 14.1497 11.8677 14.2449 12.2201 14.2449C12.5477 14.2449 12.7888 14.1701 12.9434 14.0205C13.0979 13.8642 13.1752 13.6806 13.1752 13.4698C13.1752 13.1639 13.0732 12.9599 12.8692 12.8579C12.6714 12.756 12.3901 12.6642 12.0253 12.5826C11.7781 12.5282 11.5308 12.45 11.2835 12.348C11.0362 12.246 10.8291 12.1033 10.6622 11.9197C10.4953 11.7293 10.4118 11.4812 10.4118 11.1752C10.4118 10.7333 10.5602 10.373 10.8569 10.0942C11.1599 9.80868 11.5679 9.6659 12.081 9.6659C12.5694 9.6659 12.9681 9.80188 13.2772 10.0738C13.5925 10.339 13.7748 10.7197 13.8243 11.216C13.8243 11.216 13.8243 11.4195 13.4998 11.4336C13.1752 11.4477 13.0546 11.216 13.0546 11.216C13.0237 10.9577 12.9217 10.7571 12.7486 10.6143C12.5817 10.4648 12.3561 10.39 12.0717 10.39C11.7935 10.39 11.5771 10.4546 11.4226 10.5837C11.2742 10.7129 11.2 10.8829 11.2 11.0936C11.2 11.2976 11.2959 11.4574 11.4875 11.573C11.6853 11.6885 11.9512 11.7871 12.285 11.8687C12.5694 11.9367 12.8383 12.0217 13.0917 12.1237C13.3514 12.2188 13.5616 12.365 13.7223 12.5622C13.8892 12.7526 13.9727 13.0313 13.9727 13.3984C13.9789 13.854 13.8212 14.2313 13.4998 14.5304C13.1845 14.8228 12.7548 14.969 12.2108 14.969Z"
        fill="none"
        stroke="#363636"
        strokeWidth="0.466298"
      />
      <path
        d="M11.7423 9.43331C11.7423 9.20209 11.9151 9.01465 12.1283 9.01465C12.3415 9.01465 12.5143 9.20209 12.5143 9.43331V9.71242C12.5143 9.94364 12.3415 10.1311 12.1283 10.1311C11.9151 10.1311 11.7423 9.94364 11.7423 9.71242V9.43331Z"
        fill="none"
        stroke="#363636"
        strokeWidth="0.466298"
      />
      <path
        d="M11.7423 14.9224C11.7423 14.6912 11.9151 14.5038 12.1283 14.5038C12.3415 14.5038 12.5143 14.6912 12.5143 14.9224V15.2015C12.5143 15.4328 12.3415 15.6202 12.1283 15.6202C11.9151 15.6202 11.7423 15.4328 11.7423 15.2015V14.9224Z"
        fill="none"
        stroke="#363636"
        strokeWidth="0.466298"
      />
      <circle
        cx="12"
        cy="12"
        r="6.25"
        fill="none"
        stroke="#363636"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
