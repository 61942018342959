import { Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { replaceRouteWithPrefix } from 'business/fund-manager/settings/shared/hooks/route-prefix-utils';
import { LinkTab } from 'ui/link-tab';

export enum DocumentationTab {
  Lp = 0,
  Fund = 1,
}

interface Props {
  activeTab: DocumentationTab;
  routePrefix: string;
  button: JSX.Element;
}

export const DocumentationNavBar = ({
  activeTab,
  routePrefix,
  button,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Tabs value={activeTab}>
        <LinkTab
          label={t('pages.fundManager.settings.documentType.tab.lp')}
          to={replaceRouteWithPrefix(
            FundManagerRoutes.SettingsLpDocumentationType,
            routePrefix,
          )}
        />
        <LinkTab
          label={t('pages.fundManager.settings.documentType.tab.fund')}
          to={replaceRouteWithPrefix(
            FundManagerRoutes.SettingsFundDocumentationType,
            routePrefix,
          )}
        />
      </Tabs>
      <Stack sx={{ width: '20%' }}> {button}</Stack>
    </Stack>
  );
};
