/* eslint-disable import/no-extraneous-dependencies */
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import EN from './en.json';
import FR from './fr.json';
import { LanguageEnum } from './types';

const resources = {
  en: {
    translation: EN,
  },
  fr: {
    translation: FR,
  },
};

countries.registerLocale(enLocale);
countries.registerLocale(frLocale);

i18n
  .use(LanguageDetector) // detect browser language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['navigator'],
    },
    resources,
    fallbackLng: LanguageEnum.en,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { i18n };
