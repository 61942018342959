import { ApolloError } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { InstrumentTransactionEditionModal } from 'business/fund-manager/portfolio/inventory/components/edition-modal';
import { getInstrumentColumnDefinition } from 'business/fund-manager/portfolio/inventory/services/get-instrument-column-definition';
import { GetPortfolioInventoryDetailsQuery } from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { AlternativeTable } from 'ui/alternative-table';
import { CustomModal } from 'ui/custom-modal';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props {
  data?: GetPortfolioInventoryDetailsQuery;
  loading?: boolean;
  error?: ApolloError;
}

export type PortfolioInstrumentTransaction =
  GetPortfolioInventoryDetailsQuery['transactions'][number];

export const InstrumentTable = ({ data, loading, error }: Props) => {
  const { t } = useTranslation();

  const [instrumentTransaction, setInstrumentTransaction] = useState<
    PortfolioInstrumentTransaction | undefined
  >();

  const { open, handleOpenModal, handleCloseModal } = useModal();

  const handleOpenInstrumentTransactionEditionModal = (
    transaction: PortfolioInstrumentTransaction,
  ) => {
    setInstrumentTransaction(transaction);
    handleOpenModal();
  };

  const headers = getInstrumentColumnDefinition({
    t,
    handleOpenModal: handleOpenInstrumentTransactionEditionModal,
    lastValuationDate: data?.lastValuation?.date,
  });

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  return (
    <>
      <AlternativeTable<
        GetPortfolioInventoryDetailsQuery['transactions'][number]
      >
        fileName="company-instrument-stats"
        autoGroupColumnDef={{
          headerName: t(
            'pages.fundManager.portfolio.inventory.table.companyName',
          ),
          pinned: 'left', //force pinned left. Does not work in columnDef
        }}
        rowData={data?.transactions ?? []}
        columnDefs={headers}
        domLayout="autoHeight"
        displaySidebar
        groupDisplayType={'multipleColumns'}
        suppressAggFuncInHeader={true}
        expandable
        loading={loading}
        hideSearchbar
      />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <InstrumentTransactionEditionModal
            initialValues={instrumentTransaction}
            lastValuationDate={data?.lastValuation?.date}
            handleClose={handleCloseModal}
          />
        }
      />
    </>
  );
};
