import { LinkedIn } from '@mui/icons-material';
import { Link, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { InfoItem } from 'ui/info-item';

import styles from './index.module.scss';

type LegalRepresentative = {
  fullName?: string | null;
  profilePicturePath?: string | null;
  linkedinUrl?: string | null;
};

interface Props {
  data: LegalRepresentative;
}

export default function LegalRepresentativeItem({
  data: { fullName, profilePicturePath, linkedinUrl },
}: Props) {
  const { t } = useTranslation();

  const value = (
    <Stack direction={'row'} className={styles.stack}>
      {fullName}
      <Link href={linkedinUrl ?? ''} target="_blank" rel="noopener">
        <LinkedIn color="action" className={styles.icon} />
      </Link>
    </Stack>
  );

  return (
    <InfoItem
      value={value}
      description={t('pages.dashboard.cards.contacts.legalRepresentative')}
      imageSource={profilePicturePath ?? ''}
    />
  );
}
