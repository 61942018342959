import { Navigate, Outlet, RouteObject } from 'react-router';

import { CompanyEditionTabs } from 'business/fund-manager/portfolio/company/company-edition-tabs';
import { CompanyContacts } from 'business/fund-manager/portfolio/company/pages/company-contacts';
import { CompanyCreation } from 'business/fund-manager/portfolio/company/pages/company-creation';
import { CompanyCreationContact } from 'business/fund-manager/portfolio/company/pages/company-creation-contact';
import { CompanyCreationDocumentation } from 'business/fund-manager/portfolio/company/pages/company-creation-documentation';
import { CompanyCreationInvestments } from 'business/fund-manager/portfolio/company/pages/company-creation-investments';
import { CompanyDashboard } from 'business/fund-manager/portfolio/company/pages/company-dashboard';
import { CompanyInvestmentsEdition } from 'business/fund-manager/portfolio/company/pages/company-investments-edition';
import { CompanyList } from 'business/fund-manager/portfolio/company/pages/company-list';
import { CompanyMetrics } from 'business/fund-manager/portfolio/company/pages/company-metrics';
import { CompanyProfileEdition } from 'business/fund-manager/portfolio/company/pages/company-profile-edition';
import { PortfolioPage } from 'business/fund-manager/portfolio/components/portfolio-page';
import { InventoryDashboard } from 'business/fund-manager/portfolio/inventory/pages/inventory-dashboard';
import { InventoryDetails } from 'business/fund-manager/portfolio/inventory/pages/inventory-details';
import { PortfolioValuation } from 'business/fund-manager/portfolio/valuation/pages/portfolio-valuation';
import { PortfolioValuationHistory } from 'business/fund-manager/portfolio/valuation/pages/portfolio-valuation-history';
import { PortfolioValuationOverview } from 'business/fund-manager/portfolio/valuation/pages/portfolio-valuation-overview';
import FundManagerRoutes from 'business/fund-manager/router/routes';

export const portfolioRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.Portfolio,
    element: (
      <PortfolioPage>
        <Outlet />
      </PortfolioPage>
    ),
    children: [
      {
        path: FundManagerRoutes.PortfolioFundId,
        element: (
          <Navigate
            to={`${FundManagerRoutes.PortfolioFundId}/inventory`}
            replace
          />
        ),
      },
      {
        path: FundManagerRoutes.PortfolioFundIdInventory,
        element: <InventoryDashboard />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdInventoryDetails,
        element: <InventoryDetails />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompany,
        element: <CompanyList />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyId,
        element: <CompanyDashboard />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyId,
        element: (
          <CompanyEditionTabs>
            <Outlet />
          </CompanyEditionTabs>
        ),
        children: [
          {
            path: FundManagerRoutes.PortfolioFundIdCompanyIdEditProfile,
            element: <CompanyProfileEdition />,
          },
          {
            path: FundManagerRoutes.PortfolioFundIdCompanyIdEditInvestment,
            element: <CompanyInvestmentsEdition />,
          },
        ],
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyIdMetrics,
        element: <CompanyMetrics />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyIdEditContact,
        element: <CompanyContacts />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyCreate,
        element: <CompanyCreation />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyCreateId,
        element: <CompanyCreation />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyCreateIdInvestments,
        element: <CompanyCreationInvestments />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyCreateIdDocumentation,
        element: <CompanyCreationDocumentation />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdCompanyCreateIdContact,
        element: <CompanyCreationContact />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdValuation,
        element: <PortfolioValuation />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdValuationId,
        element: <PortfolioValuationOverview />,
      },
      {
        path: FundManagerRoutes.PortfolioFundIdValuationHistory,
        element: <PortfolioValuationHistory />,
      },
    ],
  },
];
