import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyProfileForm } from 'business/fund-manager/portfolio/company/components/company-profile-form';
import { useCompanyProfileForm } from 'business/fund-manager/portfolio/company/services/hooks/use-company-profile-form';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { CompanyProfileQuery, useCompanyProfileQuery } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { QueryStateDisplay } from 'ui/query-state-display';

interface CompanyProfileEditionFormProps {
  fundId: string;
  companyId: string;
  company: NonNullable<CompanyProfileQuery['company']>;
}

const CompanyProfileEditionForm = ({
  fundId,
  companyId,
  company,
}: CompanyProfileEditionFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    onSubmit,
    control,
    loading,
    error,
    onFileUpload,
    existingLogo,
    onExistingLogoDelete,
  } = useCompanyProfileForm({
    companyId,
    fundId,
    company,
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const action = (
    <ActionButton variant="submit" type="submit" disabled={loading}>
      {t('common.actions.save')}
    </ActionButton>
  );

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <CompanyProfileForm
          action={action}
          control={control}
          error={error}
          edition
          onFileUpload={onFileUpload}
          onExistingLogoDelete={onExistingLogoDelete}
          existingLogo={existingLogo}
        />
        {action}
      </Stack>
    </form>
  );
};

export const CompanyProfileEdition = () => {
  const { companyId = '', fundId = '' } = useParams();
  const { loading, error, data } = useCompanyProfileQuery({
    variables: { id: companyId },
  });

  if (loading || error || !data?.company) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }
  return (
    <CompanyProfileEditionForm
      companyId={companyId}
      company={data.company}
      fundId={fundId}
    />
  );
};
