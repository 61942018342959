import { Paper, Stack } from '@mui/material';
import englishFlag from 'images/flag/english.png';
import frenchFlag from 'images/flag/french.png';
import { LanguageEnum } from 'translations/types';

import { useLanguageContext } from 'business/providers/language-provider';
import { LanguageItem } from 'ui/language-item';

import styles from './index.module.scss';

const languages: {
  code: LanguageEnum;
  label: string;
  picturePath: string;
}[] = [
  {
    code: LanguageEnum.fr,
    label: 'FR',
    picturePath: frenchFlag,
  },
  {
    code: LanguageEnum.en,
    label: 'EN',
    picturePath: englishFlag,
  },
];

export interface LanguageSelectorProps {
  collapsed: boolean;
}
export function LanguageSelector({ collapsed }: LanguageSelectorProps) {
  const { locale: currentLanguage, setLocale } = useLanguageContext();

  return (
    <Paper>
      <Stack className={styles.stack} direction="row">
        {!collapsed
          ? languages
              .filter(
                (language) =>
                  language.code.includes(currentLanguage) && !collapsed,
              )
              .map((language) => (
                <LanguageItem
                  selected={language.code.includes(currentLanguage)}
                  key={language.code}
                  value={language.code}
                  label={language.label}
                  picturePath={language.picturePath}
                  onChange={setLocale}
                  collapsed={collapsed}
                />
              ))
          : languages.map((language) => (
              <LanguageItem
                selected={language.code.includes(currentLanguage)}
                key={language.code}
                value={language.code}
                label={language.label}
                picturePath={language.picturePath}
                onChange={setLocale}
                collapsed={collapsed}
              />
            ))}
      </Stack>
    </Paper>
  );
}
