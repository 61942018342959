import { LoginCard } from 'business/user/components/login-card';
import PageAuth from 'business/user/pages/page-auth';

import styles from './index.module.scss';

export const SignInPage = () => {
  return (
    <PageAuth>
      <LoginCard className={styles.loginCard} />
    </PageAuth>
  );
};
