import { Stack } from '@mui/material';
import { FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { RiskAssessmentForm } from 'business/fund-manager/lp/components/risk-assessment-form';
import { RiskAssessmentTable } from 'business/fund-manager/lp/components/risk-assessment-table';
import { createAmlTableRows } from 'business/fund-manager/lp/services/create-aml-table-rows';
import { getCountryRiskRegion } from 'business/fund-manager/lp/services/get-country-risk';
import { useAmlForm } from 'business/fund-manager/lp/services/hooks/use-aml-form';
import {
  LpAmlRiskForm,
  RiskData,
} from 'business/fund-manager/lp/services/types';
import {
  GetLpCountryQuery,
  LpRiskAssessmentsQuery,
  RiskAssessmentListQuery,
  useGetLpCountryQuery,
  useLpRiskAssessmentsQuery,
  useRiskAssessmentListQuery,
} from 'generated/graphql';
import { FormCard } from 'ui/form';
import { InfoCard } from 'ui/info-card';
import { QueryStateDisplay } from 'ui/query-state-display';
import Spacer from 'ui/spacer';
import { ValidationButton } from 'ui/validation-button';

const getDefaultValues = (lpRiskAssessments?: LpRiskAssessmentsQuery) => {
  return {
    interactionsWithInvestors:
      lpRiskAssessments?.interactionsWithInvestors?.id ?? '',
    lpTransparency: lpRiskAssessments?.lpTransparency?.id ?? '',
    lpNature: lpRiskAssessments?.lpNature?.id ?? '',
    structureTransparency: lpRiskAssessments?.structureTransparency?.id ?? '',
  };
};

interface LpAmlRiskAssessmentFormProps {
  riskAssessmentsByType: RiskAssessmentListQuery;
  lpRiskAssessments?: LpRiskAssessmentsQuery;
  lpCountryCode: GetLpCountryQuery['web_portal_lpView'][0]['country'];
  onSubmit: (riskAssessmentIds: string[]) => Promise<unknown>;
}
const LpAmlRiskAssessmentForm = ({
  riskAssessmentsByType,
  lpRiskAssessments,
  lpCountryCode,
  onSubmit,
}: LpAmlRiskAssessmentFormProps) => {
  const { t } = useTranslation();
  const defaultValues = getDefaultValues(lpRiskAssessments);

  const onFormSubmit = (formValues: LpAmlRiskForm) => {
    const countryRiskRegion = getCountryRiskRegion(lpCountryCode);

    const lpCountry = riskAssessmentsByType?.lpCountry.find(
      (country) => country.fieldName === countryRiskRegion,
    );

    const riskAssessmentIds = Object.values(formValues);
    if (lpCountry) {
      riskAssessmentIds.push(lpCountry.id);
    }
    const nonEmptyRiskAssessments = riskAssessmentIds.filter(
      (riskAssessment) => !!riskAssessment,
    );

    return onSubmit(nonEmptyRiskAssessments);
  };

  const { methods, onSubmit: handleSubmit } = useAmlForm({
    onSubmit: onFormSubmit,
    defaultValues,
  });

  const riskAssessmentFormValues = useWatch({
    control: methods.control,
    defaultValue: defaultValues,
  });

  const rows: RiskData[] = createAmlTableRows({
    riskAssessmentFormValues,
    lpCountryCode,
    data: riskAssessmentsByType,
  });

  return (
    <Stack spacing={2}>
      <Spacer justify="end">
        <ValidationButton
          color="validate"
          onClick={handleSubmit}
          loading={methods.formState.isSubmitting}
        >
          {t('pages.fundManager.lpCreation.aml.actions.validate')}
        </ValidationButton>
      </Spacer>

      <Stack spacing={2}>
        <FormCard>
          <FormProvider {...methods}>
            <RiskAssessmentForm
              control={methods.control}
              data={riskAssessmentsByType}
            />
          </FormProvider>
        </FormCard>

        <InfoCard>
          <InfoCard.Title>
            {t('pages.fundManager.lpCreation.aml.table.title')}
          </InfoCard.Title>

          <RiskAssessmentTable rows={rows} />
        </InfoCard>
      </Stack>
    </Stack>
  );
};

interface LpAmlRiskAssessmentProps
  extends Pick<LpAmlRiskAssessmentFormProps, 'onSubmit'> {
  lpId: string;
}

export const LpAmlRiskAssessment = ({
  lpId,
  onSubmit,
}: LpAmlRiskAssessmentProps) => {
  const {
    data: lpCountryCode,
    loading: lpCountryCodeLoading,
    error: lpCountryCodeError,
  } = useGetLpCountryQuery({ variables: { lpId } });

  const {
    data: riskAssessmentsByType,
    loading,
    error,
  } = useRiskAssessmentListQuery();

  const {
    data: lpRiskAssessments,
    loading: lpRiskAssessmentsLoading,
    error: lpRiskAssessmentsError,
  } = useLpRiskAssessmentsQuery({
    variables: { lpId },
    fetchPolicy: 'network-only',
  });

  if (
    loading ||
    error ||
    !riskAssessmentsByType ||
    !lpId ||
    lpCountryCodeLoading ||
    lpCountryCodeError ||
    !lpCountryCode?.web_portal_lpView ||
    lpRiskAssessmentsLoading ||
    lpRiskAssessmentsError
  ) {
    return (
      <QueryStateDisplay
        loading={loading || lpCountryCodeLoading || lpRiskAssessmentsLoading}
        error={error || lpCountryCodeError || lpRiskAssessmentsError}
      />
    );
  }

  return (
    <LpAmlRiskAssessmentForm
      riskAssessmentsByType={riskAssessmentsByType}
      lpRiskAssessments={lpRiskAssessments}
      lpCountryCode={lpCountryCode.web_portal_lpView?.[0].country}
      onSubmit={onSubmit}
    />
  );
};
