import { Stack } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useRef, useState } from 'react';
import { useTranslation } from 'translations/hook';

import { getLocalDocumentType } from 'business/localisation-helper';
import { DocumentDownloadButton } from 'business/lp-platform/document/components/download-button';
import styles from 'business/lp-platform/document/pages/index.module.scss';
import { buildDocumentClauses } from 'business/lp-platform/document/services/document-clause-builder';
import { getDocumentColumnDefinition } from 'business/lp-platform/document/services/get-document-column-definition';
import { isGetDocumentsQuery } from 'business/lp-platform/document/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import { GetDocumentsQuery, useGetDocumentsQuery } from 'generated/graphql';
import { DEFAULT_WHERE_CLAUSE } from 'technical/filter/constants';
import { handleMultipleFilter } from 'technical/filter/filters';
import { FuzzyFilterDataKey } from 'technical/filter/fuzzy-filter/constants';
import { handleFuzzyFiltering } from 'technical/filter/fuzzy-filter/fuzzy-filter';
import { Table, ToolbarContainer } from 'ui/table';

const MAX_DOCUMENT_DOWNLOADS = 4;

const Document = () => {
  const { t } = useTranslation();
  const [searchedTerm, setSearchedTerm] = useState('');
  const [whereClause, setWhereClause] = useState(DEFAULT_WHERE_CLAUSE);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const headers: GridColDef<GetDocumentsQuery['documents'][number]>[] =
    getDocumentColumnDefinition(t);

  const toolbarRef = useRef<HTMLDivElement>(null);

  const { data, loading, refetch } = useGetDocumentsQuery();

  const rows =
    handleFuzzyFiltering({
      data,
      searchedTerm,
      dataKeys: [FuzzyFilterDataKey.Documents],
      enabledColumns: [getLocalDocumentType()],
    }) || data;

  if (whereClause) {
    refetch(whereClause);
  }

  return (
    <ConnectedPage title={t('pages.document.title')}>
      <Stack spacing={2}>
        <ToolbarContainer
          toolbarRef={toolbarRef}
          filterPlaceholders={[t('pages.document.table.type')]}
        />
        <Stack className={styles.downloadButtonContainer}>
          <DocumentDownloadButton
            documentIds={rowSelectionModel.map((item) => item.toString())}
            disabled={
              !rowSelectionModel.length ||
              rowSelectionModel.length > MAX_DOCUMENT_DOWNLOADS
            }
          />
        </Stack>
        <Table<GetDocumentsQuery['documents'][number]>
          toolbarAnchor={toolbarRef.current}
          columns={headers}
          rows={rows && isGetDocumentsQuery(rows) ? rows.documents : []}
          getRowId={(row) => row.id ?? ''}
          loading={loading}
          onFilterModelChange={(model) => {
            handleMultipleFilter(
              model,
              setWhereClause,
              buildDocumentClauses,
              setSearchedTerm,
            );
          }}
          filterMode="server"
          checkboxSelection
          onRowSelectionModelChange={(row) => {
            setRowSelectionModel(row);
          }}
        />
      </Stack>
    </ConnectedPage>
  );
};

export default Document;
