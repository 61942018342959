import { ApolloError } from '@apollo/client';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { i18n } from 'translations';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { ValidationErrors } from 'technical/validation/types';

export const OnErrorDisplaySnackbar = (error: ApolloError) => {
  const errorMsg = i18n.exists(error?.message)
    ? t(error?.message)
    : t(ValidationErrors.GENERIC);
  enqueueSnackbar(errorMsg, {
    variant: VariantTypeEnum.ERROR,
  });
};
