import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { LpFormPart } from 'business/fund-manager/onboarding-subscription/components/lp-form-part/lp-form-part';
import {
  CreateNewLpInOnboardingForm,
  isLPFromActiveFundsAndLpsQuery,
  OnboardingSubscriptionsForm,
} from 'business/fund-manager/onboarding-subscription/services/types';
import { onboardingSubscriptionsFormSchema } from 'business/fund-manager/onboarding-subscription/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  ActiveFundsAndLpsQuery,
  OnboardingSubscriptionTypeEnum,
  SubscriptionInput,
  useCreateOnboardingSubscriptionsMutation,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ConfirmationModal } from 'ui/confirmation-modal';
import { SectionTitle } from 'ui/section-title';
import { Option } from 'ui/types';

interface OnboardingSubscriptionsCreationFormProps {
  fund: ActiveFundsAndLpsQuery['activeFundsAndLps']['funds'][number];
  shares: Option[];
  lps: (
    | ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number]
    | CreateNewLpInOnboardingForm
  )[];
  defaultValues: OnboardingSubscriptionsForm;
  onCancel: () => void;
}

export const OnboardingSubscriptionsCreationForm = ({
  fund,
  shares,
  lps,
  defaultValues,
  onCancel,
}: OnboardingSubscriptionsCreationFormProps) => {
  const [subscriptions, setSubscriptions] = useState<
    SubscriptionInput[] | undefined
  >();
  const onCancelConfirm = () => setSubscriptions(undefined);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const methods = useForm<OnboardingSubscriptionsForm>({
    defaultValues,
    resolver: yupResolver(onboardingSubscriptionsFormSchema),
  });

  const onOpenConfirmationModal = (formValues: OnboardingSubscriptionsForm) => {
    const subs = formValues.subscriptions.flatMap((sub) =>
      sub.shares.map(
        ({
          type,
          amount,
          amountMax,
          amountMin,
          ...rest
        }): SubscriptionInput => {
          const lp = lps.find(
            (value) =>
              value.subscribingEntityName === sub.subscribingEntityName,
          );

          return {
            ...rest,
            lpId: lp && isLPFromActiveFundsAndLpsQuery(lp) ? lp.id : undefined,
            lp: lp && !isLPFromActiveFundsAndLpsQuery(lp) ? lp : undefined,
            type,
            ...(type === OnboardingSubscriptionTypeEnum.Fixed
              ? { amount }
              : { amountMax, amountMin }),
          };
        },
      ),
    );
    setSubscriptions(subs);
  };

  const [createOnboardingSubscriptions, { loading: saving }] =
    useCreateOnboardingSubscriptionsMutation({
      onError: (err) => {
        enqueueSnackbar(t(err.message), {
          variant: VariantTypeEnum.ERROR,
        });
      },
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.createOnboardingSubscriptions'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        navigate(FundManagerRoutes.OnboardingSubscription);
      },
    });

  const onConfirm = (confirmedSubs: SubscriptionInput[]) =>
    createOnboardingSubscriptions({
      variables: {
        input: {
          fundId: fund.id,
          subscriptions: confirmedSubs,
        },
      },
      // react-hook-form bad error management
    }).catch(() => undefined);

  const canSubmit = !subscriptions && !saving;

  return (
    <>
      <form
        onSubmit={
          canSubmit ? methods.handleSubmit(onOpenConfirmationModal) : undefined
        }
      >
        <FormProvider {...methods}>
          <Stack gap={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <SectionTitle title={fund.name} />
              <Stack direction="row" spacing={2}>
                <ActionButton variant="secondary" onClick={onCancel}>
                  {t('common.actions.cancel')}
                </ActionButton>
                <ActionButton
                  variant="submit"
                  type="submit"
                  disabled={!!subscriptions}
                  loading={methods.formState.isSubmitting}
                >
                  {t(
                    'pages.fundManager.onboardingSubscriptionCreate.form.submit',
                  )}
                </ActionButton>
              </Stack>
            </Stack>

            {lps.map((lp, index) => (
              <LpFormPart
                key={`lp-form-part-${lp.subscribingEntityName}`}
                lp={lp}
                index={index}
                shares={shares}
              />
            ))}
          </Stack>
        </FormProvider>
      </form>
      {subscriptions ? (
        <ConfirmationModal
          title={t(
            'pages.fundManager.onboardingSubscriptionCreate.form.submit',
          )}
          confirming={saving}
          onCancel={onCancelConfirm}
          onConfirm={() => onConfirm(subscriptions)}
          confirmationLabel={t(
            'pages.fundManager.onboardingSubscriptionCreate.confirm.action',
            { count: subscriptions.length },
          )}
        >
          <Stack spacing={2} alignItems="center">
            <span>
              {t(
                'pages.fundManager.onboardingSubscriptionCreate.confirm.label',
                {
                  count: subscriptions.length,
                },
              )}
            </span>

            <span>
              {t(
                'pages.fundManager.onboardingSubscriptionCreate.confirm.labelConfirm',
                {
                  count: subscriptions.length,
                },
              )}
            </span>
          </Stack>
        </ConfirmationModal>
      ) : null}
    </>
  );
};
