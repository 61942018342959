import { addNumbersOrNull } from 'technical/number/add-numbers-or-null';

import { ResidualValueComputationValues } from './types';

/**
 * Calculate the residual value of a share valuation by adding and subtracting gains and expenses to the previous valuation
 * Mirror of the backend function
 * @example
 * Returns:
 * lastResidualValue
 *  + totalDrawn + interestIncome + dividendIncome + otherIncomeExpense + accruedCarriedInterest + realizedPortfolio + unrealizedPortfolio
 *  - distributed - distributedRecallable - managementFees - interestExpense - partnershipExpenses - totalOffsetsToFeesExpenses
 */
export const computeResidualValue = (data: ResidualValueComputationValues) => {
  return (
    addNumbersOrNull([
      data.lastResidualValue,
      data.totalDrawn,
      data.interestIncome,
      data.dividendIncome,
      data.otherIncomeExpense,
      data.accruedCarriedInterest,
      data.realizedPortfolio,
      data.unrealizedPortfolio,
    ]) -
    addNumbersOrNull([
      data.totalDistributedNonRecallable,
      data.totalDistributedRecallable,
      data.managementFees,
      data.interestExpense,
      data.partnershipExpenses,
      data.totalOffsetsToFeesExpenses,
    ])
  );
};
