import { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { useCompanyContactDocumentUpload } from 'business/fund-manager/portfolio/company/services/hooks/use-company-contact-document-upload';
import { FileFormData } from 'business/fund-manager/shared/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { CompanyContactForm } from 'business/shared/components/company-contact-form/company-contact-form';
import { ContactListManager } from 'business/shared/components/contact-list-manager';
import { Contact, ContactWithId } from 'business/shared/services/contact/types';
import {
  useCreateCompanyContactMutation,
  useDeleteCompanyContactMutation,
  useUpdateCompanyContactMutation,
  useUpdateCompanyLegalRepresentativeMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { CustomModal } from 'ui/custom-modal';
import { ContactFormContextEnum } from 'ui/types';

interface CompanyContactManagerProps {
  companyId: string;
  contacts: ContactWithId[];
  onAdded: () => void;
  onDeleted: () => void;
  onUpdated: () => void;
}

export const CompanyContactManager = ({
  companyId,
  contacts,
  onAdded,
  onDeleted,
  onUpdated,
}: CompanyContactManagerProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<
    | {
        type: ContactFormContextEnum;
        contact?: ContactWithId;
      }
    | undefined
  >(undefined);

  const [fileToUpload, setFileToUpload] = useState<FileFormData | undefined>();
  const { uploadDocument, loading: uploadDocumentLoading } =
    useCompanyContactDocumentUpload();
  const [shouldDeleteExistingLogo, setShouldDeleteExistingLogo] =
    useState<boolean>(false);

  const closeModal = () => {
    setForm(undefined);
    setShouldDeleteExistingLogo(false);
    setFileToUpload(undefined);
  };

  const handleCompleted = async (contactId: string) => {
    if (fileToUpload) {
      await uploadDocument({
        ...fileToUpload,
        companyId,
        contactId,
        fileName: 'profilePic',
      });
    }
    onAdded();
  };

  const onFileUpload = (d: FileFormData) => setFileToUpload(d);
  const onExistingLogoDelete = () => setShouldDeleteExistingLogo(true);

  const [createContact, createResult] = useCreateCompanyContactMutation({
    onCompleted: (data) => {
      closeModal();
      handleCompleted(data?.contact?.id ?? '');
    },
  });

  const [updateContact, updateResult] = useUpdateCompanyContactMutation({
    onCompleted: (data) => {
      closeModal();
      handleCompleted(data?.updateCompanyContact?.id ?? '');
    },
  });

  const onCreate = (contact: Contact) => {
    return createContact({
      variables: {
        input: {
          companyId,
          isLegalRepresentative: contacts.length === 0,
          contact: omit(contact, ['type']),
        },
      },
      // because react-hook-form not handling well error
    }).catch(() => undefined);
  };

  const onUpdate = ({
    id,
    // We do not wish to send those onUpdate
    /* eslint-disable unused-imports/no-unused-vars*/
    isLegalRepresentative,
    publicationStatus,
    canDelete,
    logo,
    type,
    /* eslint-enable unused-imports/no-unused-vars*/
    ...rest
  }: ContactWithId) => {
    return updateContact({
      variables: {
        input: {
          id,
          contact: rest,
          companyId,
          contactLogoId: logo?.id,
        },
      },
      // because react-hook-form not handling well error
    }).catch(() => undefined);
  };

  const onSubmit = (contact: Contact | ContactWithId) => {
    const promise =
      'id' in contact && contact.id ? onUpdate(contact) : onCreate(contact);
    return promise;
  };

  const [setLegalRep] = useUpdateCompanyLegalRepresentativeMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.updateLegalRepresentative'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onUpdated();
    },
  });

  const onSetLegalRep = (contact: ContactWithId) => {
    setLegalRep({
      variables: {
        unsetCondition: {},
        unsetValues: { isLegalRepresentative: false },
        setCondition: { contactId: { _eq: contact.id } },
        setValues: { isLegalRepresentative: true },
      },
    });
  };

  const [deleteContact] = useDeleteCompanyContactMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      onDeleted();
      enqueueSnackbar(t('successMessage.deleteContact'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const onDelete = (contact: ContactWithId) => {
    return deleteContact({
      variables: {
        input: {
          id: contact.id,
        },
      },
    });
  };

  const saving =
    createResult.loading || updateResult.loading || uploadDocumentLoading;

  return (
    <>
      <ContactListManager
        onAdd={() => setForm({ type: ContactFormContextEnum.ADD })}
        onEdit={(contact: ContactWithId) =>
          setForm({ contact, type: ContactFormContextEnum.EDIT })
        }
        onSetLegalRepresentative={onSetLegalRep}
        onDelete={onDelete}
        contacts={contacts}
      />

      {form ? (
        <CustomModal
          open
          handleClose={closeModal}
          component={
            <CompanyContactForm
              onSubmit={onSubmit}
              onClose={closeModal}
              error={createResult?.error || updateResult?.error}
              loading={saving}
              context={form.type}
              contact={form.contact}
              onFileUpload={onFileUpload}
              onExistingLogoDelete={onExistingLogoDelete}
              shouldShowExistingLogo={!shouldDeleteExistingLogo}
            />
          }
        />
      ) : null}
    </>
  );
};
