import { useCallback } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { OpFieldNames } from 'business/fund-manager/operation/services/types';

import { CommitmentsForSummary } from './op-shares-config-table';
import {
  OpCreationShareInput,
  sum,
  toNum,
  toStr,
  useCommitments,
  useTotalNumberOfShares,
  useWatchMultipleNum,
  useWatchNum,
} from './op-shares-utils';
import {
  calcPerShares,
  useOperationShareData,
} from './use-fetch-share-commitments';

// Share-type level calculations

export function useTotalDrawnFromInputs() {
  const drawn = useWatchNum(`${OpFieldNames.fund}.${OpFieldNames.drawn}`);
  const managementFees = useWatchNum(
    `${OpFieldNames.fund}.${OpFieldNames.managementFees}`,
  );
  const otherFees = useWatchNum(
    `${OpFieldNames.fund}.${OpFieldNames.otherFees}`,
  );

  return drawn + managementFees + otherFees;
}

export function useTotalDistribFromInputs() {
  const returnOfCost = useWatchNum(
    `${OpFieldNames.fund}.${OpFieldNames.returnOfCost}`,
  );
  const capitalGain = useWatchNum(
    `${OpFieldNames.fund}.${OpFieldNames.capitalGain}`,
  );
  const interest = useWatchNum(`${OpFieldNames.fund}.${OpFieldNames.interest}`);
  const dividend = useWatchNum(`${OpFieldNames.fund}.${OpFieldNames.dividend}`);

  return returnOfCost + capitalGain + interest + dividend;
}

export function useTotalCurrentDistribFromInputs() {
  const currentDistributed = useWatchNum(
    `${OpFieldNames.fund}.${OpFieldNames.currentDistributed}`,
  );

  return currentDistributed;
}

export function useUpdatePerUnitShareFromShareAmount(
  shareId: string,
  columnName: OpFieldNames,
) {
  const { setValue } = useFormContext();
  const { numberOfShares } = useOperationShareData();
  return useCallback(
    (amount: number) => {
      setValue(
        `${shareId}.${columnName}.${OpFieldNames.perShare}`,
        toStr(amount / numberOfShares, 8),
      );
    },
    [columnName, numberOfShares, setValue, shareId],
  );
}

export function useUpdateFundAmountFromShareAmount(
  shareId: string,
  columnName: OpFieldNames,
) {
  const { getValues, setValue } = useFormContext();
  const { shares } = useOperationShareData();
  return useCallback(
    (amount: number) => {
      const formValues = getValues();
      for (const share of shares) {
        if (share.id !== shareId) {
          amount += toNum(
            formValues[share.id][columnName][OpFieldNames.global],
          );
        }
      }
      setValue(`${OpFieldNames.fund}.${columnName}`, toStr(amount, 2));
    },
    [columnName, getValues, setValue, shareId, shares],
  );
}

export function useTotalDrawn(shareId: string) {
  const drawn = useWatchNum(
    `${shareId}.${OpFieldNames.drawn}.${OpFieldNames.global}`,
  );
  const managementFees = useWatchNum(
    `${shareId}.${OpFieldNames.managementFees}.${OpFieldNames.global}`,
  );
  const otherFees = useWatchNum(
    `${shareId}.${OpFieldNames.otherFees}.${OpFieldNames.global}`,
  );

  return drawn + managementFees + otherFees;
}

export function useTotalDistrib(shareId: string) {
  const returnOfCost = useWatchNum(
    `${shareId}.${OpFieldNames.returnOfCost}.${OpFieldNames.global}`,
  );
  const capitalGain = useWatchNum(
    `${shareId}.${OpFieldNames.capitalGain}.${OpFieldNames.global}`,
  );
  const interest = useWatchNum(
    `${shareId}.${OpFieldNames.interest}.${OpFieldNames.global}`,
  );
  const dividend = useWatchNum(
    `${shareId}.${OpFieldNames.dividend}.${OpFieldNames.global}`,
  );

  return returnOfCost + capitalGain + interest + dividend;
}

export function useTotalCurrentDistrib(shareId: string) {
  const currentDistributed = useWatchNum(
    `${shareId}.${OpFieldNames.currentDistributed}.${OpFieldNames.global}`,
  );

  return currentDistributed;
}

export function useTotalRow(shareId: string) {
  const totalDrawn = useTotalDrawn(shareId);
  const totalDistrib = useTotalDistrib(shareId);
  const totalCurrentDistrib = useTotalCurrentDistrib(shareId);

  return totalDrawn - totalDistrib - totalCurrentDistrib;
}

// Per-share calculations

export function useUpdateGlobalAmountFromUnitShareAmount(
  shareId: string,
  columnName: OpFieldNames,
) {
  const { setValue } = useFormContext();
  const { numberOfShares } = useOperationShareData();
  return useCallback(
    (amount: number) => {
      setValue(
        `${shareId}.${columnName}.${OpFieldNames.global}`,
        toStr(amount * numberOfShares, 2),
      );
    },
    [columnName, numberOfShares, setValue, shareId],
  );
}

export function useUpdateFundAmountFromUnitShareAmount(
  shareId: string,
  columnName: OpFieldNames,
) {
  const { getValues, setValue } = useFormContext();
  const { shares } = useOperationShareData();
  const { numberOfShares } = useOperationShareData();
  return useCallback(
    (unitAmount: number) => {
      const formValues = getValues();
      let amount = unitAmount * numberOfShares;
      for (const share of shares) {
        if (share.id !== shareId) {
          amount += toNum(
            formValues[share.id][columnName][OpFieldNames.global],
          );
        }
      }
      setValue(`${OpFieldNames.fund}.${columnName}`, toStr(amount, 2));
    },
    [columnName, getValues, numberOfShares, setValue, shareId, shares],
  );
}

export function useTotalDrawnPerShare(shareId: string) {
  const drawnPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.drawn}.${OpFieldNames.perShare}`,
  );
  const managementFeesPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.managementFees}.${OpFieldNames.perShare}`,
  );
  const otherFeesPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.otherFees}.${OpFieldNames.perShare}`,
  );

  return drawnPerShare + managementFeesPerShare + otherFeesPerShare;
}

export function useTotalDistribPerShare(shareId: string) {
  const returnOfCostPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.returnOfCost}.${OpFieldNames.perShare}`,
  );
  const capitalGainPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.capitalGain}.${OpFieldNames.perShare}`,
  );
  const interestPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.interest}.${OpFieldNames.perShare}`,
  );
  const dividendPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.dividend}.${OpFieldNames.perShare}`,
  );

  return (
    returnOfCostPerShare +
    capitalGainPerShare +
    interestPerShare +
    dividendPerShare
  );
}

export function useTotalCurrentDistribPerShare(shareId: string) {
  const currentDistributedPerShare = useWatchNum(
    `${shareId}.${OpFieldNames.currentDistributed}.${OpFieldNames.perShare}`,
  );

  return currentDistributedPerShare;
}

export function useTotalRowPerShare(shareId: string) {
  const totalDrawnPerShare = useTotalDrawnPerShare(shareId);
  const totalDistribPerShare = useTotalDistribPerShare(shareId);
  const totalCurrentDistribPerShare = useTotalCurrentDistribPerShare(shareId);

  return (
    totalDrawnPerShare - totalDistribPerShare - totalCurrentDistribPerShare
  );
}

// Sums (all shares)

export function useTotalDrawnAllShareTypes(shares: OpCreationShareInput[]) {
  return (
    useDrawnAllShareTypes(shares) +
    useManagementFeesAllShareTypes(shares) +
    useOtherFeesAllShareTypes(shares)
  );
}

export function useDrawnAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, `${OpFieldNames.drawn}.${OpFieldNames.global}`);
}

export function useManagementFeesAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(
    shares,
    `${OpFieldNames.managementFees}.${OpFieldNames.global}`,
  );
}

export function useOtherFeesAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.otherFeesGlobal);
}

export function useTotalDistribAllShareTypes(shares: OpCreationShareInput[]) {
  return (
    useReturnOfCostAllShareTypes(shares) +
    useCapitalGainAllShareTypes(shares) +
    useInterestAllShareTypes(shares) +
    useDividendAllShareTypes(shares)
  );
}

export function useReturnOfCostAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.returnOfCostGlobal);
}

export function useCapitalGainAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.capitalGainGlobal);
}

export function useInterestAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.interestGlobal);
}

export function useDividendAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.dividendGlobal);
}

export function useTotalCurrentDistribAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useCurrentDistribAllShareTypes(shares);
}

export function useCurrentDistribAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.currentDistributedGlobal);
}

export function useTotalRowAllShareTypes(shares: OpCreationShareInput[]) {
  return (
    useTotalDrawnAllShareTypes(shares) -
    useTotalDistribAllShareTypes(shares) -
    useTotalCurrentDistribAllShareTypes(shares)
  );
}

// Sums per share

export function useTotalDrawnPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  const totalDrawn = useTotalDrawnAllShareTypes(shares);
  const numberOfShares = useTotalNumberOfShares();
  return calcPerShares(totalDrawn, numberOfShares);
}

export function useDrawnPerShareAllShareTypes(shares: OpCreationShareInput[]) {
  return useSumAmounts(shares, OpFieldNames.drawnPerShare);
}

export function useManagementFeesPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.managementFeesPerShare);
}

export function useOtherFeesPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.otherFeesPerShare);
}

export function useTotalDistribPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  const totalDistrib = useTotalDistribAllShareTypes(shares);
  const numberOfShares = useTotalNumberOfShares();
  return calcPerShares(totalDistrib, numberOfShares);
}

export function useReturnOfCostPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.returnOfCostPerShare);
}

export function useCapitalGainPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.capitalGainPerShare);
}

export function useInterestPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.interestPerShare);
}

export function useDividendPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.dividendPerShare);
}

export function useTotalCurrentDistribPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  const totalCurrentDistrib = useTotalCurrentDistribAllShareTypes(shares);
  const numberOfShares = useTotalNumberOfShares();
  return calcPerShares(totalCurrentDistrib, numberOfShares);
}

export function useCurrentDistribPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return useSumAmounts(shares, OpFieldNames.currentDistributedPerShare);
}

export function useTotalRowPerShareAllShareTypes(
  shares: OpCreationShareInput[],
) {
  return (
    useTotalDrawnPerShareAllShareTypes(shares) -
    useTotalDistribPerShareAllShareTypes(shares) -
    useTotalCurrentDistribPerShareAllShareTypes(shares)
  );
}

// Fund-level calculations

export function useUpdatePerShareTypeFromFundAmount(columnName: OpFieldNames) {
  const { getValues, setValue } = useFormContext();
  const commitments = useCommitments();

  return useCallback(
    (amount: number | undefined) => {
      const formValues = getValues();

      const { perShare, isShareSelected } = calcPerShare(
        formValues,
        commitments,
        columnName,
        amount,
      );

      for (const [shareId, { numberOfShares }] of Object.entries(commitments)) {
        if (isShareSelected[shareId]) {
          setValue(
            `${shareId}.${columnName}.${OpFieldNames.global}`,
            numberOfShares > 0 ? toStr(numberOfShares * perShare, 2) : '',
          );
          setValue(
            `${shareId}.${columnName}.${OpFieldNames.perShare}`,
            numberOfShares > 0 ? toStr(perShare, 8) : '',
          );
        }
      }
    },
    [columnName, commitments, getValues, setValue],
  );
}

function calcPerShare(
  formValues: FieldValues,
  commitments: CommitmentsForSummary,
  columnName: OpFieldNames,
  amount: number | undefined,
) {
  const shareIds = Object.keys(commitments);

  // Map shareId => boolean: is the share selected (checked) for this column?
  const isShareSelected: Record<string, boolean> = {};
  for (const shareId of shareIds) {
    const selected = formValues[shareId][columnName][OpFieldNames.selected];
    isShareSelected[shareId] = selected;
  }

  const numberOfSharesConcerned = Object.entries(commitments).reduce(
    (acc, [shareId, { numberOfShares }]) =>
      acc + (isShareSelected[shareId] ? numberOfShares : 0),
    0,
  );
  const perShare =
    numberOfSharesConcerned && amount != null
      ? amount / numberOfSharesConcerned
      : 0;

  return { perShare, isShareSelected };
}

// utils

export function useSumAmounts(
  shares: OpCreationShareInput[],
  name: OpFieldNames | string,
) {
  const amounts = useWatchMultipleNum(
    shares.map((share) => `${share.id}.${name}`),
  );
  return sum(amounts);
}
