import { differenceInDays } from 'date-fns';

// Calculates the resulting amount
const irrResult = (values: number[], dates: Date[], rate: number) => {
  const r = rate + 1;
  let result = values[0];
  for (let i = 1; i < values.length; i++) {
    result +=
      values[i] /
      Math.pow(
        r,
        differenceInDays(new Date(dates[i]), new Date(dates[0])) / 365,
      );
  }
  return result;
};

// Calculates the first derivation
const irrResultDeriv = (values: number[], dates: Date[], rate: number) => {
  const r = rate + 1;
  let result = values[0];
  for (let i = 1; i < values.length; i++) {
    const frac = differenceInDays(dates[i], dates[0]) / 365;
    result -= (frac * values[i]) / Math.pow(r, frac + 1);
  }
  return result;
};

const newtonMethod = (values: number[], dates: Date[], guess?: number) => {
  // Initialize guess and resultRate
  const internGuess = typeof guess === 'undefined' ? 0.1 : guess;
  let resultRate = internGuess;

  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10;

  // Set maximum number of iterations
  const iterMax = 50;

  // Implement Newton's method
  let newRate, epsRate, resultValue;
  let iteration = 0;
  let contLoop = true;
  do {
    resultValue = irrResult(values, dates, resultRate);
    newRate =
      resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    if (resultRate < -1) {
      resultRate = -0.999999999;
    }
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
  } while (contLoop && ++iteration < iterMax);

  if (contLoop) {
    return NaN;
  }

  // Return internal rate of return
  return resultRate;
};
// Algorithm taken and adapted from this gist:
// https://gist.github.com/Tacombel/67ea9dbcf43a2b6eabf7408433d157ae
export const xirr = (values: number[], dates: Date[], guess?: number) => {
  // Check that values contains at least one positive value and one negative value
  let positive = false;
  let negative = false;
  for (let i = 0; i < values.length; i++) {
    if (values[i] > 0) {
      positive = true;
    }
    if (values[i] < 0) {
      negative = true;
    }
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) {
    return NaN;
  }

  return newtonMethod(values, dates, guess);
};
