import { Stack } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { isNumber } from 'technical/number/is-number';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

export const ValueCellTooltip = ({ value }: ITooltipParams) => {
  const palette = usePalette();
  if (!isNumber(value)) {
    return null;
  }

  return (
    <Stack
      className={styles.tooltip}
      style={{
        borderColor: palette.neutralBorderColor,
        background: palette.backgroundCard,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Typo>{value}</Typo>
    </Stack>
  );
};
