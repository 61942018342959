import { Divider, Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { TFunction, useTranslation } from 'translations/hook';

import { RecapValuesContent } from 'business/fund-manager/operation/components/recap-values-content';
import { useFund } from 'business/providers/fund-provider/use-fund';
import {
  CurrencyEnum,
  GetOperationSharesRecapQuery,
  useGetOperationSharesRecapQuery,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { AlternativeTable } from 'ui/alternative-table';
import { DrawdownMenuIcon } from 'ui/icons';
import { QueryStateDisplay } from 'ui/query-state-display';
import { StatusCardAmount } from 'ui/status-card-amount/status-card-amount';
import { SummaryBar } from 'ui/summary-bar';

type ShareDetailsValues = NonNullable<
  GetOperationSharesRecapQuery['recapValues']['shares']
>[number];

const getSharesDetailsColDef = ({
  t,
}: {
  t: TFunction;
}): ColDef<ShareDetailsValues>[] => [
  {
    headerName: t(
      'pages.fundManager.operation.overview.cards.share.table.shareName',
    ),
    field: 'name',
    pinned: 'left',
  },
  {
    headerName: t(
      'pages.fundManager.operation.creationRecap.share.totalCommitment',
    ),
    field: 'recap.commitments',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    pinned: 'left',
  },
  {
    headerName: t(
      'pages.fundManager.operationShareSelection.nominalValue.label',
    ),
    field: 'numericNominalValue',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.investment'),
    field: 'numericInvestment',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.fees'),
    field: 'numericFees',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.other'),
    field: 'numericOther',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.returnOfCost'),
    field: 'numericReturnOfCost',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },

  {
    headerName: t('pages.fundManager.operation.shareRecap.table.capitalGain'),
    field: 'numericCapitalGain',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.interest'),
    field: 'numericInterest',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.dividend'),
    field: 'numericDividend',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t(
      'pages.fundManager.operation.shareRecap.table.currentDistributed',
    ),
    field: 'numericCurrentDistributed',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.shareRecap.table.premium'),
    field: 'numericPremium',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.operation.table.type.title'),
    field: 'recap.type',
    valueFormatter: ({ value: context }) =>
      t('pages.fundManager.operation.table.type.cell', { context }),
  },
  {
    headerName: t('pages.fundManager.operation.table.amount'),
    field: 'recap.numericAmount',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
];

const formatData = (
  {
    recapValues: {
      totalCommitment,
      totalPaidIn,
      totalCurrentDistributedRecallable,
      unfundedCommitments,
    },
  }: GetOperationSharesRecapQuery,
  currency: CurrencyEnum,
  t: TFunction,
) => [
  {
    label: t('pages.fundManager.operation.creationRecap.share.totalCommitment'),
    value: <StatusCardAmount value={totalCommitment} currency={currency} />,
  },
  {
    label: t('pages.fundManager.operation.creationRecap.share.totalPaidIn'),
    value: <StatusCardAmount value={totalPaidIn} currency={currency} />,
  },
  {
    label: t(
      'pages.fundManager.operation.creationRecap.share.currentlyDistributed',
    ),
    value: (
      <StatusCardAmount
        value={totalCurrentDistributedRecallable}
        currency={currency}
      />
    ),
  },
  {
    label: t(
      'pages.fundManager.operation.creationRecap.share.unfundedCommitments',
    ),
    value: <StatusCardAmount value={unfundedCommitments} currency={currency} />,
  },
];

interface ShareRecapCardProps {
  operationId: string;
  withDetailsByShares?: boolean;
}
export const ShareRecapCard = ({
  operationId,
  withDetailsByShares,
}: ShareRecapCardProps) => {
  const { t } = useTranslation();
  const { currency } = useFund();

  const { data, loading, error } = useGetOperationSharesRecapQuery({
    variables: { input: { operationId, withDetailsByShares } },
    fetchPolicy: 'network-only',
  });

  const colDetails = getSharesDetailsColDef({ t });

  const footer =
    withDetailsByShares && data?.recapValues?.shares ? (
      <>
        <Divider />
        <AlternativeTable<ShareDetailsValues>
          fileName="share-recap-values"
          rowData={data.recapValues.shares ?? []}
          columnDefs={colDetails}
          loading={loading}
          domLayout="autoHeight"
          context={{ currency }}
        />
      </>
    ) : undefined;

  return (
    <Stack>
      <SummaryBar
        title={t('pages.fundManager.operation.creationRecap.share.title')}
        iconColor="secondary"
        icon={<DrawdownMenuIcon />}
        footer={footer}
      >
        {error || loading || !data ? (
          <QueryStateDisplay error={error} loading={loading} />
        ) : (
          <RecapValuesContent content={formatData(data, currency, t)} />
        )}
      </SummaryBar>
    </Stack>
  );
};
