import { Control } from 'react-hook-form';

import { FundProfile } from 'business/fund-manager/fund/services/types';
import { useAppContext } from 'business/providers/app-provider';
import { ManagementCompanyDropdown } from 'business/shared/components/management-company-dropdown';
import { Web_Portal_ManagementCompanyType_Enum } from 'generated/graphql';

interface Props {
  control: Control<FundProfile>;
}

export const FundManagementCompanyInput = ({ control }: Props) => {
  const { user } = useAppContext();

  if (
    user?.managementCompany?.type ===
    Web_Portal_ManagementCompanyType_Enum.GeneralPartner
  ) {
    return null;
  }

  return <ManagementCompanyDropdown<FundProfile> control={control} />;
};
