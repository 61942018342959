import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { getProfileFormValues } from 'business/shared/lp-creation-module/services/get-profile-form-values';
import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import {
  ClientTypeEnum,
  GetLpFormDataQuery,
  useUpdateLpGeneralProfileMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';

import { useProfileForm } from './use-profile-form';

type Props = {
  lpProfile: NonNullable<GetLpFormDataQuery['lp']>;
  lpId: string;
  onCompleted: () => void;
};

export const useEditLpGeneralProfileForm = ({
  lpId,
  lpProfile,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const [updateLpGeneralProfileMutation, { error, loading: saving }] =
    useUpdateLpGeneralProfileMutation({
      onCompleted,
      onError: () =>
        enqueueSnackbar(t('errors.updateLp'), {
          variant: VariantTypeEnum.ERROR,
        }),
    });

  const defaultValues = getProfileFormValues(lpProfile);

  const onSubmit = async ({
    individualLegalRepresentative,
    ...rest
  }: LpProfile) =>
    updateLpGeneralProfileMutation({
      variables: {
        input: {
          id: lpId,
          profile: {
            ...rest,
            customAnswers: rest.customAnswers ?? [],
            individualLegalRepresentative:
              rest.client === ClientTypeEnum.Individual
                ? {
                    ...individualLegalRepresentative,
                    birthDate: individualLegalRepresentative.birthDate
                      ? formatAsDate(individualLegalRepresentative.birthDate)
                      : null,
                    email: individualLegalRepresentative.email ?? '',
                    firstName: individualLegalRepresentative.firstName ?? '',
                    lastName: individualLegalRepresentative.lastName ?? '',
                    phoneNumber1:
                      individualLegalRepresentative.phoneNumber1 ?? '',
                  }
                : undefined,
          },
        },
      },
    });

  const { onSubmit: onFormSubmit, methods } = useProfileForm({
    onSubmit,
    defaultValues,
  });

  return {
    methods,
    onSubmit: onFormSubmit,
    saving,
    error,
  };
};
