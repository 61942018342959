import { Stack } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { LpKYB } from 'business/shared/lp-creation-module/services/types';
import { FormInputDate, FormInputText } from 'ui/form';

interface Props {
  control: Control<LpKYB>;
}

export const LpKybKeyMetricsForm = ({ control }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <FormInputDate
            name="endOfFiscalYear"
            label={t(
              'pages.fundManager.lpCreation.form.KYB.keyMetrics.endOfFiscalYear',
            )}
            fullWidth
            control={control}
          />
        </Stack>
        <FormInputText
          name="headcount"
          control={control}
          label={t(
            'pages.fundManager.lpCreation.form.KYB.keyMetrics.headcount',
          )}
          fullWidth
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <FormInputText
          name="companyCapital"
          type="number"
          control={control}
          label={t(
            'pages.fundManager.lpCreation.form.KYB.keyMetrics.companyCapital',
          )}
          fullWidth
        />
        {/*
         * for now it is an input text because we do not handle every currencies
         * and we are not sure which currency will be returned by pappers
         */}
        <FormInputText
          name="currency"
          control={control}
          label={t('pages.fundManager.lpCreation.form.KYB.keyMetrics.currency')}
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
