import { Upload } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { UploadSignedDocumentForm } from 'business/fund-manager/onboarding-subscription/components/upload-signed-document-form/upload-signed-document-form';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';

interface UploadSignedDocumentButtonProps {
  onboardingSubscriptionId: string;
}
export const UploadSignedDocumentButton = ({
  onboardingSubscriptionId,
}: UploadSignedDocumentButtonProps) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <ActionButton variant="primary" onClick={handleOpenModal}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Upload fontSize="inherit" />
          <span>
            {t(
              'pages.onboardingSubscription.readyForSignature.uploadSignedDocument.button',
            )}
          </span>
        </Stack>
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <UploadSignedDocumentForm
            onboardingSubscriptionId={onboardingSubscriptionId}
            onClose={handleCloseModal}
          />
        }
      />
    </>
  );
};
