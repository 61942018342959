import { Grid } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { getFieldName } from 'technical/get-field-name';
import { FormInputCheckBox } from 'ui/form/form-input-checkbox';

interface Props<T extends FieldValues> {
  control?: Control<T>;
  items: { label: string; itemName: string }[];
  name?: string;
  readonly?: boolean;
}

export const FormCheckboxesGrid = <T extends FieldValues>({
  items,
  control,
  name,
  readonly = false,
}: Props<T>) => {
  const { t } = useTranslation();
  return (
    <Grid container gap={1}>
      {items.map(({ itemName, label }) => (
        <Grid item xs={5} key={itemName}>
          <FormInputCheckBox
            name={getFieldName(itemName, name)}
            control={control}
            label={t(label)}
            disabled={readonly}
          />
        </Grid>
      ))}
    </Grid>
  );
};
