import { TFunction } from 'translations/hook';

import {
  LpCreationStepEnum,
  LpLegalEntityKyb,
  OnBoardingStatusEnum,
} from 'generated/graphql';
import { getCountryNameByCountryCode } from 'technical/country-utils';
import { getNaceValue } from 'ui/nace-code';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

import { getAddressItems } from './get-address-items';
import { getCommonKYCKYBBooleans } from './get-common-kyb-kyc-booleans';
import { getDeclineReason } from './get-decline-reason';
import { getDocumentCardItems } from './get-document-card-items';
import { getInvestorProfileValidationCard } from './get-investor-profile-card';
import { getLegalEntityKYCValidationCard } from './get-legal-entity-kyc-validation-card';
import { getLpContactsValidationCard } from './get-lp-contacts-validation-card';
import {
  CardProps,
  GetCardItemsFn,
  GetCardItemsParams,
  LpValidationLegalEntity,
} from './types';

const getGeneralInfosCardItems: GetCardItemsFn<
  LpValidationLegalEntity['lpLegalEntityGeneralInfos']
> = ({ data, t, language, declineReasons }) => {
  if (!data) {
    return undefined;
  }

  const { subscribingEntityName, country, address } = data;
  const items: ValidationItemType[] = [
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.label',
      ),
      value: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.legalEntity',
      ),
    },
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpValidation.legalEntityGeneralInformation.subscribingEntityName',
      ),
      value: subscribingEntityName,
    },
    ...(address ? getAddressItems({ address, t, language }) : []),
  ];

  items.push({
    type: VALIDATION_ITEM_TYPES.TEXT,
    label: t(
      'pages.fundManager.lpValidation.legalEntityGeneralInformation.address',
      {
        context: 'country',
      },
    ),
    value: getCountryNameByCountryCode({ code: country, language }),
  });

  const step = LpCreationStepEnum.LegalEntityGeneralInfos;
  const status = (data.onBoardingStatus ??
    OnBoardingStatusEnum.PendingValidation) as OnBoardingStatusEnum;

  return {
    title: t('pages.fundManager.lpCreation.form.generalInformation.title'),
    type: 'single',
    entity: {
      items,
    },
    entityId: data.id,
    step,
    status,
    declineReason: getDeclineReason({ status, step, declineReasons }),
  };
};

const formatKybTextItemsIfExists = ({
  keys,
  data,
  t,
}: {
  keys: (keyof LpLegalEntityKyb)[];
  data: LpValidationLegalEntity['lpLegalEntityKYB'];
  t: TFunction;
}) => {
  const items: ValidationItemType[] = [];
  if (!data) {
    return items;
  }

  keys.forEach((property) => {
    const value = data[property];

    if (
      value !== undefined &&
      value !== null &&
      typeof value === 'string' &&
      value !== ''
    ) {
      items.push({
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(
          `pages.fundManager.lpValidation.legalEntityKyb.label.${property}`,
        ),
        value,
      });
    }
  });

  return items;
};

const getKybCardItems: GetCardItemsFn<
  LpValidationLegalEntity['lpLegalEntityKYB']
> = ({ data, t, language, declineReasons }) => {
  if (!data) {
    return undefined;
  }

  const items: ValidationItemType[] = [
    ...formatKybTextItemsIfExists({
      keys: ['vatIdentificationNumber'],
      data,
      t,
    }),
  ];

  if (data.investorGroup) {
    items.push({
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.investorGroup',
      ),
      value: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.investorGroup',
        { context: data.investorGroup },
      ),
    });
  }

  // boolean section
  items.push(...getCommonKYCKYBBooleans({ data, t }));

  // lpNace code section
  const lpNaceValue = getNaceValue({ value: data.lpNaceCode, language });

  if (lpNaceValue) {
    items.push(
      {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(
          'pages.fundManager.lpValidation.legalEntityKyb.label.lpNaceValue.code',
        ),
        value: lpNaceValue.code,
      },
      {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(
          'pages.fundManager.lpValidation.legalEntityKyb.label.lpNaceValue.description',
        ),
        value: lpNaceValue.description,
      },
    );
  }

  // headoffice section
  items.push(
    ...formatKybTextItemsIfExists({
      keys: ['headOfficeUniqueCompanyIdentifier', 'headOfficeHeadcount'],
      data,
      t,
    }),
  );

  const headofficeNaceValue = getNaceValue({
    value: data.headOfficeNaceCode,
    language,
  });

  if (headofficeNaceValue) {
    items.push(
      {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(
          'pages.fundManager.lpValidation.legalEntityKyb.label.headOfficeNaceValue.code',
        ),
        value: headofficeNaceValue.code,
      },
      {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(
          'pages.fundManager.lpValidation.legalEntityKyb.label.headOfficeNaceValue.description',
        ),
        value: headofficeNaceValue.description,
      },
    );
  }

  const step = LpCreationStepEnum.LegalEntityKyb;
  const status = (data.onBoardingStatus ??
    OnBoardingStatusEnum.PendingValidation) as OnBoardingStatusEnum;

  return {
    title: t('pages.fundManager.lpValidation.legalEntityKyb.title'),
    entity: {
      items,
    },
    type: 'single',
    entityId: data.id,
    step,
    status,
    declineReason: getDeclineReason({ status, step, declineReasons }),
  };
};

export const getLegalEntityValidationCards = (
  {
    data,
    withLpInvestorProfileStep,
    ...rest
  }: GetCardItemsParams<LpValidationLegalEntity> & {
    withLpInvestorProfileStep: boolean;
  },
  onClickDownload: ({
    id,
    fileName,
  }: {
    id: string;
    fileName: string;
  }) => Promise<void>,
): CardProps[] =>
  [
    getGeneralInfosCardItems({
      ...rest,
      data: data.lpLegalEntityGeneralInfos,
    }),
    getKybCardItems({ ...rest, data: data.lpLegalEntityKYB }),
    ...(withLpInvestorProfileStep
      ? [getInvestorProfileValidationCard({ ...rest, data })]
      : []),
    getLegalEntityKYCValidationCard({
      ...rest,
      data: data.legalEntityKYCs,
    }),
    getLpContactsValidationCard({ ...rest, data: data.lpContacts }),
    getDocumentCardItems(
      {
        ...rest,
        data: { lpId: data.id, ...data.lpDocumentsValidationState },
      },
      onClickDownload,
    ),
  ].filter((v: CardProps | undefined): v is CardProps => !!v);
