import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpDocument } from 'business/shared/services/document/types';
import { useUploadLpDocumentMutation } from 'generated/graphql';
import { formatISO } from 'technical/date';
import logger from 'technical/logger';

interface UseUploadLpDocumentProps {
  onCompleted?: () => void;
}

export const useUploadLpDocument = ({
  onCompleted,
}: UseUploadLpDocumentProps = {}) => {
  const { t } = useTranslation();
  const [uploadLpDocument, { loading }] = useUploadLpDocumentMutation({
    onError: (err) => {
      logger.error({
        err,
      });
      enqueueSnackbar(t('errors.upload-failed'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.uploadDocument'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onCompleted?.();
    },
  });

  const uploadDocument = ({
    fileName,
    filePath,
    type,
    emissionDate,
    expirationDate,
    lpId,
  }: LpDocument & { lpId: string }) =>
    uploadLpDocument({
      variables: {
        input: {
          lpId,
          uploadDate: formatISO(new Date()),
          emissionDate: emissionDate
            ? formatISO(new Date(emissionDate))
            : undefined,
          expirationDate: expirationDate
            ? formatISO(new Date(expirationDate))
            : undefined,
          filePath,
          fileName,
          type,
        },
      },
    });

  return {
    uploadDocument,
    loading,
  };
};
