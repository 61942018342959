import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';

import styles from './index.module.scss';
interface Props {
  labels: string[];
  values: number[];
  text?: string;
  title?: string;
  margins?: { t: number; l: number; r: number; b: number };
  colorRange?: string[];
  fileName: string;
  customData?: string[];
  extraTooltipInfo?: string;
}

export const PieGraph = ({
  labels,
  values,
  text,
  title,
  margins,
  colorRange,
  fileName,
  customData,
  extraTooltipInfo,
}: Props) => {
  const palette = usePalette();

  const hoverTemplate = `%{label} ${
    customData ? '<br>%{customdata}' : ''
  }<br>%{percent:.2%}${extraTooltipInfo ? '<br>' + extraTooltipInfo : ''}
  <extra></extra>`;

  const dataChart = [
    {
      values,
      labels,
      textinfo: 'none',
      hovertemplate: hoverTemplate,
      hole: 0.6,
      type: PlotChartTypeEnum.pie,
      customdata: customData,
    },
  ];

  const plotLayout: PlotLayout = {
    title: title ?? undefined,
    autosize: true,
    font: {
      size: 15,
    },
    margin: margins ?? {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
    showlegend: false,
    annotations: [
      {
        font: {
          size: 15,
        },
        showarrow: false,
        text: text ?? '',
        x: 0.5,
        y: 0.5,
      },
    ],
    colorway: colorRange ?? [
      palette.primary[800],
      palette.secondary[800],
      palette.tertiary[300],
      palette.primary[900],
      palette.secondary[900],
      palette.tertiary[400],
      palette.primary[400],
      palette.secondary[500],
      palette.tertiary[300],
      palette.primary[100],
      palette.secondary[100],
      palette.tertiary[100],
    ],
  };

  return (
    <div className={styles.graphContainer}>
      <PlotChart
        dataArray={dataChart}
        layout={plotLayout}
        fileName={fileName}
      />
    </div>
  );
};
