import { LegalEntityKycRole } from 'generated/graphql';

type RoleKey =
  | 'isLegalRepresentative'
  | 'isExecutive'
  | 'isSignatory'
  | 'isUltimateBeneficialOwner';

const roleByLpContactProps: [RoleKey, LegalEntityKycRole][] = [
  ['isLegalRepresentative', LegalEntityKycRole.LegalRepresentative],
  ['isExecutive', LegalEntityKycRole.Executive],
  ['isSignatory', LegalEntityKycRole.Signatory],
  ['isUltimateBeneficialOwner', LegalEntityKycRole.UltimateBeneficialOwner],
];

export const getLpContactRoles = (
  contact: Record<RoleKey, boolean>,
): LegalEntityKycRole[] =>
  roleByLpContactProps
    .map(([key, role]) => (contact[key] === true ? role : undefined))
    .filter(
      (v: LegalEntityKycRole | undefined): v is LegalEntityKycRole => !!v,
    );
