import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { MfaSetupType } from 'business/shared/mfa/services/types';
import { mfaSetupValidation } from 'business/shared/mfa/services/validation';
import { AuthAppLogo } from 'business/user/components/auth-logo';
import PageAuth from 'business/user/pages/page-auth';
import authService from 'business/user/services/auth-service';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import { useCreateUserTotpSecretMutation } from 'generated/graphql';
import { Button } from 'ui/button';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

export const MFAPage = () => {
  const [errorContext, setErrorContext] = useState<string | null>(null);
  const { t } = useTranslation();
  const [createUserTotpSecretMutation, { data, loading, error, called }] =
    useCreateUserTotpSecretMutation();

  const { control, handleSubmit } = useForm<MfaSetupType>({
    resolver: yupResolver(mfaSetupValidation),
    defaultValues: {
      code: '',
    },
  });

  if (!called || loading || error || !data) {
    if (!called) {
      createUserTotpSecretMutation();
    }
    return <QueryStateDisplay loading={!called || loading} error={error} />;
  }

  const onSubmit: SubmitHandler<MfaSetupType> = async (values) => {
    try {
      await authService.setupTotp(values.code);
    } catch (err) {
      setErrorContext(getAuthErrorContextFromAuthProviderError(err).context);
    }
  };

  return (
    <PageAuth>
      <Card className={styles.setupMfaCard}>
        <Stack
          spacing={4}
          className={styles.card}
          alignItems="center"
          justifyContent="center"
        >
          <AuthAppLogo height={50} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} alignItems="center" justifyContent="center">
              <Typography variant="body1">
                {t('pages.mfaSetup.setup.text')}
              </Typography>
              {data.createUserTotpSecret.success ? (
                <QRCodeSVG
                  value={data.createUserTotpSecret.qrCodeUrl!}
                  height={250}
                  width={250}
                />
              ) : null}
              <FormInputText
                name="code"
                label={t('pages.mfaSetup.setup.code')}
                control={control}
                disabled={loading}
                required
                fullWidth
              />
              <Button variant="contained" type="submit">
                {t('pages.mfaSetup.setup.submit')}
              </Button>
              {errorContext ? <ErrorLabel label={errorContext} /> : null}
            </Stack>
          </form>
        </Stack>
      </Card>
    </PageAuth>
  );
};
