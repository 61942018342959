import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { GetCompanyDashboardMetricsQuery } from 'generated/graphql';
import { CheckmarkRoundedIcon } from 'ui/icons/checkmark-rounded';
import { WarningIcon } from 'ui/icons/warning';
import { InfoItem } from 'ui/info-item';
import { StatInfoCard } from 'ui/stat-info-card/stat-info-card';

export const CompanyMetricsCard = ({
  data,
}: {
  data?: GetCompanyDashboardMetricsQuery['monitoringMetricsCount'];
}) => {
  const { t } = useTranslation();
  const { companyId = '', fundId = '' } = useParams();

  return (
    <StatInfoCard
      title={t(
        'pages.fundManager.portfolio.company.metricMonitoringCountTitle',
      )}
      path={FundManagerRoutes.PortfolioFundIdCompanyIdMetrics.replace(
        ':fundId',
        fundId,
      ).replace(':companyId', companyId)}
    >
      <InfoItem
        description={t(
          'pages.fundManager.portfolio.company.metricMonitoringCount',
        )}
        imageSource={<TrendingUpIcon />}
        iconColor="secondary"
        value={data?.monitoringMetricsCount.toString()}
      />
      <InfoItem
        description={t(
          'pages.fundManager.portfolio.company.outdatedMonitoringCount',
        )}
        imageSource={
          data?.outdatedMonitoringMetricsCount !== 0 ? (
            <WarningIcon />
          ) : (
            <CheckmarkRoundedIcon />
          )
        }
        iconColor="primary"
        value={data?.outdatedMonitoringMetricsCount.toString()}
      />
    </StatInfoCard>
  );
};
