import { GetDocumentsQuery } from 'generated/graphql';

export const DocumentTypeEnum: { [key: string]: string } = {
  'Articles of association': 'ARTICLES_ASSOCIATION',
  'Beneficial owner': 'BENEFICIAL_OWNER',
  'Certificate of incorporation': 'CERTIFICATE_INCORPORATION',
  'IBAN certificate': 'IBAN_CERTIFICATE',
  'Legal representative - national ID': 'LEGAL_REP_NATIONAL_ID',
  'Legal representative - proof of address': 'LEGAL_REP_PROOF_ADDRESS',
  'Power of attorney': 'POWER_ATTORNEY',
  'Proof of tax ID': 'PROOF_TAX',
  default: 'UNKNOWN_TYPE',
};

export interface DocumentCustomFilter {
  documentTypes: string[];
  funds: string[];
}

export function isGetDocumentsQuery(query: any): query is GetDocumentsQuery {
  return (
    Array.isArray(query.documents) &&
    query.documents.every(
      (document: any) =>
        typeof document === 'object' &&
        document.__typename === 'dataviz_document' &&
        typeof document.id === 'string' &&
        typeof document.type === 'object' &&
        document.type.__typename === 'dataviz_documentType' &&
        typeof document.type.labelEn === 'string' &&
        typeof document.type.labelFr === 'string',
    )
  );
}
