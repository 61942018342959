import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Typography variant="caption" sx={{ color: 'text' }}>
        {t('privacyPolicy.notice')}
      </Typography>
      <Typography variant="caption" sx={{ color: 'text' }}>
        {t('privacyPolicy.copyright', { year: new Date().getFullYear() })}
      </Typography>
    </Stack>
  );
};
