import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { ContactFileUploadForm } from 'business/fund-manager/portfolio/company/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useCompanyContactDocumentUploadMutation } from 'generated/graphql';
import { formatISO } from 'technical/date';
import logger from 'technical/logger';
import { ValidationErrors } from 'technical/validation/types';

export const useCompanyContactDocumentUpload = () => {
  const { t } = useTranslation();

  const [uploadCompanyContactDocument, { loading }] =
    useCompanyContactDocumentUploadMutation({
      onError: (err) => {
        logger.error({
          err,
        });
        enqueueSnackbar(t(ValidationErrors.GENERIC), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const uploadDocument = ({
    fileName,
    filePath,
    uploadDate,
    companyId,
    contactId,
  }: ContactFileUploadForm & { contactId: string; companyId: string }) =>
    uploadCompanyContactDocument({
      variables: {
        input: {
          companyId,
          contactId,
          uploadDate: formatISO(new Date(uploadDate)),
          filePath,
          fileName,
        },
      },
    });

  return {
    uploadDocument,
    loading,
  };
};
