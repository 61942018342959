import { Avatar, Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useWebDocumentDownloadUrlQuery } from 'generated/graphql';
import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import { InfoCard } from 'ui/info-card';

import styles from './index.module.scss';

interface ContactsCardProps {
  contact?: {
    title?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    logoDocuments: {
      documentId: string;
    }[];
  };
  count: number;
  path?: string;
}

/**
 * code duplicate in `fundManager/lp/components/contactsCard`
 * So if you duplicate it again think about making a ui/component ;)
 */
export const ContactsCard = ({ contact, count, path }: ContactsCardProps) => {
  const { t } = useTranslation();
  const palette = usePalette();

  const { data: contactlogoData } = useWebDocumentDownloadUrlQuery({
    variables: {
      input: {
        ids: [contact?.logoDocuments?.[0]?.documentId ?? ''],
      },
    },
    skip: !contact?.logoDocuments?.[0]?.documentId,
  });

  const contactLogoUrl = contactlogoData?.documentDownloadUrl?.url;

  return (
    <InfoCard dataTestId="contacts-card">
      <InfoCard.Title path={path}>
        {t('pages.dashboard.cards.contacts.title')}
      </InfoCard.Title>
      <Stack className={styles.container} direction="row" spacing={5}>
        <Avatar
          className={styles.avatar}
          variant="rounded"
          src={contactLogoUrl ?? undefined}
        />
        <Stack spacing={2}>
          <Stack>
            <Typography variant="h5">
              {contact ? getPersonFullName(contact) : ''}
            </Typography>
            <Typography>
              {t('pages.dashboard.cards.contacts.legalRepresentative')}
            </Typography>
          </Stack>
          {count > 0 ? (
            <Stack>
              <Typography sx={{ color: palette.primary[900] }}>
                {`+ ${t('pages.dashboard.cards.contacts.contactWithCount', {
                  count,
                })}`}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </InfoCard>
  );
};
