import { useState } from 'react';

import { useLanguageContext } from 'business/providers/language-provider';
import { getNaceValue } from 'ui/nace-code/get-nace-value';
import { NACE_CODES } from 'ui/nace-code/nace-codes';
import { INaceCode } from 'ui/nace-code/types';

interface IUseNaceFormData {
  onChange: (codeNace?: string) => void;
  value?: string;
}

export const useNaceFormData = ({
  onChange,
  value: valueRaw,
}: IUseNaceFormData) => {
  const { language } = useLanguageContext();

  const [nace, setNace] = useState<INaceCode | undefined>(
    valueRaw
      ? getNaceValue({
          value: valueRaw,
          language,
        })
      : undefined,
  );

  const onCodeChange = (value: string | null) => {
    const naceData = getNaceValue({ language, value: value ?? undefined });

    setNace(naceData);
    return onChange(naceData?.code ?? undefined);
  };

  const descriptionOptions = Object.keys(NACE_CODES)
    .map((id) => ({
      id,
      value: NACE_CODES[id][language].level4,
    }))
    .sort((a, b) => (a.value > b.value ? 1 : -1));

  const codeOptions = Object.keys(NACE_CODES)
    .map((id) => ({
      id,
      value: NACE_CODES[id].code,
    }))
    .sort((a, b) => (a.value > b.value ? 1 : -1));

  return {
    onCodeChange,
    descriptionOptions,
    codeOptions,
    nace,
  };
};
