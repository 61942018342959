import { ComponentProps, ReactNode } from 'react';

import { InfoItemContainer } from 'ui/info-item-container';
import { Typo } from 'ui/typo';

type InfoItemContainerProps = ComponentProps<typeof InfoItemContainer>;
type PartialSizeAndVariant = Partial<
  Pick<InfoItemContainerProps, 'size' | 'variant'>
>;

export interface InfoDescriptionItemProps
  extends Omit<InfoItemContainerProps, 'size' | 'variant'>,
    PartialSizeAndVariant {
  description: string | ReactNode;
  variant?: 'rounded' | 'circular';
  className?: string;
}

export function InfoDescriptionItem({
  description,
  variant = 'rounded',
  size = 'normal',
  ...rest
}: InfoDescriptionItemProps) {
  return (
    <InfoItemContainer {...rest} variant={variant} size={size}>
      <Typo size="sm">{description}</Typo>
    </InfoItemContainer>
  );
}
