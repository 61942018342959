import { Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationNavBar } from 'business/fund-manager/operation/components/operation-nav-bar';
import { OverviewDetailsCard } from 'business/fund-manager/operation/components/overview-details-card';
import { OverviewStatusCard } from 'business/fund-manager/operation/components/overview-status-card';
import { ShareRecapCard } from 'business/fund-manager/operation/components/share-recap-card';
import { OperationNavBarTabEnum } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  Web_Portal_ManagementCompanyType_Enum,
  Web_Portal_OperationStatus_Enum,
  useCompleteOperationMutation,
  useGetOperationStatusQuery,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { QueryStateDisplay } from 'ui/query-state-display';

const CloseOperationAction = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [completeOperationMutation, { error }] = useCompleteOperationMutation({
    onError: () =>
      enqueueSnackbar(t('errors.completeOperation'), {
        variant: VariantTypeEnum.ERROR,
      }),
    onCompleted: () => {
      navigate(FundManagerRoutes.Operations);
      enqueueSnackbar(t('successMessage.completeOperation'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  return (
    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
      {error ? <ErrorLabel label="errors.completeOperation" /> : null}
      <ActionButton
        variant="submit"
        onClick={() =>
          completeOperationMutation({
            variables: {
              input: {
                id,
              },
            },
          })
        }
        data-test-id="save-creation-recap-button"
      >
        {t('pages.fundManager.operation.closeOperation.button.save')}
      </ActionButton>
    </Stack>
  );
};

export const OperationOverview = () => {
  const { operationId = '' } = useParams();
  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const { data, loading, error } = useGetOperationStatusQuery({
    variables: {
      id: operationId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.operation) {
    return (
      <OperationNavBar activeTab={OperationNavBarTabEnum.Overview}>
        <QueryStateDisplay loading={loading} error={error} />;
      </OperationNavBar>
    );
  }

  const status = data.operation[0].status;

  return (
    <OperationNavBar
      activeTab={OperationNavBarTabEnum.Overview}
      action={
        status !== Web_Portal_OperationStatus_Enum.Done && !isAdvisor ? (
          <CloseOperationAction id={operationId} />
        ) : undefined
      }
    >
      <Stack spacing={2}>
        <OverviewStatusCard operationId={operationId} />
        <OverviewDetailsCard operationId={operationId} />
        <ShareRecapCard withDetailsByShares operationId={operationId} />
      </Stack>
    </OperationNavBar>
  );
};
