import { ApolloError } from '@apollo/client';
import { Divider, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyProfile } from 'business/fund-manager/portfolio/company/services/types';
import { LogoUploadForm } from 'business/fund-manager/shared/components/logo-upload-form';
import { useLanguageContext } from 'business/providers/language-provider';
import { AddressFormPart } from 'business/shared/components/address-form-part';
import {
  getRegionCodeByCountry,
  getRegionNameByRegionCode,
} from 'technical/country-utils';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText, FormSection, NaceCodeInput } from 'ui/form';
import { TextInput } from 'ui/text-input';
import { Typo } from 'ui/typo';

type CompanyProfileFormProps = {
  control: Control<CompanyProfile>;
  error?: ApolloError;
  edition?: boolean;
  action?: ReactNode;
  onFileUpload: (formData: { filePath: string; uploadDate: Date }) => void;
  onExistingLogoDelete: () => void;
  existingLogo?: {
    url: string;
    name: string;
  };
};

export const CompanyProfileForm = ({
  control,
  error,
  edition,
  action,
  onFileUpload,
  existingLogo,
  onExistingLogoDelete,
}: CompanyProfileFormProps) => {
  const { t } = useTranslation();
  const { field: geographyField } = useController({
    control,
    name: 'geography',
  });

  const { language } = useLanguageContext();

  return (
    <>
      {error?.message ? <ErrorLabel label={error.message} /> : null}

      <FormSection
        title={t('pages.fundManager.portfolio.company.creation.profile')}
        action={action}
      >
        <FormInputText
          name="name"
          required
          label={t('pages.fundManager.portfolio.company.creation.name')}
          control={control}
          fullWidth
        />
        <FormInputText
          name="identifier"
          required
          label={t('pages.fundManager.portfolio.company.creation.identifier')}
          control={control}
          fullWidth
        />

        <AddressFormPart
          name={'address'}
          control={control}
          required
          countryDisabled={edition}
          onCountryChanged={(country: string | null) => {
            const regionCode = getRegionCodeByCountry(country);
            geographyField.onChange(regionCode);
          }}
        />

        <TextInput
          name="geography"
          disabled
          label="geography"
          value={getRegionNameByRegionCode({
            code: geographyField.value,
            language,
          })}
          fullWidth
        />

        <Stack paddingY={2}>
          <Divider />
        </Stack>

        <NaceCodeInput control={control} name="codeNace" required />
      </FormSection>

      <FormSection
        title={t('pages.fundManager.portfolio.company.creation.governance')}
      >
        <FormInputText
          name="directorBoard"
          label={t('pages.fundManager.portfolio.company.creation.companyBoard')}
          control={control as any}
          fullWidth
        />

        <FormInputText
          name="holdingName"
          label={t('pages.fundManager.portfolio.company.creation.holdingName')}
          control={control as any}
          fullWidth
        />
      </FormSection>

      <FormSection
        title={t('pages.fundManager.portfolio.company.creation.other')}
      >
        <FormInputText
          name="websiteUrl"
          label={t('pages.fundManager.portfolio.company.creation.website')}
          control={control as any}
          fullWidth
        />

        <FormInputText
          name="linkedInUrl"
          label={t('pages.fundManager.portfolio.company.creation.linkedin')}
          control={control as any}
          fullWidth
        />
        {/* COMPANY UPLOAD LOGO */}
        <Stack>
          <Typo color="neutral">
            {t('pages.fundManager.portfolio.company.creation.uploadLogo')}
          </Typo>
          <LogoUploadForm
            onSubmit={onFileUpload}
            onExistingLogoDelete={onExistingLogoDelete}
            existingLogo={existingLogo}
          />
        </Stack>
      </FormSection>
    </>
  );
};
