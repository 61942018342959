export function insertInArray<T>({
  array,
  item,
  index,
}: {
  array: T[];
  item: T;
  index: number;
}) {
  return [...array.slice(0, index), item, ...array.slice(index)];
}
