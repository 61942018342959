import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { getLocalLabel } from 'business/localisation-helper';
import { DocumentDownloadButton } from 'business/lp-platform/document/components/download-button';
import { GetDocumentsQuery } from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { dateComparisonOperators } from 'technical/filter/operators';

export function getDocumentColumnDefinition(
  t: TFunction,
): GridColDef<GetDocumentsQuery['documents'][number]>[] {
  return [
    {
      // TODO declare a "date" column type in AG Grid to avoid repeating this date formatting code.
      field: 'uploadDate',
      filterOperators: dateComparisonOperators,
      headerName: t('pages.document.table.uploadDate'),
      aggregable: false,
      valueGetter: ({ row: { uploadDate } }) =>
        uploadDate ? formatAsDate(uploadDate) : '',
      groupingValueGetter: ({ row: { uploadDate } }) => {
        return formatAsDate(uploadDate);
      },
    },
    {
      field: 'type',
      headerName: t('pages.document.table.type'),
      aggregable: false,
      valueGetter: ({ row: { type } }) => (type ? getLocalLabel(type) : ''),
      groupingValueGetter: ({ row: { type } }) => {
        return type ? getLocalLabel(type) : '';
      },
    },
    {
      field: 'fundName',
      headerName: t('pages.document.table.fundName'),
      groupable: false,
      aggregable: false,
      // TODO: fix this to display the fundName
      valueGetter: () => '',
    },
    {
      // TODO declare a "date" column type in AG Grid to avoid repeating this date formatting code.
      field: 'expirationDate',
      filterOperators: dateComparisonOperators,
      headerName: t('pages.document.table.expirationDate'),
      aggregable: false,
      valueGetter: ({ row: { expirationDate } }) =>
        expirationDate ? formatAsDate(expirationDate) : '',
      groupingValueGetter: ({ row: { expirationDate } }) =>
        formatAsDate(expirationDate),
    },
    {
      field: 'download',
      disableExport: true,
      headerName: t('pages.document.table.action'),
      groupable: false,
      aggregable: false,
      filterable: false,
      sortable: false,
      align: 'center',
      renderCell: ({ row: { id } }) =>
        id ? <DocumentDownloadButton documentIds={[id]} /> : '',
    },
  ];
}
