import { Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { ValidationErrors } from 'technical/validation/types';
import { Flex } from 'ui/flex';
import Link from 'ui/link';

import styles from './index.module.scss';

export const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Flex className={styles.flex} justify="center" alignItems="center" column>
      <Typography variant="h1">{t(ValidationErrors.GENERIC)}</Typography>
      <Link to="/">{t('pages.common.goToHome')}</Link>
    </Flex>
  );
};
