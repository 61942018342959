import * as yup from 'yup';

import { bankDetailsSchema } from 'business/shared/services/validation';
import { uploadedFileSchema } from 'technical/file-management/validation';
import { nonEmptyStringSchema } from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

export const createSubscriptionSchema = yup.object({
  lpId: nonEmptyStringSchema,
  fundId: nonEmptyStringSchema,
  shareId: nonEmptyStringSchema,
  date: yup.date().required(ValidationErrors.REQUIRED),
  amount: yup
    .number()
    .min(1, ValidationErrors.TOO_SHORT)
    .required(ValidationErrors.REQUIRED),
  taxOption: yup.boolean(),
  bankDetails: bankDetailsSchema.required(ValidationErrors.REQUIRED),
  sideLetters: yup.array().of(uploadedFileSchema).optional(),
  subscriptionLetters: yup.array().of(uploadedFileSchema).optional(),
});
