import { isValidIBAN, isValidBIC, countrySpecs } from 'ibantools';
import * as yup from 'yup';

import { nonEmptyStringSchema } from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';
export const zipCodeValidationSchema = yup.string();

// strange but we need to be sure there is rules to be applied
const isValidCountryCode = (countryCode: string) =>
  Object.keys(countrySpecs?.[countryCode] ?? {}).length > 0;

export const ibanSchema = yup
  .string()
  .uppercase()
  .trim()
  .test({
    name: 'is-valid-iban',
    message: ValidationErrors.INVALID,
    exclusive: true,
    test: (value) => {
      if (!value) {
        return true;
      }
      const cleanValue = value.replaceAll(/\s+/g, '');

      // pick up from ibantools source
      const countryCode = cleanValue.toUpperCase().slice(0, 2);
      if (isValidCountryCode(countryCode)) {
        return isValidIBAN(cleanValue);
      }

      return true;
    },
  });

export const bicSchema = yup
  .string()
  .uppercase()
  .trim()
  .test({
    name: 'is-valid-bic',
    message: ValidationErrors.INVALID,
    exclusive: true,
    test: (value) => {
      if (!value) {
        return true;
      }
      const cleanValue = value.replaceAll(/\s+/g, '');

      return isValidBIC(cleanValue);
    },
  });

export const addressRequiredSchema = yup.object({
  name: yup.string(),
  streetLine: nonEmptyStringSchema,
  streetLine2: yup.string(),
  city: nonEmptyStringSchema,
  // only string for now
  zipCode: zipCodeValidationSchema.required(),
  country: nonEmptyStringSchema,
});

export const addressSchema = yup.object().shape({
  streetLine: yup.string(),
  streetLine2: yup.string(),
  city: yup.string(),
  zipCode: zipCodeValidationSchema,
  country: yup.string(),
});

export const bankDetailsSchema = yup.object({
  name: nonEmptyStringSchema,
  bic: bicSchema.required(ValidationErrors.REQUIRED),
  iban: ibanSchema.required(ValidationErrors.REQUIRED),
  comment: yup.string().trim().optional(),
  contact: yup.string().trim().optional(),
});
