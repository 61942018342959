import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Snackbar, Stack } from '@mui/material';
import { useState } from 'react';

interface Props {
  textToCopy: string;
  message: string;
}

export const CopyToClipboardButton = ({ textToCopy, message }: Props) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Stack>
      <Button color="secondary" onClick={handleClick}>
        <ContentCopyIcon />
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={message}
      />
    </Stack>
  );
};
