import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import { OperationCreationStepEnum } from 'business/fund-manager/operation/services/types';
import { FormCard, FormWizzard } from 'ui/form';

import styles from './index.module.scss';

type OperationCreationContainerProps = PropsWithChildren<{
  activeStep?: OperationCreationStepEnum;
  onNext?: () => void;
  onPrevious?: () => void;
  labelAction?: string;
  rawChildren?: boolean;
  isSubmitting?: boolean;
}>;

export const OperationCreationContainer = ({
  children,
  activeStep = OperationCreationStepEnum.Details,
  onNext,
  labelAction,
  onPrevious,
  rawChildren,
  isSubmitting,
}: OperationCreationContainerProps) => {
  const { t } = useTranslation();
  const steps = [
    {
      label: t('pages.fundManager.operationCreation.step.setup'),
    },
    {
      label: t('pages.fundManager.operationCreation.step.shares'),
    },
    {
      label: t('pages.fundManager.operationCreation.step.shares-lp'),
    },
    {
      label: t('pages.fundManager.operationCreation.step.preview'),
    },
    {
      label: t('pages.fundManager.operationCreation.step.recap'),
    },
  ];

  return (
    <>
      <FormWizzard
        steps={steps}
        activeStep={activeStep}
        onNext={onNext}
        labelAction={labelAction}
        onPrevious={onPrevious}
        isSubmitting={isSubmitting}
      />
      {rawChildren ? (
        children
      ) : (
        <FormCard className={styles.content}>{children}</FormCard>
      )}
    </>
  );
};
