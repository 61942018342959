import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { getOperationDetailsColumnDefinition } from 'business/fund-manager/operation/services/get-operation-details-column-definition';
import { getOperationShareLpsValues } from 'business/fund-manager/operation/services/get-operation-share-lps-values';
import { OperationSharesLpValues } from 'business/fund-manager/operation/services/types';
import { useAppContext } from 'business/providers/app-provider';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { useGetOperationDetailsQuery } from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

interface OperationDetailsContentProps {
  operationId: string;
}
export const OperationDetailsContent = ({
  operationId,
}: OperationDetailsContentProps) => {
  const { t } = useTranslation();
  const { user } = useAppContext();

  const { data, loading, error } = useGetOperationDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        operationId,
      },
    },
  });

  const { currency } = useFund();

  const operationShareLpsData = data
    ? getOperationShareLpsValues(data.getOperationDetails)
    : [];

  const columns = getOperationDetailsColumnDefinition({
    operationId,
    t,
    managementCompanyType: user?.managementCompany?.type,
    currency,
  });

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  return (
    <Stack spacing={2}>
      <AlternativeTable<OperationSharesLpValues>
        fileName="operation-details"
        rowData={operationShareLpsData}
        columnDefs={columns}
        loading={loading}
        domLayout="autoHeight"
        displaySidebar
        context={{ currency }}
      />
    </Stack>
  );
};
