import { TFunction } from 'translations/hook';

import { BankDetails } from 'business/shared/services/types';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

const bankDetailsKeys: (keyof BankDetails)[] = [
  'name',
  'iban',
  'bic',
  'comment',
];

export const getBankDetails = ({
  bankDetails,
  t,
}: {
  bankDetails: Record<string, string | undefined | null>;
  t: TFunction;
}): ValidationItemType[] => {
  return bankDetailsKeys
    .map((key: string): ValidationItemType | undefined => {
      const value = bankDetails?.[key];
      if (!value) {
        return undefined;
      }

      return {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(`components.bankDetails.${key}`),
        value,
      };
    })
    .filter(
      (v: ValidationItemType | undefined): v is ValidationItemType => !!v,
    );
};
