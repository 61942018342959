import ValidateMfaCard from 'business/user/components/validate-mfa-card';
import PageAuth from 'business/user/pages/page-auth';

import styles from './index.module.scss';

function ValidateMFAPage() {
  return (
    <PageAuth>
      <ValidateMfaCard className={styles.validateMfaCard} />
    </PageAuth>
  );
}

export default ValidateMFAPage;
