import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { Button } from 'ui/button';

import styles from './index.module.scss';

interface Props {
  text: string;
  onClick?: () => void;
}

const ThemedBrownButton = ({ text, onClick }: Props) => {
  const palette = usePalette();
  return (
    <Button
      sx={{
        color: palette.backgroundForm,
        backgroundColor: palette.secondary[800],
        '&:hover': {
          color: palette.secondary[800],
          bgcolor: palette.secondary[100],
        },
      }}
      className={styles.button}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default ThemedBrownButton;
