import { Stack } from '@mui/material';
import { ComponentProps, ReactElement } from 'react';

import { CurrencyEnum } from 'generated/graphql';
import { Amount } from 'ui/amount';
import { InfoItemContainer } from 'ui/info-item-container';
import { Typo } from 'ui/typo';

type InfoItemContainerProps = ComponentProps<typeof InfoItemContainer>;
type PartialSizeAndVariant = Partial<
  Pick<InfoItemContainerProps, 'size' | 'variant'>
>;

export interface StatNumericAmountItemProps
  extends Omit<InfoItemContainerProps, 'size' | 'variant'>,
    PartialSizeAndVariant {
  value: number;
  currency: CurrencyEnum;
  description: ReactElement | string;
}

export function StatNumericAmountItem({
  value,
  currency,
  description,
  imageSource,
  variant = 'rounded',
  size = 'normal',
  className,
  iconColor,
  sx = {},
}: StatNumericAmountItemProps) {
  return (
    <InfoItemContainer
      className={className}
      size={size}
      imageSource={imageSource}
      variant={variant}
      iconColor={iconColor}
      sx={sx}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Amount value={value} currency={currency} size="2xl" bold ellipsis />
        {typeof description === 'string' ? (
          <>
            <Typo size="sm">{description}</Typo>
          </>
        ) : (
          description
        )}
      </Stack>
    </InfoItemContainer>
  );
}
