import * as yup from 'yup';

import { WebContact } from 'business/shared/lp-creation-module/services/contact-types';
import { CurrencyEnum, Web_Portal_RiskType_Enum } from 'generated/graphql';

import { declineConfirmationSchema } from './validation';

export interface LpsFilteredFields {
  lpNames: string[];
  legalRepresentatives: string[];
}

export enum FinancialFlowNavBarTabEnum {
  overview = 0,
  escrowAccount = 1,
}

export type AddContactToLp = Pick<
  WebContact,
  | 'title'
  | 'firstName'
  | 'email'
  | 'lastName'
  | 'phoneNumber1'
  | 'phoneNumber2'
  | 'companyName'
  | 'jobTitle'
>;

export enum ContactsFields {
  title = 'title',
  firstName = 'firstName',
  email = 'email',
  lastName = 'lastName',
  phoneNumber1 = 'phoneNumber1',
  phoneNumber2 = 'phoneNumber2',
  companyName = 'companyName',
  jobTitle = 'jobTitle',
  comment = 'comment',
}

export type LpContactsBooleanFields = {
  hasOperationalRights: boolean;
  hasLpPortalAccess: boolean;
  canAccessGeneralReports: boolean;
  canAttendInvestorsMeeting: boolean;
  canAccessESGReports: boolean;
  canAccessAmpereReports: boolean;
  canAccessCapitalAccountStatement: boolean;
};

export interface LpCommitmentAndOperations {
  fundName: string | undefined;
  shareName: string | undefined;
  shareId: string | undefined;
  status: string | undefined;
  numericPaidIn: number;
  numericCommitment: number;
  numericDistributed: number;
  numericDistributedNonRecallable: number;
  numericUnfundedCommitment: number;
  currency: CurrencyEnum | undefined;
}
export interface LpSubscriptionsFilteredFields {
  fundNames: string[];
  shareNames: string[];
  status: string[];
}

export enum LpCreationStepEnum {
  Profile = 0,
  KYC = 1,
  LegalRepresentative = 2,
  Contacts = 3,
}

export type LpSubscription = {
  id: string;
  date: string | null;
  numericAmount: number;
  fundName: string;
  fundId: string;
  lpName: string;
  lpId: string;
  shareType: string;
  currency: CurrencyEnum | undefined | null;
};

export type LpSubscriptionFilteredFields = {
  fundNames: string[];
  shares: string[];
};

export type LpAmlRiskForm = {
  interactionsWithInvestors?: string;
  lpTransparency?: string;
  lpNature?: string;
  structureTransparency?: string;
};

export type RiskData = {
  name: string;
  value: string | undefined;
  riskvalue: number | undefined;
  vigilanceValue: string | undefined;
};

type RiskValue = {
  id: string;
  riskType: Web_Portal_RiskType_Enum;
  riskValue: number;
  vigilanceValue: string;
  fieldName: string;
};

export type RiskValueByInput = {
  interactionsWithInvestors?: RiskValue;
  lpTransparency?: RiskValue;
  structureTransparency?: RiskValue;
  lpNature?: RiskValue;
};

export type UpdateLpContact = {
  lpId: string;
  contactId: string;
  hasOperationalRights?: boolean;
  hasLpPortalAccess?: boolean;
  canAccessGeneralReports?: boolean;
  canAttendInvestorsMeeting?: boolean;
  canAccessESGReports?: boolean;
  canAccessAmpereReports?: boolean;
  canAccessCapitalAccountStatement?: boolean;
};

export type DeclineConfirmation = yup.InferType<
  typeof declineConfirmationSchema
>;
export interface AutocompleteDifferential {
  field: string;
  newValue: string | number | Record<string, unknown>;
  oldValue?: string | number | Record<string, unknown>;
}

export enum LpAutocompleteMaxAgeInDaysEnum {
  min = 7,
  max = 365,
}
