import { Web_Portal_CashflowEmittedBy_Enum } from 'generated/graphql';

import { Cashflow, CashflowRow } from './types';

export const getCashflowsRows = (cashflows: Cashflow[]): CashflowRow[] =>
  cashflows.map((cashflow) => {
    const { emittedBy, numericAmount } = cashflow;

    const key =
      emittedBy === Web_Portal_CashflowEmittedBy_Enum.Fund
        ? 'paidOut'
        : 'paidIn';
    return { ...cashflow, [key]: numericAmount };
  });
