import { Container, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import ConnectedPage from 'business/user/helpers/connected-page';
import Link from 'ui/link';
import { Typo } from 'ui/typo';

const CONTACT_EMAIL = 'csm@pe3.support';

export const ContactSupportPage = () => {
  const { t } = useTranslation();

  return (
    <ConnectedPage title={t('pages.fundManager.contactSupport.pageTitle')}>
      <Container maxWidth="md" className="space-y-8 my-10">
        <Typo size="3xl" component="h2" bold>
          {t('pages.fundManager.contactSupport.title')}
        </Typo>
        <Stack className="space-y-2">
          <Typo>
            {t('pages.fundManager.contactSupport.mainText1')}
            <Link to={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>
            {t('pages.fundManager.contactSupport.mainText2')}
          </Typo>
          <Typo>{t('pages.fundManager.contactSupport.outro')}</Typo>
        </Stack>
        <Typo size="xl" bold>
          {t('pages.fundManager.contactSupport.signature')}
        </Typo>
      </Container>
    </ConnectedPage>
  );
};
