import { ComponentProps, MouseEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { DownloadButton } from 'ui/download-button';

interface DownloadDocumentButtonProps
  extends Omit<ComponentProps<typeof DownloadButton>, 'onClick'> {
  documentId: string;
  onDocumentDownload: (id: string) => Promise<string | undefined>;
}

export const DownloadDocumentButton = ({
  documentId,
  label,
  onDocumentDownload,
  ...rest
}: DownloadDocumentButtonProps) => {
  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    return onDocumentDownload(documentId).then((url) => {
      if (url) {
        window.open(url);
      }
    });
  };

  return (
    <DownloadButton
      {...rest}
      onClick={handleClick}
      label={label ?? t('pages.document.table.downloadButton')}
    />
  );
};
