import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { getLpPendingSubscriptionsListColumnDefinition } from 'business/fund-manager/lp/services/get-lp-pending-subscriptions-list-column-definition';
import { LpSubscription } from 'business/fund-manager/lp/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  GetPendingValidationSubscriptionsQueryResult,
  Web_Portal_ManagementCompanyType_Enum,
  useValidateSubscriptionMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { removeDuplicates } from 'technical/remove-duplicates';
import { AlternativeTable } from 'ui/alternative-table';
import { Typo } from 'ui/typo';

interface TableProps {
  lpId: string;
  onValidated: () => void;
  loading: boolean;
  data: NonNullable<GetPendingValidationSubscriptionsQueryResult['data']>;
}

const Table = ({ lpId, onValidated, loading, data }: TableProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isFundAdmin = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.FundAdmin,
  );

  const computedDataPendingValidation: LpSubscription[] =
    data.getPendingValidationSubscriptions.map(
      ({ id, date, numericAmount, share, lp, fund, currency }) => {
        return {
          id: id,
          date: date ? formatAsDate(date) : null,
          numericAmount: numericAmount ?? 0,
          fundName: fund.name ?? '',
          fundId: fund.id ?? '',
          lpName: lp.lpName ?? '',
          lpId: lp.id ?? '',
          shareType: share.name ?? '',
          currency: currency,
        };
      },
    );
  const [validateSubscriptionMutation] = useValidateSubscriptionMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.validateSubscription'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onValidated();
    },
    onError: () => {
      enqueueSnackbar(t('errors.validateSubscription'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    refetchQueries: ['GetLpPendingValidationSubscriptionsCount'],
  });

  const handleValidateSubscription = async (
    subscriptionId: string,
    fundId: string,
  ) => {
    await validateSubscriptionMutation({
      variables: {
        input: {
          lpId,
          fundId,
          subscriptionId,
        },
      },
    });
  };

  const customFiltersPendingValidation = computedDataPendingValidation
    ? {
        fundNames: removeDuplicates(computedDataPendingValidation, 'fundName'),
        shares: removeDuplicates(computedDataPendingValidation, 'shareType'),
      }
    : {
        fundNames: [],
        shares: [],
      };

  const headersPendingValidation =
    getLpPendingSubscriptionsListColumnDefinition({
      t,
      handleValidateSubscription,
      customFilters: customFiltersPendingValidation,
      shouldShowValidationButton: isFundAdmin,
    });

  return (
    <Stack spacing={2}>
      <Typo size="xl" color="primary">
        {t('pages.fundManager.subscription.table.titles.toValidate')}
      </Typo>
      <AlternativeTable<LpSubscription>
        fileName="lp-subscriptions-to-validate"
        rowData={computedDataPendingValidation}
        columnDefs={headersPendingValidation}
        loading={loading}
        domLayout="autoHeight"
        displaySidebar
      />
    </Stack>
  );
};

interface SubscriptionsTableToValidateProps extends Omit<TableProps, 'data'> {
  data: GetPendingValidationSubscriptionsQueryResult['data'];
}

export const SubscriptionsTableToValidate = ({
  data,
  ...rest
}: SubscriptionsTableToValidateProps) => {
  if (!data || data?.getPendingValidationSubscriptions.length === 0) {
    return null;
  }

  return <Table {...rest} data={data} />;
};
