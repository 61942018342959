import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { CommitmentStatusEnum } from 'business/fund-manager/operation/services/types';
import {
  GetShareCommitmentsQuery,
  useGetShareCommitmentsQuery,
} from 'generated/graphql';
import { convertFromX100toNumber } from 'technical/currency/formatters';
import { ErrorLabel } from 'ui/error-label';
import { LoaderBlock } from 'ui/loader';

import { OpCreationShareInput } from './op-shares-utils';

import type { SetCommitments } from './op-shares-config-table';

export const defaultCommitmentStatusFetched = [
  CommitmentStatusEnum.New,
  CommitmentStatusEnum.Equalized,
];

type Refetch = ReturnType<typeof useGetShareCommitmentsQuery>['refetch'];
type OpShareData = ReturnType<typeof useCalcOpShareData>;

const OperationShareDataContext = createContext<OpShareData | null>(null);

interface OperationShareDataProviderProps extends PropsWithChildren {
  share: OpCreationShareInput;
  shares: OpCreationShareInput[];
  setCommitments: SetCommitments;
}

export const OperationShareDataProvider: FC<OperationShareDataProviderProps> =
  function OperationShareDataProvider({
    children,
    share,
    shares,
    setCommitments,
  }) {
    const shareId = share.id;
    const { loading, error, data, refetch } = useGetShareCommitmentsQuery({
      variables: {
        shareId,
        status: defaultCommitmentStatusFetched,
      },
    });

    const commitment = convertFromX100toNumber(
      data?.web_portal_commitment_aggregate.aggregate?.sum?.amount ?? 0,
    );
    const nominalValue = convertFromX100toNumber(share.nominalValue);
    const numberOfShares = calcNumberOfShares(commitment, nominalValue);
    useEffect(() => {
      setCommitments((commitments) => {
        return {
          ...commitments,
          [shareId]: {
            commitment,
            nominalValue,
            numberOfShares,
          },
        };
      });
    }, [
      commitment,
      nominalValue,
      numberOfShares,
      setCommitments,
      share.name,
      shareId,
    ]);

    const opShareData = useCalcOpShareData(shares, share, data, refetch);
    if (loading) {
      return <LoaderBlock />;
    }
    if (error) {
      return <ErrorLabel label={error.message} />;
    }
    return (
      <OperationShareDataContext.Provider value={opShareData}>
        {children}
      </OperationShareDataContext.Provider>
    );
  };

export const useOperationShareData = () => {
  const data = useContext(OperationShareDataContext);
  if (data == null) {
    throw new Error(
      'useOperationShareData must be used within a OperationShareDataProvider',
    );
  }
  return data;
};

// Implementation details - calculations

function useCalcOpShareData(
  shares: OpCreationShareInput[],
  share: OpCreationShareInput,
  data: GetShareCommitmentsQuery | undefined,
  refetch: Refetch,
) {
  return useMemo(() => {
    const shareEqualizedCommitmentCount =
      data && data.commitment_equalized.aggregate
        ? data.commitment_equalized.aggregate.count
        : 0;
    const hasEqualizedCommitment = shareEqualizedCommitmentCount > 0;

    const commitmentAmount =
      data?.web_portal_commitment_aggregate.aggregate?.sum?.amount ?? 0;
    const nominalValue = share.nominalValue;
    const numberOfShares = calcNumberOfShares(commitmentAmount, nominalValue);

    return {
      refetch,
      shares,
      hasEqualizedCommitment,
      commitmentAmount,
      nominalValue,
      numberOfShares,
      shareId: share.id,
    };
  }, [data, refetch, share.id, share.nominalValue, shares]);
}

export function calcNumberOfShares(commitment: number, nominalValue: number) {
  return commitment / nominalValue;
}

export function calcPerShares(commitment: number, numberOfShares: number) {
  return commitment / numberOfShares;
}
