import { Stack } from '@mui/material';
import { Navigate, useParams } from 'react-router';
import { TFunction, useTranslation } from 'translations/hook';

import { LpDataComparisonTable } from 'business/fund-manager/lp/components/lp-data-comparison-table/lp-data-comparison-table';
import {
  AutocompleteDifferential,
  LpAutocompleteMaxAgeInDaysEnum,
} from 'business/fund-manager/lp/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { useLpAutocompleteComparisonQuery } from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { ActionButton } from 'ui/action-button';
import { EmptyCard } from 'ui/empty-card';
import { FormCard, FormSection } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

type AutocompleteAddress = {
  id?: string;
  streetLine: string;
  streetLine2?: string;
  city?: string;
  zipCode?: string;
  country?: string;
};
function formatDataToRows(data: AutocompleteDifferential[], t: TFunction) {
  const isAddress = (
    input: AutocompleteAddress | any,
  ): input is AutocompleteAddress => {
    return (
      input?.streetLine !== undefined ||
      input?.city !== undefined ||
      input?.zipCode !== undefined ||
      input?.country !== undefined
    );
  };
  // TODO PE3-2265: adapt the test as needed when the LP update is implemented
  const serialize = (input: any) => {
    if (isAddress(input)) {
      return `${input.streetLine ? input.streetLine + '\n' : ''}${
        input.city ? input.city + '\n' : ''
      }${input.zipCode ? input.zipCode + '\n' : ''}${input.country ?? ''}`;
    }
    return input;
  };
  return data.map((diff: AutocompleteDifferential) => {
    return {
      field: t(
        `pages.fundManager.lpCreation.form.kycKybAutocomplete.fieldLabel.${diff.field}`,
      ),
      newValue: serialize(diff.newValue),
      oldValue: serialize(diff.oldValue),
    };
  });
}

export const LpKycKybAutocompleteUpdatePage = () => {
  const { lpId = '' } = useParams();

  // Redirect to the edit profile page if the feature flag is not enabled
  const displayKYBAutocompletion = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_KYB_AUTOCOMPLETION,
  );
  if (!displayKYBAutocompletion) {
    return <Navigate to={FundManagerRoutes.LpId.replace(':lpId', lpId)} />;
  }

  return <LpKycKybAutocompleteUpdatePageEnabled />;
};

export const LpKycKybAutocompleteUpdatePageEnabled = () => {
  const { lpId = '' } = useParams();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useLpAutocompleteComparisonQuery({
    variables: {
      input: {
        lpId,
        // At page load we get Lp autocomplete data fresh to the max value, so likely from the cache
        maxAgeInDays: LpAutocompleteMaxAgeInDaysEnum.max,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  if (loading || error || !data) {
    if (
      error?.message ===
      'pages.fundManager.lpCreation.form.kycKybAutocomplete.error.autoCompleteNotAvailable'
    ) {
      return (
        <Stack alignSelf="center" alignItems="center" padding={5}>
          <EmptyCard
            title={t(error.message)}
            subtitle={t(
              'pages.fundManager.lpCreation.form.kycKybAutocomplete.error.autoCompleteNotAvailable2',
            )}
          ></EmptyCard>
        </Stack>
      );
    }
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  // TODO PE3-2265: Refetch button could also become Validate button once some properties are 'updated'
  const refetchButton = (
    <ActionButton
      variant="secondary"
      size="large"
      loading={loading}
      onClick={() =>
        refetch({
          input: {
            lpId,
            // At button click we get Lp autocomplete data fresh to the min value, so likely from the api
            // This is to ensure the not too many calls are made to the api upon page load & button press
            maxAgeInDays: LpAutocompleteMaxAgeInDaysEnum.min,
          },
        })
      }
    >
      {t(
        'pages.fundManager.lpCreation.form.kycKybAutocomplete.refetchButton.enabled',
      )}
    </ActionButton>
  );

  const kybRows = data.lpAutocompleteComparison?.lpInfos
    ? formatDataToRows(data.lpAutocompleteComparison.lpInfos, t)
    : [];

  return (
    <FormCard>
      <FormSection
        title={t(
          'pages.fundManager.lpCreation.form.kycKybAutocomplete.lastUpdate',
        )}
        subtitle={formatAsDate(data.lpAutocompleteComparison.resultDate)}
        action={refetchButton}
      >
        <LpDataComparisonTable
          rows={kybRows}
          type="kyb"
          loading={loading}
          validateAction={() => {
            // TODO PE3-2265 add validate action
          }}
          declineAction={() => {
            // TODO PE3-2265 add decline action
          }}
        />
      </FormSection>
    </FormCard>
  );
};
