import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { InstrumentProceedsQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatToMonetaryAmount } from 'technical/currency/formatters';

export function getValuationProceedColumnDefinition(
  t: TFunction,
): ColDef<InstrumentProceedsQuery['instrumentProceeds']['proceeds'][number]>[] {
  return [
    {
      field: 'date',
      type: AgGridColumnTypesEnum.DATE,
      headerName: t(
        'pages.fundManager.portfolio.valuationInstrumentProceed.transaction_date',
      ),
      aggFunc: 'sum',
    },
    {
      field: 'amount',
      headerName: t(
        'pages.fundManager.portfolio.valuationInstrumentProceed.amount',
      ),
      valueFormatter: ({ value: proceed }) => formatToMonetaryAmount(proceed),
    },
  ];
}
