import { ColDef } from 'ag-grid-community';
import { TFunction, useTranslation } from 'translations/hook';

import { useGetLpEscrowAccountsQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

type EscrowAccountPositionData = {
  fundName: string;
  shareName: string;
  numericAmount: number;
};

const getLpEscrowAccountSummaryCol = (
  t: TFunction,
): ColDef<EscrowAccountPositionData>[] => [
  {
    field: 'fundName',
    filter: true,
    headerName: t('pages.fundManager.lps.financialFlows.table.fund'),
  },
  {
    field: 'shareName',
    filter: true,
    headerName: t('pages.fundManager.lps.financialFlows.table.share'),
  },
  {
    field: 'numericAmount',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    headerName: t('pages.fundManager.lps.financialFlows.table.position'),
  },
];

type EscrowAccountPositionsProps = {
  lpId: string;
  shareId?: string;
  fundId?: string;
};

const getWhereClause = ({
  lpId,
  shareId,
  fundId,
}: EscrowAccountPositionsProps) => {
  const baseWhere = {
    lpId: { _eq: lpId },
  };
  if (shareId) {
    return {
      ...baseWhere,
      shareId: { _eq: shareId },
    };
  }

  if (fundId) {
    return {
      ...baseWhere,
      share: {
        fundId: { _eq: fundId },
      },
    };
  }
  return baseWhere;
};

export const EscrowAccountPositions = (props: EscrowAccountPositionsProps) => {
  const { t } = useTranslation();
  const { loading, data, error } = useGetLpEscrowAccountsQuery({
    variables: {
      where: getWhereClause(props),
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const formattedEscrowAccountData = data?.lpEscrowAccounts
    ? data?.lpEscrowAccounts.map((escrowData) => {
        return {
          fundName: escrowData.share.fund.name,
          shareName: escrowData.share.name,
          numericAmount: escrowData.numericAmount,
          currency: escrowData.share.fund.currency,
        };
      })
    : [];

  const headers = getLpEscrowAccountSummaryCol(t);

  return (
    <AlternativeTable<EscrowAccountPositionData>
      fileName="lp-escrow-accounts"
      rowData={formattedEscrowAccountData}
      columnDefs={headers}
      domLayout="autoHeight"
      loading={loading}
      displaySidebar
    />
  );
};
