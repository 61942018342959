import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import {
  FundReportingTypeEnum,
  GetFundShareValuationQuery,
  useGetFundShareValuationQuery,
} from 'generated/graphql';
import { FormInputDropdown } from 'ui/form/form-input-dropdown';

const getFundReportingShareOptions = (
  fundShareValuation: GetFundShareValuationQuery | undefined,
) =>
  fundShareValuation
    ? fundShareValuation.availableShares.map(({ id, value }) => ({
        id,
        value,
      }))
    : [];

interface FundReportingShareInputProps {
  fundId: string;
}
export const FundReportingShareInput = ({
  fundId,
}: FundReportingShareInputProps) => {
  const { t } = useTranslation();

  const { resetField } = useFormContext();

  const { data: fundShareValuation } = useGetFundShareValuationQuery({
    variables: {
      input: {
        id: fundId,
      },
    },
  });

  const type = useWatch({
    name: 'type',
  });

  useEffect(() => {
    resetField('shareIds');
  }, [resetField, type]);

  return (
    <FormInputDropdown
      name="shareIds"
      label={t('pages.fundManager.reporting.shareType')}
      options={getFundReportingShareOptions(fundShareValuation)}
      size="small"
      disabled={type !== FundReportingTypeEnum.CapitalAccountStatement}
      multiple
      required
      fullWidth
    />
  );
};
