import { useSnackbar } from 'notistack';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'translations/hook';

import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { ContactListManager } from 'business/shared/components/contact-list-manager';
import { LpUpsertContactForm } from 'business/shared/lp-creation-module/components/lp-upsert-contact-form';
import { ContactSanctionsModal } from 'business/shared/lp-creation-module/contact-sanctions-modal';
import { LpContactFormComponents } from 'business/shared/lp-creation-module/services/types';
import { ContactWithId } from 'business/shared/services/contact/types';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { useDeleteContactMutation } from 'generated/graphql';
import { CustomModal } from 'ui/custom-modal';
import { ContactFormContextEnum } from 'ui/types';

type ModalState =
  | (Pick<LpContactFormComponents, 'contact'> & {
      context: ContactFormContextEnum;
    })
  | {
      context: 'sanctions';
      contact: ContactWithId;
    };

interface Props {
  lpId: string;
  contacts: ContactWithId[];
  onAdded?: () => void;
  onDeleted?: () => void;
  formComponent?: React.FC<LpContactFormComponents>;
  header?: ReactNode;
}

const LpContactManagerEditableList = ({
  lpId,
  contacts,
  onAdded,
  onDeleted,
  header,
  formComponent: FormComponent = LpUpsertContactForm,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<ModalState | undefined>(undefined);
  const displayContactSanction = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_OPEN_SANCTION,
  );
  const isOnboardingUser = useIsLpOnboardingUser();

  const closeModal = () => setForm(undefined);

  const [deleteContactMutation] = useDeleteContactMutation({
    onError: () => {
      enqueueSnackbar(t('errors.deleteContact'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      onDeleted?.();
      enqueueSnackbar(t('successMessage.deleteContact'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const onDelete = (contact: ContactWithId) => {
    return deleteContactMutation({
      variables: {
        id: contact.id,
      },
    });
  };

  // For updates and additions
  const onChanged = () => {
    closeModal();
    onAdded?.();
  };

  return (
    <>
      <ContactListManager
        onAdd={() => setForm({ context: ContactFormContextEnum.ADD })}
        onEdit={(contact: ContactWithId) =>
          setForm({ contact, context: ContactFormContextEnum.EDIT })
        }
        onDelete={onDelete}
        onSeeSanctions={
          displayContactSanction && !isOnboardingUser
            ? (contact: ContactWithId) =>
                setForm({ contact, context: 'sanctions' })
            : undefined
        }
        contacts={contacts}
        header={header}
      />

      {form ? (
        <CustomModal
          open
          handleClose={closeModal}
          component={
            form.context !== 'sanctions' ? (
              <FormComponent
                lpId={lpId}
                onClose={closeModal}
                context={form.context}
                contact={form.contact}
                onChanged={onChanged}
              />
            ) : (
              <ContactSanctionsModal
                onClose={closeModal}
                id={form.contact.id}
                firstName={form.contact.firstName}
                lastName={form.contact.lastName}
              />
            )
          }
        />
      ) : null}
    </>
  );
};

export const LpContactManager = ({
  readOnly,
  contacts,
  header,
  ...rest
}: Props & { readOnly?: boolean }) => {
  if (readOnly) {
    return <ContactListManager {...rest} contacts={contacts} header={header} />;
  }

  return (
    <LpContactManagerEditableList
      {...rest}
      contacts={contacts}
      header={header}
    />
  );
};
