import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'translations/hook';

import { Typo } from 'ui/typo';

import { EditCellClue } from './edit-cell-clue';
import { isCellEditable } from './utils';

export const BooleanCell = (params: ICellRendererParams) => {
  const { t } = useTranslation();

  const value = params.value;
  const isEditable = isCellEditable(params);

  return (
    <>
      <Typo color={value ? 'primary' : 'secondary'}>
        {t(`common.${value ? 'yes' : 'no'}`)}
      </Typo>
      {isEditable && <EditCellClue params={params} />}
    </>
  );
};
