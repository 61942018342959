import { useParams } from 'react-router';

import { IndividualKYC } from 'business/fund-manager/lp/components/individual-kyc';
import { LegalEntityKyc } from 'business/fund-manager/lp/components/legal-entity-kyc';
import { ClientTypeEnum, useLpViewQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpKYC = () => {
  const { lpId = '' } = useParams();
  const { data, loading, error } = useLpViewQuery({ variables: { lpId } });

  if (loading || error || !data?.lpView?.[0].client) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const clientType = data.lpView[0].client;

  if (clientType === ClientTypeEnum.Individual) {
    return <IndividualKYC lpId={lpId} />;
  }

  return <LegalEntityKyc lpId={lpId} />;
};
