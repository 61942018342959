import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ModalButton } from 'ui/custom-modal/header/button';

import styles from './index.module.scss';

interface Props {
  title: string;
  onClose: () => void;
  labelAction?: string;
  labelClose?: string;
  canSubmit?: boolean;
  handleSubmit?: () => void;
  isSubmitting?: boolean;
  disableSubmit?: boolean;
}

export const ModalHeader = ({
  title,
  onClose,
  labelAction,
  labelClose,
  canSubmit,
  handleSubmit,
  isSubmitting,
  disableSubmit = false,
}: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  return (
    <Stack
      direction="row"
      className={styles.formHeader}
      padding={2}
      spacing={3}
      sx={{ bgcolor: palette.backgroundCard }}
    >
      <Stack justifyContent={'center'} className={styles.titleContainer}>
        <Typography>{title}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <ModalButton
          label={labelClose ?? t('pages.contact.form.label.cancel')}
          onClick={onClose}
        />
        {handleSubmit || canSubmit ? (
          <ModalButton
            label={labelAction ? labelAction : t('common.actions.submit')}
            type="submit"
            loading={isSubmitting}
            onClick={handleSubmit}
            disabled={disableSubmit}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};
