import { LpCreationStepEnum } from 'generated/graphql';

import { getContactsList } from './get-contacts-list';
import { getDeclineReason } from './get-decline-reason';
import { getLpContactsOnboardingStatus } from './get-lp-contacts-onboarding-status';
import {
  LpValidationLegalEntity,
  CardProps,
  GetCardItemsParams,
} from './types';

export const getLpContactsValidationCard = ({
  data,
  t,
  language,
  declineReasons,
}: GetCardItemsParams<LpValidationLegalEntity['lpContacts']>):
  | CardProps
  | undefined => {
  if (!data?.length) {
    return undefined;
  }

  const { type, entities } = getContactsList({
    data,
    t,
    language,
    withRoles: false,
  });

  const status = getLpContactsOnboardingStatus(data);
  const step = LpCreationStepEnum.Contacts;

  return {
    type,
    entities,
    title: t('pages.fundManager.lpValidation.contacts.title'),
    entityId: data[0].lpId,
    status,
    step,
    declineReason: getDeclineReason({ status, step, declineReasons }),
  };
};
