export enum FundManagerRoutes {
  Contacts = '/fund-manager/contacts',
  Home = '/fund-manager/',
  ContactSupport = '/fund-manager/contact-support',
  // Fund
  // WARNING, when adding fund routes please modify the regex here :
  // front/src/business/fund-manager/fund/services/constants.ts
  Fund = '/fund-manager/fund',
  FundCreate = '/fund-manager/fund/create',
  FundCreateId = '/fund-manager/fund/create/:fundId',
  FundCreateIdBank = '/fund-manager/fund/create/:fundId/bank',
  FundCreateIdShare = '/fund-manager/fund/create/:fundId/share',
  FundId = '/fund-manager/fund/:fundId',
  FundIdEditProfile = '/fund-manager/fund/:fundId/edit/profile',
  FundIdEditBank = '/fund-manager/fund/:fundId/edit/bank',
  FundIdEditShare = '/fund-manager/fund/:fundId/edit/share',
  FundIdSubscription = '/fund-manager/fund/:fundId/subscription',
  FundIdFinancialFlow = '/fund-manager/fund/:fundId/financial-flow',
  FundIdDocument = '/fund-manager/fund/:fundId/document',
  FundIdShareValuation = '/fund-manager/fund/:fundId/share-valuation',
  FundIdShareValuationCreate = '/fund-manager/fund/:fundId/share-valuation/create',
  FundIdShareValuationEdit = '/fund-manager/fund/:fundId/share-valuation/edit/:shareValuationId',
  FundIdDiligence = '/fund-manager/fund/:fundId/diligence',
  // like there is not other page for now we don't apply classic route action here
  // to make previous path finder works fine
  FundIdReportingCreate = '/fund-manager/fund/:fundId/reporting-create',
  // onboarding subscriptions
  OnboardingSubscription = '/fund-manager/onboarding-subscription',
  OnboardingSubscriptionRequested = '/fund-manager/onboarding-subscription/requested',
  OnboardingSubscriptionUnsuccessful = '/fund-manager/onboarding-subscription/unsuccessful',
  OnboardingSubscriptionHistory = '/fund-manager/onboarding-subscription/history',
  OnboardingSubscriptionCreate = '/fund-manager/onboarding-subscription/create',
  OnboardingSubscriptionId = '/fund-manager/onboarding-subscription/:onboardingSubscriptionId',
  // LPs routes (plural)
  Lp = '/fund-manager/lp',
  LpCreate = '/fund-manager/lp/create',
  // LP routes
  // WARNING, when adding lp routes please modify the regex here :
  // front/src/business/fund-manager/lp/services/constants.ts
  LpId = '/fund-manager/lp/:lpId',
  LpIdDocument = '/fund-manager/lp/:lpId/document',
  LpIdEdit = '/fund-manager/lp/:lpId/edit',
  LpIdEditProfile = '/fund-manager/lp/:lpId/edit/profile',
  LpIdEditBankDetails = '/fund-manager/lp/:lpId/edit/bank-details',
  LpIdContacts = '/fund-manager/lp/:lpId/contacts',
  LpIdFinancialFlow = '/fund-manager/lp/:lpId/financial-flow',
  LpIdEscrowAccount = '/fund-manager/lp/:lpId/escrow-account',
  LpIdSubscription = '/fund-manager/lp/:lpId/subscription',
  LpIdDiligence = '/fund-manager/lp/:lpId/diligence',
  LpIdDiligenceAmlRiskAssessment = '/fund-manager/lp/:lpId/diligence/risk-assessment',
  LpIdDiligenceKYC = '/fund-manager/lp/:lpId/diligence/kyc',
  LpIdDiligenceInvestorProfile = '/fund-manager/lp/:lpId/diligence/investor-profile',
  LpIdDiligenceLegalEntityKyb = '/fund-manager/lp/:lpId/diligence/legal-entity-kyb',
  LpIdDiligenceKYCDocumentation = '/fund-manager/lp/:lpId/diligence/kyc-documentation',
  LpIdDiligenceKYCKYBAutocompleteUpdate = '/fund-manager/lp/:lpId/diligence/kyc-kyb-autocomplete-update',

  // Operations
  Operations = '/fund-manager/operation',
  OperationCreate = '/fund-manager/operation/create',
  OperationCreateId = '/fund-manager/operation/create/:operationId',
  OperationCreateIdShares = '/fund-manager/operation/create/:operationId/shares',
  OperationCreateIdSharesLp = '/fund-manager/operation/create/:operationId/shares-lp',
  OperationCreateIdPreviewEmailDocument = '/fund-manager/operation/create/:operationId/preview-email-document',
  OperationCreateIdRecap = '/fund-manager/operation/create/:operationId/recap',
  OperationId = '/fund-manager/operation/:operationId',
  OperationIdInvestors = '/fund-manager/operation/:operationId/investors',
  OperationIdInvestorsOperationShareLpId = '/fund-manager/operation/:operationId/investors/:operationShareLpId',
  OperationIdInvestorsOperationShareLpIdPendingValidation = '/fund-manager/operation/:operationId/investors/:operationShareLpId/pending-validation-cashflows',
  OperationIdEmailing = '/fund-manager/operation/:operationId/emailing',
  // portfolio
  Portfolio = '/fund-manager/portfolio',
  PortfolioFundId = '/fund-manager/portfolio/:fundId',
  PortfolioFundIdCompany = '/fund-manager/portfolio/:fundId/company',
  PortfolioFundIdCompanyId = '/fund-manager/portfolio/:fundId/company/:companyId',
  PortfolioFundIdCompanyIdMetrics = '/fund-manager/portfolio/:fundId/company/:companyId/metrics',
  PortfolioFundIdCompanyIdEditProfile = '/fund-manager/portfolio/:fundId/company/:companyId/edit/profile',
  PortfolioFundIdCompanyIdEditInvestment = '/fund-manager/portfolio/:fundId/company/:companyId/edit/investment',
  PortfolioFundIdCompanyIdEditContact = '/fund-manager/portfolio/:fundId/company/:companyId/edit/contact',
  PortfolioFundIdCompanyCreate = '/fund-manager/portfolio/:fundId/company/create',
  PortfolioFundIdCompanyCreateId = '/fund-manager/portfolio/:fundId/company/create/:companyId',
  PortfolioFundIdCompanyCreateIdInvestments = '/fund-manager/portfolio/:fundId/company/create/:companyId/investments',
  PortfolioFundIdCompanyCreateIdDocumentation = '/fund-manager/portfolio/:fundId/company/create/:companyId/documentation',
  PortfolioFundIdCompanyCreateIdContact = '/fund-manager/portfolio/:fundId/company/create/:companyId/contact',
  PortfolioFundIdInventory = '/fund-manager/portfolio/:fundId/inventory',
  PortfolioFundIdInventoryDetails = '/fund-manager/portfolio/:fundId/inventory/details',
  PortfolioFundIdValuation = '/fund-manager/portfolio/:fundId/valuation',
  PortfolioFundIdValuationHistory = '/fund-manager/portfolio/:fundId/valuation/history',
  PortfolioFundIdValuationId = '/fund-manager/portfolio/:fundId/valuation/:valuationId',
  // settings
  // Also subroute for fund manager advisor settings, allways have them prefix by /fund-manager/settings/advisor/:advisorId
  Settings = '/fund-manager/settings/',
  SettingsAdvisor = '/fund-manager/settings/advisor',
  SettingsAdvisorId = '/fund-manager/settings/advisor/:advisorId',
  SettingsAdvisorCreate = '/fund-manager/settings/advisor/create',
  SettingsProfile = '/fund-manager/settings/profile',
  SettingsAdvisorIdProfile = '/fund-manager/settings/advisor/:advisorId/profile',
  SettingsTemplateList = '/fund-manager/settings/template-list',
  SettingsAdvisorIdTemplateList = '/fund-manager/settings/advisor/:advisorId/template-list',
  SettingsTemplateListIdEmailTemplate = '/fund-manager/settings/template-list/:templateListId/email-template',
  SettingsAdvisorIdTemplateListIdEmailTemplate = '/fund-manager/settings/advisor/:advisorId/template-list/:templateListId/email-template',
  SettingsTemplateListIdEmailTemplateId = '/fund-manager/settings/template-list/:templateListId/email-template/:emailTemplateId',
  SettingsAdvisorIdTemplateListIdEmailTemplateId = '/fund-manager/settings/advisor/:advisorId/template-list/:templateListId/email-template/:emailTemplateId',
  SettingsTemplateListIdUploadDocX = '/fund-manager/settings/template-list/:templateListId/upload-docx-template',
  SettingsAdvisorIdTemplateListIdUploadDocX = '/fund-manager/settings/advisor/:advisorId/template-list/:templateListId/upload-docx-template',
  SettingsLpDocumentationType = '/fund-manager/settings/lp-document-type',
  SettingsAdvisorIdLpDocumentationType = '/fund-manager/settings/advisor/:advisorId/lp-document-type',
  SettingsFundDocumentationType = '/fund-manager/settings/fund-document-type',
  SettingsAdvisorIdFundDocumentationType = '/fund-manager/settings/advisor/:advisorId/fund-document-type',
  SettingsMetrics = '/fund-manager/settings/metrics',
  SettingsAdvisorIdMetrics = '/fund-manager/settings/advisor/:advisorId/metrics',
  SettingsPublicationData = '/fund-manager/settings/publication-data',
  SettingsAdvisorIdPublicationData = '/fund-manager/settings/advisor/:advisorId/publication-data',
}

export default FundManagerRoutes;
