import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import {
  CompanyMetricsTypeFormContextEnum,
  CompanyMetricsTypeInformation,
  CreateOrUpdateCompanyMetricsTypeForm,
  MetricsUnitEnum,
  isCompanyMetricsTypeInformation,
} from 'business/fund-manager/settings/company-metrics-type/services/types';
import { createOrUpdateCompanyMetricsTypeFormSchema } from 'business/fund-manager/settings/company-metrics-type/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  DefaultDataRankEnum,
  DefaultPeriodTypeEnum,
  useCreateOrUpdateCompanyMetricsTypeMutation,
} from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText, FormToggleButton } from 'ui/form';
import { FormInputCheckBox } from 'ui/form/form-input-checkbox';
import { FormModalContainer } from 'ui/form-modal-container';

interface Props {
  closeForm: () => void;
  initialValues:
    | CompanyMetricsTypeInformation
    | { managementCompanyId: string };
  context: CompanyMetricsTypeFormContextEnum;
  onCompleted: () => void;
}
export const CompanyMetricsTypeForm = ({
  closeForm,
  initialValues,
  context,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    resolver: yupResolver(createOrUpdateCompanyMetricsTypeFormSchema),
    defaultValues: isCompanyMetricsTypeInformation(initialValues)
      ? {
          id: initialValues.id,
          name: initialValues.name,
          unit: initialValues.unit,
          isDeletable: initialValues.isDeletable,
          managementCompanyId: initialValues.managementCompanyId,
          defaultPeriodType: initialValues.defaultPeriodType,
          defaultDataRank: initialValues.defaultDataRank,
        }
      : {
          id: '',
          name: '',
          unit: MetricsUnitEnum.NinePointNine,
          isDeletable: false,
          managementCompanyId: initialValues.managementCompanyId,
          defaultPeriodType: DefaultPeriodTypeEnum.Monthly,
          defaultDataRank: DefaultDataRankEnum.Budget,
        },
  });

  const [CreateOrUpdateCompanyMetricsTypeMutation, { loading, error }] =
    useCreateOrUpdateCompanyMetricsTypeMutation({
      onError: () =>
        enqueueSnackbar(
          t('errors.createOrUpdateCompanyMetrics', {
            context,
          }),
          {
            variant: VariantTypeEnum.ERROR,
          },
        ),
      onCompleted: () => {
        onCompleted();
        closeForm();
        enqueueSnackbar(
          t('successMessage.createOrUpdateCompanyMetrics', {
            context,
          }),
          {
            variant: VariantTypeEnum.SUCCESS,
          },
        );
      },
    });

  const onSubmit = (input: CreateOrUpdateCompanyMetricsTypeForm) =>
    CreateOrUpdateCompanyMetricsTypeMutation({
      variables: {
        input,
      },
    }).catch(() => undefined);

  const { handleSubmit, control, formState } = methods;

  return (
    <FormModalContainer
      title={t('pages.fundManager.settings.companyMetrics.form.title', {
        context,
      })}
      onSubmit={handleSubmit(onSubmit)}
      onClose={closeForm}
      isSubmitting={formState.isSubmitting}
      labelAction={t('pages.fundManager.settings.companyMetrics.form.submit', {
        context,
      })}
    >
      <Stack spacing={4} alignSelf="left">
        {error ? (
          <ErrorLabel
            label={t('errors.createOrUpdateCompanyMetrics', {
              context,
            })}
          />
        ) : null}
        <FormInputText
          name="name"
          label={t('pages.fundManager.settings.companyMetrics.form.name')}
          control={control}
          required
          disabled={loading}
        />

        <FormToggleButton
          name="unit"
          label={t('pages.fundManager.settings.companyMetrics.form.unit')}
          options={[
            {
              label: MetricsUnitEnum.NinePointNine,
              value: MetricsUnitEnum.NinePointNine,
            },
            {
              label: MetricsUnitEnum.Percent,
              value: MetricsUnitEnum.Percent,
            },
            {
              label: MetricsUnitEnum.Thousand,
              value: MetricsUnitEnum.Thousand,
            },
            {
              label: MetricsUnitEnum.Million,
              value: MetricsUnitEnum.Million,
            },
          ]}
          control={control}
          required
          fullWidth
        />

        <FormToggleButton
          name="defaultPeriodType"
          label={t(
            'pages.fundManager.settings.companyMetrics.form.defaultPeriodType',
          )}
          options={[
            {
              label: DefaultPeriodTypeEnum.Monthly,
              value: DefaultPeriodTypeEnum.Monthly,
            },
            {
              label: DefaultPeriodTypeEnum.Quarterly,
              value: DefaultPeriodTypeEnum.Quarterly,
            },
            {
              label: DefaultPeriodTypeEnum.Yearly,
              value: DefaultPeriodTypeEnum.Yearly,
            },
          ]}
          required
          control={control}
          fullWidth
        />

        <FormToggleButton
          name="defaultDataRank"
          label={t(
            'pages.fundManager.settings.companyMetrics.form.defaultDataRank',
          )}
          options={[
            {
              label: DefaultDataRankEnum.Budget,
              value: DefaultDataRankEnum.Budget,
            },
            {
              label: DefaultDataRankEnum.BusinessPlan,
              value: DefaultDataRankEnum.BusinessPlan,
            },
            {
              label: DefaultDataRankEnum.Estimated,
              value: DefaultDataRankEnum.Estimated,
            },
            {
              label: DefaultDataRankEnum.Realized,
              value: DefaultDataRankEnum.Realized,
            },
          ]}
          required
          control={control}
          fullWidth
        />

        <FormInputCheckBox
          name="isDeletable"
          label={t(
            'pages.fundManager.settings.companyMetrics.form.isDeletable',
          )}
          control={control}
        />
      </Stack>
    </FormModalContainer>
  );
};
