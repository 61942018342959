import { Stack } from '@mui/material';
import classNames from 'classnames';
import { ComponentProps } from 'react';

import { DocumentStatusEnum } from 'business/fund-manager/fund/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { PaletteVarKey } from 'business/providers/theme/services/types';
import { AddFileIcon, WarningIcon } from 'ui/icons';
import { CheckmarkRoundedIcon } from 'ui/icons/checkmark-rounded';
import { ClockIcon } from 'ui/icons/clock';
import { PendingIcon } from 'ui/icons/pending';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

type Props = {
  status: DocumentStatusEnum;
  text: string;
  className?: string;
  onAdd?: () => void;
};

const getIconAndColorName = (
  status: DocumentStatusEnum,
): {
  colorName: PaletteVarKey;
  icon: React.FC<ComponentProps<typeof CheckmarkRoundedIcon>>;
} => {
  switch (status) {
    case DocumentStatusEnum.Validated:
      return {
        icon: CheckmarkRoundedIcon,
        colorName: 'documentStatusValidated',
      };
    case DocumentStatusEnum.PendingValidation:
      return {
        icon: PendingIcon,
        colorName: 'documentStatusPendingValidation',
      };
    case DocumentStatusEnum.Declined:
      return {
        icon: WarningIcon,
        colorName: 'dangerMain',
      };
    case DocumentStatusEnum.Expired:
      return {
        icon: ClockIcon,
        colorName: 'documentStatusExpired',
      };
    case DocumentStatusEnum.Requested:
      return {
        icon: AddFileIcon,
        colorName: 'documentStatusRequested',
      };
  }
};

export const DocumentStatus = ({ status, text, className, onAdd }: Props) => {
  const palette = usePalette();

  const { icon: Icon, colorName } = getIconAndColorName(status);

  return (
    <Stack
      className={classNames(className, styles.stack, {
        [styles.clickable]: onAdd ? true : false,
      })}
      direction="row"
      onClick={onAdd}
    >
      <Stack direction="row" alignItems="center" flexGrow={1}>
        <Icon primaryColor={palette[colorName]} />
        <Typo color={colorName} size="sm" className={styles.text}>
          {text}
        </Typo>
      </Stack>
      {onAdd ? (
        <Typo color={colorName} size="sm" className={styles.text}>
          +
        </Typo>
      ) : null}
    </Stack>
  );
};
