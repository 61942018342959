import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { DeclineConfirmationModal } from 'business/fund-manager/lp/components/decline-confirmation-modal';
import { getIndividualValidationCards } from 'business/fund-manager/lp/services/validation-cards/get-individual-validation-cards';
import { getLegalEntityValidationCards } from 'business/fund-manager/lp/services/validation-cards/get-legal-entity-validation-cards';
import { CustomQuestionValidationPart } from 'business/fund-manager/onboarding-subscription/components/custom-question-validation-part/custom-question-validation-part';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useLanguageContext } from 'business/providers/language-provider';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  ClientTypeEnum,
  LpCreationStepEnum,
  OnBoardingStatusEnum,
  OnboardingSubscriptionStateQuery,
  useLpDeclineAllEntitiesMutation,
  useLpDeclineEntityMutation,
  useLpValidateAllEntitiesMutation,
  useLpValidateEntityMutation,
  useOnboardingSubscriptionStateLazyQuery,
  useWebDocumentDownloadUrlLazyQuery,
} from 'generated/graphql';
import { downloadFile } from 'technical/download-file';
import { ActionButton } from 'ui/action-button';
import { ValidationCard } from 'ui/validation-card';

export const ProfileValidation = ({
  id: onboardingSubscriptionId,
  lpState,
}: NonNullable<
  OnboardingSubscriptionStateQuery['onboardingSubscriptionState']
>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lpId = lpState.id;

  const [getFileUrl] = useWebDocumentDownloadUrlLazyQuery();
  const [approvingStep, setApprovingStep] = useState<
    LpCreationStepEnum | undefined
  >();
  const [confirmDecline, setConfirmDecline] = useState<
    { step: LpCreationStepEnum; entityId: string } | undefined
  >(undefined);
  const [confirmDeclineAll, setConfirmDeclineAll] = useState<boolean>(false);
  const { language } = useLanguageContext();

  // TODO PE3-1174 - move function to get-document-card-items.ts
  const onClickDownload = async ({
    id,
    fileName,
  }: {
    id: string;
    fileName: string;
  }) => {
    const resp = await getFileUrl({
      variables: {
        input: { ids: [id] },
      },
    });
    if (resp.data?.documentDownloadUrl.url) {
      downloadFile(resp.data.documentDownloadUrl.url, fileName);
    }
  };

  // all mutation below must return onboardingSubscriptionState
  // but too avoid burning to much I made this hack
  // TODO remove it with PE3-1925 https://app.clickup.com/t/20446702/PE3-1925

  const [refreshOnboardingSubscriptionState] =
    useOnboardingSubscriptionStateLazyQuery({
      variables: {
        onboardingSubscriptionId,
      },
      fetchPolicy: 'network-only',
    });

  const [validate] = useLpValidateEntityMutation({
    onCompleted: () => {
      setApprovingStep(undefined);
      enqueueSnackbar(t('successMessage.validateLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      refreshOnboardingSubscriptionState();
    },
    onError: () => {
      setApprovingStep(undefined);
      enqueueSnackbar(t('errors.validateLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const [decline, { loading: declining }] = useLpDeclineEntityMutation({
    onCompleted: () => {
      setConfirmDecline(undefined);
      enqueueSnackbar(t('successMessage.declineLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      refreshOnboardingSubscriptionState();
    },
    onError: () => {
      setConfirmDecline(undefined);
      enqueueSnackbar(t('errors.declineLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const [declineAll, { loading: decliningAll }] =
    useLpDeclineAllEntitiesMutation({
      onCompleted: () => {
        setConfirmDeclineAll(false);
        enqueueSnackbar(t('successMessage.declineAll'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        refreshOnboardingSubscriptionState();
      },
      onError: () => {
        setConfirmDeclineAll(false);
        enqueueSnackbar(t('errors.declineAll'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const [validateAll, { loading: validatingAll }] =
    useLpValidateAllEntitiesMutation({
      refetchQueries: ['onboardingSubscriptionState'],
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.validateAll'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        navigate(
          FundManagerRoutes.OnboardingSubscriptionId.replace(
            ':onboardingSubscriptionId',
            onboardingSubscriptionId,
          ),
        );
      },
      onError: () => {
        enqueueSnackbar(t('errors.validateAll'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const onDecline = ({
    entityId,
    step,
    comment,
  }: {
    entityId: string;
    step: LpCreationStepEnum;
    comment: string;
  }) =>
    decline({
      variables: {
        input: {
          entityId,
          step,
          comment,
        },
      },
    });

  const onDeclineAll = (comment: string) =>
    declineAll({
      variables: {
        input: {
          lpId,
          comment,
        },
      },
    });

  const onValidateAll = () =>
    validateAll({
      variables: {
        input: {
          lpId,
        },
      },
    });

  const onApprove = ({
    entityId,
    step,
  }: {
    entityId: string;
    step: LpCreationStepEnum;
  }) => {
    setApprovingStep(step);
    return validate({
      variables: {
        input: {
          entityId,
          step,
        },
      },
    });
  };

  const withLpInvestorProfileStep = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
  );

  const cards =
    lpState.client === ClientTypeEnum.LegalEntity
      ? getLegalEntityValidationCards(
          {
            data: lpState,
            language,
            t,
            declineReasons: lpState.declineReasons,
            withLpInvestorProfileStep,
          },
          onClickDownload,
        )
      : getIndividualValidationCards(
          {
            data: lpState,
            language,
            t,
            declineReasons: lpState.declineReasons,
            withLpInvestorProfileStep,
          },
          onClickDownload,
        );

  const hasActivatedCustomQuestions = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_CUSTOM_QUESTION,
  );

  return (
    <>
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
        <ActionButton
          size="small"
          variant="danger"
          loading={decliningAll}
          disabled={declining || validatingAll}
          onClick={() => setConfirmDeclineAll(true)}
        >
          {t('common.actions.declineAll')}
        </ActionButton>
        <ActionButton
          size="small"
          variant="submit"
          loading={validatingAll}
          disabled={declining || decliningAll}
          onClick={onValidateAll}
        >
          {t('common.actions.validateAll')}
        </ActionButton>
      </Stack>

      <Stack overflow="auto" spacing={2} justifyContent="start" padding={2}>
        {cards.map(({ step, entityId, ...cardProps }) => (
          <ValidationCard
            {...cardProps}
            key={`${step}-${entityId}`}
            onDecline={() => setConfirmDecline({ step, entityId })}
            onApprove={() => onApprove({ step, entityId })}
            approving={approvingStep === step}
            expand={
              cardProps.status === undefined ||
              cardProps.status === OnBoardingStatusEnum.PendingValidation
            }
          >
            {hasActivatedCustomQuestions ? (
              <CustomQuestionValidationPart
                entityStep={step}
                lpId={lpState.id}
              />
            ) : null}
          </ValidationCard>
        ))}
      </Stack>

      {confirmDecline ? (
        <DeclineConfirmationModal
          open
          onCancel={() => setConfirmDecline(undefined)}
          onSubmit={({ comment }) => onDecline({ ...confirmDecline, comment })}
          title={t('common.actions.decline')}
        />
      ) : null}

      {confirmDeclineAll ? (
        <DeclineConfirmationModal
          open
          onCancel={() => setConfirmDeclineAll(false)}
          onSubmit={({ comment }) => onDeclineAll(comment)}
          title={t('common.actions.declineAll')}
        />
      ) : null}
    </>
  );
};
