import { DownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { SharesCommitmentsQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

export const getFundReportingCommitmentTableDefinition = (
  t: TFunction,
  onClickDownload: ({
    lpId,
    shareId,
  }: {
    lpId: string;
    shareId: string;
  }) => Promise<void>,
): ColDef<SharesCommitmentsQuery['sharesCommitments'][number]>[] => [
  {
    field: 'lpView.lpName',
    headerName: t('pages.fundManager.reporting.contactTable.lpName'),
  },
  {
    field: 'share.name',
    headerName: t('pages.fundManager.reporting.contactTable.shareName'),
  },
  {
    type: AgGridColumnTypesEnum.INTERACTION,
    headerName: t('pages.fundManager.reporting.contactTable.actions'),
    cellRenderer: ({ data }: ICellRendererParams) => (
      <Tooltip
        title={t(
          'pages.fundManager.reporting.contactTable.previewDocumentTooltip',
        )}
      >
        <IconButton
          onClick={() => {
            onClickDownload({
              lpId: data.lpView.id,
              shareId: data.share.id,
            });
          }}
        >
          <DownloadOutlined />
        </IconButton>
      </Tooltip>
    ),
  },
];
