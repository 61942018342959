import { useTranslation } from 'translations/hook';

import { AddSubscriptionForm } from 'business/shared/components/add-subscription-form';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';

interface Props {
  fundId?: string;
  lpId?: string;
  onCompleted?: () => void;
}

export const AddSubscriptionButton = ({ fundId, onCompleted, lpId }: Props) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <ActionButton variant="submit" onClick={handleOpenModal}>
        {t('pages.fundManager.subscription.button.add')}
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <AddSubscriptionForm
            defaultFundId={fundId}
            defaultLpId={lpId}
            handleClose={handleCloseModal}
            onCompleted={onCompleted}
          />
        }
      />
    </>
  );
};
