import { Outlet, RouteObject } from 'react-router';

import { OperationPage } from 'business/fund-manager/operation/components/operation-page';
import { CreateOperationShareLpPreviewEmailDocument } from 'business/fund-manager/operation/pages/create-operation-email-preview';
import { CreateOperationRecap } from 'business/fund-manager/operation/pages/create-operation-recap';
import { CreateOperationSharesRecap } from 'business/fund-manager/operation/pages/create-operation-shares-recap';
import { OperationDraftEdition } from 'business/fund-manager/operation/pages/edition-draft-operation';
import { OperationDetails } from 'business/fund-manager/operation/pages/operation-details';
import { OperationEmailing } from 'business/fund-manager/operation/pages/operation-emailing/operation-emailing';
import { OperationInvestorCashflows } from 'business/fund-manager/operation/pages/operation-investor-cashflows';
import { OperationInvestorCashflowsPendingValidation } from 'business/fund-manager/operation/pages/operation-investor-cashflows-pending-validation';
import { OperationList } from 'business/fund-manager/operation/pages/operation-list';
import { OperationOverview } from 'business/fund-manager/operation/pages/operation-overview';
import { CreateOperationPage } from 'business/fund-manager/operation/pages/step-1-create-operation';
import { CreateOperationSharesSelection } from 'business/fund-manager/operation/pages/step-2-create-operation-shares-selection';
import FundManagerRoutes from 'business/fund-manager/router/routes';

export const operationRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.Operations,
    element: (
      <OperationPage>
        <Outlet />
      </OperationPage>
    ),
    children: [
      {
        path: FundManagerRoutes.Operations,
        element: <OperationList />,
      },
      {
        path: FundManagerRoutes.OperationCreate,
        children: [
          {
            path: FundManagerRoutes.OperationCreate,
            element: <CreateOperationPage />,
          },
          {
            path: FundManagerRoutes.OperationCreateId,
            element: <OperationDraftEdition />,
          },
          {
            path: FundManagerRoutes.OperationCreateIdShares,
            element: <CreateOperationSharesSelection />,
          },
          {
            path: FundManagerRoutes.OperationCreateIdSharesLp,
            element: <CreateOperationSharesRecap />,
          },
          {
            path: FundManagerRoutes.OperationCreateIdPreviewEmailDocument,
            element: <CreateOperationShareLpPreviewEmailDocument />,
          },
          {
            path: FundManagerRoutes.OperationCreateIdRecap,
            element: <CreateOperationRecap />,
          },
        ],
      },
      {
        path: FundManagerRoutes.OperationId,
        children: [
          {
            path: FundManagerRoutes.OperationId,
            element: <OperationOverview />,
          },
          {
            path: FundManagerRoutes.OperationIdInvestors,
            element: <OperationDetails />,
          },
          {
            path: FundManagerRoutes.OperationIdInvestorsOperationShareLpId,
            element: <OperationInvestorCashflows />,
          },
          {
            path: FundManagerRoutes.OperationIdEmailing,
            element: <OperationEmailing />,
          },
          {
            path: FundManagerRoutes.OperationIdInvestorsOperationShareLpIdPendingValidation,
            element: <OperationInvestorCashflowsPendingValidation />,
          },
        ],
      },
    ],
  },
];
