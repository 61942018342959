import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpCustomQuestions } from 'business/shared/lp-creation-module/components/lp-custom-questions/lp-custom-questions';
import { LpIndividualKYCFormUI } from 'business/shared/lp-creation-module/components/lp-individual-kyc-form-ui';
import { useIndividualKYCForm } from 'business/shared/lp-creation-module/services/hooks/use-individual-kyc-form';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  GetLpIndividualKycQuery,
  LpCreationStepEnum,
  useGetLpIndividualKycQuery,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { FormCard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

interface IndividualKYCFormProps {
  lpId: string;
  data: GetLpIndividualKycQuery['lpKYC'];
}

const IndividualKYCForm = ({ lpId, data }: IndividualKYCFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { methods, onSubmit, saving, error } = useIndividualKYCForm({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    data,
    lpId,
  });

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const action = isAdvisor ? undefined : (
    <ActionButton variant="submit" type="submit" loading={saving}>
      {t('common.actions.save')}
    </ActionButton>
  );

  const hasActivatedCustomQuestions = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_CUSTOM_QUESTION,
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <LpIndividualKYCFormUI
            error={error}
            saving={saving}
            action={action}
          />
        </Stack>

        {hasActivatedCustomQuestions ? (
          <LpCustomQuestions
            lpId={lpId}
            entityStep={LpCreationStepEnum.IndividualKyc}
          />
        ) : null}
      </form>
    </FormProvider>
  );
};

export const IndividualKYC = ({ lpId }: { lpId: string }) => {
  const { data, loading, error } = useGetLpIndividualKycQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  return (
    <FormCard>
      {loading || error || !data ? (
        <QueryStateDisplay loading={loading} error={error} />
      ) : (
        <IndividualKYCForm data={data.lpKYC} lpId={lpId} />
      )}
    </FormCard>
  );
};
