import { Stack, Typography } from '@mui/material';

import styles from './index.module.scss';

interface Props {
  main: string;
  secondary?: string;
}

export const TextWithOptionalParenthesisText = ({ main, secondary }: Props) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography>{main}</Typography>
      {secondary ? (
        <Typography className={styles.bold}>({secondary})</Typography>
      ) : null}
    </Stack>
  );
};
