import { Draw } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { UploadDocumentTemplateForm } from 'business/fund-manager/shared/components/upload-document-template-form/upload-document-template-form';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { UploadDocumentTemplateType } from 'business/shared/services/document/types';
import { useUpdateOnboardingSubscriptionTemplateDocumentMutation } from 'generated/graphql';
import { IFile } from 'technical/file-management/types';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { ModalHeader } from 'ui/custom-modal/header';

interface OverrideTemplateButtonProps {
  onboardingSubscriptionId: string;
  onCompleted: () => void;
  disabled: boolean;
}
export const OverrideTemplateButton = ({
  onCompleted,
  onboardingSubscriptionId,
  disabled,
}: OverrideTemplateButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { open, handleCloseModal, handleOpenModal } = useModal();

  const [updateOnboardingSubscriptionTemplateDocument] =
    useUpdateOnboardingSubscriptionTemplateDocumentMutation({
      onCompleted: () => {
        enqueueSnackbar(
          t(
            'pages.onboardingSubscription.readyForSignature.uploadDocumentTemplate.success',
          ),
          {
            variant: VariantTypeEnum.SUCCESS,
          },
        );

        handleCloseModal();
        onCompleted();
      },
      onError: () => {
        enqueueSnackbar(
          t(
            'pages.onboardingSubscription.readyForSignature.uploadDocumentTemplate.error',
          ),
          {
            variant: VariantTypeEnum.ERROR,
          },
        );
      },
    });

  const onSubmit = ({
    formValues,
    uploadedFileList,
  }: {
    uploadedFileList: IFile[];
    formValues: UploadDocumentTemplateType;
  }) =>
    updateOnboardingSubscriptionTemplateDocument({
      variables: {
        input: {
          filePath: formValues.filePath,
          fileName: uploadedFileList[0].name,
          onboardingSubscriptionId,
        },
      },
    }).catch(() => undefined);

  return (
    <>
      <ActionButton
        variant="primary"
        onClick={handleOpenModal}
        disabled={disabled}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Draw fontSize="inherit" />
          <span>
            {t(
              'pages.onboardingSubscription.readyForSignature.overrideTemplate',
            )}
          </span>
        </Stack>
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <Stack>
            <ModalHeader
              onClose={handleCloseModal}
              title={t(
                'pages.onboardingSubscription.readyForSignature.overrideTemplate',
              )}
            />

            <Stack padding={3}>
              <UploadDocumentTemplateForm onSubmit={onSubmit} />
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
