import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import { OperationCreationStepEnum } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useSelectOperationSharesMutation,
  useGetFundSharesByOperationIdQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

import { ShareSelectionForm } from './share-selection-form';

export const CreateOperationSharesSelection = () => {
  // fund id must be loaded from query
  const { operationId = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading, error } = useGetFundSharesByOperationIdQuery({
    variables: { operationId },
    fetchPolicy: 'network-only',
  });

  const [selectOperationShares, { loading: saving, error: saveError }] =
    useSelectOperationSharesMutation({
      onCompleted: () => {
        navigate(
          FundManagerRoutes.OperationCreateIdSharesLp.replace(
            ':operationId',
            operationId,
          ),
        );
        enqueueSnackbar(t('successMessage.createOperationShare'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  if (loading || error || data === undefined) {
    return (
      <OperationCreationContainer
        activeStep={OperationCreationStepEnum.Shares}
        onPrevious={() =>
          navigate(
            FundManagerRoutes.OperationCreateId.replace(
              'operationId',
              operationId,
            ),
          )
        }
      >
        <QueryStateDisplay loading={loading} error={error} />
      </OperationCreationContainer>
    );
  }

  return (
    <ShareSelectionForm
      shares={data!}
      operationId={operationId}
      submitShares={selectOperationShares}
      loading={saving}
      error={saveError}
    />
  );
};
