import * as yup from 'yup';

import {
  WebCompanyTransactionTypeEnum,
  Web_Portal_CompanyTransactionType_Enum,
} from 'generated/graphql';

import {
  addInstrumentValuationFormSchema,
  createValuationForm,
  createValuationProceedFormSchema,
} from './validation';

export type CreateValuationFormInput = yup.InferType<
  typeof createValuationForm
>;

export type CreateValuationProceedFormInput = yup.InferType<
  typeof createValuationProceedFormSchema
>;

export type InstrumentValuationTable = {
  companyInstrument: {
    id: string;
    company: { name: string };
    transactions: {
      id: string;
      nominalValue: number;
      quantity: number;
      type:
        | WebCompanyTransactionTypeEnum
        | Web_Portal_CompanyTransactionType_Enum;
    }[];
    [key: string]: unknown;
  };
  id: string;
  accruedCoupon?: number | null;
  fairValue?: number | null;
};

export type AddInstrumentValuationFormInput = yup.InferType<
  typeof addInstrumentValuationFormSchema
>;

export enum ValuationTab {
  Synthesis = 0,
  History = 1,
}

export enum InstrumentValuationTabEnum {
  Valuation = 0,
  ProceedForm = 1,
}

export type FundValuationConfirmation = {
  fairValue?: bigint | number | null;
  accruedCoupon?: bigint | number | null;
  [key: string]: unknown;
};
