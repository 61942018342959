import { Stack } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { getFieldName } from 'technical/get-field-name';
import { Contact } from 'ui/contact-form/types';
import { FormInputText, SelectTitleInput } from 'ui/form';

export interface Props<T extends FieldValues> {
  name?: string;
  control?: Control<T>;
  readonly?: boolean;
}

export const ContactIdentityForm = <T extends FieldValues>({
  name,
  control,
  readonly = false,
}: Props<T>) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2}>
      <SelectTitleInput
        id="title"
        name={getFieldName<Contact>('title', name)}
        label={t('pages.contact.form.label.title')}
        fullWidth
        control={control}
        disabled={readonly}
      />
      <FormInputText
        id="firstName"
        name={getFieldName<Contact>('firstName', name)}
        required
        fullWidth
        label={t('pages.contact.form.label.firstName')}
        control={control}
        disabled={readonly}
      />

      <FormInputText
        id="lastName"
        name={getFieldName<Contact>('lastName', name)}
        required
        fullWidth
        label={t('pages.contact.form.label.lastName')}
        control={control}
        disabled={readonly}
      />
    </Stack>
  );
};
