import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Stack } from '@mui/material';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { replaceRouteWithPrefix } from 'business/fund-manager/settings/shared/hooks/route-prefix-utils';
import { AddOrEditEmail } from 'business/fund-manager/settings/template/components/add-or-edit-email';
import styles from 'business/fund-manager/settings/template/components/add-or-edit-email/index.module.scss';
import { DownloadDocumentTemplatePreview } from 'business/fund-manager/settings/template/components/download-generated-document';
import {
  GetTemplatesByManagementCompanyQuery,
  Web_Portal_TemplateType_Enum,
} from 'generated/graphql';
import Link from 'ui/link';

interface Props {
  data: GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number];
  routePrefix: string;
}
export const AddOrEditDocumentTemplate = ({ data, routePrefix }: Props) => {
  if (data.type === Web_Portal_TemplateType_Enum.Email) {
    return <AddOrEditEmail data={data} routePrefix={routePrefix} />;
  }
  if (data.type === Web_Portal_TemplateType_Enum.Pdf) {
    return (
      <Stack direction={'row'} spacing={2}>
        {data.documentTemplates?.[0]?.id ? (
          <DownloadDocumentTemplatePreview
            documentTemplateId={data.documentTemplates[0].id}
          />
        ) : (
          <></>
        )}
        <Link
          to={replaceRouteWithPrefix(
            FundManagerRoutes.SettingsTemplateListIdUploadDocX,
            routePrefix,
          ).replace(':templateListId', data.id)}
          className={styles.alignCenter}
        >
          <AddCircleOutlineIcon />
        </Link>
      </Stack>
    );
  }
  return null;
};
