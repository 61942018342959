import FundManagerRoutes from 'business/fund-manager/router/routes';
import { replaceRouteWithPrefix } from 'business/fund-manager/settings/shared/hooks/route-prefix-utils';
import { GetTemplatesByManagementCompanyQuery } from 'generated/graphql';

export function getEmailTemplateLink(
  data: GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number],
  routePrefix: string,
) {
  if (data.emailTemplate) {
    return `${replaceRouteWithPrefix(
      FundManagerRoutes.SettingsTemplateListIdEmailTemplateId,
      routePrefix,
    )
      .replace(':templateListId', data.id)
      .replace(':emailTemplateId', data.emailTemplate?.id)}`;
  }
  return `${replaceRouteWithPrefix(
    FundManagerRoutes.SettingsTemplateListIdEmailTemplate,
    routePrefix,
  ).replace(':templateListId', data.id)}`;
}
