import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import {
  ValuationProgressEnum,
  ValuationSecuritiesQuery,
} from 'generated/graphql';
import { arraySum } from 'technical/ag-grid';
import { formatRowValueToMonetary } from 'technical/ag-grid/row-value-formatters';
import ThemedBrownButton from 'ui/themed-brown-button';

type RowType = ValuationSecuritiesQuery['valuationSecurities'][number];

export function getValuationColumnDefinition(
  t: TFunction,
  handleOpenModal: ({
    instId,
    instValId,
  }: {
    instId: string;
    instValId: string;
  }) => void,
): ColDef<RowType>[] {
  return [
    {
      headerName: t('pages.fundManager.portfolio.valuation.table.companyName'),
      field: 'company.name',
      sortable: true,
      rowGroup: true,
      flex: 1,
      filter: true,
      hide: true,
      aggFunc: 'sum',
    },
    {
      field: 'name',
      headerName: t(
        'pages.fundManager.portfolio.valuation.table.instrument.title',
      ),
      sortable: true,
      flex: 2,
      filter: true,
      pinned: 'left',
      autoHeight: true,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuation.table.valuationProgress.title',
      ),
      field: 'valuationProgress',
      aggFunc: (params) => {
        if (params.values.length === 0) {
          return ValuationProgressEnum.PendingValuation;
        }

        if (
          params.values.every((val) => val === ValuationProgressEnum.Exited)
        ) {
          return ValuationProgressEnum.Exited;
        }

        if (
          params.values.every((val) => val === ValuationProgressEnum.Valuated)
        ) {
          return ValuationProgressEnum.Valuated;
        }

        return ValuationProgressEnum.PendingValuation;
      },
      sortable: true,
      flex: 1,
      filter: true,
      valueFormatter: ({ value }) => {
        return t(
          'pages.fundManager.portfolio.valuation.table.valuationProgress.cell',
          { context: value },
        );
      },
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuation.table.acquisitionCost.title',
      ),
      flex: 1,
      field: 'acquisitionCost',
      aggFunc: arraySum,
      valueFormatter: formatRowValueToMonetary,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuation.table.fairValue.title',
      ),
      aggFunc: arraySum,
      sortable: true,
      flex: 1,
      field: 'fairValueWithAccruedCoupon',
      valueFormatter: formatRowValueToMonetary,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuation.table.proceeds.title',
      ),
      field: 'proceeds',
      aggFunc: arraySum,
      sortable: true,
      flex: 1,
      valueFormatter: formatRowValueToMonetary,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuation.table.exitValue.title',
      ),
      flex: 1,
      aggFunc: arraySum,
      field: 'exitValue',
      valueFormatter: formatRowValueToMonetary,
    },
    {
      headerName: t('pages.fundManager.portfolio.valuation.table.action.title'),
      flex: 1,
      cellRenderer: ({ data }: ICellRendererParams<RowType>) => {
        if (!data || data?.valuationProgress === ValuationProgressEnum.Exited) {
          return null;
        }
        return (
          <ThemedBrownButton
            text={t(
              'pages.fundManager.portfolio.valuation.table.action.button',
            )}
            onClick={() =>
              handleOpenModal({
                instId: data.companyInstrumentId,
                instValId: data.id,
              })
            }
          />
        );
      },
    },
  ];
}
