import { ComponentProps, ReactElement } from 'react';

import { InfoItemContainer } from 'ui/info-item-container';
import { Typo } from 'ui/typo';

export interface Info extends InfoItemProps {
  key: string;
}

type InfoItemContainerProps = ComponentProps<typeof InfoItemContainer>;
type PartialSizeAndVariant = Partial<
  Pick<InfoItemContainerProps, 'size' | 'variant'>
>;

export interface InfoItemProps
  extends Omit<InfoItemContainerProps, 'size' | 'variant'>,
    PartialSizeAndVariant {
  value?: ReactElement | string;
  description: ReactElement | string;
  variant?: 'rounded' | 'circular';
  className?: string;
  hideValue?: boolean;
}

export function InfoItem({
  value,
  description,
  imageSource,
  variant = 'rounded',
  size = 'normal',
  className,
  iconColor,
  sx = {},
  hideValue,
}: InfoItemProps) {
  return (
    <InfoItemContainer
      className={className}
      size={size}
      imageSource={imageSource}
      variant={variant}
      iconColor={iconColor}
      sx={sx}
    >
      <div>
        {hideValue ? null : (
          <Typo bold size="2xl">
            {value ?? '-'}
          </Typo>
        )}
        {typeof description === 'string' ? (
          <>
            <Typo size="sm">{description}</Typo>
          </>
        ) : (
          description
        )}
      </div>
    </InfoItemContainer>
  );
}
