import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import {
  CustomColumnType,
  GetCommitmentAndOperationForFundQuery,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { addNumbersOrNull } from 'technical/number/add-numbers-or-null';

export const getFundSubscriptionListColumnDefinition = ({
  t,
  customColumns,
}: {
  t: TFunction;
  customColumns: GetCommitmentAndOperationForFundQuery['customColumns'];
}): ColDef<
  GetCommitmentAndOperationForFundQuery['getCommitmentsAndOperationsForFund'][number]
>[] => [
  {
    headerName: t('pages.fundManager.subscription.table.lpName'),
    field: 'lpView.lpName',
    filter: true,
  },
  {
    headerName: t('pages.fundManager.subscription.table.shareName'),
    field: 'share.name',
    filter: true,
  },
  {
    headerName: t('pages.fundManager.subscription.table.commitment'),
    field: 'numericAmount',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.subscription.table.unfundedCommitment'),
    colId: 'unfundedCommitment',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    valueGetter: ({ data }) => {
      if (!data?.numericAmount) {
        return 0;
      }

      const numericDrawn = addNumbersOrNull([
        data?.numericInvestment,
        data?.numericFees,
        data?.numericOther,
      ]);

      if (!numericDrawn) {
        return data?.numericAmount;
      }
      return (
        data?.numericAmount -
        numericDrawn +
        (data?.numericCurrentDistributed ?? 0)
      );
    },
  },
  {
    headerName: t('pages.fundManager.subscription.table.paidIn'),
    colId: 'numericDrawn',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    valueGetter: ({ data }) =>
      addNumbersOrNull([
        data?.numericInvestment,
        data?.numericFees,
        data?.numericOther,
      ]),
  },
  {
    headerName: t(
      'pages.fundManager.subscription.table.distributedNonRecallable',
    ),
    colId: 'numericDistributedNonRecallable',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    valueGetter: ({ data }) =>
      addNumbersOrNull([
        data?.numericCapitalGain,
        data?.numericDividend,
        data?.numericInterest,
        data?.numericReturnOfCost,
      ]),
  },
  {
    headerName: t('pages.fundManager.subscription.table.distributed'),
    field: 'numericCurrentDistributed',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.lps.table.country'),
    field: 'lpView.country',
    type: AgGridColumnTypesEnum.COUNTRY,
  },
  ...customColumns.map(({ id, type, name }) => ({
    headerName: name,
    colId: id,
    type,
    valueGetter: ({
      data,
    }: ValueGetterParams<
      GetCommitmentAndOperationForFundQuery['getCommitmentsAndOperationsForFund'][number]
    >) => {
      const customColData = data?.customData?.find(
        (dataPoint) => dataPoint.customColumnId === id,
      );
      if (type === CustomColumnType.Boolean) {
        return customColData ? JSON.parse(customColData.value) : false;
      }
      return customColData ? JSON.parse(customColData.value) : undefined;
    },
  })),
];
