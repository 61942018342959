import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { getContactInformation } from 'business/shared/lp-creation-module/services/get-contact-information';
import {
  LpContactFormComponents,
  LpContactType,
  LpContactWithIdType,
} from 'business/shared/lp-creation-module/services/types';
import { lpContactSchema } from 'business/shared/lp-creation-module/services/validation';
import {
  ContactTypeEnum,
  useCreateContactMutation,
  useUpdateContactMutation,
  Web_Portal_PublicationStatus_Enum,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { ModalLpContactForm } from 'ui/modal-lp-contact-form';
import { ContactFormContextEnum } from 'ui/types';

export const LpUpsertContactForm = ({
  lpId,
  context,
  contact: currentContact,
  onChanged: onCompleted,
  ...rest
}: LpContactFormComponents) => {
  const { t } = useTranslation();

  const [createContactMutation, createResult] = useCreateContactMutation({
    onError: () => {
      enqueueSnackbar(t('errors.createContact'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      onCompleted();
      enqueueSnackbar(t('successMessage.createContact'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const [updateContactMutation, updateResult] = useUpdateContactMutation({
    onError: () => {
      enqueueSnackbar(t('errors.updateContact'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      onCompleted();
      enqueueSnackbar(t('successMessage.updateContact'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const onCreate = ({ contact, address }: LpContactType) => {
    return createContactMutation({
      variables: {
        input: {
          type: ContactTypeEnum.Individual,
          lpId,
          isLegalRepresentative: false,
          contact: omit(
            {
              ...contact,
              birthDate: contact.birthDate
                ? formatAsDate(contact.birthDate)
                : null,
            },
            ['type'],
          ),
          address,
        },
      },
      // react-hook-form doesn't handle error fine
    }).catch(() => undefined);
  };

  const onUpdate = ({ contact, address, id }: LpContactWithIdType) => {
    return updateContactMutation({
      variables: {
        input: {
          lpId,
          id,
          contact: omit(
            {
              ...contact,
              birthDate: contact.birthDate
                ? formatAsDate(contact.birthDate)
                : null,
            },
            ['type'],
          ),
          address,
        },
      },
      // react-hook-form doesn't handle error fine
    }).catch(() => undefined);
  };

  const onSubmit = (contactData: LpContactType) => {
    if (currentContact) {
      return onUpdate({ ...contactData, id: currentContact.id });
    }
    return onCreate(contactData);
  };

  const methods = useForm<LpContactType>({
    resolver: yupResolver<LpContactType>(lpContactSchema),
    defaultValues: getContactInformation(currentContact),
  });

  return (
    <FormProvider {...methods}>
      <ModalLpContactForm
        {...rest}
        typeFieldName="contact.type"
        onSubmit={methods.handleSubmit(onSubmit)}
        isSubmitting={methods.formState.isSubmitting}
        context={
          currentContact?.publicationStatus ===
          Web_Portal_PublicationStatus_Enum.PendingValidation
            ? ContactFormContextEnum.VALIDATE
            : context
        }
        error={createResult?.error?.message || updateResult?.error?.message}
      />
    </FormProvider>
  );
};
