import { Stack } from '@mui/material';
import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import {
  CurrencyEnum,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { PercentBarCell } from 'ui/alternative-table/components/percent-bar-cell';
import { LeftArrowIcon, RightArrowIcon } from 'ui/icons';
import Link from 'ui/link';

import { OperationSharesLpValues } from './types';

type GetOperationDetailsColumnDefinitionProps = {
  t: TFunction;
  operationId: string;
  managementCompanyType?: Web_Portal_ManagementCompanyType_Enum;
  currency?: CurrencyEnum;
};

const getArrowIcon = ({ value, t }: { value?: string; t: TFunction }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-evenly"
    >
      {value === 'distribution' ? (
        <>
          <LeftArrowIcon color="secondary" />
          <>{t('pages.fundManager.operation.table.type.cell_distribution')}</>
        </>
      ) : (
        <>
          <RightArrowIcon color="primary" />
          <>{t('pages.fundManager.operation.table.type.cell_drawdown')}</>
        </>
      )}
    </Stack>
  );
};

export function getOperationDetailsColumnDefinition({
  operationId,
  t,
  managementCompanyType,
  currency,
}: GetOperationDetailsColumnDefinitionProps): ColDef<OperationSharesLpValues>[] {
  return [
    {
      headerName: t('pages.fundManager.operation.details.table.lpName'),
      field: 'lpName',
      filter: true,
    },
    {
      headerName: t('pages.fundManager.operation.details.table.shareName'),
      field: 'shareName',
      filter: true,
    },
    {
      headerName: t('pages.fundManager.operation.details.table.type'),
      filter: true,
      colId: 'type',
      valueGetter: ({ data }: ValueGetterParams<OperationSharesLpValues>) => {
        if (data?.type === 'distribution') {
          return t('pages.fundManager.operation.table.type.cell_distribution');
        }
        if (data?.type === 'drawdown') {
          return t('pages.fundManager.operation.table.type.cell_drawdown');
        }
        return '';
      },
      cellRenderer: ({
        data,
        value,
        node,
      }: ICellRendererParams<OperationSharesLpValues>) => {
        if (node.group) {
          // show value for first cell if grouped by type
          // return '' if grouped row
          return value;
        }

        return getArrowIcon({ value: data?.type, t });
      },
    },
    {
      field: 'amount',
      headerName: t('pages.fundManager.operation.details.table.amount'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'received',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t('pages.fundManager.operation.details.table.received'),
      cellRenderer: ({
        value,
        data,
        node,
      }: ICellRendererParams<OperationSharesLpValues>) => {
        if (node.group) {
          return formatToMonetaryAmount(convertFrom10X8toNumber(value), {
            currency,
          });
        }

        const cashRatio = data?.cashRatio ?? 0;

        return (
          <PercentBarCell
            percent={cashRatio}
            label={formatToMonetaryAmount(convertFrom10X8toNumber(value), {
              currency,
            })}
          />
        );
      },
    },
    {
      field: 'remaining',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t('pages.fundManager.operation.details.table.remaining'),
    },
    {
      headerName: t('pages.fundManager.operation.details.table.details'),
      type: AgGridColumnTypesEnum.INTERACTION,
      colId: 'interaction',
      cellRenderer: ({
        value: { id, countPendingValidationCashflow },
      }: ICellRendererParams) => {
        if (!id) {
          return null;
        }

        const showPendingValidationCashflowCount =
          managementCompanyType !==
          Web_Portal_ManagementCompanyType_Enum.Advisor;

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Link
              to={FundManagerRoutes.OperationIdInvestorsOperationShareLpId.replace(
                ':operationId',
                operationId,
              ).replace(':operationShareLpId', id)}
            >
              {t('common.seeDetails')}
            </Link>
            {showPendingValidationCashflowCount &&
              countPendingValidationCashflow > 0 && (
                <PendingChipCount count={countPendingValidationCashflow} />
              )}
          </Stack>
        );
      },
      valueGetter: (params) => {
        return {
          id: params.data?.id,
          countPendingValidationCashflow:
            params.data?.countPendingValidationCashflow ?? 0,
        };
      },
    },
  ];
}
