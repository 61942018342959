import { useTranslation } from 'translations/hook';

import { PortfolioGraph } from 'business/lp-platform/portfolio/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import PlotChart from 'technical/plotly-charts';
import {
  PlotChartTextPosition,
  PlotChartTypeEnum,
  PlotData,
  PlotLayout,
} from 'technical/plotly-charts/types';

interface Props {
  values: PortfolioGraph[];
  fileName: string;
}

const PortfolioSectorGraph = ({ values, fileName }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  const uniqueSectorValues = values.reduce((acc: string[], obj) => {
    if (!acc.includes(obj.mainSector)) {
      return [...acc, obj.mainSector];
    }
    return acc;
  }, []);

  const displayedValueSumBySector: number[] = uniqueSectorValues.map(
    (sector) => {
      const sum = values.reduce(
        (acc, curr) =>
          curr.mainSector === sector ? acc + curr.displayedValue : acc,
        0,
      );

      const roundedSum = sum.toFixed(2);

      return parseFloat(roundedSum);
    },
  );

  const dataChart: PlotData = {
    type: PlotChartTypeEnum.treemap,
    labels: uniqueSectorValues,
    parents: uniqueSectorValues.map(() => ''), // For a treemap chart, we need to specify the parent's label element. If they are all on the same level, the default root is an empty string
    values: displayedValueSumBySector,
    marker: {
      colors: [
        ...Object.values(palette.primary),
        ...Object.values(palette.secondary),
      ],
      cornerradius: 10,
    },
    textposition: PlotChartTextPosition.topLeft,
    texttemplate: '%{label}<br><br>%{value} €',
    hovertemplate: `<b>%{label}</b><br><br>${t(
      'pages.portfolio.graph.tooltip',
    )}: %{value} €<extra></extra>`, // extra tag allow to remove 'trace' on hover
  };

  const plotLayout: PlotLayout = {
    autosize: true,
    font: {
      size: 11,
      color: '#ffffff',
    },
    margin: {
      t: 20,
      l: 5,
      r: 0,
      b: 0,
    },
  };

  return (
    <PlotChart
      dataArray={[dataChart]}
      layout={plotLayout}
      fileName={fileName}
    />
  );
};

export default PortfolioSectorGraph;
