import { InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FormFromDateOf } from 'business/fund-manager/fund/components/form-from-date-of';
import { FundProfile } from 'business/fund-manager/fund/services/types';
import { FormInputText, FormSection } from 'ui/form';

export const InvestmentPeriodForm = () => {
  const { control } = useFormContext<FundProfile>();
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('pages.fundManager.fundCreation.form.investmentPeriod.title')}
    >
      <FormInputText
        name="duration"
        label={t(
          'pages.fundManager.fundCreation.form.investmentPeriod.duration',
        )}
        control={control}
        type="number"
        placeholder="5"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t('pages.fundManager.fundCreation.form.investmentPeriod.years')}
            </InputAdornment>
          ),
        }}
      />

      <FormFromDateOf name="investmentPeriodFromDateOf" />

      <FormInputText
        name="investmentPeriodExtension"
        label={t(
          'pages.fundManager.fundCreation.form.investmentPeriod.extension',
        )}
        control={control}
        type="number"
        placeholder="1"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t(
                'pages.fundManager.fundCreation.form.investmentPeriod.extensionYears',
              )}
            </InputAdornment>
          ),
        }}
      />
    </FormSection>
  );
};
