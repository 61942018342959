// Extends the BigInt type to support JSON.stringify'ing BigInt's.
declare global {
  interface BigInt {
    /** Converts BigInt to string form in `JSON.stringify`. */
    toJSON(): string;
  }
}
BigInt.prototype.toJSON = function () {
  return `${this.toString()}n`;
};

export function stringify(value: any) {
  return value && typeof value !== 'string' ? JSON.stringify(value) : value;
}
