interface Props {
  error?: string;
}

export const InputError = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    // TODO add a color for error stuff
    <div data-test-id="error-message" style={{ color: 'red' }}>
      {error}
    </div>
  );
};
