import { getLocaleDateToISOString } from 'business/shared/financial-flows/services/date-utils';
import {
  ValuationGraphData,
  ValuationGraphDataByType,
} from 'business/shared/services/types';

export const getAggregagtedValuationGraphData = (
  graphData: ValuationGraphData[] | undefined,
): {
  aggregatedData: ValuationGraphDataByType | undefined;
  lastPoint: ValuationGraphData | undefined;
} => {
  // regroup data by date
  const aggregatedAcrossFundByDateData: ValuationGraphData[] | undefined =
    graphData
      ? Object.values(
          graphData.reduce(
            (
              previousValue: { [key: string]: ValuationGraphData },
              currentValue: ValuationGraphData,
            ) => {
              if (!previousValue[currentValue.valuationDate]) {
                return {
                  ...previousValue,
                  [currentValue.valuationDate]: {
                    ...currentValue,
                  },
                };
              } else {
                const dataAggregated: ValuationGraphData =
                  previousValue[currentValue.valuationDate];
                return {
                  ...previousValue,
                  [currentValue.valuationDate]: {
                    valuationDate: currentValue.valuationDate,
                    paidIn: dataAggregated.paidIn + currentValue.paidIn,
                    distributed:
                      dataAggregated.distributed + currentValue.distributed,
                    distributedRecallable:
                      dataAggregated.distributedRecallable +
                      currentValue.distributedRecallable,
                    residual: dataAggregated.residual + currentValue.residual,
                    dpi:
                      (dataAggregated.distributed + currentValue.distributed) /
                      (dataAggregated.paidIn + currentValue.paidIn),
                    rvpi:
                      (dataAggregated.residual + currentValue.residual) /
                      (dataAggregated.paidIn + currentValue.paidIn),
                  },
                };
              }
            },
            {},
          ),
        )
      : undefined;

  const sortedValuationData = aggregatedAcrossFundByDateData?.sort(
    (a, b) =>
      new Date(a.valuationDate).getTime() - new Date(b.valuationDate).getTime(),
  );

  // order aggregated result by type of data for plotly graphs
  const aggregatedAcrossFundByDataType: ValuationGraphDataByType | undefined =
    sortedValuationData
      ? sortedValuationData.reduce(
          (
            accumulator: ValuationGraphDataByType,
            currentValue: ValuationGraphData,
          ) => {
            const localDateToISOString = getLocaleDateToISOString(
              currentValue.valuationDate,
            );

            const tvpi = currentValue.dpi + currentValue.rvpi;
            const totalDistributed =
              currentValue.distributed + currentValue.distributedRecallable;
            const surmResidualAndTotalDistributed =
              totalDistributed + currentValue.residual;

            accumulator.valuationDates.push(localDateToISOString);
            accumulator.dpiValues.push(currentValue.dpi);
            accumulator.rvpiValues.push(currentValue.rvpi);
            accumulator.tvpiValues.push(tvpi);
            accumulator.totalDistributedValues.push(totalDistributed);
            accumulator.residualValues.push(currentValue.residual);
            accumulator.surmResidualAndTotalDistributedValues.push(
              surmResidualAndTotalDistributed,
            );
            accumulator.paidInValues.push(currentValue.paidIn);

            accumulator.valuationGraphDataByDate.push({
              valuationDate: localDateToISOString,
              dpi: currentValue.dpi,
              rvpi: currentValue.rvpi,
              tvpi: tvpi,
              paidIn: currentValue.paidIn,
              totalDistributed: totalDistributed,
              residual: currentValue.residual,
            });

            return accumulator;
          },
          {
            valuationDates: [],
            tvpiValues: [],
            dpiValues: [],
            rvpiValues: [],
            paidInValues: [],
            totalDistributedValues: [],
            residualValues: [],
            surmResidualAndTotalDistributedValues: [],
            valuationGraphDataByDate: [],
          },
        )
      : undefined;

  const lastPoint = sortedValuationData?.slice(-1)[0];

  return {
    aggregatedData: aggregatedAcrossFundByDataType,
    lastPoint,
  };
};
