import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { InstrumentTransactionCreationModal } from 'business/fund-manager/portfolio/inventory/components/instrument-transaction-creation-modal';
import { useGetFundCompaniesInfosQuery } from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { QueryStateDisplay } from 'ui/query-state-display';

export const InstrumentTransactionCreation = ({
  onCompleted: onCompletedProp,
}: {
  onCompleted?: () => void;
}) => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const { data, loading, error, refetch } = useGetFundCompaniesInfosQuery({
    variables: {
      fundId,
    },
    fetchPolicy: 'network-only',
  });

  const onCompleted = () => {
    refetch();
    handleCloseModal();
    onCompletedProp?.();
  };

  if (loading || error || !data) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  return (
    <Stack>
      <ActionButton variant="submit" onClick={() => handleOpenModal()}>
        {t('pages.fundManager.portfolio.companyTransaction.createNew')}
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <InstrumentTransactionCreationModal
            initialValues={data.companies}
            onCompleted={onCompleted}
            onClose={handleCloseModal}
          />
        }
      />
    </Stack>
  );
};
