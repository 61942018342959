import { TFunction } from 'translations/hook';

import { FundReportingTypeEnum } from 'generated/graphql';

export const getFundReportingTypeOptions = (t: TFunction) => {
  return Object.values(FundReportingTypeEnum).map((fundReportingType) => {
    return {
      value: t(`pages.fundManager.reporting.type.${fundReportingType}`, {
        value: fundReportingType,
      }),
      id: fundReportingType,
    };
  });
};
