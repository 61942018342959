import { ApolloError } from '@apollo/client';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { LpCategorizationForm } from 'business/shared/lp-creation-module/components/lp-categorization-form';
import { LpKYC } from 'business/shared/lp-creation-module/services/types';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText, FormSection } from 'ui/form';

type KYCFormUIProps = {
  error?: ApolloError;
  saving: boolean;
  action?: ReactNode;
};
export const LpIndividualKYCFormUI = ({
  error,
  saving,
  action,
}: KYCFormUIProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LpKYC>();

  return (
    <>
      {error ? <ErrorLabel label={error.message} /> : null}
      <FormSection
        lastOne
        title={t('pages.fundManager.lpCreation.form.KYC.categorization.title')}
        action={action}
      >
        <FormInputText
          name="taxPayerIdentificationNumber"
          label={t(
            'pages.fundManager.lpCreation.form.taxPayerIdentificationNumber.label',
          )}
          control={control}
          required
          disabled={saving}
        />

        <LpCategorizationForm control={control} saving={saving} />
      </FormSection>
    </>
  );
};
