import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { AuthAppLogo } from 'business/user/components/auth-logo';
import { Card } from 'ui/card';

import styles from './index.module.scss';

interface Props {
  primaryButtonHandle: React.MouseEventHandler;
  secondaryButtonHandle: React.MouseEventHandler;
  dataTestId?: string;
}

export default function SuccessCard({
  primaryButtonHandle,
  secondaryButtonHandle,
  dataTestId,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.successCard}>
      <Card className={styles.card} dataTestId={dataTestId}>
        <Stack spacing={3}>
          <Stack spacing={6} className={styles.stack}>
            <AuthAppLogo />
            <Typography variant="h6" className={styles.title}>
              {t('pages.forgotPassword.success.title')}
            </Typography>
          </Stack>

          <Stack spacing={2}>
            <Typography>
              {t('pages.forgotPassword.success.description')}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={2} className={styles.stack}>
            <Button
              variant="contained"
              className={styles.button}
              color="secondary"
              onClick={secondaryButtonHandle}
            >
              {t('pages.forgotPassword.success.secondaryAction')}
            </Button>
            <Button
              data-test-id="primary-button"
              variant="contained"
              className={styles.button}
              onClick={primaryButtonHandle}
            >
              {t('pages.forgotPassword.success.primaryAction')}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
}
