import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { CompanyFileUploadForm } from 'business/fund-manager/portfolio/company/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useCompanyDocumentUploadMutation } from 'generated/graphql';
import { formatISO } from 'technical/date';
import logger from 'technical/logger';

interface UseCompanyDocumentUploadProps {
  onCompleted?: () => void;
}

export const useCompanyDocumentUpload = ({
  onCompleted,
}: UseCompanyDocumentUploadProps = {}) => {
  const { t } = useTranslation();
  const [uploadCompanyDocument, { loading }] = useCompanyDocumentUploadMutation(
    {
      onError: (err) => {
        logger.error({
          err,
        });
        enqueueSnackbar(t('errors.upload-failed'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.uploadDocumentTemplate'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        onCompleted?.();
      },
    },
  );

  const uploadDocument = ({
    fileName,
    filePath,
    type,
    uploadDate,
    companyId,
  }: CompanyFileUploadForm & { companyId: string }) =>
    uploadCompanyDocument({
      variables: {
        input: {
          companyId: companyId,
          uploadDate: formatISO(new Date(uploadDate)),
          filePath,
          fileName,
          type,
        },
      },
    });

  return {
    uploadDocument,
    loading,
  };
};
