import FundManagerRoutes from 'business/fund-manager/router/routes';
import { UserWithPrivateInfos } from 'business/user/types/user';

export const getSettingsRoutePrefixAndCompanyManagementId = (
  route: string,
  user?: UserWithPrivateInfos,
): { routePrefix: string; managementCompanyId: string } => {
  //Only match route in the form /fund-manager/settings/advisor/xxx-xxx
  const match = route.match(
    /^\/fund-manager\/settings\/advisor\/[[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}]*/,
  );

  if (match) {
    const advisorId = match[0].split('/').pop();
    // Should never happen (since the regex match it), only here for typing
    if (!advisorId) {
      throw new Error('invalid URL');
    }
    return {
      routePrefix: `${match[0]}/`,
      managementCompanyId: advisorId,
    };
  }
  // Should never happen, only here for typing
  if (!user?.managementCompany) {
    throw new Error('Invalid URL');
  }
  return {
    routePrefix: '/fund-manager/settings/',
    managementCompanyId: user.managementCompany.id,
  };
};

export const replaceRouteWithPrefix = (
  route: FundManagerRoutes,
  prefix: string,
) => {
  return route.replace('/fund-manager/settings/', prefix);
};
