import { MailOutline, Person, PhoneOutlined } from '@mui/icons-material';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import { Card } from 'ui/card';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

export interface Props extends PropsWithChildren {
  email: string;
  title?: string;
  firstName: string;
  lastName: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  companyName?: string;
  jobTitle?: string;
  comment?: string;
  logo?: {
    id: string;
    name: string;
    url: string;
  };
}

export const ContactInlineCard = ({
  logo,
  title,
  firstName,
  lastName,
  phoneNumber1,
  email,
  jobTitle,
  companyName,
  children,
}: Props) => {
  return (
    <Card>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
      >
        <Grid item md={2}>
          <Stack spacing={2} direction="row" alignItems={'center'}>
            <Avatar variant="rounded" src={logo?.url} className={styles.avatar}>
              <Person />
            </Avatar>
            <Stack>
              <Typo>{getPersonFullName({ title, firstName, lastName })}</Typo>
            </Stack>
          </Stack>
        </Grid>

        <Grid item md={4}>
          <Stack direction="row" justifyContent={'flex-start'} spacing={1}>
            <Stack
              sx={{
                width: 300,
                alignItems: 'center',
              }}
              direction="row"
              spacing={1}
            >
              <MailOutline color="secondary" />
              <Typography sx={{ wordBreak: 'break-word' }} variant="body1">
                {email}
              </Typography>
            </Stack>

            {phoneNumber1 ? (
              <Stack
                sx={{
                  width: 200,
                  alignItems: 'center',
                }}
                direction="row"
                spacing={1}
              >
                <PhoneOutlined color="secondary" />
                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                  {phoneNumber1}
                </Typography>
              </Stack>
            ) : null}

            {jobTitle && companyName ? (
              <Stack sx={{ width: 200, wordWrap: 'break-word' }}>
                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                  {jobTitle}
                </Typography>
                <Typography fontWeight={1000} variant="body1">
                  @ {companyName}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        </Grid>

        {children}
      </Grid>
    </Card>
  );
};
