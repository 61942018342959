import {
  WebCompanyTransactionTypeEnum,
  Web_Portal_CompanyTransactionType_Enum,
} from 'generated/graphql';

export function getInstrumentValuationSoldQuantity(
  transactions?:
    | {
        nominalValue: number;
        type:
          | WebCompanyTransactionTypeEnum
          | Web_Portal_CompanyTransactionType_Enum
          | string;
        quantity: number;
      }[]
    | null,
) {
  if (!transactions) {
    return 0;
  }
  return transactions.reduce((acc, { nominalValue, type, quantity }) => {
    if (
      type === WebCompanyTransactionTypeEnum.Transfer ||
      type === Web_Portal_CompanyTransactionType_Enum.Transfer
    ) {
      acc += nominalValue * quantity;
    }
    return acc;
  }, 0);
}
