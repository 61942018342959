import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CreateOperationRecapValues } from 'business/fund-manager/operation/components/create-operation-recap-values';
import { LaunchOperationConfirmation } from 'business/fund-manager/operation/components/launch-operation-confirmation';
import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import { OperationCreationStepEnum } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  Web_Portal_ManagementCompanyType_Enum,
  useStartOperationMutation,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { CustomModal } from 'ui/custom-modal';

export const CreateOperationRecap = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { operationId = '' } = useParams();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const [startOperation, { error: mutationError, loading }] =
    useStartOperationMutation({
      variables: { input: { operationId } },
      onCompleted: () => {
        handleCloseModal();
        navigate(FundManagerRoutes.Operations);
        enqueueSnackbar(
          t(
            `pages.fundManager.operationCreation.step.${
              isAdvisor ? 'createOperationSuccess' : 'launchOperationSuccess'
            }`,
          ),
          {
            variant: VariantTypeEnum.SUCCESS,
          },
        );
      },
    });

  const onNext = isAdvisor ? startOperation : handleOpenModal;

  return (
    <OperationCreationContainer
      activeStep={OperationCreationStepEnum.Resume}
      onNext={onNext}
      labelAction={t(
        `pages.fundManager.operationCreation.step.${
          isAdvisor ? 'createOperation' : 'launchOperation'
        }`,
      )}
      onPrevious={() =>
        navigate(
          FundManagerRoutes.OperationCreateIdSharesLp.replace(
            ':operationId',
            operationId,
          ),
        )
      }
    >
      <CreateOperationRecapValues operationId={operationId} />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <LaunchOperationConfirmation
            operationId={operationId}
            onLaunch={startOperation}
            handleClose={handleCloseModal}
            launchError={mutationError}
            isSubmitting={loading}
          />
        }
      />
    </OperationCreationContainer>
  );
};
