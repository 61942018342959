import { addDays } from 'date-fns';

import { formatISO } from 'technical/date';

import { QueryParams } from './types';

const getLowerAndUpperBounds = (date: Date) => {
  const lowerBound = date.setHours(0, 0, 0, 0);
  const upperBound = addDays(lowerBound, 1);

  return {
    lowerBound: formatISO(lowerBound),
    upperBound: formatISO(upperBound),
  };
};

export const isFilter = (field: string, date: Date): QueryParams => {
  const { lowerBound, upperBound } = getLowerAndUpperBounds(date);
  return {
    [field]: { _gte: lowerBound, _lt: upperBound },
  };
};

export const notFilter = (field: string, date: Date): QueryParams => {
  const { lowerBound, upperBound } = getLowerAndUpperBounds(date);
  return {
    _or: [{ [field]: { _lt: lowerBound } }, { [field]: { _gte: upperBound } }],
  };
};

export const afterFilter = (field: string, date: Date): QueryParams => {
  const afterDate = addDays(date, 1).setHours(0, 0, 0, 0);
  return { [field]: { _gte: formatISO(afterDate) } };
};

export const beforeFilter = (field: string, date: Date): QueryParams => {
  const beforeDate = date.setHours(0, 0, 0, 0);
  return {
    [field]: { _lt: formatISO(beforeDate) },
  };
};

export const onOrAfterFilter = (field: string, date: Date): QueryParams => {
  const isFilterResult = isFilter(field, date);
  const afterFilterResult = afterFilter(field, date);
  return {
    _or: [isFilterResult, afterFilterResult],
  };
};

export const onOrBeforeFilter = (field: string, date: Date): QueryParams => {
  const isFilterResult = isFilter(field, date);
  const beforeFilterResult = beforeFilter(field, date);
  return {
    _or: [beforeFilterResult, isFilterResult],
  };
};
