import { ApolloError } from '@apollo/client';
import { FormProvider } from 'react-hook-form';

import { CreationPageContainer } from 'business/fund-manager/fund/components/creation-page-container';
import { FundProfileForm } from 'business/fund-manager/fund/components/fund-profile-form';
import { useProfileForm } from 'business/fund-manager/fund/services/hooks/use-profile-form';
import {
  FundCreationStepEnum,
  FundProfile,
} from 'business/fund-manager/fund/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';

export const FundCreationForm = ({
  onSubmit,
  error,
  loading,
  defaultValues,
}: {
  defaultValues?: FundProfile;
  onSubmit: (input: FundProfile) => void;
  error?: ApolloError;
  loading?: boolean;
}) => {
  const { methods, onSubmit: handleSubmit } = useProfileForm({
    onSubmit,
    defaultValues,
  });

  return (
    <CreationPageContainer
      activeStep={FundCreationStepEnum.Profile}
      onSubmit={!loading ? handleSubmit : undefined}
      previousPath={FundManagerRoutes.Fund}
    >
      <FormProvider {...methods}>
        <FundProfileForm error={error} />
      </FormProvider>
    </CreationPageContainer>
  );
};
