import { ShareCreationForm } from 'business/fund-manager/fund/components/share-form';
import { SharesList } from 'business/fund-manager/fund/components/shares-list';
import { SharesQuery } from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { CustomModal } from 'ui/custom-modal';

interface SharesManagerProps {
  fundId: string;
  data: SharesQuery;
  onCompleted?: () => void;
  title?: string;
}
export const SharesManager = ({
  fundId,
  data,
  onCompleted,
  title,
}: SharesManagerProps) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <SharesList title={title} data={data} handleOpenModal={handleOpenModal} />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <ShareCreationForm
            fundId={fundId}
            closeForm={handleCloseModal}
            onCompleted={onCompleted}
          />
        }
      />
    </>
  );
};
