import { Outlet, RouteObject } from 'react-router';

import { ContactSupportPage } from 'business/fund-manager/contact-support-page/contact-support-page';
import { HomePage } from 'business/fund-manager/home/pages';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { ProtectedRoute } from 'business/user/helpers/protected-route-container';
import { Roles } from 'business/user/types/user';

import { fundRoutes } from './fund';
import { lpRoutes } from './lp';
import { onboardingSubscriptionRoutes } from './onboarding-subscription';
import { operationRoutes } from './operation';
import { portfolioRoutes } from './portfolio';
import { settingsRoutes } from './settings';

export const fundManagerRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.Home,
    element: (
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: FundManagerRoutes.Home,
        element: <HomePage />,
      },
      {
        path: FundManagerRoutes.ContactSupport,
        element: <ContactSupportPage />,
      },
      ...settingsRoutes,
    ],
  },
  {
    element: (
      <ProtectedRoute restrictedTo={Roles.FUND_MANAGER_BACK_OFFICE}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      ...lpRoutes,
      ...fundRoutes,
      ...operationRoutes,
      ...onboardingSubscriptionRoutes,
    ],
  },
  {
    element: (
      <ProtectedRoute restrictedTo={Roles.FUND_MANAGER_FRONT_OFFICE}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: portfolioRoutes,
  },
];
