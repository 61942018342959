import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { ContactWithId } from 'business/shared/services/contact/types';
import {
  Web_Portal_ManagementCompanyType_Enum,
  Web_Portal_PublicationStatus_Enum,
} from 'generated/graphql';
import { ActionButton, ActionButtonVariant } from 'ui/action-button';
import { ContactInlineCard } from 'ui/contact-inline-card';
import { LpContactRoleTag } from 'ui/lp-contact-role-tag';

interface Action {
  label: string;
  onClick: () => void;
  variant: ActionButtonVariant;
}
const getContactActions = <T extends ContactWithId>({
  contact,
  onEdit,
  onSeeSanctions,
  onSetLegalRepresentative,
  onDelete,
}: {
  onEdit?: (contact: T) => void;
  onSetLegalRepresentative?: (contact: T) => void;
  onSeeSanctions?: (contact: T) => void;
  onDelete?: (contact: T) => void;
  contact: T;
}): Action[] => {
  if (
    contact.publicationStatus ===
    Web_Portal_PublicationStatus_Enum.PendingValidation
  ) {
    if (!onEdit) {
      return [];
    }

    return [
      {
        variant: 'submit',
        // TODO here use onValidate will be more clear
        onClick: () => onEdit(contact),
        label: 'pages.fundManager.contacts.actionButton.validate',
      },
    ];
  }
  const actions: Action[] = [];
  if (onSeeSanctions) {
    actions.push({
      variant: 'secondary',
      onClick: () => onSeeSanctions(contact),
      label: 'pages.fundManager.lps.table.seeSanctions',
    });
  }

  if (!contact.isLegalRepresentative && onSetLegalRepresentative) {
    actions.push({
      variant: 'submit',
      onClick: () => onSetLegalRepresentative(contact),
      label: 'pages.fundManager.contacts.actionButton.makeLegalRepresentative',
    });
  }

  if (onEdit) {
    actions.push({
      variant: 'primary',
      onClick: () => onEdit(contact),
      label: 'pages.fundManager.contacts.actionButton.edit',
    });
  }

  if (contact.canDelete && !contact.isLegalRepresentative && onDelete) {
    actions.push({
      variant: 'danger',
      onClick: () => onDelete(contact),
      label: 'common.actions.delete',
    });
  }

  return actions;
};

export interface ContactListItemProps<T extends ContactWithId> {
  contact: T;
  onEdit?: (contact: T) => void;
  onDelete?: (contact: T) => void;
  onSetLegalRepresentative?: (contact: T) => void;
  onSeeSanctions?: (contact: T) => void;
}

export const ContactListItem = <T extends ContactWithId>({
  contact,
  ...rest
}: ContactListItemProps<T>) => {
  const { t } = useTranslation();
  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );
  const actions = isAdvisor ? [] : getContactActions<T>({ ...rest, contact });

  return (
    <ContactInlineCard {...contact}>
      <>
        {contact?.roles ? (
          <Grid item md={2} alignItems="center">
            <Stack alignItems="center" flexDirection="row" flexWrap="wrap">
              {contact?.roles.map((role) => (
                <Stack key={`${contact.id}-${role}`} margin={0.5}>
                  <LpContactRoleTag value={role} />
                </Stack>
              ))}
            </Stack>
          </Grid>
        ) : null}

        {actions.length ? (
          <Grid item md={3} justifyContent="flex-end" display="flex">
            <Stack spacing={2} alignItems="center" direction="row">
              {actions.map(({ label, ...restAction }) => (
                <ActionButton
                  key={`${contact.id}-${label}`}
                  {...restAction}
                  size="small"
                >
                  {t(label)}
                </ActionButton>
              ))}
            </Stack>
          </Grid>
        ) : null}
      </>
    </ContactInlineCard>
  );
};
