import { GetCashflowQuery, GetOperationsQuery } from 'generated/graphql';

export type Operation = {
  row: {
    type: string;
    amount: number;
  };
};

export type OperationCashflow = {
  id: string;
  type: string;
  numericAmount: number;
  date: string;
};

export type DeprecatedOperationCashflow = {
  id: string;
  type: string;
  amount: number;
  date: string;
};

export type GraphData = {
  dueDates: string[];
  distributionAmounts: number[];
  drawdownAmounts: number[];
  cumulativeCashflowValues: number[];

  customData: {
    distributionCount: number;
    drawdownCount: number;
    absoluteAmountDrawdown: number;
    formattedAbsoluteAmountDrawdown: string;
    formattedCumulativeCashflowValue: string;
    formattedDistributionAmount: string;
  }[];
};

export enum OperationType {
  Drawdown = 'drawdown',
  Distribution = 'distribution',
  RecallableDistribution = 'recallableDistribution',
}

export interface FinancialFlowsFilterType {
  funds: string[];
  shares: string[];
  identifiers: string[];
}

export const isGetOperationsQuery = (
  query: any,
): query is GetOperationsQuery => {
  if (
    typeof query !== 'object' ||
    Object.keys(query).length === 0 ||
    !query.operations
  ) {
    return false; // query is not an object or it's an empty object
  }
  return query.operations.every(
    (operation: any) =>
      typeof operation === 'object' &&
      operation.__typename === 'dataviz_operation' &&
      typeof operation.numericAmount === 'number' &&
      typeof operation.id === 'string' &&
      typeof operation.identifier === 'string' &&
      typeof operation.status === 'string' &&
      typeof operation.type === 'string' &&
      typeof operation.share === 'object' &&
      operation.share.__typename === 'dataviz_share' &&
      typeof operation.share.type === 'string' &&
      typeof operation.share.fund === 'object' &&
      operation.share.fund.__typename === 'dataviz_fund' &&
      typeof operation.share.fund.name === 'string',
  );
};

export const isGetCashflowQuery = (query: any): query is GetCashflowQuery => {
  return (
    Array.isArray(query.cashflow) &&
    query.cashflow.every(
      (flow: any) =>
        typeof flow === 'object' &&
        flow.__typename === 'dataviz_operation' &&
        typeof flow.id === 'string' &&
        typeof flow.numericAmount === 'number' &&
        typeof flow.type === 'string',
    )
  );
};
