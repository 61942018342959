import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import SharedRoutes from 'business/shared/router/routes';
import { AuthCard } from 'business/user/components/auth-card';
import { AuthAppLogo } from 'business/user/components/auth-logo';
import { PrivacyPolicy } from 'business/user/components/privacy-policy';
import authService from 'business/user/services/auth-service';
import {
  ErrorInfo,
  getAuthErrorContextFromAuthProviderError,
} from 'business/user/services/errors';
import { LoginForm } from 'business/user/services/types';
import { useThemeConfigQuery } from 'generated/graphql';
import { Card } from 'ui/card';
import Link from 'ui/link';

import styles from './index.module.scss';

interface Props {
  className?: string;
}

export const LoginCard = ({ className }: Props) => {
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);
  const { t } = useTranslation();
  const palette = usePalette();
  const { data, loading } = useThemeConfigQuery();

  const { fundManager: companyName, hideLoginCompanyName } =
    data?.themeConfig ?? {};

  const onSubmitClick = async (values: LoginForm) => {
    setErrorInfo(null);
    try {
      await authService.signIn(values.login, values.password);
    } catch (error) {
      setErrorInfo(getAuthErrorContextFromAuthProviderError(error));
    }
  };

  return (
    <Card className={classnames(className, styles.loginCard)}>
      <Box className={styles.logoCard} sx={{ bgcolor: palette.backgroundForm }}>
        <Stack className={styles.stack} spacing={5} direction="column">
          <AuthAppLogo height={183} />
          {loading ? (
            <CircularProgress />
          ) : !hideLoginCompanyName ? (
            <Typography
              variant="h3"
              className={styles.companyName}
              sx={{ color: 'secondary.dark' }}
            >
              {companyName ? companyName : t('notConnected.login.companyName')}
            </Typography>
          ) : null}
        </Stack>
      </Box>
      <AuthCard
        className={styles.authCard}
        submitButtonLabel={t('notConnected.login.submit')}
        onSubmit={onSubmitClick}
        authError={{ key: 'errors.signIn', error: errorInfo }}
        passwordHint={
          <Link to={SharedRoutes.ForgotPassword}>
            {t('pages.forgotPassword.link')}
          </Link>
        }
        bottomHint={<PrivacyPolicy />}
      />
    </Card>
  );
};
