import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useAppContext } from 'business/providers/app-provider';
import ConnectedPage from 'business/user/helpers/connected-page';
import DisconnectedPage from 'business/user/helpers/disconnected-page';
import logger from 'technical/logger';
import { Button } from 'ui/button';
import { Flex } from 'ui/flex';

import styles from './index.module.scss';

function ReportingNoMatch() {
  const { isConnected } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    logger.info(new Error('404'), { location: location.pathname });
  }, [location.pathname]);

  const Page = isConnected ? ConnectedPage : DisconnectedPage;

  return (
    <Page>
      <Flex className={styles.flex} justify="center" alignItems="center" column>
        <Typography variant="h1">{t('errors.notFound')}</Typography>
        <Typography variant="h3">{t('errors.notAuthorized')}</Typography>
        <Button color="primary" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
      </Flex>
    </Page>
  );
}

export default ReportingNoMatch;
