import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyInventoryNavBar } from 'business/fund-manager/portfolio/company/components/company-inventory-nav-bar';
import { CompanyInventoryTab } from 'business/fund-manager/portfolio/company/services/types';
import { InstrumentTable } from 'business/fund-manager/portfolio/inventory/components/instrument-table';
import { InstrumentTransactionCreation } from 'business/fund-manager/portfolio/inventory/components/instrument-transaction-creation';
import { useGetPortfolioInventoryDetailsQuery } from 'generated/graphql';
import { Typo } from 'ui/typo';

export const InventoryDetails = () => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();

  const { refetch, ...portfolioInventory } =
    useGetPortfolioInventoryDetailsQuery({
      variables: {
        fundId,
      },
      fetchPolicy: 'network-only',
    });
  const onTransactionCreated = () => {
    refetch();
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <CompanyInventoryNavBar
          activeTab={CompanyInventoryTab.InventoryDetails}
        />
        <InstrumentTransactionCreation onCompleted={onTransactionCreated} />
      </Stack>
      <Typo size="xl" color="primary">
        {t('pages.fundManager.portfolio.inventory.titles.validated')}
      </Typo>

      <InstrumentTable {...portfolioInventory} />
    </Stack>
  );
};
