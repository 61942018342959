import { InputProps as StandardInputProps } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FormInputProps, FormInputsType } from 'ui/form/form-input-props';
import { TextInput, TextInputProps } from 'ui/text-input';

export type FormInputTextProps<T extends FormInputsType = FormInputsType> =
  FormInputProps<T> &
    TextInputProps & {
      required?: boolean;
      readOnly?: boolean;
      helperText?: string;
      onChange?: StandardInputProps['onChange'];
      onAfterChange?: StandardInputProps['onChange'];
    };

export const FormInputText = <T extends FormInputsType>({
  name,
  control,
  rules = {},
  required,
  readOnly,
  onChange,
  onAfterChange,
  disabled,
  InputProps,
  helperText,
  ...rest
}: FormInputTextProps<T>) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController<{ value: string }>({
    control,
    name: name as 'value',
    rules: { ...rules, required },
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      field.onChange(e);
      onAfterChange?.(e);
    },
    [field, onAfterChange, onChange],
  );

  return (
    <TextInput
      {...rest}
      name={field.name}
      value={field.value ?? ''}
      onChange={handleChange}
      inputRef={field.ref}
      onWheel={(e) => {
        const el = e.target as HTMLElement;
        return el.blur(); // disable scrolling
      }}
      required={required}
      disabled={disabled || isSubmitting}
      error={!!error}
      InputProps={{ ...(InputProps ?? {}), readOnly }}
      helperText={error?.message ? t(error.message) : helperText}
    />
  );
};
