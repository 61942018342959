import { get } from 'lodash';
import { TFunction } from 'translations/hook';

import { VALIDATION_ITEM_TYPES } from 'ui/validation-item';
import { ValidationItemType } from 'ui/validation-item';

import { LpValidationLegalEntity } from './types';

export const getCommunicationTypesItem = ({
  data,
  t,
}: {
  data: Partial<
    NonNullable<LpValidationLegalEntity['lpIndividualGeneralInfos']>
  >;
  t: TFunction;
}): ValidationItemType[] =>
  [
    'hasOperationalRights',
    'canAccessGeneralReports',
    'canAttendInvestorsMeeting',
    'canAccessESGReports',
    'canAccessAmpereReports',
    'canAccessCapitalAccountStatement',
  ].map((key) => ({
    type: VALIDATION_ITEM_TYPES.BOOLEAN,
    label: t(`pages.contact.form.label.communicationTypes.${key}`),
    value: get(data, key, false),
  }));
