import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { SelectInput } from 'ui/form/select-input';
import { Option } from 'ui/types';

const investorGroupValues: string[] = [
  'BANK',
  'EFCO',
  'HHLD',
  'INSC',
  'NFCO',
  'NONE',
  'OCIU',
  'OFIN',
  'PFND',
  'SMGE',
  'UNKN',
];

type SelectInvestorGroupInputProps = Omit<
  ComponentProps<typeof SelectInput>,
  'options' | 'onChange' | 'label' | 'placeholder'
>;

export const SelectInvestorGroupInput = (
  props: SelectInvestorGroupInputProps,
) => {
  const { t } = useTranslation();

  const options: Option[] = investorGroupValues.map((id) => ({
    id,
    value: t(`pages.fundManager.lp.investorGroup.values.${id}`),
  }));

  return (
    <SelectInput
      {...props}
      label={t('pages.fundManager.lp.investorGroup.label')}
      placeholder={t('pages.fundManager.lp.investorGroup.placeholder')}
      options={options}
    />
  );
};
