import { Outlet, RouteObject } from 'react-router';

import { ConnectedCreationPage } from 'business/shared/lp-creation-module/components/connected-creation-page';
import { LpCreationContacts } from 'business/shared/lp-creation-module/pages/lp-creation-contacts';
import { LpCreationGeneralProfile } from 'business/shared/lp-creation-module/pages/lp-creation-general-profile';
import { LpCreationIndividualKYC } from 'business/shared/lp-creation-module/pages/lp-creation-individual-kyc';
import { LpCreationInvestorProfile } from 'business/shared/lp-creation-module/pages/lp-creation-investor-profile';
import { LpCreationKYB } from 'business/shared/lp-creation-module/pages/lp-creation-kyb';
import { LpCreationKYCDocumentation } from 'business/shared/lp-creation-module/pages/lp-creation-kyc-documentation/lp-creation-kyc-documentation';
import { LpCreationLegalEntityKYC } from 'business/shared/lp-creation-module/pages/lp-creation-legal-entity-kyc';
import { LpDraftEdition } from 'business/shared/lp-creation-module/pages/lp-draft-edition';
import { DraftEditionStepsProvider } from 'business/shared/lp-creation-module/providers/draft-edition-steps-provider';

import { LpCreationRoutes } from './types';

export const routes: RouteObject[] = [
  {
    path: LpCreationRoutes.Create,
    element: (
      <ConnectedCreationPage>
        <Outlet />
      </ConnectedCreationPage>
    ),
    children: [
      {
        path: LpCreationRoutes.Create,
        element: <LpCreationGeneralProfile />,
      },
      {
        path: LpCreationRoutes.CreateId,
        element: (
          <DraftEditionStepsProvider>
            <Outlet />
          </DraftEditionStepsProvider>
        ),
        children: [
          {
            path: LpCreationRoutes.CreateId,
            element: <LpDraftEdition />,
          },
          {
            path: LpCreationRoutes.CreateIdKYB,
            element: <LpCreationKYB />,
          },
          {
            path: LpCreationRoutes.CreateIdLegalEntityKYC,
            element: <LpCreationLegalEntityKYC />,
          },
          {
            path: LpCreationRoutes.CreateIdIndividualKYC,
            element: <LpCreationIndividualKYC />,
          },
          {
            path: LpCreationRoutes.CreateIdInvestorProfile,
            element: <LpCreationInvestorProfile />,
          },
          {
            path: LpCreationRoutes.CreateIdKYCDocuments,
            element: <LpCreationKYCDocumentation />,
          },
          {
            path: LpCreationRoutes.CreateIdContact,
            element: <LpCreationContacts />,
          },
        ],
      },
    ],
  },
];
