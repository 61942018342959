import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { PaletteVarKey } from 'business/providers/theme/services/types';
import { OnBoardingStatusEnum } from 'generated/graphql';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

const StatusColor: Record<OnBoardingStatusEnum, PaletteVarKey> = {
  declined: 'error',
  draft: 'tertiaryLight',
  pendingValidation: 'secondaryMain',
  validated: 'primaryMain',
};

interface Props {
  value: OnBoardingStatusEnum;
}

export const OnboardingStatusLabel = ({ value }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Stack alignItems="center" direction="row">
      <div
        className={styles.dot}
        style={{ backgroundColor: palette[StatusColor[value]] }}
      />
      <Stack marginLeft={1.5}>
        <Typo>{t('components.onboardingStatus', { context: value })}</Typo>
      </Stack>
    </Stack>
  );
};
