import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { NewInvestmentIcon, ValuationMenuIcon } from 'ui/icons';
import { PortfolioMenuIcon } from 'ui/icons/portfolio-menu';
import { TooltipContainer } from 'ui/tooltip-container';
import { TooltipWithTextAndIcon } from 'ui/tooltip-with-text';

type PortfolioToolbarContentProps = {
  fundId: string;
};

export const PortfolioToolbarLinks = ({
  fundId,
}: PortfolioToolbarContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const palette = usePalette();

  const primaryColor = palette.primary[800];
  const secondaryColor = palette.primary[100];

  return (
    <TooltipContainer>
      <TooltipWithTextAndIcon
        text={t('pages.fundManager.portfolio.tooltips.inventory')}
        onClick={() =>
          navigate(
            FundManagerRoutes.PortfolioFundIdInventory.replace(
              ':fundId',
              fundId,
            ),
          )
        }
      >
        <NewInvestmentIcon
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      </TooltipWithTextAndIcon>
      <TooltipWithTextAndIcon
        text={t('pages.fundManager.portfolio.tooltips.companies')}
        onClick={() =>
          navigate(
            FundManagerRoutes.PortfolioFundIdCompany.replace(':fundId', fundId),
          )
        }
      >
        <PortfolioMenuIcon
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      </TooltipWithTextAndIcon>
      <TooltipWithTextAndIcon
        text={t('pages.fundManager.portfolio.tooltips.valuation')}
        onClick={() =>
          navigate(
            FundManagerRoutes.PortfolioFundIdValuation.replace(
              ':fundId',
              fundId,
            ),
          )
        }
      >
        <ValuationMenuIcon primaryColor={primaryColor} />
      </TooltipWithTextAndIcon>
    </TooltipContainer>
  );
};
