/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { User } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';

import config from 'config';

import {
  IErrorReporting,
  ErrorReportingOptions,
  SeverityLevel,
  SeverityLevelsToReport,
} from './type';

/**
 * Main class
 */
class ErrorReporting implements IErrorReporting {
  constructor(datadogConfig: ErrorReportingOptions, codeVersion: string) {
    datadogRum.init({
      applicationId: datadogConfig.applicationId,
      clientToken: datadogConfig.clientToken,
      site: 'datadoghq.eu',
      service: 'pe3',
      env: config.env,
      version: codeVersion,
      sessionSampleRate: datadogConfig.sessionSampleRate,
      sessionReplaySampleRate: datadogConfig.sessionReplaySampleRate,
      trackUserInteractions: datadogConfig.trackUserInteractions,
      trackResources: datadogConfig.trackResources,
      trackLongTasks: datadogConfig.trackLongTasks,
      defaultPrivacyLevel: 'mask-user-input',
    });
    // before we think about enabling session replay, or other features we need a new cookie handling to allow user to accept/refuse
    if (datadogConfig.enableSessionReplay) {
      datadogRum.startSessionReplayRecording();
    }
  }

  private log(level: SeverityLevel, error: any, data: any) {
    if (SeverityLevelsToReport.includes(level)) {
      datadogRum.addError(error, data);
    }
  }

  public setUser(user: User) {
    datadogRum.setUser(user);
  }

  public removeUser() {
    datadogRum.removeUser();
  }

  public error(error: any, data: any) {
    this.log(SeverityLevel.error, error, data);
  }

  public info(error: any, data: any) {
    this.log(SeverityLevel.info, error, data);
  }

  public warning(error: any, data: any) {
    this.log(SeverityLevel.warning, error, data);
  }
}

/**
 * Mocks class
 */
class ErrorReportingMocks implements IErrorReporting {
  setUser() {}

  // eslint-disable-next-line no-console
  error = console.error;

  // eslint-disable-next-line no-console
  info = console.info;

  // eslint-disable-next-line no-console
  warning = console.warn;

  removeUser() {}
}

const errorReporting: IErrorReporting = config.datadog.enabled
  ? new ErrorReporting(config.datadog, config.version)
  : new ErrorReportingMocks();

export default errorReporting;
