import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const CheckmarkIcon = ({ primaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="18" viewBox="0 0 24 18">
      <path
        d="M5.79508 10.8769L1.62508 6.70687L0.205078 8.11687L5.79508 13.7069L17.7951 1.70687L16.3851 0.296875L5.79508 10.8769Z"
        fill={primaryColor}
      />
    </SvgIcon>
  );
};
