import { ImageUpload } from 'business/shared/components/image-upload';
import { IFile } from 'technical/file-management/types';

interface Props {
  onFilePathUpdate: (filePath: string) => void;
}

export const LpLegalEntityLogoUpload = ({ onFilePathUpdate }: Props) => {
  const onFileRemove = () => onFilePathUpdate('');
  const onFileUpload = (file: IFile) => onFilePathUpdate(file.filePath);

  return (
    <ImageUpload onFileRemove={onFileRemove} onFileUpload={onFileUpload} />
  );
};
