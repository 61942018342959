import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyTransactionCreationInput } from 'business/fund-manager/portfolio/company/services/types';
import {
  GetFundCompaniesInfosQuery,
  WebCompanyInstrumentTypeEnum,
} from 'generated/graphql';
import { FormInputDropdown, FormInputText } from 'ui/form';
import { SelectInput } from 'ui/form/select-input';

interface Props {
  initialValues: GetFundCompaniesInfosQuery['companies'];
  loading: boolean;
}
export const InstrumentTransactionExistingInstrumentForm = ({
  initialValues,
  loading,
}: Props) => {
  const { control, watch, setValue } =
    useFormContext<CompanyTransactionCreationInput>();
  const { t } = useTranslation();

  const companySelected = watch('instrument.companyId');

  const handleInstrumentChange = (value: string | null) => {
    if (!companySelected) {
      return;
    }
    const selectedCompany = initialValues.find(
      (company) => company.id === companySelected,
    );

    if (!selectedCompany) {
      return;
    }

    const selectedInstrument = selectedCompany.instruments.find(
      (instrument) => instrument.id === value,
    );

    const identifier = selectedInstrument?.identifier || '';
    const type = selectedInstrument?.type || '';

    setValue('instrument.id', value ?? '');
    setValue('instrument.identifier', identifier);
    setValue(
      'instrument.type',
      // Note: This assumes that WebCompanyInstrumentTypeEnum and WebPortalCompanyInstrumentTypeEnum
      // have equivalent values, ensuring compatibility for 'type' assignment.
      type as WebCompanyInstrumentTypeEnum,
    );

    const latestTransactionTotalQuantity = selectedCompany?.instruments.find(
      ({ id }) => id === value,
    )?.transactions[0].totalQuantity;

    setValue('transaction.totalQuantity', latestTransactionTotalQuantity ?? 0);
  };

  return (
    <>
      <SelectInput
        name="instrument.id"
        label={t(
          'pages.fundManager.portfolio.companyTransaction.form.selectSecurity',
        )}
        dataTestId="select-company-instrument"
        control={control}
        options={initialValues
          .filter((company) => company.id === companySelected)
          .flatMap((company) => company.instruments)
          .map(({ id, name }) => {
            return {
              id,
              value: name,
            };
          })}
        onChange={(value: string | null) => handleInstrumentChange(value)}
        required
        disabled={loading}
        fullWidth
      />
      <Stack direction="row" spacing={2}>
        <FormInputDropdown
          name="instrument.type"
          label={t(
            'pages.fundManager.portfolio.inventory.instrumentType.title',
          )}
          control={control}
          options={Object.values(WebCompanyInstrumentTypeEnum).map((id) => ({
            value: t(
              `pages.fundManager.portfolio.inventory.instrumentType.${id}`,
            ),
            id,
          }))}
          disabled={true}
          required
          fullWidth
        />
        <FormInputText
          name="instrument.identifier"
          label={t(
            'pages.fundManager.portfolio.companyTransaction.form.identifier',
          )}
          control={control}
          required
          disabled
          fullWidth
        />
      </Stack>
    </>
  );
};
