import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useGetFundManagerDashboardOperationsInfoQuery } from 'generated/graphql';
import {
  formatToMonetaryInMillions,
  convertFrom10X8toNumber,
} from 'technical/currency/formatters';
import { DashboardCard } from 'ui/dashboard-card/dashboard-card';
import { PieGraph } from 'ui/graph/pie-graph';
import { InfoCard } from 'ui/info-card';
import LegendItem from 'ui/legend-item';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props {
  title: string;
  path?: string;
}

export const OperationsCard = ({ path, title }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  const { data, loading, error } =
    useGetFundManagerDashboardOperationsInfoQuery({
      fetchPolicy: 'network-only',
    });

  if (loading || error || !data?.fundManagerDashboardOperationsInfo) {
    return (
      <DashboardCard path={path} title={title}>
        <Stack justifyContent="center" alignItems="center" flexGrow={1}>
          <QueryStateDisplay loading={loading} error={error} />
        </Stack>
      </DashboardCard>
    );
  }

  const operationData = data.fundManagerDashboardOperationsInfo;

  const labels = [
    t('pages.fundManager.dashboard.cards.operations.graph.totalPaidIn'),
    t('pages.fundManager.dashboard.cards.operations.graph.unfundedCommitments'),
  ];

  const values = [
    convertFrom10X8toNumber(operationData.totalPaidIn),
    convertFrom10X8toNumber(operationData.unfundedCommitments),
  ];

  const customData = [
    formatToMonetaryInMillions(
      convertFrom10X8toNumber(operationData.totalPaidIn),
    ),
    formatToMonetaryInMillions(
      convertFrom10X8toNumber(operationData.unfundedCommitments),
    ),
  ];

  const text = t(
    'pages.fundManager.dashboard.cards.operations.operationWithCount',
    {
      count: operationData.count,
    },
  );

  const tooltipInfo = t(
    'pages.fundManager.dashboard.cards.operations.graph.noRecallableDistribution',
  );

  const colorRange = [palette.secondaryMain, palette.primaryMain];

  return (
    <DashboardCard path={path} title={title}>
      <>
        <Stack padding={3} boxSizing="border-box">
          <PieGraph
            labels={labels}
            values={values}
            colorRange={colorRange}
            text={text}
            customData={customData}
            extraTooltipInfo={tooltipInfo}
            fileName={t(
              'pages.fundManager.dashboard.cards.operations.graph.fileName',
            )}
          />
        </Stack>
        <InfoCard.Footer>
          <Stack flexDirection="row" justifyContent="center" gap={2}>
            <LegendItem
              sx={{ color: palette.primaryMain }}
              legend={t(
                'pages.fundManager.dashboard.cards.operations.graph.totalPaidIn',
              )}
            />
            <LegendItem
              sx={{ color: palette.secondaryMain }}
              legend={t(
                'pages.fundManager.dashboard.cards.operations.graph.unfundedCommitments',
              )}
            />
          </Stack>
        </InfoCard.Footer>
      </>
    </DashboardCard>
  );
};
