import { LPFilter } from 'business/lp-platform/nav-bar/services/types';
import { Lp as LpUser } from 'business/user/types/user';
import { parseLocalStorage } from 'technical/local-storage';

export const MAX_LP_DISPLAYED = 2;

export function getSelectedLps(
  storedLps: LpUser[],
  userLps: LpUser[],
): LPFilter[] {
  return userLps.map((lp) => {
    const isLpSelected =
      storedLps.findIndex((storedLp) => storedLp.id === lp.id) !== -1;
    return {
      id: lp.id,
      name: lp.name,
      selected: isLpSelected,
    };
  });
}

export function getStoredLp() {
  return parseLocalStorage<LPFilter[]>('lpFilters') ?? [];
}

export function areMultipleLpStored() {
  const storedLp = getStoredLp();

  return storedLp.length > 1;
}
