import { Locale } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { LanguageEnum } from 'translations/types';

export function getLanguageLocale(i18nCurrentLocale: string): Locale {
  switch (i18nCurrentLocale) {
    case 'fr-FR':
      return fr;
    case 'en-GB':
      return enGB;
    default:
      return enGB;
  }
}

export function getDateFnsLocaleFromLanguage(language: LanguageEnum): Locale {
  switch (language) {
    case 'fr':
      return fr;
    case 'en':
      return enGB;
    default:
      return enGB;
  }
}
