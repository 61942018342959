import { TFunction, useTranslation } from 'translations/hook';

import { RecapValuesContent } from 'business/fund-manager/operation/components/recap-values-content';
import { useFund } from 'business/providers/fund-provider/use-fund';
import {
  CurrencyEnum,
  GetInvestorsRecapValuesQuery,
  useGetInvestorsRecapValuesQuery,
} from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { PersonIcon } from 'ui/icons';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SummaryBar } from 'ui/summary-bar';

const formatData = (
  data: GetInvestorsRecapValuesQuery,
  currency: CurrencyEnum,
  t: TFunction,
) => [
  {
    value: formatToMonetaryAmount(
      convertFrom10X8toNumber(data.getOperationInvestorsRecap.totalDrawn),
      { currency },
    ),
    label: t('pages.fundManager.operation.creationRecap.investors.totalDrawn'),
  },
  {
    value: formatToMonetaryAmount(
      convertFrom10X8toNumber(data.getOperationInvestorsRecap.numericPremium),
      { currency },
    ),
    label: t('pages.fundManager.operation.creationRecap.investors.premium'),
  },
];

interface InvestorsRecapCardProps {
  operationId: string;
}
export const InvestorsRecapCard = ({
  operationId,
}: InvestorsRecapCardProps) => {
  const { t } = useTranslation();
  const { currency } = useFund();

  const { data, loading, error } = useGetInvestorsRecapValuesQuery({
    variables: { input: { operationId } },
  });

  return (
    <SummaryBar
      icon={<PersonIcon />}
      iconColor="tertiary"
      title={t('pages.fundManager.operation.creationRecap.investors.title')}
      subtitle={t(
        'pages.fundManager.operation.creationRecap.investors.activeInvestors',
        { count: data?.getOperationInvestorsRecap.activeInvestors ?? 0 },
      )}
    >
      {error || loading || !data ? (
        <QueryStateDisplay error={error} loading={loading} />
      ) : (
        <RecapValuesContent content={formatData(data, currency, t)} />
      )}
    </SummaryBar>
  );
};
