import { router } from 'business/router/services';

import { logPageViewed } from '.';

const autoLogPageViewed = () => {
  // Log the first page viewed
  logPageViewed(String(router.state.location.pathname));
  // Then, attach a listener that log the pathname
  // of the page everytime it changes
  router.subscribe((state) => {
    logPageViewed(String(state.location.pathname));
  });
};
autoLogPageViewed();
