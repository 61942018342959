import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { ConditionalRequiredFormInputDate } from 'business/fund-manager/fund/components/conditional-required-form-input-date';
import { FundManagementCompanyInput } from 'business/fund-manager/fund/components/fund-management-company-input';
import { FundProfile } from 'business/fund-manager/fund/services/types';
import { CurrencyEnum, FundFromDateEnum } from 'generated/graphql';
import {
  FormInputDate,
  FormInputText,
  FormSection,
  FormToggleButton,
} from 'ui/form';

interface Props {
  readOnlyManagementCompany: boolean;
  action?: ReactNode;
}

export const GeneralInformationForm = ({
  action,
  readOnlyManagementCompany,
}: Props) => {
  const { control, setValue } = useFormContext<FundProfile>();
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('pages.fundManager.fundCreation.form.generalInformation.title')}
      action={action}
    >
      <FormInputText
        name="name"
        label={t(
          'pages.fundManager.fundCreation.form.generalInformation.fullName',
        )}
        control={control}
        required
      />
      <Stack direction="row" spacing={1}>
        <FormInputDate
          name="firstClosingDate"
          label={t(
            'pages.fundManager.fundCreation.form.common.fromDateOf.firstClosingDate',
          )}
          control={control}
          onChange={(value: Date | null) => {
            if (value) {
              setValue('vintageYear', value.getFullYear());
            }
          }}
          required
        />

        <ConditionalRequiredFormInputDate
          name="finalClosingDate"
          watchedValue={FundFromDateEnum.FinalClosingDate}
          label={t(
            'pages.fundManager.fundCreation.form.common.fromDateOf.finalClosingDate',
          )}
          control={control}
        />

        <FormInputText
          type="number"
          name="vintageYear"
          label={t(
            'pages.fundManager.fundCreation.form.generalInformation.vintageYear.label',
          )}
          placeholder={t(
            'pages.fundManager.fundCreation.form.generalInformation.vintageYear.placeholder',
          )}
          control={control}
          // field is auto completed when changing firstClosingDate
          readOnly={true}
          required
        />
      </Stack>

      <ConditionalRequiredFormInputDate
        name="firstInvestmentDate"
        watchedValue={FundFromDateEnum.FirstInvestmentDate}
        label={t(
          'pages.fundManager.fundCreation.form.common.fromDateOf.firstInvestmentDate',
        )}
        control={control}
      />

      {readOnlyManagementCompany ? null : (
        <FundManagementCompanyInput control={control} />
      )}

      <FormToggleButton
        control={control}
        fullWidth
        label={t(
          'pages.fundManager.fundCreation.form.generalInformation.currency',
        )}
        name="currency"
        options={[
          // TODO PE3-462 currency will be handled in another task
          { label: CurrencyEnum.Gbp, value: CurrencyEnum.Gbp },
          { label: CurrencyEnum.Eur, value: CurrencyEnum.Eur },
          { label: CurrencyEnum.Usd, value: CurrencyEnum.Usd },
          { label: CurrencyEnum.Chf, value: CurrencyEnum.Chf },
        ]}
        required
      />

      <FormInputText
        name="valuationPolicy"
        label={t(
          'pages.fundManager.fundCreation.form.generalInformation.valuationPolicy.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.generalInformation.valuationPolicy.placeholder',
        )}
        control={control}
      />
    </FormSection>
  );
};
