import { Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';

import Link from 'ui/link';

export const EmailCell = ({ value }: ICellRendererParams) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.6}>
      <Link to={`mailto:${value}`}>{value}</Link>
    </Stack>
  );
};
