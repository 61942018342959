import StartIcon from '@mui/icons-material/Start';
import { Stack } from '@mui/material';

import { Typo } from 'ui/typo';
import { TypoSizeEnum } from 'ui/typo/types';

interface PeriodProps {
  title: string;
  period: { start: string; end: string };
  direction?: 'row' | 'column';
}

export const PeriodBlock = ({
  title,
  period,
  direction = 'column',
}: PeriodProps) => {
  const { typoSize, spacing } =
    direction === 'column'
      ? {
          typoSize: TypoSizeEnum.sm,
          spacing: 1,
        }
      : {
          typoSize: TypoSizeEnum.m,
          spacing: 4,
        };
  return (
    <Stack spacing={1} direction={direction}>
      <Stack direction="row" justifyContent="center">
        <Typo size={typoSize} bold>
          {title}
        </Typo>
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={spacing}>
        <Typo size={typoSize}>{period.start}</Typo>
        <StartIcon />
        <Typo size={typoSize}>{period.end}</Typo>
      </Stack>
    </Stack>
  );
};
