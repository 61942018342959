import { useParams } from 'react-router';

import { DownloadSummaryButton } from 'business/fund-manager/operation/components/download-summary-button/download-summary-button';
import { OperationDetailsContent } from 'business/fund-manager/operation/components/operation-details-content/operation-details-content';
import { OperationNavBar } from 'business/fund-manager/operation/components/operation-nav-bar';
import { OperationNavBarTabEnum } from 'business/fund-manager/operation/services/types';

export const OperationDetails = () => {
  const { operationId = '' } = useParams();

  return (
    <OperationNavBar
      activeTab={OperationNavBarTabEnum.Investors}
      action={<DownloadSummaryButton operationId={operationId} />}
    >
      <OperationDetailsContent operationId={operationId} />
    </OperationNavBar>
  );
};
