import { Card, Stack } from '@mui/material';

import { EmptyContent } from './content';
import styles from './index.module.scss';

interface EmptyCardProps {
  title?: string;
  subtitle?: string;
}

export const EmptyCard = ({ title, subtitle }: EmptyCardProps) => {
  return (
    <Stack spacing={1} padding={5} alignItems="center" justifyContent="center">
      <Card>
        <Stack
          spacing={1}
          padding={5}
          alignItems="center"
          justifyContent="center"
          className={styles.emptyCard}
        >
          <EmptyContent title={title} subtitle={subtitle} />
        </Stack>
      </Card>
    </Stack>
  );
};
