import { NavigateOptions, useNavigate } from 'react-router';

import { useGetAbsolutePath } from './use-get-absolute-path';

export const useLpCreationModuleNavigate = () => {
  const navigate = useNavigate();
  const getAbsolutePath = useGetAbsolutePath();

  return (to: string, options?: NavigateOptions) =>
    navigate(getAbsolutePath(to), options);
};
