import { TFunction } from 'translations/hook';

import { getLpDocumentStatus } from 'business/shared/services/document/get-lp-document-status';
import {
  ClientTypeEnum,
  LpDocTypeWithDocumentQueryResult,
} from 'generated/graphql';

import { LpDocumentTypeWithDocument } from './types';

export const getFormattedMandatoryLpDocumentTypeWithDoc = (
  data: LpDocTypeWithDocumentQueryResult['data'],
  lpType: ClientTypeEnum,
  t: TFunction,
): LpDocumentTypeWithDocument[] => {
  return data?.lpDocTypeWithDocument
    ? data?.lpDocTypeWithDocument
        .filter((docType) =>
          lpType === ClientTypeEnum.Individual
            ? docType.isMandatoryIndividual
            : docType.isMandatoryLegalEntity,
        )
        .map((documentTypeWithDoc) => {
          return {
            documentTypeId: documentTypeWithDoc.id,
            documentType: t(
              `pages.fundManager.settings.lpDocumentType.values.${documentTypeWithDoc.name}`,
              { defaultValue: documentTypeWithDoc.name },
            ),
            status: getLpDocumentStatus(documentTypeWithDoc.lpDocument),
          };
        })
    : [];
};
