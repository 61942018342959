import { GetCompaniesQuery } from 'generated/graphql';

export interface PortfolioGraph {
  id: string;
  displayedValue: number;
  mainSector: string;
  country: string;
}

export enum CompanyDisplayedValues {
  TOTAL_INVESTED = 'totalInvested',
  LATEST_VALUATION = 'latestValuation',
}

export interface PortfolioCustomFilter {
  name: string[];
  sector: string[];
  location: string[];
  status: string[];
  funds: string[];
}

export const isGetCompaniesQuery = (query: any): query is GetCompaniesQuery => {
  return (
    Array.isArray(query.companies) &&
    query.companies.every(
      (company: any) =>
        typeof company === 'object' &&
        company.__typename === 'dataviz_company' &&
        typeof company.id === 'string' &&
        typeof company.grossIrr === 'number' &&
        (typeof company.latestValuation === 'number' ||
          company.latestValuation === null) &&
        typeof company.name === 'string' &&
        typeof company.sector === 'string' &&
        typeof company.status === 'string' &&
        typeof company.totalInvested === 'number' &&
        typeof company.location === 'string' &&
        typeof company.fund === 'object' &&
        company.fund.__typename === 'dataviz_fund' &&
        typeof company.fund.name === 'string' &&
        Array.isArray(company.company_metrics) &&
        company.company_metrics.every(
          (metrics: any) =>
            typeof metrics === 'object' &&
            metrics.__typename === 'dataviz_company_metrics' &&
            typeof metrics.amount === 'number' &&
            typeof metrics.type === 'string' &&
            typeof metrics.periodType === 'string',
        ),
    )
  );
};
