import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { OperationCashflow } from 'business/financial-flows/types';
import { OperationType } from 'business/lp-platform/financial-flows/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { FinancialFlowsGraph } from 'business/shared/financial-flows/components';
import { depractedGetGraphData } from 'business/shared/financial-flows/services/deprecated-get-graph-data';
import { FinancialFlowRow } from 'business/shared/financial-flows/services/types';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';
import { formatAsDate } from 'technical/date';
import LegendItem from 'ui/legend-item';

import styles from './index.module.scss';

interface Props {
  data: FinancialFlowRow[];
}

// Code already duplicated here front/src/business/lpPlatform/financial-flows/components/graph-panel/index.tsx
// if you have to duplicate it again, please find a way to make it more generic
const GraphPanel = ({ data }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const { currency } = useFund();

  const graphData: OperationCashflow[] = data.flatMap(
    ({ distributed, recallableDistributed, drawdown, date, id }) => {
      return [
        {
          amount: convertFrom10X8toNumber(distributed),
          id: id,
          date: formatAsDate(date),
          type: OperationType.Distribution,
        },
        {
          amount: convertFrom10X8toNumber(recallableDistributed),
          id: id,
          date: formatAsDate(date),
          type: OperationType.RecallableDistribution,
        },
        {
          amount: convertFrom10X8toNumber(drawdown),
          id: id,
          date: formatAsDate(date),
          type: OperationType.Drawdown,
        },
      ];
    },
  );

  const plotlyGraphData = depractedGetGraphData(graphData, currency);

  return (
    <Box
      sx={{ bgcolor: palette.backgroundForm }}
      data-test-id="financial-flows-graph"
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={2}
        className={styles.titleBar}
      >
        <Typography variant="body1">
          {t('pages.financialFlows.graph.title') + ` - ${currency}`}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <LegendItem
            sx={{ color: palette.primary[800] }}
            legend={t('pages.financialFlows.graph.drawdown')}
          />
          <LegendItem
            sx={{ color: palette.secondary[800] }}
            legend={t('pages.financialFlows.graph.distribution')}
          />
          <LegendItem
            sx={{ color: palette.secondary[500] }}
            legend={t('pages.financialFlows.graph.cumulativeCashflow')}
          />
        </Stack>
      </Stack>
      <Divider light />
      <div className={styles.graphContainer}>
        <FinancialFlowsGraph
          financialFlowsData={plotlyGraphData}
          fileName={t('pages.financialFlows.graph.fileName')}
        />
      </div>
    </Box>
  );
};

export default GraphPanel;
