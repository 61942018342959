import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { LpDocumentModalForm } from 'business/fund-manager/lp/components/lp-document-upload-modal-form';
import { LpDocumentationTable } from 'business/shared/components/lp-documentation-table/lp-documentation-table';
import { LpDocumentationTypesTableList } from 'business/shared/components/lp-documentation-types-table-list/lp-documentation-types-table-list';
import {
  GetLpKycDocumentsQueryResult,
  LpDocTypeWithDocumentQueryResult,
  useLpQuery,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { FormCard } from 'ui/form/form-card';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

interface KYCDocumentationProps {
  lpDocuments: GetLpKycDocumentsQueryResult['data'];
  loading: boolean;
  lpId: string;
  lpDocumentTypes: LpDocTypeWithDocumentQueryResult['data'];
  refetchDocuments: GetLpKycDocumentsQueryResult['refetch'];
  refetchDocumentsTypes: LpDocTypeWithDocumentQueryResult['refetch'];
}
export const KYCDocumentation = ({
  lpDocuments,
  loading,
  lpId,
  lpDocumentTypes,
  refetchDocuments,
  refetchDocumentsTypes,
}: KYCDocumentationProps) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const [selectedDocumentTypeId, setSelectedDocumentTypeId] =
    useState<string>('');

  const {
    data,
    loading: loadingLp,
    error,
  } = useLpQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
  });

  if (!data || loadingLp || error) {
    return <QueryStateDisplay loading={loadingLp} error={error} />;
  }

  const handleDocumentTypeSelection = (documentTypeId: string) => {
    setSelectedDocumentTypeId(documentTypeId);
    handleOpenModal();
  };

  const onCompleted = () => {
    handleCloseModal();
    refetchDocuments();
    refetchDocumentsTypes();
  };

  return (
    <FormCard className={styles.documentFormCard}>
      <Stack direction="row" spacing={3}>
        <Stack className={styles.documentTable}>
          <LpDocumentationTable data={lpDocuments} loading={loading} />
        </Stack>
        <Stack className={styles.documentTypes} spacing={2}>
          <ActionButton variant="secondary" onClick={handleOpenModal}>
            {t('common.actions.addDocument')}
          </ActionButton>
          <LpDocumentationTypesTableList
            lpType={data.lp.client}
            data={lpDocumentTypes}
            onDocumentAdd={handleDocumentTypeSelection}
          />
        </Stack>
      </Stack>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <LpDocumentModalForm
            lpId={lpId}
            onClose={handleCloseModal}
            onCompleted={onCompleted}
            preselectedTypeId={selectedDocumentTypeId}
            lpDocumentTypes={lpDocumentTypes}
          />
        }
      />
    </FormCard>
  );
};
