import {
  GetValuationsQuery,
  ValuationCashflowTypeEnum,
} from 'generated/graphql';

export interface ValuationCustomFilterFields {
  funds: string[];
  shares: string[];
}

export type ValuationForIrrCalculation = {
  shareId: string;
  valuationDate: string;
  residualValue: number;
  share: {
    operations: {
      type: Omit<ValuationCashflowTypeEnum, 'residualValue'>;
      amount: number;
      date: string;
    }[];
  };
};

export type TypedOperationDeprecated = {
  date: Date;
  amount: number;
  type: ValuationCashflowTypeEnum;
};

export type TypedOperation = {
  date: Date;
  numericAmount: number;
  type: ValuationCashflowTypeEnum;
};

export type Operation = Pick<TypedOperationDeprecated, 'date' | 'amount'>;

export const isGetValuationsQuery = (
  query: any,
): query is GetValuationsQuery => {
  return query.valuations.every(
    (valuation: any) =>
      typeof valuation === 'object' &&
      valuation.__typename === 'dataviz_valuationLpShare' &&
      typeof valuation.paidIn === 'number' &&
      typeof valuation.residualValue === 'number' &&
      typeof valuation.rvpi === 'number' &&
      typeof valuation.dpi === 'number' &&
      typeof valuation.distributedRecallable === 'number' &&
      typeof valuation.distributedNonRecallable === 'number' &&
      typeof valuation.shareId === 'string' &&
      typeof valuation.share === 'object' &&
      valuation.share.__typename === 'dataviz_share' &&
      typeof valuation.share.type === 'string' &&
      typeof valuation.share.fund === 'object' &&
      valuation.share.fund.__typename === 'dataviz_fund' &&
      typeof valuation.share.fund.name === 'string' &&
      Array.isArray(valuation.share.operations) &&
      valuation.share.operations.every(
        (operation: any) =>
          typeof operation === 'object' &&
          operation.__typename === 'dataviz_operation' &&
          typeof operation.amount === 'number' &&
          typeof operation.type === 'string',
      ),
  );
};
