import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import GraphPanel from 'business/fund-manager/lp/components/financial-flow-graphs';
import { FinancialFlowNavBar } from 'business/fund-manager/lp/components/financial-flow-nav-bar';
import { FinancialFlowNavBarTabEnum } from 'business/fund-manager/lp/services/types';
import { computeOperationStats } from 'business/fund-manager/shared/services/compute-operation-values';
import { getFinancialFlowsColumnDefinition } from 'business/fund-manager/shared/services/financial-flows/get-financial-flows-column-definition';
import { createFinancialFlowsRows } from 'business/shared/financial-flows/services/create-financial-flows-rows';
import { FinancialFlowRow } from 'business/shared/financial-flows/services/types';
import { useGetFundManagerOperationsForLpQuery } from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { FinancialFlowSynthesis } from 'ui/financial-flow-synthesis';

export const LpFinancialFlowPage = () => {
  const { t } = useTranslation();
  const { lpId } = useParams();

  const { data, error, loading } = useGetFundManagerOperationsForLpQuery({
    variables: { id: lpId ?? '' },
  });

  const headers = getFinancialFlowsColumnDefinition(t);
  const rows = createFinancialFlowsRows(data);

  const { drawdown, distribution, recallableDistribution } = data
    ?.web_portal_operationShareLp_aggregate.aggregate?.sum
    ? computeOperationStats({
        ...data.web_portal_operationShareLp_aggregate.aggregate?.sum,
      })
    : { drawdown: 0, distribution: 0, recallableDistribution: 0 };

  if (!lpId || error) {
    return null;
  }

  return (
    <FinancialFlowNavBar activeTab={FinancialFlowNavBarTabEnum.overview}>
      <Stack spacing={2}>
        {rows ? (
          <>
            <FinancialFlowSynthesis
              drawdown={drawdown}
              distribution={distribution}
              recallableDistribution={recallableDistribution}
            />
            <GraphPanel data={rows} />
          </>
        ) : null}
        <AlternativeTable<FinancialFlowRow>
          fileName={'lp-financial-flow'}
          columnDefs={headers}
          rowData={rows}
          domLayout="autoHeight"
          loading={loading}
          displaySidebar
        />
      </Stack>
    </FinancialFlowNavBar>
  );
};
