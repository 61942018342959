import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { CompanyManagerForm } from 'business/fund-manager/settings/management-company/components/company-manager-form';
import { setFormDefaultValues } from 'business/fund-manager/settings/management-company/services/set-form-default-values';
import { UpdateCompanyManagerForm } from 'business/fund-manager/settings/management-company/services/types';
import { updateCompanyManagerFormSchema } from 'business/fund-manager/settings/management-company/services/validation';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useGetManagementCompanyQuery,
  useUpdateManagementCompanyMutation,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props {
  defaultValues: UpdateCompanyManagerForm;
}

const CompanyManagerUpdateContent = ({ defaultValues }: Props) => {
  const { t } = useTranslation();
  const { navigateSettings } = useNavigateSettings();
  const { enqueueSnackbar } = useSnackbar();

  const [updateManagementCompanyMutation, { error }] =
    useUpdateManagementCompanyMutation({
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.updateManagementCompany'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        navigateSettings(FundManagerRoutes.Settings);
      },
      onError: () => {
        enqueueSnackbar(t('errors.updateManagementCompany'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const { handleSubmit, control, setValue } = useForm<UpdateCompanyManagerForm>(
    {
      resolver: yupResolver(updateCompanyManagerFormSchema),
      defaultValues,
    },
  );

  const onSubmit: SubmitHandler<UpdateCompanyManagerForm> = async (
    formData,
  ) => {
    updateManagementCompanyMutation({
      variables: {
        input: formData,
      },
    });
  };

  const handleCountryChange = (value: string | null) => {
    if (!value) {
      return;
    }
    setValue('addressInformation.country', value);
  };

  return (
    <CompanyManagerForm<UpdateCompanyManagerForm>
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      error={error}
      onCountryChange={handleCountryChange}
    />
  );
};

export const CompanyManagerUpdate = () => {
  const { managementCompanyId } = useNavigateSettings();
  const {
    data: dataManagementCompany,
    loading,
    error,
  } = useGetManagementCompanyQuery({
    variables: { id: managementCompanyId },
    fetchPolicy: 'network-only',
  });

  if (error || !dataManagementCompany?.companyInfos || loading) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  return (
    <CompanyManagerUpdateContent
      defaultValues={setFormDefaultValues(dataManagementCompany.companyInfos)}
    />
  );
};
