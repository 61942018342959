import { FormControl } from '@mui/material';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { ValidationErrors } from 'technical/validation/types';
import { CheckBox, PE3CheckboxProps } from 'ui/check-box';
import { ErrorLabel } from 'ui/error-label';
import { FormInputProps, FormInputsType } from 'ui/form/form-input-props';

type PE3CheckboxPropsInherited = Omit<
  PE3CheckboxProps,
  'field' | 'label' | 'checked' | 'dataTestId'
>;

export type FormInputCheckBoxProps<T extends FormInputsType = FormInputsType> =
  FormInputProps<T> &
    PE3CheckboxPropsInherited & {
      disabled?: boolean;
    };

export const FormInputCheckBox = <T extends FormInputsType>({
  name,
  disabled,
  control,
  label,
  required,
  onChange,
  onAfterChange,
  rules = {},
  dataTestId,
  ...rest
}: FormInputCheckBoxProps<T>) => {
  const {
    field,
    fieldState: { error: fieldError },
    formState: { isSubmitting },
  } = useController<{ value: boolean }>({
    name: name as `value`,
    control,
    rules: { ...rules, required },
  });

  const handleChange = useCallback(
    (checked: boolean) => {
      onChange?.(checked);
      field.onChange(checked);
      onAfterChange?.(checked);
    },
    [field, onAfterChange, onChange],
  );

  return (
    <FormControl
      required={required}
      error={!!fieldError}
      sx={{ justifyContent: 'center' }}
    >
      <CheckBox
        {...rest}
        field={name}
        label={label}
        checked={field.value ?? false}
        onChange={handleChange}
        dataTestId={dataTestId}
        disabled={disabled || isSubmitting}
      />

      {fieldError ? (
        <ErrorLabel label={fieldError.message ?? ValidationErrors.GENERIC} />
      ) : null}
    </FormControl>
  );
};
