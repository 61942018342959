import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpCustomQuestions } from 'business/shared/lp-creation-module/components/lp-custom-questions/lp-custom-questions';
import { useLpViewQuery } from 'generated/graphql';
import {
  ClientTypeEnum,
  LpCreationStepEnum,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { FormCard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

import { useInvestorProfileForm } from './use-investor-profile-form';

export const InvestorProfile = () => {
  const { lpId = '' } = useParams();

  const { data, loading, error } = useLpViewQuery({ variables: { lpId } });

  return (
    <FormCard>
      {loading || error || !data || !data.lpView.length ? (
        <QueryStateDisplay loading={loading} error={error} />
      ) : (
        <InvestorProfileForm
          isIndividual={data.lpView[0].client === ClientTypeEnum.Individual}
          lpId={lpId}
        />
      )}
    </FormCard>
  );
};

interface InvestorProfileFormProps {
  lpId: string;
  isIndividual: boolean;
}
const InvestorProfileForm = ({
  lpId,
  isIndividual,
}: InvestorProfileFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { methods, onSubmit, saving } = useInvestorProfileForm({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    lpId,
  });

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const action = isAdvisor ? undefined : (
    <ActionButton variant="submit" type="submit" loading={saving}>
      {t('common.actions.save')}
    </ActionButton>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        {action && (
          <Stack direction="row" justifyContent="end">
            {action}
          </Stack>
        )}

        <Stack spacing={2}>
          <LpCustomQuestions
            lpId={lpId}
            entityStep={
              isIndividual
                ? LpCreationStepEnum.IndividualInvestorProfile
                : LpCreationStepEnum.LegalEntityInvestorProfile
            }
          />
          {action}
        </Stack>
      </form>
    </FormProvider>
  );
};
