// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import { FundManagerEmailTemplateForm } from 'business/fund-manager/settings/template/components/email-template-form';
import { TemplateFormWithDynamicContent } from 'business/fund-manager/settings/template/components/template-form-with-dynamic-content';
import { EmailTemplateCreationForm } from 'business/fund-manager/settings/template/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useInsertEmailTemplateMutation } from 'generated/graphql';

export const FundManagerEmailTemplateCreation = () => {
  const { t } = useTranslation();
  const { managementCompanyId, navigateSettings } = useNavigateSettings();
  const { templateListId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [insertEmailTemplateMutation, { loading, error }] =
    useInsertEmailTemplateMutation({
      onCompleted: () => {
        navigateSettings(FundManagerRoutes.SettingsTemplateList);
        enqueueSnackbar(t('successMessage.createEmailTemplate'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  const onSubmit = (value: EmailTemplateCreationForm) => {
    insertEmailTemplateMutation({
      variables: {
        content: value.content,
        subject: value.subject,
        templateListId,
        managementCompanyId: managementCompanyId,
      },
    });
  };

  const defaultValues = undefined;
  return (
    <TemplateFormWithDynamicContent managementCompanyId={managementCompanyId}>
      <FundManagerEmailTemplateForm
        error={error}
        onSubmit={onSubmit}
        loading={loading}
        defaultValues={defaultValues}
      />
    </TemplateFormWithDynamicContent>
  );
};
