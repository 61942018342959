import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { CompanyManagerForm } from 'business/fund-manager/settings/management-company/components/company-manager-form';
import { CreateCompanyManagerForm } from 'business/fund-manager/settings/management-company/services/types';
import { createCompanyManagementSchema } from 'business/fund-manager/settings/management-company/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  ManagementCompanyType,
  useCreateAdvisorManagementCompanyMutation,
} from 'generated/graphql';

interface Props {
  defaultType: ManagementCompanyType;
}

export const CreateManagementCompany = ({ defaultType }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createAdvisorManagementCompany, { error }] =
    useCreateAdvisorManagementCompanyMutation({
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.createAdvisorManagementCompany'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        navigate(FundManagerRoutes.Settings);
      },
      onError: () => {
        enqueueSnackbar(t('errors.createAdvisorManagementCompany'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const { handleSubmit, control, setValue } = useForm<CreateCompanyManagerForm>(
    {
      resolver: yupResolver(createCompanyManagementSchema),
      defaultValues: {
        companyInformation: {
          type: defaultType,
        },
      },
    },
  );

  const onSubmit: SubmitHandler<CreateCompanyManagerForm> = async (
    formData,
  ) => {
    await createAdvisorManagementCompany({
      variables: {
        input: formData,
      },
    });
  };

  const handleCountryChange = (value: string | null) => {
    if (!value) {
      return;
    }
    setValue('addressInformation.country', value);
  };

  return (
    <CompanyManagerForm<CreateCompanyManagerForm>
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      error={error}
      unchangeableType
      onCountryChange={handleCountryChange}
    />
  );
};
