import { useWebDocumentDownloadUrlQuery } from 'generated/graphql';
import { PreviewFile } from 'ui/preview-file';

export const LpLegalEntityLogoDisplay = ({
  logoDocumentId,
  handleDelete,
}: {
  logoDocumentId: string;
  handleDelete: () => void;
}) => {
  const { data } = useWebDocumentDownloadUrlQuery({
    variables: { input: { ids: [logoDocumentId] } },
    skip: !logoDocumentId,
  });

  if (!data?.documentDownloadUrl?.url) {
    return null;
  }

  return (
    <PreviewFile
      url={data.documentDownloadUrl.url}
      handleDelete={handleDelete}
    />
  );
};
