import { LanguageEnum } from 'translations/types';

import { COUNTRY_DATA } from './constants';

export const getCountryList = (language: LanguageEnum) =>
  Object.values(COUNTRY_DATA).map((value) => ({
    countryName: value[language].countryName,
    code: value.code,
  }));

export const getCountryNameByCountryCode = ({
  code,
  language,
}: {
  code: string;
  language: LanguageEnum;
}) => {
  const country = Object.values(COUNTRY_DATA).find(
    ({ code: countryCode }) => countryCode === code,
  );

  return country ? country[language].countryName : '';
};

export const getValidCountryCode = (value: string | null) =>
  value && value in COUNTRY_DATA ? value : undefined;

export const getRegionCodeByCountry = (value: string | null) => {
  const countryCode = getValidCountryCode(value);

  return countryCode ? COUNTRY_DATA[countryCode].regionCode : undefined;
};

export const getRegionNameByRegionCode = ({
  code,
  language,
}: {
  code: string;
  language: LanguageEnum;
}) => {
  const region = Object.values(COUNTRY_DATA).find(
    ({ regionCode }) => regionCode === code,
  );

  return region ? region[language].regionName : '';
};
