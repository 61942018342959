import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium';
import { isValid } from 'date-fns';

import { convertFromNumberToX100 } from 'technical/currency/formatters';
import { parseDateString } from 'technical/date';
import {
  FilterField,
  FilterOperator,
  GqlWhereClause,
  QueryParams,
  QuickFilteringParameters,
} from 'technical/filter/types';

import { DEFAULT_WHERE_CLAUSE, DISTRIBUTED_FIELDS } from './constants';
import {
  afterFilter,
  beforeFilter,
  isFilter,
  notFilter,
  onOrAfterFilter,
  onOrBeforeFilter,
} from './date-filter';

const generateDefaultDistributedWhereClause = (value: string): QueryParams => {
  return { type: { _eq: value } };
};

const generateGqlWhereClause = (
  operator: FilterOperator,
  field: FilterField,
  value: string | string[],
  clauseBuilderFunction: (
    field: string,
    gqlOperator: {
      [key: string]: unknown;
    },
  ) => QueryParams,
): QueryParams => {
  switch (operator) {
    case FilterOperator.EQUALS:
    case FilterOperator.IS_EQUAL:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _eq: value } }],
        };
      }
      return clauseBuilderFunction(field, {
        _eq: value,
      });
    case FilterOperator.IS_NOT_EQUAL:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _neq: value } }],
        };
      }
      return clauseBuilderFunction(field, {
        _neq: value,
      });
    case FilterOperator.IS_GREATER:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _gt: value } }],
        };
      }
      return clauseBuilderFunction(field, {
        _gt: value,
      });
    case FilterOperator.IS_GREATER_OR_EQUALS:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _gte: value } }],
        };
      }
      return clauseBuilderFunction(field, {
        _gte: value,
      });
    case FilterOperator.IS_LESS:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _lt: value } }],
        };
      }
      return clauseBuilderFunction(field, {
        _lt: value,
      });
    case FilterOperator.IS_LESS_OR_EQUAL:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _lte: value } }],
        };
      }
      return clauseBuilderFunction(field, {
        _lte: value,
      });

    case FilterOperator.IS:
      if (!Array.isArray(value)) {
        const date = parseDateString(value);
        if (date && isValid(date)) {
          return isFilter(field, date);
        }
      }
      return clauseBuilderFunction(field, {
        _eq: value,
      });
    case FilterOperator.NOT:
      if (!Array.isArray(value)) {
        const date = parseDateString(value);
        if (date && isValid(date)) {
          return notFilter(field, date);
        }
      }
      return clauseBuilderFunction(field, {
        _neq: value,
      });
    case FilterOperator.CONTAINS:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return generateDefaultDistributedWhereClause(value as string);
      }
      return clauseBuilderFunction(field, { _ilike: `${'%' + value + '%'}` });
    case FilterOperator.STARTS_WITH:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return generateDefaultDistributedWhereClause(value as string);
      }
      return clauseBuilderFunction(field, { _like: `${value + '%'}` });
    case FilterOperator.ENDS_WITH:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return generateDefaultDistributedWhereClause(value as string);
      }
      return clauseBuilderFunction(field, { _like: `${'%' + value}` });
    case FilterOperator.IS_EMPTY:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _is_null: true } }],
        };
      }
      return clauseBuilderFunction(field, { _is_null: true });
    case FilterOperator.IS_NOT_EMPTY:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return {
          _and: [{ type: { _eq: field } }, { amount: { _is_null: false } }],
        };
      }
      return clauseBuilderFunction(field, { _is_null: false });
    case FilterOperator.IS_ANY_OF:
      if (DISTRIBUTED_FIELDS.includes(field)) {
        return generateDefaultDistributedWhereClause(value as string);
      }
      if (!value || !Array.isArray(value)) {
        return DEFAULT_WHERE_CLAUSE;
      }

      return clauseBuilderFunction(field, { _in: value });
    // Date filtering
    case FilterOperator.AFTER:
      if (!Array.isArray(value)) {
        const date = parseDateString(value);
        if (date && isValid(date)) {
          return afterFilter(field, date);
        }
      }
      return clauseBuilderFunction(field, { _gt: value });
    // Date filtering
    case FilterOperator.BEFORE:
      if (!Array.isArray(value)) {
        const date = parseDateString(value);
        if (date && isValid(date)) {
          return beforeFilter(field, date);
        }
      }
      return clauseBuilderFunction(field, { _lt: value });
    // Date filtering
    case FilterOperator.ON_OR_AFTER:
      if (!Array.isArray(value)) {
        const date = parseDateString(value);
        if (date && isValid(date)) {
          return onOrAfterFilter(field, date);
        }
      }
      return clauseBuilderFunction(field, { _gte: value });
    // Date filtering
    case FilterOperator.ON_OR_BEFORE:
      if (!Array.isArray(value)) {
        const date = parseDateString(value);
        if (date && isValid(date)) {
          return onOrBeforeFilter(field, date);
        }
      }
      return clauseBuilderFunction(field, { _lte: value });

    default:
      return DEFAULT_WHERE_CLAUSE;
  }
};

export const handleFilterModelChange = (
  { operator, field, value }: GridFilterItem,
  clauseBuilderFunction: (
    field: string,
    gqlOperator: QueryParams,
  ) => QueryParams,
): QueryParams => {
  let formattedValue = value;

  const convertToCentsOrValue = (input: string) => {
    if (!isNaN(Number(input))) {
      return convertFromNumberToX100(Number(input)).toString();
    }
    return input;
  };

  if (Array.isArray(formattedValue)) {
    formattedValue = formattedValue.map((val: string) =>
      convertToCentsOrValue(val),
    );
  } else {
    formattedValue = convertToCentsOrValue(value);
  }

  return generateGqlWhereClause(
    operator as FilterOperator,
    field as FilterField,
    formattedValue,
    clauseBuilderFunction,
  );
};

export const handleLogicalOperatorChaining = (
  clauses: QueryParams[],
  logicalOperator?: 'or' | 'and',
) => {
  const gqlLogicalOperator: '_or' | '_and' =
    logicalOperator === 'or' ? '_or' : '_and';
  if (clauses.length === 1) {
    return { where: clauses[0] };
  }
  return {
    where: {
      [gqlLogicalOperator]: clauses,
    },
  };
};

export const handleMultipleFilter = (
  model: GridFilterModel,
  setWhereClause: (clause: GqlWhereClause) => void,
  clauseBuilderFunction: (
    field: string,
    gqlOperator: QueryParams,
  ) => QueryParams,
  setSearchedTerm?: (term: string) => void,
) => {
  if (model.quickFilterValues && setSearchedTerm) {
    setSearchedTerm(model.quickFilterValues.join(''));
  }
  if (!model.items.length) {
    setWhereClause(DEFAULT_WHERE_CLAUSE);
  } else {
    const clauses = model.items.map((values) =>
      handleFilterModelChange(values, clauseBuilderFunction),
    );
    setWhereClause(handleLogicalOperatorChaining(clauses, model.logicOperator));
  }
};

export const handleQuickFiltering = ({
  quickFilterValues,
  fields,
  setWhereClause,
  clauseBuilderFunction,
}: QuickFilteringParameters) => {
  if (!quickFilterValues.length) {
    setWhereClause(DEFAULT_WHERE_CLAUSE);
  } else {
    const clauses = fields
      .map((field) => {
        return quickFilterValues.map((value) =>
          handleFilterModelChange(
            { operator: FilterOperator.CONTAINS, field, value },
            clauseBuilderFunction,
          ),
        );
      })
      .flatMap((values) => values);

    setWhereClause(handleLogicalOperatorChaining(clauses, 'or'));
  }
};
