import { Download } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ComponentProps, MouseEvent } from 'react';

import { ActionButton } from 'ui/action-button';

interface Props
  extends Omit<ComponentProps<typeof ActionButton>, 'variant' | 'children'> {
  label: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export const DownloadButton = ({
  label,
  onClick,
  disabled = false,
  ...rest
}: Props) => {
  return (
    <ActionButton
      variant="secondary"
      onClick={onClick}
      disabled={!onClick || disabled}
      {...rest}
    >
      <Stack alignItems="center" spacing={1} direction="row">
        <Download fontSize="inherit" />
        <span>{label}</span>
      </Stack>
    </ActionButton>
  );
};
