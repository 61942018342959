import { DocumentStatusEnum } from 'business/fund-manager/fund/services/types';
import { LpDocument, OnBoardingStatusEnum } from 'generated/graphql';

export const getLpDocumentStatus = (
  lpDocument?: Pick<LpDocument, 'onboardingStatus' | 'expirationDate'> | null,
): DocumentStatusEnum => {
  if (!lpDocument) {
    return DocumentStatusEnum.Requested;
  }

  if (lpDocument.onboardingStatus === OnBoardingStatusEnum.Declined) {
    return DocumentStatusEnum.Declined;
  }

  if (
    lpDocument.expirationDate &&
    new Date() > new Date(lpDocument.expirationDate)
  ) {
    return DocumentStatusEnum.Expired;
  }

  if (lpDocument.onboardingStatus === OnBoardingStatusEnum.Validated) {
    return DocumentStatusEnum.Validated;
  }

  return DocumentStatusEnum.PendingValidation;
};
