import { Stack } from '@mui/material';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';

import { useLanguageContext } from 'business/providers/language-provider';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { getDefaultColumnTypes } from 'technical/ag-grid';
import {
  useStartSilenceAGGridLicenseLogsOnDev,
  stopSilenceAGGridLicenseLogsOnDev,
} from 'ui/alternative-table/utils';
import Loader from 'ui/loader';
import { useAgGrigLocaleText } from 'ui/use-ag-grid-locale-text';

interface AlternativeTableProps<T> extends Omit<GridOptions<T>, 'columnDefs'> {
  loading?: boolean;
  columnDefs: ColDef<T>[];
}
export const SimpleTable = <T,>({
  loading,
  rowData,
  columnDefs,
  defaultColDef,
  ...rest
}: AlternativeTableProps<T>) => {
  const { language } = useLanguageContext();

  const palette = usePalette();
  const localeText = useAgGrigLocaleText();

  useStartSilenceAGGridLicenseLogsOnDev();

  // This function is used to resize table to fit width
  const onGridReady = (event: GridReadyEvent<T, any>) => {
    event.api.sizeColumnsToFit();
    stopSilenceAGGridLicenseLogsOnDev();
  };

  const gridRef = useRef<AgGridReact<T>>(null);

  return (
    <Stack spacing={2}>
      <div
        className="ag-theme-material rounded bg-white overflow-hidden"
        style={{
          '--ag-header-column-separator-color': palette.tableCellDivider,
          '--ag-header-column-separator-display': 'block',
        }}
      >
        <AgGridReact<T>
          {...rest}
          tooltipShowDelay={1000}
          tooltipMouseTrack={true}
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={loading ? undefined : rowData}
          suppressCsvExport
          suppressExcelExport
          onGridReady={onGridReady}
          loadingCellRenderer={<Loader />}
          loadingOverlayComponent={Loader}
          localeText={localeText}
          defaultColDef={
            defaultColDef
              ? defaultColDef
              : {
                  flex: 1,
                  minWidth: 150,
                  sortable: true,
                  resizable: true, // for horizontal scroll
                  filter: true,
                }
          }
          columnTypes={getDefaultColumnTypes(language)}
        />
      </div>
    </Stack>
  );
};
