import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useAppContext } from 'business/providers/app-provider';
import { useThemeConfigQuery } from 'generated/graphql';
import { ErrorBoundary } from 'technical/error-boundary';
import Page from 'ui/layout/page';

import { DEFAULT_NOT_CONNECTED_ROUTE } from './protected-route-container';

interface ConnectedPageProps extends PropsWithChildren {
  title?: string;
  path?: string;
}
function ConnectedPage({ title, path, children }: ConnectedPageProps) {
  const { isConnected, user } = useAppContext();
  const { data } = useThemeConfigQuery();
  const { t } = useTranslation();

  if (!isConnected || !user) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  return (
    <Page
      path={path}
      title={title ? t(title) : undefined}
      logo={data?.themeConfig?.smallLogo}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Page>
  );
}

export default ConnectedPage;
