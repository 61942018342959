import {
  createPassword,
  forgotPassword,
  getAccessToken,
  getAuthResult,
  goToLogin,
  initAuthentication,
  isAuthenticated,
  logout,
  refreshEmailValidation,
  renewToken,
  requestEmailVerificationCallback,
  requestLoginCallback,
  resetPassword,
  setupTotp,
  signIn,
  validateMfa,
} from 'technical/auth/providers/custom';

const authService = {
  goToLogin,
  isAuthenticated,
  signIn,
  validateMfa,
  requestLoginCallback,
  getAuthResult,
  getAccessToken,
  initAuthentication,
  logout,
  requestPasswordReset: forgotPassword,
  resetPassword,
  createPassword,
  renewToken,
  refreshEmailValidation,
  requestEmailVerificationCallback,
  setupTotp,
};

export { getAccessToken, renewToken };

export default authService;
