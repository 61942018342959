import ClearIcon from '@mui/icons-material/Clear';
import { Stack } from '@mui/material';
import classNames from 'classnames';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { convertFileSizeToMb } from 'technical/file';
import { ImageFit } from 'ui/image-fit';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface Props {
  url?: string | null;
  name?: string;
  size?: number;
  handleDelete: () => void;
  className?: string;
}

const DisplayFile = ({ url, name }: Pick<Props, 'url' | 'name'>) => {
  if (url) {
    return <ImageFit src={url} />;
  }
  if (name) {
    return <Typo className={styles.name}>{name}</Typo>;
  }
  return null;
};

export function PreviewFile({
  url,
  name,
  size,
  handleDelete,
  className,
}: Props) {
  const palette = usePalette();
  return (
    <Stack
      className={classNames(styles.stack, className, {
        [styles.previewStack]: !!url,
      })}
      sx={{ border: `2px solid ${palette.primaryDark}` }}
      spacing={2}
      padding={2}
      alignItems="center"
    >
      <DisplayFile name={name} url={url} />
      {size ? (
        <Typo
          size="sm"
          color="neutral-light"
          className={styles.info}
        >{`${convertFileSizeToMb(size)} Mb`}</Typo>
      ) : null}
      <ClearIcon
        className={styles.removeButton}
        onClick={handleDelete}
        sx={{ color: palette.primaryDark }}
      />
    </Stack>
  );
}
