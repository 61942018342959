import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { managerRegulators } from 'business/fund-manager/fund/services/constants';
import { FundProfile } from 'business/fund-manager/fund/services/types';
import { FormSection, FormInputDropdown, FormInputText } from 'ui/form';

export const OtherForm = () => {
  const { control } = useFormContext<FundProfile>();
  const { t } = useTranslation();

  return (
    <FormSection title={t('pages.fundManager.fundCreation.form.other.title')}>
      <FormInputDropdown
        name="managerRegulator"
        label={t(
          'pages.fundManager.fundCreation.form.other.managerRegulator.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.other.managerRegulator.placeholder',
        )}
        control={control}
        options={managerRegulators.map((value) => ({
          id: value,
          // value is not supposed to be translated
          // cf PE3-414
          value,
        }))}
      />
      <FormInputText
        multiline
        name="auditor"
        label={t('pages.fundManager.fundCreation.form.other.auditor.label')}
        placeholder={t(
          'pages.fundManager.fundCreation.form.other.auditor.placeholder',
        )}
        control={control}
      />

      <FormInputText
        multiline
        name="outlineOfStructure"
        label={t(
          'pages.fundManager.fundCreation.form.other.outlineOfStructure.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.other.outlineOfStructure.placeholder',
        )}
        control={control}
      />
    </FormSection>
  );
};
