import { ReactNode } from 'react';

import { ErrorLabel } from 'ui/error-label';

import { FundTermForm } from './fund-term-section';
import { GeneralInformationForm } from './general-information-section';
import { InvestmentFocusForm } from './investment-focus-section';
import { InvestmentPeriodForm } from './investment-period-section';
import { KeyEconomicTermForm } from './key-economic-term-section';
import { OtherForm } from './other-section';

type FundProfileFormProps = {
  error?: Error;
  action?: ReactNode;
  readOnlyManagementCompany?: boolean;
};

export const FundProfileForm = ({
  error,
  action,
  readOnlyManagementCompany = false,
}: FundProfileFormProps) => {
  return (
    <>
      {error ? <ErrorLabel label={error.message} /> : null}
      <GeneralInformationForm
        action={action}
        readOnlyManagementCompany={readOnlyManagementCompany}
      />
      <InvestmentPeriodForm />
      <FundTermForm />
      <InvestmentFocusForm />
      <KeyEconomicTermForm />
      <OtherForm />
    </>
  );
};
