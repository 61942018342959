import { Stack } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import {
  LpKYB,
  LpKYC,
} from 'business/shared/lp-creation-module/services/types';
import { LpInvestorTypeEnum } from 'generated/graphql';
import {
  FormInputCheckBox,
  FormToggleButton,
  SelectInvestorGroupInput,
} from 'ui/form';

type LpCategorisationFormProps = {
  control: Control<LpKYC> | Control<LpKYB>;
  saving: boolean;
};

export const LpCategorizationForm = ({
  control,
  saving,
}: LpCategorisationFormProps) => {
  const { t } = useTranslation();

  const isLpPlatform = useIsLpPlatform();

  const investorTypeOptions = [
    LpInvestorTypeEnum.Private,
    LpInvestorTypeEnum.Institutional,
    LpInvestorTypeEnum.GeneralPartnerTeam,
  ].map((type) => ({
    label: t(
      'pages.fundManager.lpCreation.form.KYC.categorization.investorType',
      { context: type },
    ),
    value: type,
  }));

  return (
    <>
      <FormToggleButton
        label={t(
          'pages.fundManager.lpCreation.form.KYC.categorization.investorType',
        )}
        name={'investorType'}
        control={control}
        options={investorTypeOptions}
        allowEmpty={false}
        fullWidth={true}
        disabled={saving}
      />

      <SelectInvestorGroupInput name="investorGroup" fullWidth />

      <Stack>
        {[
          'professionnalClient',
          'USRelatedPerson',
          'frenchEquitySavingsPlan',
          ...(isLpPlatform ? [] : ['taxOption', 'ampereReporting']),
        ].map((name, index) => (
          <FormInputCheckBox
            key={index}
            name={name}
            control={control}
            label={t(
              'pages.fundManager.lpCreation.form.KYC.categorization.investorGroup',
              { context: name },
            )}
            disabled={saving}
          />
        ))}
      </Stack>
    </>
  );
};
