import { i18n } from 'translations';

import {
  AskForContactRequestDocument,
  AskForContactRequestInput,
} from 'generated/graphql';
import client from 'technical/graphql/client';

export const askForContactRequest = async (
  input: AskForContactRequestInput,
): Promise<void> => {
  const { data, errors } = await client.mutate({
    mutation: AskForContactRequestDocument,
    variables: {
      input,
    },
  });

  if (!data.askContactRequest.success) {
    throw new Error(
      i18n.t('errors.askForContactRequest', {
        context: errors,
      }) || '',
    );
  }
};
