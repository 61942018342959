import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundNavBar } from 'business/fund-manager/fund/components/fund-nav-bar';
import { FundProfileForm } from 'business/fund-manager/fund/components/fund-profile-form';
import { getProfileFormValues } from 'business/fund-manager/fund/services/get-profile-form-values';
import { useProfileForm } from 'business/fund-manager/fund/services/hooks/use-profile-form';
import {
  FundNavBarTabEnum,
  FundProfile,
} from 'business/fund-manager/fund/services/types';
import {
  GetFundProfileQuery,
  useGetFundProfileQuery,
  useUpdateFundProfileMutation,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { FormCard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

type FundEditFormProps = {
  id: string;
  profile: NonNullable<GetFundProfileQuery['fundProfile']>;
};

const FundEditForm = ({ id, profile }: FundEditFormProps) => {
  const { t } = useTranslation();
  const [updateMutation, { error, loading }] = useUpdateFundProfileMutation();

  const { methods, onSubmit, ...rest } = useProfileForm({
    defaultValues: getProfileFormValues(profile),
    onSubmit: (data: FundProfile) =>
      updateMutation({
        variables: {
          input: {
            id,
            data,
          },
        },
      }),
  });

  const saveButton = (
    <ActionButton type="submit" variant="primary" loading={loading}>
      {t('common.actions.save')}
    </ActionButton>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <FormCard>
          <FundProfileForm
            error={error}
            {...rest}
            action={saveButton}
            readOnlyManagementCompany
          />
          <Stack justifyContent="stretch">{saveButton}</Stack>
        </FormCard>
      </form>
    </FormProvider>
  );
};

export const FundEdit = () => {
  const { fundId = '' } = useParams();
  const { data, loading, error } = useGetFundProfileQuery({
    variables: { id: fundId },
    fetchPolicy: 'network-only',
  });

  return (
    <FundNavBar activeTab={FundNavBarTabEnum.Profile}>
      {loading || error || !data?.fundProfile ? (
        <FormCard>
          <QueryStateDisplay loading={loading} error={error} />
        </FormCard>
      ) : (
        <FundEditForm id={fundId} profile={data.fundProfile} />
      )}
    </FundNavBar>
  );
};
