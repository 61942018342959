import { ChangeEvent } from 'react';

import { useGetUploadUrlLazyQuery } from 'generated/graphql';
import { generateUniqueFilePath } from 'technical/string/generate-unique-file-path';

import { uploadObjectAsync } from '.';
import { MAX_ATTACHMENTS_FILE_SIZE } from './constant';
import { IFile } from './types';

interface Props {
  getFiles: () => IFile[];
}

/**
 * Handle upload files list for you
 *  could be used with ui/form/form-input-filess
 */
export const useOnFilesUpload = ({ getFiles }: Props) => {
  const [getUrlQuery] = useGetUploadUrlLazyQuery();

  const onFilesUpload = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<IFile[]> => {
    // the files are received as a FileList
    const files = e.currentTarget.files;

    if (!files) {
      throw new Error('no file found');
    }

    const totalUploadedSize = getFiles().reduce(
      (acc, curr) => (acc = acc + curr.size),
      0,
    );
    // We can convert to an array of File (File[])
    const fileArray = Array.from(files);
    const totalFilesSize = fileArray.reduce(
      (acc, curr) => (acc = acc + curr.size),
      0,
    );
    // if the size of all the files are supperior to the MAX_ATTACHMENTS_FILE_SIZE
    // we throw an error
    if (totalFilesSize + totalUploadedSize > MAX_ATTACHMENTS_FILE_SIZE) {
      throw new Error('Size of uploaded files must not exceeds 8 Mb');
    }

    // For each file, we create a new file and create a signed URL
    // using this file url we then upload the content and add it
    // to the uploaded file list
    return Promise.all(
      fileArray.map<Promise<IFile>>(async (file) => {
        const newFile: IFile = {
          name: file.name,
          type: file.type,
          size: file.size,
          filePath: generateUniqueFilePath(files[0].name).trim(),
        };

        const { data } = await getUrlQuery({
          variables: {
            input: {
              fileName: newFile.filePath,
            },
          },
        });

        if (!data || !data.uploadUrlResponse.url) {
          throw new Error('upload failed');
        }
        const response = await uploadObjectAsync(
          file,
          data.uploadUrlResponse.url,
        );
        if (response && response.status !== 200) {
          throw new Error('upload failed');
        }

        return newFile;
      }),
    );
  };

  return onFilesUpload;
};
