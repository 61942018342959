import { ContactWithId } from 'business/shared/services/contact/types';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';

import { getContactInformation } from './get-contact-information';
import { LegalEntityKYCContact } from './types';

export function getLegalEntityContact(
  contactRaw?: ContactWithId,
): LegalEntityKYCContact {
  const {
    contact: { type, ...contact },
    address,
  } = getContactInformation(contactRaw);

  return {
    type,
    contact,
    address,
    roles: contactRaw?.roles ?? [],
    ...(contactRaw?.ultimateEffectiveControlPercentage && {
      ultimateEffectiveControlPercentage: convertFrom10X8toNumber(
        contactRaw.ultimateEffectiveControlPercentage,
      ),
    }),
    ...(contactRaw?.taxResidenceCountry && {
      taxResidenceCountry: contactRaw.taxResidenceCountry,
    }),
    ...(contactRaw?.taxIdentificationNumber && {
      taxIdentificationNumber: contactRaw.taxIdentificationNumber,
    }),
  };
}
