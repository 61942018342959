import { Stack } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyMetricForm } from 'business/fund-manager/portfolio/company/services/types';
import { useGetCompanyMetricsTypeQuery } from 'generated/graphql';
import { FormInputDropdown, FormInputText } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

export const MetricTypeSelect = () => {
  const { companyId = '' } = useParams();
  const { t } = useTranslation();
  const { control } = useFormContext<CompanyMetricForm>();
  const companyMetricsTypeId = useWatch<CompanyMetricForm>({
    name: 'companyMetricsTypeId',
  });

  const { data, loading, error } = useGetCompanyMetricsTypeQuery({
    variables: { companyId },
  });

  if (loading || error || !data?.companyMetricsTypes) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <FormInputDropdown
        name="companyMetricsTypeId"
        label={t('pages.fundManager.portfolio.company.metrics.form.unit')}
        options={data.companyMetricsTypes.map((elt) => ({
          id: elt.id,
          value: elt.name,
        }))}
        required
        control={control}
        fullWidth
      />
      <FormInputText
        label={t('pages.fundManager.portfolio.company.metrics.form.value', {
          unit: data.companyMetricsTypes.find(
            (elt) => elt.id === companyMetricsTypeId,
          )?.unit
            ? `(${
                data.companyMetricsTypes.find(
                  (elt) => elt.id === companyMetricsTypeId,
                )?.unit
              })`
            : '',
        })}
        name="value"
        control={control}
        fullWidth
        required
      />
    </Stack>
  );
};
