import { TFunction } from 'translations/hook';

import {
  OnboardingSubscriptionStatsQueryResult,
  OnboardingSubscriptionStatusEnum,
} from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';

import { OnboardingFunnelStatsByStatus } from './types';

export const getOnboardingGraphData = (
  data: NonNullable<
    NonNullable<OnboardingSubscriptionStatsQueryResult['data']>['stats']
  >,
  t: TFunction,
) => {
  // steps to be merges together to become "pending Lps" status (new status only used in this graph)
  const graphFunnelPendingLpStatuses = [
    OnboardingSubscriptionStatusEnum.ProfileCompleted,
    OnboardingSubscriptionStatusEnum.ProfileDeclined,
    OnboardingSubscriptionStatusEnum.ProfileValidated,
    OnboardingSubscriptionStatusEnum.SubInfoCompleted,
    OnboardingSubscriptionStatusEnum.SubInfoDeclined,
    OnboardingSubscriptionStatusEnum.SubInfoValidated,
  ];

  // we need to filter statuses to keep only the ones we want to show in the graph
  const onboardingStatusStatsToDisplay: OnboardingFunnelStatsByStatus =
    data.totalByStatus.filter(
      (stat) => !graphFunnelPendingLpStatuses.includes(stat.status),
    );

  const finalFunnelStatsByStatus = [...onboardingStatusStatsToDisplay];

  // new pending LPs status cumulated stats
  const pendingLpsStatusStats = data.totalByStatus.reduce(
    (acc, stat) => {
      if (graphFunnelPendingLpStatuses.includes(stat.status)) {
        return {
          count: acc.count + stat.count,
          amount: acc.amount + stat.amount,
        };
      }
      return acc;
    },
    { count: 0, amount: 0 },
  );

  const invitationSentIndex = onboardingStatusStatsToDisplay.findIndex(
    (stat) => stat.status === OnboardingSubscriptionStatusEnum.InvitationSent,
  );

  // we add the new pending Lps status right after the invitation sent status, and before the rest of the statuses
  finalFunnelStatsByStatus.splice(invitationSentIndex + 1, 0, {
    status: 'pendingLps',
    ...pendingLpsStatusStats,
  });

  // plotly customdata
  const customData = finalFunnelStatsByStatus.map((stat) => ({
    translation: t(
      `pages.fundManager.dashboard.cards.onboardingSubscriptions.graph.status.${stat.status}`,
      { count: stat.count },
    ),
    amount: formatToMonetaryAmount(convertFrom10X8toNumber(stat.amount)),
  }));

  return {
    stats: finalFunnelStatsByStatus,
    customData,
  };
};
