import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const PortfolioMenuIcon = ({
  primaryColor,
  secondaryColor,
}: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M9 6V5C9 4.44772 9.44772 4 10 4H14C14.5523 4 15 4.44772 15 5V6"
        fill="none"
        stroke="#363636"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M4.16016 17.5529V14.6312C4.16016 13.9863 4.76149 13.51 5.3892 13.6577L11.2888 15.0459C11.431 15.0793 11.5788 15.0812 11.7218 15.0515L20.6695 13.1873C21.2905 13.058 21.8735 13.532 21.8735 14.1663V17.5529C21.8735 18.1052 21.4258 18.5529 20.8735 18.5529H5.16016C4.60787 18.5529 4.16016 18.1052 4.16016 17.5529Z"
        fill={secondaryColor}
      />
      <path
        d="M2.43164 11.6406V17.5531C2.43164 18.1054 2.87936 18.5531 3.43164 18.5531H20.8731C21.4254 18.5531 21.8731 18.1054 21.8731 17.5531V11.6406"
        fill="none"
        stroke="#363636"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M10.2086 12.9935L2.78757 11.3802C2.32788 11.2803 2 10.8735 2 10.403V7.88867C2 7.33639 2.44771 6.88867 3 6.88867H21.3055C21.8578 6.88867 22.3055 7.33639 22.3055 7.88867V10.3944C22.3055 10.8687 21.9723 11.2778 21.5078 11.3738L13.6649 12.9935M10.2086 12.9935L11.7243 13.323C11.8643 13.3534 12.0092 13.3534 12.1492 13.323L13.6649 12.9935M10.2086 12.9935V12.209C10.2086 11.6567 10.6563 11.209 11.2086 11.209H12.6649C13.2172 11.209 13.6649 11.6567 13.6649 12.209V12.9935"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
