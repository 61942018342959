import { Stack, Tabs } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { LinkTab } from 'ui/link-tab';

interface Props extends PropsWithChildren {
  activeTab: number;
  tabs: {
    to: string;
    label: string | ReactNode;
  }[];
}

export const LinkTabs = ({ activeTab, tabs, children }: Props) => {
  return (
    <Stack spacing={2}>
      <Tabs value={activeTab > 0 ? activeTab : 0}>
        {tabs.map((tab) => (
          <LinkTab key={tab.to} {...tab} />
        ))}
      </Tabs>
      <Stack>{children}</Stack>
    </Stack>
  );
};
