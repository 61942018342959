import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'translations/hook';

import LpInformationCard from 'business/lp-platform/home/components/lp-information-card';
import { getSelectedLpsData } from 'business/lp-platform/home/services/get-selected-lps-data';
import { MAX_LP_DISPLAYED } from 'business/lp-platform/nav-bar/services/lp-filters';
import { GetDashBoardInfosQuery } from 'generated/graphql';
import { GridSpacer } from 'ui/grid-spacer';
import { InfoCard } from 'ui/info-card';
import VerticalAvatarTitle from 'ui/vertical-avatar-title';

interface Props {
  data: Pick<GetDashBoardInfosQuery, 'dataviz_lp'>;
  title: string;
}

const LpNameAndLogo = React.memo(
  ({ data }: { data: Pick<GetDashBoardInfosQuery, 'dataviz_lp'> }) => {
    const { t } = useTranslation();

    // TODO : This part can be improved.
    // Currently,a re-render is necessary to display data.
    const selectedLps = getSelectedLpsData(data);
    const isSingleLp = selectedLps.length === 1;

    if (isSingleLp) {
      const { logoPath, lpName, address } = selectedLps[0];
      return (
        <LpInformationCard
          logoPath={logoPath ?? ''}
          lpName={lpName}
          address={address}
        />
      );
    }

    return (
      <GridSpacer>
        {selectedLps.slice(0, MAX_LP_DISPLAYED).map(({ lpName, logoPath }) => (
          <Grid item xs={4} key={lpName}>
            <VerticalAvatarTitle name={lpName} logoPath={logoPath ?? ''} />
          </Grid>
        ))}
        {selectedLps.length > MAX_LP_DISPLAYED && (
          <Grid item xs={4}>
            <VerticalAvatarTitle
              name={`+ ${t('common.more', {
                count: selectedLps.length - MAX_LP_DISPLAYED,
              })}`}
              logoPath={''}
              customAvatar={`${selectedLps.length - MAX_LP_DISPLAYED}`}
            />
          </Grid>
        )}
      </GridSpacer>
    );
  },
);

LpNameAndLogo.displayName = 'LpNameAndLogo';

export default function GeneralInfosCard({ data, title }: Props) {
  return (
    <InfoCard>
      <InfoCard.Title>{title}</InfoCard.Title>
      <LpNameAndLogo data={data} />
    </InfoCard>
  );
}
