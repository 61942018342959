import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Divider, IconButton, Stack } from '@mui/material';
import { ComponentProps, PropsWithChildren, ReactNode } from 'react';

import { InfoCard } from 'ui/info-card';

interface CollapseCardProps
  extends PropsWithChildren,
    Pick<ComponentProps<typeof InfoCard>, 'sx'> {
  title: ReactNode;
  expandIconText?: ReactNode;
  onToggle: () => void;
  expand?: boolean;
}

export const CollapseCard = ({
  title,
  expandIconText,
  children,
  onToggle,
  expand,
  sx,
}: CollapseCardProps) => {
  return (
    <InfoCard sx={sx}>
      <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
        <Stack flexGrow={1} marginRight={1}>
          {title}
        </Stack>
        {expandIconText ?? null}
        <IconButton onClick={onToggle} size="small">
          {expand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </Stack>
      <Divider light={true} />
      {children}
    </InfoCard>
  );
};
