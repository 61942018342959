import { Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from 'translations/hook';

import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import {
  TemplatePageContainer,
  TemplateTabEnum,
} from 'business/fund-manager/settings/template/components/template-page-container';
import { getTemplatesColumnDefinition } from 'business/fund-manager/settings/template/services/get-templates-column-definition';
import {
  GetTemplatesByManagementCompanyQuery,
  useGetTemplatesByManagementCompanyQuery,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';

export const CompanyManagementTemplates = () => {
  const { routePrefix, managementCompanyId } = useNavigateSettings();
  const { t } = useTranslation();

  const { data, loading } = useGetTemplatesByManagementCompanyQuery({
    variables: { id: managementCompanyId },
    fetchPolicy: 'network-only',
  });
  const headers: ColDef<
    GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number]
  >[] = getTemplatesColumnDefinition(t, routePrefix);

  return (
    <TemplatePageContainer
      activeTab={TemplateTabEnum.Template}
      routePrefix={routePrefix}
    >
      <Stack spacing={2}>
        <AlternativeTable<
          GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number]
        >
          fileName="template-list"
          columnDefs={headers}
          rowData={data?.templatesByManagementCompany ?? []}
          loading={loading}
          domLayout="autoHeight"
        />
      </Stack>
    </TemplatePageContainer>
  );
};
