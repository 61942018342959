import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { GetCompanyDocumentQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

export const getCompanyDocumentColumnDefinition = (
  t: TFunction,
): ColDef<GetCompanyDocumentQuery['web_portal_companyDocument'][number]>[] => {
  return [
    {
      field: 'document.name',
      headerName: t(
        'pages.fundManager.portfolio.company.documentation.table.name',
      ),
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.company.documentation.table.uploadDate',
      ),
      type: AgGridColumnTypesEnum.DATE,
      field: 'document.uploadDate',
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.company.documentation.table.type',
      ),
      colId: 'type',
      valueGetter: ({ data }) => {
        if (!data) {
          return '-';
        }
        return t(
          `pages.fundManager.portfolio.company.documentation.documentType.${data.type}`,
        );
      },
    },
  ];
};
