import ExpandIcon from '@mui/icons-material/Expand';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { SelectChangeEvent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'translations/hook';

import { OperationShareCommitmentStatusForm } from 'business/fund-manager/operation/components/operation-share-commitment-status-form';
import {
  CommitmentStatusEnum,
  OpFieldNames,
} from 'business/fund-manager/operation/services/types';
import {
  formatNumber,
  formatToMonetaryAmount,
  formatRatioToPercentage,
} from 'technical/currency/formatters';
import {
  FormInputCheckBox,
  FormInputCheckBoxProps,
  FormInputText,
  FormInputTextProps,
} from 'ui/form';
import { InfoIcon } from 'ui/icons';

import { ColSpans, GridCell, opShareCellProps } from './grid-table/grid-cell';
import {
  useCapitalGainAllShareTypes,
  useCurrentDistribAllShareTypes,
  useDividendAllShareTypes,
  useInterestAllShareTypes,
  useReturnOfCostAllShareTypes,
  useSumAmounts,
  useTotalCurrentDistrib,
  useTotalCurrentDistribAllShareTypes,
  useTotalCurrentDistribFromInputs,
  useTotalCurrentDistribPerShare,
  useTotalCurrentDistribPerShareAllShareTypes,
  useTotalDistrib,
  useTotalDistribAllShareTypes,
  useTotalDistribFromInputs,
  useTotalDistribPerShare,
  useTotalDistribPerShareAllShareTypes,
  useTotalDrawn,
  useTotalDrawnAllShareTypes,
  useTotalDrawnFromInputs,
  useTotalDrawnPerShare,
  useTotalDrawnPerShareAllShareTypes,
  useTotalRow,
  useTotalRowAllShareTypes,
  useTotalRowPerShare,
  useTotalRowPerShareAllShareTypes,
  useUpdateFundAmountFromShareAmount,
  useUpdateFundAmountFromUnitShareAmount,
  useUpdateGlobalAmountFromUnitShareAmount,
  useUpdatePerShareTypeFromFundAmount,
  useUpdatePerUnitShareFromShareAmount,
} from './op-shares-calc-fields';
import {
  OpCreationShareInput,
  toNum,
  useCommitments,
  useShares,
  useTotalCommitment,
  useTotalNumberOfShares,
} from './op-shares-utils';
import {
  calcPerShares,
  defaultCommitmentStatusFetched,
  useOperationShareData,
} from './use-fetch-share-commitments';

interface ShareProp {
  share: OpCreationShareInput;
}

interface ShareIDProp {
  shareId: string;
}

interface SharesProp {
  shares: OpCreationShareInput[];
}

export const ExpandButtonCol: FC<{
  open: boolean;
  toggle: () => void;
  className?: string;
  closedTooltip?: string;
  openTooltip?: string;
}> = ({ open, toggle, className, closedTooltip, openTooltip }) => {
  const { t } = useTranslation();
  const iconButton = (
    <IconButton color="primary" onClick={toggle}>
      {open ? (
        <UnfoldLessIcon className="rotate-90" />
      ) : (
        <ExpandIcon className="rotate-90" />
      )}
    </IconButton>
  );
  return (
    <div className={twMerge(opShareCellProps, className, 'justify-end')}>
      {openTooltip && closedTooltip ? (
        <Tooltip title={t(open ? openTooltip : closedTooltip)}>
          {iconButton}
        </Tooltip>
      ) : (
        iconButton
      )}
    </div>
  );
};

export function ExpandSharesButton({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t(
        open
          ? 'pages.fundManager.operationShareSelection.hideSharesDetail'
          : 'pages.fundManager.operationShareSelection.showSharesDetail',
      )}
    >
      <IconButton color="primary" onClick={toggle} className="-ml-2">
        {open ? <UnfoldLessIcon /> : <ExpandIcon />}
      </IconButton>
    </Tooltip>
  );
}

export const GroupTitle: FC<
  {
    className: string;
    span?: ColSpans;
  } & PropsWithChildren
> = ({ className, children, span }) => {
  return (
    <GridCell
      colSpan={span}
      className={twMerge(
        'border-b border-neutral-300 transition-all',
        className,
      )}
    >
      {children}
    </GridCell>
  );
};

export const ShareTitleCell: FC<ShareProp> = ({ share }) => {
  const { t } = useTranslation();
  return (
    <strong>
      {t('pages.fundManager.shared.shareWithName', {
        shareName: share.name,
      })}
    </strong>
  );
};

export function ImpactedCommitmentsInput() {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const { refetch, hasEqualizedCommitment, shareId } = useOperationShareData();
  const commitments = useCommitments();
  const { commitment = 0, numberOfShares = 0 } = commitments[shareId] ?? {};
  const nbSharesFormatted = formatNumber(numberOfShares, {
    precision: 2,
    minPrecision: 0,
  });

  return (
    <div className="flex-1 flex gap-2 items-center">
      <OperationShareCommitmentStatusForm
        name={`${shareId}.${OpFieldNames.commitmentStatus}`}
        label={t(
          'pages.fundManager.operationShareSelection.commitmentStatus.fieldName',
        )}
        control={control}
        disabled={!hasEqualizedCommitment}
        size="small"
        onChange={(event: SelectChangeEvent<string>) => {
          setValue(
            `${shareId}.${OpFieldNames.commitmentStatus}`,
            event.target.value,
          );
          refetch({
            shareId,
            status:
              event.target.value !== CommitmentStatusEnum.All
                ? event.target.value
                : defaultCommitmentStatusFetched,
          });
        }}
        options={[
          {
            id: CommitmentStatusEnum.All,
            value: t(
              `pages.fundManager.operationShareSelection.commitmentStatus.${CommitmentStatusEnum.All}`,
            ),
          },
          {
            id: CommitmentStatusEnum.New,
            value: t(
              `pages.fundManager.operationShareSelection.commitmentStatus.${CommitmentStatusEnum.New}`,
            ),
          },
          {
            id: CommitmentStatusEnum.Equalized,
            value: t(
              `pages.fundManager.operationShareSelection.commitmentStatus.${CommitmentStatusEnum.Equalized}`,
            ),
          },
        ]}
      />
      <Tooltip
        title={
          <div>
            {t(
              'pages.fundManager.operationShareSelection.shareTooltipCommitment',
            )}{' '}
            {formatToMonetaryAmount(commitment, {
              precision: 2,
              minPrecision: 0,
            })}{' '}
            ({nbSharesFormatted}{' '}
            {t('pages.fundManager.operationShareSelection.shareNoNumber', {
              count: numberOfShares,
            })}
            )
          </div>
        }
      >
        <span>
          <InfoIcon />
        </span>
      </Tooltip>
    </div>
  );
}

// Fund level inputs (€)

export const TotalInputTitleCell: FC = () => {
  const { t } = useTranslation();
  return (
    <strong>
      {t('pages.fundManager.operationShareSelection.totalInputAtFundLevel')}
    </strong>
  );
};

type FundInputProps = Omit<OpShareInputProps, 'name' | 'onChange'> & {
  name: OpFieldNames;
};

export const FundInput: FC<FundInputProps> = function FundInput(props) {
  const { name, ...rest } = props;
  const updatePerShareTypeFromFundAmount =
    useUpdatePerShareTypeFromFundAmount(name);
  return (
    <OpShareInput
      {...rest}
      name={`${OpFieldNames.fund}.${name}`}
      onChange={(e) => updatePerShareTypeFromFundAmount(toNum(e.target.value))}
    />
  );
};

// Share-type level inputs (€)

// Somme de toutes les sommes intermédiaires. Introduire des hooks pour éviter le copier-coller.
// useTotalDrawnFromInputs, ...

export function TotalAllFrominputsField() {
  const total =
    useTotalDrawnFromInputs() -
    useTotalDistribFromInputs() -
    useTotalCurrentDistribFromInputs();
  return (
    <CellOSRead>
      {formatToMonetaryAmount(total, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
}

// Drawn

export function TotalDrawnFromInputsField() {
  const total = useTotalDrawnFromInputs();
  return (
    <CellOSRead>
      {formatToMonetaryAmount(total, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
}

export const TotalDrawnField: FC<ShareIDProp> = ({ shareId }) => {
  const totalDrawn = useTotalDrawn(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalDrawn, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

// Distribution

export function TotalDistribFromInputsField() {
  const total = useTotalDistribFromInputs();
  return (
    <CellOSRead>
      {formatToMonetaryAmount(total, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
}

export const TotalDistribField: FC<ShareIDProp> = ({ shareId }) => {
  const totalDistrib = useTotalDistrib(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalDistrib, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

// Current distribution

export const TotalCurrentDistribField: FC<ShareIDProp> = ({ shareId }) => {
  const totalCurrentDistrib = useTotalCurrentDistrib(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalCurrentDistrib, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export function ShareAmountInput({ name }: { name: OpFieldNames }) {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { shareId } = useOperationShareData();
  const updatePerUnitShareFromShareAmount =
    useUpdatePerUnitShareFromShareAmount(shareId, name);
  const updateFundAmountFromShareAmount = useUpdateFundAmountFromShareAmount(
    shareId,
    name,
  );
  const updatePerShareTypeFromFundAmount =
    useUpdatePerShareTypeFromFundAmount(name);

  const checked = useWatch({
    name: `${shareId}.${name}.${OpFieldNames.selected}`,
  });

  const commitments = useCommitments();
  const { commitment } = commitments[shareId] ?? {};
  const disabled = !commitment;

  const checkbox = (
    <OpCheckbox
      name={`${shareId}.${name}.${OpFieldNames.selected}`}
      disabled={disabled}
      onAfterChange={(checked2) => {
        if (!checked2) {
          setValue(`${shareId}.${name}.${OpFieldNames.global}`, '');
          setValue(`${shareId}.${name}.${OpFieldNames.perShare}`, '');
        }
        updatePerShareTypeFromFundAmount(getValues()[OpFieldNames.fund][name]);
      }}
    />
  );

  const input = (
    <OpShareInput
      name={`${shareId}.${name}.${OpFieldNames.global}`}
      disabled={!checked || disabled}
      onChange={(e) => {
        const amount = toNum(e.target.value);
        updatePerUnitShareFromShareAmount(amount);
        updateFundAmountFromShareAmount(amount);
      }}
    />
  );

  return (
    <>
      {disabled ? (
        <Tooltip
          title={t(
            'pages.fundManager.operationShareSelection.noCommitmentDisabledTooltip',
          )}
        >
          <div className="flex items-center">
            {checkbox}
            {input}
          </div>
        </Tooltip>
      ) : (
        <>
          {checkbox}
          {input}
        </>
      )}
    </>
  );
}

// Row totals

export const TotalRowField: FC<ShareIDProp> = ({ shareId }) => {
  const totalRow = useTotalRow(shareId);
  return (
    <strong>
      <CellOSRead>
        {formatToMonetaryAmount(totalRow, {
          minPrecision: 0,
        })}
      </CellOSRead>
    </strong>
  );
};

// Share unit level inputs (€/share)
// Drawn

export const TotalCallPerShareField: FC<ShareIDProp> = ({ shareId }) => {
  const totalCallPerShare = useTotalDrawnPerShare(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalCallPerShare, {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

// Distribution

export const TotalDistribPerShareField: FC<ShareIDProp> = ({ shareId }) => {
  const totalDistribPerShare = useTotalDistribPerShare(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalDistribPerShare, {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

// Current distribution

export const TotalCurrentDistribPerShareField: FC<ShareIDProp> = ({
  shareId,
}) => {
  const totalCurrentDistribPerShare = useTotalCurrentDistribPerShare(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalCurrentDistribPerShare, {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export function PerShareInput({ name }: { name: OpFieldNames }) {
  const { shareId } = useOperationShareData();
  const updateGlobalAmountFromUnitShareAmount =
    useUpdateGlobalAmountFromUnitShareAmount(shareId, name);
  const updateFundAmountFromUnitShareAmount =
    useUpdateFundAmountFromUnitShareAmount(shareId, name);

  const checked = useWatch({
    name: `${shareId}.${name}.${OpFieldNames.selected}`,
  });

  return (
    <OpShareInput
      name={`${shareId}.${name}.${OpFieldNames.perShare}`}
      indented
      disabled={!checked}
      onChange={(e) => {
        const amount = toNum(e.target.value);
        updateGlobalAmountFromUnitShareAmount(toNum(e.target.value));
        updateFundAmountFromUnitShareAmount(amount);
      }}
    />
  );
}

// Row totals

export const TotalRowPerShareField: FC<ShareIDProp> = ({ shareId }) => {
  const totalRowPerShare = useTotalRowPerShare(shareId);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(totalRowPerShare, {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

// Sums (all shares)

export const TotalTitleCell: FC = () => {
  const { t } = useTranslation();
  const totalCommitment = useTotalCommitment();
  const totalNumberOfShares = useTotalNumberOfShares();
  const totalNbSharesFormatted = formatNumber(totalNumberOfShares, {
    precision: 2,
    minPrecision: 0,
  });
  return (
    <Tooltip
      title={
        <div className="flex flex-col gap-2">
          <div>
            {t(
              'pages.fundManager.operationShareSelection.totalAtFundLevelTooltip',
            )}
          </div>
          <div>
            {t(
              'pages.fundManager.operationShareSelection.shareTooltipCommitment',
            )}{' '}
            {formatToMonetaryAmount(totalCommitment, {
              precision: 2,
              minPrecision: 0,
            })}{' '}
            ({totalNbSharesFormatted}{' '}
            {t('pages.fundManager.operationShareSelection.shareNoNumber', {
              count: totalNumberOfShares,
            })}
            )
          </div>
        </div>
      }
    >
      <strong className="flex gap-1 items-center">
        {t('pages.fundManager.operationShareSelection.totalAtFundLevel')}
        <InfoIcon />
      </strong>
    </Tooltip>
  );
};

export const TotalDrawnAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <strong>
      <CellOSRead>
        {formatToMonetaryAmount(useTotalDrawnAllShareTypes(shares), {
          minPrecision: 0,
        })}
      </CellOSRead>
    </strong>
  );
};

export function AmountSumField({ name }: { name: OpFieldNames }) {
  const shares = useShares();
  const amount = useSumAmounts(shares, `${name}.${OpFieldNames.global}`);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(amount, {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
}

export const TotalDistribAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <strong>
      <CellOSRead>
        {formatToMonetaryAmount(useTotalDistribAllShareTypes(shares), {
          minPrecision: 0,
        })}
      </CellOSRead>
    </strong>
  );
};

export const ReturnOfCostAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useReturnOfCostAllShareTypes(shares), {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export const CapitalGainAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useCapitalGainAllShareTypes(shares), {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export const InterestAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useInterestAllShareTypes(shares), {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export const DividendAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useDividendAllShareTypes(shares), {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export const TotalCurrentDistribAllShareTypesField: FC<SharesProp> = ({
  shares,
}) => {
  return (
    <strong>
      <CellOSRead>
        {formatToMonetaryAmount(useTotalCurrentDistribAllShareTypes(shares), {
          minPrecision: 0,
        })}
      </CellOSRead>
    </strong>
  );
};

export const CurrentDistribAllShareTypesField: FC<SharesProp> = ({
  shares,
}) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useCurrentDistribAllShareTypes(shares), {
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export const TotalRowAllShareTypesField: FC<SharesProp> = ({ shares }) => {
  return (
    <strong>
      <CellOSRead>
        {formatToMonetaryAmount(useTotalRowAllShareTypes(shares), {
          minPrecision: 0,
        })}
      </CellOSRead>
    </strong>
  );
};

// Sums per share

export const TotalDrawnPerShareAllShareTypesField: FC<SharesProp> = ({
  shares,
}) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useTotalDrawnPerShareAllShareTypes(shares), {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export function AmountPerShareSumField({ name }: { name: OpFieldNames }) {
  const shares = useShares();
  const amount = useSumAmounts(shares, `${name}.${OpFieldNames.global}`);
  const numberOfShares = useTotalNumberOfShares();
  const perShare = calcPerShares(amount, numberOfShares);
  return (
    <CellOSRead>
      {formatToMonetaryAmount(perShare, {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
}

export const TotalDistribPerShareAllShareTypesField: FC<SharesProp> = ({
  shares,
}) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useTotalDistribPerShareAllShareTypes(shares), {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

export const TotalCurrentDistribPerShareAllShareTypesField: FC<SharesProp> = ({
  shares,
}) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(
        useTotalCurrentDistribPerShareAllShareTypes(shares),
        {
          precision: 8,
          minPrecision: 0,
        },
      )}
    </CellOSRead>
  );
};

export const TotalRowPerShareAllShareTypesField: FC<SharesProp> = ({
  shares,
}) => {
  return (
    <CellOSRead>
      {formatToMonetaryAmount(useTotalRowPerShareAllShareTypes(shares), {
        precision: 8,
        minPrecision: 0,
      })}
    </CellOSRead>
  );
};

// Final sum

export const FinalDrawnField: FC = () => {
  const { t } = useTranslation();
  const shares = useShares();
  const drawn = useTotalRowAllShareTypes(shares);
  const totalCommitment = useTotalCommitment();

  return (
    <>
      {t(
        'pages.fundManager.operationShareSelection.operationTotal.totalDrawn',
        {
          amount: formatToMonetaryAmount(drawn, {
            minPrecision: 0,
          }),
          percent: formatRatioToPercentage(
            totalCommitment ? drawn / totalCommitment : 0,
            {
              maxPrecision: 2,
            },
          ),
        },
      )}
    </>
  );
};

export function RecalcColumnsOnCommitmentChange() {
  const { getValues, trigger } = useFormContext();

  const recalcDrawn = useUpdatePerShareTypeFromFundAmount(OpFieldNames.drawn);
  const recalcManagementFees = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.managementFees,
  );
  const recalcOtherFees = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.otherFees,
  );
  const recalcReturnOfCost = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.returnOfCost,
  );
  const recalcCapitalGain = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.capitalGain,
  );
  const recalcInterest = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.interest,
  );
  const recalcDividend = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.dividend,
  );
  const recalcCurrentDistributed = useUpdatePerShareTypeFromFundAmount(
    OpFieldNames.currentDistributed,
  );
  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    // Recalculate all columns
    // For obscure reasons, without setTimeout, the updated value set in react hook form is not updated in the UI.
    // The react-hook-form devtools (@hookform/devtools) showed be the value is correctly set in the model, and it is used for other calculations like row sums. But the input itself is not updated.
    // And the validation is not triggered either by below updates. It behaves as if this update is done outside the react-hook-form normal lifecycle events.
    setTimeout(() => {
      recalcDrawn(getValues()[OpFieldNames.fund][OpFieldNames.drawn]);
      recalcManagementFees(
        getValues()[OpFieldNames.fund][OpFieldNames.managementFees],
      );
      recalcOtherFees(getValues()[OpFieldNames.fund][OpFieldNames.otherFees]);
      recalcReturnOfCost(
        getValues()[OpFieldNames.fund][OpFieldNames.returnOfCost],
      );
      recalcCapitalGain(
        getValues()[OpFieldNames.fund][OpFieldNames.capitalGain],
      );
      recalcInterest(getValues()[OpFieldNames.fund][OpFieldNames.interest]);
      recalcDividend(getValues()[OpFieldNames.fund][OpFieldNames.dividend]);
      recalcCurrentDistributed(
        getValues()[OpFieldNames.fund][OpFieldNames.currentDistributed],
      );
      trigger();
    });
  }, [
    getValues,
    recalcCapitalGain,
    recalcCurrentDistributed,
    recalcDividend,
    recalcDrawn,
    recalcInterest,
    recalcManagementFees,
    recalcOtherFees,
    recalcReturnOfCost,
    trigger,
  ]);
  return null;
}

// details

type OpCheckboxProps = Omit<FormInputCheckBoxProps, 'name' | 'control'> & {
  name: string;
  onChange?: FormInputCheckBoxProps['onChange'];
  onAfterChange?: FormInputCheckBoxProps['onAfterChange'];
};

const OpCheckbox: FC<OpCheckboxProps> = function OpCheckbox({
  className,
  ...rest
}) {
  const { control } = useFormContext();

  return (
    <FormInputCheckBox
      control={control}
      {...rest}
      className={twMerge('-ml-2', className)}
    />
  );
};

type OpShareInputProps = Omit<FormInputTextProps, 'name' | 'control'> & {
  name: string;
  onChange?: FormInputTextProps['onChange'];
  indented?: boolean;
};

const OpShareInput: FC<OpShareInputProps> = (props) => {
  const { indented, className, ...rest } = props;
  const { control } = useFormContext();

  return (
    <FormInputText
      placeholder="0"
      type="number"
      size="small"
      {...rest}
      control={control}
      className={twMerge('flex-1', indented && 'ml-[30px]', className)}
    />
  );
};

const CellOSRead: FC<PropsWithChildren> = ({ children }) => {
  return <div className="p-3.5">{children}</div>;
};
