import { useNavigate } from 'react-router';

import { FundCreationForm } from 'business/fund-manager/fund/components/fund-creation-form';
import { FundProfile } from 'business/fund-manager/fund/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useCreateFundMutation } from 'generated/graphql';

export const FundCreation = () => {
  const navigate = useNavigate();

  const [createFundMutation, { loading, error }] = useCreateFundMutation({
    onCompleted: (data) => {
      // replace current url
      navigate(
        FundManagerRoutes.FundCreateId.replace(':fundId', data.createFund.id),
        { replace: true },
      );

      navigate(
        FundManagerRoutes.FundCreateIdBank.replace(
          ':fundId',
          data.createFund.id,
        ),
      );
    },
  });

  const onSubmit = (input: FundProfile) => {
    createFundMutation({
      variables: { input },
    });
  };

  return (
    <FundCreationForm loading={loading} error={error} onSubmit={onSubmit} />
  );
};
