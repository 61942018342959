import { useIsLpUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-user';
import LpMultiSelector from 'business/lp-platform/nav-bar/components/lp-multi-selector';
import UiNavBar, { NavBarProps } from 'ui/nav-bar';

export const NavBar = (props: NavBarProps) => {
  const isLpUser = useIsLpUser();

  return (
    <UiNavBar {...props}>{isLpUser ? <LpMultiSelector /> : null}</UiNavBar>
  );
};
