import { Stack } from '@mui/material';

import { GeneralInformationRecapCard } from 'business/fund-manager/operation/components/general-information-recap-card';
import { InvestorsRecapCard } from 'business/fund-manager/operation/components/investors-recap-card';
import { SettingsRecapCard } from 'business/fund-manager/operation/components/settings-recap-card';
import { ShareRecapCard } from 'business/fund-manager/operation/components/share-recap-card';

interface CreateOperationRecapValuesProps {
  operationId: string;
}
export const CreateOperationRecapValues = ({
  operationId,
}: CreateOperationRecapValuesProps) => {
  return (
    <Stack spacing={2}>
      <GeneralInformationRecapCard operationId={operationId} />
      <ShareRecapCard operationId={operationId} />
      <InvestorsRecapCard operationId={operationId} />
      <SettingsRecapCard operationId={operationId} />
    </Stack>
  );
};
