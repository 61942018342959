import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import { UploadDocumentTemplateForm } from 'business/fund-manager/shared/components/upload-document-template-form/upload-document-template-form';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { UploadDocumentTemplateType } from 'business/shared/services/document/types';
import { useUploadDocumentTemplateMutation } from 'generated/graphql';
import { IFile } from 'technical/file-management/types';

import styles from './index.module.scss';

export const UploadDocumentTemplate = () => {
  const { navigateSettings, managementCompanyId } = useNavigateSettings();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { templateListId = '' } = useParams();

  const [uploadDocumentTemplate] = useUploadDocumentTemplateMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.uploadDocumentTemplate'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      navigateSettings(FundManagerRoutes.SettingsTemplateList);
    },
  });

  const onSubmit = async ({
    uploadedFileList,
    formValues,
  }: {
    uploadedFileList: IFile[];
    formValues: UploadDocumentTemplateType;
  }) => {
    uploadDocumentTemplate({
      variables: {
        input: {
          managementCompanyId,
          filePath: formValues.filePath,
          templateId: templateListId,
          fileName: uploadedFileList[0].name,
        },
      },
    });
  };

  return (
    <Stack direction="column" alignItems="center">
      <UploadDocumentTemplateForm
        onSubmit={onSubmit}
        className={styles.large}
      />
    </Stack>
  );
};
