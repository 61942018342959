import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { OperationType } from 'business/financial-flows/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { CombinedArrowsIcon, LeftArrowIcon, RightArrowIcon } from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { StatNumericAmountItem } from 'ui/stat-numeric-amount-item';
import { StatNumericAmountItemProps } from 'ui/stat-numeric-amount-item';

import styles from './index.module.scss';

interface Props {
  drawdown: number;
  distribution: number;
  recallableDistribution: number;
}

export const FinancialFlowSynthesis = ({
  drawdown,
  distribution,
  recallableDistribution,
}: Props) => {
  const { t } = useTranslation();
  const { currency } = useFund();

  const items: (StatNumericAmountItemProps & { type: string })[] = [
    {
      type: OperationType.Drawdown,
      value: drawdown,
      currency,
      description: t('pages.financialFlows.synthesis.drawn'),
      imageSource: <LeftArrowIcon />,
      iconColor: 'primary',
    },
    {
      type: OperationType.Distribution,
      value: distribution,
      currency,
      description: t('pages.financialFlows.synthesis.distributedNonRecallable'),
      imageSource: <RightArrowIcon />,
      iconColor: 'secondary',
    },
    {
      type: OperationType.RecallableDistribution,
      value: recallableDistribution,
      currency,
      description: t('pages.financialFlows.synthesis.distributedRecallable'),
      imageSource: <CombinedArrowsIcon />,
      iconColor: 'neutral',
    },
  ];
  return (
    <Stack direction="row" spacing={3} justifyContent={'space-between'}>
      {items.map((element) => (
        <InfoCard key={element.type} className={styles.card}>
          <StatNumericAmountItem {...element} />
        </InfoCard>
      ))}
    </Stack>
  );
};
