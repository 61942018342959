import { GetCommitmentAndOperationForLpQuery } from 'generated/graphql';

import { LpCommitmentAndOperations } from './types';

export const getLpAggregatedSubscriptionsData = (
  commitments: GetCommitmentAndOperationForLpQuery['getCommitmentsAndOperationsForLp'],
): LpCommitmentAndOperations[] => {
  return commitments.map((commitment) => {
    return {
      fundName: commitment?.share.fund.name,
      shareName: commitment?.share.name,
      shareId: commitment?.share.id,
      status: commitment?.status,
      numericPaidIn: commitment?.numericPaidIn,
      numericCommitment: commitment?.numericCommitment ?? 0,
      numericDistributed: commitment?.numericDistributed,
      numericDistributedNonRecallable:
        commitment?.numericDistributedNonRecallable,
      numericUnfundedCommitment: commitment?.numericUnfundedCommitment,
      currency: commitment?.share.fund.currency,
    };
  });
};
