import {
  PropsWithChildren,
  FC,
  Children,
  ReactNode,
  isValidElement,
  createContext,
  useContext,
} from 'react';
import { twMerge } from 'tailwind-merge';

export interface GridConfig {
  cellWidthStyle?: 'min-content' | 'max-content' | 'auto';
  /** Tailwind classes (e.g. w-40) are encouraged. */
  cellWidthClass?: string;
  cellHeightClass?: string;
  numberOfColumns?: number;
}

interface GridProps extends PropsWithChildren {
  className?: string;
  /** This config object should be memoized (e.g. with useMemo) to avoid re-rendering the grid. */
  config?: GridConfig;
}

const defaultGridConfig: GridConfig = {
  cellWidthStyle: 'min-content',
  cellWidthClass: 'w-40',
  cellHeightClass: 'h-10',
  numberOfColumns: 13,
};

export const TableGrid: FC<GridProps> = (props) => {
  const { children, className, config: c = {} } = props;
  const config = { ...defaultGridConfig, ...c };
  const { cellWidthStyle, numberOfColumns } = config;

  const rows = Children.toArray(children);
  if (!rows.length) {
    return null;
  }
  const nbColumns = numberOfColumns ?? getMaxRowLength(rows);

  return (
    <TableGridProvider config={config}>
      <div
        className={twMerge('grid', className)}
        style={{
          gridTemplateColumns: `repeat(${nbColumns},${cellWidthStyle})`,
        }}
      >
        {rows}
      </div>
    </TableGridProvider>
  );
};

// Utils

/** Return the length of the longest row in the grid. Downside: it only counts components, which is incorrect in case of column spans or manual start indexes. */
function getMaxRowLength(rows: ReactNode[]) {
  let maxLength = 0;
  for (const row of rows) {
    if (isValidElement(row)) {
      const rowLength = Children.toArray(row.props.children).length;
      if (rowLength > maxLength) {
        maxLength = rowLength;
      }
    }
  }
  return maxLength;
}

// Provider to pass the grid config to the cells

const TableGridConfigContext = createContext<GridConfig | undefined>(undefined);
// Provider to pass the grid config to the cells
const TableGridProvider: FC<GridProps> = (props) => {
  const { children, config } = props;
  return (
    <TableGridConfigContext.Provider value={config}>
      {children}
    </TableGridConfigContext.Provider>
  );
};

export function useGridConfig() {
  const context = useContext(TableGridConfigContext);
  if (!context) {
    throw new Error('useGridConfig must be used within a TableGridProvider');
  }
  return context;
}
