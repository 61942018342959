export const managerRegulators = [
  'AT - [FMA] - Finanzmarktaufsichtsbehörde',
  'BE - [FSMA] - Financial Services and Markets Authority',
  'BG - [FSC] - Financial Supervision Commission',
  'CY - [CYSEC] - Cyprus Securities and Exchange Commission',
  'CZ - [ČNB] - Česká národní banka',
  'DE - [BaFin] - Bundesanstalt für Finanzdienstleistungsaufsicht',
  'DK - [Ftnet] - Finanstilsynet',
  'EE - [FI] - Finansinspektsioon',
  'ES - [CNMV] - Comisión Nacional del Mercado de Valores',
  'FI - [FIN-FSA] - Finanssivalvonta',
  'FR - [AMF] - Autorité des marchés financiers',
  'GB - [FCA] - Financial Conduct Authority',
  'GR - [HCMC] - Epitroph Kefalaiagoras',
  'HU - [PSZAF] - Pénzügyi Szervezetek Állami Felügyelete',
  'IE - Central Bank of Ireland',
  'IS - [FME] - Fjármálaeftirlitið',
  'IT - [CONSOB] - Commissione Nazionale per le Società e la Borsa',
  'LI - [FMA] - Finanzmarktaufsicht Liechtenstein',
  'LT - [LB] - Lietuvos bankas (Banque de Lituanie)',
  'LU - [CSSF] - Commission de Surveillance du Secteur Financier',
  'LV - [FKTK] - Finanšu un kapitāla tirgus komisija',
  'MT - [MFSA] - Malta Financial Services Authority',
  'NL - [AFM] - Autoriteit Financiële Markten',
  'NO - [Ftnet] - Finanstilsynet',
  'PL - [KNF] - Komisja Nadzoru Finansowego',
  'PT - [CMVM] - Comissão do Mercado de Valores Mobiliários',
  'RO - [CNMV] - Comisia Naţională a Valorilor Mobiliare din Romania',
  'SE - [FI] - Finansinspektionen',
  'SI - [A-TVP] - Agencija za trg vrednostnih papirjev',
  'SK - [NBS] - Narodna Banka Slovenska',
];

export const EMPTY_MANAGER_REGULATOR = '';

export const fundAvailableRoutesRegex =
  /^(edit_profile|edit_bank|edit_share|share|subscription|financial-flow|pending-validation|share-valuation|risk-assessment)$/;
