import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { WarningDeclinedStep } from 'ui/warning-declined-step/warning-declined-step';

export const DeclineReason = () => {
  const { activeStepIndex, steps } = useDraftEditionStepsContext();
  const declineComment = steps[activeStepIndex]?.declineComment;

  if (!declineComment) {
    return null;
  }

  return <WarningDeclinedStep declineComment={declineComment} />;
};
