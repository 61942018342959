import { Box, Stack } from '@mui/material';
import React from 'react';

import { NavBar } from 'business/lp-platform/nav-bar';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import SideMenu from 'business/shared/side-menu';
import { NavBarProps } from 'ui/nav-bar';
import SideBar from 'ui/side-bar';

import styles from './index.module.scss';

interface PageProps extends NavBarProps {
  children?: React.ReactNode;
}
function Page({ children, ...rest }: PageProps) {
  const palette = usePalette();

  return (
    <Box
      sx={{ background: palette.backgroundGeneral }}
      className={styles.layout}
    >
      <NavBar {...rest} />
      <Stack direction={'row'}>
        <SideBar content={<SideMenu />} />
        <Box className={styles.content}>{children}</Box>
      </Stack>
    </Box>
  );
}
export default Page;
