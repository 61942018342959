import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { LpAmlRiskAssessment } from 'business/fund-manager/lp/components/lp-aml-risk-assessment/lp-aml-risk-assessment';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useSetOnboardingSubscriptionReadyForSignatureMutation,
  useUpsertRiskAssessmentMutation,
} from 'generated/graphql';

interface RiskAssessmentsFormProps {
  lpId: string;
  onboardingSubscriptionId: string;
}

export const RiskAssessmentsForm = ({
  lpId,
  onboardingSubscriptionId,
}: RiskAssessmentsFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [createRiskAssessmentMutation] = useUpsertRiskAssessmentMutation({
    onError: () => {
      enqueueSnackbar(t('errors.approveSubscriptionRiskAssessment'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const [setReadyForSignature] =
    useSetOnboardingSubscriptionReadyForSignatureMutation({
      variables: {
        id: onboardingSubscriptionId,
      },
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.generic'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: (error) => {
        enqueueSnackbar(t(error.message), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const onSubmit = (riskAssessmentIds: string[]) =>
    createRiskAssessmentMutation({
      variables: {
        input: {
          lpId,
          riskAssessmentIds,
        },
      },
    })
      .then(() => setReadyForSignature())
      .catch(() => undefined);

  return <LpAmlRiskAssessment lpId={lpId} onSubmit={onSubmit} />;
};
