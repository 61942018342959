import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { LpLegalEntityLogoDisplay } from 'business/shared/lp-creation-module/components/lp-legal-entity-logo-display';
import { LpLegalEntityLogoUpload } from 'business/shared/lp-creation-module/components/lp-legal-entity-logo-upload';
import { FormSection } from 'ui/form';

interface Props {
  onFilePathUpdate: (filePath: string) => void;
  onExisingLogoRemove: () => void;
  existingLogoId?: string | null;
}

export const LpLegalEntityLogoFormPart = ({
  onFilePathUpdate,
  onExisingLogoRemove,
  existingLogoId,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('pages.fundManager.lpCreation.form.logoUpload.title')}
      lastOne
    >
      <Stack alignItems="center">
        {existingLogoId ? (
          <LpLegalEntityLogoDisplay
            logoDocumentId={existingLogoId}
            handleDelete={onExisingLogoRemove}
          />
        ) : (
          <LpLegalEntityLogoUpload onFilePathUpdate={onFilePathUpdate} />
        )}
      </Stack>
    </FormSection>
  );
};
