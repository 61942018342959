import { ApolloError } from '@apollo/client';
import { Control, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'translations/hook';

import { SendEmailTemplateTestForm } from 'business/fund-manager/settings/template/services/types';
import {
  GetOperationAssociatedToFundAndLpQuery,
  useGetOperationAssociatedToFundAndLpQuery,
} from 'generated/graphql';
import { FormInputDropdown } from 'ui/form';
import Loader from 'ui/loader';

interface Props {
  control: Control<SendEmailTemplateTestForm>;
}
const getOptions = ({
  data,
  error,
  t,
}: {
  data: GetOperationAssociatedToFundAndLpQuery | undefined;
  error: ApolloError | undefined;
  t: TFunction;
}) => {
  if (!error && data?.web_portal_operation) {
    return data.web_portal_operation.map(({ id, identifier }) => {
      return {
        id,
        value: t(
          'pages.fundManager.templates.form.preview.operationIdentifier',
          { identifier },
        ),
      };
    });
  }
  return [
    { id: '', value: t('pages.fundManager.templates.form.preview.noData') },
  ];
};
export const EmailTemplateOperationDropdown = ({ control }: Props) => {
  const lpId = useWatch({ control, name: 'lpId' });
  const fundId = useWatch({ control, name: 'fundId' });
  const { t } = useTranslation();

  const { data, loading, error } = useGetOperationAssociatedToFundAndLpQuery({
    variables: {
      fundId,
      lpId,
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <FormInputDropdown
      name="operationId"
      label={t('pages.fundManager.templates.form.preview.operation')}
      control={control}
      required
      options={getOptions({ data, error, t })}
    />
  );
};
