import * as yup from 'yup';
import { ObjectShape } from 'yup';

import {
  CashflowEmittedByEnum,
  CashflowTypeEnum,
  GetFundSharesByOperationIdQuery,
} from 'generated/graphql';
import {
  emailRequiredSchema,
  floatNumberSchema,
  nonEmptyStringSchema,
  uuidRequiredSchema,
} from 'technical/validation';

import { CommitmentStatusEnum, OpFieldNames } from './types';

export const operationCreationInputSchema = yup.object({
  // thanks yup + root object we have to add `defined()` function
  fundId: nonEmptyStringSchema.defined(),
  identifier: nonEmptyStringSchema.defined(),
  date: yup.date().defined(),
  shouldEmailLetters: yup.boolean().defined(),
});

export const operationUpdateInputSchema = yup.object({
  operationId: nonEmptyStringSchema,
  fundId: nonEmptyStringSchema.defined(),
  identifier: nonEmptyStringSchema.defined(),
  date: yup.date().defined(),
  shouldEmailLetters: yup.boolean().defined(),
});

const formGlobalFieldSchemaShape = {
  [OpFieldNames.fund]: yup.object({
    [OpFieldNames.drawn]: floatNumberSchema,
    [OpFieldNames.managementFees]: floatNumberSchema,
    [OpFieldNames.otherFees]: floatNumberSchema,
    [OpFieldNames.returnOfCost]: floatNumberSchema,
    [OpFieldNames.capitalGain]: floatNumberSchema,
    [OpFieldNames.interest]: floatNumberSchema,
    [OpFieldNames.dividend]: floatNumberSchema,
    [OpFieldNames.currentDistributed]: floatNumberSchema,
  }),
} satisfies ObjectShape;

export type SharesFormFundPartSchema = typeof formGlobalFieldSchemaShape;
const tmp = yup.object(formGlobalFieldSchemaShape);
export type SharesFormFundPart = yup.InferType<typeof tmp>;

export const shareInputFieldSchema = yup.object({
  shareId: nonEmptyStringSchema,
  // selected: yup.boolean(),
  commitmentStatus: yup
    .mixed<CommitmentStatusEnum>()
    .oneOf(Object.values(CommitmentStatusEnum)),
  // investment: floatNumberSchema,
  // fees: floatNumberSchema,
  // other: floatNumberSchema,
  // returnOfCost: floatNumberSchema,
  // capitalGain: floatNumberSchema,
  // interest: floatNumberSchema,
  // dividend: floatNumberSchema,
  // currentDistributed: floatNumberSchema,

  [OpFieldNames.drawn]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.managementFees]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.otherFees]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.returnOfCost]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.capitalGain]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.interest]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.dividend]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
  [OpFieldNames.currentDistributed]: yup
    .object({
      [OpFieldNames.selected]: yup.boolean(),
      [OpFieldNames.global]: floatNumberSchema,
      [OpFieldNames.perShare]: floatNumberSchema,
    })
    .optional(),
});

export type ShareYupFieldsSchema = typeof shareInputFieldSchema;
export type ShareYupFields = yup.InferType<ShareYupFieldsSchema>;
type AnyFieldName = keyof ShareYupFields;

export const shareValueNames = [
  OpFieldNames.drawn,
  OpFieldNames.managementFees,
  OpFieldNames.otherFees,
  OpFieldNames.returnOfCost,
  OpFieldNames.capitalGain,
  OpFieldNames.interest,
  OpFieldNames.dividend,
  OpFieldNames.currentDistributed,
] satisfies AnyFieldName[];

type AmountFieldName = (typeof shareValueNames)[number];

type ShareFullSchema = SharesFormFundPartSchema &
  Record<string, ShareYupFieldsSchema>;

// when we tried to use a dynamic resolver we have these error:
// no validation here, `yup.lazy` is needed because the structure of the
// object is not known in advance but it currently doesn't work with the
// `resolver` property -> https://github.com/react-hook-form/resolvers/issues/567
export function makeSharesSelectionSchema(
  shares: GetFundSharesByOperationIdQuery,
) {
  const perShareSchema: Record<string, ShareYupFieldsSchema> =
    shares?.web_portal_share.reduce(
      (acc, share) => ({
        ...acc,
        [share.id]: shareInputFieldSchema,
      }),
      {},
    );
  const fullSchema = {
    ...formGlobalFieldSchemaShape,
    ...perShareSchema,
  } as ShareFullSchema;
  return yup.object(fullSchema).test((values, _ctx) => {
    // Ideally, we should just check that the total drawn (below the grid) is non-zero.
    // But it would require more refactoring to access this number here.
    // Instead, we check the source rule: there should be at least one fund-level amount provided with at least one share selected (and a non-null per-share-type amount) for that amount/column.

    return Object.entries(values[OpFieldNames.fund] ?? {}).some(([k, v]) => {
      return (
        !!v &&
        shares.web_portal_share.some(
          (share) =>
            !!(values[share.id] as ShareYupFields)[k as AmountFieldName]?.[
              OpFieldNames.selected
            ] &&
            !!(values[share.id] as ShareYupFields)[k as AmountFieldName]?.[
              OpFieldNames.global
            ],
        )
      );
    });
  });
}

export const previewEmailForm = yup.object({
  email: emailRequiredSchema,
});

export const createCashflowFormSchema = yup.object({
  date: yup.date().required(),
  amount: yup.number().positive().required(),
  type: yup.mixed<CashflowTypeEnum>().required(),
  emittedBy: yup.mixed<CashflowEmittedByEnum>().required(),
});

export const fundDocumentSchema = yup.object().shape({
  fundDocumentTypeId: uuidRequiredSchema,
  emissionDate: yup.date(),
  documentName: nonEmptyStringSchema,
  filePath: nonEmptyStringSchema,
});
