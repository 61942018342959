import { DownloadOutlined, ForwardToInboxOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'translations/hook';

import { GetOperationShareLpPreviewQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

export const getPreviewEmailDocumentColumentDefinition = (
  t: TFunction,
  handleOpenModal: () => void,
  setOperationShareLpId: Dispatch<SetStateAction<string>>,
  onClickDownload: (id: string) => Promise<void>,
): ColDef<
  GetOperationShareLpPreviewQuery['operationShareLpPreview']['operationShareLps'][number]
>[] => {
  return [
    {
      field: 'lpName',
      headerName: t(
        'pages.fundManager.operation.emailDocumentOperationPreview.table.lpName',
      ),
    },
    {
      field: 'shareName',
      headerName: t(
        'pages.fundManager.operation.emailDocumentOperationPreview.table.share',
      ),
    },
    {
      field: 'id',
      type: AgGridColumnTypesEnum.INTERACTION,
      headerName: t(
        'pages.fundManager.operation.emailDocumentOperationPreview.table.actions',
      ),
      cellRenderer: ({ value: id }: ICellRendererParams) => {
        return (
          <>
            <Tooltip
              title={t(
                'pages.fundManager.operation.emailDocumentOperationPreview.table.tooltip.sendEmail',
              )}
            >
              <IconButton
                onClick={() => {
                  setOperationShareLpId(id);
                  handleOpenModal();
                }}
              >
                <ForwardToInboxOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(
                'pages.fundManager.operation.emailDocumentOperationPreview.table.tooltip.previewOperationLetter',
              )}
            >
              <IconButton
                onClick={() => {
                  onClickDownload(id);
                }}
              >
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
};
