import { useState } from 'react';

import { IFile } from 'technical/file-management/types';
import { useFileUploadDeprecated } from 'technical/file-management/use-file-upload-deprecated';

interface UseDocumentUploadProps {
  onFileRemove: () => void;
  onFileUpload: (file: IFile) => void;
}

export const useDocumentUpload = ({
  onFileRemove,
  onFileUpload,
}: UseDocumentUploadProps) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [imgMaxDimensionsExceeded, setImgMaxDimensionsExceeded] =
    useState(false);

  const { handleFileUpload, removeUploadedFile, fileErrors, uploadedFileList } =
    useFileUploadDeprecated({
      onFileRemove,
      onFileUpload,
    });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const candidateFile = e.target.files?.[0];
    setImgMaxDimensionsExceeded(false);
    if (!candidateFile) {
      return;
    }
    const fileUrl = URL.createObjectURL(candidateFile);
    const img = document.createElement('img');
    img.onload = () => {
      if (img.width > 500 || img.height > 500) {
        setImgMaxDimensionsExceeded(true);
        return;
      }
      setUploadedFile(candidateFile);
      URL.revokeObjectURL(fileUrl);
    };
    handleFileUpload(e);
    img.src = fileUrl;
  };

  return {
    removeUploadedFile,
    fileErrors,
    uploadedFileList,
    handleOnChange,
    uploadedFile,
    imgMaxDimensionsExceeded,
  };
};
