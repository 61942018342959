export function convertStringToBoolean(value: string | undefined) {
  if (!value) {
    return false;
  }
  if (value === 'false') {
    return false;
  } else if (value === 'true') {
    return true;
  }
  return false;
}
