import { ComponentProps, ReactNode } from 'react';

import { ActionButton, ActionButtonProps } from 'ui/action-button';

import styles from './index.module.scss';

type ValidationButtonColor = 'edit-draft' | 'validate';

interface Props extends Pick<ActionButtonProps, 'onClick' | 'loading'> {
  children: ReactNode;
  color?: ValidationButtonColor;
}

const getActionButtonColor: (
  color?: ValidationButtonColor,
) => ComponentProps<typeof ActionButton>['variant'] = (color) => {
  switch (color) {
    case 'edit-draft':
      return 'primary';
    case 'validate':
      return 'submit';
    default:
      return 'secondary';
  }
};

export const ValidationButton = ({
  color,
  onClick,
  children,
  ...rest
}: Props) => {
  const actionButtonColor = getActionButtonColor(color);
  return (
    <ActionButton
      {...rest}
      size="small"
      onClick={onClick}
      variant={actionButtonColor}
      className={styles.buttonMinSize}
    >
      {children}
    </ActionButton>
  );
};
