import { Stack, Tabs } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import {
  CountsPerTab,
  OnboardingSubscriptionNavBarTabEnum,
} from 'business/fund-manager/onboarding-subscription/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { ActionButton } from 'ui/action-button';
import { LinkTab } from 'ui/link-tab';

import styles from './index.module.scss';

type OnboardingSubscriptionNavBarProps = PropsWithChildren<{
  activeTab?: OnboardingSubscriptionNavBarTabEnum;
  counts: CountsPerTab;
}>;

export const OnboardingSubscriptionNavBar = ({
  activeTab,
  children,
  counts,
}: OnboardingSubscriptionNavBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack className={styles.container}>
      <Stack flexDirection="row" marginBottom={1}>
        <Stack flexGrow={1}>
          <Tabs value={activeTab}>
            <LinkTab
              to={FundManagerRoutes.OnboardingSubscriptionRequested}
              label={`${t(
                'pages.fundManager.onboardingSubscriptions.list.requested.title',
              )} (${counts[OnboardingSubscriptionNavBarTabEnum.Requested]})`}
            />
            <LinkTab
              to={FundManagerRoutes.OnboardingSubscriptionUnsuccessful}
              label={`${t(
                'pages.fundManager.onboardingSubscriptions.list.unsuccessful.title',
              )} (${counts[OnboardingSubscriptionNavBarTabEnum.Unsuccessful]})`}
            />
            <LinkTab
              to={FundManagerRoutes.OnboardingSubscriptionHistory}
              label={`${t(
                'pages.fundManager.onboardingSubscriptions.list.history.title',
              )} (${counts[OnboardingSubscriptionNavBarTabEnum.History]})`}
            />
          </Tabs>
        </Stack>
        <Stack justifyContent="end" flexDirection="row" alignItems="center">
          <ActionButton
            variant="submit"
            onClick={() =>
              navigate(FundManagerRoutes.OnboardingSubscriptionCreate)
            }
          >
            {t('pages.fundManager.onboardingSubscriptions.newSubscription')}
          </ActionButton>
        </Stack>
      </Stack>
      <Stack className={styles.content}>{children}</Stack>
    </Stack>
  );
};
