import { createContext } from 'react';

import { CurrencyEnum } from 'generated/graphql';

export type FundContextValue = {
  currency: CurrencyEnum;
  managementCompanyId: string | undefined;
};

export const FundContext = createContext<FundContextValue>({
  currency: CurrencyEnum.Eur,
  managementCompanyId: undefined,
});
