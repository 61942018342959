import { Card, Chip, Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { DisplayImage } from 'ui/display-image';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface ComplementaryInfoType {
  value?: string;
  label: string;
}

interface Props {
  to?: string;
  imageLink?: string | null;
  name: string;
  subtitle: string[];
  status: string;
  complementaryInfo?: ComplementaryInfoType[];
}

export function CompanyCard({
  to,
  imageLink,
  name,
  subtitle,
  status,
  complementaryInfo,
}: Props) {
  const linkProps = to ? { to, component: Link } : {};
  return (
    <Card className={styles.card} {...linkProps}>
      <Stack spacing={3} padding={2} direction="row" className={styles.body}>
        <Stack flexShrink={0} justifyContent="center">
          <DisplayImage src={imageLink} />
        </Stack>
        <Stack flexGrow={1} overflow="hidden">
          <Typo size="2xl" component="h5" bold noWrap>
            {name}
          </Typo>
          <Stack paddingLeft={1} justifyContent="between">
            {subtitle.map((sub, index) => (
              <Typo key={index} noWrap>
                {sub}
              </Typo>
            ))}
          </Stack>
          <Stack alignItems="start" justifyContent="end" flexGrow={1}>
            <Chip color="default" size="small" label={status} />
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="stretch">
        {complementaryInfo
          ? complementaryInfo.map((info, index) => (
              <>
                <Stack flexGrow={1} paddingY={1} paddingX={2}>
                  <Typo key={index} bold>
                    {info.value ? info.value : '-'}
                  </Typo>
                  <Typo key={index}>{info.label}</Typo>
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={styles.infoDivider}
                />
              </>
            ))
          : null}
      </Stack>
    </Card>
  );
}
