import { Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import { LanguageEnum } from 'translations/types';

import styles from './index.module.scss';

interface Props {
  label: string;
  value: LanguageEnum;
  picturePath: string;
  selected: boolean;
  collapsed: boolean;
  onChange: (val: LanguageEnum) => void;
}

export function LanguageItem({
  label,
  value,
  picturePath,
  selected,
  collapsed,
  onChange,
}: Props) {
  return (
    <Stack
      className={classNames(
        styles.stack,
        selected ? styles.selected : '',
        collapsed ? styles.centered : '',
      )}
      direction="row"
      onClick={() => onChange(value)}
    >
      {collapsed ? <img className={styles.image} src={picturePath} /> : null}
      <Typography
        className={classNames(
          styles.label,
          !collapsed ? styles.unCollapsed : '',
        )}
      >
        {label}
      </Typography>
    </Stack>
  );
}
