import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';
import * as yup from 'yup';

import { useGetLpSubscribedFundsAndSharesQuery } from 'generated/graphql';
import { nonEmptyStringSchema } from 'technical/validation';
import { ActionButton } from 'ui/action-button';
import { Card } from 'ui/card';
import { FormInputDropdown } from 'ui/form';

const SubscribedFundFilterSchema = yup.object({
  fundId: nonEmptyStringSchema,
  shareId: yup.string(),
});

export type SubscribedFundFilterForm = yup.InferType<
  typeof SubscribedFundFilterSchema
>;

export const SubscribedFundFilter = ({
  lpId,
  onSubmit,
  onReset,
}: {
  lpId: string;
  onSubmit: (args: SubscribedFundFilterForm | undefined) => void;
  onReset: () => void;
}) => {
  const { t } = useTranslation();
  const { loading, data } = useGetLpSubscribedFundsAndSharesQuery({
    variables: { lpId },
    fetchPolicy: 'network-only',
  });

  const { handleSubmit, control, watch, reset } =
    useForm<SubscribedFundFilterForm>({
      resolver: yupResolver<SubscribedFundFilterForm>(
        SubscribedFundFilterSchema,
      ),
      defaultValues: {
        fundId: '',
        shareId: '',
      },
    });

  const handleReset = () => {
    reset();
    onReset();
  };

  const fundId = watch('fundId');
  const fund = data?.funds
    ? data?.funds.find((fundItem) => fundItem.id === fundId)
    : undefined;
  const sharesOpts = fund
    ? fund.shares.map((shareOpt) => ({ id: shareOpt.id, value: shareOpt.name }))
    : [];

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} padding={2}>
          <FormInputDropdown
            size="small"
            name="fundId"
            label={t('pages.fundManager.lps.escrowAccount.table.fund')}
            control={control}
            disabled={loading}
            minWidth={220}
            options={
              data?.funds
                ? data.funds.map((opt) => ({ id: opt.id, value: opt.name }))
                : []
            }
          />
          <FormInputDropdown
            size="small"
            name="shareId"
            label={t('pages.fundManager.lps.escrowAccount.table.share')}
            control={control}
            disabled={loading || sharesOpts.length === 0}
            options={sharesOpts}
            minWidth={220}
          />
          <ActionButton variant="submit" type="submit">
            {t('common.actions.submit')}
          </ActionButton>
          {fundId ? (
            <ActionButton variant="secondary" onClick={handleReset}>
              {t('common.actions.reset')}
            </ActionButton>
          ) : null}
        </Stack>
      </form>
    </Card>
  );
};
