import React, { CSSProperties } from 'react';

interface JustifyProperties {
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

interface AlignItemsProperties {
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}

interface AlignContentProperties {
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch';
}

interface Props
  extends JustifyProperties,
    AlignItemsProperties,
    AlignContentProperties {
  column?: boolean;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

export function Flex({
  column,
  className,
  style,
  justify,
  alignItems,
  alignContent,
  children,
}: Props) {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        justifyContent: justify,
        alignItems,
        alignContent,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
