import { DeleteOutline } from '@mui/icons-material';
import { ComponentProps } from 'react';

import { ActionButton } from 'ui/action-button';

import styles from './index.module.scss';

type Props = Omit<ComponentProps<typeof ActionButton>, 'children' | 'variant'>;

export const DefaultDeleteButton = (props: Props) => {
  return (
    <ActionButton {...props} variant="danger">
      <DeleteOutline className={styles.iconSize} />
    </ActionButton>
  );
};
