import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpCustomQuestions } from 'business/shared/lp-creation-module/components/lp-custom-questions/lp-custom-questions';
import { LpProfileForm } from 'business/shared/lp-creation-module/components/lp-profile-form';
import { useEditLpGeneralProfileForm } from 'business/shared/lp-creation-module/services/hooks/use-edit-lp-general-profile-form';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  ClientTypeEnum,
  LpCreationStepEnum,
  GetLpFormDataQuery,
  useGetLpFormDataQuery,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { FormCard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

type Props = {
  lpProfile: NonNullable<GetLpFormDataQuery['lp']>;
  lpId: string;
};

const LpEditForm = ({ lpProfile, lpId }: Props) => {
  const { t } = useTranslation();

  const {
    methods,
    onSubmit: handleSubmit,
    error,
    saving,
  } = useEditLpGeneralProfileForm({
    lpId,
    lpProfile,
    onCompleted: () =>
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      }),
  });

  const hasActivatedCustomQuestions = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_CUSTOM_QUESTION,
  );

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const action = isAdvisor ? undefined : (
    <ActionButton variant="submit" type="submit" loading={saving}>
      {t('common.actions.save')}
    </ActionButton>
  );

  const entityStep =
    lpProfile.client === ClientTypeEnum.Individual
      ? LpCreationStepEnum.IndividualGeneralInfos
      : LpCreationStepEnum.LegalEntityGeneralInfos;

  return (
    <FormCard>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <LpProfileForm
              error={error}
              saving={saving}
              isUpdate
              action={action}
            />
            {hasActivatedCustomQuestions ? (
              <LpCustomQuestions lpId={lpId} entityStep={entityStep} />
            ) : null}
            {action}
          </Stack>
        </form>
      </FormProvider>
    </FormCard>
  );
};

export const LpEdit = () => {
  const { lpId } = useParams();

  const { data, loading, error } = useGetLpFormDataQuery({
    variables: { input: { id: lpId! } },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.lp) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return <LpEditForm lpProfile={data.lp} lpId={lpId!} />;
};
