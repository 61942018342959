import { computeOperationStats } from 'business/fund-manager/shared/services/compute-operation-values';
import { SubscriptionCardData } from 'business/shared/components/subscriptions-card/types';
import { GetLpSubscriptionsQuery } from 'generated/graphql';

import { getFundCount } from './get-fund-count';

export const getLpSubscriptionsValues = (
  data: GetLpSubscriptionsQuery | undefined,
): SubscriptionCardData => {
  if (!data?.operationShareLpAgg) {
    return {
      totalSubscriptions: 0,
      totalDrawn: 0,
      unfundedSubscriptions: 0,
      count: 0,
      pendingValidationItemsCount: 0,
    };
  }

  const totalSubscriptions: number =
    data.subscriptions.aggregate?.sum?.numericAmount ?? 0;

  const count = data ? getFundCount(data) : 0;

  const { drawdown, recallableDistribution } = data.operationShareLpAgg
    .aggregate?.sum
    ? computeOperationStats(data.operationShareLpAgg.aggregate?.sum)
    : { drawdown: 0, recallableDistribution: 0 };

  const pendingValidationSubscriptionsCount =
    data?.pendingSubscriptionsAgg?.aggregate?.count;

  const subscriptionData = {
    totalDrawn: drawdown,
    totalSubscriptions,
    // TODO move drawdown and other number in numeric
    unfundedSubscriptions:
      totalSubscriptions - drawdown + recallableDistribution,
    count,
    pendingValidationItemsCount: pendingValidationSubscriptionsCount,
  };

  return subscriptionData;
};
