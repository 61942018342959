import { CircularProgress } from '@mui/material';

import { Flex } from 'ui/flex';

import styles from './index.module.scss';

const sizes: { [key: string]: number } = {
  small: 20,
  medium: 40, // Default
  large: 60,
};

interface Props {
  label?: string;
  size?: 'small' | 'medium' | 'large';
}

export const Loader = ({ label, size = 'medium' }: Props) => (
  <Flex column alignItems="center" justify="center">
    <CircularProgress
      color="primary"
      className={styles.animation}
      size={sizes[size]}
    />
    {label && <h1>{label}</h1>}
  </Flex>
);

export const LoaderBlock = ({ size }: Props) => (
  <div className="flex flex-col w-full flex-1 items-center justify-center">
    <Loader size={size} />
  </div>
);

export default Loader;
