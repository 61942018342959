import { Stack } from '@mui/material';
import {
  ColDef,
  ICellRendererParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { CashflowEmittedByEnum, CurrencyEnum } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { isNumber } from 'technical/number/is-number';
import { EditCellClue } from 'ui/alternative-table/components/edit-cell-clue';

import { OperationSharesLp, OperationSharesLpDisplayValues } from './types';

interface OperationShareRecapCol extends OperationSharesLpDisplayValues {
  onPremiumUpdate: (props: { id: string; premium: number }) => void;
  currency?: CurrencyEnum;
}
export function getOperationShareRecapColumnDefinition(
  t: TFunction,
  {
    displayInvestmentFeesAndOther,
    displayReturnOfCostCapitalGainInterestorDividends,
    displayCurrentlyDistributed,
    onPremiumUpdate,
    currency,
  }: OperationShareRecapCol,
): ColDef<OperationSharesLp>[] {
  const columns: ColDef<OperationSharesLp>[] = [
    {
      field: 'lpView.lpName',
      headerName: t('pages.fundManager.operation.shareRecap.table.lpName'),
      pinned: 'left',
      minWidth: 200,
      sort: 'asc',
    },
    {
      field: 'shareName',
      headerName: t('pages.fundManager.operation.shareRecap.table.shareName'),
      pinned: 'left',
      sort: 'asc',
    },
  ];

  if (displayInvestmentFeesAndOther) {
    columns.push(
      {
        field: 'drawn',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t('pages.fundManager.operation.shareRecap.table.drawn'),
        cellClass: 'font-bold',
      },
      {
        field: 'numericInvestment',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          'pages.fundManager.operation.shareRecap.table.investment',
        ),
      },
      {
        field: 'numericFees',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t('pages.fundManager.operation.shareRecap.table.fees'),
      },
      {
        field: 'numericOther',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t('pages.fundManager.operation.shareRecap.table.other'),
      },
      {
        headerName: t('pages.fundManager.operation.shareRecap.table.premium'),
        editable: true,
        valueGetter: ({ data }) =>
          convertFrom10X8toNumber(data?.numericPremium || 0),
        cellEditor: 'agNumberCellEditor',
        valueSetter: (props: ValueSetterParams<OperationSharesLp>) => {
          onPremiumUpdate({
            id: props.data.id,
            premium: isNumber(props.newValue) ? Number(props.newValue) : 0,
          });
          return true;
        },
        cellRenderer: (props: ICellRendererParams) => {
          return (
            <Stack
              sx={{ width: '100%' }}
              direction="row"
              justifyContent="end"
              alignItems="center"
              spacing={2}
            >
              <span>
                {formatToMonetaryAmount(props.value, {
                  currency,
                })}
              </span>
              <EditCellClue params={props} />
            </Stack>
          );
        },
      },
    );
  }

  if (displayReturnOfCostCapitalGainInterestorDividends) {
    columns.push(
      {
        field: 'distributed',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          'pages.fundManager.operation.shareRecap.table.distributed',
        ),
        cellClass: 'font-bold',
      },
      {
        field: 'numericReturnOfCost',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          'pages.fundManager.operation.shareRecap.table.returnOfCost',
        ),
      },
      {
        field: 'numericCapitalGain',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          'pages.fundManager.operation.shareRecap.table.capitalGain',
        ),
      },
      {
        field: 'numericInterest',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t('pages.fundManager.operation.shareRecap.table.interest'),
      },
      {
        field: 'numericDividend',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t('pages.fundManager.operation.shareRecap.table.dividend'),
      },
      {
        field: 'witholdings',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          'pages.fundManager.operation.shareRecap.table.witholdings',
        ),
      },
      {
        field: 'netDistributed',
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          'pages.fundManager.operation.shareRecap.table.netDistributed',
        ),
        cellClass: 'font-bold',
      },
    );
  }

  if (displayCurrentlyDistributed) {
    columns.push({
      field: 'numericCurrentDistributed',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t(
        'pages.fundManager.operation.shareRecap.table.currentDistributed',
      ),
      cellClass: 'font-bold',
    });
  }

  columns.push(
    {
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t(
        'pages.fundManager.operation.shareRecap.table.toEscrowAccount',
      ),
      colId: 'toEscrowAmount',
      valueGetter: ({ data }) => {
        if (
          !data ||
          !data.emittedBy ||
          data.emittedBy !== CashflowEmittedByEnum.Fund
        ) {
          return null;
        }
        return data.escrowAmount;
      },
    },
    {
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t(
        'pages.fundManager.operation.shareRecap.table.fromEscrowAccount',
      ),
      colId: 'fromEscrowAmount',
      valueGetter: ({ data }) => {
        if (
          !data ||
          !data.emittedBy ||
          data.emittedBy !== CashflowEmittedByEnum.Investor
        ) {
          return null;
        }
        return data.escrowAmount;
      },
    },
  );

  return columns;
}
