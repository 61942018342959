import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { TFunction } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { GetFundShareValuationQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';

import { FundShareValuationFetched } from './types';

type RowType = NonNullable<
  GetFundShareValuationQuery['fundShareValuations'][number]
>;

const numericFields: (keyof RowType)[] = [
  'numericResidualValue',
  'numericTotalDrawn',
  'numericTotalDistributed',
  'numericTotalDistributedRecallable',
  'numericManagementFees',
  'numericPartnershipExpenses',
  'numericTotalOffsetsToFeesExpenses',
  'numericInterestIncome',
  'numericDividendIncome',
  'numericInterestExpense',
  'numericOtherIncomeExpense',
  'numericAccruedCarriedInterest',
  'numericRealizedPortfolio',
  'numericUnrealizedPortfolio',
];

export function getFundShareValuationColumnDefinition({
  t,
  latestValuation,
  fundId,
}: {
  t: TFunction;
  latestValuation?: FundShareValuationFetched | null;
  fundId: string;
}): ColDef<RowType>[] {
  const columns: ColDef<RowType>[] = [
    {
      type: AgGridColumnTypesEnum.DATE,
      field: 'date',
      headerName: t('pages.fundManager.fundShareValuation.table.date'),
      filter: true,
    },
    {
      field: 'share.name',
      type: AgGridColumnTypesEnum.NUMBER,
      headerName: t('pages.fundManager.fundShareValuation.table.shareType'),
      filter: true,
    },
    ...numericFields.map(
      (field): ColDef<RowType> => ({
        field,
        type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
        headerName: t(
          `pages.fundManager.fundShareValuationCreation.form.${field}`,
        ),
        filter: true,
      }),
    ),
    {
      field: 'id',
      type: AgGridColumnTypesEnum.INTERACTION,
      headerName: t('pages.fundManager.fundShareValuation.table.action'),
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (!value || value !== latestValuation?.id) {
          return null;
        }
        return (
          <ActionButton
            variant="submit"
            size="medium"
            component={Link}
            to={FundManagerRoutes.FundIdShareValuationEdit.replace(
              ':fundId',
              fundId,
            ).replace(':shareValuationId', value)}
          >
            {t('common.actions.edit')}
          </ActionButton>
        );
      },
    },
  ];

  return columns;
}
