import { enqueueSnackbar } from 'notistack';
import { i18n } from 'translations';
import { useTranslation } from 'translations/hook';

import { FundReportingCreation } from 'business/fund-manager/fund/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  FundReportingTypeEnum,
  useCreateFundReportingMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import logger from 'technical/logger';
import { ValidationErrors } from 'technical/validation/types';

export const useUploadReportingDocument = (onCompleted?: () => void) => {
  const { t } = useTranslation();

  const [createFundReportingMutation, { loading }] =
    useCreateFundReportingMutation({
      onError: (err) => {
        logger.error({
          err,
        });
        const errorMsg = i18n.exists(err?.message)
          ? t(err?.message)
          : t(ValidationErrors.GENERIC);
        enqueueSnackbar(errorMsg, {
          variant: VariantTypeEnum.ERROR,
        });
      },
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.createFundReporting'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        onCompleted?.();
      },
    });

  const uploadReportingDocument = (input: FundReportingCreation) => {
    const {
      type,
      fundId,
      shareIds,
      valuationDate,
      subject,
      content,
      files: [file],
    } = input;

    const document = file
      ? { filePath: file.filePath, fileName: file.name }
      : null;

    const params =
      type === FundReportingTypeEnum.CapitalAccountStatement
        ? {
            shareIds,
            valuationDate: valuationDate ? formatAsDate(valuationDate) : null,
          }
        : null;

    return createFundReportingMutation({
      variables: {
        input: {
          document,
          type,
          fundId,
          params,
          subject,
          content,
        },
      },
    });
  };

  return {
    uploadReportingDocument,
    loading,
  };
};
