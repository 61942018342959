import { Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import styles from './index.module.scss';

interface Props {
  value: string | ReactElement;
  description: ReactElement | string;
  color?: 'primary' | 'secondary' | 'tertiary';
  backgroundColor?: 'primary' | 'secondary' | 'tertiary';
}

export default function SynthesisItem({
  value,
  description,
  color = 'primary',
  backgroundColor,
}: Props) {
  return (
    <Stack
      direction="column"
      spacing={1}
      className={styles.synthesisItem}
      sx={{ bgcolor: `${backgroundColor}.lighter` }}
    >
      <div>
        {typeof value === 'string' ? (
          <Typography
            color={color}
            variant="h5"
            className={styles.synthesisTitle}
          >
            {value}
          </Typography>
        ) : (
          value
        )}
        {typeof description === 'string' ? (
          <Typography variant="body2">{description}</Typography>
        ) : (
          description
        )}
      </div>
    </Stack>
  );
}
