import { Typography } from '@mui/material';

import styles from './index.module.scss';

export interface Info extends Props {
  key: string;
}

interface Props {
  value: string;
  align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined;
}

export function InfoDetailItem({ value, align = 'left' }: Props) {
  return (
    <Typography align={align} className={styles.infoDetails}>
      {value}
    </Typography>
  );
}
