import { Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useAreOperationLettersGeneratedQuery,
  useDownloadOperationSummaryDocumentLazyQuery,
} from 'generated/graphql';
import { downloadFile } from 'technical/download-file';
import { DownloadButton } from 'ui/download-button';

interface DownloadSummaryButtonProps {
  operationId: string;
}
export const DownloadSummaryButton = ({
  operationId,
}: DownloadSummaryButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useAreOperationLettersGeneratedQuery({
    variables: { operationId },
  });

  const [downloadOperationSummaryDocument] =
    useDownloadOperationSummaryDocumentLazyQuery({
      variables: {
        operationId,
      },
    });

  const handleDownload = async () => {
    downloadOperationSummaryDocument({
      onCompleted: ({
        downloadOperationSummaryDocument: { fileName, url },
      }) => {
        downloadFile(
          `${url}&response-content-disposition=attachment;`,
          fileName,
        );
      },
      onError: (downloadError) => {
        enqueueSnackbar(t(downloadError.message), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });
  };

  return !loading && !error && data?.areOperationLettersGenerated ? (
    <DownloadButton
      label={t('pages.fundManager.operation.details.downloadSummary.button')}
      onClick={handleDownload}
    />
  ) : (
    <Tooltip
      title={t(
        'pages.fundManager.operation.details.downloadSummary.lettersNotGenerated',
      )}
    >
      {/* The button must be wrapped by a div for the MUI Tooltip to work */}
      <div>
        <DownloadButton
          label={t(
            'pages.fundManager.operation.details.downloadSummary.button',
          )}
        />
      </div>
    </Tooltip>
  );
};
