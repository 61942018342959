import { useTranslation } from 'translations/hook';

import { TVPIPerformanceGraph } from 'business/fund-manager/fund/components/tvpi-performance-graph/tvpi-performance-graph';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useGetFundPerformanceValuesQuery } from 'generated/graphql';
import { InfoCard } from 'ui/info-card';
import { NoDataCard } from 'ui/no-data-card';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

interface Props {
  fundId: string;
}

export const FundShareValuation = ({ fundId }: Props) => {
  const { t } = useTranslation();
  const {
    data: fundPerformanceValues,
    loading,
    error,
  } = useGetFundPerformanceValuesQuery({
    variables: {
      input: {
        id: fundId,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !fundPerformanceValues?.fundPerformances) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  return (
    <>
      <InfoCard>
        <InfoCard.Title
          path={`${FundManagerRoutes.FundIdShareValuation.replace(
            ':fundId',
            fundId,
          )}`}
        >
          {t('pages.dashboard.cards.fundShareValuations.title')}
        </InfoCard.Title>
        {fundPerformanceValues?.fundPerformances.length > 0 ? (
          <div className={styles.graphContainer}>
            <TVPIPerformanceGraph
              performanceData={fundPerformanceValues.fundPerformances}
              fileName="test"
            />
          </div>
        ) : (
          <NoDataCard
            text={t('pages.dashboard.cards.fundShareValuations.noData')}
          />
        )}
      </InfoCard>
    </>
  );
};
