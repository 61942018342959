import { FormHelperText } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { ValidationErrors } from 'technical/validation/types';

interface ErrorLabelProps {
  label: string;
}

export const ErrorLabel = ({ label }: ErrorLabelProps) => {
  const { t } = useTranslation();

  return (
    <FormHelperText error>
      {t(label, { defaultValue: t(ValidationErrors.GENERIC) })}
    </FormHelperText>
  );
};
