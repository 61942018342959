import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FormInputProps, FormInputsType } from 'ui/form/form-input-props';

export type FormInputDateValue = Date | null;

export type FormInputDateProps<T extends FormInputsType> = FormInputProps<T> & {
  required?: boolean;
  onChange?: (value: FormInputDateValue) => void;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: DatePickerProps<Date>['sx'];
};

export const FormInputDate = <T extends FormInputsType>({
  name,
  control,
  label,
  rules = {},
  required,
  onChange,
  className,
  disabled,
  fullWidth,
  size,
  sx,
}: FormInputDateProps<T>) => {
  const { t } = useTranslation();
  const {
    field: { value, ref, onChange: onFormChange },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController<{ value: FormInputDateValue }>({
    control,
    name: name as 'value',
    rules: { ...rules, required },
  });

  return (
    <DatePicker<Date>
      className={className}
      label={label}
      value={value ?? null /* we add null here to avoid MUI warning */}
      ref={ref}
      onChange={(newValue: FormInputDateValue) => {
        onFormChange(newValue);
        onChange?.(newValue);
      }}
      disabled={disabled || isSubmitting}
      slotProps={{
        textField: {
          InputLabelProps: { shrink: true, error: !!error },
          required,
          error: !!error,
          fullWidth,
          helperText: error?.message ? t(error.message) : null,
          name,
          size,
        },
      }}
      sx={sx}
    />
  );
};
