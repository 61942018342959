import { PropsWithChildren } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { lpAvailableRoutesRegex } from 'business/fund-manager/lp/services/constants';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import ConnectedPage from 'business/user/helpers/connected-page';
import { useGetLpByPkQuery } from 'generated/graphql';
import { getPageTitle } from 'technical/get-page-title';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpDetailPage = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { lpId = '' } = useParams();
  const { pathname } = useLocation();

  const detailPath = FundManagerRoutes.LpId.replace(':lpId', lpId);
  const path = pathname === detailPath ? FundManagerRoutes.Lp : detailPath;

  const { data, loading, error } = useGetLpByPkQuery({
    variables: { id: lpId },
  });

  if (loading || error || !data?.lp) {
    return (
      <ConnectedPage path={path}>
        <QueryStateDisplay loading={loading} error={error} />
      </ConnectedPage>
    );
  }

  const title = getPageTitle(pathname, lpAvailableRoutesRegex);

  return (
    <ConnectedPage
      path={path}
      title={
        title
          ? t(`pages.fundManager.lp.title.${title}`, {
              lpName: data.lp.lpView?.lpName ?? '',
            })
          : t('pages.fundManager.lp.title.main', {
              lpName: data.lp.lpView?.lpName ?? '',
            })
      }
    >
      {children}
    </ConnectedPage>
  );
};
