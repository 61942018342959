import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { UploadDocumentTemplateType } from 'business/shared/services/document/types';
import { uploadDocumentTemplateSchema } from 'business/shared/services/document/validation';
import { IFile } from 'technical/file-management/types';
import { useFileUploadDeprecated } from 'technical/file-management/use-file-upload-deprecated';
import { ValidationErrors } from 'technical/validation/types';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { FileInput } from 'ui/form';

import styles from './index.module.scss';

interface UploadDocumentTemplateFormProps {
  onSubmit: ({
    uploadedFileList,
    formValues,
  }: {
    uploadedFileList: IFile[];
    formValues: UploadDocumentTemplateType;
  }) => void | Promise<unknown>;
  className?: string;
}
export const UploadDocumentTemplateForm = ({
  onSubmit,
  className,
}: UploadDocumentTemplateFormProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<UploadDocumentTemplateType>({
    resolver: yupResolver(uploadDocumentTemplateSchema),
    defaultValues: {
      filePath: '',
    },
  });

  const { handleFileUpload, removeUploadedFile, fileErrors, uploadedFileList } =
    useFileUploadDeprecated({
      onFileRemove: () => setValue('filePath', ''),
      onFileUpload: (file) => setValue('filePath', file.filePath),
    });

  const submit: SubmitHandler<UploadDocumentTemplateType> = (formValues) =>
    onSubmit({ uploadedFileList, formValues });

  const onError = (formError: any) => {
    if (t(formError?.filePath?.message)) {
      enqueueSnackbar(t(formError?.filePath?.message), {
        variant: VariantTypeEnum.ERROR,
      });
    } else {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    }
  };
  return (
    <Stack spacing={2} className={className}>
      <form onSubmit={handleSubmit(submit, onError)}>
        <Stack spacing={2}>
          {uploadedFileList?.length ? null : (
            <FileInput
              onChange={handleFileUpload}
              fileExtensionAcceptList={['.docx']}
              maximumFileSize="8 MB"
              className={styles.fullWidth}
              disabled={watch('filePath').length > 0}
            />
          )}
          {uploadedFileList
            ? uploadedFileList.map((file) => (
                <UploadedFile
                  file={file}
                  handleRemoveFile={removeUploadedFile}
                  key={file.filePath}
                  className={styles.fullWidth}
                />
              ))
            : null}
          <ActionButton variant="submit" type="submit" loading={isSubmitting}>
            {t('pages.fundManager.templates.upload.pdf.submit')}
          </ActionButton>
        </Stack>
      </form>
      {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
    </Stack>
  );
};
