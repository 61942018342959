import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useCreateShareMutation } from 'generated/graphql';
import { Button } from 'ui/button';
import { FormInputText } from 'ui/form';

import styles from './index.module.scss';
import { CreateShareForm } from './types';
import { shareCreationFormInputSchema } from './validation';

interface Props {
  fundId: string;
  closeForm: () => void;
  onCompleted?: () => void;
}

export const ShareCreationForm = ({
  fundId,
  closeForm,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const { enqueueSnackbar } = useSnackbar();

  const [createShareMutation, { loading, error }] = useCreateShareMutation({
    onCompleted: () => {
      onCompleted?.();
      closeForm();
      enqueueSnackbar(t('successMessage.createShare'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const { handleSubmit, control } = useForm<CreateShareForm>({
    resolver: yupResolver(shareCreationFormInputSchema),
    defaultValues: {
      fundId,
    },
  });

  const onSubmit: SubmitHandler<CreateShareForm> = async (formData) => {
    createShareMutation({
      variables: {
        input: formData,
      },
    });
  };

  return (
    <Stack
      className={styles.form}
      sx={{ backgroundColor: palette.backgroundForm }}
      spacing={3}
    >
      <Stack className={styles.centered}>
        <Typography sx={{ color: palette.primary[900] }}>
          {t('pages.fundManager.fundShares.shareCreation.title')}
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <Stack>
            <FormInputText
              name="name"
              label={t('pages.fundManager.fundShares.shareCreation.form.name')}
              control={control}
              required
            />
          </Stack>
          <Stack direction="row" spacing={4}>
            <FormInputText
              className={styles.mediumInput}
              name="nominalValue"
              label={t(
                'pages.fundManager.fundShares.shareCreation.form.nominalValue',
              )}
              control={control}
              required
            />
            <FormInputText
              className={styles.mediumInput}
              name="isin"
              label={t('pages.fundManager.fundShares.shareCreation.form.isin')}
              control={control}
              required
            />
          </Stack>
          <Stack>
            <Stack className={styles.centered} direction="row" spacing={2}>
              <Stack>
                <Button
                  className={styles.button}
                  variant="contained"
                  type="submit"
                  disabled={loading}
                >
                  {t('pages.fundManager.fundShares.shareCreation.submit')}
                </Button>
              </Stack>
              <Stack>
                <Button className={styles.button} onClick={closeForm}>
                  {t('pages.fundManager.fundShares.shareCreation.cancel')}
                </Button>
              </Stack>
            </Stack>
            {error ? (
              <FormHelperText error>{t('errors.createShare')}</FormHelperText>
            ) : null}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
