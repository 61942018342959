import { Divider, Stack } from '@mui/material';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { format } from 'date-fns';
import { ReactNode, RefObject, useRef, useState } from 'react';
import { useTranslation } from 'translations/hook';

import { useLanguageContext } from 'business/providers/language-provider';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { getDefaultColumnTypes } from 'technical/ag-grid';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';
import { formatAsLocaleDate } from 'technical/date';
import { isNumber } from 'technical/number/is-number';
import { ActionButton } from 'ui/action-button';
import { Card } from 'ui/card';
import Loader from 'ui/loader';
import { SearchBar } from 'ui/search-bar';
import { useAgGrigLocaleText } from 'ui/use-ag-grid-locale-text';

import { sideBarDefinition } from './constants';
import {
  useStartSilenceAGGridLicenseLogsOnDev,
  stopSilenceAGGridLicenseLogsOnDev,
} from './utils';

export interface AlternativeTableProps<T>
  extends Omit<GridOptions<T>, 'columnDefs'> {
  loading?: boolean;
  displaySidebar?: boolean;
  expandable?: boolean;
  actions?: ReactNode;
  columnDefs: ColDef<T>[];
  fileName: string;
  hideSearchbar?: boolean;
  enableCharts?: boolean;
  enableRowGroup?: boolean;
  enablePivot?: boolean;
  gridRef?: RefObject<AgGridReact<T>>;
}

interface TopActionsBarProps {
  onExpandAll: () => void;
  onCollapseAll: () => void;
  actions?: ReactNode;
  expandable?: boolean;
}

const TopActionsBar = ({
  onExpandAll,
  onCollapseAll,
  actions,
  expandable,
}: TopActionsBarProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} padding={1}>
        {expandable ? (
          <>
            <ActionButton
              size="small"
              variant="secondary"
              onClick={onExpandAll}
            >
              {t('common.actions.expandAll')}
            </ActionButton>
            <ActionButton
              size="small"
              variant="secondary"
              onClick={onCollapseAll}
            >
              {t('common.actions.collapseAll')}
            </ActionButton>
          </>
        ) : null}
        {actions ? actions : null}
      </Stack>
      <Divider />
    </>
  );
};

export const AlternativeTable = <T,>({
  loading,
  rowData,
  displaySidebar,
  columnDefs: columnDefsRaw,
  expandable,
  actions,
  fileName,
  hideSearchbar = false,
  enableCharts = true,
  enableRowGroup = true,
  enablePivot = true,
  gridRef: gridRefRaw,
  ...rest
}: AlternativeTableProps<T>) => {
  const { language } = useLanguageContext();
  const tableWrapperRef = useRef<
    HTMLDivElement & { agGrid: GridReadyEvent<T, any> }
  >(null);
  const palette = usePalette();
  const { t } = useTranslation();

  const localeText = useAgGrigLocaleText();
  const [quickFilterText, setQuickFilterText] = useState<string>('');

  useStartSilenceAGGridLicenseLogsOnDev();

  const onGridReady = (event: GridReadyEvent<T, any>) => {
    stopSilenceAGGridLicenseLogsOnDev();

    //Resize table to fit width
    event.api.sizeColumnsToFit();

    if (tableWrapperRef.current) {
      tableWrapperRef.current.agGrid = event;
    }
  };

  // Way to override ref there is probably another solution.
  // But this is the only simple solution I have right know :)
  const defaultRef = useRef<AgGridReact<T>>(null);
  const gridRef: RefObject<AgGridReact<T>> = gridRefRaw ?? defaultRef;

  const date = format(Date.now(), 'yyyyMMddkkmmss');
  const fileNameWithDate = `${fileName}-${date}`;

  const exportedColumns = columnDefsRaw
    .filter((header) => header.type !== AgGridColumnTypesEnum.INTERACTION)
    .map((header) => {
      return (header.field as string) ?? (header.colId as string); // TODO: improve typing when time
    });

  const columnDefs = columnDefsRaw.map((columnDef) => {
    // Cell rendering for INTERACTION should be overwrited
    // in case of groupby
    if (columnDef.type === AgGridColumnTypesEnum.INTERACTION) {
      return {
        ...columnDef,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.node.group) {
            return null; // display nothing if grouped row
          }

          return columnDef.cellRenderer(params);
        },
      };
    }

    return columnDef;
  });

  const processCellCallback = (params: ProcessCellForExportParams) => {
    const value = params.value;
    const columnType = params.column.getColDef().type;

    if (value === undefined) {
      return '';
    }

    if (
      (columnType === AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER ||
        columnType === AgGridColumnTypesEnum.VALUE_WITH_10X8_DIVIDER) &&
      isNumber(value)
    ) {
      return convertFrom10X8toNumber(value);
    }

    if (columnType === AgGridColumnTypesEnum.DATE) {
      return formatAsLocaleDate(value);
    }

    return value;
  };

  const defaultExportParams = {
    fileName: fileNameWithDate,
    columnKeys: exportedColumns,
    processCellCallback,
  };

  return (
    <Stack spacing={2}>
      {!hideSearchbar ? (
        <Card>
          <Stack padding={1}>
            <SearchBar
              label={t('common.search')}
              name="tableFilter"
              onChange={setQuickFilterText}
              size="small"
            />
          </Stack>
        </Card>
      ) : null}
      <div
        data-testid="ag-grid-table"
        ref={tableWrapperRef}
        className="ag-theme-material rounded bg-white overflow-hidden"
        style={{
          '--ag-header-column-separator-color': palette.tableCellDivider,
          '--ag-header-column-separator-display': 'block',
        }}
      >
        {expandable || actions ? (
          <TopActionsBar
            expandable={expandable}
            onExpandAll={() => gridRef?.current?.api?.expandAll()}
            onCollapseAll={() => gridRef?.current?.api?.collapseAll()}
            actions={actions}
          />
        ) : null}

        <AgGridReact<T>
          ref={gridRef}
          rowData={loading ? undefined : rowData}
          {...rest}
          tooltipShowDelay={100}
          tooltipMouseTrack={true}
          onGridReady={onGridReady}
          loadingCellRenderer={<Loader />}
          loadingOverlayComponent={Loader}
          sideBar={displaySidebar ? sideBarDefinition : false}
          localeText={localeText}
          groupDisplayType="multipleColumns"
          rowGroupPanelShow={enableRowGroup ? 'always' : 'never'} // drag and drop colomn header to group by them
          quickFilterText={quickFilterText}
          enableCharts={enableCharts}
          columnDefs={columnDefs}
          enableRangeSelection
          popupParent={document.body}
          ensureDomOrder
          cacheQuickFilter
          defaultCsvExportParams={defaultExportParams}
          defaultExcelExportParams={defaultExportParams}
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            sortable: true,
            resizable: true, // for horizontal scroll
            enablePivot,
            enableRowGroup,
            enableValue: true,
            chartDataType: 'category', // data = axe X
          }}
          columnTypes={getDefaultColumnTypes(language)}
          reactiveCustomComponents={true}
        />
      </div>
    </Stack>
  );
};
