import LogRocket from 'logrocket';
import { useEffect } from 'react';

import { useAppContext } from 'business/providers/app-provider';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';

export const ProductAnalyticsSetup = () => {
  const recordUserSession = useFeatureFlag(FeatureFlagEnum.RECORD_USER_SESSION);
  const { user } = useAppContext();

  useEffect(() => {
    // Record session for connected users
    if (recordUserSession && user) {
      LogRocket.init('jhshqr/pe3');
      LogRocket.identify(user.userId, {
        name: user.user.firstName ?? '',
        email: user.email,
      });
    }
  }, [recordUserSession, user]);

  return null;
};
