import { Stack } from '@mui/material';
import { RowClickedEvent } from 'ag-grid-community';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { getFundSubscriptionListColumnDefinition } from 'business/fund-manager/fund/services/get-fund-subscriptions-list-column-definition';
import { SubscriptionHistoryDrawer } from 'business/shared/components/subscription-history-drawer/subscription-history-drawer';
import {
  GetCommitmentAndOperationForFundQuery,
  GetCommitmentAndOperationForFundQueryResult,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { Typo } from 'ui/typo';

interface SubscriptionsTableProps {
  loading: boolean;
  data: GetCommitmentAndOperationForFundQueryResult['data'];
}
export const SubscriptionsTable = ({
  loading,
  data,
}: SubscriptionsTableProps) => {
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState<
    { shareId: string; lpId: string } | undefined
  >(undefined);

  const headers = getFundSubscriptionListColumnDefinition({
    t,
    customColumns: data?.customColumns ?? [],
  });

  const onRowClick = (
    event: RowClickedEvent<
      GetCommitmentAndOperationForFundQuery['getCommitmentsAndOperationsForFund'][number]
    >,
  ) => {
    if (!event.data?.share.id || !event.data?.lpView.id) {
      return;
    }
    setSelectedRow({
      shareId: event.data.share.id,
      lpId: event.data.lpView.id,
    });
  };

  const onCloseHistory = () => {
    setSelectedRow(undefined);
  };

  return (
    <Stack spacing={2}>
      <Typo size="xl" color="primary">
        {t('pages.fundManager.subscription.table.titles.validated')}
      </Typo>
      <AlternativeTable<
        GetCommitmentAndOperationForFundQuery['getCommitmentsAndOperationsForFund'][number]
      >
        fileName="fund-subscriptions-list"
        rowData={data?.getCommitmentsAndOperationsForFund ?? []}
        columnDefs={headers}
        domLayout="autoHeight"
        loading={loading}
        displaySidebar
        onRowClicked={onRowClick}
      />
      {selectedRow ? (
        <SubscriptionHistoryDrawer
          lpId={selectedRow.lpId}
          shareId={selectedRow.shareId}
          open={!!selectedRow}
          onClose={onCloseHistory}
        />
      ) : null}
    </Stack>
  );
};
