import { ColDef, ICellRendererParams, ITooltipParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { PortfolioInstrumentTransaction } from 'business/fund-manager/portfolio/inventory/components/instrument-table';
import { Statuses } from 'business/lp-platform/portfolio/enum';
import { WebCompanyTransactionTypeEnum } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatRowValueToMonetary } from 'technical/ag-grid/row-value-formatters';
import { formatNumber } from 'technical/currency/formatters';
import {
  convertFromISOdateStringToLocalDateTz,
  isISODateString,
} from 'technical/date';
import { ActionButton } from 'ui/action-button';

export function getInstrumentColumnDefinition({
  t,
  handleOpenModal,
  lastValuationDate,
}: {
  t: TFunction;
  handleOpenModal: (transaction: PortfolioInstrumentTransaction) => void;
  lastValuationDate?: string | null;
}): ColDef<PortfolioInstrumentTransaction>[] {
  const canDisplayEditButton = (data: PortfolioInstrumentTransaction) => {
    return (
      data &&
      data.date &&
      isISODateString(data.date) &&
      // If the date is before or equal to the last valuation date, the transaction cannot be edited
      (!lastValuationDate ||
        (lastValuationDate &&
          isISODateString(lastValuationDate) &&
          convertFromISOdateStringToLocalDateTz(data.date) >
            convertFromISOdateStringToLocalDateTz(lastValuationDate)))
    );
  };

  const columns: ColDef<PortfolioInstrumentTransaction>[] = [
    {
      field: 'instrument.company.name',
      headerName: t('pages.fundManager.portfolio.inventory.table.companyName'),
      filter: true,
      rowGroup: true,
      hide: true,
    },
    {
      field: 'instrument.identifier',
      headerName: t('pages.fundManager.portfolio.inventory.table.instrument'),
      rowGroup: true,
      hide: true,
    },
    {
      field: 'instrument.type',
      pinned: 'left',
      headerName: t(
        'pages.fundManager.portfolio.inventory.instrumentType.title',
      ),
      valueFormatter: ({ value }) =>
        value
          ? t(`pages.fundManager.portfolio.inventory.instrumentType.${value}`)
          : '',
      valueGetter: (params) => params.data?.instrument?.type,
      filter: true,
      rowGroup: false,
    },
    {
      field: 'date',
      headerName: t('pages.fundManager.portfolio.inventory.table.date'),
      type: AgGridColumnTypesEnum.DATE,
    },
    {
      type: AgGridColumnTypesEnum.NUMBER,
      field: 'quantity',
      headerName: t(
        'pages.fundManager.portfolio.inventory.table.quantityInPortfolio',
      ),
      aggFunc: 'sum',
      valueFormatter: ({ value }) =>
        value === 0
          ? t('pages.portfolio.table.companyStatus', {
              context: Statuses.exited,
            })
          : formatNumber(value, { precision: 0 }),
      valueGetter: (params) => {
        if (params.data) {
          return params.data.type === WebCompanyTransactionTypeEnum.Acquisition
            ? params.data.quantity
            : -params.data.quantity;
        } else {
          return '-';
        }
      },
    },
    {
      colId: 'acquisitionCost',
      headerName: t(
        'pages.fundManager.portfolio.inventory.table.acquisitionCost',
      ),
      type: AgGridColumnTypesEnum.NUMBER,
      sortable: true,
      flex: 2,
      aggFunc: 'sum',
      valueGetter: (params) => {
        return params.data &&
          params.data.type === WebCompanyTransactionTypeEnum.Acquisition
          ? params.data.quantity * params.data.nominalValue
          : '-';
      },
      valueFormatter: formatRowValueToMonetary,
    },
    {
      colId: 'exitValue',
      headerName: t('pages.fundManager.portfolio.inventory.table.exitValue'),
      type: AgGridColumnTypesEnum.NUMBER,
      sortable: true,
      flex: 1,
      aggFunc: 'sum',
      valueGetter: (params) => {
        return params.data &&
          params.data.type === WebCompanyTransactionTypeEnum.Transfer
          ? params.data.quantity * params.data.nominalValue
          : '-';
      },
      valueFormatter: formatRowValueToMonetary,
    },
    {
      colId: 'proceed',
      type: AgGridColumnTypesEnum.NUMBER,
      headerName: t('pages.fundManager.portfolio.inventory.table.proceed'),
      sortable: true,
      flex: 1,
      aggFunc: 'sum',
      valueGetter: (params) => {
        if (!params.data) {
          return '-';
        }
        const quantityInPortfolio =
          params.data.type === WebCompanyTransactionTypeEnum.Acquisition
            ? params.data.quantity
            : -params.data.quantity;
        return params.data.instrument?.companyInstrumentProceeds
          ? params.data.instrument.companyInstrumentProceeds.reduce(
              (acc, { amount }) => acc + amount,
              0,
            ) * Math.abs(quantityInPortfolio)
          : 0;
      },
      valueFormatter: formatRowValueToMonetary,
    },
    {
      headerName: t('common.actions.edit'),
      type: AgGridColumnTypesEnum.INTERACTION,
      flex: 2,
      cellRenderer: ({ data }: ICellRendererParams) => {
        if (canDisplayEditButton(data)) {
          return (
            <ActionButton
              variant="secondary"
              onClick={() => handleOpenModal(data)}
            >
              {t('common.actions.edit')}
            </ActionButton>
          );
        } else {
          return null;
        }
      },
      headerTooltip: t(
        'pages.fundManager.portfolio.inventory.table.editTransactionRules',
      ),
      tooltipValueGetter: (params: ITooltipParams) =>
        canDisplayEditButton(params.data)
          ? null
          : t(
              'pages.fundManager.portfolio.inventory.table.editTransactionRules',
            ),
    },
  ];
  return columns;
}
