import { useParams } from 'react-router';

import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { LpFormProvider } from 'business/shared/lp-creation-module/components/lp-form-provider/lp-form-provider';
import { LpKYBFormUI } from 'business/shared/lp-creation-module/components/lp-kyb-form-ui';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { useLegalEntityKYBForm } from 'business/shared/lp-creation-module/services/hooks/use-legal-entity-kyb-form';
import { LpKybFormDataQuery, useLpKybFormDataQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

type Props = {
  data: NonNullable<LpKybFormDataQuery>;
  lpId: string;
};

// COMPONENT 1: will diplay the form once the data in Component 2 is ready
const LpCreationKYBData = ({ data, lpId }: Props) => {
  const { onNextStep: onCompleted } = useDraftEditionStepsContext();

  const { methods, onSubmit, saving, error } = useLegalEntityKYBForm({
    onCompleted,
    data,
    lpId,
  });

  return (
    <DraftEditionContainer onSubmit={!saving ? onSubmit : undefined}>
      <LpFormProvider methods={methods} lpId={lpId}>
        <LpKYBFormUI
          error={error}
          saving={saving}
          country={data.lp?.lpLegalEntityGeneralInfos?.[0]?.country ?? ''}
        />
      </LpFormProvider>
    </DraftEditionContainer>
  );
};

// COMPONENT 2: will get the data and display loading state
export const LpCreationKYB = () => {
  const { lpId = '' } = useParams();

  const { data, loading, error } = useLpKybFormDataQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  if (loading || error || !data) {
    return (
      <DraftEditionContainer>
        <QueryStateDisplay loading={loading} error={error} />
      </DraftEditionContainer>
    );
  }

  return <LpCreationKYBData data={data} lpId={lpId} />;
};
