import { useAppContext } from 'business/providers/app-provider';
import { ConfigClientVariableKeyEnum } from 'generated/graphql';

export const useConfigClientVariable = (
  key: ConfigClientVariableKeyEnum,
): string => {
  const { configClient } = useAppContext();

  return configClient?.[key] ?? '';
};
