import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';

import {
  CustomAnswerInput,
  CustomQuestionAnswer,
  CustomQuestionFragment,
  LpCustomQuestionTypeEnum,
} from 'generated/graphql';

/**
 * Function to check if the form part is an interactive input field
 */
export const isCustomQuestionTypeInput = (type: LpCustomQuestionTypeEnum) =>
  [
    LpCustomQuestionTypeEnum.Select,
    LpCustomQuestionTypeEnum.SelectMultiple,
    LpCustomQuestionTypeEnum.Text,
  ].includes(type);

export type CustomQuestionTextTitle =
  | LpCustomQuestionTypeEnum.H1
  | LpCustomQuestionTypeEnum.H2
  | LpCustomQuestionTypeEnum.H3
  | LpCustomQuestionTypeEnum.H4
  | LpCustomQuestionTypeEnum.H5
  | LpCustomQuestionTypeEnum.H6;

/**
 * Function to check if the form part is a title
 */
export const isCustomQuestionTypeTitle = (
  type: LpCustomQuestionTypeEnum,
): type is CustomQuestionTextTitle =>
  [
    LpCustomQuestionTypeEnum.H1,
    LpCustomQuestionTypeEnum.H2,
    LpCustomQuestionTypeEnum.H3,
    LpCustomQuestionTypeEnum.H4,
    LpCustomQuestionTypeEnum.H5,
    LpCustomQuestionTypeEnum.H6,
  ].includes(type);

export const setFormCustomAnswersValue = <T extends FieldValues>(
  {
    questions,
    answers,
  }: { questions: CustomQuestionFragment[]; answers?: CustomQuestionAnswer[] },
  setValue: UseFormSetValue<T>,
) => {
  const customAnswers: CustomAnswerInput[] = questions
    .filter(({ type }) => isCustomQuestionTypeInput(type))
    .map((question) => {
      const answer = answers?.find(
        ({ questionId }) => questionId === question.id,
      );

      return {
        isRequired: question.isRequired,
        questionId: question.id,
        type: question.type,
        ...(question.type === LpCustomQuestionTypeEnum.SelectMultiple
          ? { values: answer?.answer?.values ?? [] }
          : { value: answer?.answer?.value || '' }),
      };
    });
  setValue('customAnswers' as Path<T>, customAnswers as PathValue<T, Path<T>>);
};
