import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { CardMedia, Stack } from '@mui/material';

import styles from './index.module.scss';

export const DisplayImage = ({ src }: { src?: string | null }) => {
  if (src) {
    return (
      <CardMedia
        component="img"
        image={src}
        alt="image"
        className={styles.logo}
      />
    );
  }

  return (
    <Stack
      sx={{
        backgroundColor: 'tertiary.lighter',
        color: 'tertiary.light',
      }}
      className={styles.logo}
      alignItems="center"
      justifyContent="center"
    >
      <ImageOutlinedIcon />
    </Stack>
  );
};
