import { Divider, Stack } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { SectionTitle } from 'ui/section-title';
import { Typo } from 'ui/typo';

type FormSectionProps = PropsWithChildren<{
  title?: string;
  action?: ReactNode;
  subtitle?: string;
  lastOne?: boolean;
  horizontal?: boolean;
}>;

/**
 * Component that will display a form section with a title, subtitle, children and an action
 * @param lastOne - if true, the divider line coming after the section wont be displayed
 */
export const FormSection = ({
  title,
  subtitle,
  children,
  action,
  lastOne,
  horizontal,
}: FormSectionProps) => {
  return (
    <>
      <Stack spacing={4}>
        <Stack direction="row" justifyContent="space-between">
          {title && (
            <Stack spacing={1}>
              <SectionTitle title={title} />
              {subtitle ? (
                <>
                  <Typo size="sm" color="neutral">
                    {subtitle}
                  </Typo>
                  <Divider />
                </>
              ) : null}
            </Stack>
          )}
          <Stack>{action ? action : undefined}</Stack>
        </Stack>
        {horizontal ? (
          <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">
            {children}
          </Stack>
        ) : (
          <Stack spacing={2}>{children}</Stack>
        )}
      </Stack>
      {lastOne ? null : <Divider />}
    </>
  );
};
