import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';
import { LanguageEnum } from 'translations/types';

import { useLanguageContext } from 'business/providers/language-provider';
import { NACE_CODES } from 'ui/nace-code/nace-codes';
import { NaceSector } from 'ui/nace-code/types';
import { Select } from 'ui/select';

const NACE_SECTORS: NaceSector[] = Object.values(
  Object.values(NACE_CODES).reduce<Record<string, NaceSector>>(
    (acc, nace) => ({
      ...acc,
      [nace.en.level1]: {
        id: nace.en.level1,
        labels: {
          en: nace.en.level1,
          fr: nace.fr.level1,
        },
        codes: [...(acc[nace.en.level1]?.codes ?? []), nace.code],
      },
    }),
    {},
  ),
);

const getSectorOptions = (language: LanguageEnum) =>
  NACE_SECTORS.map(({ id, labels }) => ({
    value: labels[language],
    id,
  })).sort((a, b) => (a.value > b.value ? 1 : -1));

interface NaceSectorSelectorProps
  extends Omit<
    ComponentProps<typeof Select>,
    'onChange' | 'value' | 'label' | 'options'
  > {
  onChange: (sector: NaceSector | undefined) => void;
  value?: NaceSector;
}

export const NaceSectorSelector = ({
  onChange,
  value,
  ...rest
}: NaceSectorSelectorProps) => {
  const { language } = useLanguageContext();
  const { t } = useTranslation();

  const handleChange = (newValue: string | null) => {
    const sector = NACE_SECTORS.find((s) => s.id === newValue) ?? undefined;

    onChange(sector);
  };

  return (
    <Select
      {...rest}
      label={t('pages.fundManager.operation.codeNace.businessActivity')}
      onChange={handleChange}
      value={value?.id}
      options={getSectorOptions(language)}
    />
  );
};
