import { Stack } from '@mui/material';
import { RowClickedEvent } from 'ag-grid-community';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { SubscriptionsTableToValidate } from 'business/fund-manager/lp/components/subscriptions-table-to-validate';
import { getLpAggregatedSubscriptionsData } from 'business/fund-manager/lp/services/get-lp-aggregated-subscriptions-data';
import { getLpSubscriptionCustomFilters } from 'business/fund-manager/lp/services/get-lp-subscription-custom-filters';
import { getLpSubscriptionListColumnDefinition } from 'business/fund-manager/lp/services/get-lp-subscriptions-list-column-definition';
import { LpCommitmentAndOperations } from 'business/fund-manager/lp/services/types';
import { SubscriptionHistoryDrawer } from 'business/shared/components/subscription-history-drawer/subscription-history-drawer';
import { AddSubscriptionButton } from 'business/shared/lp-creation-module/components/add-subscription-button';
import {
  Web_Portal_PublicationStatus_Enum,
  useGetCommitmentAndOperationForLpQuery,
  useGetPendingValidationSubscriptionsQuery,
  useLpViewQuery,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Typo } from 'ui/typo';

export const LpSubscriptionList = () => {
  const { t } = useTranslation();
  const { lpId = '' } = useParams();
  const [selectedShare, setSelectedShare] = useState<string | undefined>(
    undefined,
  );

  const {
    data,
    loading: getCommitmentLoading,
    error: getCommitmentError,
    refetch: refetchCommitments,
  } = useGetCommitmentAndOperationForLpQuery({
    variables: { lpId },
    nextFetchPolicy: 'network-only',
  });

  const {
    data: dataPendingValidation,
    loading: loadingPendingValidation,
    refetch: refetchPendingValidation,
  } = useGetPendingValidationSubscriptionsQuery({
    variables: { input: { lpId } },
    fetchPolicy: 'network-only',
  });

  const {
    data: lpPublicationStatusData,
    loading: lpPublicationStatusLoading,
    error: lpPublicationStatusError,
  } = useLpViewQuery({
    variables: { lpId },
  });

  const computedData = data
    ? getLpAggregatedSubscriptionsData(data.getCommitmentsAndOperationsForLp)
    : [];

  const customFilters = getLpSubscriptionCustomFilters(computedData);
  const headers = getLpSubscriptionListColumnDefinition(t, customFilters);

  const loading = getCommitmentLoading || lpPublicationStatusLoading;
  const error = getCommitmentError || lpPublicationStatusError;
  const lpPublicationStatus =
    lpPublicationStatusData?.lpView[0].publicationStatus ?? null;

  if (error || loading || !data || !lpPublicationStatus) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const onCompleted = () => {
    refetchCommitments();
    refetchPendingValidation();
  };

  const displayHistory = (
    event: RowClickedEvent<LpCommitmentAndOperations>,
  ) => {
    setSelectedShare(event.data?.shareId);
  };

  const hideHistory = () => {
    setSelectedShare(undefined);
  };

  return (
    <Stack spacing={2}>
      {lpPublicationStatus === Web_Portal_PublicationStatus_Enum.Validated ? (
        <Stack direction="row" justifyContent="end">
          <AddSubscriptionButton lpId={lpId} onCompleted={onCompleted} />
        </Stack>
      ) : null}

      <Stack spacing={4}>
        <SubscriptionsTableToValidate
          lpId={lpId}
          onValidated={onCompleted}
          data={dataPendingValidation}
          loading={loadingPendingValidation}
        />

        <Stack spacing={2}>
          <Typo size="xl" color="primary">
            {t('pages.fundManager.subscription.table.titles.validated')}
          </Typo>
          <AlternativeTable<LpCommitmentAndOperations>
            fileName="lp-subscriptions"
            rowData={computedData}
            columnDefs={headers}
            domLayout="autoHeight"
            loading={loading}
            displaySidebar
            onRowClicked={displayHistory}
          />
        </Stack>
      </Stack>
      {selectedShare ? (
        <SubscriptionHistoryDrawer
          lpId={lpId}
          shareId={selectedShare}
          open={!!selectedShare}
          onClose={hideHistory}
        />
      ) : null}
    </Stack>
  );
};
