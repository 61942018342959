import { createContext } from 'react';

import { LpCreationStep } from 'business/shared/lp-creation-module/services/types';

interface DraftEditionStepsContextInterface {
  activeStepIndex: number;
  steps: LpCreationStep[];
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export const DraftEditionStepsContext =
  createContext<DraftEditionStepsContextInterface>({
    activeStepIndex: 0,
    steps: [],
    onNextStep: () => {},
    onPreviousStep: () => {},
  });
