import { PropsWithChildren } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useAppContext } from 'business/providers/app-provider';
import ConnectedPage from 'business/user/helpers/connected-page';
import { useGetManagementCompanyQuery } from 'generated/graphql';
import { convertPathToTranslationKey } from 'technical/convert-path-to-translation-key';
import { getPreviousUrlPath } from 'technical/get-previous-url-path';

export const SettingsPage = ({ children }: PropsWithChildren) => {
  const { advisorId } = useParams();
  const { user } = useAppContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { data: dataManagementCompany } = useGetManagementCompanyQuery({
    skip: !advisorId,
    variables: { id: advisorId ?? '' },
  });

  const title = t('routes.' + convertPathToTranslationKey(pathname), {
    advisorName: dataManagementCompany?.companyInfos.name,
  });

  //Also remove email-template, and template id to go back directly to template list and not to email creation
  const previousPath = getPreviousUrlPath(pathname)
    .replace(/\/email-template\/?$/, '')
    .replace(/\/template-list(\/[^/]*)?\/?$/, '/template-list');

  // Should not be possible
  if (!user?.managementCompany) {
    return <Navigate to={FundManagerRoutes.Home} />;
  }

  return (
    <ConnectedPage title={title} path={previousPath}>
      {children}
    </ConnectedPage>
  );
};
