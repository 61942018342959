import { useTranslation } from 'translations/hook';

import { useFund } from 'business/providers/fund-provider/use-fund';
import { CombinedArrowsIcon, LeftArrowIcon, RightArrowIcon } from 'ui/icons';
import { StatNumericAmountCard } from 'ui/stat-numeric-amount-card';
import { StatNumericAmountItemProps } from 'ui/stat-numeric-amount-item';

interface Props {
  data: {
    drawdown: number;
    distribution: number;
    recallableDistribution: number;
  };
  title: string;
  path?: string;
}

export const FinancialFlowCard = ({ data, title, path }: Props) => {
  const { t } = useTranslation();
  const { currency } = useFund();

  const financialFlowItems: StatNumericAmountItemProps[] = [
    {
      value: data.drawdown,
      currency,
      description: t('pages.dashboard.cards.financialFlows.drawdown'),
      imageSource: <RightArrowIcon />,
      iconColor: 'primary',
    },
    {
      value: data.distribution,
      currency,
      description: t('pages.dashboard.cards.financialFlows.distribution'),
      imageSource: <LeftArrowIcon />,
      iconColor: 'secondary',
    },
    {
      value: data.recallableDistribution,
      currency,
      description: t(
        'pages.dashboard.cards.financialFlows.recallableDistribution',
      ),
      imageSource: <CombinedArrowsIcon />,
      iconColor: 'neutral',
    },
  ];
  return (
    <StatNumericAmountCard
      path={path}
      title={title}
      items={financialFlowItems}
    />
  );
};
