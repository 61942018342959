import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { CompanyDocumentUploadForm } from 'business/fund-manager/portfolio/company/components/company-document-upload-form';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';

interface CompanyDocumentUploadFormCtaProps {
  companyId: string;
  onAdded: () => void;
}

export const CompanyDocumentUploadFormCta = ({
  companyId,
  onAdded,
}: CompanyDocumentUploadFormCtaProps) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const handleAdded = () => {
    handleCloseModal();
    onAdded();
  };

  return (
    <Stack>
      <ActionButton onClick={handleOpenModal} variant="submit">
        {t(
          'pages.fundManager.portfolio.company.documentation.uploadDocument.title',
        )}
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <CompanyDocumentUploadForm
            companyId={companyId}
            onCompleted={handleAdded}
            onClose={handleCloseModal}
          />
        }
      />
    </Stack>
  );
};
