import { addYears } from 'date-fns';

import { CurrencyEnum, FundFromDateEnum } from 'generated/graphql';
import { formatAsDate } from 'technical/date';

interface Period {
  start: string;
  end: string;
}

export interface FundProfilePeriods {
  investment: Period;
  liquidation: Period;
}

interface Params extends Partial<Record<FundFromDateEnum, string | Date>> {
  investmentPeriodFromDateOf: FundFromDateEnum;
  currency: CurrencyEnum;
  duration: number;
  termDelay: number;
}

export const getFundPeriods = ({
  investmentPeriodFromDateOf,
  duration,
  termDelay,
  ...rest
}: Params): FundProfilePeriods | undefined => {
  const investmentStarting = rest?.[investmentPeriodFromDateOf];
  if (!investmentStarting) {
    return undefined;
  }
  const investmentStartingDate = new Date(investmentStarting);
  const investmentClosingDate = addYears(investmentStartingDate, duration);

  const liquidationClosingDate = addYears(investmentClosingDate, termDelay);

  return {
    investment: {
      start: formatAsDate(investmentStartingDate),
      end: formatAsDate(investmentClosingDate),
    },
    liquidation: {
      start: formatAsDate(investmentClosingDate),
      end: formatAsDate(liquidationClosingDate),
    },
  };
};
