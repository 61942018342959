import { TFunction } from 'translations/hook';
import * as yup from 'yup';

import { zipCodeValidationSchema } from 'business/shared/services/validation';
import {
  requiredStringWhenFieldEquals,
  stringValidationSchema,
} from 'business/user/validations/string';
import { ClientTypeEnum } from 'generated/graphql';
import { nonEmptyStringSchema } from 'technical/validation';

const clientValidation = yup
  .mixed<ClientTypeEnum>()
  .oneOf(Object.values(ClientTypeEnum))
  .required();

export const validateLpAddress = (t: TFunction) =>
  yup.object().shape(
    {
      streetLine: nonEmptyStringSchema,
      streetLine2: yup.string(),
      zipCode: zipCodeValidationSchema.required(t('errors.required')),
      city: stringValidationSchema(
        {
          minValue: 2,
          maxValue: 60,
          fieldName: 'city',
          required: true,
        },
        t,
      ),
    },
    [['city', 'city']],
  );

export const validateLp = (t: TFunction) =>
  yup.object().shape(
    {
      client: clientValidation,
      country: stringValidationSchema(
        {
          minValue: 1,
          maxValue: 60,
          fieldName: 'country',
          required: true,
        },
        t,
      ),
      uniqueCompanyIdentifier: requiredStringWhenFieldEquals(
        {
          dependingFieldName: 'client',
          expectedValue: ClientTypeEnum.LegalEntity,
        },
        {
          minValue: 1,
          maxValue: 100,
          fieldName: 'uniqueCompanyIdentifier',
          required: false,
        },
        t,
      ),
      registrationLocation: requiredStringWhenFieldEquals(
        {
          dependingFieldName: 'client',
          expectedValue: ClientTypeEnum.LegalEntity,
        },
        {
          minValue: 1,
          maxValue: 100,
          fieldName: 'registrationLocation',
          required: false,
        },
        t,
      ),
      managementCompanyId: nonEmptyStringSchema,
      subscribingEntityName: stringValidationSchema(
        {
          minValue: 1,
          maxValue: 150,
          fieldName: 'subscribingEntityName',
          required: true,
        },
        t,
      ),
      address: validateLpAddress(t),
    },
    [
      ['subscribingEntityName', 'subscribingEntityName'],
      ['registrationLocation', 'registrationLocation'],
      ['taxPayerIdentificationNumber', 'taxPayerIdentificationNumber'],
      ['siren', 'siren'],
      ['country', 'country'],
      ['client', 'client'],
    ],
  );

export const validateLpUpdateForm = (t: TFunction) =>
  yup.object().shape({
    generalInformation: validateLp(t),
  });

export const amlFormSchema = yup.object({
  interactionsWithInvestors: yup.string(),
  lpTransparency: yup.string(),
  lpNature: yup.string(),
  structureTransparency: yup.string(),
});

export const declineConfirmationSchema = yup.object({
  comment: nonEmptyStringSchema,
});
