import { useTranslation } from 'translations/hook';

import { LegalEntityKycRole } from 'generated/graphql';
import { FormInputDropdown } from 'ui/form/form-input-dropdown';
import { Option } from 'ui/types';

export const SelectContactRoles = () => {
  const { t } = useTranslation();

  const options: Option[] = Object.values(LegalEntityKycRole)
    .map((role) => ({
      id: role,
      value: t('components.lpContactRole', { context: role }),
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

  return (
    <FormInputDropdown
      name="roles"
      label={t('components.lpContactRole.label')}
      options={options}
      multiple
      chip
    />
  );
};
