import { Stack, Tab, Tabs } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { getEmailingPreferencesColumnDefinition } from 'business/fund-manager/lp/services/get-emailing-preferences-column-definition';
import { getEmailingPreferencesCountByKeys } from 'business/fund-manager/lp/services/get-emailing-preferences-count-by-keys';
import { UpdateLpContact } from 'business/fund-manager/lp/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpContactManager } from 'business/shared/components/lp-contact-manager';
import { useLpContacts } from 'business/shared/lp-creation-module/services/hooks/use-lp-contacts';
import { ContactWithId } from 'business/shared/services/contact/types';
import {
  Web_Portal_ManagementCompanyType_Enum,
  useUpdateLpContactMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SimpleTable } from 'ui/simple-table/simple-table';

import styles from './index.module.scss';

export const Contacts = () => {
  const { lpId = '' } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [tab, setTab] = useState('contacts');

  const { loading, error, contacts, refetch } = useLpContacts({
    lpId,
  });
  const [updateLpContact] = useUpdateLpContactMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      refetch();
    },
  });

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const emailingPreferencesCountByKeys = contacts
    ? getEmailingPreferencesCountByKeys(contacts)
    : {
        isLegalRepresentative: 0,
        hasOperationalRights: 0,
        hasLpPortalAccess: 0,
        canAccessGeneralReports: 0,
        canAttendInvestorsMeeting: 0,
        canAccessESGReports: 0,
        canAccessAmpereReports: 0,
        canAccessCapitalAccountStatement: 0,
      };

  const handleEmailingPreferenceUpdate = async (
    input: UpdateLpContact,
  ): Promise<void> => {
    if (isAdvisor) {
      return;
    }
    await updateLpContact({
      variables: {
        input,
      },
    });
  };

  const headers: ColDef<ContactWithId>[] =
    getEmailingPreferencesColumnDefinition(
      t,
      handleEmailingPreferenceUpdate,
      emailingPreferencesCountByKeys,
      lpId,
    );

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        className={styles.tabs}
      >
        <Tab
          value="contacts"
          label={t('pages.fundManager.lps.contacts.tab.contacts')}
        />
        <Tab
          value="emailingPreferences"
          label={t('pages.fundManager.lps.contacts.tab.emailingPreferences')}
        />
      </Tabs>
      {tab === 'contacts' ? (
        <Stack spacing={2}>
          <LpContactManager
            lpId={lpId}
            contacts={contacts}
            onAdded={refetch}
            onDeleted={refetch}
          />
        </Stack>
      ) : null}
      {tab === 'emailingPreferences' ? (
        <Stack spacing={2}>
          <SimpleTable<ContactWithId>
            columnDefs={headers}
            rowData={contacts ?? []}
            loading={loading}
          />
        </Stack>
      ) : null}
    </>
  );
};
