import { useParams } from 'react-router-dom';

import { FundShareValuationCreationPage } from 'business/fund-manager/fund/pages/fund-share-valuation-creation/fund-share-valuation-creation';
import { useFundShareValuationToEditQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundShareValuationEdition = () => {
  const { fundId = '', shareValuationId = '' } = useParams();

  if (!fundId || !shareValuationId) {
    throw new Error('Missing parameters in the URL');
  }

  const { loading, error, data } = useFundShareValuationToEditQuery({
    variables: {
      id: shareValuationId,
    },
    // Each time we reopen the valuation edition page, it should refetch the latest edition data. The cache has almost no benefit here and just introduces the need to manually refetch when submitting the edition form.
    fetchPolicy: 'network-only',
  });

  const fundShareValuation = data?.fundShareValuationToEdit;

  const share = fundShareValuation?.share;

  const formGridShare = share
    ? {
        share: {
          ...share,
          lastFundShareValuation: fundShareValuation.previousFundShareValuation,
        },
        id: share.id,
        label: share.name,
      }
    : undefined;

  if (loading || error || !formGridShare) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const formGridShares = [formGridShare];

  return (
    <FundShareValuationCreationPage
      fundId={fundId}
      formGridShares={formGridShares}
      fundShareValuation={fundShareValuation}
    />
  );
};
