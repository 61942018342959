import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FundBankDetails } from 'business/fund-manager/fund/services/types';
import { AddressFormPart } from 'business/shared/components/address-form-part';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText, FormSection } from 'ui/form';

type FundBankFormProps = {
  control: Control<FundBankDetails>;
  error?: Error;
  action?: ReactNode;
};

export const FundBankForm = ({ action, control, error }: FundBankFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      {error?.message ? <ErrorLabel label={error.message} /> : null}

      <FormSection
        title={t('pages.fundManager.fundCreation.form.bankDetails.title')}
        action={action}
      >
        <FormInputText
          label={t('pages.fundManager.fundCreation.form.bankDetails.name')}
          name="name"
          control={control}
        />
        <Stack direction="row" spacing={1}>
          <FormInputText
            label={t('pages.fundManager.fundCreation.form.bankDetails.iban')}
            name="iban"
            control={control}
          />
          <FormInputText
            label={t('pages.fundManager.fundCreation.form.bankDetails.bic')}
            name="bic"
            control={control}
          />
        </Stack>
      </FormSection>

      <FormSection
        title={t(
          'pages.fundManager.fundCreation.form.bankDetails.depositary.title',
        )}
        lastOne
      >
        <FormInputText
          label={t(
            'pages.fundManager.fundCreation.form.bankDetails.depositary.name',
          )}
          name="address.name"
          control={control}
        />

        <AddressFormPart control={control} name="address" />

        <FormInputText
          label={t(
            'pages.fundManager.fundCreation.form.bankDetails.contact.label',
          )}
          multiline
          name="contact"
          placeholder={t(
            'pages.fundManager.fundCreation.form.bankDetails.contact.placeholder',
          )}
          control={control}
        />
      </FormSection>
    </>
  );
};
