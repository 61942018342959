import { Stack } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { LpAmlRiskForm } from 'business/fund-manager/lp/services/types';
import { RiskAssessmentListQuery } from 'generated/graphql';
import { FormSection, SelectInput } from 'ui/form';

type RiskAssessmentFormProps = {
  data: RiskAssessmentListQuery;
  control: Control<LpAmlRiskForm>;
};

export const RiskAssessmentForm = ({
  data,
  control,
}: RiskAssessmentFormProps) => {
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('pages.fundManager.lpCreation.aml.form.title')}
      lastOne
    >
      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          <SelectInput
            name="lpNature"
            control={control}
            label={t(
              'pages.fundManager.lpCreation.aml.form.lpNature.fieldName',
            )}
            fullWidth
            options={data?.lpNature.map(
              (value: RiskAssessmentListQuery['lpNature'][number]) => {
                return {
                  id: value.id,
                  value: t(
                    `pages.fundManager.lpCreation.aml.form.lpNature.selectOptions.${value.fieldName}`,
                  ),
                };
              },
            )}
            disableClearable
          />
          <SelectInput
            name="interactionsWithInvestors"
            control={control}
            label={t(
              'pages.fundManager.lpCreation.aml.form.interactionsWithInvestors.fieldName',
            )}
            fullWidth
            options={data?.interactionsWithInvestors.map(
              (
                value: RiskAssessmentListQuery['interactionsWithInvestors'][number],
              ) => {
                return {
                  id: value.id,
                  value: t(
                    `pages.fundManager.lpCreation.aml.form.interactionsWithInvestors.selectOptions.${value.fieldName}`,
                  ),
                };
              },
            )}
            disableClearable
          />
        </Stack>
        <Stack spacing={2} direction="row">
          <SelectInput
            name="structureTransparency"
            control={control}
            label={t(
              'pages.fundManager.lpCreation.aml.form.structureTransparency.fieldName',
            )}
            fullWidth
            options={data?.structureTransparency.map(
              (
                value: RiskAssessmentListQuery['structureTransparency'][number],
              ) => {
                return {
                  id: value.id,
                  value: t(
                    `pages.fundManager.lpCreation.aml.form.structureTransparency.selectOptions.${value.fieldName}`,
                  ),
                };
              },
            )}
            disableClearable
          />
          <SelectInput
            name="lpTransparency"
            control={control}
            label={t(
              'pages.fundManager.lpCreation.aml.form.lpTransparency.fieldName',
            )}
            fullWidth
            options={data?.lpTransparency.map(
              (value: RiskAssessmentListQuery['lpTransparency'][number]) => {
                return {
                  id: value.id,
                  value: t(
                    `pages.fundManager.lpCreation.aml.form.lpTransparency.selectOptions.${value.fieldName}`,
                  ),
                };
              },
            )}
            disableClearable
          />
        </Stack>
      </Stack>
    </FormSection>
  );
};
