import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { AutocompleteDifferential } from 'business/fund-manager/lp/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { EmptyTableView } from 'ui/empty-table-view';
import { FormSection } from 'ui/form';
import { CheckmarkRoundedIcon } from 'ui/icons';

type DifferencesTableProps = {
  rows: AutocompleteDifferential[];
  type: 'kyb' | 'ubo';
  loading: boolean;
  validateAction: () => void;
  declineAction: () => void;
};

export function LpDataComparisonTable({
  rows = [],
  type,
  loading,
}: // TODO PE3-2265: restore the following props when the functions are implemented
// validateAction,
// declineAction,
DifferencesTableProps) {
  const { t } = useTranslation();
  const palette = usePalette();

  // TODO PE3-2265: remove next 7 lines when the feature is implemented (log for linting)
  const [rowsToShow, setRowsToShow] = useState(rows);
  const TODOPE32026deleteMe = (cellData: ICellRendererParams['data']) => {
    setRowsToShow(rowsToShow.filter((row) => row.field !== cellData.field));
  };
  const TODOPE32026deleteMeAll = () => {
    setRowsToShow([]);
  };

  if (!rowsToShow.length || !rows.length) {
    return (
      <FormSection
        title={t(
          `pages.fundManager.lpCreation.form.kycKybAutocomplete.${type}Table.title`,
        )}
      >
        <EmptyTableView
          text={{
            main: t(
              `pages.fundManager.lpCreation.form.kycKybAutocomplete.${type}Table.noUpdate`,
            ),
            mainColor: palette.secondaryLight,
          }}
          icon={<CheckmarkRoundedIcon primaryColor={palette.secondaryLight} />}
        />
      </FormSection>
    );
  }
  const headers: ColDef[] = [
    {
      field: 'field',
      headerName: t(
        'pages.fundManager.lpCreation.form.kycKybAutocomplete.headers.field',
      ),
    },
    {
      field: 'oldValue',
      headerName: t(
        'pages.fundManager.lpCreation.form.kycKybAutocomplete.headers.oldValue',
      ),
    },
    {
      field: 'newValue',
      headerName: t(
        'pages.fundManager.lpCreation.form.kycKybAutocomplete.headers.newValue',
      ),
    },
    {
      headerName: t('common.tableHeaders.action'),
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({ data }: ICellRendererParams) => (
        <Stack direction="row" spacing={1}>
          <ActionButton
            onClick={() => TODOPE32026deleteMe(data)}
            variant="primary"
            size="small"
            notAllCaps={true}
          >
            {t('common.actions.update')}
          </ActionButton>
          <ActionButton
            onClick={() => TODOPE32026deleteMe(data)}
            variant="danger"
            size="small"
            notAllCaps={true}
          >
            {t('common.actions.reject')}
          </ActionButton>
        </Stack>
      ),
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  ];

  return (
    <FormSection
      title={t(
        `pages.fundManager.lpCreation.form.kycKybAutocomplete.${type}Table.title`,
      )}
      action={
        <Stack direction="row" spacing={2}>
          <ActionButton variant="primary" onClick={TODOPE32026deleteMeAll}>
            {t('common.actions.updateAll')}
          </ActionButton>
          <ActionButton variant="danger" onClick={TODOPE32026deleteMeAll}>
            {t('common.actions.rejectAll')}
          </ActionButton>
        </Stack>
      }
    >
      <AlternativeTable
        fileName={`${type}Differences`}
        rowData={rowsToShow}
        columnDefs={headers}
        loading={loading}
        domLayout="autoHeight"
        hideSearchbar
        enableCharts={false}
        enableRowGroup={false}
        enablePivot={false}
      />
    </FormSection>
  );
}
