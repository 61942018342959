import { Box, Divider, Typography } from '@mui/material';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

interface Props {
  title: string;
}

const TableHeader = ({ title }: Props) => {
  const palette = usePalette();
  return (
    <>
      <Box sx={{ bgcolor: palette.backgroundForm }} className={styles.title}>
        <Typography variant="body2">{title}</Typography>
      </Box>
      <Divider light />
    </>
  );
};

export default TableHeader;
