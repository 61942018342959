import { TableCell } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

type Props = PropsWithChildren<
  ComponentProps<typeof TableCell> & {
    sx?: any;
    hideBorderRight?: boolean;
  }
>;

export const TableCellGreyBorder = ({
  children,
  sx,
  hideBorderRight,
  ...rest
}: Props) => {
  const palette = usePalette();

  return (
    <TableCell
      sx={{
        borderRight: hideBorderRight
          ? '0px'
          : `1px solid ${palette.backgroundCard}`,
        borderBottom: `1px solid ${palette.backgroundCard}`,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </TableCell>
  );
};
