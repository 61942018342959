interface Params {
  options: {
    id: string;
    value: string;
  }[];
  shareFilter?: string;
  setShareFilter: (shareId: string) => void;
}
export const setDefaultShareFilter = ({
  options,
  shareFilter,
  setShareFilter,
}: Params) => {
  if (!shareFilter && options.length) {
    const defaultShareId = options[0].id;
    setShareFilter(defaultShareId);
  }
};
