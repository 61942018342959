import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'translations/hook';

import { OpFieldNames } from 'business/fund-manager/operation/services/types';

import { GridCell } from './grid-table/grid-cell';
import { GridRow } from './grid-table/grid-row';
import { TableGrid } from './grid-table/grid-table';
import {
  ExpandButtonCol,
  GroupTitle,
  ImpactedCommitmentsInput,
  TotalCallPerShareField,
  TotalDrawnField,
  TotalDrawnAllShareTypesField,
  ShareTitleCell,
  TotalTitleCell,
  FinalDrawnField,
  TotalDistribField,
  TotalRowField,
  TotalDistribPerShareField,
  TotalRowPerShareField,
  TotalDistribAllShareTypesField,
  TotalRowAllShareTypesField,
  FundInput,
  ShareAmountInput,
  PerShareInput,
  AmountSumField,
  TotalInputTitleCell,
  TotalDrawnFromInputsField,
  TotalDistribFromInputsField,
  TotalAllFrominputsField,
  RecalcColumnsOnCommitmentChange,
} from './op-shares-components';
import { SharesTableProvider, OpCreationShareInput } from './op-shares-utils';
import { OperationShareDataProvider } from './use-fetch-share-commitments';

interface Props {
  shares: OpCreationShareInput[];
}

function useToggle(defaultValue = false) {
  const [enabled, setEnabled] = useState(defaultValue);
  const toggle = useCallback(() => {
    setEnabled((exp) => !exp);
  }, []);
  return [enabled, toggle] as const;
}

interface CommitmentForSummary {
  commitment: number;
  nominalValue: number;
  numberOfShares: number;
}
export type CommitmentsForSummary = Record<string, CommitmentForSummary>;
export type SetCommitments = Dispatch<SetStateAction<CommitmentsForSummary>>;

export const OpSharesConfigTable: FC<Props> = ({ shares }) => {
  const { t } = useTranslation();
  const [callExpanded, toggleCallExpand] = useToggle(true);
  const [distribExpanded, toggleDistribExpand] = useToggle(true);

  const [commitments, setCommitments] = useState<CommitmentsForSummary>({});

  return (
    <SharesTableProvider shares={shares} commitments={commitments}>
      <RecalcColumnsOnCommitmentChange />
      <TableGrid className="overflow-x-auto pb-4">
        {/* Row: expand/collapse col groups */}
        <GridRow>
          <ExpandButtonCol
            open={callExpanded}
            toggle={toggleCallExpand}
            className={'col-start-4'}
            closedTooltip="pages.fundManager.operationShareSelection.openGroupCall"
            openTooltip="pages.fundManager.operationShareSelection.closeGroupCall"
          />
          <ExpandButtonCol
            open={distribExpanded}
            toggle={toggleDistribExpand}
            className="col-start-8"
            closedTooltip="pages.fundManager.operationShareSelection.openGroupDistrib"
            openTooltip="pages.fundManager.operationShareSelection.closeGroupDistrib"
          />
        </GridRow>

        {/* Row: call / distrib labels */}
        <GridRow>
          <GroupTitle className="bg-neutral-100 col-start-3">
            {t(
              'pages.fundManager.operationShareSelection.operationTotal.title',
            )}
          </GroupTitle>
          <GroupTitle span={4} className="bg-neutral-200">
            {t('pages.fundManager.operationShareSelection.drawn.title')}
          </GroupTitle>
          <GroupTitle span={5} className="bg-neutral-100">
            {t(
              'pages.fundManager.operationShareSelection.distributionNonRecallable.title',
            )}
          </GroupTitle>
          <GroupTitle className="bg-neutral-200">
            {t(
              'pages.fundManager.operationShareSelection.distributionRecallable.title',
            )}
          </GroupTitle>
        </GridRow>

        {/* Row: columns titles */}
        <GridRow>
          <GridCell className="col-start-4">
            {t('pages.fundManager.operationShareSelection.drawn.totalDrawn')}
          </GridCell>
          <GridCell colOpen={callExpanded}>
            {t('pages.fundManager.operationShareSelection.drawn.investment')}
          </GridCell>
          <GridCell colOpen={callExpanded}>
            {t('pages.fundManager.operationShareSelection.drawn.fees')}
          </GridCell>
          <GridCell colOpen={callExpanded}>
            {t('pages.fundManager.operationShareSelection.drawn.other')}
          </GridCell>
          <GridCell>
            {t(
              'pages.fundManager.operationShareSelection.distributionNonRecallable.totalDistributed',
            )}
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            {t(
              'pages.fundManager.operationShareSelection.distributionNonRecallable.returnOfCost',
            )}
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            {t(
              'pages.fundManager.operationShareSelection.distributionNonRecallable.capitalGain',
            )}
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            {t(
              'pages.fundManager.operationShareSelection.distributionNonRecallable.interest',
            )}
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            {t(
              'pages.fundManager.operationShareSelection.distributionNonRecallable.dividend',
            )}
          </GridCell>
          <GridCell>
            {t(
              'pages.fundManager.operationShareSelection.distributionRecallable.totalCurrentDistributed',
            )}
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell colSpan={2} className="justify-end" noWidth>
            <TotalInputTitleCell />
          </GridCell>
          <GridCell>
            <TotalAllFrominputsField />
          </GridCell>
          <GridCell>
            <TotalDrawnFromInputsField />
          </GridCell>
          <GridCell colOpen={callExpanded}>
            <FundInput name={OpFieldNames.drawn} autoFocus />
          </GridCell>
          <GridCell colOpen={callExpanded}>
            <FundInput name={OpFieldNames.managementFees} />
          </GridCell>
          <GridCell colOpen={callExpanded}>
            <FundInput name={OpFieldNames.otherFees} />
          </GridCell>
          <GridCell>
            <TotalDistribFromInputsField />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <FundInput name={OpFieldNames.returnOfCost} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <FundInput name={OpFieldNames.capitalGain} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <FundInput name={OpFieldNames.interest} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <FundInput name={OpFieldNames.dividend} />
          </GridCell>
          <GridCell>
            <FundInput name={OpFieldNames.currentDistributed} />
          </GridCell>
        </GridRow>

        {/* Rows: share fields */}
        {shares.map((share) => (
          <Fragment key={share.id}>
            <OperationShareDataProvider
              share={share}
              shares={shares}
              setCommitments={setCommitments}
            >
              {/* Row: share type level */}
              <GridRow>
                <GridCell
                  className="flex-col items-stretch justify-center"
                  noWidth
                >
                  <ShareTitleCell share={share} />
                </GridCell>
                <GridCell noWidth className="w-44">
                  <ImpactedCommitmentsInput />
                </GridCell>
                <GridCell>
                  <TotalRowField shareId={share.id} />
                </GridCell>
                <GridCell>
                  <TotalDrawnField shareId={share.id} />
                </GridCell>
                <GridCell colOpen={callExpanded}>
                  <ShareAmountInput name={OpFieldNames.drawn} />
                </GridCell>
                <GridCell colOpen={callExpanded}>
                  <ShareAmountInput name={OpFieldNames.managementFees} />
                </GridCell>
                <GridCell colOpen={callExpanded}>
                  <ShareAmountInput name={OpFieldNames.otherFees} />
                </GridCell>
                <GridCell>
                  <TotalDistribField shareId={share.id} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <ShareAmountInput name={OpFieldNames.returnOfCost} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <ShareAmountInput name={OpFieldNames.capitalGain} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <ShareAmountInput name={OpFieldNames.interest} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <ShareAmountInput name={OpFieldNames.dividend} />
                </GridCell>
                <GridCell>
                  <ShareAmountInput name={OpFieldNames.currentDistributed} />
                </GridCell>
              </GridRow>

              {/* Row: unit share level */}
              <GridRow>
                <GridCell colSpan={2} className="pr-5 justify-end" noWidth>
                  <div>
                    {t(
                      'pages.fundManager.operationShareSelection.atShareLevel',
                    )}
                  </div>
                </GridCell>
                <GridCell>
                  <TotalRowPerShareField shareId={share.id} />
                </GridCell>
                <GridCell>
                  <TotalCallPerShareField shareId={share.id} />
                </GridCell>
                <GridCell colOpen={callExpanded}>
                  <PerShareInput name={OpFieldNames.drawn} />
                </GridCell>
                <GridCell colOpen={callExpanded}>
                  <PerShareInput name={OpFieldNames.managementFees} />
                </GridCell>
                <GridCell colOpen={callExpanded}>
                  <PerShareInput name={OpFieldNames.otherFees} />
                </GridCell>
                <GridCell>
                  <TotalDistribPerShareField shareId={share.id} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <PerShareInput name={OpFieldNames.returnOfCost} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <PerShareInput name={OpFieldNames.capitalGain} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <PerShareInput name={OpFieldNames.interest} />
                </GridCell>
                <GridCell colOpen={distribExpanded}>
                  <PerShareInput name={OpFieldNames.dividend} />
                </GridCell>
                <GridCell>
                  <PerShareInput name={OpFieldNames.currentDistributed} />
                </GridCell>
              </GridRow>
            </OperationShareDataProvider>
          </Fragment>
        ))}

        {/* Sums at fund level */}
        <GridRow>
          <GridCell colSpan={2} className="justify-end" noWidth>
            <TotalTitleCell />
          </GridCell>
          <GridCell>
            <TotalRowAllShareTypesField shares={shares} />
          </GridCell>
          <GridCell>
            <TotalDrawnAllShareTypesField shares={shares} />
          </GridCell>
          <GridCell colOpen={callExpanded}>
            <AmountSumField name={OpFieldNames.drawn} />
          </GridCell>
          <GridCell colOpen={callExpanded}>
            <AmountSumField name={OpFieldNames.managementFees} />
          </GridCell>
          <GridCell colOpen={callExpanded}>
            <AmountSumField name={OpFieldNames.otherFees} />
          </GridCell>
          <GridCell>
            <TotalDistribAllShareTypesField shares={shares} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <AmountSumField name={OpFieldNames.returnOfCost} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <AmountSumField name={OpFieldNames.capitalGain} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <AmountSumField name={OpFieldNames.interest} />
          </GridCell>
          <GridCell colOpen={distribExpanded}>
            <AmountSumField name={OpFieldNames.dividend} />
          </GridCell>
          <GridCell>
            <AmountSumField name={OpFieldNames.currentDistributed} />
          </GridCell>
        </GridRow>
      </TableGrid>
      <div className="text-xl font-bold">
        <FinalDrawnField />
      </div>
    </SharesTableProvider>
  );
};
