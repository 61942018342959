import { Stack } from '@mui/material';
import classNames from 'classnames';

import styles from './index.module.scss';

interface Props {
  src: string;
  className?: string;
}

export function ImageFit({ src, className }: Props) {
  return (
    <Stack className={classNames(styles.stack, className ?? '')}>
      <img src={src} />
    </Stack>
  );
}
