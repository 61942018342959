import { MenuItem, Select, Stack, Typography } from '@mui/material';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ErrorLabel } from 'ui/error-label';

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  options: string[];
  error?: boolean;
  dataTestId?: string;
  disabled: boolean;
  required?: boolean;
  placeholder: string;
  helperText?: string;
  onChange: (e: any) => void;
}
export function DropDown({
  id,
  name,
  label,
  value,
  options,
  error,
  dataTestId,
  disabled,
  required,
  placeholder,
  helperText,
  onChange: handleChange,
}: Props) {
  const palette = usePalette();

  const renderedLabel = () => (
    <Typography sx={{ color: palette.backgroundCard }}>{`${label} ${
      required ? '*' : ''
    }`}</Typography>
  );

  return (
    <Stack>
      <Select
        id={id}
        name={name}
        value={value}
        data-test-id={dataTestId}
        disabled={disabled}
        onChange={handleChange}
        displayEmpty
        renderValue={value !== '' ? undefined : renderedLabel}
        error={error}
        required={required}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {helperText ? <ErrorLabel label={helperText} /> : null}
    </Stack>
  );
}
