import { Locale, formatDistanceToNow } from 'date-fns';
import { TFunction } from 'translations/hook';

import {
  OnboardingSubscriptionDocumentStatusEnum,
  OnboardingSubscriptionsQuery,
} from 'generated/graphql';

export type FormattedOnboardingSubscription = {
  id: string;
  fundName: string;
  subscribingEntityName: string;
  daysSinceLastInvite: string;
  status: string;
  statusLabel: string;
  signedDocumentId?: string;
  type: string;
  numericAmount: number;
  numericAmountMax: number;
  numericAmountMin: number;
  email?: string;
};

export const formatOnboardingSubscription = ({
  data,
  t,
  locale,
}: {
  data: OnboardingSubscriptionsQuery['requested'][number];
  t: TFunction;
  locale: Locale;
}): FormattedOnboardingSubscription => {
  const signedDocument = data.onboardingSubscriptionDocument.find(
    (document) =>
      document.status === OnboardingSubscriptionDocumentStatusEnum.SignedByAll,
  );

  return {
    id: data.id,
    fundName: data.share.fund.name,
    subscribingEntityName: data.lpView.lpName,
    daysSinceLastInvite: formatDistanceToNow(new Date(data.date), {
      locale,
    }),
    status: data.status,
    statusLabel: t(`pages.onboardingSubscription.status.${data.status}`),
    signedDocumentId: signedDocument?.documentId,
    type: t(`pages.onboardingSubscription.type.${data.type}`),
    numericAmount: data.numericAmount,
    numericAmountMin: data.numericAmountMin,
    numericAmountMax: data.numericAmountMax,
    email: data.lp?.legalRepresentative?.email,
  };
};
