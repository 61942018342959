import { Reference } from '@apollo/client';
import gql from 'graphql-tag';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useSetLpCustomColumnValueMutation } from 'generated/graphql';

export const useSetLpCustomColumnValue = () => {
  const { t } = useTranslation();

  const [setLpCustomColumnValue] = useSetLpCustomColumnValueMutation({
    optimisticResponse: ({ input: { lpId, customColumnId, value } }) => ({
      setLpCustomColumnValue: {
        id: `temp-id:${lpId}-${customColumnId}`,
        lpId,
        customColumnId,
        value,
        __typename: 'CustomColumnValue',
      },
    }),
    update: (cache, { data: response }) => {
      if (!response) {
        return;
      }
      cache.modify({
        id: cache.identify({
          id: response.setLpCustomColumnValue.lpId,
          __typename: 'LpWithComputedDetails',
        }),
        fields: {
          customData(currentCustomData: Reference[], { readField }) {
            const otherCustomData = currentCustomData.filter(
              (ref) =>
                readField('customColumnId', ref) !==
                response.setLpCustomColumnValue.customColumnId,
            );

            const newValue = cache.writeFragment({
              data: response.setLpCustomColumnValue,
              fragment: gql`
                fragment NewCustomColumnValue on CustomColumnValue {
                  id
                  lpId
                  customColumnId
                  value
                }
              `,
            });

            return [...otherCustomData, newValue];
          },
        },
      });
    },
    onError: (error) =>
      enqueueSnackbar(t(error.message), {
        variant: VariantTypeEnum.ERROR,
      }),
  });

  return setLpCustomColumnValue;
};
