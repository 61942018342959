import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { computeOperationStats } from 'business/fund-manager/shared/services/compute-operation-values';
import { useGetLpOperationSharesQuery } from 'generated/graphql';
import { FinancialFlowCard } from 'ui/financial-flow-card';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props {
  lpId: string;
}

export const LpFinancialFlowCard = ({ lpId }: Props) => {
  const { t } = useTranslation();
  const financialFlowPath = `${FundManagerRoutes.LpIdFinancialFlow.replace(
    ':lpId',
    lpId,
  )}`;

  const { data, error, loading } = useGetLpOperationSharesQuery({
    variables: {
      id: lpId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || data === undefined) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const computedData = data.web_portal_operationShareLp_aggregate.aggregate?.sum
    ? computeOperationStats({
        ...data.web_portal_operationShareLp_aggregate.aggregate?.sum,
      })
    : { drawdown: 0, distribution: 0, recallableDistribution: 0 };

  return (
    <FinancialFlowCard
      data={computedData}
      title={t('pages.dashboard.cards.financialFlows.title')}
      path={financialFlowPath}
    />
  );
};
