import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyInvestment } from 'business/fund-manager/portfolio/company/services/types';
import { CurrencyEnum } from 'generated/graphql';
import {
  Web_Portal_CompanyDealSourcingEnum_Enum,
  Web_Portal_CompanyDealTypeEnum_Enum,
  Web_Portal_CompanyFundStakeEnum_Enum,
  Web_Portal_CompanyListedOnStockExchangeEnum_Enum,
  Web_Portal_CompanyOperationTypeEnum_Enum,
} from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import { FormSection, FormToggleButton, FormInputText } from 'ui/form';

interface FormToggleEnumProps<T extends Record<string, string>> {
  label: string;
  name: string;
  optEnum: T;
  control: Control<CompanyInvestment>;
  valueAsLabel?: boolean;
}

const FormToggleEnum = <
  T extends Record<string, string> = Record<string, string>,
>({
  label,
  name,
  control,
  optEnum,
  valueAsLabel,
}: FormToggleEnumProps<T>) => {
  const { t } = useTranslation();
  return (
    <FormToggleButton
      allowEmpty
      required
      control={control as any}
      fullWidth
      label={t(label)}
      name={name}
      options={
        // TODO PE3-462 currency will be handled in another task
        Object.values(optEnum).map((value) => ({
          value,
          label: valueAsLabel ? value : t(label, { context: value }),
        }))
      }
    />
  );
};

interface CompanyInvestmentFormProps {
  control: Control<CompanyInvestment>;
  error?: ApolloError;
  action?: ReactNode;
}

export const CompanyInvestementForm = ({
  control,
  error,
  action,
}: CompanyInvestmentFormProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      {error?.message ? <ErrorLabel label={error.message} /> : null}
      <FormSection
        title={t('pages.fundManager.portfolio.company.investments.profile')}
        action={action}
      >
        <Stack spacing={4}>
          <FormToggleEnum
            label="pages.fundManager.portfolio.company.investments.listedOnStockExchange"
            name="listedOnStockExchange"
            control={control}
            optEnum={Web_Portal_CompanyListedOnStockExchangeEnum_Enum}
          />

          <FormToggleEnum
            label="pages.fundManager.portfolio.company.investments.currency"
            name="currency"
            control={control}
            optEnum={CurrencyEnum}
            valueAsLabel
          />

          <Stack direction="row" spacing={6} justifyContent="stretch">
            <FormToggleEnum
              label="pages.fundManager.portfolio.company.investments.dealSourcing"
              name="dealSourcing"
              control={control}
              optEnum={Web_Portal_CompanyDealSourcingEnum_Enum}
            />

            <FormToggleEnum
              label="pages.fundManager.portfolio.company.investments.dealType"
              name="dealType"
              control={control}
              optEnum={Web_Portal_CompanyDealTypeEnum_Enum}
            />
          </Stack>

          <FormToggleEnum
            label="pages.fundManager.portfolio.company.investments.operationType"
            name="operationType"
            control={control}
            optEnum={Web_Portal_CompanyOperationTypeEnum_Enum}
          />

          <FormToggleEnum
            label="pages.fundManager.portfolio.company.investments.fundStake"
            name="fundStake"
            control={control}
            optEnum={Web_Portal_CompanyFundStakeEnum_Enum}
          />

          <FormInputText
            control={control}
            required
            name="fundOnwershipShareHolding"
            type="number"
            label={t(
              'pages.fundManager.portfolio.company.investments.fundOnwershipShareHolding',
            )}
          />
        </Stack>
      </FormSection>
    </Stack>
  );
};
