import { Outlet, RouteObject } from 'react-router';

import { FundDetailPage } from 'business/fund-manager/fund/components/fund-details-page';
import { FundDashboard } from 'business/fund-manager/fund/dashboard/pages';
import { FundFinancialFlowPage } from 'business/fund-manager/fund/pages/financial-flow';
import { FundCreation } from 'business/fund-manager/fund/pages/fund-creation';
import { FundCreationBankStep } from 'business/fund-manager/fund/pages/fund-creation-bank-step';
import { FundCreationGuard } from 'business/fund-manager/fund/pages/fund-creation-guard';
import { FundCreationShareStep } from 'business/fund-manager/fund/pages/fund-creation-share-step';
import { FundDiligence } from 'business/fund-manager/fund/pages/fund-diligence/fund-diligence';
import { FundDocumentList } from 'business/fund-manager/fund/pages/fund-document';
import { FundDraftEdition } from 'business/fund-manager/fund/pages/fund-draft-edition';
import { FundEdit } from 'business/fund-manager/fund/pages/fund-edit';
import { FundEditBank } from 'business/fund-manager/fund/pages/fund-edit-bank';
import { FundEditShare } from 'business/fund-manager/fund/pages/fund-edit-share';
import { FundList } from 'business/fund-manager/fund/pages/fund-list';
import { FundReportingCreation } from 'business/fund-manager/fund/pages/fund-reporting-creation/fund-reporting-creation';
import { FundShareValuationPage } from 'business/fund-manager/fund/pages/fund-share-valuation';
import { FundShareValuationCreation } from 'business/fund-manager/fund/pages/fund-share-valuation-creation/fund-share-valuation-creation';
import { FundShareValuationEdition } from 'business/fund-manager/fund/pages/fund-share-valuation-edition/fund-share-valuation-edition';
import { FundSubscriptionList } from 'business/fund-manager/fund/pages/fund-subscription-list';
import FundManagerRoutes from 'business/fund-manager/router/routes';

export const fundRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.Fund,
    element: <FundList />,
  },
  {
    path: FundManagerRoutes.FundId,
    element: (
      <FundDetailPage>
        <Outlet />
      </FundDetailPage>
    ),
    children: [
      {
        path: FundManagerRoutes.FundId,
        element: <FundDashboard />,
      },
      {
        path: FundManagerRoutes.FundIdEditProfile,
        element: <FundEdit />,
      },
      {
        path: FundManagerRoutes.FundIdEditShare,
        element: <FundEditShare />,
      },
      {
        path: FundManagerRoutes.FundIdEditBank,
        element: <FundEditBank />,
      },
      {
        path: FundManagerRoutes.FundIdFinancialFlow,
        element: <FundFinancialFlowPage />,
      },
      {
        path: FundManagerRoutes.FundIdDocument,
        element: <FundDocumentList />,
      },
      {
        path: FundManagerRoutes.FundIdShareValuation,
        element: <FundShareValuationPage />,
      },
      {
        path: FundManagerRoutes.FundIdShareValuationCreate,
        element: <FundShareValuationCreation />,
      },
      {
        path: FundManagerRoutes.FundIdShareValuationEdit,
        element: <FundShareValuationEdition />,
      },
      {
        path: FundManagerRoutes.FundIdSubscription,
        element: <FundSubscriptionList />,
      },
      {
        path: FundManagerRoutes.FundIdDiligence,
        element: <FundDiligence />,
      },
      {
        path: FundManagerRoutes.FundIdReportingCreate,
        element: <FundReportingCreation />,
      },
    ],
  },
  {
    path: FundManagerRoutes.FundCreate,
    element: (
      <FundCreationGuard>
        <Outlet />
      </FundCreationGuard>
    ),
    children: [
      {
        path: FundManagerRoutes.FundCreate,
        element: <FundCreation />,
      },
      {
        path: FundManagerRoutes.FundCreateId,
        element: <FundDraftEdition />,
      },
      {
        path: FundManagerRoutes.FundCreateIdBank,
        element: <FundCreationBankStep />,
      },
      {
        path: FundManagerRoutes.FundCreateIdShare,
        element: <FundCreationShareStep />,
      },
    ],
  },
];
