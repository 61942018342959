import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { CompanyProfile } from 'business/fund-manager/portfolio/company/services/types';
import {
  GetCompanyAutocompleteInformationQuery,
  useGetCompanyAutocompleteInformationLazyQuery,
} from 'generated/graphql';
import { isSiren } from 'technical/is-siren';

interface Props {
  setValue: UseFormSetValue<CompanyProfile>;
  control: Control<CompanyProfile>;
}

const keys: (keyof Omit<
  GetCompanyAutocompleteInformationQuery['companyAutocomplete']['content'],
  'address'
>)[] = ['name', 'identifier', 'codeNace', 'geography'];

const addressKeys: (keyof NonNullable<
  GetCompanyAutocompleteInformationQuery['companyAutocomplete']['content']['address']
>)[] = ['streetLine', 'streetLine2', 'zipCode', 'city', 'country'];

export const useCompanyInformationAutocomplete = ({
  setValue,
  control,
}: Props) => {
  const siren = useWatch({
    control,
    name: 'identifier',
  });

  const [getAutocompleteResult, { loading, error }] =
    useGetCompanyAutocompleteInformationLazyQuery();

  const fillFormData = (
    content: GetCompanyAutocompleteInformationQuery['companyAutocomplete']['content'],
  ) => {
    keys.forEach((key) => {
      setValue(key, content[key]);
    });

    addressKeys.forEach((key) => {
      setValue(`address.${key}`, content.address?.[key] ?? '');
    });
  };

  const fetchAndSetAutocompleteResult = async () => {
    if (!siren) {
      return;
    }

    const { data } = await getAutocompleteResult({
      variables: { input: { siren } },
    });

    if (!data?.companyAutocomplete) {
      return;
    }

    fillFormData(data?.companyAutocomplete.content);
  };

  return {
    fetchAndSetAutocompleteResult,
    disabled: !isSiren(siren),
    loading,
    error,
  };
};
