import { TFunction as reactI18NextTFunction, TOptions } from 'i18next';
import {
  // eslint-disable-next-line no-restricted-imports
  useTranslation as useReactI18NextTranslation,
} from 'react-i18next';

import logger from 'technical/logger';

function tFactory(originalTFunction: reactI18NextTFunction) {
  return function t(key: string | string[], options?: TOptions) {
    const result = originalTFunction(key, options);
    if (result === null) {
      logger.warn(`[Use Translation] Unknown key ${key}`);
      if (typeof key === 'string') {
        return key;
      }
      return key[0];
    }
    return result;
  };
}

export function useTranslation() {
  // can't use specific hooks option (like prefix) because our translation generator don't detect it
  // more details here: https://gitlab.matters.tech/pe3/pe3/-/merge_requests/349#note_470725
  const { t: reactI18NextT, i18n } = useReactI18NextTranslation();

  return {
    t: tFactory(reactI18NextT),
    i18n,
  };
}

export type TFunction = ReturnType<typeof tFactory>;
