import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { getDocumentColumnDefinition } from 'business/fund-manager/shared/services/get-documents-column-definition';
import {
  WebDocumentWithType,
  useLpRelatedWebDocumentsWithTypeQuery,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpDocumentList = () => {
  const { t } = useTranslation();

  const { lpId = '' } = useParams();

  const { data, error, loading } = useLpRelatedWebDocumentsWithTypeQuery({
    variables: {
      input: { lpId },
    },
  });

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const rowData = (data?.lpRelatedWebDocumentsWithType ?? []).filter(
    (document): document is WebDocumentWithType => !!document,
  );
  const headers = getDocumentColumnDefinition(t);

  return (
    <Stack spacing={2}>
      <AlternativeTable<WebDocumentWithType>
        rowData={rowData}
        fileName="lp-documents"
        columnDefs={headers}
        loading={loading}
        domLayout="autoHeight"
      />
    </Stack>
  );
};
