import { CurrencyExchange } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { useGetOperationGeneralInformationQuery } from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { MainSecondaryItem } from 'ui/main-secondary-item';
import { SummaryBar } from 'ui/summary-bar';

export const OverviewDetailsCard = ({
  operationId,
}: {
  operationId: string;
}) => {
  const { t } = useTranslation();

  const { data, loading } = useGetOperationGeneralInformationQuery({
    variables: {
      id: operationId,
    },
  });

  const items = [
    {
      main: t('pages.fundManager.operation.table.fund'),
      secondary: data?.web_portal_operation_by_pk?.fund?.name ?? '',
    },
    {
      main: t('pages.fundManager.operation.overview.cards.details.operation'),
      secondary: data?.web_portal_operation_by_pk?.identifier ?? '',
    },
    {
      main: t('pages.fundManager.operation.table.date'),
      secondary: data?.web_portal_operation_by_pk?.date
        ? formatAsDate(data.web_portal_operation_by_pk.date)
        : '',
    },
  ];
  return (
    <SummaryBar
      icon={<CurrencyExchange />}
      iconColor="primary"
      title={t('pages.fundManager.operation.overview.cards.details.title')}
      loading={loading}
    >
      <Grid container direction="row" spacing={2} alignItems="center">
        {items.map((item) => (
          <Grid item xs={3} key={`${item.main}`}>
            <MainSecondaryItem {...item} />
          </Grid>
        ))}
      </Grid>
    </SummaryBar>
  );
};
