import { checkIfPropertiesHaveValues } from 'technical/check-if-properties-have-values';

import { OperationSharesLp } from './types';

export function getOperationShareLpDisplayValues(
  operationSharesLp: OperationSharesLp[],
) {
  const displayInvestmentFeesAndOther = checkIfPropertiesHaveValues<
    OperationSharesLp,
    keyof OperationSharesLp
  >(operationSharesLp, ['investment', 'fees', 'other']);

  const displayReturnOfCostCapitalGainInterestorDividends =
    checkIfPropertiesHaveValues<OperationSharesLp, keyof OperationSharesLp>(
      operationSharesLp,
      ['returnOfCost', 'capitalGain', 'interest', 'dividend'],
    );

  const displayCurrentlyDistributed = checkIfPropertiesHaveValues<
    OperationSharesLp,
    keyof OperationSharesLp
  >(operationSharesLp, ['currentDistributed']);

  return {
    displayInvestmentFeesAndOther,
    displayReturnOfCostCapitalGainInterestorDividends,
    displayCurrentlyDistributed,
  };
}
