import { ClientTypeEnum, LpCreationStepEnum } from 'generated/graphql';

import { getDeclineReason } from './get-decline-reason';
import { GetCardItemsFn, LpValidationLegalEntity } from './types';

export const getInvestorProfileValidationCard: GetCardItemsFn<
  LpValidationLegalEntity
> = ({ data, t, declineReasons }) => {
  const step =
    data.client === ClientTypeEnum.Individual
      ? LpCreationStepEnum.IndividualInvestorProfile
      : LpCreationStepEnum.LegalEntityInvestorProfile;

  const status = data.investorProfileStatus;

  return {
    title: t('pages.fundManager.lpCreation.form.investorProfile.title'),
    type: 'single',
    entity: {
      items: [],
    },
    entityId: data.id,
    step,
    status: data.investorProfileStatus,
    declineReason: getDeclineReason({ status, step, declineReasons }),
  };
};
