export enum FilterOperator {
  CONTAINS = 'contains',
  EQUALS = 'equals',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
  IS_ANY_OF = 'isAnyOf',
  IS = 'is',
  NOT = 'not',
  IS_EQUAL = '=',
  IS_NOT_EQUAL = '!=',
  IS_GREATER = '>',
  IS_GREATER_OR_EQUALS = '>=',
  IS_LESS = '<',
  IS_LESS_OR_EQUAL = '<=',
  AFTER = 'after',
  BEFORE = 'before',
  ON_OR_AFTER = 'onOrAfter',
  ON_OR_BEFORE = 'onOrBefore',
}

export enum FilterField {
  DATE = 'date',
  FUNDNAME = 'fundName',
  SHARE_TYPE = 'shareType',
  IDENTIFIER = 'operationIdentifier',
  DRAWN = 'drawdown',
  DISTRUBUTED = 'distribution',
  DISTRUBUTED_RECALLABLE = 'recallableDistribution',
  STATUS = 'status',
}

export type GqlWhereClause = { where: any };

export type QueryParams = {
  [key: string]: unknown; // or any other type you want to use for the values
};

export interface QuickFilteringParameters {
  quickFilterValues: string[];
  fields: string[];
  setWhereClause: (clause: GqlWhereClause) => void;
  clauseBuilderFunction: (
    field: string,
    gqlOperator: QueryParams,
  ) => QueryParams;
}

export interface FuzzyFilteringParameters {
  quickFilterValues: string[];
  setSearchedTerm: (term: string) => void;
}
