import {
  Chip,
  List,
  ListItem,
  Divider,
  Stack,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'translations/hook';

import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import {
  ContactSanctionsQuery,
  useContactSanctionsQuery,
} from 'generated/graphql';
import { formatNumber } from 'technical/currency/formatters';
import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import { ModalHeader } from 'ui/custom-modal/header';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface SanctionProps {
  title: string;
  results: ContactSanctionsQuery['contactSanctions'];
}

const Sanction = ({ title, results }: SanctionProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typo color="primary" size="lg">
          {title}
        </Typo>
        <PendingChipCount count={results.length} />
      </Stack>
      <Divider />
      {results.length ? (
        <List dense>
          {results.map(({ id, score, entityUrl, caption }) => (
            <ListItem
              key={id}
              secondaryAction={
                <Chip
                  label={formatNumber(score, { precision: 2 })}
                  size="small"
                />
              }
            >
              <ListItemText>
                <a href={entityUrl} target="_blank" rel="noreferrer noopener">
                  {caption}
                </a>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Stack alignItems="center">
          <Typo size="sm">{t('common.noData')}</Typo>
        </Stack>
      )}
    </Stack>
  );
};

interface ContactSanctionsModalProps {
  id: string;
  firstName: string;
  lastName: string;
  onClose: () => void;
}

export const ContactSanctionsModal = ({
  id,
  firstName,
  lastName,
  onClose,
}: ContactSanctionsModalProps) => {
  const { data, loading, error } = useContactSanctionsQuery({
    variables: {
      contactId: id,
    },
  });
  const { t } = useTranslation();

  if (loading || error || data === undefined) {
    return (
      <>
        <ModalHeader
          title={t('pages.fundManager.lps.table.seeSanctions')}
          onClose={onClose}
        />
        <Stack alignItems="center" className={styles.content}>
          <QueryStateDisplay loading={loading} error={error} />
        </Stack>
      </>
    );
  }

  return (
    <>
      <ModalHeader
        title={t('pages.fundManager.lps.table.seeSanctions')}
        onClose={onClose}
      />
      <Stack padding={3} spacing={2} className={styles.content}>
        <Sanction
          title={getPersonFullName({ firstName, lastName })}
          results={data.contactSanctions ?? []}
        />
      </Stack>
    </>
  );
};
