import { TFunction } from 'translations/hook';

import { computeAmlVigilanceAverage } from 'business/fund-manager/lp/services/compute-aml-vigilance-average';
import {
  ClientTypeEnum,
  GetFundManagerDashBoardInfosQueryResult,
} from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryInMillions,
} from 'technical/currency/formatters';
import { PlotChartTypeEnum } from 'technical/plotly-charts/types';

export const getVigilanceScoresGraphData = ({
  data,
  colorRange,
  t,
}: {
  data: NonNullable<
    GetFundManagerDashBoardInfosQueryResult['data']
  >['getFundManagerDashBoardInfos']['lpsOverview'];
  colorRange: string[];
  t: TFunction;
}) => {
  return data.lpRiskScoreRepartition.map((riskScore, index) => {
    const color = colorRange[index];
    const vigilance = computeAmlVigilanceAverage(riskScore?.riskScore);

    return {
      x: [riskScore?.count ?? 0],
      y: [0],
      type: PlotChartTypeEnum.bar,
      offsetgroup: 1,
      orientation: 'h',
      text: vigilance,
      textPosition: 'auto',
      marker: {
        color: color,
        line: color,
        width: 1,
      },
      insidetextanchor: 'middle',
      hovertemplate: `<b>${vigilance}: ${t(
        'pages.fundManager.dashboard.cards.investors.lpCount',
        {
          count: riskScore?.count,
        },
      )}</b><extra></extra>`,
    };
  });
};

export const getLpsByClientTypeGraphData = ({
  data,
  colorRange,
  t,
}: {
  data: NonNullable<
    GetFundManagerDashBoardInfosQueryResult['data']
  >['getFundManagerDashBoardInfos']['lpsOverview'];
  colorRange: string[];
  t: TFunction;
}) => {
  return [
    { clientType: ClientTypeEnum.Individual, count: data.lpIndividualCount },
    { clientType: ClientTypeEnum.LegalEntity, count: data.lpLegalEntityCount },
  ].map((countByType, index) => {
    const color = colorRange[index];
    const clientTypeTrad = t(
      `pages.fundManager.dashboard.cards.investors.graphs.labels.${countByType.clientType}`,
    );

    return {
      x: [countByType.count ?? 0],
      y: [1],
      type: PlotChartTypeEnum.bar,
      offsetgroup: 1,
      orientation: 'h',
      text: clientTypeTrad,
      textPosition: 'auto',
      insidetextanchor: 'middle',
      marker: {
        color: color,
        line: color,
        width: 1,
      },
      hovertemplate: `<b>${clientTypeTrad}: ${t(
        'pages.fundManager.dashboard.cards.investors.lpCount',
        {
          count: countByType?.count,
        },
      )}</b><extra></extra>`,
    };
  });
};

export const getComitmentsByClientType = ({
  data,
  colorRange,
  t,
}: {
  data: NonNullable<
    GetFundManagerDashBoardInfosQueryResult['data']
  >['getFundManagerDashBoardInfos']['lpsOverview'];
  colorRange: string[];
  t: TFunction;
}) => {
  return [
    {
      clientType: ClientTypeEnum.Individual,
      count: data.lpIndividualCommitmentAmount,
    },
    {
      clientType: ClientTypeEnum.LegalEntity,
      count: data.lpLegalEntityCommitmentAmount,
    },
  ].map((countByType, index) => {
    const color = colorRange[index];
    const clientTypeTrad = t(
      `pages.fundManager.dashboard.cards.investors.graphs.labels.${countByType.clientType}`,
    );
    return {
      x: [countByType.count ?? 0],
      y: [1],
      type: PlotChartTypeEnum.bar,
      offsetgroup: 1,
      orientation: 'h',
      text: clientTypeTrad,
      textPosition: 'auto',
      marker: {
        color: color,
        line: color,
        width: 1,
      },
      insidetextanchor: 'middle',
      hovertemplate: `<b>${clientTypeTrad}: ${formatToMonetaryInMillions(
        convertFrom10X8toNumber(countByType.count),
      )}</b><extra></extra>`,
    };
  });
};
