import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'translations/hook';

import { RiskAssessmentTableAverageCell } from 'business/fund-manager/lp/components/risk-assessment-table-average-cell';
import { computeAmlRiskAverages } from 'business/fund-manager/lp/services/compute-aml-risk-averages';
import { RiskData } from 'business/fund-manager/lp/services/types';
import { useLanguageContext } from 'business/providers/language-provider';
import { getCountryNameByCountryCode } from 'technical/country-utils';
import { useRiskAssessmentColors } from 'technical/risk-assessment-colors/risk-assessment-colors';
import { TableCellGreyBorder } from 'ui/table-cell-grey-border';

import styles from './index.module.scss';

const RiskAssessmentValue = ({ row }: { row: RiskData }) => {
  const { language } = useLanguageContext();
  const { t } = useTranslation();

  if (row.name === 'lpCountry') {
    return (
      <TableCellGreyBorder>
        {row.value
          ? getCountryNameByCountryCode({
              code: row.value,
              language,
            })
          : ''}
      </TableCellGreyBorder>
    );
  }

  return (
    <TableCellGreyBorder>
      {/* slice(4) is used to remove the "N - " prefix of select option */}
      {row.value
        ? t(
            `pages.fundManager.lpCreation.aml.form.${row.name}.selectOptions.${row.value}`,
          ).slice(4)
        : ''}
    </TableCellGreyBorder>
  );
};

export const RiskAssessmentTable = ({ rows }: { rows: RiskData[] }) => {
  const { t } = useTranslation();

  const { displayedRiskAverage, vigilanceAverage } =
    computeAmlRiskAverages(rows);

  const rowColors = useRiskAssessmentColors();

  return (
    <TableContainer component={Paper}>
      <Table className={styles.table}>
        <TableHead>
          <TableRow className={styles.tableHeader}>
            <TableCellGreyBorder hideBorderRight></TableCellGreyBorder>

            <TableCellGreyBorder></TableCellGreyBorder>

            <TableCellGreyBorder>
              <RiskAssessmentTableAverageCell
                title={t('pages.fundManager.lpCreation.aml.table.headers.risk')}
                description={
                  displayedRiskAverage
                    ? t(
                        'pages.fundManager.lpCreation.aml.table.averages.riskAverageMessage',
                        {
                          context: displayedRiskAverage,
                        },
                      )
                    : ''
                }
                displayedAverage={displayedRiskAverage}
                colorIndex={displayedRiskAverage}
                rowColors={rowColors}
              />
            </TableCellGreyBorder>

            <TableCellGreyBorder>
              <RiskAssessmentTableAverageCell
                title={t(
                  'pages.fundManager.lpCreation.aml.table.headers.vigilance',
                )}
                description={
                  displayedRiskAverage
                    ? t(
                        'pages.fundManager.lpCreation.aml.table.averages.vigilanceAverageMessage',
                        {
                          context: vigilanceAverage,
                        },
                      )
                    : ''
                }
                displayedAverage={vigilanceAverage}
                colorIndex={displayedRiskAverage}
                rowColors={rowColors}
              />
            </TableCellGreyBorder>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCellGreyBorder
                component="th"
                scope="row"
                hideBorderRight
                className={styles.inputCell}
              >
                {t(
                  `pages.fundManager.lpCreation.aml.form.${row.name}.fieldName`,
                )}
              </TableCellGreyBorder>

              <RiskAssessmentValue row={row} />

              <TableCellGreyBorder
                sx={{
                  backgroundColor: row.riskvalue
                    ? rowColors[row.riskvalue].background
                    : '',
                  color: row.riskvalue ? rowColors[row.riskvalue].text : '',
                }}
                className={styles.resultCell}
              >
                {row.riskvalue}
              </TableCellGreyBorder>
              <TableCell
                sx={{
                  backgroundColor: row.riskvalue
                    ? rowColors[row.riskvalue].background
                    : '',
                  color: row.riskvalue ? rowColors[row.riskvalue].text : '',
                }}
                className={styles.resultCell}
              >
                {row.vigilanceValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
