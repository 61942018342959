import { Stack } from '@mui/material';
import { useParams } from 'react-router';

import { CompanyContactManager } from 'business/fund-manager/portfolio/company/components/company-contact-manager';
import { useCompanyContacts } from 'business/fund-manager/portfolio/company/services/hooks/use-company-contacts';
import { QueryStateDisplay } from 'ui/query-state-display';

export const CompanyContacts = () => {
  const { companyId = '' } = useParams();
  const { loading, error, contacts, refetch } = useCompanyContacts({
    companyId,
  });
  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }
  return (
    <Stack alignSelf="stretch">
      <CompanyContactManager
        companyId={companyId}
        contacts={contacts}
        onAdded={refetch}
        onDeleted={refetch}
        onUpdated={refetch}
      />
    </Stack>
  );
};
