import { Grid } from '@mui/material';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
}

export const GridSpacer = ({ children }: Props) => {
  return (
    <Grid container spacing={2} className={styles.verticalMargin}>
      {children}
    </Grid>
  );
};
