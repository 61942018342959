import { ReactNode } from 'react';

import { Typo } from 'ui/typo';

interface Props {
  title: ReactNode | string;
}

export const SectionTitle = ({ title }: Props) => {
  return (
    <Typo color="primary" size="2xl" component="h5">
      {title}
    </Typo>
  );
};
