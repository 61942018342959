import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction, useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { router } from 'business/router/services';
import {
  GetAllAdvisorsManagementCompaniesQuery,
  useGetAllAdvisorsManagementCompaniesQuery,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { SimpleTable } from 'ui/simple-table/simple-table';

export const getManagementCompaniesColumnDefinition = (
  t: TFunction,
): ColDef<
  GetAllAdvisorsManagementCompaniesQuery['advisorManagementCompanies'][number]
>[] => {
  return [
    {
      field: 'name',
      filter: true,
      headerName: t('pages.fundManager.settings.managementCompany.table.name'),
      flex: 1,
    },
    {
      headerName: t(
        'pages.fundManager.settings.managementCompany.table.details',
      ),
      colId: 'details',
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<
        GetAllAdvisorsManagementCompaniesQuery['advisorManagementCompanies'][number]
      >) => {
        if (!data || !value.id) {
          return null;
        }

        return (
          <ActionButton
            variant={'primary'}
            onClick={() => router.navigate(value.to)}
            size="small"
          >
            {t('pages.fundManager.settings.managementCompany.table.details')}
          </ActionButton>
        );
      },
      valueGetter: ({ data }) => {
        if (!data) {
          return {};
        }

        return {
          id: data.id,
          to: FundManagerRoutes.SettingsAdvisorId.replace(
            ':advisorId',
            data.id,
          ),
        };
      },
      flex: 1,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  ];
};

export const ListAdvisorManagementCompany = () => {
  const { t } = useTranslation();
  const { data, loading } = useGetAllAdvisorsManagementCompaniesQuery({
    fetchPolicy: 'network-only',
  });

  const headers: ColDef<
    GetAllAdvisorsManagementCompaniesQuery['advisorManagementCompanies'][number]
  >[] = getManagementCompaniesColumnDefinition(t);

  return (
    <Stack spacing={2}>
      <SimpleTable<
        GetAllAdvisorsManagementCompaniesQuery['advisorManagementCompanies'][number]
      >
        rowData={data?.advisorManagementCompanies ?? []}
        columnDefs={headers}
        loading={loading}
      />
    </Stack>
  );
};
