import { useController } from 'react-hook-form';

import { DropDownCountry, DropdownCountryProps } from 'ui/dropdown-country';

interface FormDropdownCountryProps
  extends Omit<DropdownCountryProps, 'onChange' | 'value' | 'error'> {
  // improve this
  control?: any;
  onChange?: (value: string | null) => void;
}

export const FormDropdownCountry = ({
  control,
  name,
  required,
  disabled,
  onChange,
  ...rest
}: FormDropdownCountryProps) => {
  const {
    field: { value, onChange: onFormChange },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
    rules: { required },
  });

  return (
    <DropDownCountry
      {...rest}
      name={name}
      required={required}
      onChange={(newValue: string | null) =>
        onChange ? onChange(newValue ?? '') : onFormChange(newValue ?? '')
      }
      value={value ?? ''}
      error={!!error}
      helperText={error?.message}
      disabled={disabled || isSubmitting}
    />
  );
};
