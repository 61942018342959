import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  primaryColor: string;
  secondaryColor: string;
}

export const NewInvestmentIcon = ({ primaryColor, secondaryColor }: Props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path d="M2.83594 23.047H21.6641C21.9877 23.047 22.25 22.7847 22.25 22.4611V17.7345C22.25 17.6094 22.2054 17.5113 22.2044 17.5075L22.2038 17.5061L19.8649 11.6197C19.7762 11.3966 19.5603 11.2501 19.3203 11.2501H16.6326L13.2167 11.2502L11.0242 11.2502C8.11653 11.2502 9.16001 11.25 9.16001 11.25L7.86737 11.2501H5.17969C4.93967 11.2501 4.72375 11.3966 4.63525 11.6197L2.29578 17.5075C2.29456 17.5114 2.25107 17.6076 2.25015 17.7307L2.25 17.7345V22.4611C2.25 22.7847 2.5123 23.047 2.83594 23.047ZM3.42188 21.8751V18.3205H21.0781V21.8751H3.42188ZM5.57733 12.422H8.93961C9.07252 12.422 11.1458 12.422 12.3986 12.422H15.5605H18.9227L20.8009 17.1486H3.69928L5.57733 12.422Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15998 11L19.3203 11.0001C19.6627 11.0001 19.9707 11.2091 20.0972 11.5274L22.4343 17.4091L22.4402 17.4226L22.4416 17.4276C22.443 17.4312 22.4446 17.4354 22.4464 17.4402C22.4527 17.4572 22.4607 17.4805 22.4685 17.5087C22.4839 17.5643 22.5 17.6432 22.5 17.7345V22.4611C22.5 22.9228 22.1258 23.297 21.6641 23.297H2.83594C2.37423 23.297 2 22.9228 2 22.4611V17.7245C2.00115 17.6368 2.01706 17.5609 2.03205 17.5072C2.03979 17.4795 2.04763 17.4566 2.05386 17.4398C2.05699 17.4313 2.05967 17.4246 2.06159 17.4198L2.06278 17.4169L2.06345 17.4152L4.40287 11.5275C4.40286 11.5276 4.40289 11.5275 4.40287 11.5275C4.52924 11.2092 4.83734 11.0001 5.17969 11.0001L9.15998 11ZM8.97263 11.5L5.17969 11.5001C5.04204 11.5001 4.9183 11.5842 4.86764 11.7119L2.52869 17.5983L2.52677 17.6032L2.52542 17.6065L2.52266 17.6136C2.52036 17.6198 2.51705 17.6294 2.51365 17.6416C2.50661 17.6668 2.5004 17.699 2.50015 17.7326L2.50009 17.7407L2.5 22.4611C2.5 22.6467 2.65037 22.797 2.83594 22.797H21.6641C21.8496 22.797 22 22.6467 22 22.4611V17.7345C22 17.7007 21.9938 17.668 21.9867 17.6423C21.9832 17.6299 21.9799 17.6201 21.9775 17.6138L21.9746 17.6063M21.9737 17.604L21.9712 17.5985L19.6326 11.712C19.6326 11.712 19.6326 11.712 19.6326 11.712C19.5818 11.5842 19.4579 11.5001 19.3203 11.5001L8.97854 11.5001L8.9752 11.5001L8.97263 11.5M2.06345 17.4152C2.06342 17.4152 2.06348 17.4151 2.06345 17.4152C2.06435 17.4128 2.06455 17.4124 2.06345 17.4152ZM5.40765 12.172H19.0923L21.1692 17.3986H3.33093L5.40765 12.172ZM5.74701 12.672L4.06763 16.8986H20.4325L18.753 12.672H5.74701ZM3.17188 18.0705H21.3281V22.1251H3.17188V18.0705ZM3.67188 18.5705V21.6251H20.8281V18.5705H3.67188Z"
      />
      <path d="M16.9609 15H7.58594C7.2623 15 7 15.2623 7 15.5859C7 15.9096 7.2623 16.1719 7.58594 16.1719H16.9609C17.2846 16.1719 17.5469 15.9096 17.5469 15.5859C17.5469 15.2623 17.2846 15 16.9609 15Z" />
      <circle
        cx="11.918"
        cy="6.91799"
        r="6.16799"
        stroke={primaryColor}
        strokeWidth="1.5"
        fill={secondaryColor}
      />
      <path d="M12.1267 9.85232C11.5829 9.85232 11.1308 9.70114 10.7704 9.39878C10.4099 9.09642 10.1991 8.68655 10.138 8.16918C10.138 8.16918 10.1126 7.92146 10.5193 7.92146C10.9261 7.92146 10.9261 8.16918 10.9261 8.16918C10.975 8.43123 11.1003 8.65968 11.3019 8.85453C11.5096 9.04267 11.7876 9.13674 12.1358 9.13674C12.4596 9.13674 12.6979 9.06283 12.8507 8.915C13.0034 8.76046 13.0798 8.57905 13.0798 8.37075C13.0798 8.06839 12.979 7.86682 12.7773 7.76603C12.5818 7.66524 12.3039 7.57454 11.9434 7.49391C11.699 7.44015 11.4546 7.36288 11.2102 7.2621C10.9659 7.16131 10.7612 7.02021 10.5962 6.83879C10.4313 6.65065 10.3488 6.40541 10.3488 6.10304C10.3488 5.6663 10.4954 5.31019 10.7887 5.0347C11.088 4.7525 11.4913 4.6114 11.9984 4.6114C12.481 4.6114 12.8751 4.74578 13.1806 5.01454C13.4922 5.27659 13.6724 5.65286 13.7213 6.14336C13.7213 6.14336 13.7213 6.34446 13.4005 6.35837C13.0798 6.37229 12.9606 6.14336 12.9606 6.14336C12.9301 5.88803 12.8293 5.68982 12.6582 5.54872C12.4933 5.40089 12.2703 5.32698 11.9892 5.32698C11.7143 5.32698 11.5004 5.39082 11.3477 5.51848C11.2011 5.64614 11.1278 5.81412 11.1278 6.02242C11.1278 6.22399 11.2225 6.38189 11.4119 6.49611C11.6074 6.61034 11.8701 6.70777 12.2 6.7884C12.481 6.85559 12.7468 6.93958 12.9973 7.04036C13.2539 7.13443 13.4616 7.27889 13.6205 7.47375C13.7854 7.66189 13.8679 7.93737 13.8679 8.3002C13.874 8.75039 13.7182 9.1233 13.4005 9.41894C13.0889 9.70786 12.6643 9.85232 12.1267 9.85232Z" />
      <path d="M11.6636 4.38153C11.6636 4.15302 11.8344 3.96777 12.0451 3.96777C12.2558 3.96777 12.4266 4.15302 12.4266 4.38153V4.65737C12.4266 4.88588 12.2558 5.07113 12.0451 5.07113C11.8344 5.07113 11.6636 4.88588 11.6636 4.65737V4.38153Z" />
      <path d="M11.6636 9.80635C11.6636 9.57784 11.8344 9.39259 12.0451 9.39259C12.2558 9.39259 12.4266 9.57784 12.4266 9.80635V10.0822C12.4266 10.3107 12.2558 10.4959 12.0451 10.4959C11.8344 10.4959 11.6636 10.3107 11.6636 10.0822V9.80635Z" />
      <path
        d="M12.1267 9.85232C11.5829 9.85232 11.1308 9.70114 10.7704 9.39878C10.4099 9.09642 10.1991 8.68655 10.138 8.16918C10.138 8.16918 10.1126 7.92146 10.5193 7.92146C10.9261 7.92146 10.9261 8.16918 10.9261 8.16918C10.975 8.43123 11.1003 8.65968 11.3019 8.85453C11.5096 9.04267 11.7876 9.13674 12.1358 9.13674C12.4596 9.13674 12.6979 9.06283 12.8507 8.915C13.0034 8.76046 13.0798 8.57905 13.0798 8.37075C13.0798 8.06839 12.979 7.86682 12.7773 7.76603C12.5818 7.66524 12.3039 7.57454 11.9434 7.49391C11.699 7.44015 11.4546 7.36288 11.2102 7.2621C10.9659 7.16131 10.7612 7.02021 10.5962 6.83879C10.4313 6.65065 10.3488 6.40541 10.3488 6.10304C10.3488 5.6663 10.4954 5.31019 10.7887 5.0347C11.088 4.7525 11.4913 4.6114 11.9984 4.6114C12.481 4.6114 12.8751 4.74578 13.1806 5.01454C13.4922 5.27659 13.6724 5.65286 13.7213 6.14336C13.7213 6.14336 13.7213 6.34446 13.4005 6.35837C13.0798 6.37229 12.9606 6.14336 12.9606 6.14336C12.9301 5.88803 12.8293 5.68982 12.6582 5.54872C12.4933 5.40089 12.2703 5.32698 11.9892 5.32698C11.7143 5.32698 11.5004 5.39082 11.3477 5.51848C11.2011 5.64614 11.1278 5.81412 11.1278 6.02242C11.1278 6.22399 11.2225 6.38189 11.4119 6.49611C11.6074 6.61034 11.8701 6.70777 12.2 6.7884C12.481 6.85559 12.7468 6.93958 12.9973 7.04036C13.2539 7.13443 13.4616 7.27889 13.6205 7.47375C13.7854 7.66189 13.8679 7.93737 13.8679 8.3002C13.874 8.75039 13.7182 9.1233 13.4005 9.41894C13.0889 9.70786 12.6643 9.85232 12.1267 9.85232Z"
        stroke={primaryColor}
        strokeWidth="0.466298"
      />
      <path
        d="M11.6636 4.38153C11.6636 4.15302 11.8344 3.96777 12.0451 3.96777C12.2558 3.96777 12.4266 4.15302 12.4266 4.38153V4.65737C12.4266 4.88588 12.2558 5.07113 12.0451 5.07113C11.8344 5.07113 11.6636 4.88588 11.6636 4.65737V4.38153Z"
        stroke={primaryColor}
        strokeWidth="0.466298"
      />
      <path
        d="M11.6636 9.80635C11.6636 9.57784 11.8344 9.39259 12.0451 9.39259C12.2558 9.39259 12.4266 9.57784 12.4266 9.80635V10.0822C12.4266 10.3107 12.2558 10.4959 12.0451 10.4959C11.8344 10.4959 11.6636 10.3107 11.6636 10.0822V9.80635Z"
        stroke={primaryColor}
        strokeWidth="0.466298"
      />
    </SvgIcon>
  );
};
