import { Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { ModalFormBankDetails } from 'business/fund-manager/lp/components/modal-form-bank-details';
import { LpBankDetailsQuery, useLpBankDetailsQuery } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { EmptyCard } from 'ui/empty-card';
import { LpFundBankDetailsCard } from 'ui/lp-fund-bank-details-card';
import { QueryStateDisplay } from 'ui/query-state-display';

interface FundBankDetailsCardProps
  extends NonNullable<LpBankDetailsQuery['lpFundBankDetails'][number]> {
  onEdit: () => void;
}
const FundBankDetailsCard = ({
  fund,
  onBoardingStatus,
  bankDetails,
  onEdit,
}: FundBankDetailsCardProps) => {
  const { t } = useTranslation();

  return (
    <LpFundBankDetailsCard
      fundName={fund.name}
      name={bankDetails.name}
      iban={bankDetails.iban}
      bic={bankDetails.bic}
      onboardingStatus={onBoardingStatus}
    >
      <ActionButton variant="secondary" onClick={onEdit}>
        {t('common.actions.edit')}
      </ActionButton>
    </LpFundBankDetailsCard>
  );
};

const LpFundBankDetailsList = ({
  lpId,
  onEdit,
}: {
  lpId: string;
  onEdit: (id: string) => void;
}) => {
  const { data, loading, error } = useLpBankDetailsQuery({
    variables: {
      lpId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || data === undefined) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (data.lpFundBankDetails.length === 0) {
    return <EmptyCard />;
  }

  return (
    <Stack spacing={2} flexGrow={1} alignItems="stretch">
      {data.lpFundBankDetails.map((lpFundBankDetails) => (
        <FundBankDetailsCard
          key={lpFundBankDetails.id}
          {...lpFundBankDetails}
          onEdit={() => onEdit(lpFundBankDetails.bankDetails.id)}
        />
      ))}
    </Stack>
  );
};

export const LpFundBankDetails = () => {
  const { lpId = '' } = useParams();
  const [bankDetailsId, setBankDetailsId] = useState<string | undefined>();

  const onClose = () => setBankDetailsId(undefined);

  return (
    <>
      <LpFundBankDetailsList lpId={lpId} onEdit={setBankDetailsId} />
      {bankDetailsId ? (
        <CustomModal
          open
          handleClose={onClose}
          component={
            <ModalFormBankDetails id={bankDetailsId} onClose={onClose} />
          }
        />
      ) : null}
    </>
  );
};
