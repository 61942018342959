import { ContactWithId } from 'business/shared/services/contact/types';
import { ContactTypeEnum } from 'generated/graphql';

import { LpContactType } from './types';

export function getContactInformation(contact?: ContactWithId): LpContactType {
  return {
    contact: {
      title: contact?.title ?? '',
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
      phoneNumber1: contact?.phoneNumber1 ?? '',
      email: contact?.email ?? '',
      jobTitle: contact?.jobTitle ?? '',
      jobEndDate: contact?.jobEndDate
        ? new Date(contact?.jobEndDate)
        : undefined,
      hasOperationalRights: contact?.hasOperationalRights ?? false,
      canAccessGeneralReports: contact?.canAccessGeneralReports ?? false,
      canAttendInvestorsMeeting: contact?.canAttendInvestorsMeeting ?? false,
      canAccessESGReports: contact?.canAccessESGReports ?? false,
      canAccessAmpereReports: contact?.canAccessAmpereReports ?? false,
      canAccessCapitalAccountStatement:
        contact?.canAccessCapitalAccountStatement ?? false,
      birthDate: contact?.birthDate ? new Date(contact.birthDate) : undefined,
      birthCity: contact?.birthCity ?? '',
      birthDepartment: contact?.birthDepartment ?? '',
      birthCountry: contact?.birthCountry ?? '',
      taxpayerIdentificationNumber: contact?.taxpayerIdentificationNumber ?? '',
      ...(contact?.type === ContactTypeEnum.LegalEntity
        ? {
            type: ContactTypeEnum.LegalEntity,
            legalEntity: {
              subscribingEntityName:
                contact?.legalEntity?.subscribingEntityName ?? '',
              uniqueCompanyIdentifier:
                contact?.legalEntity?.uniqueCompanyIdentifier ?? '',
              registrationLocation:
                contact?.legalEntity?.registrationLocation ?? '',
              country: contact?.legalEntity?.country ?? '',
            },
          }
        : {
            type: ContactTypeEnum.Individual,
          }),
    },
    address: {
      streetLine: contact?.address?.streetLine ?? '',
      streetLine2: contact?.address?.streetLine2 ?? '',
      country: contact?.address?.country ?? '',
      city: contact?.address?.city ?? '',
      zipCode: contact?.address?.zipCode ?? '',
    },
  };
}
