import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { getStoredLp } from 'business/lp-platform/nav-bar/services/lp-filters';
import { LPFilter } from 'business/lp-platform/nav-bar/services/types';
import authService from 'business/user/services/auth-service';
import fetchUser from 'business/user/services/user';
import { UserData } from 'business/user/types/user';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

function useUserData() {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [user, setUser] = useState<UserData | undefined>(undefined);
  const [lpFilters, setLpFilters] = useState<LPFilter[]>(getStoredLp());
  const client = useApolloClient();

  // @todo use subscription for user data when below error is handled
  // (cf this commit to "revert" code)
  // BEWARE: on invalid JWT, subscriptions are automatically disconnected and not re-subscribed
  // Error: cannot start as connection_init failed with : Malformed Authorization header

  const fetchAndStoreUser = useCallback(
    async function refetchUser() {
      setUser(await fetchUser(client));
    },
    [client],
  );

  const setLpContext = useCallback(async (newLpFilters: LPFilter[]) => {
    localStorage.setItem('lpFilters', JSON.stringify(newLpFilters));
    setLpFilters(newLpFilters);
    await authService.renewToken();
  }, []);

  /**
   * Fonction to be called if needed typcially when authentication status changes
   * (ie - was not connected and now am connected)
   */
  const requestRebootstrap = useCallback(
    async function requestRebootstrap() {
      const authResult = authService.getAuthResult();
      if (authResult) {
        setUser(authResult);
        setIsBootstraped(true);
        return;
      }

      try {
        await authService.initAuthentication();
        if (!authService.isAuthenticated()) {
          return;
        }
        await fetchAndStoreUser();
      } catch (error) {
        logger.error(error);
        throw error;
      } finally {
        setIsBootstraped(true);
      }
    },
    [fetchAndStoreUser],
  );

  useEffect(() => {
    requestRebootstrap();
  }, [client, requestRebootstrap]);

  // user sync with providers
  useEffect(() => {
    if (user) {
      errorReporting.setUser({
        id: user.userId,
        email: user.email,
      });
    } else {
      errorReporting.removeUser();
    }
  }, [user]);

  return {
    user,
    isConnected: !!user,
    isBootstraped,
    requestRebootstrap,
    setLpContext,
    lpFilters,
  };
}

export default useUserData;
