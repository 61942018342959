import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { GetDashBoardInfosQuery } from 'generated/graphql';
import { removeDuplicates } from 'technical/remove-duplicates';
import { ChartIcon, FileIcon, RightArrowIcon } from 'ui/icons';
import { StatNumericAmountCard } from 'ui/stat-numeric-amount-card';
import { StatNumericAmountItemProps } from 'ui/stat-numeric-amount-item';
import { Typo } from 'ui/typo';

interface Props {
  title: string;
  data: {
    funds: GetDashBoardInfosQuery['funds'];
    numericAmount: number;
    numericTotalDrawn: number;
    numericUnfundedCommitment: number;
  };
}

// TODO : refacto to have 1 card for commitment for all dashboard (WebPortal.GP, WebPortal.LP, LP), like it's done for subscriptions
export default function CommitmentCard({
  title,
  data: { numericAmount, numericTotalDrawn, funds, numericUnfundedCommitment },
}: Props) {
  const { t } = useTranslation();
  const { currency } = useFund();

  const fundsCount = removeDuplicates(
    funds.map(({ share }) => share),
    'fundId',
  ).length;

  const totalSubscriptionsDescription = (
    <Stack direction="row" spacing={0.5}>
      <Typo>{t('pages.dashboard.cards.commitment.totalCommitment')}</Typo>
      <Typo bold>
        {t('pages.dashboard.cards.commitment.fundWithCount', {
          count: fundsCount,
        })}
      </Typo>
    </Stack>
  );

  const items: StatNumericAmountItemProps[] = [
    {
      value: numericAmount,
      currency,
      description: totalSubscriptionsDescription,
      imageSource: <ChartIcon />,
      iconColor: 'secondary',
    },
    {
      value: numericTotalDrawn,
      currency,
      description: t('pages.dashboard.cards.commitment.totalDrawn'),
      imageSource: <RightArrowIcon />,
      iconColor: 'primary',
    },
    {
      value: numericUnfundedCommitment,
      currency,
      description: t('pages.dashboard.cards.commitment.unfundedCommitments'),
      imageSource: <FileIcon />,
      iconColor: 'secondary',
    },
  ];
  return (
    <StatNumericAmountCard
      items={items}
      title={title}
      path={LpPlatformRoutes.Commitment}
    />
  );
}
