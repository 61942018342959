import { GetFundShareValuationQuery } from 'generated/graphql';

// Business Rule:
// If multiple shares are selected, return valuation dates in common
export const getSharesCommonValuationDates = (
  fundShareValuations: GetFundShareValuationQuery['fundShareValuations'],
  selectedSharesIds: string[],
) => {
  const shareIdsByDate = fundShareValuations.reduce<
    Record<number, { shareIds: string[]; date: number }>
  >((acc, fundShareValuation) => {
    if (
      !fundShareValuation ||
      !selectedSharesIds.includes(fundShareValuation.share.id)
    ) {
      return acc;
    }

    const { date, share } = fundShareValuation;

    if (acc?.[date]?.shareIds?.includes(share.id)) {
      return acc;
    }

    return {
      ...acc,
      [date]: { date, shareIds: [...(acc?.[date]?.shareIds ?? []), share.id] },
    };
  }, {});

  return Object.values(shareIdsByDate)
    .filter(({ shareIds }) => shareIds.length === selectedSharesIds.length)
    .map(({ date }) => date);
};
