export enum RiskAssessmentCountryType {
  EU_COUNTRIES = 'euCountry',
  BLACKLIST = 'blacklist',
  OFFSHORE_CENTER = 'offshoreCenter',
  NON_EU_WITH_AML_ARRANGEMENT = 'nonEuWithAMLArrangement',
  ART_L561_TARGETED_COUNTRIES = 'articleL561Targeted',
}

const blacklistCountries = ['KP', 'IR', 'MM'];
// I did not add Bulgaria (country code BG)
// it's on the grey list
// src -> https://www.douane.gouv.fr/actualites/actualisation-par-le-gafi-de-la-liste-des-pays-sur-liste-grise-et-noire
const euCountries = [
  'AT',
  'BE',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'ES',
  'SE',
];

// src -> https://www.consilium.europa.eu/fr/press/press-releases/2023/10/17/taxation-antigua-and-barbuda-belize-and-seychelles-added-to-eu-list-of-non-cooperative-jurisdictions-for-tax-purposes/
const offShoreCenterCountries = [
  // les Samoa américaines
  'AS',
  // Antigua-et-Barbuda
  'AG',
  // Anguilla
  'AI',
  // les Bahamas
  'BS',
  // le Belize
  'BZ',
  // les Fidji
  'FJ',
  // Guam
  'GU',
  // les Palaos
  'PW',
  // le Panama
  'PA',
  // la Russie
  'RU',
  // le Samoa
  'WS',
  // les Seychelles
  'SC',
  // Trinité-et-Tobago
  'TT',
  // les Îles Turks-et-Caïcos
  'TC',
  // les Îles Vierges américaines
  'VI',
  // le Vanuatu
  'VU',
];

// src -> https://www.legifrance.gouv.fr/loda/article_lc/LEGIARTI000024422380#:~:text=Les%20pays%20tiers%20%C3%A9quivalents%20mentionn%C3%A9s,Mexique%2C%20Singapour%20et%20la%20Suisse
const articleL561TargetedCountries = [
  // l'Afrique du Sud,
  'ZA',
  //  l'Australie,
  'AU',
  //  le Brésil,
  'BR',
  //  le Canada,
  'CA',
  //  la Corée du Sud,
  'KR',
  //  les Etats-Unis,
  'US',
  //  la Fédération de Russie,
  'RU',
  //  Hong Kong,
  'HK',
  //  l'Inde,
  'IN',
  //  le Japon,
  'JP',
  //  le Mexique,
  'MX',
  //  Singapour,
  'SG',
  // Suisse
  'CH',
];

// src -> https://www.fatf-gafi.org/en/countries/fatf.html
const nonEuWithAMLArrangementCountries = [
  // Argentina
  'AR',
  // Australia
  'AU',
  // Austria already in EU
  // Belgium - already in EU
  // Brazil
  'BR',
  // Canada
  'CA',
  // China
  'CN',
  // Denmark - already in EU
  // European Commission - not a country
  // Finland - already in EU
  // France - already in EU
  // Germany -already in EU
  // Greece - already in EU
  // Gulf Co-operation Council - not a country
  // Hong Kong, China
  'HK',
  // Iceland
  'IS',
  // India
  'IN',
  // Indonesia
  'ID',
  // Ireland - already in EU
  // Israel
  'IL',
  // Italy - already in EU
  // Japan
  'JP',
  // Korea
  'KR',
  // Luxembourg - already in EU
  // Malaysia
  'MY',
  // Mexico
  'MX',
  // Netherlands - already in EU
  // New Zealand
  'NZ',
  // Norway
  'NO',
  // Portugal - already in EU
  // Russian Federation * suspended
  // Saudi Arabia
  'SA',
  // Singapore
  'SG',
  // South Africa
  'ZA',
  // Spain - already in EU
  // Sweden - already in EU
  // Switzerland
  'CH',
  // Türkiye
  'TR',
  // United Kingdom
  'GB',
  // United States
  'US',
];

export const riskAssessmentCountries: Record<
  RiskAssessmentCountryType,
  string[]
> = {
  [RiskAssessmentCountryType.EU_COUNTRIES]: euCountries,
  [RiskAssessmentCountryType.BLACKLIST]: blacklistCountries,
  [RiskAssessmentCountryType.NON_EU_WITH_AML_ARRANGEMENT]:
    nonEuWithAMLArrangementCountries,
  [RiskAssessmentCountryType.OFFSHORE_CENTER]: offShoreCenterCountries,
  [RiskAssessmentCountryType.ART_L561_TARGETED_COUNTRIES]:
    articleL561TargetedCountries,
};
