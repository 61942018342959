import { SvgIcon } from '@mui/material';

export const MoicIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4H11V16H7V4ZM6 18H18V20H6V18Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 10H17V16H13V10Z" />
    </SvgIcon>
  );
};
