import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import styles from './index.module.scss';

// TODO rename this component this isn't a tooltip :/
export const TooltipContainer = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      className={styles.stack}
      direction="row"
      justifyContent="space-between"
    >
      {children}
    </Stack>
  );
};
