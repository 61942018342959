import * as yup from 'yup';

import {
  CashflowEmittedByEnum,
  CashflowTypeEnum,
  GetOperationShareQuery,
  Web_Portal_CashflowEmittedBy_Enum,
  Web_Portal_CashflowType_Enum,
  Web_Portal_OperationStatus_Enum,
} from 'generated/graphql';

import {
  SharesFormFundPart,
  ShareYupFields,
  fundDocumentSchema,
  operationCreationInputSchema,
  operationUpdateInputSchema,
  previewEmailForm,
} from './validation';

export interface OperationsFilteredFields {
  fundNames: string[];
  status: string[];
}

export enum OperationStatus {
  DRAFT = 'draft',
  DONE = 'done',
  IN_PROGRESS = 'inProgress',
}

export enum FlowType {
  NET_WIRE_TRANSFER = 'net-wire-transfer',
  ESCROW_ACCOUNT_USE = 'escrow-account-use',
}

export enum FlowEmittedBy {
  FUND = 'fund',
  INVESTOR = 'investor',
}

export enum FinancialFlowErrors {
  INVALID_DATE = 'invalid-date',
  INVALID_AMOUNT = 'invalid-amount',
}

export enum OperationCreationStepEnum {
  Details = 0,
  Shares = 1,
  SharesLp = 2,
  PreviewEmail = 3,
  Resume = 4,
}

export type OperationSharesLpPreview = {
  lp: {
    id: string;
    lpName: string;
  };
  share: { name: string; id: string; fund: { id: string } };
  id: string;
};

export type OperationSharesLp = {
  lpView: {
    id: string;
    lpName: string;
  };
  shareName: string;
  id: string;
  interest?: number | null;
  investment?: number | null;
  fees?: number | null;
  dividend?: number | null;
  currentDistributed?: number | null;
  capitalGain?: number | null;
  premium?: number | null;
  other?: number | null;
  returnOfCost?: number | null;
  numericInterest?: number | null;
  numericInvestment?: number | null;
  numericFees?: number | null;
  numericDividend?: number | null;
  numericCurrentDistributed?: number | null;
  numericCapitalGain?: number | null;
  numericPremium?: number | null;
  numericOther?: number | null;
  numericReturnOfCost?: number | null;
  drawn: number;
  distributed: number;
  witholdings: number;
  netDistributed: number;
  emittedBy?: CashflowEmittedByEnum;
  escrowAmount?: number;
};

export type ShareRecapTableType =
  GetOperationShareQuery['web_portal_operationShare'][number]['operationShareLps'][number] & {
    shareName: string;
    drawn: number;
    distributed: number;
    witholdings: number;
    netDistributed: number;
  };

export interface OperationSharesLpDisplayValues {
  displayInvestmentFeesAndOther: boolean;
  displayReturnOfCostCapitalGainInterestorDividends: boolean;
  displayCurrentlyDistributed: boolean;
}

export interface OperationShareValues {
  interest?: number | null;
  investment?: number | null;
  fees?: number | null;
  premium?: number | null;
  dividend?: number | null;
  currentDistributed?: number | null;
  capitalGain?: number | null;
  other?: number | null;
  returnOfCost?: number | null;
}

export interface ShareValues {
  shareName: string;
  nominalValue: number;
  totalSubscription: number;
  unfundedCommitmemts: number;
  unfundedCommitmemtsAfterOperation: number;
  investment: number;
}

export interface OperationSharesLpValues {
  lpName: string;
  shareName: string;
  id: string;
  type: string;
  countPendingValidationCashflow: number;
  remaining: number;
  received: number;
  amount: number;
  cashRatio: number;
}

export interface Cashflow {
  id: string;
  date: Date;
  numericAmount: number;
  // TODO: find a way to fix enums
  emittedBy: Web_Portal_CashflowEmittedBy_Enum | CashflowEmittedByEnum;
  type: Web_Portal_CashflowType_Enum | CashflowTypeEnum;
}

export interface CashflowRow extends Cashflow {
  paidOut?: number;
  paidIn?: number;
}

export enum OperationNavBarTabEnum {
  Overview = 0,
  Investors = 1,
  Emailing = 2,
}

export enum OperationInvestorsNavBarTabEnum {
  Validated = 0,
  ToValidate = 1,
}

export type OperationStatusRecap = {
  totalCalled: number;
  totalPaid: number;
  premium: number;
  remain: number;
  status: Web_Portal_OperationStatus_Enum;
};

export type OperationCreationInput = yup.InferType<
  typeof operationCreationInputSchema
>;

export type UpdateOperationInput = yup.InferType<
  typeof operationUpdateInputSchema
>;

export enum CommitmentStatusEnum {
  New = 'new',
  Equalized = 'equalized',
  All = 'all',
}

export enum OpFieldNames {
  drawn = 'drawn',
  managementFees = 'fees',
  otherFees = 'other',
  returnOfCost = 'returnOfCost',
  capitalGain = 'capitalGain',
  interest = 'interest',
  dividend = 'dividend',
  currentDistributed = 'currentDistributed',

  fund = 'fund',
  selected = 'selected',
  global = 'global',
  perShare = 'perShare',

  drawnGlobal = 'drawn.global',
  managementFeesGlobal = 'fees.global',
  otherFeesGlobal = 'other.global',
  returnOfCostGlobal = 'returnOfCost.global',
  capitalGainGlobal = 'capitalGain.global',
  interestGlobal = 'interest.global',
  dividendGlobal = 'dividend.global',
  currentDistributedGlobal = 'currentDistributed.global',

  drawnPerShare = 'drawn.perShare',
  managementFeesPerShare = 'fees.perShare',
  otherFeesPerShare = 'other.perShare',
  returnOfCostPerShare = 'returnOfCost.perShare',
  capitalGainPerShare = 'capitalGain.perShare',
  interestPerShare = 'interest.perShare',
  dividendPerShare = 'dividend.perShare',
  currentDistributedPerShare = 'currentDistributed.perShare',

  commitmentStatus = 'commitmentStatus',
}

export type FieldNamesWithoutSelected = Exclude<
  OpFieldNames,
  OpFieldNames.selected
>;

// {<uuid> : { <inputFieldName> : <value>, ...}, ...}
export type SharesFormSharesPart = Record<string, ShareYupFields>;
export type SharesFormValues = SharesFormSharesPart & SharesFormFundPart;

export type PreviewEmailForm = yup.InferType<typeof previewEmailForm>;

export type SendOperationEmailPreview = {
  email: string;
  operationShareLpId: string;
};

export enum EmailStatusCodeEnum {
  ERROR = 'emailStatusError',
  PENDING = 'emailStatusPending',
  SENT = 'emailStatusSent',
  NO_EMAIl = 'emailStatusNoEmail',
}

export type OperationShareLpEmailing = {
  id: string;
  lpName: string;
  shareName: string;
  shouldEmailLetter: boolean;
  status: string;
  statusCode: EmailStatusCodeEnum;
  emailSentAt?: string;
  emailReceivers?: string[];
};

// Also update en.json and fr.json to add corresponding text
export enum OperationShareLpErrors {
  DOC_GENERATION_FAILURE = 'doc-generation-failure',
  EMAIL_GENERATION_FAILURE = 'email-generation-failure',
  NO_CONTACT_WITH_OPERATIONAL_RIGHTS = 'no-contact-with-operational-rights',
}

export type FundDocumentFormData = yup.InferType<typeof fundDocumentSchema>;
