import { Business } from '@mui/icons-material';
import { Avatar, Stack } from '@mui/material';

import styles from './index.module.scss';

interface Props {
  name: string;
}

export const CompanyName = ({ name }: Props) => {
  return (
    <Stack direction="row" spacing={1} className={styles.stack}>
      <Avatar variant="rounded" className={styles.icon}>
        <Business />
      </Avatar>
      <p>{name}</p>
    </Stack>
  );
};
