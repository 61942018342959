import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { LpSelectableTable } from 'business/fund-manager/onboarding-subscription/components/lp-selectable-table';
import { CreateOnboardingSubscription } from 'business/fund-manager/onboarding-subscription/services/types';
import {
  createOnboardingSubscriptionFormSchema,
  ONBOARDING_NB_LP_LIMIT,
} from 'business/fund-manager/onboarding-subscription/services/validation';
import { ActiveFundsAndLpsQuery } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { FormCard, SelectInput } from 'ui/form';
import { Typo } from 'ui/typo';

interface OnboardingSubscriptionCreationFormProps {
  onSubmit: (data: CreateOnboardingSubscription) => void;
  onAdd: () => void;
  funds: ActiveFundsAndLpsQuery['activeFundsAndLps']['funds'];
  lps: Pick<
    ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
    'subscribingEntityName' | 'managementCompanyId' | 'id'
  >[];
}

export const FundAndLpSelection = ({
  onSubmit,
  onAdd,
  funds,
  lps,
}: OnboardingSubscriptionCreationFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { isValid },
    trigger,
  } = useForm<CreateOnboardingSubscription>({
    mode: 'onChange',
    resolver: yupResolver<CreateOnboardingSubscription>(
      createOnboardingSubscriptionFormSchema,
    ),
    defaultValues: {
      fundId: '',
      managementCompanyId: '',
      subscribingEntityNames: [],
    },
  });
  const subscribingEntityNames = useWatch({
    name: 'subscribingEntityNames',
    control,
  });

  const managementCompanyId = useWatch({
    control,
    name: 'managementCompanyId',
  });

  const onFundChange = (fundId: string) => {
    const fund = funds.find((f) => f.id === fundId);
    if (!fund) {
      reset();
      return;
    }

    if (managementCompanyId !== fund.managementCompanyId) {
      setValue('subscribingEntityNames', []);
      // to force formState.isValid = false, when this call is missing formState.isValid stay to true if form was valid
      trigger('subscribingEntityNames');
      setValue('managementCompanyId', fund.managementCompanyId ?? '');
    }

    setValue('fundId', fund.id ?? '');
  };

  // here we memoized lpList to avoid re-render LpSelectableTable at each render and loose user selection
  const lpList = useMemo(
    () =>
      lps.filter(
        // here we keep unstored lp that's why wee keep lp without id
        (lp) => !lp.id || lp.managementCompanyId === managementCompanyId,
      ),
    [lps, managementCompanyId],
  );

  return (
    <FormCard padding={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack
            justifyContent="end"
            direction="row"
            alignItems="center"
            gap={2}
          >
            <ActionButton
              size="small"
              variant="secondary"
              onClick={onAdd}
              data-test-id="create-operation-button"
            >
              {t(
                'pages.fundManager.onboardingSubscriptionCreate.createNewLp.title',
              )}
            </ActionButton>

            <Typo
              size="sm"
              color={
                subscribingEntityNames.length > ONBOARDING_NB_LP_LIMIT
                  ? 'dangerMain'
                  : undefined
              }
            >
              {t('components.fundAndLpSelection.lpSelected', {
                count: subscribingEntityNames.length,
                limit: ONBOARDING_NB_LP_LIMIT,
              })}
            </Typo>
            <ActionButton type="submit" variant="submit" disabled={!isValid}>
              {t('common.actions.continue')}
            </ActionButton>
          </Stack>
          <SelectInput
            fullWidth
            name="fundId"
            label={t(
              'pages.fundManager.onboardingSubscriptionCreate.form.fund',
            )}
            options={funds.map(({ id, name }) => ({
              id,
              value: name,
            }))}
            disabled={funds.length === 0}
            onChange={onFundChange}
            control={control}
          />
          {managementCompanyId ? (
            <LpSelectableTable lps={lpList} control={control} />
          ) : null}
        </Stack>
      </form>
    </FormCard>
  );
};
