import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { CheckmarkIcon, CrossIcon } from 'ui/icons';

interface Props {
  value: boolean;
}

const TrueFalseIndicator = ({ value }: Props) => {
  const palette = usePalette();
  return value ? (
    <CheckmarkIcon primaryColor={palette.primary[800]} />
  ) : (
    <CrossIcon primaryColor={palette.secondary[800]} />
  );
};

export default TrueFalseIndicator;
