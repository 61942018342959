import * as yup from 'yup';

import {
  companyFileUploadFormSchema,
  companyInvestmentSchema,
  companyMetricFormSchema,
  companyProfileSchema,
  companyTransactionCreationInputSchema,
  companyTransactionUpdateInputSchema,
  companyValuationUpdateInputSchema,
  contactFileUploadFormSchema,
} from './validation';

export enum CompanyCreationStepEnum {
  profile = 0,
  investments = 1,
  documentation = 2,
  contacts = 3,
}

export type CompanyProfile = yup.InferType<typeof companyProfileSchema>;

export type CompanyInvestment = yup.InferType<typeof companyInvestmentSchema>;

export type CompanyTransactionCreationInput = yup.InferType<
  typeof companyTransactionCreationInputSchema
>;

export type CompanyTransactionUpdateInput = yup.InferType<
  typeof companyTransactionUpdateInputSchema
>;

export type CompanyValuationUpdateInput = yup.InferType<
  typeof companyValuationUpdateInputSchema
>;

export type CompanyFileUploadForm = yup.InferType<
  typeof companyFileUploadFormSchema
>;

export type ContactFileUploadForm = yup.InferType<
  typeof contactFileUploadFormSchema
>;

export type CompanyMetricForm = yup.InferType<typeof companyMetricFormSchema>;

export enum CompanyInventoryTab {
  Synthesis = 0,
  InventoryDetails = 1,
}

export enum Quarters {
  q1 = 'q1',
  q2 = 'q2',
  q3 = 'q3',
  q4 = 'q4',
}

export type CompanyMetricValue = {
  value: number;
  metricName: string;
  companyMetricsType: {
    name: string;
    unit: string;
  };
  date: Date;
};
