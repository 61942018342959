import { useTranslation } from 'translations/hook';

import { PortfolioGraph } from 'business/lp-platform/portfolio/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import PlotChart from 'technical/plotly-charts';
import {
  PlotChartTextPosition,
  PlotChartTypeEnum,
  PlotData,
  PlotLayout,
} from 'technical/plotly-charts/types';

interface Props {
  values: PortfolioGraph[];
  fileName: string;
}

const PortfolioCountryGraph = ({ values, fileName }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  const uniqueCountriesValues = values.reduce((acc: string[], obj) => {
    if (!acc.includes(obj.country)) {
      return [...acc, obj.country];
    }
    return acc;
  }, []);

  const displayedValueSumByCountry: number[] = uniqueCountriesValues.map(
    (country) => {
      const sum = values.reduce(
        (acc, curr) =>
          curr.country === country ? acc + curr.displayedValue : acc,
        0,
      );

      const roundedSum = sum.toFixed(2);

      return parseFloat(roundedSum);
    },
  );

  const dataChart: PlotData = {
    type: PlotChartTypeEnum.treemap,
    labels: uniqueCountriesValues,
    parents: uniqueCountriesValues.map(() => ''), // For a treemap chart, we need to specify the parent's label element. If they are all on the same level, the default root is an empty string
    values: displayedValueSumByCountry,
    marker: {
      colors: [...Object.values(palette.secondary).reverse()],
      cornerradius: 10,
    },
    textposition: PlotChartTextPosition.topLeft,
    texttemplate: '%{label}<br><br>%{value} €',
    hovertemplate: `<b>%{label}</b><br><br>${t(
      'pages.portfolio.graph.tooltip',
    )}: %{value} €<extra></extra>`, // extra tag allow to remove 'trace' on hover
  };

  const plotLayout: PlotLayout = {
    autosize: true,
    font: {
      size: 11,
      color: '#ffffff',
    },
    margin: {
      t: 20,
      l: 0,
      r: 0,
      b: 0,
    },
  };

  return (
    <PlotChart
      dataArray={[dataChart]}
      layout={plotLayout}
      fileName={fileName}
    />
  );
};

export default PortfolioCountryGraph;
