import * as yup from 'yup';

import {
  emailRequiredSchema,
  nonEmptyStringSchema,
} from 'technical/validation';

export const emailTemplatingFormSchema = yup.object({
  subject: nonEmptyStringSchema,
  content: yup.string().optional(),
});

export const publicationDataSchema = yup.object({
  id: nonEmptyStringSchema,
  senderEmail: emailRequiredSchema,
  senderName: yup.string().defined(),
  crmEmail: emailRequiredSchema,
  fundContactInformation: yup.string().defined(),
  footerInformation: yup.string().defined(),
});

export const sendEmailTemplateTestFormSchema = yup.object({
  subject: nonEmptyStringSchema,
  content: nonEmptyStringSchema,
  email: emailRequiredSchema,
  lpId: nonEmptyStringSchema,
  fundId: nonEmptyStringSchema,
  emailTemplateId: nonEmptyStringSchema,
  operationId: nonEmptyStringSchema,
});
