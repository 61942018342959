export class LockedEmailError extends Error {
  lockedUntil: string;

  constructor(message: string, lockedUntil: string) {
    super();
    this.message = message;
    this.lockedUntil = lockedUntil;
  }
}

export class WrongCredentialsError extends Error {
  remainingTrials: number;

  constructor(message: string, remainingTrials: number) {
    super();
    this.message = message;
    this.remainingTrials = remainingTrials;
  }
}
