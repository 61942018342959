import { ReactElement } from 'react';

import { InfoCard } from 'ui/info-card';

interface Props {
  path?: string;
  title: string;
  children: ReactElement[] | ReactElement;
  chipContent?: number;
}

export const StatInfoCard = ({ path, title, chipContent, children }: Props) => {
  return (
    <InfoCard>
      <InfoCard.Title path={path} chipContent={chipContent}>
        {title}
      </InfoCard.Title>
      <InfoCard.Items>{children}</InfoCard.Items>
    </InfoCard>
  );
};
