import React, { PropsWithChildren } from 'react';

import { ErrorPage } from 'technical/error-page/error-page';
import logger from 'technical/logger';

type Props = PropsWithChildren;
interface State {
  hasError: false;
  error?: Error;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}

export const ErrorBoundary = ErrorBoundaryComponent;
