import classNames from 'classnames';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import appLogo from 'config/appLogo.svg';
import Link from 'ui/link';

import styles from './index.module.scss';

interface AppLogoProps {
  width?: number;
  height?: number;
  path?: string;
  connected?: boolean;
  clickable?: boolean;
}

export function AppLogo({ path, clickable, width, height = 24 }: AppLogoProps) {
  const imageSrc = path ? `data:image/png;base64,${path}` : null;

  return clickable ? (
    <Link to={LpPlatformRoutes.Home}>
      <img
        className={classNames(styles.appLogo, styles.pointer)}
        width={width ?? 'auto'}
        height={height}
        src={imageSrc ?? appLogo}
        alt="App logo"
      />
    </Link>
  ) : (
    <img
      className={styles.appLogo}
      width={width}
      height={height}
      src={imageSrc ?? appLogo}
      alt="App logo"
    />
  );
}
