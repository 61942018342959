import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { FormCard, FormWizzard } from 'ui/form';

interface DraftEditionContainerProps extends PropsWithChildren {
  onSubmit?: () => void;
  rawChildren?: boolean;
}
export const DraftEditionContainer = ({
  onSubmit,
  rawChildren,
  children,
}: DraftEditionContainerProps) => {
  const palette = usePalette();
  const { t } = useTranslation();
  const { onPreviousStep, activeStepIndex, steps } =
    useDraftEditionStepsContext();

  const labelNext =
    activeStepIndex < steps.length - 1
      ? t('common.actions.continue')
      : t('common.actions.save');

  const labelPrevious =
    activeStepIndex === 0
      ? t('common.actions.cancel')
      : t('common.actions.back');

  return (
    <Box className="relative">
      <Box
        className="sticky inset-0 z-10 pt-1 pb-4"
        sx={{ background: palette.backgroundGeneral }}
      >
        <FormWizzard.Actions
          labelPrevious={labelPrevious}
          labelNext={labelNext}
          onNext={onSubmit}
          onPrevious={onPreviousStep}
        />
      </Box>
      {rawChildren ? children : <FormCard>{children}</FormCard>}
    </Box>
  );
};
