import { Typography } from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import {
  PaletteVarKey,
  ThemePalette,
} from 'business/providers/theme/services/types';

import styles from './index.module.scss';
import { TypoAlign, TypoColor, TypoColorEnum, TypoSize } from './types';

// TODO palette is typed as any for now ... try improve it later
const getColor = (
  color: TypoColor | PaletteVarKey,
  palette: ThemePalette,
): string => {
  switch (color) {
    case TypoColorEnum.error:
      return palette.dangerMain;
    case TypoColorEnum.primary:
      return palette.primaryMain;
    case TypoColorEnum.secondary:
      return palette.secondaryMain;
    case TypoColorEnum.light:
      return palette.backgroundForm;
    case TypoColorEnum.neutralLight:
      return palette.textLight;
    default:
      if (color in palette) {
        return palette[color as PaletteVarKey];
      }
      return palette.text;
  }
};

export interface TypoProps extends PropsWithChildren {
  size?: TypoSize;
  color?: TypoColor | PaletteVarKey;
  textAlign?: TypoAlign;
  bold?: boolean;
  className?: string;
  component?: React.ElementType;
  noWrap?: boolean;
  ellipsis?: boolean;
  uppercase?: boolean;
  italic?: boolean;
}
export const Typo = ({
  className = '',
  children,
  color = 'neutral',
  size = 'm',
  bold,
  italic,
  ellipsis,
  uppercase,
  ...rest
}: TypoProps) => {
  const palette = usePalette();
  return (
    <Typography
      {...rest}
      fontWeight={bold ? 'bold' : undefined}
      className={classNames(styles[`_${size}`], className, {
        [styles.ellipsis]: ellipsis,
        [styles.uppercase]: uppercase,
        [styles.italic]: italic,
      })}
      sx={{
        color: getColor(color, palette),
      }}
    >
      {children}
    </Typography>
  );
};
