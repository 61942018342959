import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Stack } from '@mui/material';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { Button } from 'ui/button';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

export const VALIDATION_ITEM_TYPES = {
  BOOLEAN: 'BOOLEAN',
  TEXT: 'TEXT',
  LINK: 'LINK',
} as const;

type CheckboxItem = {
  type: typeof VALIDATION_ITEM_TYPES.BOOLEAN;
  value: boolean;
  label: string;
};

export type ValidationTextItem = {
  type: typeof VALIDATION_ITEM_TYPES.TEXT;
  value: string;
  label: string;
};

type LinkItem = {
  type: typeof VALIDATION_ITEM_TYPES.LINK;
  // PE3-1174 IMO this component is weird compared to the others
  // because I'm already burning I can't refacto it properly
  // my main complaint is that we weren't using it like the other 2 components
  // just look at the storybook to understank what I'm saying
  value: {
    label: string;
    onClick: () => void | Promise<void>;
  };
};

export type ValidationItemType = ValidationTextItem | CheckboxItem | LinkItem;

interface ValidationItemProps {
  item: ValidationItemType;
}
const TextItem = ({ label, value }: ValidationTextItem) => {
  return (
    <Stack className={styles.item}>
      <Typo size="sm" uppercase bold>
        {label}
      </Typo>
      <Typo className={styles.itemValue} size="sm">
        {value}
      </Typo>
    </Stack>
  );
};

const CheckboxItem = ({ label, value }: CheckboxItem) => {
  return value ? (
    <Stack className={styles.item} direction="row" alignItems="center" gap={1}>
      <Stack alignItems="center" justifyContent="center">
        <CheckBoxIcon fontSize="inherit" color="disabled" />
      </Stack>
      <Typo size="sm">{label}</Typo>
    </Stack>
  ) : null;
};

const LinkItem = ({ value: { onClick, label } }: LinkItem) => {
  const palette = usePalette();

  return (
    <Button
      key={`document_${label}`}
      className={styles.documentItem}
      onClick={onClick}
      size="small"
      sx={{
        color: palette.text,
        bgcolor: 'transparent',
      }}
    >
      {label}
    </Button>
  );
};

export const ValidationItem = ({ item }: ValidationItemProps) => {
  switch (item.type) {
    case VALIDATION_ITEM_TYPES.TEXT:
      return <TextItem {...item} />;
    case VALIDATION_ITEM_TYPES.BOOLEAN:
      return <CheckboxItem {...item} />;
    case VALIDATION_ITEM_TYPES.LINK:
      return <LinkItem {...item} />;
    default:
      return null;
  }
};
