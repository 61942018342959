import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { KYCDocumentation } from 'business/shared/lp-creation-module/components/kyc-documentation/kyc-documentation';
import { DeclineReason } from 'business/shared/lp-creation-module/components/lp-decline-reason/lp-decline-reason';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import {
  useCompleteLpProfileMutation,
  useGetLpKycDocumentsQuery,
  useLpDocTypeWithDocumentQuery,
  useUpdateLpDocumentationStatusToPendingValidationMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { FormCard } from 'ui/form/form-card';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

export const LpCreationKYCDocumentation = () => {
  const { lpId = '' } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isOnboardingUser = useIsLpOnboardingUser();

  const { onNextStep } = useDraftEditionStepsContext();

  const [completeLpProfileMutation] = useCompleteLpProfileMutation({
    onError: () => {
      enqueueSnackbar(t('errors.completeLpProfile'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.completeLpProfile'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onNextStep();
    },
  });

  const [updateLpDocumentStatusAndProgressToNextStep] =
    useUpdateLpDocumentationStatusToPendingValidationMutation({
      onCompleted: () =>
        completeLpProfileMutation({
          variables: {
            input: {
              id: lpId,
            },
          },
        }),
      onError: () => {
        enqueueSnackbar(t(ValidationErrors.GENERIC), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const { data, loading, error, refetch } = useGetLpKycDocumentsQuery({
    variables: {
      input: {
        lpId,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  const {
    data: lpDocumentTypes,
    loading: lpDocumentTypesLoading,
    error: lpDocumentTypeError,
    refetch: refetchDocumentsTypes,
  } = useLpDocTypeWithDocumentQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
    fetchPolicy: 'network-only', // ignore cache
  });

  const handleSubmit = isOnboardingUser
    ? () => {
        return updateLpDocumentStatusAndProgressToNextStep({
          variables: {
            input: {
              id: lpId,
            },
          },
        });
      }
    : onNextStep;

  if (
    loading ||
    lpDocumentTypesLoading ||
    error ||
    lpDocumentTypeError ||
    !data ||
    !lpDocumentTypes
  ) {
    return (
      <DraftEditionContainer>
        <FormCard className={styles.documentFormCard}>
          <QueryStateDisplay loading={loading} error={error} />
        </FormCard>
      </DraftEditionContainer>
    );
  }

  return (
    <DraftEditionContainer rawChildren onSubmit={handleSubmit}>
      <DeclineReason />
      <KYCDocumentation
        lpDocuments={data}
        loading={loading}
        lpId={lpId}
        lpDocumentTypes={lpDocumentTypes}
        refetchDocuments={refetch}
        refetchDocumentsTypes={refetchDocumentsTypes}
      />
    </DraftEditionContainer>
  );
};
