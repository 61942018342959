import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetSecurityLogQuery } from 'generated/graphql';
import { formatISO } from 'technical/date';

export function getSecurityLogColumnDefinition(
  t: TFunction,
  // customFilters: FundsFilteredFields,
): GridColDef<GetSecurityLogQuery['securityLog'][number]>[] {
  return [
    {
      field: 'email',
      headerName: t('pages.securityLog.table.email'),
      type: 'singleSelect',
      aggregable: false,
      filterable: false,
      valueGetter: ({ row }) => row?.user?.email,
      renderCell: ({ value }) => value ?? '',
    },
    {
      field: 'actionType',
      headerName: t('pages.securityLog.table.actionType'),
      type: 'singleSelect',
      filterable: false,
      valueGetter: ({ row }) => row?.actionType,
      renderCell: ({ value }) => (value ? value.toUpperCase() : ''),
    },
    {
      field: 'createdAt',
      headerName: t('pages.securityLog.table.createdAt'),
      groupable: false,
      aggregable: false,
      filterable: false,
      valueGetter: ({ row }) => row?.createdAt,
      renderCell: ({ value }) => (value ? formatISO(new Date(value)) : ''),
    },
    {
      field: 'data',
      headerName: t('pages.securityLog.table.data'),
      groupable: false,
      aggregable: false,
      filterable: false,
      valueGetter: ({ row }) => row?.data,
      renderCell: ({ value }) => (value ? JSON.stringify(value) : ''),
    },
  ];
}
