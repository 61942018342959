import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { LpCategorizationForm } from 'business/shared/lp-creation-module/components/lp-categorization-form';
import { LpKybKeyMetricsForm } from 'business/shared/lp-creation-module/components/lp-kyb-key-metrics-form';
import { LpKYB } from 'business/shared/lp-creation-module/services/types';
import { LpInvestorTypeEnum } from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import {
  FormInputText,
  FormSection,
  NaceCodeInput,
  SelectHeadcountInput,
  SelectLegalFormInput,
} from 'ui/form';

type KYBFormUIProps = {
  error?: ApolloError;
  saving: boolean;
  action?: ReactNode;
  country: string;
};

const LegalFormInput = ({ country }: { country: string }) => {
  const investorType = useWatch({ name: 'investorType' });

  return (
    <SelectLegalFormInput
      fullWidth
      country={country}
      name="legalForm"
      disabled={investorType !== LpInvestorTypeEnum.Private}
    />
  );
};

export const LpKYBFormUI = ({
  error,
  saving,
  action,
  country,
}: KYBFormUIProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<LpKYB>();
  const isOnboardingUser = useIsLpOnboardingUser();

  return (
    <>
      {error ? <ErrorLabel label={error.message} /> : null}
      {!isOnboardingUser ? (
        <>
          <FormSection
            title={t(
              'pages.fundManager.lpCreation.form.KYB.identification.title',
            )}
            action={action}
          >
            <FormInputText
              control={control}
              name="vatIdentificationNumber"
              label={t(
                'pages.fundManager.lpCreation.form.KYB.identification.vatIdentificationNumber.label',
              )}
            />
            <FormInputText
              control={control}
              name="lei"
              label={t('pages.fundManager.lpCreation.form.KYB.lei.label')}
            />
          </FormSection>

          <FormSection
            title={t(
              'pages.fundManager.lpCreation.form.KYC.categorization.title',
            )}
          >
            <LegalFormInput country={country} />
            <LpCategorizationForm control={control} saving={saving} />
            <Stack spacing={2}>
              <NaceCodeInput name="lpNaceCode" control={control} hideSector />

              <FormInputText
                name="corporatePurpose"
                control={control}
                label={t(
                  'pages.fundManager.lpCreation.form.KYB.categorization.corporatePurpose',
                )}
                fullWidth
                multiline
                minRows={3}
              />
            </Stack>
          </FormSection>

          <FormSection
            title={t('pages.fundManager.lpCreation.form.KYB.keyMetrics.title')}
          >
            <LpKybKeyMetricsForm control={control} />
          </FormSection>

          <FormSection
            lastOne
            title={t('pages.fundManager.lpCreation.form.KYB.headOffice.title')}
          >
            <Stack spacing={2}>
              <FormInputText
                name="headOfficeUniqueCompanyIdentifier"
                label={t(
                  'pages.fundManager.lpCreation.form.KYB.headOffice.uniqueCompanyIdentifier.label',
                )}
                control={control}
                fullWidth
              />
              <NaceCodeInput
                name="headOfficeNaceCode"
                control={control}
                hideSector
              />
              <SelectHeadcountInput name="headOfficeHeadcount" fullWidth />
            </Stack>
          </FormSection>
        </>
      ) : null}
    </>
  );
};
