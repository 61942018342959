import { useNavigate, useParams } from 'react-router';

import { CompanyCreationWizzard } from 'business/fund-manager/portfolio/company/components/company-creation-wizzard';
import { CompanyInvestementForm } from 'business/fund-manager/portfolio/company/components/company-investment-form';
import { useCompanyInvestmentForm } from 'business/fund-manager/portfolio/company/services/hooks/use-company-investment-form';
import { CompanyCreationStepEnum } from 'business/fund-manager/portfolio/company/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  CompanyInvestmentQuery,
  useCompanyInvestmentQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

interface CompanyCreationInvestmentsContentProps {
  fundId: string;
  companyId: string;
  data: NonNullable<CompanyInvestmentQuery['company']>;
}

const CompanyCreationInvestmentsContent = ({
  fundId,
  companyId,
  data,
}: CompanyCreationInvestmentsContentProps) => {
  const navigate = useNavigate();

  const { onSubmit, loading, error, control } = useCompanyInvestmentForm({
    company: data,
    onCompleted: () => {
      navigate(
        FundManagerRoutes.PortfolioFundIdCompanyCreateIdDocumentation.replace(
          ':fundId',
          fundId,
        ).replace(':companyId', data.id),
      );
    },
  });

  return (
    <CompanyCreationWizzard
      activeStep={CompanyCreationStepEnum.investments}
      onSubmit={!loading ? onSubmit : undefined}
      previousPath={FundManagerRoutes.PortfolioFundIdCompanyCreateId.replace(
        ':fundId',
        fundId,
      ).replace(':companyId', companyId)}
    >
      <CompanyInvestementForm control={control} error={error} />
    </CompanyCreationWizzard>
  );
};

export const CompanyCreationInvestments = () => {
  const { fundId = '', companyId = '' } = useParams();
  const { data, loading, error } = useCompanyInvestmentQuery({
    variables: { id: companyId },
  });

  if (loading || error || !data?.company) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <CompanyCreationInvestmentsContent
      fundId={fundId}
      companyId={companyId}
      data={data.company}
    />
  );
};
