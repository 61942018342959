import { GetLpSubscriptionsQuery } from 'generated/graphql';

export const getFundCount = (data: GetLpSubscriptionsQuery): number => {
  const fundIdsList = data.subscriptions.nodes.reduce(
    (uniqueFunds: string[], node) => {
      if (node.share?.fundId && !uniqueFunds.includes(node.share?.fundId)) {
        return [...uniqueFunds, node.share?.fundId];
      }
      return uniqueFunds;
    },
    [],
  );

  return fundIdsList.length;
};
