import { GetFundBankDetailsQuery } from 'generated/graphql';

import { FundBankDetails } from './types';

export const getBankFormValues = (
  bankDetails?: NonNullable<GetFundBankDetailsQuery['fundBankDetails'][0]>,
): FundBankDetails => {
  if (bankDetails) {
    return {
      name: bankDetails.name,
      bic: bankDetails.bic,
      iban: bankDetails.iban,
      contact: bankDetails.contact ?? '',
      address: bankDetails.address
        ? {
            // these field are mandatory in back :'(
            name: bankDetails.address.name ?? '',
            streetLine: bankDetails.address.streetLine ?? '',
            streetLine2: bankDetails.address.streetLine2 ?? '',
            city: bankDetails.address.city ?? '',
            zipCode: bankDetails.address.zipCode ?? '',
            country: bankDetails.address.country ?? '',
          }
        : undefined,
    };
  }

  return {
    name: '',
    bic: '',
    iban: '',
    contact: '',
    address: undefined,
  };
};
