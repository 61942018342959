import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { useFund } from 'business/providers/fund-provider/use-fund';
import { GetFundManagerDashBoardInfosQuery } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryInMillions,
} from 'technical/currency/formatters';
import { PieGraph } from 'ui/graph/pie-graph';
import { InfoCard } from 'ui/info-card';
import { Typo } from 'ui/typo';

interface Props {
  data: Pick<
    GetFundManagerDashBoardInfosQuery['getFundManagerDashBoardInfos'],
    'funds'
  >;
  title: string;
  path?: string;
}

export const FundGraphCard = ({
  data: {
    funds: { fund, count, totalAmount },
  },
  path,
  title,
}: Props) => {
  const { currency } = useFund();
  const { t } = useTranslation();
  const labels = fund.map(({ name }) => name);
  const values = fund.map(({ ratio }) => ratio);
  const text = t('pages.fundManager.dashboard.cards.funds.fundWithCount', {
    count,
  });

  return (
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>
      <Stack padding={3} boxSizing="border-box">
        <PieGraph
          labels={labels}
          values={values}
          text={text}
          fileName={t('pages.fundManager.dashboard.cards.funds.graph.fileName')}
        />
      </Stack>
      <InfoCard.Footer>
        <Stack flexDirection="row" justifyContent="center">
          <Typo>AUM:&nbsp;</Typo>
          <Typo bold>
            {formatToMonetaryInMillions(
              convertFrom10X8toNumber(totalAmount),
              currency,
            )}
          </Typo>
        </Stack>
      </InfoCard.Footer>
    </InfoCard>
  );
};
