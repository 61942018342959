export type Person = {
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

export enum PersonTitle {
  mr = 'mr',
  mrs = 'mrs',
}
