import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import { TemplateDynamicContent } from 'business/fund-manager/settings/template/components/template-dynamic-content';

import styles from './index.module.scss';

type Props = PropsWithChildren & {
  managementCompanyId: string;
};

export const TemplateFormWithDynamicContent = ({
  children,
  managementCompanyId,
}: Props) => {
  return (
    <Stack className={styles.stack} direction="row">
      <Stack className={styles.formContainer}>{children}</Stack>
      <TemplateDynamicContent managementCompanyId={managementCompanyId} />
    </Stack>
  );
};
