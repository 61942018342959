import { PropsWithChildren } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { FormCard } from 'ui/form';
import { LinkTabs } from 'ui/link-tabs';

export const CompanyEditionTabs = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { fundId = '', companyId = '' } = useParams();

  const tabs = [
    {
      to: FundManagerRoutes.PortfolioFundIdCompanyIdEditProfile.replace(
        ':fundId',
        fundId,
      ).replace(':companyId', companyId),
      label: t('pages.fundManager.portfolio.company.creation.profile'),
    },
    {
      to: FundManagerRoutes.PortfolioFundIdCompanyIdEditInvestment.replace(
        ':fundId',
        fundId,
      ).replace(':companyId', companyId),
      label: t('pages.fundManager.portfolio.company.investments.profile'),
    },
  ];

  const activeTab = /profile$/.test(pathname) ? 0 : 1;

  return (
    <LinkTabs activeTab={activeTab} tabs={tabs}>
      <FormCard>{children}</FormCard>
    </LinkTabs>
  );
};
