import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { getFundColumnDefinition } from 'business/fund-manager/fund/services/get-fund-column-definition';
import { getFundData } from 'business/fund-manager/fund/services/get-fund-data';
import { FundData } from 'business/fund-manager/fund/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  useGetFundsWithSubscriptionDetailsQuery,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { removeDuplicates } from 'technical/remove-duplicates';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import Spacer from 'ui/spacer';

export const FundList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading } = useGetFundsWithSubscriptionDetailsQuery({
    fetchPolicy: 'network-only',
  });

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const customFilters = data
    ? {
        fundNames: removeDuplicates(
          data.getFundsWithSubscriptionDetails,
          'name',
        ),
        vintageYears: removeDuplicates(
          data.getFundsWithSubscriptionDetails,
          'vintageYear',
        ),
      }
    : {
        fundNames: [],
        vintageYears: [],
      };

  const headers = getFundColumnDefinition(t, customFilters);

  const computedData = data
    ? getFundData({
        funds: data.getFundsWithSubscriptionDetails,
      })
    : [];

  return (
    <ConnectedPage title={t('pages.fundName.fund.title')}>
      <Stack spacing={2}>
        {!isAdvisor ? (
          <Spacer justify="end">
            <ActionButton
              variant="submit"
              data-test-id="add-fund-button"
              onClick={() => navigate(FundManagerRoutes.FundCreate)}
            >
              {t('pages.fundManager.fund.addFund')}
            </ActionButton>
          </Spacer>
        ) : null}
        <AlternativeTable<FundData>
          fileName="fund-list"
          rowData={computedData}
          columnDefs={headers}
          domLayout="autoHeight"
          displaySidebar
          loading={loading}
        />
      </Stack>
    </ConnectedPage>
  );
};
