import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { GetAdvisorFundDocumentTypeQuery } from 'generated/graphql';

export const getFundDocumentTypeColumnDefinition = (
  t: TFunction,
): ColDef<GetAdvisorFundDocumentTypeQuery['fundDocumentTypes'][number]>[] => {
  return [
    {
      field: 'name',
      headerName: t('pages.fundManager.settings.documentType.headers.name'),
      valueFormatter: ({ value }) =>
        t(`pages.fundManager.settings.documentType.values.${value}`, value),
    },
  ];
};
