import { Card as MuiCard, CardProps } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

import styles from './index.module.scss';

interface Props extends CardProps {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export function Card({ children, className, dataTestId, sx }: Props) {
  return (
    <MuiCard
      className={classnames(styles.card, className)}
      data-test-id={dataTestId}
      sx={sx}
    >
      {children}
    </MuiCard>
  );
}
