import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationInvestorsNavBarTabEnum } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import {
  ManagementCompanyType,
  useGetPendingValidationShareLpCashFlowCountQuery,
} from 'generated/graphql';
import { LinkTabs } from 'ui/link-tabs';

interface NavBarProps extends PropsWithChildren {
  activeTab: OperationInvestorsNavBarTabEnum;
}

// COUNT BADGE
const ChipCountPendingValidationCashFlows = ({
  operationShareLpId,
}: {
  operationShareLpId: string;
}) => {
  const { data, loading, error } =
    useGetPendingValidationShareLpCashFlowCountQuery({
      variables: {
        operationShareLpId,
      },
    });

  return (
    <PendingChipCount
      loading={loading}
      error={error}
      count={data?.pendingValidationShareLpCashFlowCount?.count}
    />
  );
};

export const OperationInvestorCashFlowNavBar = ({
  activeTab,
  children,
}: NavBarProps) => {
  const { operationId = '', operationShareLpId = '' } = useParams();
  const { t } = useTranslation();
  const isGeneralPartner = useManagementCompanyTypeGuard([
    ManagementCompanyType.GeneralPartner,
  ]);

  if (isGeneralPartner) {
    return <>{children}</>;
  }

  const tabs = [
    {
      to: FundManagerRoutes.OperationIdInvestorsOperationShareLpId.replace(
        ':operationId',
        operationId,
      ).replace(':operationShareLpId', operationShareLpId),
      label: t('pages.fundManager.subscription.tabs.validated'),
    },
    {
      to: FundManagerRoutes.OperationIdInvestorsOperationShareLpIdPendingValidation.replace(
        ':operationId',
        operationId,
      ).replace(':operationShareLpId', operationShareLpId),
      label: (
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>{t('pages.fundManager.subscription.tabs.toValidate')}</span>
          <ChipCountPendingValidationCashFlows
            operationShareLpId={operationShareLpId}
          />
        </Stack>
      ),
    },
  ];

  return (
    <LinkTabs activeTab={activeTab} tabs={tabs}>
      {children}
    </LinkTabs>
  );
};
