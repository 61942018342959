import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FormCard, FormWizzard } from 'ui/form';

type CompanyCreationWizzardProps = PropsWithChildren<{
  activeStep?: number;
  onSubmit?: () => void;
  previousPath: string;
  rawChildren?: boolean;
}>;

export const CompanyCreationWizzard = ({
  children,
  activeStep = 0,
  onSubmit,
  previousPath,
  rawChildren,
}: CompanyCreationWizzardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const steps = [
    {
      label: t('pages.fundManager.portfolio.company.creation.profile'),
    },
    {
      label: t('pages.fundManager.portfolio.company.investments.profile'),
    },
    {
      label: t('pages.fundManager.portfolio.company.documentation.title'),
    },
    {
      label: t('pages.fundManager.portfolio.company.contact.title'),
    },
  ];

  return (
    <>
      <FormWizzard
        steps={steps}
        activeStep={activeStep}
        onNext={onSubmit}
        onPrevious={() => navigate(previousPath)}
      />
      {rawChildren ? children : <FormCard>{children}</FormCard>}
    </>
  );
};
