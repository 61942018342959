import { SvgIcon, SvgIconProps } from '@mui/material';

export const FileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.34315 4.34315 2 6 2H14C17.866 2 21 5.13401 21 9V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5ZM13 3.99986H6C5.44772 3.99986 5 4.44758 5 4.99986V18.9999C5 19.5522 5.44772 19.9999 6 19.9999H18C18.5523 19.9999 19 19.5522 19 18.9999V8.99986H13V3.99986ZM18.584 6.99987C17.9413 5.52893 16.6113 4.42697 15 4.09989V6.99987H18.584ZM7 9.99993H11V7.99993H7V9.99993ZM7 14H17V12H7V14ZM17 17.9999H7V15.9999H17V17.9999Z"
      />
    </SvgIcon>
  );
};
