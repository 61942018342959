import { NaceValues } from './types';

export const NACE_CODES: Record<string, NaceValues> = {
  '01.11': {
    code: '01.11',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4:
        'Growing of cereals (except rice), leguminous crops and oil seeds',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4:
        "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
    },
  },
  '01.12': {
    code: '01.12',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: '',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of rice',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture du riz',
    },
  },
  '01.13': {
    code: '01.13',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of vegetables and melons, roots and tubers',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de légumes, de melons, de racines et de tubercules',
    },
  },
  '01.14': {
    code: '01.14',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of sugar cane',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de la canne à sucre',
    },
  },
  '01.15': {
    code: '01.15',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of tobacco',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture du tabac',
    },
  },
  '01.16': {
    code: '01.16',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of fibre crops',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de plantes à fibres',
    },
  },
  '01.19': {
    code: '01.19',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of other non-perennial crops',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Autres cultures non permanentes',
    },
  },
  '01.21': {
    code: '01.21',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of grapes',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de la vigne',
    },
  },
  '01.22': {
    code: '01.22',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of tropical and subtropical fruits',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de fruits tropicaux et subtropicaux',
    },
  },
  '01.23': {
    code: '01.23',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of citrus fruits',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: "Culture d'agrumes",
    },
  },
  '01.24': {
    code: '01.24',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of pome fruits and stone fruits',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de fruits à pépins et à noyau',
    },
  },
  '01.25': {
    code: '01.25',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of other tree and bush fruits and nuts',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4:
        "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
    },
  },
  '01.26': {
    code: '01.26',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of oleaginous fruits',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de fruits oléagineux',
    },
  },
  '01.27': {
    code: '01.27',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of beverage crops',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture de plantes à boissons',
    },
  },
  '01.28': {
    code: '01.28',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of spices, aromatic, drug and pharmaceutical crops',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4:
        'Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques',
    },
  },
  '01.29': {
    code: '01.29',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Growing of other perennial crops',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Autres cultures permanentes',
    },
  },
  '01.30': {
    code: '01.30',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Plant propagation',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Reproduction de plantes',
    },
  },
  '01.41': {
    code: '01.41',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of dairy cattle',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Élevage de vaches laitières',
    },
  },
  '01.42': {
    code: '01.42',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of other cattle and buffaloes',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: "Élevage d'autres bovins et de buffles",
    },
  },
  '01.43': {
    code: '01.43',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of horses and other equines',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: "Élevage de chevaux et d'autres équidés",
    },
  },
  '01.44': {
    code: '01.44',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of camels and camelids',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: "Élevage de chameaux et d'autres camélidés",
    },
  },
  '01.45': {
    code: '01.45',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of sheep and goats',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: "Élevage d'ovins et de caprins",
    },
  },
  '01.46': {
    code: '01.46',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of swine/pigs',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Élevage de porcins',
    },
  },
  '01.47': {
    code: '01.47',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of poultry',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Élevage de volailles',
    },
  },
  '01.49': {
    code: '01.49',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Raising of other animals',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: "Élevage d'autres animaux",
    },
  },
  '01.50': {
    code: '01.50',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Mixed farming',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Culture et élevage associés',
    },
  },
  '01.61': {
    code: '01.61',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Support activities for crop production',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Activités de soutien aux cultures',
    },
  },
  '01.62': {
    code: '01.62',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Support activities for animal production',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Activités de soutien à la production animale',
    },
  },
  '01.63': {
    code: '01.63',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Post-harvest crop activities',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Traitement primaire des récoltes',
    },
  },
  '01.64': {
    code: '01.64',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Seed processing for propagation',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Traitement des semences',
    },
  },
  '01.70': {
    code: '01.70',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2:
        'Crop and animal production, hunting and related service activities',
      level3: 'Growing of non-perennial crops',
      level4: 'Hunting, trapping and related service activities',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Culture et production animale, chasse et services annexes',
      level3: 'Cultures non permanentes',
      level4: 'Chasse, piégeage et services annexes',
    },
  },
  '02.10': {
    code: '02.10',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Forestry and logging',
      level3: 'Silviculture and other forestry activities',
      level4: 'Silviculture and other forestry activities',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Sylviculture et exploitation forestière',
      level3: 'Sylviculture et autres activités forestières',
      level4: 'Sylviculture et autres activités forestières',
    },
  },
  '02.20': {
    code: '02.20',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Forestry and logging',
      level3: 'Silviculture and other forestry activities',
      level4: 'Logging',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Sylviculture et exploitation forestière',
      level3: 'Sylviculture et autres activités forestières',
      level4: 'Exploitation forestière',
    },
  },
  '02.30': {
    code: '02.30',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Forestry and logging',
      level3: 'Silviculture and other forestry activities',
      level4: 'Gathering of wild growing non-wood products',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Sylviculture et exploitation forestière',
      level3: 'Sylviculture et autres activités forestières',
      level4:
        "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
    },
  },
  '02.40': {
    code: '02.40',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Forestry and logging',
      level3: 'Silviculture and other forestry activities',
      level4: 'Support services to forestry',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Sylviculture et exploitation forestière',
      level3: 'Sylviculture et autres activités forestières',
      level4: "Services de soutien à l'exploitation forestière",
    },
  },
  '03.11': {
    code: '03.11',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Fishing and aquaculture',
      level3: 'Fishing',
      level4: 'Marine fishing',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Pêche et aquaculture',
      level3: 'Pêche',
      level4: 'Pêche en mer',
    },
  },
  '03.12': {
    code: '03.12',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Fishing and aquaculture',
      level3: 'Fishing',
      level4: 'Freshwater fishing',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Pêche et aquaculture',
      level3: 'Pêche',
      level4: 'Pêche en eau douce',
    },
  },
  '03.21': {
    code: '03.21',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Fishing and aquaculture',
      level3: 'Fishing',
      level4: 'Marine aquaculture',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Pêche et aquaculture',
      level3: 'Pêche',
      level4: 'Aquaculture en mer',
    },
  },
  '03.22': {
    code: '03.22',
    en: {
      level1: 'Agriculture, forestry and fishing',
      level2: 'Fishing and aquaculture',
      level3: 'Fishing',
      level4: 'Freshwater aquaculture',
    },
    fr: {
      level1: 'Agriculture, sylviculture et pêche',
      level2: 'Pêche et aquaculture',
      level3: 'Pêche',
      level4: 'Aquaculture en eau douce',
    },
  },
  '05.10': {
    code: '05.10',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining of coal and lignite',
      level3: 'Mining of hard coal',
      level4: 'Mining of hard coal',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Extraction de houille et de lignite',
      level3: 'Extraction de houille',
      level4: 'Extraction de houille',
    },
  },
  '05.20': {
    code: '05.20',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining of coal and lignite',
      level3: 'Mining of hard coal',
      level4: 'Mining of lignite',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Extraction de houille et de lignite',
      level3: 'Extraction de houille',
      level4: 'Extraction de lignite',
    },
  },
  '06.10': {
    code: '06.10',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Extraction of crude petroleum and natural gas',
      level3: 'Extraction of crude petroleum',
      level4: 'Extraction of crude petroleum',
    },
    fr: {
      level1: 'Industries extractives',
      level2: "Extraction d'hydrocarbures",
      level3: 'Extraction de pétrole brut',
      level4: 'Extraction de pétrole brut',
    },
  },
  '06.20': {
    code: '06.20',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Extraction of crude petroleum and natural gas',
      level3: 'Extraction of crude petroleum',
      level4: 'Extraction of natural gas',
    },
    fr: {
      level1: 'Industries extractives',
      level2: "Extraction d'hydrocarbures",
      level3: 'Extraction de pétrole brut',
      level4: 'Extraction de gaz naturel',
    },
  },
  '07.10': {
    code: '07.10',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining of metal ores',
      level3: 'Mining of iron ores',
      level4: 'Mining of iron ores',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Extraction de minerais métalliques',
      level3: 'Extraction de minerais de fer',
      level4: 'Extraction de minerais de fer',
    },
  },
  '07.21': {
    code: '07.21',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining of metal ores',
      level3: 'Mining of iron ores',
      level4: 'Mining of uranium and thorium ores',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Extraction de minerais métalliques',
      level3: 'Extraction de minerais de fer',
      level4: "Extraction de minerais d'uranium et de thorium",
    },
  },
  '07.29': {
    code: '07.29',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining of metal ores',
      level3: 'Mining of iron ores',
      level4: 'Mining of other non-ferrous metal ores',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Extraction de minerais métalliques',
      level3: 'Extraction de minerais de fer',
      level4: "Extraction d'autres minerais de métaux non ferreux",
    },
  },
  '08.11': {
    code: '08.11',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Other mining and quarrying',
      level3: 'Quarrying of stone, sand and clay',
      level4:
        'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Autres industries extractives',
      level3: "Extraction de pierres, de sables et d'argiles",
      level4:
        "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
    },
  },
  '08.12': {
    code: '08.12',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Other mining and quarrying',
      level3: 'Quarrying of stone, sand and clay',
      level4: 'Operation of gravel and sand pits; mining of clays and kaolin',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Autres industries extractives',
      level3: "Extraction de pierres, de sables et d'argiles",
      level4:
        "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
    },
  },
  '08.91': {
    code: '08.91',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Other mining and quarrying',
      level3: 'Quarrying of stone, sand and clay',
      level4: 'Mining of chemical and fertiliser minerals',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Autres industries extractives',
      level3: "Extraction de pierres, de sables et d'argiles",
      level4: "Extraction des minéraux chimiques et d'engrais minéraux",
    },
  },
  '08.92': {
    code: '08.92',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Other mining and quarrying',
      level3: 'Quarrying of stone, sand and clay',
      level4: 'Extraction of peat',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Autres industries extractives',
      level3: "Extraction de pierres, de sables et d'argiles",
      level4: 'Extraction de tourbe',
    },
  },
  '08.93': {
    code: '08.93',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Other mining and quarrying',
      level3: 'Quarrying of stone, sand and clay',
      level4: 'Extraction of salt',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Autres industries extractives',
      level3: "Extraction de pierres, de sables et d'argiles",
      level4: 'Production de sel',
    },
  },
  '08.99': {
    code: '08.99',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Other mining and quarrying',
      level3: 'Quarrying of stone, sand and clay',
      level4: 'Other mining and quarrying n.e.c.',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Autres industries extractives',
      level3: "Extraction de pierres, de sables et d'argiles",
      level4: 'Autres activités extractives n.c.a.',
    },
  },
  '09.10': {
    code: '09.10',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining support service activities',
      level3: 'Support activities for petroleum and natural gas extraction',
      level4: 'Support activities for petroleum and natural gas extraction',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Services de soutien aux industries extractives',
      level3: "Activités de soutien à l'extraction d'hydrocarbures",
      level4: "Activités de soutien à l'extraction d'hydrocarbures",
    },
  },
  '09.90': {
    code: '09.90',
    en: {
      level1: 'Mining and quarrying',
      level2: 'Mining support service activities',
      level3: 'Support activities for petroleum and natural gas extraction',
      level4: 'Support activities for other mining and quarrying',
    },
    fr: {
      level1: 'Industries extractives',
      level2: 'Services de soutien aux industries extractives',
      level3: "Activités de soutien à l'extraction d'hydrocarbures",
      level4: 'Activités de soutien aux autres industries extractives',
    },
  },
  '10.11': {
    code: '10.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Processing and preserving of meat',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Transformation et conservation de la viande de boucherie',
    },
  },
  '10.12': {
    code: '10.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Processing and preserving of poultry meat',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Transformation et conservation de la viande de volaille',
    },
  },
  '10.13': {
    code: '10.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Production of meat and poultry meat products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Préparation de produits à base de viande',
    },
  },
  '10.20': {
    code: '10.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Processing and preserving of fish, crustaceans and molluscs',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4:
        'Transformation et conservation de poisson, de crustacés et de mollusques',
    },
  },
  '10.31': {
    code: '10.31',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Processing and preserving of potatoes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Transformation et conservation de pommes de terre',
    },
  },
  '10.32': {
    code: '10.32',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of fruit and vegetable juice',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Préparation de jus de fruits et légumes',
    },
  },
  '10.39': {
    code: '10.39',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Other processing and preserving of fruit and vegetables',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Autre transformation et conservation de fruits et légumes',
    },
  },
  '10.41': {
    code: '10.41',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of oils and fats',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: "Fabrication d'huiles et graisses",
    },
  },
  '10.42': {
    code: '10.42',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of margarine and similar edible fats',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de margarine et graisses comestibles similaires',
    },
  },
  '10.51': {
    code: '10.51',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Operation of dairies and cheese making',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Exploitation de laiteries et fabrication de fromage',
    },
  },
  '10.52': {
    code: '10.52',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of ice cream',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de glaces et sorbets',
    },
  },
  '10.61': {
    code: '10.61',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of grain mill products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Travail des grains',
    },
  },
  '10.62': {
    code: '10.62',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of starches and starch products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de produits amylacés',
    },
  },
  '10.71': {
    code: '10.71',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4:
        'Manufacture of bread; manufacture of fresh pastry goods and cakes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de pain et de pâtisserie fraîche',
    },
  },
  '10.72': {
    code: '10.72',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4:
        'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4:
        'Fabrication de biscuits, biscottes et pâtisseries de conservation',
    },
  },
  '10.73': {
    code: '10.73',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4:
        'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de pâtes alimentaires',
    },
  },
  '10.81': {
    code: '10.81',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of sugar',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de sucre',
    },
  },
  '10.82': {
    code: '10.82',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of cocoa, chocolate and sugar confectionery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de cacao, chocolat et de produits de confiserie',
    },
  },
  '10.83': {
    code: '10.83',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Processing of tea and coffee',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Transformation du thé et du café',
    },
  },
  '10.84': {
    code: '10.84',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of condiments and seasonings',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de condiments et assaisonnements',
    },
  },
  '10.85': {
    code: '10.85',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of prepared meals and dishes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: 'Fabrication de plats préparés',
    },
  },
  '10.86': {
    code: '10.86',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of homogenised food preparations and dietetic food',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: "Fabrication d'aliments homogénéisés et diététiques",
    },
  },
  '10.89': {
    code: '10.89',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of other food products n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: "Fabrication d'autres produits alimentaires n.c.a.",
    },
  },
  '10.91': {
    code: '10.91',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of prepared feeds for farm animals',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: "Fabrication d'aliments pour animaux de ferme",
    },
  },
  '10.92': {
    code: '10.92',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of food products',
      level3:
        'Processing and preserving of meat and production of meat products',
      level4: 'Manufacture of prepared pet foods',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industries alimentaires',
      level3:
        'Transformation et conservation de la viande et préparation de produits à base de viande',
      level4: "Fabrication d'aliments pour animaux de compagnie",
    },
  },
  '11.01': {
    code: '11.01',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4: 'Distilling, rectifying and blending of spirits',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4: 'Production de boissons alcooliques distillées',
    },
  },
  '11.02': {
    code: '11.02',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4: 'Manufacture of wine from grape',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4: 'Production de vin (de raisin)',
    },
  },
  '11.03': {
    code: '11.03',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4: 'Manufacture of cider and other fruit wines',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4: 'Fabrication de cidre et de vins de fruits',
    },
  },
  '11.04': {
    code: '11.04',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4: 'Manufacture of other non-distilled fermented beverages',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4: "Production d'autres boissons fermentées non distillées",
    },
  },
  '11.05': {
    code: '11.05',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4: 'Manufacture of beer',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4: 'Fabrication de bière',
    },
  },
  '11.06': {
    code: '11.06',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4: 'Manufacture of malt',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4: 'Fabrication de malt',
    },
  },
  '11.07': {
    code: '11.07',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of beverages',
      level3: 'Manufacture of beverages',
      level4:
        'Manufacture of soft drinks; production of mineral waters and other bottled waters',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de boissons',
      level3: 'Fabrication de boissons',
      level4:
        'Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes',
    },
  },
  '12.00': {
    code: '12.00',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of tobacco products',
      level3: 'Manufacture of tobacco products',
      level4: 'Manufacture of tobacco products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits à base de tabac',
      level3: 'Fabrication de produits à base de tabac',
      level4: 'Fabrication de produits à base de tabac',
    },
  },
  '13.10': {
    code: '13.10',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Preparation and spinning of textile fibres',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: 'Préparation de fibres textiles et filature',
    },
  },
  '13.20': {
    code: '13.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Weaving of textiles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: 'Tissage',
    },
  },
  '13.30': {
    code: '13.30',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Finishing of textiles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: 'Ennoblissement textile',
    },
  },
  '13.91': {
    code: '13.91',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Manufacture of knitted and crocheted fabrics',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: "Fabrication d'étoffes à mailles",
    },
  },
  '13.92': {
    code: '13.92',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Manufacture of made-up textile articles, except apparel',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: "Fabrication d'articles textiles, sauf habillement",
    },
  },
  '13.93': {
    code: '13.93',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Manufacture of carpets and rugs',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: 'Fabrication de tapis et moquettes',
    },
  },
  '13.94': {
    code: '13.94',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Manufacture of cordage, rope, twine and netting',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: 'Fabrication de ficelles, cordes et filets',
    },
  },
  '13.95': {
    code: '13.95',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4:
        'Manufacture of non-wovens and articles made from non-wovens, except apparel',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: 'Fabrication de non-tissés, sauf habillement',
    },
  },
  '13.96': {
    code: '13.96',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Manufacture of other technical and industrial textiles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: "Fabrication d'autres textiles techniques et industriels",
    },
  },
  '13.99': {
    code: '13.99',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of textiles',
      level3: 'Preparation and spinning of textile fibres',
      level4: 'Manufacture of other textiles n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de textiles',
      level3: 'Préparation de fibres textiles et filature',
      level4: "Fabrication d'autres textiles n.c.a.",
    },
  },
  '14.11': {
    code: '14.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of leather clothes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: 'Fabrication de vêtements en cuir',
    },
  },
  '14.12': {
    code: '14.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of workwear',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: 'Fabrication de vêtements de travail',
    },
  },
  '14.13': {
    code: '14.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of other outerwear',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: 'Fabrication de vêtements de dessus',
    },
  },
  '14.14': {
    code: '14.14',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of underwear',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: 'Fabrication de vêtements de dessous',
    },
  },
  '14.19': {
    code: '14.19',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of other wearing apparel and accessories',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: "Fabrication d'autres vêtements et accessoires",
    },
  },
  '14.20': {
    code: '14.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of articles of fur',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: "Fabrication d'articles en fourrure",
    },
  },
  '14.31': {
    code: '14.31',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of knitted and crocheted hosiery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: "Fabrication d'articles chaussants à mailles",
    },
  },
  '14.39': {
    code: '14.39',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of wearing apparel',
      level3: 'Manufacture of wearing apparel, except fur apparel',
      level4: 'Manufacture of other knitted and crocheted apparel',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Industrie de l'habillement",
      level3: "Fabrication de vêtements, autres qu'en fourrure",
      level4: "Fabrication d'autres articles à mailles",
    },
  },
  '15.11': {
    code: '15.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of leather and related products',
      level3:
        'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
      level4: 'Tanning and dressing of leather; dressing and dyeing of fur',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du cuir et de la chaussure',
      level3:
        "Apprêt et tannage des cuirs; préparation et teinture des fourrures; fabrication d'articles de voyage, de maroquinerie et de sellerie",
      level4:
        'Apprêt et tannage des cuirs; préparation et teinture des fourrures',
    },
  },
  '15.12': {
    code: '15.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of leather and related products',
      level3:
        'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
      level4:
        'Manufacture of luggage, handbags and the like, saddlery and harness',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du cuir et de la chaussure',
      level3:
        "Apprêt et tannage des cuirs; préparation et teinture des fourrures; fabrication d'articles de voyage, de maroquinerie et de sellerie",
      level4:
        "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
    },
  },
  '15.20': {
    code: '15.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of leather and related products',
      level3:
        'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
      level4: 'Manufacture of footwear',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du cuir et de la chaussure',
      level3:
        "Apprêt et tannage des cuirs; préparation et teinture des fourrures; fabrication d'articles de voyage, de maroquinerie et de sellerie",
      level4: 'Fabrication de chaussures',
    },
  },
  '16.10': {
    code: '16.10',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      level3: 'Sawmilling and planing of wood',
      level4: 'Sawmilling and planing of wood',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles; fabrication d’articles en vannerie et sparterie",
      level3: 'Sciage et rabotage du bois',
      level4: 'Sciage et rabotage du bois',
    },
  },
  '16.21': {
    code: '16.21',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      level3: 'Sawmilling and planing of wood',
      level4: 'Manufacture of veneer sheets and wood-based panels',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles; fabrication d’articles en vannerie et sparterie",
      level3: 'Sciage et rabotage du bois',
      level4: 'Fabrication de placage et de panneaux de bois',
    },
  },
  '16.22': {
    code: '16.22',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      level3: 'Sawmilling and planing of wood',
      level4: 'Manufacture of assembled parquet floors',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles; fabrication d’articles en vannerie et sparterie",
      level3: 'Sciage et rabotage du bois',
      level4: 'Fabrication de parquets assemblés',
    },
  },
  '16.23': {
    code: '16.23',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      level3: 'Sawmilling and planing of wood',
      level4: "Manufacture of other builders' carpentry and joinery",
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles; fabrication d’articles en vannerie et sparterie",
      level3: 'Sciage et rabotage du bois',
      level4: "Fabrication de charpentes et d'autres menuiseries",
    },
  },
  '16.24': {
    code: '16.24',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      level3: 'Sawmilling and planing of wood',
      level4: 'Manufacture of wooden containers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles; fabrication d’articles en vannerie et sparterie",
      level3: 'Sciage et rabotage du bois',
      level4: "Fabrication d'emballages en bois",
    },
  },
  '16.29': {
    code: '16.29',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      level3: 'Sawmilling and planing of wood',
      level4:
        'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles; fabrication d’articles en vannerie et sparterie",
      level3: 'Sciage et rabotage du bois',
      level4:
        "Fabrication d'objets divers en bois; fabrication d'objets en liège, vannerie et sparterie",
    },
  },
  '17.11': {
    code: '17.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4: 'Manufacture of pulp',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4: 'Fabrication de pâte à papier',
    },
  },
  '17.12': {
    code: '17.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4: 'Manufacture of paper and paperboard',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4: 'Fabrication de papier et de carton',
    },
  },
  '17.21': {
    code: '17.21',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4:
        'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4:
        "Fabrication de papier et carton ondulés et d'emballages en papier ou en carton",
    },
  },
  '17.22': {
    code: '17.22',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4:
        'Manufacture of household and sanitary goods and of toilet requisites',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4:
        "Fabrication d'articles en papier à usage sanitaire ou domestique",
    },
  },
  '17.23': {
    code: '17.23',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4: 'Manufacture of paper stationery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4: "Fabrication d'articles de papeterie",
    },
  },
  '17.24': {
    code: '17.24',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4: 'Manufacture of wallpaper',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4: 'Fabrication de papiers peints',
    },
  },
  '17.29': {
    code: '17.29',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of paper and paper products',
      level3: 'Manufacture of pulp, paper and paperboard',
      level4: 'Manufacture of other articles of paper and paperboard',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie du papier et du carton',
      level3: 'Fabrication de pâte à papier, de papier et de carton',
      level4: "Fabrication d'autres articles en papier ou en carton",
    },
  },
  '18.11': {
    code: '18.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Printing and reproduction of recorded media',
      level3: 'Printing and service activities related to printing',
      level4: 'Printing of newspapers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Imprimerie et reproduction d'enregistrements",
      level3: 'Imprimerie et services annexes',
      level4: 'Imprimerie de journaux',
    },
  },
  '18.12': {
    code: '18.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Printing and reproduction of recorded media',
      level3: 'Printing and service activities related to printing',
      level4: 'Other printing',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Imprimerie et reproduction d'enregistrements",
      level3: 'Imprimerie et services annexes',
      level4: 'Autre imprimerie (labeur)',
    },
  },
  '18.13': {
    code: '18.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Printing and reproduction of recorded media',
      level3: 'Printing and service activities related to printing',
      level4: 'Pre-press and pre-media services',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Imprimerie et reproduction d'enregistrements",
      level3: 'Imprimerie et services annexes',
      level4: 'Activités de prépresse',
    },
  },
  '18.14': {
    code: '18.14',
    en: {
      level1: 'Manufacturing',
      level2: 'Printing and reproduction of recorded media',
      level3: 'Printing and service activities related to printing',
      level4: 'Binding and related services',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Imprimerie et reproduction d'enregistrements",
      level3: 'Imprimerie et services annexes',
      level4: 'Reliure et activités connexes',
    },
  },
  '18.20': {
    code: '18.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Printing and reproduction of recorded media',
      level3: 'Printing and service activities related to printing',
      level4: 'Reproduction of recorded media',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Imprimerie et reproduction d'enregistrements",
      level3: 'Imprimerie et services annexes',
      level4: "Reproduction d'enregistrements",
    },
  },
  '19.10': {
    code: '19.10',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of coke and refined petroleum products',
      level3: 'Manufacture of coke oven products',
      level4: 'Manufacture of coke oven products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Cokéfaction et raffinage',
      level3: 'Cokéfaction',
      level4: 'Cokéfaction',
    },
  },
  '19.20': {
    code: '19.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of coke and refined petroleum products',
      level3: 'Manufacture of coke oven products',
      level4: 'Manufacture of refined petroleum products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Cokéfaction et raffinage',
      level3: 'Cokéfaction',
      level4: 'Raffinage du pétrole',
    },
  },
  '20.11': {
    code: '20.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of industrial gases',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de gaz industriels',
    },
  },
  '20.12': {
    code: '20.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of dyes and pigments',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de colorants et de pigments',
    },
  },
  '20.13': {
    code: '20.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of other inorganic basic chemicals',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication d'autres produits chimiques inorganiques de base",
    },
  },
  '20.14': {
    code: '20.14',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of other organic basic chemicals',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication d'autres produits chimiques organiques de base",
    },
  },
  '20.15': {
    code: '20.15',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of fertilisers and nitrogen compounds',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication de produits azotés et d'engrais",
    },
  },
  '20.16': {
    code: '20.16',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of plastics in primary forms',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de matières plastiques de base',
    },
  },
  '20.17': {
    code: '20.17',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of synthetic rubber in primary forms',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de caoutchouc synthétique',
    },
  },
  '20.20': {
    code: '20.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of pesticides and other agrochemical products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication de pesticides et d'autres produits agrochimiques",
    },
  },
  '20.30': {
    code: '20.30',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4:
        'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de peintures, vernis, encres et mastics',
    },
  },
  '20.41': {
    code: '20.41',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4:
        'Manufacture of soap and detergents, cleaning and polishing preparations',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication de savons, détergents et produits d'entretien",
    },
  },
  '20.42': {
    code: '20.42',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of perfumes and toilet preparations',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de parfums et de produits pour la toilette',
    },
  },
  '20.51': {
    code: '20.51',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of explosives',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de produits explosifs',
    },
  },
  '20.52': {
    code: '20.52',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of glues',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de colles',
    },
  },
  '20.53': {
    code: '20.53',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of essential oils',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication d'huiles essentielles",
    },
  },
  '20.59': {
    code: '20.59',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of other chemical products n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: "Fabrication d'autres produits chimiques n.c.a.",
    },
  },
  '20.60': {
    code: '20.60',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of chemicals and chemical products',
      level3:
        'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      level4: 'Manufacture of man-made fibres',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie chimique',
      level3:
        "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
      level4: 'Fabrication de fibres artificielles ou synthétiques',
    },
  },
  '21.10': {
    code: '21.10',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
      level3: 'Manufacture of basic pharmaceutical products',
      level4: 'Manufacture of basic pharmaceutical products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie pharmaceutique',
      level3: 'Fabrication de produits pharmaceutiques de base',
      level4: 'Fabrication de produits pharmaceutiques de base',
    },
  },
  '21.20': {
    code: '21.20',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
      level3: 'Manufacture of basic pharmaceutical products',
      level4: 'Manufacture of pharmaceutical preparations',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie pharmaceutique',
      level3: 'Fabrication de produits pharmaceutiques de base',
      level4: 'Fabrication de préparations pharmaceutiques',
    },
  },
  '22.11': {
    code: '22.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of rubber and plastic products',
      level3: 'Manufacture of rubber products',
      level4:
        'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits en caoutchouc et en plastique',
      level3: 'Fabrication de produits en caoutchouc',
      level4: 'Fabrication et rechapage de pneumatiques',
    },
  },
  '22.19': {
    code: '22.19',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of rubber and plastic products',
      level3: 'Manufacture of rubber products',
      level4: 'Manufacture of other rubber products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits en caoutchouc et en plastique',
      level3: 'Fabrication de produits en caoutchouc',
      level4: "Fabrication d'autres articles en caoutchouc",
    },
  },
  '22.21': {
    code: '22.21',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of rubber and plastic products',
      level3: 'Manufacture of rubber products',
      level4: 'Manufacture of plastic plates, sheets, tubes and profiles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits en caoutchouc et en plastique',
      level3: 'Fabrication de produits en caoutchouc',
      level4:
        'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques',
    },
  },
  '22.22': {
    code: '22.22',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of rubber and plastic products',
      level3: 'Manufacture of rubber products',
      level4: 'Manufacture of plastic packing goods',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits en caoutchouc et en plastique',
      level3: 'Fabrication de produits en caoutchouc',
      level4: "Fabrication d'emballages en matières plastiques",
    },
  },
  '22.23': {
    code: '22.23',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of rubber and plastic products',
      level3: 'Manufacture of rubber products',
      level4: 'Manufacture of builders’ ware of plastic',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits en caoutchouc et en plastique',
      level3: 'Fabrication de produits en caoutchouc',
      level4:
        "Fabrication d'éléments en matières plastiques pour la construction",
    },
  },
  '22.29': {
    code: '22.29',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of rubber and plastic products',
      level3: 'Manufacture of rubber products',
      level4: 'Manufacture of other plastic products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de produits en caoutchouc et en plastique',
      level3: 'Fabrication de produits en caoutchouc',
      level4: "Fabrication d'autres articles en matières plastiques",
    },
  },
  '23.11': {
    code: '23.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of flat glass',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de verre plat',
    },
  },
  '23.12': {
    code: '23.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Shaping and processing of flat glass',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Façonnage et transformation du verre plat',
    },
  },
  '23.13': {
    code: '23.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of hollow glass',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de verre creux',
    },
  },
  '23.14': {
    code: '23.14',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of glass fibres',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de fibres de verre',
    },
  },
  '23.19': {
    code: '23.19',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4:
        'Manufacture and processing of other glass, including technical glassware',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4:
        "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
    },
  },
  '23.20': {
    code: '23.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of refractory products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de produits réfractaires',
    },
  },
  '23.31': {
    code: '23.31',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of ceramic tiles and flags',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de carreaux en céramique',
    },
  },
  '23.32': {
    code: '23.32',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4:
        'Manufacture of bricks, tiles and construction products, in baked clay',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4:
        'Fabrication de briques, tuiles et produits de construction, en terre cuite',
    },
  },
  '23.41': {
    code: '23.41',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of ceramic household and ornamental articles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4:
        "Fabrication d'articles céramiques à usage domestique ou ornemental",
    },
  },
  '23.42': {
    code: '23.42',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of ceramic sanitary fixtures',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'appareils sanitaires en céramique",
    },
  },
  '23.43': {
    code: '23.43',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of ceramic insulators and insulating fittings',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'isolateurs et pièces isolantes en céramique",
    },
  },
  '23.44': {
    code: '23.44',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of other technical ceramic products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'autres produits céramiques à usage technique",
    },
  },
  '23.49': {
    code: '23.49',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of other ceramic products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'autres produits céramiques",
    },
  },
  '23.51': {
    code: '23.51',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of cement',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de ciment',
    },
  },
  '23.52': {
    code: '23.52',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of lime and plaster',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de chaux et plâtre',
    },
  },
  '23.61': {
    code: '23.61',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of concrete products for construction purposes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'éléments en béton pour la construction",
    },
  },
  '23.62': {
    code: '23.62',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of plaster products for construction purposes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'éléments en plâtre pour la construction",
    },
  },
  '23.63': {
    code: '23.63',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of ready-mixed concrete',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication de béton prêt à l'emploi",
    },
  },
  '23.64': {
    code: '23.64',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of mortars',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de mortiers et bétons secs',
    },
  },
  '23.65': {
    code: '23.65',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of fibre cement',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'ouvrages en fibre ciment",
    },
  },
  '23.69': {
    code: '23.69',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of other articles of concrete, plaster and cement',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
    },
  },
  '23.70': {
    code: '23.70',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Cutting, shaping and finishing of stone',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Taille, façonnage et finissage de pierres',
    },
  },
  '23.91': {
    code: '23.91',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Production of abrasive products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: 'Fabrication de produits abrasifs',
    },
  },
  '23.99': {
    code: '23.99',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other non-metallic mineral products',
      level3: 'Manufacture of glass and glass products',
      level4: 'Manufacture of other non-metallic mineral products n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres produits minéraux non métalliques",
      level3: "Fabrication de verre et d'articles en verre",
      level4: "Fabrication d'autres produits minéraux non métalliques n.c.a.",
    },
  },
  '24.10': {
    code: '24.10',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Manufacture of basic iron and steel and of ferro-alloys',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Sidérurgie',
    },
  },
  '24.20': {
    code: '24.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4:
        'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4:
        'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier ',
    },
  },
  '24.31': {
    code: '24.31',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Cold drawing of bars',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Étirage à froid de barres',
    },
  },
  '24.32': {
    code: '24.32',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Cold rolling of narrow strip',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Laminage à froid de feuillards',
    },
  },
  '24.33': {
    code: '24.33',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Cold forming or folding',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Profilage à froid par formage ou pliage',
    },
  },
  '24.34': {
    code: '24.34',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Cold drawing of wire',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Tréfilage à froid',
    },
  },
  '24.41': {
    code: '24.41',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Precious metals production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Production de métaux précieux',
    },
  },
  '24.42': {
    code: '24.42',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Aluminium production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: "Métallurgie de l'aluminium",
    },
  },
  '24.43': {
    code: '24.43',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Lead, zinc and tin production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: "Métallurgie du plomb, du zinc ou de l'étain",
    },
  },
  '24.44': {
    code: '24.44',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Copper production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Métallurgie du cuivre',
    },
  },
  '24.45': {
    code: '24.45',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Other non-ferrous metal production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Métallurgie des autres métaux non ferreux',
    },
  },
  '24.46': {
    code: '24.46',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Processing of nuclear fuel',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Élaboration et transformation de matières nucléaires',
    },
  },
  '24.51': {
    code: '24.51',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Casting of iron',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Fonderie de fonte',
    },
  },
  '24.52': {
    code: '24.52',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Casting of steel',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: "Fonderie d'acier",
    },
  },
  '24.53': {
    code: '24.53',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Casting of light metals',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: 'Fonderie de métaux légers',
    },
  },
  '24.54': {
    code: '24.54',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of basic metals',
      level3: 'Manufacture of basic iron and steel and of ferro-alloys',
      level4: 'Casting of other non-ferrous metals',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Métallurgie',
      level3: 'Sidérurgie',
      level4: "Fonderie d'autres métaux non ferreux",
    },
  },
  '25.11': {
    code: '25.11',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of metal structures and parts of structures',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4:
        'Fabrication de structures métalliques et de parties de structures',
    },
  },
  '25.12': {
    code: '25.12',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of doors and windows of metal',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Fabrication de portes et fenêtres en métal',
    },
  },
  '25.21': {
    code: '25.21',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of central heating radiators and boilers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4:
        'Fabrication de radiateurs et de chaudières pour le chauffage central',
    },
  },
  '25.29': {
    code: '25.29',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of other tanks, reservoirs and containers of metal',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4:
        "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
    },
  },
  '25.30': {
    code: '25.30',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4:
        'Manufacture of steam generators, except central heating hot water boilers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4:
        "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
    },
  },
  '25.40': {
    code: '25.40',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of weapons and ammunition',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: "Fabrication d'armes et de munitions",
    },
  },
  '25.50': {
    code: '25.50',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4:
        'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Forge, emboutissage, estampage; métallurgie des poudres',
    },
  },
  '25.61': {
    code: '25.61',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Treatment and coating of metals',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Traitement et revêtement des métaux',
    },
  },
  '25.62': {
    code: '25.62',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Machining',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Usinage',
    },
  },
  '25.71': {
    code: '25.71',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of cutlery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Fabrication de coutellerie',
    },
  },
  '25.72': {
    code: '25.72',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of locks and hinges',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Fabrication de serrures et de ferrures',
    },
  },
  '25.73': {
    code: '25.73',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of tools',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: "Fabrication d'outillage",
    },
  },
  '25.91': {
    code: '25.91',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of steel drums and similar containers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Fabrication de fûts et emballages métalliques similaires',
    },
  },
  '25.92': {
    code: '25.92',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of light metal packaging',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: "Fabrication d'emballages métalliques légers",
    },
  },
  '25.93': {
    code: '25.93',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of wire products, chain and springs',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4:
        "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
    },
  },
  '25.94': {
    code: '25.94',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of fasteners and screw machine products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: 'Fabrication de vis et de boulons',
    },
  },
  '25.99': {
    code: '25.99',
    en: {
      level1: 'Manufacturing',
      level2:
        'Manufacture of fabricated metal products, except machinery and equipment',
      level3: 'Manufacture of structural metal products',
      level4: 'Manufacture of other fabricated metal products n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits métalliques, à l’exception des machines et des équipements',
      level3: "Fabrication d'éléments en métal pour la construction",
      level4: "Fabrication d'autres produits métalliques n.c.a.",
    },
  },
  '26.11': {
    code: '26.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of electronic components',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: 'Fabrication de composants électroniques',
    },
  },
  '26.12': {
    code: '26.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of loaded electronic boards',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: 'Fabrication de cartes électroniques assemblées',
    },
  },
  '26.20': {
    code: '26.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of computers and peripheral equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: "Fabrication d'ordinateurs et d'équipements périphériques",
    },
  },
  '26.30': {
    code: '26.30',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of communication equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: "Fabrication d'équipements de communication",
    },
  },
  '26.40': {
    code: '26.40',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of consumer electronics',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: 'Fabrication de produits électroniques grand public',
    },
  },
  '26.51': {
    code: '26.51',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4:
        'Manufacture of instruments and appliances for measuring, testing and navigation',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4:
        "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation",
    },
  },
  '26.52': {
    code: '26.52',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of watches and clocks',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: 'Horlogerie',
    },
  },
  '26.60': {
    code: '26.60',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4:
        'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4:
        "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques ",
    },
  },
  '26.70': {
    code: '26.70',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of optical instruments and photographic equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: 'Fabrication de matériels optique et photographique',
    },
  },
  '26.80': {
    code: '26.80',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of computer, electronic and optical products',
      level3: 'Manufacture of electronic components and boards',
      level4: 'Manufacture of magnetic and optical media',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2:
        'Fabrication de produits informatiques, électroniques et optiques',
      level3: 'Fabrication de composants et cartes électroniques',
      level4: 'Fabrication de supports magnétiques et optiques',
    },
  },
  '27.11': {
    code: '27.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of electric motors, generators and transformers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4:
        'Fabrication de moteurs, génératrices et transformateurs électriques',
    },
  },
  '27.12': {
    code: '27.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of electricity distribution and control apparatus',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4:
        'Fabrication de matériel de distribution et de commande électrique',
    },
  },
  '27.20': {
    code: '27.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of batteries and accumulators',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: "Fabrication de piles et d'accumulateurs électriques",
    },
  },
  '27.31': {
    code: '27.31',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of fibre optic cables',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: 'Fabrication de câbles de fibres optiques',
    },
  },
  '27.32': {
    code: '27.32',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of other electronic and electric wires and cables',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4:
        "Fabrication d'autres fils et câbles électroniques ou électriques",
    },
  },
  '27.33': {
    code: '27.33',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of wiring devices',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: "Fabrication de matériel d'installation électrique",
    },
  },
  '27.40': {
    code: '27.40',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of electric lighting equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: "Fabrication d'appareils d'éclairage électrique",
    },
  },
  '27.51': {
    code: '27.51',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of electric domestic appliances',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: "Fabrication d'appareils électroménagers",
    },
  },
  '27.52': {
    code: '27.52',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of non-electric domestic appliances',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: "Fabrication d'appareils ménagers non électriques",
    },
  },
  '27.90': {
    code: '27.90',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of electrical equipment',
      level3:
        'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      level4: 'Manufacture of other electrical equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'équipements électriques",
      level3:
        'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
      level4: "Fabrication d'autres matériels électriques",
    },
  },
  '28.11': {
    code: '28.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4:
        'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4:
        "Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules",
    },
  },
  '28.12': {
    code: '28.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of fluid power equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication d'équipements hydrauliques et pneumatiques",
    },
  },
  '28.13': {
    code: '28.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of other pumps and compressors',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication d'autres pompes et compresseurs",
    },
  },
  '28.14': {
    code: '28.14',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of other taps and valves',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication d'autres articles de robinetterie",
    },
  },
  '28.15': {
    code: '28.15',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of bearings, gears, gearing and driving elements',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4:
        "Fabrication d'engrenages et d'organes mécaniques de transmission",
    },
  },
  '28.21': {
    code: '28.21',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of ovens, furnaces and furnace burners',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: 'Fabrication de fours et brûleurs',
    },
  },
  '28.22': {
    code: '28.22',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of lifting and handling equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: 'Fabrication de matériel de levage et de manutention',
    },
  },
  '28.23': {
    code: '28.23',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4:
        'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4:
        "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
    },
  },
  '28.24': {
    code: '28.24',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of power-driven hand tools',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication d'outillage portatif à moteur incorporé",
    },
  },
  '28.25': {
    code: '28.25',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of non-domestic cooling and ventilation equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4:
        "Fabrication d'équipements aérauliques et frigorifiques industriels",
    },
  },
  '28.29': {
    code: '28.29',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of other general-purpose machinery n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication de machines diverses d'usage général",
    },
  },
  '28.30': {
    code: '28.30',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of agricultural and forestry machinery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: 'Fabrication de machines agricoles et forestières',
    },
  },
  '28.41': {
    code: '28.41',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of metal forming machinery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: 'Fabrication de machines de formage des métaux',
    },
  },
  '28.49': {
    code: '28.49',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of other machine tools',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication d'autres machines-outils",
    },
  },
  '28.91': {
    code: '28.91',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of machinery for metallurgy',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: 'Fabrication de machines pour la métallurgie',
    },
  },
  '28.92': {
    code: '28.92',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of machinery for mining, quarrying and construction',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication de machines pour l'extraction ou la construction",
    },
  },
  '28.93': {
    code: '28.93',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4:
        'Manufacture of machinery for food, beverage and tobacco processing',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication de machines pour l'industrie agro-alimentaire",
    },
  },
  '28.94': {
    code: '28.94',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4:
        'Manufacture of machinery for textile, apparel and leather production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: 'Fabrication de machines pour les industries textiles',
    },
  },
  '28.95': {
    code: '28.95',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of machinery for paper and paperboard production',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4:
        'Fabrication de machines pour les industries du papier et du carton',
    },
  },
  '28.96': {
    code: '28.96',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of plastics and rubber machinery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4:
        'Fabrication de machines pour le travail du caoutchouc ou des plastiques',
    },
  },
  '28.99': {
    code: '28.99',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of machinery and equipment n.e.c.',
      level3: 'Manufacture of general-purpose machinery',
      level4: 'Manufacture of other special-purpose machinery n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de machines et équipements n.c.a.',
      level3: "Fabrication de machines d'usage général",
      level4: "Fabrication d'autres machines d'usage spécifique n.c.a.",
    },
  },
  '29.10': {
    code: '29.10',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of motor vehicles, trailers and semi-trailers',
      level3: 'Manufacture of motor vehicles',
      level4: 'Manufacture of motor vehicles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie automobile',
      level3: 'Construction de véhicules automobiles',
      level4: 'Construction de véhicules automobiles',
    },
  },
  '29.20': {
    code: '29.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of motor vehicles, trailers and semi-trailers',
      level3: 'Manufacture of motor vehicles',
      level4:
        'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie automobile',
      level3: 'Construction de véhicules automobiles',
      level4: 'Fabrication de carrosseries et remorques',
    },
  },
  '29.31': {
    code: '29.31',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of motor vehicles, trailers and semi-trailers',
      level3: 'Manufacture of motor vehicles',
      level4:
        'Manufacture of electrical and electronic equipment for motor vehicles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie automobile',
      level3: 'Construction de véhicules automobiles',
      level4:
        "Fabrication d'équipements électriques et électroniques automobiles",
    },
  },
  '29.32': {
    code: '29.32',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of motor vehicles, trailers and semi-trailers',
      level3: 'Manufacture of motor vehicles',
      level4: 'Manufacture of other parts and accessories for motor vehicles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Industrie automobile',
      level3: 'Construction de véhicules automobiles',
      level4: "Fabrication d'autres équipements automobiles",
    },
  },
  '30.11': {
    code: '30.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Building of ships and floating structures',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: 'Construction de navires et de structures flottantes',
    },
  },
  '30.12': {
    code: '30.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Building of pleasure and sporting boats',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: 'Construction de bateaux de plaisance',
    },
  },
  '30.20': {
    code: '30.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Manufacture of railway locomotives and rolling stock',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4:
        'Construction de locomotives et d’autre matériel ferroviaire roulant',
    },
  },
  '30.30': {
    code: '30.30',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Manufacture of air and spacecraft and related machinery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: 'Construction aéronautique et spatiale',
    },
  },
  '30.40': {
    code: '30.40',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Manufacture of military fighting vehicles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: 'Construction de véhicules militaires de combat',
    },
  },
  '30.91': {
    code: '30.91',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Manufacture of motorcycles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: 'Fabrication de motocycles',
    },
  },
  '30.92': {
    code: '30.92',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Manufacture of bicycles and invalid carriages',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: 'Fabrication de bicyclettes et de véhicules pour invalides',
    },
  },
  '30.99': {
    code: '30.99',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of other transport equipment',
      level3: 'Building of ships and boats',
      level4: 'Manufacture of other transport equipment n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Fabrication d'autres matériels de transport",
      level3: 'Construction navale',
      level4: "Fabrication d'autres équipements de transport n.c.a.",
    },
  },
  '31.01': {
    code: '31.01',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of furniture',
      level3: 'Manufacture of furniture',
      level4: 'Manufacture of office and shop furniture',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de meubles',
      level3: 'Fabrication de meubles',
      level4: 'Fabrication de meubles de bureau et de magasin',
    },
  },
  '31.02': {
    code: '31.02',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of furniture',
      level3: 'Manufacture of furniture',
      level4: 'Manufacture of kitchen furniture',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de meubles',
      level3: 'Fabrication de meubles',
      level4: 'Fabrication de meubles de cuisine',
    },
  },
  '31.03': {
    code: '31.03',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of furniture',
      level3: 'Manufacture of furniture',
      level4: 'Manufacture of mattresses',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de meubles',
      level3: 'Fabrication de meubles',
      level4: 'Fabrication de matelas',
    },
  },
  '31.09': {
    code: '31.09',
    en: {
      level1: 'Manufacturing',
      level2: 'Manufacture of furniture',
      level3: 'Manufacture of furniture',
      level4: 'Manufacture of other furniture',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Fabrication de meubles',
      level3: 'Fabrication de meubles',
      level4: "Fabrication d'autres meubles",
    },
  },
  '32.11': {
    code: '32.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Striking of coins',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: 'Frappe de monnaie',
    },
  },
  '32.12': {
    code: '32.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of jewellery and related articles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: "Fabrication d'articles de joaillerie et bijouterie",
    },
  },
  '32.13': {
    code: '32.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of imitation jewellery and related articles',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4:
        "Fabrication d'articles de bijouterie fantaisie et articles similaires",
    },
  },
  '32.20': {
    code: '32.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of musical instruments',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: "Fabrication d'instruments de musique",
    },
  },
  '32.30': {
    code: '32.30',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of sports goods',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: "Fabrication d'articles de sport",
    },
  },
  '32.40': {
    code: '32.40',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of games and toys',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: 'Fabrication de jeux et jouets',
    },
  },
  '32.50': {
    code: '32.50',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of medical and dental instruments and supplies',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4:
        "Fabrication d'instruments et de fournitures à usage médical et dentaire",
    },
  },
  '32.91': {
    code: '32.91',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Manufacture of brooms and brushes',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: "Fabrication d'articles de brosserie",
    },
  },
  '32.99': {
    code: '32.99',
    en: {
      level1: 'Manufacturing',
      level2: 'Other manufacturing',
      level3: 'Manufacture of jewellery, bijouterie and related articles',
      level4: 'Other manufacturing n.e.c.',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: 'Autres industries manufacturières',
      level3:
        "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
      level4: 'Autres activités manufacturières n.c.a.',
    },
  },
  '33.11': {
    code: '33.11',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair of fabricated metal products',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: "Réparation d'ouvrages en métaux",
    },
  },
  '33.12': {
    code: '33.12',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair of machinery',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: 'Réparation de machines et équipements mécaniques',
    },
  },
  '33.13': {
    code: '33.13',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair of electronic and optical equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: 'Réparation de matériels électroniques et optiques',
    },
  },
  '33.14': {
    code: '33.14',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair of electrical equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: "Réparation d'équipements électriques",
    },
  },
  '33.15': {
    code: '33.15',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair and maintenance of ships and boats',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: 'Réparation et maintenance navale',
    },
  },
  '33.16': {
    code: '33.16',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair and maintenance of aircraft and spacecraft',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: "Réparation et maintenance d'aéronefs et d'engins spatiaux",
    },
  },
  '33.17': {
    code: '33.17',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair and maintenance of other transport equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: "Réparation et maintenance d'autres équipements de transport",
    },
  },
  '33.19': {
    code: '33.19',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Repair of other equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: "Réparation d'autres équipements",
    },
  },
  '33.20': {
    code: '33.20',
    en: {
      level1: 'Manufacturing',
      level2: 'Repair and installation of machinery and equipment',
      level3: 'Repair of fabricated metal products, machinery and equipment',
      level4: 'Installation of industrial machinery and equipment',
    },
    fr: {
      level1: 'Industrie manufacturière',
      level2: "Réparation et installation de machines et d'équipements",
      level3: "Réparation d'ouvrages en métaux, de machines et d'équipements",
      level4: "Installation de machines et d'équipements industriels",
    },
  },
  '35.11': {
    code: '35.11',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Production of electricity',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: "Production d'électricité",
    },
  },
  '35.12': {
    code: '35.12',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Transmission of electricity',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: "Transport d'électricité",
    },
  },
  '35.13': {
    code: '35.13',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Distribution of electricity',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: "Distribution d'électricité",
    },
  },
  '35.14': {
    code: '35.14',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Trade of electricity',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: "Commerce d'électricité",
    },
  },
  '35.21': {
    code: '35.21',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Manufacture of gas',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: 'Production de combustibles gazeux',
    },
  },
  '35.22': {
    code: '35.22',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Distribution of gaseous fuels through mains',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: 'Distribution de combustibles gazeux par conduites',
    },
  },
  '35.23': {
    code: '35.23',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Trade of gas through mains',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: 'Commerce de combustibles gazeux par conduites',
    },
  },
  '35.30': {
    code: '35.30',
    en: {
      level1: 'Electricity, gas, steam and air conditioning supply',
      level2: 'Electricity, gas, steam and air conditioning supply',
      level3: 'Electric power generation, transmission and distribution',
      level4: 'Steam and air conditioning supply',
    },
    fr: {
      level1:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level2:
        "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
      level3: "Production, transport et distribution d'électricité",
      level4: "Production et distribution de vapeur et d'air conditionné",
    },
  },
  '36.00': {
    code: '36.00',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2: 'Water collection, treatment and supply',
      level3: 'Water collection, treatment and supply',
      level4: 'Water collection, treatment and supply',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: "Captage, traitement et distribution d'eau ",
      level3: "Captage, traitement et distribution d'eau",
      level4: "Captage, traitement et distribution d'eau",
    },
  },
  '37.00': {
    code: '37.00',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2: 'Sewerage',
      level3: 'Sewerage',
      level4: 'Sewerage',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte et traitement des eaux usées',
      level3: 'Collecte et traitement des eaux usées',
      level4: 'Collecte et traitement des eaux usées',
    },
  },
  '38.11': {
    code: '38.11',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2:
        'Waste collection, treatment and disposal activities; materials recovery',
      level3: 'Waste collection',
      level4: 'Collection of non-hazardous waste',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte, traitement et élimination des déchets; récupération',
      level3: 'Collecte des déchets',
      level4: 'Collecte des déchets non dangereux',
    },
  },
  '38.12': {
    code: '38.12',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2:
        'Waste collection, treatment and disposal activities; materials recovery',
      level3: 'Waste collection',
      level4: 'Collection of hazardous waste',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte, traitement et élimination des déchets; récupération',
      level3: 'Collecte des déchets',
      level4: 'Collecte des déchets dangereux',
    },
  },
  '38.21': {
    code: '38.21',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2:
        'Waste collection, treatment and disposal activities; materials recovery',
      level3: 'Waste collection',
      level4: 'Treatment and disposal of non-hazardous waste',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte, traitement et élimination des déchets; récupération',
      level3: 'Collecte des déchets',
      level4: 'Traitement et élimination des déchets non dangereux',
    },
  },
  '38.22': {
    code: '38.22',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2:
        'Waste collection, treatment and disposal activities; materials recovery',
      level3: 'Waste collection',
      level4: 'Treatment and disposal of hazardous waste',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte, traitement et élimination des déchets; récupération',
      level3: 'Collecte des déchets',
      level4: 'Traitement et élimination des déchets dangereux',
    },
  },
  '38.31': {
    code: '38.31',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2:
        'Waste collection, treatment and disposal activities; materials recovery',
      level3: 'Waste collection',
      level4: 'Dismantling of wrecks',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte, traitement et élimination des déchets; récupération',
      level3: 'Collecte des déchets',
      level4: "Démantèlement d'épaves",
    },
  },
  '38.32': {
    code: '38.32',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2:
        'Waste collection, treatment and disposal activities; materials recovery',
      level3: 'Waste collection',
      level4: 'Recovery of sorted materials',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Collecte, traitement et élimination des déchets; récupération',
      level3: 'Collecte des déchets',
      level4: 'Récupération de déchets triés',
    },
  },
  '39.00': {
    code: '39.00',
    en: {
      level1:
        'Water supply; sewerage, waste management and remediation activities',
      level2: 'Remediation activities and other waste management services',
      level3: 'Remediation activities and other waste management services',
      level4: 'Remediation activities and other waste management services',
    },
    fr: {
      level1:
        "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
      level2: 'Dépollution et autres services de gestion des déchets',
      level3: 'Dépollution et autres services de gestion des déchets',
      level4: 'Dépollution et autres services de gestion des déchets',
    },
  },
  '41.10': {
    code: '41.10',
    en: {
      level1: 'Construction',
      level2: 'Construction of buildings',
      level3: 'Development of building projects',
      level4: 'Development of building projects',
    },
    fr: {
      level1: 'Construction',
      level2: 'Construction de bâtiments',
      level3: 'Promotion immobilière',
      level4: 'Promotion immobilière',
    },
  },
  '41.20': {
    code: '41.20',
    en: {
      level1: 'Construction',
      level2: 'Construction of buildings',
      level3: 'Development of building projects',
      level4: 'Construction of residential and non-residential buildings',
    },
    fr: {
      level1: 'Construction',
      level2: 'Construction de bâtiments',
      level3: 'Promotion immobilière',
      level4: 'Construction de bâtiments résidentiels et non résidentiels',
    },
  },
  '42.11': {
    code: '42.11',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4: 'Construction of roads and motorways',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: 'Construction de routes et autoroutes',
    },
  },
  '42.12': {
    code: '42.12',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4: 'Construction of railways and underground railways',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: 'Construction de voies ferrées de surface et souterraines',
    },
  },
  '42.13': {
    code: '42.13',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4: 'Construction of bridges and tunnels',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: 'Construction de ponts et tunnels',
    },
  },
  '42.21': {
    code: '42.21',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4: 'Construction of utility projects for fluids',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: 'Construction de réseaux pour fluides',
    },
  },
  '42.22': {
    code: '42.22',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4:
        'Construction of utility projects for electricity and telecommunications',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: 'Construction de réseaux électriques et de télécommunications',
    },
  },
  '42.91': {
    code: '42.91',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4: 'Construction of water projects',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: "Construction d'ouvrages maritimes et fluviaux",
    },
  },
  '42.99': {
    code: '42.99',
    en: {
      level1: 'Construction',
      level2: 'Civil engineering',
      level3: 'Construction of roads and railways',
      level4: 'Construction of other civil engineering projects n.e.c.',
    },
    fr: {
      level1: 'Construction',
      level2: 'Génie civil',
      level3: 'Construction de routes et de voies ferrées',
      level4: "Construction d'autres ouvrages de génie civil n.c.a.",
    },
  },
  '43.11': {
    code: '43.11',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Demolition',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de démolition',
    },
  },
  '43.12': {
    code: '43.12',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Site preparation',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de préparation des sites',
    },
  },
  '43.13': {
    code: '43.13',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Test drilling and boring',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Forages et sondages',
    },
  },
  '43.21': {
    code: '43.21',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Electrical installation',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Installation électrique',
    },
  },
  '43.22': {
    code: '43.22',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Plumbing, heat and air-conditioning installation',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4:
        "Travaux de plomberie et installation de chauffage et de conditionnement d'air",
    },
  },
  '43.29': {
    code: '43.29',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Other construction installation',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: "Autres travaux d'installation",
    },
  },
  '43.31': {
    code: '43.31',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Plastering',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de plâtrerie',
    },
  },
  '43.32': {
    code: '43.32',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Joinery installation',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de menuiserie',
    },
  },
  '43.33': {
    code: '43.33',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Floor and wall covering',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de revêtement des sols et des murs',
    },
  },
  '43.34': {
    code: '43.34',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Painting and glazing',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de peinture et vitrerie',
    },
  },
  '43.39': {
    code: '43.39',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Other building completion and finishing',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Autres travaux de finition',
    },
  },
  '43.91': {
    code: '43.91',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Roofing activities',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Travaux de couverture',
    },
  },
  '43.99': {
    code: '43.99',
    en: {
      level1: 'Construction',
      level2: 'Specialised construction activities',
      level3: 'Demolition and site preparation',
      level4: 'Other specialised construction activities n.e.c.',
    },
    fr: {
      level1: 'Construction',
      level2: 'Travaux de construction spécialisés',
      level3: 'Démolition et préparation des sites',
      level4: 'Autres travaux de construction spécialisés n.c.a.',
    },
  },
  '45.11': {
    code: '45.11',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2:
        'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      level3: 'Sale of motor vehicles',
      level4: 'Sale of cars and light motor vehicles',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2: "Commerce et réparation d'automobiles et de motocycles",
      level3: 'Commerce de véhicules automobiles',
      level4: 'Commerce de voitures et de véhicules automobiles légers',
    },
  },
  '45.19': {
    code: '45.19',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2:
        'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      level3: 'Sale of motor vehicles',
      level4: 'Sale of other motor vehicles',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2: "Commerce et réparation d'automobiles et de motocycles",
      level3: 'Commerce de véhicules automobiles',
      level4: "Commerce d'autres véhicules automobiles",
    },
  },
  '45.20': {
    code: '45.20',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2:
        'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      level3: 'Sale of motor vehicles',
      level4: 'Maintenance and repair of motor vehicles',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2: "Commerce et réparation d'automobiles et de motocycles",
      level3: 'Commerce de véhicules automobiles',
      level4: 'Entretien et réparation de véhicules automobiles',
    },
  },
  '45.31': {
    code: '45.31',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2:
        'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      level3: 'Sale of motor vehicles',
      level4: 'Wholesale trade of motor vehicle parts and accessories',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2: "Commerce et réparation d'automobiles et de motocycles",
      level3: 'Commerce de véhicules automobiles',
      level4: "Commerce de gros d'équipements automobiles",
    },
  },
  '45.32': {
    code: '45.32',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2:
        'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      level3: 'Sale of motor vehicles',
      level4: 'Retail trade of motor vehicle parts and accessories',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2: "Commerce et réparation d'automobiles et de motocycles",
      level3: 'Commerce de véhicules automobiles',
      level4: "Commerce de détail d'équipements automobiles",
    },
  },
  '45.40': {
    code: '45.40',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2:
        'Wholesale and retail trade and repair of motor vehicles and motorcycles',
      level3: 'Sale of motor vehicles',
      level4:
        'Sale, maintenance and repair of motorcycles and related parts and accessories',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2: "Commerce et réparation d'automobiles et de motocycles",
      level3: 'Commerce de véhicules automobiles',
      level4: 'Commerce et réparation de motocycles',
    },
  },
  '46.11': {
    code: '46.11',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis',
    },
  },
  '46.12': {
    code: '46.12',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques',
    },
  },
  '46.13': {
    code: '46.13',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Agents involved in the sale of timber and building materials',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Intermédiaires du commerce en bois et matériaux de construction',
    },
  },
  '46.14': {
    code: '46.14',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Intermédiaires du commerce en machines, équipements industriels, navires et avions',
    },
  },
  '46.15': {
    code: '46.15',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie',
    },
  },
  '46.16': {
    code: '46.16',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir',
    },
  },
  '46.17': {
    code: '46.17',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Agents involved in the sale of food, beverages and tobacco',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Intermédiaires du commerce en denrées, boissons et tabac',
    },
  },
  '46.18': {
    code: '46.18',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Agents specialised in the sale of other particular products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
    },
  },
  '46.19': {
    code: '46.19',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Agents involved in the sale of a variety of goods',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Intermédiaires du commerce en produits divers',
    },
  },
  '46.21': {
    code: '46.21',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail ",
    },
  },
  '46.22': {
    code: '46.22',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of flowers and plants',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de fleurs et plantes',
    },
  },
  '46.23': {
    code: '46.23',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of live animals',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'animaux vivants",
    },
  },
  '46.24': {
    code: '46.24',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of hides, skins and leather',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de cuirs et peaux',
    },
  },
  '46.31': {
    code: '46.31',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of fruit and vegetables',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de fruits et légumes',
    },
  },
  '46.32': {
    code: '46.32',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of meat and meat products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de viandes et de produits à base de viande',
    },
  },
  '46.33': {
    code: '46.33',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of dairy products, eggs and edible oils and fats',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles',
    },
  },
  '46.34': {
    code: '46.34',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of beverages',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de boissons',
    },
  },
  '46.35': {
    code: '46.35',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of tobacco products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de produits à base de de tabac',
    },
  },
  '46.36': {
    code: '46.36',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of sugar and chocolate and sugar confectionery',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de sucre, chocolat et confiserie',
    },
  },
  '46.37': {
    code: '46.37',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of coffee, tea, cocoa and spices',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de café, thé, cacao et épices',
    },
  },
  '46.38': {
    code: '46.38',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of other food, including fish, crustaceans and molluscs',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques",
    },
  },
  '46.39': {
    code: '46.39',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Non-specialised wholesale of food, beverages and tobacco',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros non spécialisé de denrées, boissons et tabac',
    },
  },
  '46.41': {
    code: '46.41',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of textiles',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de textiles',
    },
  },
  '46.42': {
    code: '46.42',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of clothing and footwear',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'habillement et de chaussures",
    },
  },
  '46.43': {
    code: '46.43',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of electrical household appliances',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'appareils électroménagers",
    },
  },
  '46.44': {
    code: '46.44',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of china and glassware and cleaning materials',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros de vaisselle, verrerie et produits d'entretien",
    },
  },
  '46.45': {
    code: '46.45',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of perfume and cosmetics',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de parfumerie et de produits de beauté',
    },
  },
  '46.46': {
    code: '46.46',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of pharmaceutical goods',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de produits pharmaceutiques',
    },
  },
  '46.47': {
    code: '46.47',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of furniture, carpets and lighting equipment',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros de meubles, de tapis et d'appareils d'éclairage ",
    },
  },
  '46.48': {
    code: '46.48',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of watches and jewellery',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'articles d'horlogerie et de bijouterie",
    },
  },
  '46.49': {
    code: '46.49',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of other household goods',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'autres biens domestiques",
    },
  },
  '46.51': {
    code: '46.51',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of computers, computer peripheral equipment and software',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
    },
  },
  '46.52': {
    code: '46.52',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of electronic and telecommunications equipment and parts',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros de composants et d'équipements électroniques et de télécommunication",
    },
  },
  '46.61': {
    code: '46.61',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of agricultural machinery, equipment and supplies',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de matériel agricole',
    },
  },
  '46.62': {
    code: '46.62',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of machine tools',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de machines-outils',
    },
  },
  '46.63': {
    code: '46.63',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of mining, construction and civil engineering machinery',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros de machines pour l'extraction, la construction et le génie civil ",
    },
  },
  '46.64': {
    code: '46.64',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of machinery for the textile industry and of sewing and knitting machines',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros de machines pour l'industrie textile et l'habillement",
    },
  },
  '46.65': {
    code: '46.65',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of office furniture',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de mobilier de bureau',
    },
  },
  '46.66': {
    code: '46.66',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of other office machinery and equipment',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'autres machines et équipements de bureau",
    },
  },
  '46.69': {
    code: '46.69',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of other machinery and equipment',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'autres machines et équipements",
    },
  },
  '46.71': {
    code: '46.71',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of solid, liquid and gaseous fuels and related products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de combustibles et de produits annexes',
    },
  },
  '46.72': {
    code: '46.72',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of metals and metal ores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de minerais et métaux',
    },
  },
  '46.73': {
    code: '46.73',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of wood, construction materials and sanitary equipment',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        "Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires",
    },
  },
  '46.74': {
    code: '46.74',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4:
        'Wholesale of hardware, plumbing and heating equipment and supplies',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4:
        'Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage',
    },
  },
  '46.75': {
    code: '46.75',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of chemical products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de produits chimiques',
    },
  },
  '46.76': {
    code: '46.76',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of other intermediate products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: "Commerce de gros d'autres produits intermédiaires",
    },
  },
  '46.77': {
    code: '46.77',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Wholesale of waste and scrap',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros de déchets et débris',
    },
  },
  '46.90': {
    code: '46.90',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Wholesale trade, except of motor vehicles and motorcycles',
      level3: 'Wholesale on a fee or contract basis',
      level4: 'Non-specialised wholesale trade',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de gros, à l’exception des automobiles et des motocycles',
      level3: 'Intermédiaires du commerce de gros',
      level4: 'Commerce de gros non spécialisé',
    },
  },
  '47.11': {
    code: '47.11',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail en magasin non spécialisé à prédominance alimentaire',
    },
  },
  '47.19': {
    code: '47.19',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Other retail sale in non-specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Autre commerce de détail en magasin non spécialisé',
    },
  },
  '47.21': {
    code: '47.21',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of fruit and vegetables in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail de fruits et légumes en magasin spécialisé',
    },
  },
  '47.22': {
    code: '47.22',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of meat and meat products in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de viandes et de produits à base de viande en magasin spécialisé',
    },
  },
  '47.23': {
    code: '47.23',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of fish, crustaceans and molluscs in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé',
    },
  },
  '47.24': {
    code: '47.24',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé',
    },
  },
  '47.25': {
    code: '47.25',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of beverages in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail de boissons en magasin spécialisé',
    },
  },
  '47.26': {
    code: '47.26',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of tobacco products in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de produits à base de tabac en magasin spécialisé',
    },
  },
  '47.29': {
    code: '47.29',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Other retail sale of food in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Autres commerces de détail alimentaires en magasin spécialisé',
    },
  },
  '47.30': {
    code: '47.30',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of automotive fuel in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail de carburants en magasin spécialisé',
    },
  },
  '47.41': {
    code: '47.41',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of computers, peripheral units and software in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
    },
  },
  '47.42': {
    code: '47.42',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of telecommunications equipment in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de matériels de télécommunication en magasin spécialisé',
    },
  },
  '47.43': {
    code: '47.43',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of audio and video equipment in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de matériels audio/vidéo en magasin spécialisé',
    },
  },
  '47.51': {
    code: '47.51',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of textiles in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail de textiles en magasin spécialisé',
    },
  },
  '47.52': {
    code: '47.52',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of hardware, paints and glass in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé',
    },
  },
  '47.53': {
    code: '47.53',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé',
    },
  },
  '47.54': {
    code: '47.54',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of electrical household appliances in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail d'appareils électroménagers en magasin spécialisé",
    },
  },
  '47.59': {
    code: '47.59',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of furniture, lighting equipment and other household articles in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé",
    },
  },
  '47.61': {
    code: '47.61',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of books in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail de livres en magasin spécialisé',
    },
  },
  '47.62': {
    code: '47.62',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of newspapers and stationery in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de journaux et papeterie en magasin spécialisé',
    },
  },
  '47.63': {
    code: '47.63',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of music and video recordings in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
    },
  },
  '47.64': {
    code: '47.64',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of sporting equipment in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: "Commerce de détail d'articles de sport en magasin spécialisé",
    },
  },
  '47.65': {
    code: '47.65',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of games and toys in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail de jeux et jouets en magasin spécialisé',
    },
  },
  '47.71': {
    code: '47.71',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of clothing in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: "Commerce de détail d'habillement en magasin spécialisé",
    },
  },
  '47.72': {
    code: '47.72',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of footwear and leather goods in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé",
    },
  },
  '47.73': {
    code: '47.73',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Dispensing chemist in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de produits pharmaceutiques en magasin spécialisé',
    },
  },
  '47.74': {
    code: '47.74',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of medical and orthopaedic goods in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
    },
  },
  '47.75': {
    code: '47.75',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of cosmetic and toilet articles in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé',
    },
  },
  '47.76': {
    code: '47.76',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé',
    },
  },
  '47.77': {
    code: '47.77',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of watches and jewellery in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
    },
  },
  '47.78': {
    code: '47.78',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Other retail sale of new goods in specialised stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Autre commerce de détail de biens neufs en magasin spécialisé',
    },
  },
  '47.79': {
    code: '47.79',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale of second-hand goods in stores',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: "Commerce de détail de biens d'occasion en magasin",
    },
  },
  '47.81': {
    code: '47.81',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale via stalls and markets of food, beverages and tobacco products',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Commerce de détail alimentaire sur éventaires et marchés',
    },
  },
  '47.82': {
    code: '47.82',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4:
        'Retail sale via stalls and markets of textiles, clothing and footwear',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4:
        "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
    },
  },
  '47.89': {
    code: '47.89',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale via stalls and markets of other goods',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Autres commerces de détail sur éventaires et marchés',
    },
  },
  '47.91': {
    code: '47.91',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Retail sale via mail order houses or via Internet',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Vente à distance',
    },
  },
  '47.99': {
    code: '47.99',
    en: {
      level1:
        'Wholesale and retail trade; repair of motor vehicles and motorcycles',
      level2: 'Retail trade, except of motor vehicles and motorcycles',
      level3: 'Retail sale in non-specialised stores',
      level4: 'Other retail sale not in stores, stalls or markets',
    },
    fr: {
      level1: "Commerce; réparation d'automobiles et de motocycles",
      level2:
        'Commerce de détail, à l’exception des automobiles et des motocycles',
      level3: 'Commerce de détail en magasin non spécialisé',
      level4: 'Autres commerces de détail hors magasin, éventaires ou marchés',
    },
  },
  '49.10': {
    code: '49.10',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Passenger rail transport, interurban',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Transport ferroviaire interurbain de voyageurs',
    },
  },
  '49.20': {
    code: '49.20',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Freight rail transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Transports ferroviaires de fret',
    },
  },
  '49.31': {
    code: '49.31',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Urban and suburban passenger land transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Transports urbains et suburbains de voyageurs',
    },
  },
  '49.32': {
    code: '49.32',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Taxi operation',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Transports de voyageurs par taxis',
    },
  },
  '49.39': {
    code: '49.39',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Other passenger land transport n.e.c.',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Autres transports terrestres de voyageurs n.c.a.',
    },
  },
  '49.41': {
    code: '49.41',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Freight transport by road',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Transports routiers de fret',
    },
  },
  '49.42': {
    code: '49.42',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Removal services',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Services de déménagement',
    },
  },
  '49.50': {
    code: '49.50',
    en: {
      level1: 'Transportation and storage',
      level2: 'Land transport and transport via pipelines',
      level3: 'Passenger rail transport, interurban',
      level4: 'Transport via pipeline',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports terrestres et transport par conduites',
      level3: 'Transport ferroviaire interurbain de voyageurs',
      level4: 'Transports par conduites',
    },
  },
  '50.10': {
    code: '50.10',
    en: {
      level1: 'Transportation and storage',
      level2: 'Water transport',
      level3: 'Sea and coastal passenger water transport',
      level4: 'Sea and coastal passenger water transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports par eau',
      level3: 'Transports maritimes et côtiers de passagers',
      level4: 'Transports maritimes et côtiers de passagers',
    },
  },
  '50.20': {
    code: '50.20',
    en: {
      level1: 'Transportation and storage',
      level2: 'Water transport',
      level3: 'Sea and coastal passenger water transport',
      level4: 'Sea and coastal freight water transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports par eau',
      level3: 'Transports maritimes et côtiers de passagers',
      level4: 'Transports maritimes et côtiers de fret',
    },
  },
  '50.30': {
    code: '50.30',
    en: {
      level1: 'Transportation and storage',
      level2: 'Water transport',
      level3: 'Sea and coastal passenger water transport',
      level4: 'Inland passenger water transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports par eau',
      level3: 'Transports maritimes et côtiers de passagers',
      level4: 'Transports fluviaux de passagers',
    },
  },
  '50.40': {
    code: '50.40',
    en: {
      level1: 'Transportation and storage',
      level2: 'Water transport',
      level3: 'Sea and coastal passenger water transport',
      level4: 'Inland freight water transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports par eau',
      level3: 'Transports maritimes et côtiers de passagers',
      level4: 'Transports fluviaux de fret',
    },
  },
  '51.10': {
    code: '51.10',
    en: {
      level1: 'Transportation and storage',
      level2: 'Air transport',
      level3: 'Passenger air transport',
      level4: 'Passenger air transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports aériens',
      level3: 'Transports aériens de passagers',
      level4: 'Transports aériens de passagers',
    },
  },
  '51.21': {
    code: '51.21',
    en: {
      level1: 'Transportation and storage',
      level2: 'Air transport',
      level3: 'Passenger air transport',
      level4: 'Freight air transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports aériens',
      level3: 'Transports aériens de passagers',
      level4: 'Transports aériens de fret',
    },
  },
  '51.22': {
    code: '51.22',
    en: {
      level1: 'Transportation and storage',
      level2: 'Air transport',
      level3: 'Passenger air transport',
      level4: 'Space transport',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Transports aériens',
      level3: 'Transports aériens de passagers',
      level4: 'Transports spatiaux',
    },
  },
  '52.10': {
    code: '52.10',
    en: {
      level1: 'Transportation and storage',
      level2: 'Warehousing and support activities for transportation',
      level3: 'Warehousing and storage',
      level4: 'Warehousing and storage',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Entreposage et services auxiliaires des transports',
      level3: 'Entreposage et stockage',
      level4: 'Entreposage et stockage',
    },
  },
  '52.21': {
    code: '52.21',
    en: {
      level1: 'Transportation and storage',
      level2: 'Warehousing and support activities for transportation',
      level3: 'Warehousing and storage',
      level4: 'Service activities incidental to land transportation',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Entreposage et services auxiliaires des transports',
      level3: 'Entreposage et stockage',
      level4: 'Services auxiliaires des transports terrestres',
    },
  },
  '52.22': {
    code: '52.22',
    en: {
      level1: 'Transportation and storage',
      level2: 'Warehousing and support activities for transportation',
      level3: 'Warehousing and storage',
      level4: 'Service activities incidental to water transportation',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Entreposage et services auxiliaires des transports',
      level3: 'Entreposage et stockage',
      level4: 'Services auxiliaires des transports par eau',
    },
  },
  '52.23': {
    code: '52.23',
    en: {
      level1: 'Transportation and storage',
      level2: 'Warehousing and support activities for transportation',
      level3: 'Warehousing and storage',
      level4: 'Service activities incidental to air transportation',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Entreposage et services auxiliaires des transports',
      level3: 'Entreposage et stockage',
      level4: 'Services auxiliaires des transports aériens',
    },
  },
  '52.24': {
    code: '52.24',
    en: {
      level1: 'Transportation and storage',
      level2: 'Warehousing and support activities for transportation',
      level3: 'Warehousing and storage',
      level4: 'Cargo handling',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Entreposage et services auxiliaires des transports',
      level3: 'Entreposage et stockage',
      level4: 'Manutention',
    },
  },
  '52.29': {
    code: '52.29',
    en: {
      level1: 'Transportation and storage',
      level2: 'Warehousing and support activities for transportation',
      level3: 'Warehousing and storage',
      level4: 'Other transportation support activities',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Entreposage et services auxiliaires des transports',
      level3: 'Entreposage et stockage',
      level4: 'Autres services auxiliaires des transports',
    },
  },
  '53.10': {
    code: '53.10',
    en: {
      level1: 'Transportation and storage',
      level2: 'Postal and courier activities',
      level3: 'Postal activities under universal service obligation',
      level4: 'Postal activities under universal service obligation',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Activités de poste et de courrier',
      level3:
        "Activités de poste dans le cadre d'une obligation de service universel",
      level4:
        "Activités de poste dans le cadre d'une obligation de service universel",
    },
  },
  '53.20': {
    code: '53.20',
    en: {
      level1: 'Transportation and storage',
      level2: 'Postal and courier activities',
      level3: 'Postal activities under universal service obligation',
      level4: 'Other postal and courier activities',
    },
    fr: {
      level1: 'Transports et entreposage',
      level2: 'Activités de poste et de courrier',
      level3:
        "Activités de poste dans le cadre d'une obligation de service universel",
      level4: 'Autres activités de poste et de courrier',
    },
  },
  '55.10': {
    code: '55.10',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Accommodation',
      level3: 'Hotels and similar accommodation',
      level4: 'Hotels and similar accommodation',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Hébergement',
      level3: 'Hôtels et hébergement similaire',
      level4: 'Hôtels et hébergement similaire',
    },
  },
  '55.20': {
    code: '55.20',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Accommodation',
      level3: 'Hotels and similar accommodation',
      level4: 'Holiday and other short-stay accommodation',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Hébergement',
      level3: 'Hôtels et hébergement similaire',
      level4: 'Hébergement touristique et autre hébergement de courte durée',
    },
  },
  '55.30': {
    code: '55.30',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Accommodation',
      level3: 'Hotels and similar accommodation',
      level4: 'Camping grounds, recreational vehicle parks and trailer parks',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Hébergement',
      level3: 'Hôtels et hébergement similaire',
      level4:
        'Terrains de camping et parcs pour caravanes ou véhicules de loisirs',
    },
  },
  '55.90': {
    code: '55.90',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Accommodation',
      level3: 'Hotels and similar accommodation',
      level4: 'Other accommodation',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Hébergement',
      level3: 'Hôtels et hébergement similaire',
      level4: 'Autres hébergements',
    },
  },
  '56.10': {
    code: '56.10',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Food and beverage service activities',
      level3: 'Restaurants and mobile food service activities',
      level4: 'Restaurants and mobile food service activities',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Restauration',
      level3: 'Restaurants et services de restauration mobile',
      level4: 'Restaurants et services de restauration mobile',
    },
  },
  '56.21': {
    code: '56.21',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Food and beverage service activities',
      level3: 'Restaurants and mobile food service activities',
      level4: 'Event catering activities',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Restauration',
      level3: 'Restaurants et services de restauration mobile',
      level4: 'Services des traiteurs',
    },
  },
  '56.29': {
    code: '56.29',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Food and beverage service activities',
      level3: 'Restaurants and mobile food service activities',
      level4: 'Other food service activities',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Restauration',
      level3: 'Restaurants et services de restauration mobile',
      level4: 'Autres services de restauration',
    },
  },
  '56.30': {
    code: '56.30',
    en: {
      level1: 'Accommodation and food service activities',
      level2: 'Food and beverage service activities',
      level3: 'Restaurants and mobile food service activities',
      level4: 'Beverage serving activities',
    },
    fr: {
      level1: 'Hébergement et restauration',
      level2: 'Restauration',
      level3: 'Restaurants et services de restauration mobile',
      level4: 'Débits de boissons',
    },
  },
  '58.11': {
    code: '58.11',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Book publishing',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: 'Édition de livres',
    },
  },
  '58.12': {
    code: '58.12',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Publishing of directories and mailing lists',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: "Édition de répertoires et de fichiers d'adresses",
    },
  },
  '58.13': {
    code: '58.13',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Publishing of newspapers',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: 'Édition de journaux',
    },
  },
  '58.14': {
    code: '58.14',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Publishing of journals and periodicals',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: 'Édition de revues et périodiques',
    },
  },
  '58.19': {
    code: '58.19',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Other publishing activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: "Autres activités d'édition",
    },
  },
  '58.21': {
    code: '58.21',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Publishing of computer games',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: 'Édition de jeux électroniques',
    },
  },
  '58.29': {
    code: '58.29',
    en: {
      level1: 'Information and communication',
      level2: 'Publishing activities',
      level3:
        'Publishing of books, periodicals and other publishing activities',
      level4: 'Other software publishing',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Édition',
      level3: "Édition de livres et périodiques et autres activités d'édition",
      level4: "Édition d'autres logiciels",
    },
  },
  '59.11': {
    code: '59.11',
    en: {
      level1: 'Information and communication',
      level2:
        'Motion picture, video and television programme production, sound recording and music publishing activities',
      level3: 'Motion picture, video and television programme activities',
      level4:
        'Motion picture, video and television programme production activities',
    },
    fr: {
      level1: 'Information et communication',
      level2:
        'Production de films cinématographiques, de vidéo et de programmes de télévision; enregistrement sonore et édition musicale',
      level3: 'Activités cinématographiques, vidéo et de télévision',
      level4:
        'Production de films cinématographiques, de vidéo et de programmes de télévision ',
    },
  },
  '59.12': {
    code: '59.12',
    en: {
      level1: 'Information and communication',
      level2:
        'Motion picture, video and television programme production, sound recording and music publishing activities',
      level3: 'Motion picture, video and television programme activities',
      level4:
        'Motion picture, video and television programme post-production activities',
    },
    fr: {
      level1: 'Information et communication',
      level2:
        'Production de films cinématographiques, de vidéo et de programmes de télévision; enregistrement sonore et édition musicale',
      level3: 'Activités cinématographiques, vidéo et de télévision',
      level4:
        'Post-production de films cinématographiques, de vidéo et de programmes de télévision',
    },
  },
  '59.13': {
    code: '59.13',
    en: {
      level1: 'Information and communication',
      level2:
        'Motion picture, video and television programme production, sound recording and music publishing activities',
      level3: 'Motion picture, video and television programme activities',
      level4:
        'Motion picture, video and television programme distribution activities',
    },
    fr: {
      level1: 'Information et communication',
      level2:
        'Production de films cinématographiques, de vidéo et de programmes de télévision; enregistrement sonore et édition musicale',
      level3: 'Activités cinématographiques, vidéo et de télévision',
      level4:
        'Distribution de films cinématographiques, de vidéo et de programmes de télévision ',
    },
  },
  '59.14': {
    code: '59.14',
    en: {
      level1: 'Information and communication',
      level2:
        'Motion picture, video and television programme production, sound recording and music publishing activities',
      level3: 'Motion picture, video and television programme activities',
      level4: 'Motion picture projection activities',
    },
    fr: {
      level1: 'Information et communication',
      level2:
        'Production de films cinématographiques, de vidéo et de programmes de télévision; enregistrement sonore et édition musicale',
      level3: 'Activités cinématographiques, vidéo et de télévision',
      level4: 'Projection de films cinématographiques',
    },
  },
  '59.20': {
    code: '59.20',
    en: {
      level1: 'Information and communication',
      level2:
        'Motion picture, video and television programme production, sound recording and music publishing activities',
      level3: 'Motion picture, video and television programme activities',
      level4: 'Sound recording and music publishing activities',
    },
    fr: {
      level1: 'Information et communication',
      level2:
        'Production de films cinématographiques, de vidéo et de programmes de télévision; enregistrement sonore et édition musicale',
      level3: 'Activités cinématographiques, vidéo et de télévision',
      level4: 'Enregistrement sonore et édition musicale',
    },
  },
  '60.10': {
    code: '60.10',
    en: {
      level1: 'Information and communication',
      level2: 'Programming and broadcasting activities',
      level3: 'Radio broadcasting',
      level4: 'Radio broadcasting',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Programmation et diffusion',
      level3: 'Édition et diffusion de programmes radio',
      level4: 'Édition et diffusion de programmes radio',
    },
  },
  '60.20': {
    code: '60.20',
    en: {
      level1: 'Information and communication',
      level2: 'Programming and broadcasting activities',
      level3: 'Radio broadcasting',
      level4: 'Television programming and broadcasting activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Programmation et diffusion',
      level3: 'Édition et diffusion de programmes radio',
      level4: 'Programmation de télévision et télédiffusion',
    },
  },
  '61.10': {
    code: '61.10',
    en: {
      level1: 'Information and communication',
      level2: 'Telecommunications',
      level3: 'Wired telecommunications activities',
      level4: 'Wired telecommunications activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Télécommunications',
      level3: 'Télécommunications filaires',
      level4: 'Télécommunications filaires',
    },
  },
  '61.20': {
    code: '61.20',
    en: {
      level1: 'Information and communication',
      level2: 'Telecommunications',
      level3: 'Wired telecommunications activities',
      level4: 'Wireless telecommunications activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Télécommunications',
      level3: 'Télécommunications filaires',
      level4: 'Télécommunications sans fil',
    },
  },
  '61.30': {
    code: '61.30',
    en: {
      level1: 'Information and communication',
      level2: 'Telecommunications',
      level3: 'Wired telecommunications activities',
      level4: 'Satellite telecommunications activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Télécommunications',
      level3: 'Télécommunications filaires',
      level4: 'Télécommunications par satellite',
    },
  },
  '61.90': {
    code: '61.90',
    en: {
      level1: 'Information and communication',
      level2: 'Telecommunications',
      level3: 'Wired telecommunications activities',
      level4: 'Other telecommunications activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Télécommunications',
      level3: 'Télécommunications filaires',
      level4: 'Autres activités de télécommunication',
    },
  },
  '62.01': {
    code: '62.01',
    en: {
      level1: 'Information and communication',
      level2: 'Computer programming, consultancy and related activities',
      level3: 'Computer programming, consultancy and related activities',
      level4: 'Computer programming activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Programmation, conseil et autres activités informatiques ',
      level3: 'Programmation, conseil et autres activités informatiques',
      level4: 'Programmation informatique',
    },
  },
  '62.02': {
    code: '62.02',
    en: {
      level1: 'Information and communication',
      level2: 'Computer programming, consultancy and related activities',
      level3: 'Computer programming, consultancy and related activities',
      level4: 'Computer consultancy activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Programmation, conseil et autres activités informatiques ',
      level3: 'Programmation, conseil et autres activités informatiques',
      level4: 'Conseil informatique',
    },
  },
  '62.03': {
    code: '62.03',
    en: {
      level1: 'Information and communication',
      level2: 'Computer programming, consultancy and related activities',
      level3: 'Computer programming, consultancy and related activities',
      level4: 'Computer facilities management activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Programmation, conseil et autres activités informatiques ',
      level3: 'Programmation, conseil et autres activités informatiques',
      level4: "Gestion d'installations informatiques",
    },
  },
  '62.09': {
    code: '62.09',
    en: {
      level1: 'Information and communication',
      level2: 'Computer programming, consultancy and related activities',
      level3: 'Computer programming, consultancy and related activities',
      level4: 'Other information technology and computer service activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: 'Programmation, conseil et autres activités informatiques ',
      level3: 'Programmation, conseil et autres activités informatiques',
      level4: 'Autres activités informatiques',
    },
  },
  '63.11': {
    code: '63.11',
    en: {
      level1: 'Information and communication',
      level2: 'Information service activities',
      level3: 'Data processing, hosting and related activities; web portals',
      level4: 'Data processing, hosting and related activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: "Services d'information",
      level3:
        'Traitement de données, hébergement et activités connexes; portails Internet',
      level4: 'Traitement de données, hébergement et activités connexes',
    },
  },
  '63.12': {
    code: '63.12',
    en: {
      level1: 'Information and communication',
      level2: 'Information service activities',
      level3: 'Data processing, hosting and related activities; web portals',
      level4: 'Web portals',
    },
    fr: {
      level1: 'Information et communication',
      level2: "Services d'information",
      level3:
        'Traitement de données, hébergement et activités connexes; portails Internet',
      level4: 'Portails Internet',
    },
  },
  '63.91': {
    code: '63.91',
    en: {
      level1: 'Information and communication',
      level2: 'Information service activities',
      level3: 'Data processing, hosting and related activities; web portals',
      level4: 'News agency activities',
    },
    fr: {
      level1: 'Information et communication',
      level2: "Services d'information",
      level3:
        'Traitement de données, hébergement et activités connexes; portails Internet',
      level4: 'Activités des agences de presse',
    },
  },
  '63.99': {
    code: '63.99',
    en: {
      level1: 'Information and communication',
      level2: 'Information service activities',
      level3: 'Data processing, hosting and related activities; web portals',
      level4: 'Other information service activities n.e.c.',
    },
    fr: {
      level1: 'Information et communication',
      level2: "Services d'information",
      level3:
        'Traitement de données, hébergement et activités connexes; portails Internet',
      level4: "Autres services d'information n.c.a.",
    },
  },
  '64.11': {
    code: '64.11',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4: 'Central banking',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4: 'Activités de banque centrale',
    },
  },
  '64.19': {
    code: '64.19',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4: 'Other monetary intermediation',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4: 'Autres intermédiations monétaires',
    },
  },
  '64.20': {
    code: '64.20',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4: 'Activities of holding companies',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4: 'Activités des sociétés holding',
    },
  },
  '64.30': {
    code: '64.30',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4: 'Trusts, funds and similar financial entities',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4: 'Fonds de placement et entités financières similaires',
    },
  },
  '64.91': {
    code: '64.91',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4: 'Financial leasing',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4: 'Crédit-bail',
    },
  },
  '64.92': {
    code: '64.92',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4: 'Other credit granting',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4: 'Autre distribution de crédit',
    },
  },
  '64.99': {
    code: '64.99',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Financial service activities, except insurance and pension funding',
      level3: 'Monetary intermediation',
      level4:
        'Other financial service activities, except insurance and pension funding n.e.c.',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2:
        'Activités des services financiers, hors assurance et caisses de retraite',
      level3: 'Intermédiation monétaire',
      level4:
        'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.',
    },
  },
  '65.11': {
    code: '65.11',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Insurance, reinsurance and pension funding, except compulsory social security',
      level3: 'Insurance',
      level4: 'Life insurance',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: 'Assurance',
      level3: 'Assurance',
      level4: 'Assurance vie',
    },
  },
  '65.12': {
    code: '65.12',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Insurance, reinsurance and pension funding, except compulsory social security',
      level3: 'Insurance',
      level4: 'Non-life insurance',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: 'Assurance',
      level3: 'Assurance',
      level4: 'Autres assurances',
    },
  },
  '65.20': {
    code: '65.20',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Insurance, reinsurance and pension funding, except compulsory social security',
      level3: 'Insurance',
      level4: 'Reinsurance',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: 'Assurance',
      level3: 'Assurance',
      level4: 'Réassurance',
    },
  },
  '65.30': {
    code: '65.30',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Insurance, reinsurance and pension funding, except compulsory social security',
      level3: 'Insurance',
      level4: 'Pension funding',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: 'Assurance',
      level3: 'Assurance',
      level4: 'Caisses de retraite',
    },
  },
  '66.11': {
    code: '66.11',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4: 'Administration of financial markets',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4: 'Administration de marchés financiers',
    },
  },
  '66.12': {
    code: '66.12',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4: 'Security and commodity contracts brokerage',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4: 'Courtage de valeurs mobilières et de marchandises',
    },
  },
  '66.19': {
    code: '66.19',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4:
        'Other activities auxiliary to financial services, except insurance and pension funding',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4:
        'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite',
    },
  },
  '66.21': {
    code: '66.21',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4: 'Risk and damage evaluation',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4: 'Évaluation des risques et dommages',
    },
  },
  '66.22': {
    code: '66.22',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4: 'Activities of insurance agents and brokers',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4: "Activités des agents et courtiers d'assurances",
    },
  },
  '66.29': {
    code: '66.29',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4: 'Other activities auxiliary to insurance and pension funding',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4:
        "Autres activités auxiliaires d'assurance et de caisses de retraite",
    },
  },
  '66.30': {
    code: '66.30',
    en: {
      level1: 'Financial and insurance activities',
      level2:
        'Activities auxiliary to financial services and insurance activities',
      level3:
        'Activities auxiliary to financial services, except insurance and pension funding',
      level4: 'Fund management activities',
    },
    fr: {
      level1: "Activités financières et d'assurance",
      level2: "Activités auxiliaires de services financiers et d'assurance",
      level3:
        'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
      level4: 'Gestion de fonds',
    },
  },
  '68.10': {
    code: '68.10',
    en: {
      level1: 'Real estate activities',
      level2: 'Real estate activities',
      level3: 'Buying and selling of own real estate',
      level4: 'Buying and selling of own real estate',
    },
    fr: {
      level1: 'Activités immobilières',
      level2: 'Activités immobilières',
      level3: 'Activités des marchands de biens immobiliers',
      level4: 'Activités des marchands de biens immobiliers',
    },
  },
  '68.20': {
    code: '68.20',
    en: {
      level1: 'Real estate activities',
      level2: 'Real estate activities',
      level3: 'Buying and selling of own real estate',
      level4: 'Rental and operating of own or leased real estate',
    },
    fr: {
      level1: 'Activités immobilières',
      level2: 'Activités immobilières',
      level3: 'Activités des marchands de biens immobiliers',
      level4: 'Location et exploitation de biens immobiliers propres ou loués',
    },
  },
  '68.31': {
    code: '68.31',
    en: {
      level1: 'Real estate activities',
      level2: 'Real estate activities',
      level3: 'Buying and selling of own real estate',
      level4: 'Real estate agencies',
    },
    fr: {
      level1: 'Activités immobilières',
      level2: 'Activités immobilières',
      level3: 'Activités des marchands de biens immobiliers',
      level4: 'Agences immobilières',
    },
  },
  '68.32': {
    code: '68.32',
    en: {
      level1: 'Real estate activities',
      level2: 'Real estate activities',
      level3: 'Buying and selling of own real estate',
      level4: 'Management of real estate on a fee or contract basis',
    },
    fr: {
      level1: 'Activités immobilières',
      level2: 'Activités immobilières',
      level3: 'Activités des marchands de biens immobiliers',
      level4: 'Administration de biens immobiliers',
    },
  },
  '69.10': {
    code: '69.10',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Legal and accounting activities',
      level3: 'Legal activities',
      level4: 'Legal activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Activités juridiques et comptables',
      level3: 'Activités juridiques',
      level4: 'Activités juridiques',
    },
  },
  '69.20': {
    code: '69.20',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Legal and accounting activities',
      level3: 'Legal activities',
      level4:
        'Accounting, bookkeeping and auditing activities; tax consultancy',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Activités juridiques et comptables',
      level3: 'Activités juridiques',
      level4: 'Activités comptables',
    },
  },
  '70.10': {
    code: '70.10',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Activities of head offices; management consultancy activities',
      level3: 'Activities of head offices',
      level4: 'Activities of head offices',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Activités des sièges sociaux; conseil de gestion',
      level3: 'Activités des sièges sociaux',
      level4: 'Activités des sièges sociaux',
    },
  },
  '70.21': {
    code: '70.21',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Activities of head offices; management consultancy activities',
      level3: 'Activities of head offices',
      level4: 'Public relations and communication activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Activités des sièges sociaux; conseil de gestion',
      level3: 'Activités des sièges sociaux',
      level4: 'Conseil en relations publiques et communication',
    },
  },
  '70.22': {
    code: '70.22',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Activities of head offices; management consultancy activities',
      level3: 'Activities of head offices',
      level4: 'Business and other management consultancy activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Activités des sièges sociaux; conseil de gestion',
      level3: 'Activités des sièges sociaux',
      level4: 'Conseil pour les affaires et autres conseils de gestion',
    },
  },
  '71.11': {
    code: '71.11',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2:
        'Architectural and engineering activities; technical testing and analysis',
      level3:
        'Architectural and engineering activities and related technical consultancy',
      level4: 'Architectural activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2:
        "Activités d'architecture et d'ingénierie; activités de contrôle et analyses techniques",
      level3: "Activités d'architecture et d'ingénierie",
      level4: "Activités d'architecture",
    },
  },
  '71.12': {
    code: '71.12',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2:
        'Architectural and engineering activities; technical testing and analysis',
      level3:
        'Architectural and engineering activities and related technical consultancy',
      level4: 'Engineering activities and related technical consultancy',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2:
        "Activités d'architecture et d'ingénierie; activités de contrôle et analyses techniques",
      level3: "Activités d'architecture et d'ingénierie",
      level4: "Activités d'ingénierie",
    },
  },
  '71.20': {
    code: '71.20',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2:
        'Architectural and engineering activities; technical testing and analysis',
      level3:
        'Architectural and engineering activities and related technical consultancy',
      level4: 'Technical testing and analysis',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2:
        "Activités d'architecture et d'ingénierie; activités de contrôle et analyses techniques",
      level3: "Activités d'architecture et d'ingénierie",
      level4: 'Activités de contrôle et analyses techniques',
    },
  },
  '72.11': {
    code: '72.11',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Scientific research and development',
      level3:
        'Research and experimental development on natural sciences and engineering',
      level4: 'Research and experimental development on biotechnology',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Recherche développement scientifique',
      level3: 'Recherche-développement en sciences physiques et naturelles',
      level4: 'Recherche-développement en biotechnologie',
    },
  },
  '72.19': {
    code: '72.19',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Scientific research and development',
      level3:
        'Research and experimental development on natural sciences and engineering',
      level4:
        'Other research and experimental development on natural sciences and engineering',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Recherche développement scientifique',
      level3: 'Recherche-développement en sciences physiques et naturelles',
      level4:
        'Recherche-développement en autres sciences physiques et naturelles',
    },
  },
  '72.20': {
    code: '72.20',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Scientific research and development',
      level3:
        'Research and experimental development on natural sciences and engineering',
      level4:
        'Research and experimental development on social sciences and humanities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Recherche développement scientifique',
      level3: 'Recherche-développement en sciences physiques et naturelles',
      level4: 'Recherche-développement en sciences humaines et sociales',
    },
  },
  '73.11': {
    code: '73.11',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Advertising and market research',
      level3: 'Advertising',
      level4: 'Advertising agencies',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Publicité et études de marché',
      level3: 'Publicité',
      level4: 'Activités des agences de publicité',
    },
  },
  '73.12': {
    code: '73.12',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Advertising and market research',
      level3: 'Advertising',
      level4: 'Media representation',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Publicité et études de marché',
      level3: 'Publicité',
      level4: 'Régie publicitaire de médias',
    },
  },
  '73.20': {
    code: '73.20',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Advertising and market research',
      level3: 'Advertising',
      level4: 'Market research and public opinion polling',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Publicité et études de marché',
      level3: 'Publicité',
      level4: 'Études de marché et sondages',
    },
  },
  '74.10': {
    code: '74.10',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Other professional, scientific and technical activities',
      level3: 'Specialised design activities',
      level4: 'Specialised design activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Autres activités spécialisées, scientifiques et techniques',
      level3: 'Activités spécialisées de design',
      level4: 'Activités spécialisées de design',
    },
  },
  '74.20': {
    code: '74.20',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Other professional, scientific and technical activities',
      level3: 'Specialised design activities',
      level4: 'Photographic activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Autres activités spécialisées, scientifiques et techniques',
      level3: 'Activités spécialisées de design',
      level4: 'Activités photographiques',
    },
  },
  '74.30': {
    code: '74.30',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Other professional, scientific and technical activities',
      level3: 'Specialised design activities',
      level4: 'Translation and interpretation activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Autres activités spécialisées, scientifiques et techniques',
      level3: 'Activités spécialisées de design',
      level4: 'Traduction et interprétation',
    },
  },
  '74.90': {
    code: '74.90',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Other professional, scientific and technical activities',
      level3: 'Specialised design activities',
      level4: 'Other professional, scientific and technical activities n.e.c.',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Autres activités spécialisées, scientifiques et techniques',
      level3: 'Activités spécialisées de design',
      level4:
        'Autres activités spécialisées, scientifiques et techniques n.c.a.',
    },
  },
  '75.00': {
    code: '75.00',
    en: {
      level1: 'Professional, scientific and technical activities',
      level2: 'Veterinary activities',
      level3: 'Veterinary activities',
      level4: 'Veterinary activities',
    },
    fr: {
      level1: 'Activités spécialisées, scientifiques et techniques',
      level2: 'Activités vétérinaires',
      level3: 'Activités vétérinaires',
      level4: 'Activités vétérinaires',
    },
  },
  '77.11': {
    code: '77.11',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of cars and light motor vehicles',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4:
        'Location et location-bail de voitures et de véhicules automobiles légers',
    },
  },
  '77.12': {
    code: '77.12',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of trucks',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4: 'Location et location-bail de camions',
    },
  },
  '77.21': {
    code: '77.21',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of recreational and sports goods',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4: "Location et location-bail d'articles de loisirs et de sport",
    },
  },
  '77.22': {
    code: '77.22',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental of video tapes and disks',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4: 'Location de vidéocassettes et disques vidéo',
    },
  },
  '77.29': {
    code: '77.29',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of other personal and household goods',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4:
        "Location et location-bail d'autres biens personnels et domestiques",
    },
  },
  '77.31': {
    code: '77.31',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of agricultural machinery and equipment',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4: 'Location et location-bail de machines et équipements agricoles',
    },
  },
  '77.32': {
    code: '77.32',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4:
        'Rental and leasing of construction and civil engineering machinery and equipment',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4:
        'Location et location bail de machines et équipements pour la construction',
    },
  },
  '77.33': {
    code: '77.33',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4:
        'Rental and leasing of office machinery and equipment (including computers)',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4:
        'Location et location-bail de machines de bureau et de matériel informatique',
    },
  },
  '77.34': {
    code: '77.34',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of water transport equipment',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4: 'Location et location-bail de matériels de transport par eau',
    },
  },
  '77.35': {
    code: '77.35',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4: 'Rental and leasing of air transport equipment',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4: 'Location et location-bail de matériels de transport aérien',
    },
  },
  '77.39': {
    code: '77.39',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4:
        'Rental and leasing of other machinery, equipment and tangible goods n.e.c.',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4:
        'Location et location-bail d’autres machines, équipements et biens matériels n.c.a.',
    },
  },
  '77.40': {
    code: '77.40',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Rental and leasing activities',
      level3: 'Rental and leasing of motor vehicles',
      level4:
        'Leasing of intellectual property and similar products, except copyrighted works',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Activités de location et location-bail',
      level3: 'Location et location-bail de véhicules automobiles',
      level4:
        "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
    },
  },
  '78.10': {
    code: '78.10',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Employment activities',
      level3: 'Activities of employment placement agencies',
      level4: 'Activities of employment placement agencies',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: "Activités liées à l'emploi",
      level3: "Activités des agences de placement de main-d'œuvre",
      level4: "Activités des agences de placement de main-d'œuvre",
    },
  },
  '78.20': {
    code: '78.20',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Employment activities',
      level3: 'Activities of employment placement agencies',
      level4: 'Temporary employment agency activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: "Activités liées à l'emploi",
      level3: "Activités des agences de placement de main-d'œuvre",
      level4: 'Activités des agences de travail temporaire',
    },
  },
  '78.30': {
    code: '78.30',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Employment activities',
      level3: 'Activities of employment placement agencies',
      level4: 'Other human resources provision',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: "Activités liées à l'emploi",
      level3: "Activités des agences de placement de main-d'œuvre",
      level4: 'Autre mise à disposition de ressources humaines',
    },
  },
  '79.11': {
    code: '79.11',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Travel agency, tour operator and other reservation service and related activities',
      level3: 'Travel agency and tour operator activities',
      level4: 'Travel agency activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités des agences de voyage, voyagistes, services de réservation et activités connexes',
      level3: 'Activités des agences de voyage et voyagistes',
      level4: 'Activités des agences de voyage',
    },
  },
  '79.12': {
    code: '79.12',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Travel agency, tour operator and other reservation service and related activities',
      level3: 'Travel agency and tour operator activities',
      level4: 'Tour operator activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités des agences de voyage, voyagistes, services de réservation et activités connexes',
      level3: 'Activités des agences de voyage et voyagistes',
      level4: 'Activités des voyagistes',
    },
  },
  '79.90': {
    code: '79.90',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Travel agency, tour operator and other reservation service and related activities',
      level3: 'Travel agency and tour operator activities',
      level4: 'Other reservation service and related activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités des agences de voyage, voyagistes, services de réservation et activités connexes',
      level3: 'Activités des agences de voyage et voyagistes',
      level4: 'Autres services de réservation et activités connexes',
    },
  },
  '80.10': {
    code: '80.10',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Security and investigation activities',
      level3: 'Private security activities',
      level4: 'Private security activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Enquêtes et sécurité',
      level3: 'Activités de sécurité privée',
      level4: 'Activités de sécurité privée',
    },
  },
  '80.20': {
    code: '80.20',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Security and investigation activities',
      level3: 'Private security activities',
      level4: 'Security systems service activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Enquêtes et sécurité',
      level3: 'Activités de sécurité privée',
      level4: 'Activités liées aux systèmes de sécurité',
    },
  },
  '80.30': {
    code: '80.30',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Security and investigation activities',
      level3: 'Private security activities',
      level4: 'Investigation activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Enquêtes et sécurité',
      level3: 'Activités de sécurité privée',
      level4: "Activités d'enquête",
    },
  },
  '81.10': {
    code: '81.10',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Services to buildings and landscape activities',
      level3: 'Combined facilities support activities',
      level4: 'Combined facilities support activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Services relatifs aux bâtiments et aménagement paysager',
      level3: 'Activités combinées de soutien lié aux bâtiments',
      level4: 'Activités combinées de soutien lié aux bâtiments',
    },
  },
  '81.21': {
    code: '81.21',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Services to buildings and landscape activities',
      level3: 'Combined facilities support activities',
      level4: 'General cleaning of buildings',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Services relatifs aux bâtiments et aménagement paysager',
      level3: 'Activités combinées de soutien lié aux bâtiments',
      level4: 'Nettoyage courant des bâtiments',
    },
  },
  '81.22': {
    code: '81.22',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Services to buildings and landscape activities',
      level3: 'Combined facilities support activities',
      level4: 'Other building and industrial cleaning activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Services relatifs aux bâtiments et aménagement paysager',
      level3: 'Activités combinées de soutien lié aux bâtiments',
      level4:
        'Autres activités de nettoyage des bâtiments et nettoyage industriel',
    },
  },
  '81.29': {
    code: '81.29',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Services to buildings and landscape activities',
      level3: 'Combined facilities support activities',
      level4: 'Other cleaning activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Services relatifs aux bâtiments et aménagement paysager',
      level3: 'Activités combinées de soutien lié aux bâtiments',
      level4: 'Autres activités de nettoyage',
    },
  },
  '81.30': {
    code: '81.30',
    en: {
      level1: 'Administrative and support service activities',
      level2: 'Services to buildings and landscape activities',
      level3: 'Combined facilities support activities',
      level4: 'Landscape service activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2: 'Services relatifs aux bâtiments et aménagement paysager',
      level3: 'Activités combinées de soutien lié aux bâtiments',
      level4: "Services d'aménagement paysager",
    },
  },
  '82.11': {
    code: '82.11',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4: 'Combined office administrative service activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4: 'Services administratifs combinés de bureau',
    },
  },
  '82.19': {
    code: '82.19',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4:
        'Photocopying, document preparation and other specialised office support activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4:
        'Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau',
    },
  },
  '82.20': {
    code: '82.20',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4: 'Activities of call centres',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4: "Activités de centres d'appels",
    },
  },
  '82.30': {
    code: '82.30',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4: 'Organisation of conventions and trade shows',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4: 'Organisation de salons professionnels et congrès',
    },
  },
  '82.91': {
    code: '82.91',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4: 'Activities of collection agencies and credit bureaus',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4:
        "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
    },
  },
  '82.92': {
    code: '82.92',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4: 'Packaging activities',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4: 'Activités de conditionnement',
    },
  },
  '82.99': {
    code: '82.99',
    en: {
      level1: 'Administrative and support service activities',
      level2:
        'Office administrative, office support and other business support activities',
      level3: 'Office administrative and support activities',
      level4: 'Other business support service activities n.e.c.',
    },
    fr: {
      level1: 'Activités de services administratifs et de soutien',
      level2:
        'Activités administratives et autres activités de soutien aux entreprises',
      level3: 'Activités administratives',
      level4: 'Autres activités de soutien aux entreprises n.c.a.',
    },
  },
  '84.11': {
    code: '84.11',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'General public administration activities',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Administration publique générale',
    },
  },
  '84.12': {
    code: '84.12',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4:
        'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4:
        'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale ',
    },
  },
  '84.13': {
    code: '84.13',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4:
        'Regulation of and contribution to more efficient operation of businesses',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Administration publique (tutelle) des activités économiques',
    },
  },
  '84.21': {
    code: '84.21',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'Foreign affairs',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Affaires étrangères',
    },
  },
  '84.22': {
    code: '84.22',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'Defence activities',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Défense',
    },
  },
  '84.23': {
    code: '84.23',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'Justice and judicial activities',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Justice',
    },
  },
  '84.24': {
    code: '84.24',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'Public order and safety activities',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Activités d’ordre public et de sécurité',
    },
  },
  '84.25': {
    code: '84.25',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'Fire service activities',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Services du feu et de secours',
    },
  },
  '84.30': {
    code: '84.30',
    en: {
      level1: 'Public administration and defence; compulsory social security',
      level2: 'Public administration and defence; compulsory social security',
      level3:
        'Administration of the State and the economic and social policy of the community',
      level4: 'Compulsory social security activities',
    },
    fr: {
      level1: 'Administration publique',
      level2:
        'Administration publique et défense; sécurité sociale obligatoire',
      level3: 'Administration générale, économique et sociale',
      level4: 'Sécurité sociale obligatoire',
    },
  },
  '85.10': {
    code: '85.10',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Pre-primary education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement pré-primaire',
    },
  },
  '85.20': {
    code: '85.20',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Primary education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement primaire',
    },
  },
  '85.31': {
    code: '85.31',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'General secondary education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement secondaire général',
    },
  },
  '85.32': {
    code: '85.32',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Technical and vocational secondary education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement secondaire technique ou professionnel',
    },
  },
  '85.41': {
    code: '85.41',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Post-secondary non-tertiary education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement post-secondaire non supérieur',
    },
  },
  '85.42': {
    code: '85.42',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Tertiary education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement supérieur',
    },
  },
  '85.51': {
    code: '85.51',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Sports and recreation education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: "Enseignement de disciplines sportives et d'activités de loisirs",
    },
  },
  '85.52': {
    code: '85.52',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Cultural education',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement culturel',
    },
  },
  '85.53': {
    code: '85.53',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Driving school activities',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignement de la conduite',
    },
  },
  '85.59': {
    code: '85.59',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Other education n.e.c.',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: 'Enseignements divers',
    },
  },
  '85.60': {
    code: '85.60',
    en: {
      level1: 'Education',
      level2: 'Education',
      level3: 'Pre-primary education',
      level4: 'Educational support activities',
    },
    fr: {
      level1: 'Enseignement',
      level2: 'Enseignement',
      level3: 'Enseignement pré-primaire',
      level4: "Activités de soutien à l'enseignement",
    },
  },
  '86.10': {
    code: '86.10',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Human health activities',
      level3: 'Hospital activities',
      level4: 'Hospital activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Activités pour la santé humaine',
      level3: 'Activités hospitalières',
      level4: 'Activités hospitalières',
    },
  },
  '86.21': {
    code: '86.21',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Human health activities',
      level3: 'Hospital activities',
      level4: 'General medical practice activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Activités pour la santé humaine',
      level3: 'Activités hospitalières',
      level4: 'Activité des médecins généralistes',
    },
  },
  '86.22': {
    code: '86.22',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Human health activities',
      level3: 'Hospital activities',
      level4: 'Specialist medical practice activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Activités pour la santé humaine',
      level3: 'Activités hospitalières',
      level4: 'Activité des médecins spécialistes',
    },
  },
  '86.23': {
    code: '86.23',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Human health activities',
      level3: 'Hospital activities',
      level4: 'Dental practice activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Activités pour la santé humaine',
      level3: 'Activités hospitalières',
      level4: 'Pratique dentaire',
    },
  },
  '86.90': {
    code: '86.90',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Human health activities',
      level3: 'Hospital activities',
      level4: 'Other human health activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Activités pour la santé humaine',
      level3: 'Activités hospitalières',
      level4: 'Autres activités pour la santé humaine',
    },
  },
  '87.10': {
    code: '87.10',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Residential care activities',
      level3: 'Residential nursing care activities',
      level4: 'Residential nursing care activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Hébergement médico-social et social',
      level3: 'Hébergement médicalisé',
      level4: 'Hébergement médicalisé',
    },
  },
  '87.20': {
    code: '87.20',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Residential care activities',
      level3: 'Residential nursing care activities',
      level4:
        'Residential care activities for mental retardation, mental health and substance abuse',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Hébergement médico-social et social',
      level3: 'Hébergement médicalisé',
      level4:
        'Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes ',
    },
  },
  '87.30': {
    code: '87.30',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Residential care activities',
      level3: 'Residential nursing care activities',
      level4: 'Residential care activities for the elderly and disabled',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Hébergement médico-social et social',
      level3: 'Hébergement médicalisé',
      level4:
        'Hébergement social pour personnes âgées ou handicapées physiques',
    },
  },
  '87.90': {
    code: '87.90',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Residential care activities',
      level3: 'Residential nursing care activities',
      level4: 'Other residential care activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Hébergement médico-social et social',
      level3: 'Hébergement médicalisé',
      level4: "Autres activités d'hébergement social",
    },
  },
  '88.10': {
    code: '88.10',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Social work activities without accommodation',
      level3:
        'Social work activities without accommodation for the elderly and disabled',
      level4:
        'Social work activities without accommodation for the elderly and disabled',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Action sociale sans hébergement',
      level3:
        'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées',
      level4:
        'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées',
    },
  },
  '88.91': {
    code: '88.91',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Social work activities without accommodation',
      level3:
        'Social work activities without accommodation for the elderly and disabled',
      level4: 'Child day-care activities',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Action sociale sans hébergement',
      level3:
        'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées',
      level4: 'Action sociale sans hébergement pour jeunes enfants',
    },
  },
  '88.99': {
    code: '88.99',
    en: {
      level1: 'Human health and social work activities',
      level2: 'Social work activities without accommodation',
      level3:
        'Social work activities without accommodation for the elderly and disabled',
      level4: 'Other social work activities without accommodation n.e.c.',
    },
    fr: {
      level1: 'Santé humaine et action sociale',
      level2: 'Action sociale sans hébergement',
      level3:
        'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées',
      level4: 'Autre action sociale sans hébergement n.c.a.',
    },
  },
  '90.01': {
    code: '90.01',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Creative, arts and entertainment activities',
      level3: 'Creative, arts and entertainment activities',
      level4: 'Performing arts',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités créatives, artistiques et de spectacle ',
      level3: 'Activités créatives, artistiques et de spectacle ',
      level4: 'Arts du spectacle vivant',
    },
  },
  '90.02': {
    code: '90.02',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Creative, arts and entertainment activities',
      level3: 'Creative, arts and entertainment activities',
      level4: 'Support activities to performing arts',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités créatives, artistiques et de spectacle ',
      level3: 'Activités créatives, artistiques et de spectacle ',
      level4: 'Activités de soutien au spectacle vivant',
    },
  },
  '90.03': {
    code: '90.03',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Creative, arts and entertainment activities',
      level3: 'Creative, arts and entertainment activities',
      level4: 'Artistic creation',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités créatives, artistiques et de spectacle ',
      level3: 'Activités créatives, artistiques et de spectacle ',
      level4: 'Création artistique',
    },
  },
  '90.04': {
    code: '90.04',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Creative, arts and entertainment activities',
      level3: 'Creative, arts and entertainment activities',
      level4: 'Operation of arts facilities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités créatives, artistiques et de spectacle ',
      level3: 'Activités créatives, artistiques et de spectacle ',
      level4: 'Gestion de salles de spectacles',
    },
  },
  '91.01': {
    code: '91.01',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Libraries, archives, museums and other cultural activities',
      level3: 'Libraries, archives, museums and other cultural activities',
      level4: 'Library and archives activities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Bibliothèques, archives, musées et autres activités culturelles',
      level3: 'Bibliothèques, archives, musées et autres activités culturelles',
      level4: 'Gestion des bibliothèques et des archives',
    },
  },
  '91.02': {
    code: '91.02',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Libraries, archives, museums and other cultural activities',
      level3: 'Libraries, archives, museums and other cultural activities',
      level4: 'Museums activities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Bibliothèques, archives, musées et autres activités culturelles',
      level3: 'Bibliothèques, archives, musées et autres activités culturelles',
      level4: 'Gestion des musées',
    },
  },
  '91.03': {
    code: '91.03',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Libraries, archives, museums and other cultural activities',
      level3: 'Libraries, archives, museums and other cultural activities',
      level4:
        'Operation of historical sites and buildings and similar visitor attractions',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Bibliothèques, archives, musées et autres activités culturelles',
      level3: 'Bibliothèques, archives, musées et autres activités culturelles',
      level4:
        'Gestion des sites et monuments historiques et des attractions touristiques similaires',
    },
  },
  '91.04': {
    code: '91.04',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Libraries, archives, museums and other cultural activities',
      level3: 'Libraries, archives, museums and other cultural activities',
      level4: 'Botanical and zoological gardens and nature reserves activities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Bibliothèques, archives, musées et autres activités culturelles',
      level3: 'Bibliothèques, archives, musées et autres activités culturelles',
      level4:
        'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
    },
  },
  '92.00': {
    code: '92.00',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Gambling and betting activities',
      level3: 'Gambling and betting activities',
      level4: 'Gambling and betting activities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: "Organisation de jeux de hasard et d'argent",
      level3: "Organisation de jeux de hasard et d'argent",
      level4: "Organisation de jeux de hasard et d'argent",
    },
  },
  '93.11': {
    code: '93.11',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Sports activities and amusement and recreation activities',
      level3: 'Sports activities',
      level4: 'Operation of sports facilities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités sportives, récréatives et de loisirs',
      level3: 'Activités liées au sport',
      level4: "Gestion d'installations sportives",
    },
  },
  '93.12': {
    code: '93.12',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Sports activities and amusement and recreation activities',
      level3: 'Sports activities',
      level4: 'Activities of sports clubs',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités sportives, récréatives et de loisirs',
      level3: 'Activités liées au sport',
      level4: 'Activités de clubs de sports',
    },
  },
  '93.13': {
    code: '93.13',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Sports activities and amusement and recreation activities',
      level3: 'Sports activities',
      level4: 'Fitness facilities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités sportives, récréatives et de loisirs',
      level3: 'Activités liées au sport',
      level4: 'Activités des centres de culture physique',
    },
  },
  '93.19': {
    code: '93.19',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Sports activities and amusement and recreation activities',
      level3: 'Sports activities',
      level4: 'Other sports activities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités sportives, récréatives et de loisirs',
      level3: 'Activités liées au sport',
      level4: 'Autres activités liées au sport',
    },
  },
  '93.21': {
    code: '93.21',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Sports activities and amusement and recreation activities',
      level3: 'Sports activities',
      level4: 'Activities of amusement parks and theme parks',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités sportives, récréatives et de loisirs',
      level3: 'Activités liées au sport',
      level4: "Activités des parcs d'attractions et parcs à thèmes",
    },
  },
  '93.29': {
    code: '93.29',
    en: {
      level1: 'Arts, entertainment and recreation',
      level2: 'Sports activities and amusement and recreation activities',
      level3: 'Sports activities',
      level4: 'Other amusement and recreation activities',
    },
    fr: {
      level1: 'Arts, spectacles et activités récréatives',
      level2: 'Activités sportives, récréatives et de loisirs',
      level3: 'Activités liées au sport',
      level4: 'Autres activités récréatives et de loisirs',
    },
  },
  '94.11': {
    code: '94.11',
    en: {
      level1: 'Other service activities',
      level2: 'Activities of membership organisations',
      level3:
        'Activities of business, employers and professional membership organisations',
      level4: 'Activities of business and employers membership organisations',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Activités des organisations associatives',
      level3:
        'Activités des organisations économiques, patronales et professionnelles',
      level4: 'Activités des organisations patronales et consulaires',
    },
  },
  '94.12': {
    code: '94.12',
    en: {
      level1: 'Other service activities',
      level2: 'Activities of membership organisations',
      level3:
        'Activities of business, employers and professional membership organisations',
      level4: 'Activities of professional membership organisations',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Activités des organisations associatives',
      level3:
        'Activités des organisations économiques, patronales et professionnelles',
      level4: 'Activités des organisations professionnelles',
    },
  },
  '94.20': {
    code: '94.20',
    en: {
      level1: 'Other service activities',
      level2: 'Activities of membership organisations',
      level3:
        'Activities of business, employers and professional membership organisations',
      level4: 'Activities of trade unions',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Activités des organisations associatives',
      level3:
        'Activités des organisations économiques, patronales et professionnelles',
      level4: 'Activités des syndicats de salariés',
    },
  },
  '94.91': {
    code: '94.91',
    en: {
      level1: 'Other service activities',
      level2: 'Activities of membership organisations',
      level3:
        'Activities of business, employers and professional membership organisations',
      level4: 'Activities of religious organisations',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Activités des organisations associatives',
      level3:
        'Activités des organisations économiques, patronales et professionnelles',
      level4: 'Activités des organisations religieuses',
    },
  },
  '94.92': {
    code: '94.92',
    en: {
      level1: 'Other service activities',
      level2: 'Activities of membership organisations',
      level3:
        'Activities of business, employers and professional membership organisations',
      level4: 'Activities of political organisations',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Activités des organisations associatives',
      level3:
        'Activités des organisations économiques, patronales et professionnelles',
      level4: 'Activités des organisations politiques',
    },
  },
  '94.99': {
    code: '94.99',
    en: {
      level1: 'Other service activities',
      level2: 'Activities of membership organisations',
      level3:
        'Activities of business, employers and professional membership organisations',
      level4: 'Activities of other membership organisations n.e.c.',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Activités des organisations associatives',
      level3:
        'Activités des organisations économiques, patronales et professionnelles',
      level4: 'Activités des organisations associatives n.c.a.',
    },
  },
  '95.11': {
    code: '95.11',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of computers and peripheral equipment',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: "Réparation d'ordinateurs et d'équipements périphériques",
    },
  },
  '95.12': {
    code: '95.12',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of communication equipment',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: "Réparation d'équipements de communication",
    },
  },
  '95.21': {
    code: '95.21',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of consumer electronics',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: 'Réparation de produits électroniques grand public',
    },
  },
  '95.22': {
    code: '95.22',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of household appliances and home and garden equipment',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4:
        "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
    },
  },
  '95.23': {
    code: '95.23',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of footwear and leather goods',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: "Réparation de chaussures et d'articles en cuir",
    },
  },
  '95.24': {
    code: '95.24',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of furniture and home furnishings',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: "Réparation de meubles et d'équipements du foyer",
    },
  },
  '95.25': {
    code: '95.25',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of watches, clocks and jewellery',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: "Réparation d'articles d'horlogerie et de bijouterie",
    },
  },
  '95.29': {
    code: '95.29',
    en: {
      level1: 'Other service activities',
      level2: 'Repair of computers and personal and household goods',
      level3: 'Repair of computers and communication equipment',
      level4: 'Repair of other personal and household goods',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: "Réparation d'ordinateurs et de biens personnels et domestiques",
      level3: "Réparation d'ordinateurs et d'équipements de communication",
      level4: "Réparation d'autres biens personnels et domestiques",
    },
  },
  '96.01': {
    code: '96.01',
    en: {
      level1: 'Other service activities',
      level2: 'Other personal service activities',
      level3: 'Other personal service activities',
      level4: 'Washing and (dry-)cleaning of textile and fur products',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Autres services personnels',
      level3: 'Autres services personnels',
      level4: 'Blanchisserie teinturerie',
    },
  },
  '96.02': {
    code: '96.02',
    en: {
      level1: 'Other service activities',
      level2: 'Other personal service activities',
      level3: 'Other personal service activities',
      level4: 'Hairdressing and other beauty treatment',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Autres services personnels',
      level3: 'Autres services personnels',
      level4: 'Coiffure et soins de beauté',
    },
  },
  '96.03': {
    code: '96.03',
    en: {
      level1: 'Other service activities',
      level2: 'Other personal service activities',
      level3: 'Other personal service activities',
      level4: 'Funeral and related activities',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Autres services personnels',
      level3: 'Autres services personnels',
      level4: 'Services funéraires',
    },
  },
  '96.04': {
    code: '96.04',
    en: {
      level1: 'Other service activities',
      level2: 'Other personal service activities',
      level3: 'Other personal service activities',
      level4: 'Physical well-being activities',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Autres services personnels',
      level3: 'Autres services personnels',
      level4: 'Entretien corporel',
    },
  },
  '96.09': {
    code: '96.09',
    en: {
      level1: 'Other service activities',
      level2: 'Other personal service activities',
      level3: 'Other personal service activities',
      level4: 'Other personal service activities n.e.c.',
    },
    fr: {
      level1: 'Autres activités de services',
      level2: 'Autres services personnels',
      level3: 'Autres services personnels',
      level4: 'Autres services personnels n.c.a.',
    },
  },
  '97.00': {
    code: '97.00',
    en: {
      level1:
        'Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
      level2: 'Activities of households as employers of domestic personnel',
      level3: 'Activities of households as employers of domestic personnel',
      level4: 'Activities of households as employers of domestic personnel',
    },
    fr: {
      level1:
        "Activités des ménages en tant qu'employeurs; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
      level2:
        "Activités des ménages en tant qu'employeurs de personnel domestique",
      level3:
        "Activités des ménages en tant qu'employeurs de personnel domestique",
      level4:
        "Activités des ménages en tant qu'employeurs de personnel domestique",
    },
  },
  '98.10': {
    code: '98.10',
    en: {
      level1:
        'Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
      level2:
        'Undifferentiated goods- and services-producing activities of private households for own use',
      level3:
        'Undifferentiated goods-producing activities of private households for own use',
      level4:
        'Undifferentiated goods-producing activities of private households for own use',
    },
    fr: {
      level1:
        "Activités des ménages en tant qu'employeurs; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
      level2:
        'Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre',
      level3:
        'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
      level4:
        'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
    },
  },
  '98.20': {
    code: '98.20',
    en: {
      level1:
        'Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
      level2:
        'Undifferentiated goods- and services-producing activities of private households for own use',
      level3:
        'Undifferentiated goods-producing activities of private households for own use',
      level4:
        'Undifferentiated service-producing activities of private households for own use',
    },
    fr: {
      level1:
        "Activités des ménages en tant qu'employeurs; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
      level2:
        'Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre',
      level3:
        'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
      level4:
        'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre',
    },
  },
  '99.00': {
    code: '99.00',
    en: {
      level1: 'Activities of extraterritorial organisations and bodies',
      level2: 'Activities of extraterritorial organisations and bodies',
      level3: 'Activities of extraterritorial organisations and bodies',
      level4: 'Activities of extraterritorial organisations and bodies',
    },
    fr: {
      level1: 'Activités extra territoriales',
      level2: 'Activités des organisations et organismes extraterritoriaux',
      level3: 'Activités des organisations et organismes extraterritoriaux',
      level4: 'Activités des organisations et organismes extraterritoriaux',
    },
  },
};
