import { Stack, Tooltip, tooltipClasses } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { DocumentStatusEnum } from 'business/fund-manager/fund/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { DocumentStatus } from 'ui/document-status';
import { InfoIcon } from 'ui/icons';

import styles from './index.module.scss';

export const KycDocumentationToolTip = () => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Stack direction="row" spacing={1}>
      <Stack>
        {t(
          'pages.fundManager.lpCreation.form.KYCDocumentation.documentTypesTableTitle',
        )}
      </Stack>
      <Tooltip
        componentsProps={{
          popper: {
            sx: {
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: palette.backgroundForm,
              },
            },
          },
        }}
        title={
          <Stack>
            <DocumentStatus
              status={DocumentStatusEnum.Expired}
              text={t(
                'pages.fundManager.lpCreation.form.KYCDocumentation.legend.documentsToUdate',
              )}
            />
            <DocumentStatus
              status={DocumentStatusEnum.Validated}
              text={t(
                'pages.fundManager.lpCreation.form.KYCDocumentation.legend.documentsValidated',
              )}
            />
            <DocumentStatus
              status={DocumentStatusEnum.PendingValidation}
              text={t(
                'pages.fundManager.lpCreation.form.KYCDocumentation.legend.documentsPendingValidation',
              )}
            />
            <DocumentStatus
              status={DocumentStatusEnum.Requested}
              text={t(
                'pages.fundManager.lpCreation.form.KYCDocumentation.legend.documentsRequired',
              )}
            />
            <DocumentStatus
              status={DocumentStatusEnum.Declined}
              text={t(
                'pages.fundManager.lpCreation.form.KYCDocumentation.legend.documentsDeclined',
              )}
            />
          </Stack>
        }
      >
        <Stack className={styles.iconWrapper}>
          <InfoIcon />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
