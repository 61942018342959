import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import { OperationDocumenttemplate } from 'business/fund-manager/operation/components/operation-document-template/operation-document-template';
import { OperationEmailTemplateForm } from 'business/fund-manager/operation/components/operation-email-template-form';
import { PreviewEmailingDocumentTable } from 'business/fund-manager/operation/components/preview-email-document-table';
import { OperationCreationStepEnum } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useGetOperationShareLpPreviewQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const CreateOperationShareLpPreviewEmailDocument = () => {
  const { operationId = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useGetOperationShareLpPreviewQuery({
    variables: {
      input: {
        operationId: operationId,
      },
    },
    // Previous steps modifies OperationShareLps and ids are changed
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data) {
    return (
      <OperationCreationContainer
        activeStep={OperationCreationStepEnum.PreviewEmail}
        onPrevious={() =>
          navigate(
            FundManagerRoutes.OperationCreateIdSharesLp.replace(
              ':operationId',
              operationId,
            ),
          )
        }
      >
        <QueryStateDisplay loading={loading} error={error} />;
      </OperationCreationContainer>
    );
  }

  return (
    <OperationCreationContainer
      activeStep={OperationCreationStepEnum.PreviewEmail}
      onNext={() => {
        navigate(
          FundManagerRoutes.OperationCreateIdRecap.replace(
            ':operationId',
            operationId,
          ),
        );
      }}
      onPrevious={() =>
        navigate(
          FundManagerRoutes.OperationCreateIdSharesLp.replace(
            ':operationId',
            operationId,
          ),
        )
      }
      labelAction={t('common.actions.save')}
    >
      {/* DOCUMENTS */}
      <OperationDocumenttemplate />

      {/* EMAIl */}
      <OperationEmailTemplateForm />
      <PreviewEmailingDocumentTable
        data={data?.operationShareLpPreview?.operationShareLps}
      />
    </OperationCreationContainer>
  );
};
