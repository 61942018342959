import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { FundLpDiligenceQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import {
  convertFrom10X8toNumber,
  formatRatioToPercentage,
} from 'technical/currency/formatters';
import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import { Person } from 'technical/get-person-full-name/get-person-full-name.types';
import Link from 'ui/link';

type UltimateBeneficialOwner = Person & {
  ultimateEffectiveControlPercentage?: number;
};
const getUltimateBeneficialOwnersText = (
  ultimateBeneficialOwners?: UltimateBeneficialOwner[],
) =>
  ultimateBeneficialOwners
    ? ultimateBeneficialOwners
        .map(
          ({ ultimateEffectiveControlPercentage, ...personData }) =>
            getPersonFullName(personData) +
            (ultimateEffectiveControlPercentage
              ? ` (${formatRatioToPercentage(
                  convertFrom10X8toNumber(ultimateEffectiveControlPercentage) /
                    100,
                  { precision: 2 },
                )})`
              : ''),
        )
        .join(', ')
    : '';

export function getFundLpDiligenceColumnDefinition(
  t: TFunction,
): ColDef<FundLpDiligenceQuery['fundLpDiligence'][number]>[] {
  return [
    {
      headerName: t('pages.fundManager.fundDiligence.table.lpName'),
      field: 'lpName',
    },
    {
      headerName: t(
        'pages.fundManager.fundDiligence.table.legalRepresentative',
      ),
      colId: 'legalRepresentative',
      valueGetter: ({ data }) =>
        data ? getPersonFullName(data.legalRepresentative) : '',
    },
    {
      headerName: t(
        'pages.fundManager.fundDiligence.table.ultimateBeneficialOwners',
      ),
      colId: 'ultimateBeneficialOwners',
      valueGetter: ({ data }) =>
        getUltimateBeneficialOwnersText(data?.ultimateBeneficialOwners),
      tooltipValueGetter: ({ data }) =>
        getUltimateBeneficialOwnersText(data?.ultimateBeneficialOwners),
    },
    {
      headerName: t('pages.fundManager.fundDiligence.table.country'),
      field: 'country',
      type: AgGridColumnTypesEnum.COUNTRY,
    },
    {
      headerName: t('pages.fundManager.fundDiligence.table.investorType'),
      colId: 'investorType',
      valueGetter: ({ data }) =>
        data?.investorType
          ? t(
              `pages.fundManager.fundDiligence.table.investorType_${data.investorType}`,
            )
          : '',
    },
    {
      headerName: t('pages.fundManager.fundDiligence.table.isComplete'),
      field: 'isComplete',
      type: AgGridColumnTypesEnum.BOOLEAN,
    },
    {
      headerName: t(
        'pages.fundManager.fundDiligence.table.isDocumentationUpToDate',
      ),
      field: 'isDocumentationUpToDate',
      type: AgGridColumnTypesEnum.BOOLEAN,
    },
    {
      headerName: t('pages.fundManager.fundDiligence.table.vigilanceScore'),
      field: 'riskScore',
      type: AgGridColumnTypesEnum.SCORE,
    },
    {
      headerName: t('pages.fundManager.lps.table.details'),
      field: 'id',
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({ value }: ICellRendererParams) => {
        return (
          <Link to={FundManagerRoutes.LpId.replace(':lpId', value)}>
            {t('common.seeDetails')}
          </Link>
        );
      },
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
  ];
}
