import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { getFundLpDiligenceColumnDefinition } from 'business/fund-manager/fund/services/get-fund-lp-diligence-column-definition';
import {
  useFundLpDiligenceQuery,
  FundLpDiligenceQuery,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundDiligence = () => {
  const { t } = useTranslation();
  const { fundId = '' } = useParams();
  const { data, loading, error } = useFundLpDiligenceQuery({
    variables: {
      fundId,
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const headers = getFundLpDiligenceColumnDefinition(t);

  return (
    <AlternativeTable<FundLpDiligenceQuery['fundLpDiligence'][number]>
      fileName="fund-riskassement-list"
      rowData={data ? data.fundLpDiligence : []}
      columnDefs={headers}
      domLayout="autoHeight"
      displaySidebar
      loading={loading}
    />
  );
};
