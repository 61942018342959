export function areAllPropertiesNotNull(obj: any): boolean {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const isNotNull = areAllPropertiesNotNull(obj[key]);
      if (!isNotNull) {
        return false;
      }
    } else if (obj[key] === null) {
      return false;
    }
  }
  return true;
}
