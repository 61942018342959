import { useSnackbar } from 'notistack';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FundReportingCreation } from 'business/fund-manager/fund/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  SharesCommitmentsQuery,
  useDownloadFundReportingPreviewLazyQuery,
  useSharesCommitmentsQuery,
} from 'generated/graphql';
import { downloadFile } from 'technical/download-file';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

import { getFundReportingCommitmentTableDefinition } from './get-fund-reporting-commitment-table-definition';

interface FundReportingCommitmentTableProps {
  fundId: string;
}
export const FundReportingCommitmentTable = ({
  fundId,
}: FundReportingCommitmentTableProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { control } = useFormContext<FundReportingCreation>();

  const [type, shareIds, valuationDate] = useWatch({
    name: ['type', 'shareIds', 'valuationDate'],
    control,
  });

  const [downloadFundReportingPreview] =
    useDownloadFundReportingPreviewLazyQuery();

  const { data, error, loading } = useSharesCommitmentsQuery({
    skip: !type || !shareIds || !valuationDate,
    variables: {
      shareIds: shareIds!,
    },
  });

  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const onClickDownload = async ({
    lpId,
    shareId,
  }: {
    lpId: string;
    shareId: string;
  }) => {
    if (!valuationDate) {
      return;
    }

    const { data: previewData, error: previewError } =
      await downloadFundReportingPreview({
        // For development, if you need to generate a new document each time, you can uncomment the following line (but don't commit it).
        // fetchPolicy: 'network-only',
        variables: {
          input: {
            fundId,
            type,
            shareId,
            valuationDate,
            lpId,
          },
        },
      });
    if (previewError) {
      enqueueSnackbar(t(previewError.message), {
        variant: VariantTypeEnum.ERROR,
      });
    }
    if (previewData) {
      downloadFile(
        previewData.downloadFundReportingPreview.url,
        previewData.downloadFundReportingPreview.fileName,
      );
    }
  };

  const columns = getFundReportingCommitmentTableDefinition(t, onClickDownload);

  return (
    <AlternativeTable<SharesCommitmentsQuery['sharesCommitments'][number]>
      fileName={`${type}-preview`}
      rowData={data?.sharesCommitments ?? []}
      columnDefs={columns}
      domLayout="autoHeight"
    />
  );
};
