import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundManagerEmailTemplatePreviewForm } from 'business/fund-manager/settings/template/components/email-template-preview-form';
import { EmailTemplateCreationForm } from 'business/fund-manager/settings/template/services/types';
import { emailTemplatingFormSchema } from 'business/fund-manager/settings/template/services/validation';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import useModal from 'technical/modal/use-modal';
import { CustomCkeditorInput } from 'ui/custom-ckeditor';
import { CustomModal } from 'ui/custom-modal';
import { FormCard } from 'ui/form';
import { FormInputText } from 'ui/form/form-input-text';

type Props = {
  onSubmit: (value: EmailTemplateCreationForm) => void;
  loading: boolean;
  error: ApolloError | undefined;
  defaultValues:
    | {
        subject: string;
        content: string;
      }
    | undefined;
  hidePreview?: boolean;
};

export const FundManagerEmailTemplateForm = ({
  onSubmit,
  loading,
  error,
  defaultValues,
  hidePreview = false,
}: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const { emailTemplateId } = useParams();

  const { open, handleCloseModal, handleOpenModal } = useModal();

  const { handleSubmit, control, formState, getValues, ...rest } = useForm({
    resolver: yupResolver(emailTemplatingFormSchema),
    defaultValues,
  });

  useEffect(() => {
    rest.register('content');
  });

  const previewValues = {
    subject: getValues('subject'),
    content: getValues('content') ?? '',
    emailTemplateId,
  };

  const isPreviewAvailable =
    formState.isValid &&
    previewValues.subject.length &&
    previewValues.content.length;

  const handlePreview = () => {
    if (isPreviewAvailable) {
      handleOpenModal();
    }
  };

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormCard>
          <Stack spacing={3}>
            <Typography variant="h5" sx={{ color: palette.primary[900] }}>
              {t('pages.fundManager.templates.form.title')}
            </Typography>
            <Stack>
              <FormInputText
                name="subject"
                label={t('pages.fundManager.templates.form.subject')}
                control={control}
                required
              />
            </Stack>
            <Stack>
              <CustomCkeditorInput
                defaultData={defaultValues ? defaultValues.content : undefined}
                label={t('pages.fundManager.templates.form.content')}
                onChange={(_event, editor) => {
                  // Because of the way CKEDITOR works, we cannot use the onChange methods directly here
                  // We register the field line 48 and we then have to manually set the value and trigger the verification
                  const data = editor.getData();
                  rest.setValue('content', data);
                  rest.trigger('content');
                }}
                minHeight={300}
              />
            </Stack>
            <Stack spacing={1}>
              <Button variant="contained" type="submit" disabled={loading}>
                {t('pages.fundManager.FundManagerCompanyUpdate.submit')}
              </Button>
              {!hidePreview ? (
                <Button
                  variant="contained"
                  disabled={loading || !isPreviewAvailable}
                  onClick={handlePreview}
                >
                  {t('pages.fundManager.templates.form.preview.button')}
                </Button>
              ) : null}
              {error ? (
                <FormHelperText error>
                  {t('errors.updateEmailTemplate')}
                </FormHelperText>
              ) : null}
            </Stack>
          </Stack>
        </FormCard>
      </form>
      {!hidePreview ? (
        <CustomModal
          open={open}
          handleClose={handleCloseModal}
          component={
            <FundManagerEmailTemplatePreviewForm
              previewValues={previewValues}
              handleClose={handleCloseModal}
            />
          }
        />
      ) : null}
    </Stack>
  );
};
