import { Navigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { SignatureIframe } from 'business/shared/components/onboarding-subscription-signature-iframe/onboarding-subscription-signature-iframe';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  OnboardingSubscriptionStatusEnum,
  useOnboardingSubscriptionQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const OnboardingSubscriptionSignature = () => {
  const { t } = useTranslation();
  const { onboardingSubscriptionId = '' } = useParams();
  const { loading, data, error } = useOnboardingSubscriptionQuery({
    variables: {
      id: onboardingSubscriptionId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.onboardingSubscription) {
    return (
      <ConnectedPage>
        <QueryStateDisplay loading={loading} error={error} />
      </ConnectedPage>
    );
  }

  const { share, status } = data.onboardingSubscription;

  if (status !== OnboardingSubscriptionStatusEnum.WaitingForLpSignature) {
    return <Navigate to={LpPlatformRoutes.Home} />;
  }

  return (
    <ConnectedPage
      path={LpPlatformRoutes.Home}
      title={t('pages.onboardingSubscription.form.title', {
        fund: share.fund.name,
        share: share.name,
      })}
    >
      <SignatureIframe
        onboardingSubscriptionId={onboardingSubscriptionId}
        redirectionLink={LpPlatformRoutes.Home}
      />
    </ConnectedPage>
  );
};
