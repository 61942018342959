import { useTranslation } from 'translations/hook';

export const useAgGrigLocaleText = () => {
  const { t } = useTranslation();
  return {
    // Set Filter
    selectAll: t('agGrid.selectAll'),
    selectAllSearchResults: t('agGrid.selectAllSearchResults'),
    searchOoo: t('agGrid.searchOoo'),
    blanks: t('agGrid.blanks'),
    noMatches: t('agGrid.noMatches'),

    // Number Filter & Text Filter
    filterOoo: t('agGrid.filterOoo'),
    equals: t('agGrid.equals'),
    notEqual: t('agGrid.notEqual'),
    blank: t('agGrid.blank'),
    notBlank: t('agGrid.notBlank'),
    empty: t('agGrid.empty'),

    // Number Filter
    lessThan: t('agGrid.lessThan'),
    greaterThan: t('agGrid.greaterThan'),
    lessThanOrEqual: t('agGrid.lessThanOrEqual'),
    greaterThanOrEqual: t('agGrid.greaterThanOrEqual'),
    inRange: t('agGrid.inRange'),
    inRangeStart: t('agGrid.inRangeStart'),
    inRangeEnd: t('agGrid.inRangeEnd'),

    // Text Filter
    contains: t('agGrid.contains'),
    notContains: t('agGrid.notContains'),
    startsWith: t('agGrid.startsWith'),
    endsWith: t('agGrid.endsWith'),

    // Date Filter
    dateFormatOoo: t('agGrid.dateFormatOoo'),

    // Filter Conditions
    andCondition: t('agGrid.andCondition'),
    orCondition: t('agGrid.orCondition'),

    // Filter Buttons
    applyFilter: t('agGrid.applyFilter'),
    resetFilter: t('agGrid.resetFilter'),
    clearFilter: t('agGrid.clearFilter'),
    cancelFilter: t('agGrid.cancelFilter'),

    // Filter Titles
    textFilter: t('agGrid.textFilter'),
    numberFilter: t('agGrid.numberFilter'),
    dateFilter: t('agGrid.dateFilter'),
    setFilter: t('agGrid.setFilter'),

    // Group Column Filter
    groupFilterSelect: t('agGrid.groupFilterSelect'),

    // Advanced Filter
    advancedFilterContains: t('agGrid.advancedFilterContains'),
    advancedFilterNotContains: t('agGrid.advancedFilterNotContains'),
    advancedFilterTextEquals: t('agGrid.advancedFilterTextEquals'),
    advancedFilterTextNotEqual: t('agGrid.advancedFilterTextNotEqual'),
    advancedFilterStartsWith: t('agGrid.advancedFilterStartsWith'),
    advancedFilterEndsWith: t('agGrid.advancedFilterEndsWith'),
    advancedFilterBlank: t('agGrid.advancedFilterBlank'),
    advancedFilterNotBlank: t('agGrid.advancedFilterNotBlank'),
    advancedFilterEquals: t('agGrid.advancedFilterEquals'),
    advancedFilterNotEqual: t('agGrid.advancedFilterNotEqual'),
    advancedFilterGreaterThan: t('agGrid.advancedFilterGreaterThan'),
    advancedFilterGreaterThanOrEqual: t(
      'agGrid.advancedFilterGreaterThanOrEqual',
    ),
    advancedFilterLessThan: t('agGrid.advancedFilterLessThan'),
    advancedFilterLessThanOrEqual: t('agGrid.advancedFilterLessThanOrEqual'),
    advancedFilterTrue: t('agGrid.advancedFilterTrue'),
    advancedFilterFalse: t('agGrid.advancedFilterFalse'),
    advancedFilterAnd: t('agGrid.advancedFilterAnd'),
    advancedFilterOr: t('agGrid.advancedFilterOr'),
    advancedFilterApply: t('agGrid.advancedFilterApply'),
    advancedFilterValidationMissingColumn: t(
      'agGrid.advancedFilterValidationMissingColumn',
    ),
    advancedFilterValidationMissingOption: t(
      'agGrid.advancedFilterValidationMissingOption',
    ),
    advancedFilterValidationMissingValue: t(
      'agGrid.advancedFilterValidationMissingValue',
    ),
    advancedFilterValidationInvalidColumn: t(
      'agGrid.advancedFilterValidationInvalidColumn',
    ),
    advancedFilterValidationInvalidOption: t(
      'agGrid.advancedFilterValidationInvalidOption',
    ),
    advancedFilterValidationMissingQuote: t(
      'agGrid.advancedFilterValidationMissingQuote',
    ),
    advancedFilterValidationNotANumber: t(
      'agGrid.advancedFilterValidationNotANumber',
    ),
    advancedFilterValidationMissingCondition: t(
      'agGrid.advancedFilterValidationMissingCondition',
    ),
    advancedFilterValidationJoinOperatorMismatch: t(
      'agGrid.advancedFilterValidationJoinOperatorMismatch',
    ),
    advancedFilterValidationInvalidJoinOperator: t(
      'agGrid.advancedFilterValidationInvalidJoinOperator',
    ),
    advancedFilterValidationMissingEndBracket: t(
      'agGrid.advancedFilterValidationMissingEndBracket',
    ),
    advancedFilterValidationExtraEndBracket: t(
      'agGrid.advancedFilterValidationExtraEndBracket',
    ),
    advancedFilterValidationMessage: t(
      'agGrid.advancedFilterValidationMessage',
    ),
    advancedFilterValidationMessageAtEnd: t(
      'agGrid.advancedFilterValidationMessageAtEnd',
    ),

    // Side Bar
    columns: t('agGrid.columns'),
    filters: t('agGrid.filters'),

    // columns tool panel
    pivotMode: t('agGrid.pivotMode'),
    groups: t('agGrid.groups'),
    rowGroupColumnsEmptyMessage: t('agGrid.rowGroupColumnsEmptyMessage'),
    values: t('agGrid.values'),
    valueColumnsEmptyMessage: t('agGrid.valueColumnsEmptyMessage'),
    pivots: t('agGrid.pivots'),
    pivotColumnsEmptyMessage: t('agGrid.pivotColumnsEmptyMessage'),

    // Header of the Default Group Column
    group: t('agGrid.group'),

    // Row Drag
    rowDragRow: t('agGrid.rowDragRow'),
    rowDragRows: t('agGrid.rowDragRows'),

    // Other
    loadingOoo: t('agGrid.selecloadingOootAll'),
    loadingError: t('agGrid.loadingError'),
    noRowsToShow: t('agGrid.noRowsToShow'),
    enabled: t('agGrid.enabled'),

    // Menu
    pinColumn: t('agGrid.pinColumn'),
    pinLeft: t('agGrid.pinLeft'),
    pinRight: t('agGrid.pinRight'),
    noPin: t('agGrid.noPin'),
    valueAggregation: t('agGrid.valueAggregation'),
    noAggregation: t('agGrid.noAggregation'),
    autosizeThiscolumn: t('agGrid.autosizeThiscolumn'),
    autosizeAllColumns: t('agGrid.autosizeAllColumns'),
    groupBy: t('agGrid.groupBy'),
    ungroupBy: t('agGrid.ungroupBy'),
    ungroupAll: t('agGrid.ungroupAll'),
    addToValues: t('agGrid.addToValues'),
    removeFromValues: t('agGrid.removeFromValues'),
    addToLabels: t('agGrid.addToLabels'),
    removeFromLabels: t('agGrid.addToLabels'),
    resetColumns: t('agGrid.resetColumns'),
    expandAll: t('agGrid.expandAll'),
    collapseAll: t('agGrid.collapseAll'),
    copy: t('agGrid.copy'),
    ctrlC: t('agGrid.ctrlC'),
    ctrlX: t('agGrid.ctrlX'),
    copyWithHeaders: t('agGrid.copyWithHeaders'),
    copyWithGroupHeaders: t('agGrid.copyWithGroupHeaders'),
    cut: t('agGrid.cut'),
    paste: t('agGrid.paste'),
    ctrlV: t('agGrid.ctrlV'),
    export: t('agGrid.export'),
    csvExport: t('agGrid.csvExport'),
    excelExport: t('agGrid.excelExport'),

    // Enterprise Menu Aggregation and Status Bar
    sum: t('agGrid.sum'),
    first: t('agGrid.first'),
    last: t('agGrid.last'),
    min: t('agGrid.min'),
    max: t('agGrid.max'),
    none: t('agGrid.none'),
    count: t('agGrid.count'),
    avg: t('agGrid.avg'),
    filteredRows: t('agGrid.filteredRows'),
    selectedRows: t('agGrid.selectedRows'),
    totalRows: t('agGrid.totalRows'),
    totalAndFilteredRows: t('agGrid.totalAndFilteredRows'),
    more: t('agGrid.more'),
    to: t('agGrid.to'),
    of: t('agGrid.selecoftAll'),
    page: t('agGrid.page'),
    pageLastRowUnknown: t('agGrid.pageLastRowUnknown'),
    nextPage: t('agGrid.nextPage'),
    lastPage: t('agGrid.lastPage'),
    firstPage: t('agGrid.firstPage'),
    previousPage: t('agGrid.previousPage'),

    // Pivoting
    pivotColumnGroupTotals: t('agGrid.pivotColumnGroupTotals'),

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: t('agGrid.pivotChartAndPivotMode'),
    pivotChart: t('agGrid.pivotChart'),
    chartRange: t('agGrid.chartRange'),

    columnChart: t('agGrid.columnChart'),
    groupedColumn: t('agGrid.groupedColumn'),
    stackedColumn: t('agGrid.stackedColumn'),
    normalizedColumn: t('agGrid.normalizedColumn'),

    barChart: t('agGrid.barChart'),
    groupedBar: t('agGrid.groupedBar'),
    stackedBar: t('agGrid.stackedBar'),
    normalizedBar: t('agGrid.normalizedBar'),

    pieChart: t('agGrid.pieChart'),
    pie: t('agGrid.pie'),
    doughnut: t('agGrid.doughnut'),

    line: t('agGrid.line'),

    xyChart: t('agGrid.xyChart'),
    scatter: t('agGrid.scatter'),
    bubble: t('agGrid.bubble'),

    areaChart: t('agGrid.areaChart'),
    area: t('agGrid.area'),
    stackedArea: t('agGrid.stackedArea'),
    normalizedArea: t('agGrid.normalizedArea'),

    histogramChart: t('agGrid.histogramChart'),
    histogramFrequency: t('agGrid.histogramFrequency'),

    combinationChart: t('agGrid.combinationChart'),
    columnLineCombo: t('agGrid.columnLineCombo'),

    // Charts
    pivotChartTitle: t('agGrid.pivotChartTitle'),
    rangeChartTitle: t('agGrid.rangeChartTitle'),
    settings: t('agGrid.settings'),
    data: t('agGrid.data'),
    format: t('agGrid.format'),
    categories: t('agGrid.categories'),
    defaultCategory: t('agGrid.defaultCategory'),
    series: t('agGrid.series'),
    xyValues: t('agGrid.xyValues'),
    paired: t('agGrid.paired'),
    axis: t('agGrid.axis'),
    navigator: t('agGrid.navigator'),
    color: t('agGrid.color'),
    thickness: t('agGrid.thickness'),
    xType: t('agGrid.xType'),
    automatic: t('agGrid.automatic'),
    category: t('agGrid.category'),
    number: t('agGrid.number'),
    time: t('agGrid.time'),
    autoRotate: t('agGrid.autoRotate'),
    xRotation: t('agGrid.xRotation'),
    yRotation: t('agGrid.yRotation'),
    ticks: t('agGrid.ticks'),
    width: t('agGrid.width'),
    height: t('agGrid.height'),
    length: t('agGrid.length'),
    padding: t('agGrid.padding'),
    spacing: t('agGrid.spacing'),
    chart: t('agGrid.chart'),
    title: t('agGrid.title'),
    titlePlaceholder: t('agGrid.titlePlaceholder'),
    background: t('agGrid.background'),
    font: t('agGrid.font'),
    top: t('agGrid.top'),
    right: t('agGrid.right'),
    bottom: t('agGrid.bottom'),
    left: t('agGrid.left'),
    labels: t('agGrid.labels'),
    size: t('agGrid.size'),
    minSize: t('agGrid.minSize'),
    maxSize: t('agGrid.maxSize'),
    legend: t('agGrid.legend'),
    position: t('agGrid.position'),
    markerSize: t('agGrid.markerSize'),
    markerStroke: t('agGrid.markerStroke'),
    markerPadding: t('agGrid.markerPadding'),
    itemSpacing: t('agGrid.itemSpacing'),
    itemPaddingX: t('agGrid.itemPaddingX'),
    itemPaddingY: t('agGrid.itemPaddingY'),
    layoutHorizontalSpacing: t('agGrid.layoutHorizontalSpacing'),
    layoutVerticalSpacing: t('agGrid.layoutVerticalSpacing'),
    strokeWidth: t('agGrid.strokeWidth'),
    lineDash: t('agGrid.lineDash'),
    offset: t('agGrid.offset'),
    offsets: t('agGrid.offsets'),
    tooltips: t('agGrid.tooltips'),
    callout: t('agGrid.callout'),
    markers: t('agGrid.markers'),
    shadow: t('agGrid.shadow'),
    blur: t('agGrid.blur'),
    xOffset: t('agGrid.xOffset'),
    yOffset: t('agGrid.yOffset'),
    lineWidth: t('agGrid.lineWidth'),
    normal: t('agGrid.normal'),
    bold: t('agGrid.bold'),
    italic: t('agGrid.italic'),
    boldItalic: t('agGrid.boldItalic'),
    predefined: t('agGrid.predefined'),
    fillOpacity: t('agGrid.fillOpacity'),
    strokeOpacity: t('agGrid.strokeOpacity'),
    histogramBinCount: t('agGrid.histogramBinCount'),
    columnGroup: t('agGrid.columnGroup'),
    barGroup: t('agGrid.barGroup'),
    pieGroup: t('agGrid.pieGroup'),
    lineGroup: t('agGrid.lineGroup'),
    scatterGroup: t('agGrid.scatterGroup'),
    areaGroup: t('agGrid.areaGroup'),
    histogramGroup: t('agGrid.histogramGroup'),
    combinationGroup: t('agGrid.combinationGroup'),
    groupedColumnTooltip: t('agGrid.groupedColumnTooltip'),
    stackedColumnTooltip: t('agGrid.stackedColumnTooltip'),
    normalizedColumnTooltip: t('agGrid.normalizedColumnTooltip'),
    groupedBarTooltip: t('agGrid.groupedBarTooltip'),
    stackedBarTooltip: t('agGrid.stackedBarTooltip'),
    normalizedBarTooltip: t('agGrid.normalizedBarTooltip'),
    pieTooltip: t('agGrid.pieTooltip'),
    doughnutTooltip: t('agGrid.doughnutTooltip'),
    lineTooltip: t('agGrid.lineTooltip'),
    groupedAreaTooltip: t('agGrid.groupedAreaTooltip'),
    stackedAreaTooltip: t('agGrid.stackedAreaTooltip'),
    normalizedAreaTooltip: t('agGrid.normalizedAreaTooltip'),
    scatterTooltip: t('agGrid.scatterTooltip'),
    bubbleTooltip: t('agGrid.bubbleTooltip'),
    histogramTooltip: t('agGrid.histogramTooltip'),
    columnLineComboTooltip: t('agGrid.columnLineComboTooltip'),
    areaColumnComboTooltip: t('agGrid.areaColumnComboTooltip'),
    customComboTooltip: t('agGrid.customComboTooltip'),
    noDataToChart: t('agGrid.noDataToChart'),
    pivotChartRequiresPivotMode: t('agGrid.pivotChartRequiresPivotMode'),
    chartSettingsToolbarTooltip: t('agGrid.chartSettingsToolbarTooltip'),
    chartLinkToolbarTooltip: t('agGrid.chartLinkToolbarTooltip'),
    chartUnlinkToolbarTooltip: t('agGrid.chartUnlinkToolbarTooltip'),
    chartDownloadToolbarTooltip: t('agGrid.chartDownloadToolbarTooltip'),
    seriesChartType: t('agGrid.seriesChartType'),
    seriesType: t('agGrid.seriesType'),
    secondaryAxis: t('agGrid.secondaryAxis'),

    // ARIA
    ariaAdvancedFilterInput: t('agGrid.ariaAdvancedFilterInput'),
    ariaChecked: t('agGrid.ariaChecked'),
    ariaColumn: t('agGrid.ariaColumn'),
    ariaColumnGroup: t('agGrid.ariaColumnGroup'),
    ariaColumnList: t('agGrid.ariaColumnList'),
    ariaColumnSelectAll: t('agGrid.ariaColumnSelectAll'),
  };
};
