import { SvgIcon } from '@mui/material';

import { SvgProps } from './types/props';

export const LpMenuIcon = ({ primaryColor, secondaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M5 15V21H13V15C13 13.3431 11.1046 12 10 12H7C5.89543 12 5 13.3431 5 15Z"
        fill={secondaryColor}
      />
      <circle cx="7" cy="7" r="3" fill={secondaryColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11C19.2091 11 21 9.20914 21 7C21 4.79086 19.2091 3 17 3C14.7909 3 13 4.79086 13 7C13 9.20914 14.7909 11 17 11ZM17 9C18.1046 9 19 8.10457 19 7C19 5.89543 18.1046 5 17 5C15.8954 5 15 5.89543 15 7C15 8.10457 15.8954 9 17 9ZM20 14C20.5523 14 21 14.4477 21 15V21H23V15C23 13.3431 21.6569 12 20 12H15L16 14H20Z"
        fill={primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 11C9.2091 11 11 9.20914 11 7C11 4.79086 9.2091 3 7 3C4.79086 3 3 4.79086 3 7C3 9.20914 4.79086 11 7 11ZM7 9C8.1046 9 9 8.10457 9 7C9 5.89543 8.1046 5 7 5C5.8954 5 5 5.89543 5 7C5 8.10457 5.8954 9 7 9ZM10 14C10.5523 14 11 14.4477 11 15V21H13V15C13 13.3431 11.6569 12 10 12H4C2.34315 12 1 13.3431 1 15V21H3V15C3 14.4477 3.44772 14 4 14H10Z"
        fill="#363636"
      />
    </SvgIcon>
  );
};
