import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { SelectSignatoryForm } from 'business/fund-manager/onboarding-subscription/services/types';
import { selectSignatoryFormSchema } from 'business/fund-manager/onboarding-subscription/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  UserData,
  useOnboardingSubscriptionManagementCompanySignatoriesQuery,
} from 'generated/graphql';
import { CustomModal } from 'ui/custom-modal';
import { FormInputDropdown } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Option } from 'ui/types';

interface SelectSignatoryModalProps {
  onboardingSubscriptionId: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (signatoryId: string) => void;
}
export const SelectSignatoryModal = ({
  onboardingSubscriptionId,
  open,
  onSubmit,
  onClose,
}: SelectSignatoryModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<SelectSignatoryForm>({
    resolver: yupResolver<SelectSignatoryForm>(selectSignatoryFormSchema),
  });

  const { data, loading, error } =
    useOnboardingSubscriptionManagementCompanySignatoriesQuery({
      skip: !open,
      variables: {
        onboardingSubscriptionId,
      },
      onCompleted: (response) => {
        methods.setValue(
          'signatoryId',
          response.onboardingSubscriptionManagementCompanySignatories[0]?.id,
        );
      },
      onError: (err) => {
        enqueueSnackbar(t(err.message), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const getSignatoriesOptions = (signatories: UserData[]): Option[] =>
    signatories.map((signatory) => ({
      id: signatory.id,
      value: `${signatory.firstName} ${signatory.lastName} (${signatory.email})`,
    }));

  return (
    <CustomModal
      open={open}
      handleClose={onClose}
      component={
        <FormProvider {...methods}>
          <FormModalContainer
            title={t(
              'pages.onboardingSubscription.readyForSignature.chooseSignatory',
            )}
            onClose={onClose}
            onSubmit={methods.handleSubmit((values) =>
              onSubmit(values.signatoryId),
            )}
            isSubmitting={methods.formState.isSubmitting}
            labelAction={t('common.actions.confirm')}
            disableSubmit={!methods.formState.isValid}
          >
            {loading || error || !data ? (
              <QueryStateDisplay loading={loading} error={error} />
            ) : (
              <Stack margin={4}>
                <FormInputDropdown
                  label={t(
                    'pages.onboardingSubscription.readyForSignature.signatory',
                  )}
                  name="signatoryId"
                  options={getSignatoriesOptions(
                    data.onboardingSubscriptionManagementCompanySignatories,
                  )}
                  fullWidth
                  required
                />
              </Stack>
            )}
          </FormModalContainer>
        </FormProvider>
      }
    />
  );
};
