import { Button, Stack, Typography } from '@mui/material';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';
import * as yup from 'yup';

import { AuthAppLogo } from 'business/user/components/auth-logo';
import { PrivacyPolicy } from 'business/user/components/privacy-policy';
import authService from 'business/user/services/auth-service';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import { ValidateMfaValues } from 'business/user/types/auth';
import { mfaCode } from 'business/user/validations/string';
import { formikErrorMessage } from 'technical/form';
import { Card } from 'ui/card';
import { InputError } from 'ui/form/input-error';
import { TextInput } from 'ui/text-input';

import styles from './index.module.scss';

export interface Values {
  code: string;
}

interface Props {
  className?: string;
}

const initialValues: Values = { code: '' };
const logoHeight = 50;

export default function ValidateMfaCard({ className }: Props) {
  const [errorContext, setErrorContext] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleSubmit = async (values: ValidateMfaValues) => {
    setErrorContext(null);
    try {
      await authService.validateMfa(values.code);
    } catch (error) {
      setErrorContext(getAuthErrorContextFromAuthProviderError(error).context);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: yup.object().shape({
      code: mfaCode,
    }),
    onSubmit: handleSubmit,
  });

  const handleSubmitClick = (e: React.FormEvent) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <Card className={classnames(styles.validateMfaCard, className)}>
      <Stack spacing={4} className={styles.card}>
        <AuthAppLogo height={logoHeight} />
        <Stack spacing={4}>
          <form onSubmit={(e) => handleSubmitClick(e)}>
            <Stack spacing={3}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  {t('pages.validateMfa.title')}
                </Typography>
                <Typography variant="body2">
                  {t('pages.validateMfa.description')}
                </Typography>
              </Stack>
              <TextInput
                id="code"
                name="code"
                label={t('notConnected.login.mfa.code')}
                value={formik.values.code}
                onChange={(e) => {
                  formik.setTouched({ ...formik.touched, code: undefined });
                  formik.handleChange(e);
                }}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formikErrorMessage(formik, 'login')}
                data-test-id="login-input"
              />
              {errorContext ? (
                <InputError
                  error={
                    errorContext !== null
                      ? t('errors.validateMfa', { context: errorContext })
                      : undefined
                  }
                />
              ) : null}
              <Button
                variant="contained"
                type="submit"
                data-test-id="submit-button"
              >
                {t('notConnected.login.mfa.submit')}
              </Button>
            </Stack>
          </form>

          <PrivacyPolicy />
        </Stack>
      </Stack>
    </Card>
  );
}
