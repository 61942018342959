import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { PreviewEmailForm } from 'business/fund-manager/operation/services/types';
import { previewEmailForm } from 'business/fund-manager/operation/services/validation';
import { useSendOperationEmailPreviewMutation } from 'generated/graphql';
import { FormCard, FormInputText } from 'ui/form';

interface Props {
  operationShareLpId: string;
  handleCloseModal: () => void;
}

export const SendPreviewEmailForm = ({
  operationShareLpId,
  handleCloseModal,
}: Props) => {
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<PreviewEmailForm>({
    resolver: yupResolver(previewEmailForm),
  });

  const [sendOperationEmailPreview, { error, loading }] =
    useSendOperationEmailPreviewMutation({
      onCompleted: () => {
        handleCloseModal();
      },
    });

  const onSubmit = (input: { email: string }) => {
    sendOperationEmailPreview({
      variables: {
        email: input.email,
        operationShareLpId: operationShareLpId,
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <Stack spacing={5}>
          <FormInputText
            name="email"
            label={t(
              'pages.fundManager.operation.emailDocumentOperationPreview.form.email',
            )}
            control={control}
            required
          />
        </Stack>
        <Stack>
          <Button variant="contained" type="submit" disabled={loading}>
            {t(
              'pages.fundManager.operation.emailDocumentOperationPreview.form.submit',
            )}
          </Button>
          {error ? (
            <FormHelperText error>
              {t('errors.sendTestEmailTemplate')}
            </FormHelperText>
          ) : null}
        </Stack>
      </FormCard>
    </form>
  );
};
