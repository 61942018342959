import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'translations/hook';

import GraphsPanel from 'business/lp-platform/valuations/components/graphs-panel';
import Synthesis from 'business/lp-platform/valuations/components/synthesis';
import { getValuationColumnDefinition } from 'business/lp-platform/valuations/services/get-valuation-column-definition';
import { getValuationGraphData } from 'business/lp-platform/valuations/services/get-valuation-progression-graph-data';
import {
  ValuationCustomFilterFields,
  isGetValuationsQuery,
} from 'business/lp-platform/valuations/services/types';
import { buildValuationNestedClauses } from 'business/lp-platform/valuations/services/valuation-clause-builder';
import { getAggregagtedValuationGraphData } from 'business/shared/services/get-aggregated-valuation-graph-data';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import { GetValuationsQuery, useGetValuationsQuery } from 'generated/graphql';
import { DEFAULT_WHERE_CLAUSE } from 'technical/filter/constants';
import { handleMultipleFilter } from 'technical/filter/filters';
import {
  FuzzyFilterDataKey,
  FuzzyFilterEnabledColumn,
} from 'technical/filter/fuzzy-filter/constants';
import { handleFuzzyFiltering } from 'technical/filter/fuzzy-filter/fuzzy-filter';
import { removeDuplicates } from 'technical/remove-duplicates';
import { Table, ToolbarContainer } from 'ui/table';

export const FundShareValuations = () => {
  const { t } = useTranslation();

  const displayValuationSynthesis = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_VALUATION_SYNTHESIS,
  );

  const [searchedTerm, setSearchedTerm] = useState('');
  const [whereClause, setWhereClause] = useState(DEFAULT_WHERE_CLAUSE);
  const [customValuationFilter, setCustomValuationFilter] =
    useState<ValuationCustomFilterFields>({
      funds: [],
      shares: [],
    });

  const headers: GridColDef<GetValuationsQuery['valuations'][number]>[] =
    getValuationColumnDefinition(t, customValuationFilter);

  const toolbarRef = useRef<HTMLDivElement>(null);

  const { data, loading } = useGetValuationsQuery({
    variables: whereClause,
  });

  useEffect(() => {
    if (data && customValuationFilter.funds.length === 0) {
      setCustomValuationFilter({
        funds: removeDuplicates(
          data.valuations.map(({ share }) => share).map(({ fund }) => fund),
          'name',
        ),
        shares: removeDuplicates(
          data.valuations.map(({ share }) => share),
          'type',
        ),
      });
    }
  }, [data, customValuationFilter.funds.length]);

  const rows =
    handleFuzzyFiltering({
      data,
      searchedTerm,
      dataKeys: [
        FuzzyFilterDataKey.Valuations,
        FuzzyFilterDataKey.ValuationsAggregate,
      ],
      enabledColumns: [
        FuzzyFilterEnabledColumn.ShareType,
        FuzzyFilterEnabledColumn.FundName,
      ],
    }) || data;

  const graphData = rows ? getValuationGraphData(rows.valuations) : undefined;

  const { aggregatedData, lastPoint } =
    getAggregagtedValuationGraphData(graphData);

  return (
    <ConnectedPage title={t('pages.fundShareValuations.title')}>
      <Stack spacing={2}>
        <ToolbarContainer
          toolbarRef={toolbarRef}
          filterPlaceholders={[
            t('pages.fundShareValuations.table.fund'),
            t('pages.fundShareValuations.table.shareType'),
          ]}
        />
        {displayValuationSynthesis &&
        rows &&
        isGetValuationsQuery(rows) &&
        lastPoint ? (
          <Synthesis data={rows} lastPoint={lastPoint} />
        ) : (
          <></>
        )}
        {aggregatedData && <GraphsPanel data={aggregatedData} />}
        <Table<GetValuationsQuery['valuations'][number]>
          toolbarAnchor={toolbarRef.current}
          title={t('pages.fundShareValuations.table.title')}
          columns={headers}
          rows={rows ? rows.valuations : []}
          getRowId={(row) => `${row.shareId}-${row.valuationId}`}
          loading={loading}
          initialState={{
            pinnedColumns: { left: ['valuationDate', 'fund', 'shareType'] },
          }}
          onFilterModelChange={(model) => {
            handleMultipleFilter(
              model,
              setWhereClause,
              buildValuationNestedClauses,
              setSearchedTerm,
            );
          }}
          filterMode="server"
          columnHeaderHeight={60}
        />
      </Stack>
    </ConnectedPage>
  );
};
