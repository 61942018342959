import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { CompanyStatusSelector as InstrumentStatusSelector } from 'business/fund-manager/portfolio/company/components/company-status-selector';
import {
  DeletePortfolioValuation,
  InstrumentValuationModal,
} from 'business/fund-manager/portfolio/valuation/components/instrument-valuation-modal';
import { getValuationColumnDefinition } from 'business/fund-manager/portfolio/valuation/services/get-valuation-column-definition';
import { isConfirmationPossible } from 'business/fund-manager/portfolio/valuation/services/is-confirmation-possible';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useUpdateValuationSetStatusDoneMutation,
  useValuationSecuritiesQuery,
  ValuationSecuritiesQuery,
  WebCompanyStatusEnum,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { CustomModal } from 'ui/custom-modal';
import { QueryStateDisplay } from 'ui/query-state-display';

export const PortfolioValuationOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fundId = '', valuationId = '' } = useParams();
  const [ids, setIds] = useState<
    { instrumentValuationId: string; companyInstrumentId: string } | undefined
  >();

  const { open, handleOpenModal, handleCloseModal } = useModal();
  const [companyStatusFilter, setCompanyStatusFilter] = useState<
    WebCompanyStatusEnum | undefined
  >();

  const { data, loading, error } = useValuationSecuritiesQuery({
    variables: {
      valuationId,
      hasuraValuationId: valuationId,
    },
    fetchPolicy: 'network-only',
  });

  const [updateValuationStatusToDone] = useUpdateValuationSetStatusDoneMutation(
    {
      variables: {
        id: valuationId,
      },
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.updateValuationStatus'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        navigate(
          FundManagerRoutes.PortfolioFundIdValuation.replace(':fundId', fundId),
        );
      },
      onError: () => {
        enqueueSnackbar(t('errors.updateValuationStatus'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    },
  );

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const handleOpenValuationCreationModal = ({
    instValId,
    instId,
  }: {
    instValId: string;
    instId: string;
  }) => {
    setIds({ instrumentValuationId: instValId, companyInstrumentId: instId });
    handleOpenModal();
  };

  const headers = getValuationColumnDefinition(
    t,
    handleOpenValuationCreationModal,
  );

  const valuationSecurities = data?.valuationSecurities ?? [];

  return (
    <Stack spacing={2}>
      <AlternativeTable<ValuationSecuritiesQuery['valuationSecurities'][number]>
        fileName="valuation-securities"
        rowData={
          companyStatusFilter
            ? valuationSecurities.filter(
                (v) => v.company.status === companyStatusFilter,
              )
            : valuationSecurities
        }
        autoGroupColumnDef={{
          headerName: t(
            'pages.fundManager.portfolio.valuation.table.companyName',
          ),
          pinned: 'left', //force pinned left. Does not work in columnDef
        }}
        actions={
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <Stack>
              <InstrumentStatusSelector
                name="instrumentStatusSelector"
                onChange={setCompanyStatusFilter}
                value={companyStatusFilter}
                size="small"
                fullWidth
              />
            </Stack>
            <Stack flexGrow={1} direction="row" justifyContent="end">
              <ActionButton
                disabled={!isConfirmationPossible(valuationSecurities)}
                onClick={() => updateValuationStatusToDone()}
                variant="submit"
              >
                {t(
                  'pages.fundManager.portfolio.valuation.creation.button.confirm',
                )}
              </ActionButton>
            </Stack>
          </Stack>
        }
        columnDefs={headers}
        domLayout="autoHeight"
        displaySidebar
        loading={loading}
        suppressAggFuncInHeader
        expandable
      />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <InstrumentValuationModal
            instrumentValuationId={ids?.instrumentValuationId ?? ''}
            companyInstrumentId={ids?.companyInstrumentId ?? ''}
            handleClose={handleCloseModal}
          />
        }
      />
      {data?.valuation ? (
        <DeletePortfolioValuation valuation={data?.valuation} />
      ) : null}
    </Stack>
  );
};
