import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import { PublicationDataForm } from 'business/fund-manager/settings/template/components/publication-data-form';
import {
  TemplatePageContainer,
  TemplateTabEnum,
} from 'business/fund-manager/settings/template/components/template-page-container';
import { useGetPublicationDataQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const PublicationData = () => {
  const { routePrefix, managementCompanyId } = useNavigateSettings();
  const { data, error, loading } = useGetPublicationDataQuery({
    variables: { id: managementCompanyId },
    fetchPolicy: 'network-only',
  });

  if (error || loading || !data?.publicationData) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  return (
    <TemplatePageContainer
      activeTab={TemplateTabEnum.Publication}
      routePrefix={routePrefix}
    >
      <PublicationDataForm
        defaultValues={{
          id: data.publicationData.id,
          crmEmail: data.publicationData.crmEmail ?? '',
          footerInformation: data.publicationData.footerInformation ?? '',
          fundContactInformation:
            data.publicationData.fundContactInformation ?? '',
          senderEmail: data.publicationData.senderEmail ?? '',
          senderName: data.publicationData.senderName ?? '',
        }}
      />
    </TemplatePageContainer>
  );
};
