import {
  GetOperationsDraftDetailsQuery,
  UpdateOperationInput,
} from 'generated/graphql';

export const getOperationDraftValues = (
  data: GetOperationsDraftDetailsQuery['web_portal_operation'],
): UpdateOperationInput => {
  const { identifier, date, fundId, id, shouldEmailLetters } = data[0];

  return {
    operationId: id,
    identifier,
    date: new Date(date),
    fundId,
    shouldEmailLetters,
  };
};
