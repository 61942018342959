import { Grid } from '@mui/material';

import { Card } from 'ui/card';
import { FormInputCheckBox } from 'ui/form/form-input-checkbox';

import styles from './form-grid-selectable-list.module.scss';

interface BaseItem {
  id: string;
  label: string;
}

type FormGridSelectableListProps<
  Item extends BaseItem = BaseItem,
  Component extends React.FC<Item & { name: string }> = React.FC<
    Item & { name: string }
  >,
> = {
  name: string;
  items: Item[];
  component: Component;
  readonly?: boolean;
};

export const FormGridSelectableList = <Item extends BaseItem>({
  name,
  items,
  component: Component,
  readonly = false,
}: FormGridSelectableListProps<Item>) => {
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid xs={4} key={item.id}>
          <Card className={styles.card}>
            <FormInputCheckBox
              name={`${name}.${index}.selected`}
              label={item.label}
              disabled={readonly}
            />
            <Component
              {...item}
              readonly={readonly}
              name={`${name}.${index}`}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
