function isEditPath(splittedPath: string[]) {
  return splittedPath.includes('edit');
}

// TODO refactor this by using convertPathToTranslationKey to replace this usage too heavy to use :/
export function getPageTitle(
  path: string,
  availableRoutesRegex: RegExp,
): string | null {
  const splittedPath = path.split('/');
  if (splittedPath.length === 0) {
    return null;
  }
  const lastElement = splittedPath[splittedPath.length - 1];

  const i18nKey = isEditPath(splittedPath)
    ? `edit_${lastElement}`
    : lastElement;

  return availableRoutesRegex.test(i18nKey) ? i18nKey : null;
}
