import * as yup from 'yup';

import { nonNegativeNumberSchema } from 'technical/validation';

export const createValuationForm = yup.object({
  date: yup.date().required(),
});

export const createValuationProceedFormSchema = yup.object({
  proceed: nonNegativeNumberSchema.required(),
  date: yup.date().required(),
});

export const addInstrumentValuationFormSchema = yup.object({
  fairValue: nonNegativeNumberSchema.required(),
  accruedCoupon: nonNegativeNumberSchema.required(),
});
