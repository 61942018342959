import { useTranslation } from 'translations/hook';

import { getFundPeriods } from 'business/fund-manager/fund/dashboard/services/get-fund-periods';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import {
  LpOnboardingSubscriptionsQuery,
  OnboardingSubscriptionStatusEnum,
  OnboardingSubscriptionTypeEnum,
} from 'generated/graphql';
import { OnboardingSubscriptionCard } from 'ui/onboarding-subscription-card/onboarding-subscription-card';

const getSubscriptionLink = ({
  status,
  id,
}: Pick<
  NonNullable<LpOnboardingSubscriptionsQuery>['subscriptions'][number],
  'id' | 'status'
>) => {
  switch (status) {
    case OnboardingSubscriptionStatusEnum.InvitationSent:
    case OnboardingSubscriptionStatusEnum.ProfileCompleted:
    case OnboardingSubscriptionStatusEnum.SubInfoDeclined:
    case OnboardingSubscriptionStatusEnum.ProfileDeclined:
    case OnboardingSubscriptionStatusEnum.WaitingForLpSignature:
      // every lp action is redirected to the same page because the page handles all redirections based on the onboardingSubscription status
      return LpPlatformRoutes.LpOnboardingSubscriptionCreateId.replace(
        ':onboardingSubscriptionId',
        id,
      );
    default:
      return undefined;
  }
};

export const DashboardOnboardingUser = ({
  data,
}: {
  data: NonNullable<LpOnboardingSubscriptionsQuery>;
}) => {
  const { t } = useTranslation();

  if (data.subscriptions.length === 0) {
    return null;
  }

  const items = data.subscriptions.map(
    ({
      id,
      share,
      type,
      numericAmount,
      numericAmountMin,
      numericAmountMax,
      status,
      lpView,
    }) => ({
      id,
      fundPeriods: getFundPeriods({
        ...share.fund,
        duration: share.fund.duration ?? 0,
      }),
      lpName: lpView.lpName,
      fundName: share.fund.name,
      shareName: share.name,
      link: getSubscriptionLink({
        status,
        id,
      }),
      status,
      subscriptionAmount:
        type === OnboardingSubscriptionTypeEnum.Fixed
          ? {
              type,
              numericAmount,
            }
          : {
              type,
              numericAmountMin,
              numericAmountMax,
            },
    }),
  );

  return (
    <OnboardingSubscriptionCard
      title={t('pages.dashboard.cards.onboardingSubscriptions.title')}
      items={items}
    />
  );
};
