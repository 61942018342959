import { Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useTranslation } from 'translations/hook';

import { getLpCustomFilters } from 'business/fund-manager/lp/services/get-lp-custom-filters';
import { getLpsColumnDefinition } from 'business/fund-manager/lp/services/get-lps-column-definition';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { useAppContext } from 'business/providers/app-provider';
import { router } from 'business/router/services';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  LpTableQuery,
  useLpTableQuery,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import Spacer from 'ui/spacer';

import { useSetLpCustomColumnValue } from './hooks/set-lp-custom-column-value';

export const FundManagerLps = () => {
  const { user } = useAppContext();
  const { t } = useTranslation();

  const { data, loading } = useLpTableQuery({
    skip: !user?.managementCompany?.id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first', // Necessary for optimistic cache update to work
    variables: {
      managementCompanyId: user?.managementCompany?.id ?? '',
    },
  });

  const displayLpInvestorProfileStep = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
  );

  const setLpCustomColumnValue = useSetLpCustomColumnValue();

  const canValidateLp = useManagementCompanyTypeGuard([
    Web_Portal_ManagementCompanyType_Enum.FundAdmin,
    Web_Portal_ManagementCompanyType_Enum.GeneralPartner,
  ]);

  const customFilters = getLpCustomFilters(data);
  const headers: ColDef<LpTableQuery['lps'][number]>[] = getLpsColumnDefinition(
    {
      t,
      customFilters,
      canValidateLp,
      customColumns: data?.customColumns ?? [],
      setLpCustomColumnValue,
      displayLpInvestorProfileStep,
    },
  );

  const handleOpenLpCreationForm = () => {
    router.navigate(FundManagerRoutes.LpCreate);
  };

  return (
    <ConnectedPage title={t('pages.fundManager.lps.title')}>
      <Stack spacing={2}>
        <Spacer justify="end">
          <ActionButton
            variant="submit"
            data-test-id="add-lp-button"
            onClick={handleOpenLpCreationForm}
          >
            {t('pages.fundManager.lps.addLp')}
          </ActionButton>
        </Spacer>

        <AlternativeTable<LpTableQuery['lps'][number]>
          fileName="lp-list"
          rowData={data?.lps ?? []}
          columnDefs={headers}
          domLayout="autoHeight"
          displaySidebar
          loading={loading}
        />
      </Stack>
    </ConnectedPage>
  );
};
