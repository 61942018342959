import { i18n } from 'translations';

import { CurrencyEnum } from 'generated/graphql';

interface FormatOptions {
  currency?: CurrencyEnum;
  locale?: string;
  precision?: number;
  minPrecision?: number;
  maxPrecision?: number;
}

/**
 * Multiply a normal number by 10^8 to match backend 'numeric' values
 */
export function convertFromNumberTo10X8(value: number) {
  return value * 10 ** 8;
}

/**
 * Multiply a normal number by 100 to match backend 'bigint' values
 */
export function convertFromNumberToX100(value: number) {
  return value * 100;
}

/**
 * Convert a number with x100 multiplier to a normal number.
 * @example
 * convertFromX100toNumber(150000) // 1500
 */
export function convertFromX100toNumber(value: number) {
  return value / 100;
}

/**
 * Convert a number with 10^8 multiplier to a normal number.
 * @example
 * convertFrom10X8toNumber(150000000) // 1.5
 */
export function convertFrom10X8toNumber<T extends number | undefined>(
  value: T,
): T {
  return (value !== undefined ? value / 10 ** 8 : undefined) as T;
}

/**
 * Format a number as a monetary amount using the site's locale.
 * @example
 * formatMonetaryAmount(150000, { currency: CurrencyEnum.Eur }) // "€1,500,000.00"
 */
export function formatToMonetaryAmount(
  value: number,
  {
    currency = CurrencyEnum.Eur,
    locale = i18n.language,
    precision = 2,
    minPrecision,
    maxPrecision,
  }: FormatOptions = {},
) {
  return value.toLocaleString(locale, {
    currency,
    style: 'currency',
    minimumFractionDigits: minPrecision ?? precision,
    maximumFractionDigits: maxPrecision ?? precision,
  });
}

/**
 * Format a percentage according to the selected locale.
 * @param value A number between 0 and 1
 * @example
 * formatRatioToPercentage(0.991) // 99%
 * formatRatioToPercentage(0.991, {precision: 2}) // 99.10%
 */
export function formatRatioToPercentage(
  value: number,
  {
    locale = i18n.language,
    precision = 0,
    minPrecision,
    maxPrecision,
  }: FormatOptions = {},
) {
  return value.toLocaleString(locale, {
    style: 'percent',
    minimumFractionDigits: minPrecision ?? precision,
    maximumFractionDigits: maxPrecision ?? precision,
  });
}

/**
 * Format a number as a monetary amount in millions by dividing the amount by 10^6.
 * @example
 * formatToMonetaryInMillions(150000000, { currency: CurrencyEnum.Eur }) // "€150m"
 */
export function formatToMonetaryInMillions(
  amount: number,
  currency: CurrencyEnum = CurrencyEnum.Eur,
) {
  return formatToMonetaryAmount(amount / 10 ** 6, { currency }) + 'm';
}

/**
 * Format a number according to the selected locale.
 * @example
 * formatNumber(150000) // "150,000"
 * formatNumber(150000, {precision: 2}) // "150,000.00"
 */
export function formatNumber(
  value: number,
  {
    locale = i18n.language,
    precision = 0,
    minPrecision,
    maxPrecision,
  }: FormatOptions = {},
) {
  return value.toLocaleString(locale, {
    minimumFractionDigits: minPrecision ?? precision,
    maximumFractionDigits: maxPrecision ?? precision,
  });
}
