import { Box } from '@mui/material';
import { HTMLAttributes } from 'react';

import { useLanguageContext } from 'business/providers/language-provider';
import { getCountryList } from 'technical/country-utils';
import { getFlagIcon } from 'technical/flag-icons';
import { Select } from 'ui/select';

export interface DropdownCountryProps {
  id?: string;
  name: string;
  label: string;
  value: string;
  error?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  onChange: (value: string | null) => void;
  fullWidth?: boolean;
}
export function DropDownCountry({
  id,
  name,
  label,
  value,
  error,
  dataTestId,
  disabled,
  required,
  placeholder,
  helperText,
  fullWidth,
  onChange,
}: DropdownCountryProps) {
  const { language } = useLanguageContext();
  const countries = getCountryList(language).map(({ code, countryName }) => ({
    id: code,
    value: countryName,
  }));

  type CountryOption = { id: string; value: string };

  return (
    <Select
      disabled={disabled}
      dataTestId={dataTestId}
      value={value}
      options={countries}
      fullWidth={fullWidth}
      label={label}
      name={name}
      id={id}
      placeholder={placeholder}
      error={error}
      required={required}
      onChange={onChange}
      helperText={helperText}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement>,
        option: CountryOption,
      ) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={getFlagIcon(option.id)}
            srcSet={getFlagIcon(option.id, true)}
            alt={`Flag of ${option.value}`}
          />
          {option.value} ({option.id})
        </Box>
      )}
    />
  );
}
