import { ColDef } from 'ag-grid-community';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { SendPreviewEmailForm } from 'business/fund-manager/operation/components/send-preview-email-form';
import { getPreviewEmailDocumentColumentDefinition } from 'business/fund-manager/operation/services/get-preview-email-document-column-definition';
import { GENERATED_FILE_NAME } from 'business/fund-manager/settings/template/services/constants';
import {
  GetOperationShareLpPreviewQuery,
  useDownloadOperationLetterPreviewMutation,
} from 'generated/graphql';
import { downloadFile } from 'technical/download-file';
import useModal from 'technical/modal/use-modal';
import { AlternativeTable } from 'ui/alternative-table';
import { CustomModal } from 'ui/custom-modal';

interface Props {
  data: GetOperationShareLpPreviewQuery['operationShareLpPreview']['operationShareLps'];
}

export const PreviewEmailingDocumentTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const [operationShareLpId, setOperationShareLpId] = useState('');
  const [downloadOperationLetterPreview] =
    useDownloadOperationLetterPreviewMutation();

  const onClickDownload = async (id: string) => {
    const resp = await downloadOperationLetterPreview({
      variables: { operationShareLpId: id },
    });
    if (
      resp.data?.downloadOperationLetterPreview?.success &&
      resp.data?.downloadOperationLetterPreview.url
    ) {
      downloadFile(
        resp.data.downloadOperationLetterPreview.url,
        GENERATED_FILE_NAME,
      );
    }
  };

  const headers: ColDef<
    GetOperationShareLpPreviewQuery['operationShareLpPreview']['operationShareLps'][number]
  >[] = getPreviewEmailDocumentColumentDefinition(
    t,
    handleOpenModal,
    setOperationShareLpId,
    onClickDownload,
  );

  return (
    <>
      <AlternativeTable<
        GetOperationShareLpPreviewQuery['operationShareLpPreview']['operationShareLps'][number]
      >
        fileName="operaiton-share-lp-preview"
        rowData={data}
        columnDefs={headers}
        domLayout="autoHeight"
      />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <SendPreviewEmailForm
            operationShareLpId={operationShareLpId}
            handleCloseModal={handleCloseModal}
          />
        }
      />
    </>
  );
};
