import { ApolloError } from '@apollo/client';
import { PropsWithChildren } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { AddressFormPart } from 'business/shared/components/address-form-part';
import { CompanyContact } from 'business/shared/services/contact/types';
import { ContactForm } from 'ui/contact-form';
import { ErrorLabel } from 'ui/error-label';
import { FormSection } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';
import { ContactFormContextEnum } from 'ui/types';

interface Props extends PropsWithChildren {
  context: ContactFormContextEnum;
  onClose: () => void;
  onSubmit: () => void;
  error?: ApolloError;
  isSubmitting?: boolean;
  control: Control<CompanyContact>;
  labelAction: string;
  onFileUpload: (formData: { filePath: string; uploadDate: Date }) => void;
  onExistingLogoDelete: () => void;
  existingLogo?: {
    name: string;
    url: string;
  };
}

export const CompanyContactModal = ({
  onClose,
  onSubmit,
  error,
  context,
  isSubmitting = false,
  control,
  labelAction,
  onFileUpload,
  onExistingLogoDelete,
  existingLogo,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormModalContainer
      title={t('pages.contact.form.formTitle', {
        context,
      })}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      labelAction={labelAction}
    >
      <>
        <ContactForm
          control={control}
          onFileUpload={onFileUpload}
          onExistingLogoDelete={onExistingLogoDelete}
          existingLogo={existingLogo}
        />
        <FormSection title={t('pages.contact.form.section.address')} lastOne>
          <AddressFormPart control={control} name="address" required={false} />
        </FormSection>
        {error ? <ErrorLabel label={error.message} /> : null}
      </>
    </FormModalContainer>
  );
};
