import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ValuationGraphDataByType } from 'business/shared/services/types';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';
import variables from 'ui/variables.module.scss';

interface Props {
  valuationCreationData: ValuationGraphDataByType;
  fileName: string;
}

export const ValuationCreationGraph = ({
  valuationCreationData,
  fileName,
}: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const plotLayout: PlotLayout = {
    colorway: [palette.secondary[800]],
    xaxis: {
      showgrid: false,
      dtick: 'M24',
    },
    xaxis2: {
      // axe that filters which traces will be shown in rangeSLider (xaxis is set on x2)
      dtick: 'M24',
      matches: 'x',
      overlaying: 'x',
      rangeslider: {
        thickness: parseFloat(variables.graphRangeSliderTickness),
      },
    },
    yaxis: {
      tickformat: '.2f',
      ticksuffix: ' x',
    },
    autosize: true,
    showlegend: false,
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    hoverlabel: {
      bgcolor: palette.backgroundForm,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTextSize),
        color: palette.text,
      },
      bordercolor: palette.backgroundForm,
    },
    margin: {
      pad: parseFloat(variables.graphAxisPadding),
    },
  };

  const tvpi = {
    name: 'tvpi',
    x: valuationCreationData.valuationDates,
    y: valuationCreationData.tvpiValues,
    hoveron: 'points',
    stackgroup: 'tvpi',
    fillcolor: 'transparent',
    hovertemplate: `<b>%{x|%Y-%m-%d}</b> <br>${t(
      'pages.fundShareValuations.tvpi',
    )}:  <b>%{y}</b><br>${t(
      'pages.fundShareValuations.rvpi',
    )}:  <b>%{customdata.rvpi:.2f} x</b><br>${t(
      'pages.fundShareValuations.dpi',
    )}:  <b>%{customdata.dpi:.2f} x</b><extra></extra>`,
    customdata: valuationCreationData.valuationGraphDataByDate,
    line: {
      color: palette.secondary[500],
    },
    type: PlotChartTypeEnum.scatter,
    mode: 'lines', // change to 'line+markers' to see points
    hoverinfo: 'all',
  };

  const dpi = {
    name: 'dpi',
    x: valuationCreationData.valuationDates,
    y: valuationCreationData.dpiValues,
    fill: 'tonexty',
    fillcolor: palette.secondary[800],
    line: {
      color: palette.secondary[800],
    },
    hovertemplate: `<b>%{x|%Y-%m-%d}</b><br>${t(
      'pages.fundShareValuations.tvpi',
    )}:  <b>%{customdata.tvpi:.2f} x</b><br>${t(
      'pages.fundShareValuations.rvpi',
    )}:  <b>%{customdata.rvpi:.2f} x</b><br>${t(
      'pages.fundShareValuations.dpi',
    )}:  <b>%{y}</b><extra></extra>`,
    customdata: valuationCreationData.valuationGraphDataByDate,
    stackgroup: 'dpiRvpi',
    hoveron: 'points',
    type: PlotChartTypeEnum.scatter,
    mode: 'lines',
    hoverinfo: 'all',
  };

  const rvpi = {
    name: 'rvpi',
    x: valuationCreationData.valuationDates,
    y: valuationCreationData.rvpiValues,
    fill: 'tonexty',
    hoverinfo: 'skip',
    mode: 'lines',
    fillcolor: palette.primaryMain,
    line: {
      color: palette.primaryMain,
    },
    stackgroup: 'dpiRvpi',
    type: PlotChartTypeEnum.scatter,
  };

  const rangeSliderTrace = {
    name: 'rangeSlider',
    x: valuationCreationData.valuationDates,
    y: valuationCreationData.tvpiValues,
    opacity: 0, // overriden by css in PlotChart
    hoverinfo: 'skip',
    type: PlotChartTypeEnum.scatter,
    xaxis: 'x2',
    fill: 'tonexty',
    fillcolor: 'transparent',
    line: {
      color: 'transparent',
    },
  };

  // dpi and rvpi are stacked on top of each other (stackgroup is the same)
  const dataChart = [dpi, rvpi, tvpi, rangeSliderTrace];

  return (
    <PlotChart dataArray={dataChart} layout={plotLayout} fileName={fileName} />
  );
};
