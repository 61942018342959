import { TFunction } from 'translations/hook';

import { LpOnboardingDocument } from 'business/fund-manager/fund/services/types';
import { GetLpKycDocumentsQueryResult } from 'generated/graphql';
import { formatAsDate } from 'technical/date';

import { getLpDocumentStatus } from './get-lp-document-status';

export const getLpOnboardingDocumentsRows = ({
  data,
  t,
}: {
  data: GetLpKycDocumentsQueryResult['data'];
  t: TFunction;
}): LpOnboardingDocument[] => {
  return (
    data?.lpKYCDocuments.map((lpDocument) => {
      return {
        name: lpDocument.document.name,
        documentId: lpDocument.documentId,
        date: lpDocument?.emissionDate
          ? formatAsDate(new Date(lpDocument?.emissionDate))
          : '',
        type: t(
          `pages.fundManager.settings.lpDocumentType.values.${lpDocument.lpDocumentType?.name}`,
          {
            defaultValue: lpDocument.lpDocumentType?.name ?? '',
          },
        ),
        status: getLpDocumentStatus(lpDocument),
        expirationDate: lpDocument?.expirationDate
          ? formatAsDate(new Date(lpDocument?.expirationDate))
          : '',
      };
    }) ?? []
  );
};
