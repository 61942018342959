import { useAppContext } from 'business/providers/app-provider';
import { hasRole } from 'business/user/helpers/roles';
import { Roles } from 'business/user/types/user';

export const useIsLpUser = (): boolean => {
  const { user } = useAppContext();

  return (
    user !== undefined &&
    (hasRole(user.roles, Roles.USER) || hasRole(user.roles, Roles.DATA_MANAGER))
  );
};
