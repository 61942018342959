import {
  getGridDateOperators,
  getGridNumericOperators,
} from '@mui/x-data-grid-premium';

const numericalOperators = [
  '=',
  '!=',
  '>',
  '>=',
  '<',
  '<=',
  'isNotEmpty',
  'isEmpty',
];

export const numericComparisonOperators = getGridNumericOperators().filter(
  (operator) => numericalOperators.includes(operator.value),
);

const dateOperators = [
  'is',
  'not',
  'after',
  'before',
  'onOrAfter',
  'onOrBefore',
];

export const dateComparisonOperators = getGridDateOperators().filter(
  (operator) => dateOperators.includes(operator.value),
);
