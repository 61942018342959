import { Tabs } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { replaceRouteWithPrefix } from 'business/fund-manager/settings/shared/hooks/route-prefix-utils';
import { LinkTab } from 'ui/link-tab';

export enum TemplateTabEnum {
  Template = 0,
  Publication = 1,
}

type TemplatePageContainerProps = PropsWithChildren<{
  activeTab: TemplateTabEnum;
  routePrefix: string;
}>;

export const TemplatePageContainer = ({
  children,
  activeTab,
  routePrefix,
}: TemplatePageContainerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Tabs value={activeTab} centered>
        <LinkTab
          label={t('pages.fundManager.templates.title')}
          to={replaceRouteWithPrefix(
            FundManagerRoutes.SettingsTemplateList,
            routePrefix,
          )}
        />
        <LinkTab
          label={t('pages.fundManager.settings.publicationData.form.title')}
          to={replaceRouteWithPrefix(
            FundManagerRoutes.SettingsPublicationData,
            routePrefix,
          )}
        />
      </Tabs>
      {children}
    </>
  );
};
