import { Chip } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { LegalEntityKycRole } from 'generated/graphql';

const LpContactRoleVariant: Record<
  `${LegalEntityKycRole}`,
  Pick<ComponentProps<typeof Chip>, 'color' | 'variant'>
> = {
  signatory: { color: 'secondary', variant: 'outlined' },
  executive: { color: 'info', variant: 'outlined' },
  legalRepresentative: { color: 'primary' },
  ultimateBeneficialOwner: { color: 'secondary' },
};

interface LpContactRoleTagProps {
  value: LegalEntityKycRole;
}

export const LpContactRoleTag = ({ value }: LpContactRoleTagProps) => {
  const { t } = useTranslation();
  return (
    <Chip
      {...LpContactRoleVariant[value]}
      size="small"
      label={t('components.lpContactRole', { context: value })}
    />
  );
};
