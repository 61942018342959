import { useLocation, useNavigate } from 'react-router';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  getSettingsRoutePrefixAndCompanyManagementId,
  replaceRouteWithPrefix,
} from 'business/fund-manager/settings/shared/hooks/route-prefix-utils';
import { useAppContext } from 'business/providers/app-provider';

export const useNavigateSettings = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAppContext();
  const { routePrefix, managementCompanyId } =
    getSettingsRoutePrefixAndCompanyManagementId(pathname, user);
  return {
    routePrefix,
    managementCompanyId,
    navigateSettings: (
      route: FundManagerRoutes,
      routePrefixOverride?: string,
    ) =>
      navigate(
        replaceRouteWithPrefix(route, routePrefixOverride ?? routePrefix),
      ),
  };
};
