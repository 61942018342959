import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FundProfile } from 'business/fund-manager/fund/services/types';
import { FundFromDateEnum } from 'generated/graphql';
import { FormInputDate, FormInputDateProps, FormInputsType } from 'ui/form';

export type FormInputDateValue = Date | null;

type ConditionalRequiredFormInputDateProps<T extends FormInputsType> =
  FormInputDateProps<T> & {
    watchedValue: FundFromDateEnum;
    name: keyof FundProfile;
  };

export const ConditionalRequiredFormInputDate = <T extends FormInputsType>({
  watchedValue,
  control,
  name,
  ...rest
}: ConditionalRequiredFormInputDateProps<T>) => {
  const [required, setRequired] = useState<boolean>(false);
  const {
    trigger,
    formState: { isSubmitted },
  } = useFormContext<FundProfile>();
  const termFormDateOf = useWatch<FundProfile>({
    name: 'termFromDateOf',
    control,
  });
  const periodFormDateOf = useWatch<FundProfile>({
    name: 'investmentPeriodFromDateOf',
    control,
  });

  // here we use a useEffect because we need to watch other field value
  // then trigger a form validation to apply new format (only when required value change)
  useEffect(() => {
    setRequired((oldValue) => {
      const value =
        termFormDateOf === watchedValue || periodFormDateOf === watchedValue;
      // we trigger validation only when value changed and the form is already submitted
      // Like this the field will became red if it is required
      if (isSubmitted && value !== oldValue) {
        trigger(name);
      }
      return value;
    });
  }, [
    termFormDateOf,
    periodFormDateOf,
    watchedValue,
    trigger,
    name,
    isSubmitted,
  ]);

  return (
    <FormInputDate
      {...rest}
      name={name}
      required={required}
      control={control}
    />
  );
};
