import { Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyList as CompanyListContent } from 'business/fund-manager/portfolio/company/components/company-list';
import { CompanyStatusSelector } from 'business/fund-manager/portfolio/company/components/company-status-selector';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  CompaniesQuery,
  useCompaniesQuery,
  WebCompanyStatusEnum,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { Card } from 'ui/card';
import { CheckBox } from 'ui/check-box';
import { NaceSectorSelector } from 'ui/nace-code/nace-sector-selector';
import { NaceSector } from 'ui/nace-code/types';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SearchBar } from 'ui/search-bar';

const getCompanies = ({
  filter,
  companies,
}: {
  filter: CompanyListFilterState;
  companies: CompaniesQuery['companies'];
}) => {
  if (!filter.search && !filter.sector && !filter.status) {
    return companies;
  }
  return companies.filter((company) => {
    const searchFilter = filter.search
      ? company.name.toLowerCase().includes(filter?.search?.toLowerCase()) ||
        company.identifier.toLowerCase().includes(filter?.search)
      : true;

    const sectorFilter = filter.sector
      ? filter.sector.codes.includes(company.codeNace)
      : true;

    const statusFilter = filter.status
      ? filter.status === company.status
      : true;

    return searchFilter && sectorFilter && statusFilter;
  });
};

interface CompanyListFilterState {
  search?: string;
  sector?: NaceSector;
  status?: WebCompanyStatusEnum;
}

export const CompanyList = () => {
  const { t } = useTranslation();
  const { fundId = '' } = useParams();
  const [filter, setFilter] = useState<CompanyListFilterState>({});
  const [includeDrafts, setIncludeDrafts] = useState<boolean>(false);
  const navigate = useNavigate();
  const { loading, error, data } = useCompaniesQuery({
    variables: {
      input: {
        fundId,
        includeDrafts,
      },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Stack spacing={2}>
      <Card>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
          spacing={2}
        >
          <Stack spacing={2} alignItems="start" direction="row">
            <SearchBar
              label={t('common.search')}
              name="companyFilter"
              onChange={(search: string) =>
                setFilter((f) => ({ ...f, search }))
              }
              size="small"
            />

            <Stack>
              <CompanyStatusSelector
                name="companyStatusSelector"
                onChange={(status?: WebCompanyStatusEnum) =>
                  setFilter((f) => ({ ...f, status }))
                }
                value={filter.status}
                size="small"
                fullWidth
              />
            </Stack>

            <Stack>
              <NaceSectorSelector
                name="naceSectorSelector"
                onChange={(sector?: NaceSector) =>
                  setFilter((f) => ({ ...f, sector }))
                }
                value={filter.sector}
                size="small"
                fullWidth
              />
            </Stack>
            <Stack>
              <CheckBox
                checked={includeDrafts}
                label={t('pages.fundManager.portfolio.company.include-drafts')}
                field="include-drafts"
                dataTestId="include-draft-checkbox"
                onChange={() => setIncludeDrafts(!includeDrafts)}
              />
            </Stack>
          </Stack>

          <ActionButton
            variant="submit"
            onClick={() => {
              navigate(
                FundManagerRoutes.PortfolioFundIdCompanyCreate.replace(
                  ':fundId',
                  fundId,
                ),
              );
            }}
          >
            {t('pages.fundManager.portfolio.company.createNew')}
          </ActionButton>
        </Stack>
      </Card>
      <Stack>
        {loading || error || data?.companies === undefined ? (
          <QueryStateDisplay loading={loading} error={error} />
        ) : (
          <CompanyListContent
            companies={getCompanies({
              filter,
              companies: data.companies,
            })}
            editPath={FundManagerRoutes.PortfolioFundIdCompanyId.replace(
              ':fundId',
              fundId,
            )}
          />
        )}
      </Stack>
    </Stack>
  );
};
