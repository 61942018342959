import { OnboardingSubscriptionStatusEnum } from 'generated/graphql';

import { ONBOARDING_SUBSCRIPTION_GP_STEPS_ORDERED } from './onboarding-subscription.constants';

export const getOnboardingSubscriptionGPStepIndex = (
  status: OnboardingSubscriptionStatusEnum,
) =>
  ONBOARDING_SUBSCRIPTION_GP_STEPS_ORDERED.findIndex((steps) =>
    steps.includes(status),
  ) + 1;
