import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { TypedOperation } from 'business/lp-platform/valuations/services/types';
import { xirr } from 'business/lp-platform/valuations/services/xirr';
import { useFund } from 'business/providers/fund-provider/use-fund';
import {
  formatNumber,
  formatRatioToPercentage,
} from 'technical/currency/formatters';
import { Amount } from 'ui/amount';
import { InfoCard } from 'ui/info-card';
import SynthesisItem from 'ui/synthesis-item';

interface Props {
  title: string;
  tvpi: number;
  cashflows: TypedOperation[];
  numericResidualValue: number;
  className?: string;
}

export const FundsShareValuationsCard = ({
  title,
  tvpi,
  cashflows,
  numericResidualValue,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { currency } = useFund();

  const irrFlux = cashflows.reduce<{ values: number[]; dates: Date[] }>(
    (acc, c) => ({
      values: [...acc.values, c.numericAmount],
      dates: [...acc.dates, c.date],
    }),
    { values: [], dates: [] },
  );
  const netIrr = xirr(irrFlux.values, irrFlux.dates);
  const formattedIrr = isNaN(netIrr) ? 'N/A' : formatRatioToPercentage(netIrr);

  return (
    <InfoCard className={className}>
      <InfoCard.Title path={LpPlatformRoutes.FundShareValuations}>
        {title}
      </InfoCard.Title>
      <InfoCard.Items noPaddingTop>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <SynthesisItem
            value={formattedIrr}
            description={t('pages.dashboard.cards.fundShareValuations.netIrr')}
            backgroundColor="secondary"
          />
          <SynthesisItem
            value={`${formatNumber(tvpi, { precision: 2 })} x`}
            description={t('pages.dashboard.cards.fundShareValuations.tvpi')}
            backgroundColor="primary"
          />
          <SynthesisItem
            value={
              <Amount
                color="primary"
                value={numericResidualValue}
                currency={currency}
                size="2xl"
                ellipsis
              />
            }
            description={t(
              'pages.dashboard.cards.fundShareValuations.residualValue',
            )}
            backgroundColor="tertiary"
          />
        </Stack>
      </InfoCard.Items>
    </InfoCard>
  );
};
