import { Grid } from '@mui/material';
import { PropsWithChildren } from 'react';

import { InfoCard } from 'ui/info-card';
import Spacer from 'ui/spacer';

import styles from './index.module.scss';

type SettingsCardProps = PropsWithChildren<{
  title: string;
  path?: string;
}>;

export const SettingsCard = ({ title, path, children }: SettingsCardProps) => (
  <Grid item xs={4}>
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>
      <Spacer
        justify="center"
        align="center"
        size="large"
        className={styles.settingsCardData}
      >
        {children}
      </Spacer>
    </InfoCard>
  </Grid>
);
