import { ManagementCompany } from 'business/fund-manager/settings/management-company/services/types';
import { CommonUserFieldsFragment } from 'generated/graphql';
import { areAllPropertiesNotNull } from 'technical/are-all-properties-not-null/are-all-properties-not-null';

export enum Roles {
  USER = 'user',
  DATA_MANAGER = 'data-manager',
  FUND_MANAGER = 'fund-manager',
  FUND_MANAGER_BACK_OFFICE = 'fund-manager-back-office',
  FUND_MANAGER_FRONT_OFFICE = 'fund-manager-front-office',
  ONBOARDING_USER = 'onboarding-user',
}

export interface Lp {
  id: string;
  name: string;
}

export interface UserData extends UserWithPrivateInfos {
  user: {
    firstName?: string | null | undefined;
  };
}
export interface UserWithPrivateInfos {
  userId: string;
  identityId: string | null;
  email: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  lps: Lp[];
  roles: string[];
  managementCompany: ManagementCompany | null;
}

export function isUserWithPrivateInfos(
  user: CommonUserFieldsFragment,
): user is UserWithPrivateInfos {
  if (!user || !areAllPropertiesNotNull(user)) {
    return false;
  }
  return true;
}
