import { Grid } from '@mui/material';
import { useTranslation } from 'translations/hook';

import SingleLpContactInformation from 'business/lp-platform/home/components/single-lp-contact-information';
import { areMultipleLpStored } from 'business/lp-platform/nav-bar/services/lp-filters';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { GetDashBoardInfosQuery } from 'generated/graphql';
import { isDefined } from 'technical/is-defined';
import { GridSpacer } from 'ui/grid-spacer';
import { InfoCard } from 'ui/info-card';
import VerticalAvatarTitle from 'ui/vertical-avatar-title';

interface Props {
  data: Pick<
    GetDashBoardInfosQuery,
    | 'legalRepresentatives'
    | 'contacts'
    | 'contactsWithLpPortalAccess'
    | 'dataviz_lp'
  >;
  title: string;
}

export default function ContactsCard({ data, title }: Props) {
  const { t } = useTranslation();

  const legalRepresentatives = data.dataviz_lp.map(
    ({ lpName, logoPath, lpContacts }) => {
      if (!lpContacts.length) {
        return null;
      }
      return {
        lpName,
        logoPath,
        legalRepresentativeName: lpContacts[0].contact.fullName,
      };
    },
  );
  return (
    <InfoCard>
      <InfoCard.Title path={LpPlatformRoutes.Contact}>{title}</InfoCard.Title>
      {!areMultipleLpStored() ? (
        <SingleLpContactInformation data={data} />
      ) : (
        <GridSpacer>
          {legalRepresentatives
            .filter(isDefined)
            .map(({ logoPath, legalRepresentativeName, lpName }) => (
              <Grid item xs={4} key={lpName}>
                <VerticalAvatarTitle
                  name={legalRepresentativeName}
                  logoPath={logoPath ?? ''}
                  alt={lpName}
                  description={t(
                    'pages.dashboard.cards.contacts.legalRepresentative',
                  )}
                />
              </Grid>
            ))}
        </GridSpacer>
      )}
    </InfoCard>
  );
}
