import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { UploadDocumentTemplateForm } from 'business/fund-manager/shared/components/upload-document-template-form/upload-document-template-form';
import { WebDocumentDownloadButton } from 'business/fund-manager/shared/components/web-download-button';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { UploadDocumentTemplateType } from 'business/shared/services/document/types';
import {
  useOperationDocumentTemplateQuery,
  useUpdateOperationTemplateDocumentMutation,
} from 'generated/graphql';
import { IFile } from 'technical/file-management/types';
import { QueryStateDisplay } from 'ui/query-state-display';

export const OperationDocumenttemplate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const palette = usePalette();
  const { operationId = '' } = useParams();

  const { data, loading, error } = useOperationDocumentTemplateQuery({
    variables: {
      input: {
        operationId,
      },
    },
  });

  const [updateOperationTemplateDocument] =
    useUpdateOperationTemplateDocumentMutation({
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.uploadOperationDocumentTemplate'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: () => {
        enqueueSnackbar(t('errors.uploadOperationDocumentTemplate'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const onSubmit = async ({
    uploadedFileList,
    formValues,
  }: {
    uploadedFileList: IFile[];
    formValues: UploadDocumentTemplateType;
  }) => {
    updateOperationTemplateDocument({
      variables: {
        input: {
          filePath: formValues.filePath,
          fileName: uploadedFileList[0].name,
          operationId,
        },
      },
    });
  };

  if (loading || error || !data) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5" sx={{ color: palette.primary[900] }}>
        {t('pages.fundManager.templates.operationDocument')}
      </Typography>

      {data?.operationTemplateDocument?.documentId ? (
        <WebDocumentDownloadButton
          documentIds={[data?.operationTemplateDocument?.documentId]}
        />
      ) : null}
      <UploadDocumentTemplateForm onSubmit={onSubmit} />
    </Stack>
  );
};
