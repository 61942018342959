// TODO: move in ui ?

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';
import variables from 'ui/variables.module.scss';

interface Props {
  fileName: string;
  percent: number;
  displayNumber?: number;
}
export const CompletionGaugeGraph = ({
  fileName,
  percent,
  displayNumber,
}: Props) => {
  const palette = usePalette();

  const plotLayout: PlotLayout = {
    autosize: true,
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    margin: { t: 0, b: 0, l: 0, r: 0 },
  };

  const completion = {
    value: displayNumber ?? percent,
    number: { suffix: displayNumber !== undefined ? '' : '%' },
    type: PlotChartTypeEnum.indicator,
    mode: 'gauge+number',
    gauge: {
      axis: {
        range: [null, 100],
        visible: false,
      },
      borderwidth: 0,
      bar: { color: palette.primaryMain },
      steps: [
        {
          range: [0, percent],
          color: palette.primaryMain,
          thickness: 0.8,
        },
        {
          range: [percent, 100],
          color: palette.secondaryMain,
          thickness: 0.8,
        },
      ],
    },
  };

  const dataChart = [completion];

  return (
    <PlotChart dataArray={dataChart} layout={plotLayout} fileName={fileName} />
  );
};
