import { useMemo } from 'react';

import { useGetDashBoardInfosQuery } from 'generated/graphql';
import { getNinetyDaysAgo } from 'technical/date';

export function useDashBoardInfosQuery(startDate?: string) {
  const ninetyDaysAgo = useMemo(() => getNinetyDaysAgo(), []);
  const { data, loading, error } = useGetDashBoardInfosQuery({
    variables: {
      startDate: startDate || ninetyDaysAgo,
    },
  });

  if (loading) {
    return { loading };
  }

  if (error) {
    return { error };
  }

  return { data };
}
