import { GetCompaniesQuery } from 'generated/graphql';
import { convertFromX100toNumber } from 'technical/currency/formatters';

import { CompanyDisplayedValues, PortfolioGraph } from './types';

export function selectCompanyDisplayedValue(
  data: GetCompaniesQuery,
  valueKey: CompanyDisplayedValues,
): PortfolioGraph[] {
  return data.companies.map(({ id, sector, location, ...rest }) => {
    // rest[valueKey] can be null if, and only if, valueKey === latestValuation
    const displayedValue = rest[valueKey] ?? 0;
    return {
      id,
      mainSector: sector,
      displayedValue: convertFromX100toNumber(displayedValue),
      // TODO replace it when location field is pushed
      country: location,
    };
  });
}
