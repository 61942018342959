import { Box } from '@mui/material';
import React from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
}

export default function PageAuth({ children }: Props) {
  const palette = usePalette();
  return (
    <Box
      sx={{ background: palette.backgroundAuth }}
      className={styles.container}
    >
      {children}
    </Box>
  );
}
