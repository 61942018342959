import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { ChangeEvent, PropsWithChildren } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { CreateSubscription } from 'business/fund-manager/subscription/services/types';
import { createSubscriptionSchema } from 'business/fund-manager/subscription/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  GetActiveFundsWithSharesQuery,
  LpsForSubscriptionsQuery,
  Web_Portal_ManagementCompanyType_Enum,
  useCreateSubscriptionMutation,
  useGetActiveFundsWithSharesQuery,
  useGetSharesByFundIdQuery,
  useLpsForSubscriptionsQuery,
  useSubscriptionBankDetailsByLpAndFundQuery,
  SubscriptionDocumentTypeEnum,
} from 'generated/graphql';
import { useOnFilesUpload } from 'technical/file-management/use-on-files-upload';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SubscriptionForm } from 'ui/subscription-form/subscription-form';

type SubscriptionFormContainerProps = PropsWithChildren<{
  onSubmit?: () => void;
  onModalClose: () => void;
  isSubmitting?: boolean;
}>;

const SubscriptionFormContainer = ({
  children,
  onSubmit,
  onModalClose,
  isSubmitting,
}: SubscriptionFormContainerProps) => {
  const { t } = useTranslation();

  return (
    <FormModalContainer
      onSubmit={onSubmit}
      onClose={onModalClose}
      title={t('pages.fundManager.subscription.form.header.title')}
      isSubmitting={isSubmitting}
    >
      {children}
    </FormModalContainer>
  );
};

interface SubscriptionFormContentProps {
  handleClose: () => void;
  readOnlyLpId?: boolean;
  readOnlyFundId?: boolean;
  onCompleted?: () => void;
  funds: GetActiveFundsWithSharesQuery['funds'];
  lps: LpsForSubscriptionsQuery['lps'];
  defaultValues?: Partial<CreateSubscription>;
}

const SubscriptionFormContent = ({
  onCompleted,
  handleClose,
  readOnlyFundId,
  readOnlyLpId,
  lps,
  funds,
  defaultValues,
}: SubscriptionFormContentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const [createSubscription, { error }] = useCreateSubscriptionMutation({
    onCompleted: () => {
      onCompleted?.();
      handleClose();
      enqueueSnackbar(
        isAdvisor
          ? t('successMessage.createSubscriptionPendingValidation')
          : t('successMessage.createSubscription'),
        {
          variant: VariantTypeEnum.SUCCESS,
        },
      );
    },
  });

  const methods = useForm<CreateSubscription>({
    defaultValues,
    resolver: yupResolver<CreateSubscription>(createSubscriptionSchema),
  });

  const onSubscriptionLettersUpload = useOnFilesUpload({
    getFiles: () => methods.getValues('subscriptionLetters') ?? [],
  });

  const onSideLettersUpload = useOnFilesUpload({
    getFiles: () => methods.getValues('sideLetters') ?? [],
  });

  const onFilesUpload = (
    type: SubscriptionDocumentTypeEnum,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    if (type === SubscriptionDocumentTypeEnum.SideLetter) {
      return onSideLettersUpload(e);
    }

    return onSubscriptionLettersUpload(e);
  };

  const onSubmit = ({
    amount,
    date,
    sideLetters,
    subscriptionLetters,
    ...restInput
  }: CreateSubscription) =>
    createSubscription({
      variables: {
        input: {
          ...restInput,
          amount,
          date: date.toUTCString(),
          sideLetter: sideLetters?.[0]
            ? {
                fileName: sideLetters[0].name,
                filePath: sideLetters[0].filePath,
              }
            : undefined,
          subscriptionLetter: subscriptionLetters?.[0]
            ? {
                fileName: subscriptionLetters[0].name,
                filePath: subscriptionLetters[0].filePath,
              }
            : undefined,
        },
      },
      // form react-hook-form submitting
    }).catch(() => undefined);

  const fundId = useWatch({ name: 'fundId', control: methods.control });
  const lpId = useWatch({ name: 'lpId', control: methods.control });

  const {
    data: shares,
    loading: sharesLoading,
    error: sharesError,
  } = useGetSharesByFundIdQuery({
    variables: { fundId },
    // no need to send query when defaultFundId not defined
    skip: !fundId,
  });

  const sharesOptions = (
    !sharesError && !sharesLoading && shares ? shares.shares : []
  ).map(({ id, name }) => ({ id, value: name }));

  useSubscriptionBankDetailsByLpAndFundQuery({
    fetchPolicy: 'network-only',
    variables: {
      lpId,
      fundId,
    },
    onCompleted: (data) => {
      if (data.bankDetails.length === 0) {
        return;
      }
      const bankDetails = data.bankDetails[0];
      methods.setValue('bankDetails', {
        name: bankDetails.name ?? '',
        bic: bankDetails.bic ?? '',
        iban: bankDetails.iban ?? '',
        comment: bankDetails.comment ?? '',
      });
    },
    skip: !fundId || !lpId,
  });

  const onFundChange = (value: string | null) => {
    methods.setValue('fundId', value ?? '');
    methods.setValue('shareId', '');
  };

  const onLpChange = (id: string | null) => {
    methods.setValue('lpId', id ?? '');
    const { taxOption = false } = lps.find((v) => v.id === id) ?? {};
    methods.setValue('taxOption', taxOption ?? false);
  };

  return (
    <SubscriptionFormContainer
      onModalClose={handleClose}
      onSubmit={methods.handleSubmit(onSubmit)}
      isSubmitting={methods.formState.isSubmitting}
    >
      <FormProvider {...methods}>
        <SubscriptionForm
          onFilesUpload={onFilesUpload}
          readOnlyFundId={readOnlyFundId}
          fundsOptions={funds.map((fund) => ({
            id: fund.id,
            value: fund.name,
          }))}
          onFundChange={onFundChange}
          readOnlyLpId={readOnlyLpId}
          lpsOptions={lps.map(({ id, lpName }) => ({
            id: id ?? '',
            value: lpName ?? '',
          }))}
          onLpChange={onLpChange}
          sharesLoading={sharesLoading}
          sharesOptions={sharesOptions}
          error={error}
        />
      </FormProvider>
    </SubscriptionFormContainer>
  );
};

interface AddSubscriptionFormProps
  extends Pick<SubscriptionFormContentProps, 'handleClose' | 'onCompleted'> {
  defaultFundId?: string;
  defaultLpId?: string;
}

export const AddSubscriptionForm = ({
  handleClose,
  defaultFundId,
  defaultLpId,
  ...rest
}: AddSubscriptionFormProps) => {
  const fundsQuery = useGetActiveFundsWithSharesQuery({
    fetchPolicy: 'network-only',
  });

  const lpsQuery = useLpsForSubscriptionsQuery({
    fetchPolicy: 'network-only',
  });

  if (
    lpsQuery.loading ||
    lpsQuery.error ||
    lpsQuery?.data?.lps === undefined ||
    fundsQuery.loading ||
    fundsQuery.error ||
    fundsQuery?.data?.funds === undefined
  ) {
    return (
      <SubscriptionFormContainer onModalClose={handleClose}>
        <QueryStateDisplay
          loading={lpsQuery.loading || fundsQuery.loading}
          error={lpsQuery.error || fundsQuery.error}
        />
      </SubscriptionFormContainer>
    );
  }
  const defaultValues: Partial<CreateSubscription> = {
    lpId: defaultLpId ?? '',
    fundId: defaultFundId ?? '',
    taxOption: defaultLpId
      ? lpsQuery.data.lps.find((lp) => lp.id === defaultLpId)?.taxOption ??
        false
      : false,
  };

  return (
    <SubscriptionFormContent
      {...rest}
      readOnlyLpId={!!defaultLpId}
      readOnlyFundId={!!defaultFundId}
      handleClose={handleClose}
      lps={lpsQuery.data.lps}
      funds={fundsQuery.data.funds}
      defaultValues={defaultValues}
    />
  );
};
