import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { ErrorInfo } from 'business/user/services/errors';
import { LoginForm } from 'business/user/services/types';
import { loginFormSchema } from 'business/user/services/validation';
import { ActionButton } from 'ui/action-button';
import { FormInputText } from 'ui/form';
import { InputError } from 'ui/form/input-error';

import styles from './index.module.scss';

interface Props {
  submitButtonLabel: string;
  onSubmit: (values: LoginForm) => Promise<void>;
  authError: {
    key: string;
    error: ErrorInfo | null;
  };
  hint?: React.ReactNode;
  passwordHint?: React.ReactNode;
  bottomHint?: React.ReactNode;
  className?: string;
}

export const AuthCard = ({
  submitButtonLabel,
  onSubmit,
  authError,
  hint,
  passwordHint,
  bottomHint,
  className,
}: Props) => {
  const { t } = useTranslation();

  const methods = useForm<LoginForm>({
    resolver: yupResolver(loginFormSchema(t)),
  });

  return (
    <div className={className}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Stack className={styles.stack} spacing={7}>
            <Stack spacing={4}>
              <Typography variant="h4">
                {t('notConnected.login.title')}
              </Typography>
              <Stack spacing={2}>
                <FormInputText
                  id="login"
                  name="login"
                  label={t('notConnected.login.email')}
                  autoComplete="username"
                  data-test-id="login-input"
                />
                <FormInputText
                  id="password"
                  name="password"
                  label={t('notConnected.login.password')}
                  autoComplete="current-password"
                  type="password"
                  data-test-id="password-input"
                />
                <InputError
                  error={
                    authError.error && authError.error.context !== null
                      ? t(authError.key, {
                          context: authError.error.context,
                          ...authError.error.variables,
                        })
                      : undefined
                  }
                />
                {hint}
              </Stack>
              <ActionButton
                data-test-id="submit-button"
                variant="submit"
                type="submit"
                loading={methods.formState.isSubmitting}
              >
                {submitButtonLabel}
              </ActionButton>
              {passwordHint}
            </Stack>
            {bottomHint}
          </Stack>
        </FormProvider>
      </form>
    </div>
  );
};
