import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CreateNewLpInOnboardingForm } from 'business/fund-manager/onboarding-subscription/services/types';
import { createNewLpInOnboardingFormSchema } from 'business/fund-manager/onboarding-subscription/services/validation';
import { ClientTypeEnum } from 'generated/graphql';
import { FormInputText, SelectInput } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';

interface Props {
  onClose: () => void;
  onSubmit: (formData: CreateNewLpInOnboardingForm) => void;
  lpForbiddenNames: string[];
}

export const CreateNewLpInOnboardingFormModal = ({
  onClose,
  onSubmit,
  lpForbiddenNames,
}: Props) => {
  const { t } = useTranslation();

  const clientSelectOptions = [
    {
      value: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.legalEntity',
      ),
      id: ClientTypeEnum.LegalEntity,
    },
    {
      value: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.individual',
      ),
      id: ClientTypeEnum.Individual,
    },
  ];

  const methods = useForm<CreateNewLpInOnboardingForm>({
    resolver: yupResolver<CreateNewLpInOnboardingForm>(
      createNewLpInOnboardingFormSchema,
    ),
    defaultValues: {
      client: ClientTypeEnum.LegalEntity,
    },
  });

  const client = useWatch({
    name: 'client',
    control: methods.control,
  });

  const handleSubmit = (formData: CreateNewLpInOnboardingForm) => {
    const subscribingEntityName =
      formData.client === ClientTypeEnum.LegalEntity
        ? formData.subscribingEntityName
        : `${formData.firstName} ${formData.lastName}`;
    if (!subscribingEntityName) {
      methods.setError('subscribingEntityName', {
        message:
          'pages.fundManager.onboardingSubscriptionCreate.createNewLp.errors.subscribingEntityName.required',
      });
      return;
    }
    if (lpForbiddenNames.includes(subscribingEntityName)) {
      methods.setError('subscribingEntityName', {
        message:
          'pages.fundManager.onboardingSubscriptionCreate.createNewLp.errors.subscribingEntityName.forbidden',
      });
      return;
    }
    return onSubmit({ ...formData, subscribingEntityName });
  };

  const onChangeNames = () => {
    if (client === ClientTypeEnum.Individual) {
      methods.setValue(
        'subscribingEntityName',
        `${methods.getValues('firstName') ?? ''} ${
          methods.getValues('lastName') ?? ''
        }`,
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <FormModalContainer
        title={t(
          'pages.fundManager.onboardingSubscriptionCreate.createNewLp.title',
        )}
        onClose={onClose}
        handleSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Stack spacing={2}>
          <>{methods.formState.errors.root?.errors?.message}</>
          <SelectInput
            label={t(
              'pages.fundManager.lpCreation.form.generalInformation.client.label',
            )}
            id="client"
            name="client"
            fullWidth
            options={clientSelectOptions}
            required
          />

          {client === ClientTypeEnum.LegalEntity && (
            <FormInputText
              id="subscribingEntityName"
              name="subscribingEntityName"
              required
              fullWidth
              label={t(
                'pages.fundManager.lpCreation.form.subscribingEntityName.label',
              )}
            />
          )}

          <FormInputText
            id="firstName"
            name="firstName"
            required
            fullWidth
            label={t('pages.contact.form.label.firstName')}
            onChange={(e) => {
              methods.setValue('firstName', e.target.value);
              onChangeNames();
            }}
          />

          <FormInputText
            id="lastName"
            name="lastName"
            required
            fullWidth
            label={t('pages.contact.form.label.lastName')}
            onChange={(e) => {
              methods.setValue('lastName', e.target.value);
              onChangeNames();
            }}
          />
          <FormInputText
            id="email"
            name="email"
            label={t('pages.contact.form.label.email')}
            type="email"
            required
          />
        </Stack>
      </FormModalContainer>
    </FormProvider>
  );
};
