import { GetFundsWithSubscriptionDetailsQuery } from 'generated/graphql';

import { FundData } from './types';

export const getFundData = ({
  funds,
}: {
  funds?: GetFundsWithSubscriptionDetailsQuery['getFundsWithSubscriptionDetails'];
}): FundData[] => {
  if (!funds) {
    return [];
  }

  return funds.map((fund) => {
    const pendingValidationItemsCount =
      fund.fundSubscriptionsDetails.countPendingValidationShares +
      fund.fundSubscriptionsDetails.countPendingValidationSubscriptions +
      fund.fundSubscriptionsDetails.countPendingValidationShareTransfers;

    return {
      id: fund.id,
      fundName: fund.name,
      publicationStatus: fund.publicationStatus,
      vintageYear: fund.vintageYear,
      numericCommitments: fund.fundSubscriptionsDetails.numericCommitments,
      numericTotalDrawn: fund.fundSubscriptionsDetails.numericTotalDrawn,
      numericDistributedNonRecallable:
        fund.fundSubscriptionsDetails.numericDistributedNonRecallable,
      numericCurrentDistributed:
        fund.fundSubscriptionsDetails.numericCurrentDistributed,
      subscribers: fund.fundSubscriptionsDetails.lpCount,
      currency: fund.currency ?? undefined,
      numericUnfundedCommitments:
        fund.fundSubscriptionsDetails.numericUnfundedCommitments,
      pendingValidationItemsCount,
    };
  });
};
