import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FundDocumentTypeForm } from 'business/fund-manager/settings/advisor-document-type/services/type';
import { fundDocumentTypeSchema } from 'business/fund-manager/settings/advisor-document-type/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useCreateFundDocumentTypeMutation } from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { CustomModal } from 'ui/custom-modal';
import { FormInputText } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  managementCompanyId: string;
}

export const CreateFundDocumentTypeModal = ({
  managementCompanyId,
  onClose,
  open,
}: Props) => {
  const { t } = useTranslation();
  const methods = useForm<FundDocumentTypeForm>({
    resolver: yupResolver<FundDocumentTypeForm>(fundDocumentTypeSchema),
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const [
    createFundDocumentType,
    {
      loading: createFundDocumentTypeLoading,
      error: createFundDocumentTypeError,
    },
  ] = useCreateFundDocumentTypeMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.createDocumentType'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      handleClose();
    },
  });

  const onSubmit = (data: FundDocumentTypeForm) =>
    createFundDocumentType({
      variables: {
        input: {
          ...data,
          managementCompanyId,
        },
      },
    }).catch(() => undefined);

  if (createFundDocumentTypeLoading || createFundDocumentTypeError) {
    return (
      <QueryStateDisplay
        loading={createFundDocumentTypeLoading}
        error={createFundDocumentTypeError}
      />
    );
  }

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      component={
        <FormProvider {...methods}>
          <FormModalContainer
            title={t(
              `pages.fundManager.settings.documentType.form.title.create`,
            )}
            onClose={onClose}
            onSubmit={methods.handleSubmit(onSubmit)}
            isSubmitting={methods.formState.isSubmitting}
            labelAction={t('common.actions.upload')}
            disableSubmit={!methods.formState.isValid}
          >
            <Stack spacing={4}>
              <FormInputText
                name="name"
                label={t(
                  'pages.fundManager.settings.documentType.headers.name',
                )}
                required
              />
            </Stack>
          </FormModalContainer>
        </FormProvider>
      }
    />
  );
};
