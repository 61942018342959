import { Stack } from '@mui/material';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'translations/hook';

import { ErrorLabel } from 'ui/error-label';
import { Option } from 'ui/types';

import styles from './index.module.scss';

type AutocomplProps = AutocompleteProps<
  any,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
>;

export interface SelectProps<T extends string = string> {
  id?: string;
  options: Option<T>[];
  disabled?: boolean;
  fullWidth?: boolean;
  disableClearable?: boolean;
  value?: T;
  placeholder?: string;
  label: string;
  name: string;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  onChange: (value: T | null) => void;
  dataTestId?: string;
  size?: 'small' | 'medium';
  className?: string;
  loading?: boolean;
  renderOption?:
    | ((
        props: HTMLAttributes<HTMLLIElement>,
        option: Option<T>,
        state: AutocompleteRenderOptionState,
      ) => ReactNode)
    | undefined;
  sx?: AutocomplProps['sx'];
}

export const Select = <T extends string = string>({
  options,
  disabled,
  fullWidth,
  disableClearable,
  value,
  placeholder,
  label,
  name,
  required,
  helperText,
  onChange,
  error,
  dataTestId,
  size,
  className,
  loading,
  renderOption,
  sx,
}: SelectProps<T>) => {
  const { t } = useTranslation();
  const inputValue = options.find((opt: Option<T>) => opt.id === value) ?? null;

  return (
    <Stack
      flexGrow={fullWidth ? 1 : undefined}
      sx={{ ...sx, ...(fullWidth ? { width: '100%' } : undefined) }}
    >
      <Autocomplete
        loading={loading}
        className={styles.autocomplete}
        disabled={disabled}
        options={loading ? [] : options}
        autoHighlight
        getOptionLabel={(option: Option) => option.value}
        fullWidth={fullWidth}
        disableClearable={disableClearable}
        data-test-id={dataTestId}
        value={inputValue ?? null}
        noOptionsText={t('common.noOptions')}
        size={size}
        renderOption={renderOption}
        sx={sx}
        renderInput={(params) => (
          <TextField
            {...params}
            className={className}
            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
            label={label}
            value={inputValue?.value}
            name={name}
            required={required}
            placeholder={placeholder}
            error={error}
            fullWidth={fullWidth}
            size={size}
            sx={sx}
          />
        )}
        onChange={(_, newValue: Option<T> | null) => {
          onChange(newValue ? newValue.id : null);
        }}
      />
      {helperText ? <ErrorLabel label={helperText} /> : null}
    </Stack>
  );
};
