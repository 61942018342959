import { TFunction } from 'translations/hook';
import { LanguageEnum } from 'translations/types';

import { Address } from 'generated/graphql';
import { getCountryNameByCountryCode } from 'technical/country-utils';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

export const getAddressItems = ({
  address,
  t,
  language,
}: {
  address: Record<string, string | null | undefined>;
  t: TFunction;
  language: LanguageEnum;
}): ValidationItemType[] => {
  const addressKeys: (keyof Address)[] = [
    'streetLine',
    'streetLine2',
    'zipCode',
    'city',
    'country',
  ];

  return addressKeys
    .map((key): ValidationItemType | undefined => {
      const value = address?.[key];
      if (!value) {
        return undefined;
      }

      return {
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(
          'pages.fundManager.lpValidation.legalEntityGeneralInformation.address',
          {
            context: key,
          },
        ),
        value:
          key === 'country'
            ? getCountryNameByCountryCode({
                code: value,
                language,
              })
            : value,
      };
    })
    .filter(
      (v: ValidationItemType | undefined): v is ValidationItemType => !!v,
    );
};
