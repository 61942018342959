import { ContactGeneralInformation } from 'business/shared/lp-creation-module/services/contact-types';
import { ContactWithId } from 'business/shared/services/contact/types';

export const getContactDefaultValues = (): ContactGeneralInformation => ({
  title: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber1: '',
  phoneNumber2: '',
  companyName: '',
  jobTitle: '',
  comment: '',
});

export const getLpContactsBooleanFields = (): (keyof Pick<
  ContactWithId,
  | 'hasOperationalRights'
  | 'hasLpPortalAccess'
  | 'canAccessGeneralReports'
  | 'canAttendInvestorsMeeting'
  | 'canAccessESGReports'
  | 'canAccessAmpereReports'
  | 'canAccessCapitalAccountStatement'
>)[] => [
  'hasOperationalRights',
  'hasLpPortalAccess',
  'canAccessGeneralReports',
  'canAttendInvestorsMeeting',
  'canAccessESGReports',
  'canAccessAmpereReports',
  'canAccessCapitalAccountStatement',
];

export const lpAvailableRoutesRegex =
  /^(edit_profile|edit_bank-details|contacts|financial-flow|subscription|escrow-acccount|pending-validation|validate-profile|edit_kyc|edit_legal-entity-kyb)$/;
