import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

import { LpCustomQuestions } from 'business/shared/lp-creation-module/components/lp-custom-questions/lp-custom-questions';
import { DeclineReason } from 'business/shared/lp-creation-module/components/lp-decline-reason/lp-decline-reason';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';

interface Props<T extends FieldValues> {
  methods: UseFormReturn<T>;
  children?: React.ReactNode;
  lpId: string;
}

export const LpFormProvider = <T extends FieldValues>({
  children,
  methods,
  lpId,
}: Props<T>) => {
  const { activeStepIndex, steps } = useDraftEditionStepsContext();
  const entityStep = steps[activeStepIndex]?.step;

  const hasActivatedCustomQuestions = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_CUSTOM_QUESTION,
  );

  return (
    <FormProvider {...methods}>
      <DeclineReason />
      {children}
      {hasActivatedCustomQuestions ? (
        <LpCustomQuestions lpId={lpId} entityStep={entityStep} />
      ) : null}
    </FormProvider>
  );
};
