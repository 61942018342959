import { Add, Remove } from '@mui/icons-material';
import { Divider, InputAdornment, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { ComponentProps } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { i18n } from 'translations';
import { useTranslation } from 'translations/hook';

import { FormGridValuationShares } from 'business/fund-manager/fund/pages/fund-share-valuation-creation/fund-share-valuation-creation';
import { computeResidualValue } from 'business/fund-manager/fund/services/get-residual-value-in-share-valuation';
import { FundShareValuationEdited } from 'business/fund-manager/fund/services/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  OperationShareRecapQuery,
  useOperationShareRecapQuery,
} from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { formatAsDate } from 'technical/date';
import { getFieldName } from 'technical/get-field-name';
import { nonNegativeNumberSchema } from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';
import { FormInputText } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Typo } from 'ui/typo';

type FormAdditionInputProps = ComponentProps<typeof FormInputText> & {
  sign: 'minus' | 'plus';
};

const FormAdditionInput = ({ sign, ...rest }: FormAdditionInputProps) => (
  <FormInputText
    {...rest}
    type="number"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {sign === 'plus' ? <Add color="primary" /> : <Remove color="error" />}
        </InputAdornment>
      ),
    }}
  />
);
/**
 * Checks if the residual value is a non-negative number
 */
function checkResidualValue(value: number) {
  try {
    nonNegativeNumberSchema.validateSync(value);
    return true;
  } catch (e) {
    return false;
  }
}

const ResidualValue = ({
  name,
  lastFundShareValuationDate,
}: {
  name: string;
  lastFundShareValuationDate?: string;
}) => {
  const { t } = useTranslation();
  const { currency } = useFund();
  const formData = useWatch({ name });
  const { lastResidualValue } = formData;
  const newResidualValue = computeResidualValue(formData);
  const isValidResidualValue = checkResidualValue(newResidualValue);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typo>
          {t(
            'pages.fundManager.fundShareValuationCreation.form.numericNewResidualValue',
          )}
        </Typo>
        <Typo bold color={!isValidResidualValue ? 'dangerMain' : 'neutral'}>
          {!!newResidualValue || newResidualValue === 0
            ? formatToMonetaryAmount(newResidualValue, {
                currency,
              })
            : '-'}
        </Typo>
      </Stack>
      {!isValidResidualValue && (
        <Typo color="error" size="xs" italic>
          {t('errors.noNegativeResidualValue')}
        </Typo>
      )}
      <Divider light />
      {lastResidualValue ||
      (lastResidualValue === 0 && lastFundShareValuationDate) ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typo size="sm">
                {t(
                  'pages.fundManager.fundShareValuationCreation.form.numericLastResidualValue',
                )}
              </Typo>
              {lastFundShareValuationDate ? (
                <Typo size="sm">
                  {formatAsDate(lastFundShareValuationDate)}
                </Typo>
              ) : undefined}
            </Stack>
            <Typo size="sm">
              {formatToMonetaryAmount(lastResidualValue, {
                currency,
              })}
            </Typo>
          </Stack>
        </>
      ) : undefined}
    </>
  );
};

const OperationRecapValues = ({
  name,
  disabled,
}: {
  name: string;
  disabled: boolean;
}) => {
  const newValuationDate = useWatch({ name: 'date' });
  const { selected, date: previousValuationDate, shareId } = useWatch({ name });
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();

  const { loading } = useOperationShareRecapQuery({
    variables: {
      input: {
        shareId,
        startDate: previousValuationDate,
        endDate: newValuationDate,
      },
    },
    onCompleted: (data: OperationShareRecapQuery) => {
      const share = watch(name);
      // We only want to override the completed form values with the ones from the query, except real and unrealized portfolio which are modifiable.
      setValue(name, {
        managementFees: 0,
        partnershipExpenses: 0,
        totalOffsetsToFeesExpenses: 0,
        interestIncome: 0,
        dividendIncome: 0,
        interestExpense: 0,
        otherIncomeExpense: 0,
        accruedCarriedInterest: 0,
        realizedPortfolio: data.sharePortfolioGains.realizedPortfolio,
        unrealizedPortfolio: data.sharePortfolioGains.unrealizedPortfolio,
        ...share,
        totalDrawn: convertFrom10X8toNumber(data.recap.totalDrawn),
        totalDistributedNonRecallable: convertFrom10X8toNumber(
          data.recap.totalDistributedNonRecallable,
        ),
        totalDistributedRecallable: convertFrom10X8toNumber(
          data.recap.totalDistributedRecallable,
        ),
      });
      setValue('snapshotedAt', new Date(data.recap.snapshotedAt));
    },
    onError: (err) => {
      const errorMsg = i18n.exists(err?.message)
        ? t(err?.message)
        : t(ValidationErrors.GENERIC);
      enqueueSnackbar(errorMsg, {
        variant: VariantTypeEnum.ERROR,
      });
    },
    skip: !newValuationDate || !selected || disabled,
  });

  if (loading) {
    return <QueryStateDisplay loading={loading} />;
  }
  return (
    <>
      <FormAdditionInput
        sign="plus"
        disabled
        label={t(
          'pages.fundManager.fundShareValuationCreation.form.numericTotalDrawn',
        )}
        name={getFieldName('totalDrawn', name)}
      />
      <FormAdditionInput
        sign="minus"
        disabled
        label={t(
          'pages.fundManager.fundShareValuationCreation.form.numericTotalDistributedNonRecallable',
        )}
        name={getFieldName('totalDistributedNonRecallable', name)}
      />
      <FormAdditionInput
        sign="minus"
        disabled
        label={t(
          'pages.fundManager.fundShareValuationCreation.form.numericTotalDistributedRecallable',
        )}
        name={getFieldName('totalDistributedRecallable', name)}
      />
    </>
  );
};

interface FormFundShareValuationProps extends FormGridValuationShares {
  name: string;
  readonly?: boolean;
  fundShareValuation?: FundShareValuationEdited;
}
export const FormFundShareValuation = ({
  name,
  share,
  readonly,
  fundShareValuation,
}: FormFundShareValuationProps) => {
  const { t } = useTranslation();
  const isEdition = !!fundShareValuation;

  const selected =
    useWatch({ name: getFieldName('selected', name) }) || isEdition;
  const disabled = readonly || !selected;
  return (
    <Stack
      spacing={2}
      alignItems="stretch"
      padding={2}
      sx={{ opacity: selected ? 1 : 0.6 }}
    >
      <ResidualValue
        name={name}
        lastFundShareValuationDate={share.lastFundShareValuation?.date}
      />
      <Typo color="primary">
        {t('pages.fundManager.fundShareValuationCreation.form.overPeriod')}
      </Typo>
      <OperationRecapValues name={name} disabled={disabled} />
      <Stack direction="row" spacing={2}>
        <FormAdditionInput
          sign="minus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericManagementFees',
          )}
          name={getFieldName('managementFees', name)}
        />
        <FormAdditionInput
          sign="minus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericPartnershipExpenses',
          )}
          name={getFieldName('partnershipExpenses', name)}
        />
      </Stack>
      <FormAdditionInput
        sign="minus"
        disabled={disabled}
        label={t(
          'pages.fundManager.fundShareValuationCreation.form.numericTotalOffsetsToFeesExpenses',
        )}
        name={getFieldName('totalOffsetsToFeesExpenses', name)}
      />
      <Stack direction="row" spacing={2}>
        <FormAdditionInput
          sign="plus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericInterestIncome',
          )}
          name={getFieldName('interestIncome', name)}
        />
        <FormAdditionInput
          sign="plus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericDividendIncome',
          )}
          name={getFieldName('dividendIncome', name)}
        />
      </Stack>
      <FormAdditionInput
        sign="minus"
        disabled={disabled}
        label={t(
          'pages.fundManager.fundShareValuationCreation.form.numericInterestExpense',
        )}
        name={getFieldName('interestExpense', name)}
      />
      <Stack direction="row" spacing={2}>
        <FormAdditionInput
          sign="plus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericOtherIncomeExpense',
          )}
          name={getFieldName('otherIncomeExpense', name)}
        />
        <FormAdditionInput
          sign="plus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericAccruedCarriedInterest',
          )}
          name={getFieldName('accruedCarriedInterest', name)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormAdditionInput
          sign="plus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericRealizedPortfolio',
          )}
          name={getFieldName('realizedPortfolio', name)}
        />
        <FormAdditionInput
          sign="plus"
          disabled={disabled}
          label={t(
            'pages.fundManager.fundShareValuationCreation.form.numericUnrealizedPortfolio',
          )}
          name={getFieldName('unrealizedPortfolio', name)}
        />
      </Stack>
    </Stack>
  );
};
