import config, { Env } from 'config';
import errorReporting from 'technical/error-reporting';

interface Logger {
  debug: Console['debug'];
  warn: Console['warn'];
  info: Console['info'];
  error: Console['error'];
}

type Level = keyof Logger;

const LevelsPrority: { [k in Level]: number } = {
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
};

const prodLogger: Logger = {
  debug: () => {}, // DO NOTHING
  warn: (message: any, ...data: any[]) => {
    errorReporting.warning(message, data);
  },
  info: (message: any, ...data: any[]) => {
    errorReporting.info(message, data);
  },
  error: (message: any, ...data: any[]) => {
    errorReporting.error(message, data);
  },
};

export class AppLogger implements Logger {
  private logger: Logger;

  private level: Level;

  constructor(logger: Logger, level: Level) {
    this.logger = logger;
    this.level = level;
  }

  private log(level: Level, message: any, ...data: any[]) {
    if (LevelsPrority[level] < LevelsPrority[this.level]) {
      return;
    }
    this.logger[level](message, data);
  }

  debug(message: any, ...data: any[]) {
    this.log('debug', message, data);
  }

  info(message: any, ...data: any[]) {
    this.log('info', message, data);
  }

  warn(message: any, ...data: any[]) {
    this.log('warn', message, data);
  }

  error(message: any, ...data: any[]) {
    this.log('error', message, data);
  }
}

const logger = new AppLogger(
  config.env !== Env.Prod ? console : prodLogger,
  config.logLevel,
);

export default logger;
