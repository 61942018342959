import { useAppContext } from 'business/providers/app-provider';
import {
  ManagementCompanyType,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';

import { useIsLpPlatform } from './use-is-lp-platform';

export const useManagementCompanyTypeGuard = (
  authorizedType: // hasura types
  | Web_Portal_ManagementCompanyType_Enum
    | Web_Portal_ManagementCompanyType_Enum[]
    // custom-server types
    | ManagementCompanyType
    | ManagementCompanyType[],
): boolean => {
  const { user } = useAppContext();
  const isLpPlatform = useIsLpPlatform();

  if (!user?.managementCompany?.type) {
    // if we use this hook on a composant used
    // in both the lpPlatform and the fundManagementPlatform
    // it will thrown an error
    if (isLpPlatform) {
      return false;
    }
    // this hooks can be only used in connected page
    // so we throw an error when is used in another structure
    throw Error('management company must be defined');
  }
  const authorizedTypes =
    typeof authorizedType === 'string' ? [authorizedType] : authorizedType;

  return authorizedTypes.includes(user.managementCompany.type);
};
