import { Settings } from '@mui/icons-material';
import { TFunction, useTranslation } from 'translations/hook';

import { RecapValuesContent } from 'business/fund-manager/operation/components/recap-values-content';
import {
  GetOperationSettingsQuery,
  useGetOperationSettingsQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SummaryBar } from 'ui/summary-bar';

const formatData = (data: GetOperationSettingsQuery, t: TFunction) => [
  {
    value: t(
      'pages.fundManager.operation.creationRecap.settings.shouldEmailLetters',
    ),
    label: data.web_portal_operation_by_pk?.shouldEmailLetters
      ? t('pages.fundManager.operation.creationRecap.settings.automatic')
      : t('pages.fundManager.operation.creationRecap.settings.nonAutomatic'),
  },
];

interface SettingsRecapCardProps {
  operationId: string;
}
export const SettingsRecapCard = ({ operationId }: SettingsRecapCardProps) => {
  const { t } = useTranslation();

  const { data, loading, error } = useGetOperationSettingsQuery({
    variables: { id: operationId },
  });

  return (
    <SummaryBar
      title={t('pages.fundManager.operation.creationRecap.settings.title')}
      icon={<Settings />}
    >
      {error || loading || !data ? (
        <QueryStateDisplay error={error} loading={loading} />
      ) : (
        <RecapValuesContent content={formatData(data, t)} />
      )}
    </SummaryBar>
  );
};
