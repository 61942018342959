import { Divider, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationInvestorCashFlowNavBar } from 'business/fund-manager/operation/components/operation-investor-cashflow-nav-bar';
import { getCashflowColumnDefs } from 'business/fund-manager/operation/services/get-cashflow-column-defs';
import { getCashflowsRows } from 'business/fund-manager/operation/services/get-cashflows-rows';
import { OperationInvestorsNavBarTabEnum } from 'business/fund-manager/operation/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  GetPendingValidationShareLpCashFlowsQueryResult,
  Web_Portal_ManagementCompanyType_Enum,
  useGetPendingValidationShareLpCashFlowsQuery,
  useValidateCashflowMutation,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { Card } from 'ui/card';
import { QueryStateDisplay } from 'ui/query-state-display';

type OperationInvestorCashflowsPendingValidationWithDataProps = {
  refetchTableData: GetPendingValidationShareLpCashFlowsQueryResult['refetch'];
  operationId: string;
  tableData: NonNullable<
    GetPendingValidationShareLpCashFlowsQueryResult['data']
  >;
};

const OperationInvestorCashflowsPendingValidationWithData = ({
  refetchTableData,
  tableData,
}: OperationInvestorCashflowsPendingValidationWithDataProps) => {
  const { t } = useTranslation();

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );
  const { currency } = useFund();

  const [validateCashflowMutation] = useValidateCashflowMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.validateCashflow'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      refetchTableData();
    },
    onError: () => {
      enqueueSnackbar(t('errors.validateCashflow'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    refetchQueries: ['GetPendingValidationShareLpCashFlowCount'],
  });

  const handleValidateCashflow = async (cashflowId: string) => {
    await validateCashflowMutation({
      variables: {
        cashflowId,
      },
    });
  };

  const cashflowRows = getCashflowsRows(
    tableData.pendingValidationShareLpCashFlows,
  );

  const columnDefs = getCashflowColumnDefs({
    t,
    shouldHaveValidationAction: !isAdvisor,
    handleValidation: handleValidateCashflow,
  });

  return (
    <Card>
      <Stack spacing={2}>
        <Stack>
          <Divider />
          <AlternativeTable
            fileName="cashflow-pending-validation"
            rowData={cashflowRows}
            columnDefs={columnDefs}
            domLayout="autoHeight"
            sideBar="filters"
            displaySidebar
            context={{ currency }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export const OperationInvestorCashflowsPendingValidation = () => {
  const { operationId = '', operationShareLpId = '' } = useParams();

  const { data, loading, error, refetch } =
    useGetPendingValidationShareLpCashFlowsQuery({
      variables: {
        operationShareLpId,
      },
      fetchPolicy: 'network-only',
    });

  if (loading || error || data === undefined) {
    return (
      <OperationInvestorCashFlowNavBar
        activeTab={OperationInvestorsNavBarTabEnum.ToValidate}
      >
        <QueryStateDisplay loading={loading} error={error} />
      </OperationInvestorCashFlowNavBar>
    );
  }
  return (
    <OperationInvestorCashFlowNavBar
      activeTab={OperationInvestorsNavBarTabEnum.ToValidate}
    >
      <OperationInvestorCashflowsPendingValidationWithData
        refetchTableData={refetch}
        operationId={operationId}
        tableData={data}
      />
    </OperationInvestorCashFlowNavBar>
  );
};
