import * as yup from 'yup';

import { DefaultDataRankEnum, DefaultPeriodTypeEnum } from 'generated/graphql';

import { createOrUpdateCompanyMetricsTypeFormSchema } from './validation';

export enum MetricsUnitEnum {
  Million = 'm€',
  NinePointNine = '9.9',
  Percent = '%',
  Thousand = 'k€',
}

export interface CompanyMetricsTypeInformation {
  id: string;
  name: string;
  isDeletable: boolean;
  unit: string;
  defaultPeriodType: DefaultPeriodTypeEnum;
  defaultDataRank: DefaultDataRankEnum;
  managementCompanyId: string;
}

export const isCompanyMetricsTypeInformation = (
  obj: any,
): obj is CompanyMetricsTypeInformation => {
  return (
    typeof obj.id === 'string' &&
    typeof obj.name === 'string' &&
    typeof obj.unit === 'string' &&
    typeof obj.managementCompanyId === 'string' &&
    Object.values(DefaultPeriodTypeEnum).includes(obj.defaultPeriodType) &&
    Object.values(DefaultDataRankEnum).includes(obj.defaultDataRank) &&
    typeof obj.isDeletable === 'boolean'
  );
};

export type CreateOrUpdateCompanyMetricsTypeForm = yup.InferType<
  typeof createOrUpdateCompanyMetricsTypeFormSchema
>;

export enum CompanyMetricsTypeFormContextEnum {
  Add = 'add',
  Edit = 'edit',
}
