import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyCreationWizzard } from 'business/fund-manager/portfolio/company/components/company-creation-wizzard';
import { CompanyProfileForm } from 'business/fund-manager/portfolio/company/components/company-profile-form';
import { useCompanyInformationAutocomplete } from 'business/fund-manager/portfolio/company/services/hooks/use-company-information-autocomplete';
import { useCompanyProfileForm } from 'business/fund-manager/portfolio/company/services/hooks/use-company-profile-form';
import { CompanyCreationStepEnum } from 'business/fund-manager/portfolio/company/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { CompanyProfileQuery, useCompanyProfileQuery } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Typo } from 'ui/typo';

interface CompanyCreationContentProps {
  fundId: string;
  companyId?: string;
  company?: NonNullable<CompanyProfileQuery['company']>;
}

const CompanyCreationContent = ({
  fundId,
  companyId,
  company,
}: CompanyCreationContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    onSubmit,
    control,
    loading,
    error,
    onFileUpload,
    existingLogo,
    onExistingLogoDelete,
    setValue,
    saving,
  } = useCompanyProfileForm({
    companyId,
    fundId,
    company,
    onCompleted: (id: string) => {
      navigate(
        FundManagerRoutes.PortfolioFundIdCompanyCreateIdInvestments.replace(
          ':fundId',
          fundId,
        ).replace(':companyId', id),
      );
    },
  });

  const {
    loading: autocompleteLoading,
    disabled,
    fetchAndSetAutocompleteResult,
    error: autocompleteError,
  } = useCompanyInformationAutocomplete({ control, setValue });

  const handleAutocomplete = () => {
    setValue('isAutocomplete', true);
    fetchAndSetAutocompleteResult();
  };

  return (
    <CompanyCreationWizzard
      activeStep={CompanyCreationStepEnum.profile}
      onSubmit={!loading ? onSubmit : undefined}
      previousPath={FundManagerRoutes.PortfolioFundIdCompany.replace(
        ':fundId',
        fundId,
      )}
    >
      <CompanyProfileForm
        control={control}
        error={error || autocompleteError}
        onFileUpload={onFileUpload}
        onExistingLogoDelete={onExistingLogoDelete}
        existingLogo={existingLogo}
        action={
          <Stack
            alignItems="center"
            spacing={1}
            direction="row"
            justifyContent="end"
          >
            <ActionButton
              size="small"
              variant={'primary'}
              disabled={disabled || saving}
              onClick={handleAutocomplete}
              loading={autocompleteLoading}
            >
              {t('common.autocomplete')}
            </ActionButton>
            <Typo color="neutral-light">
              <Tooltip title={t('common.autocomplete-tooltip')}>
                <InfoOutlined fontSize="inherit" />
              </Tooltip>
            </Typo>
          </Stack>
        }
      />
    </CompanyCreationWizzard>
  );
};

export const CompanyCreation = () => {
  const { companyId, fundId = '' } = useParams();
  const { loading, error, data } = useCompanyProfileQuery({
    variables: { id: companyId ?? '' },
    skip: companyId === undefined,
    fetchPolicy: 'network-only',
  });

  if (!companyId) {
    return <CompanyCreationContent fundId={fundId} />;
  }

  if (loading || error || !data?.company) {
    return (
      <CompanyCreationWizzard
        activeStep={CompanyCreationStepEnum.profile}
        previousPath={FundManagerRoutes.PortfolioFundIdCompany.replace(
          ':fundId',
          fundId,
        )}
      >
        <QueryStateDisplay loading={loading} error={error} />
      </CompanyCreationWizzard>
    );
  }

  return (
    <CompanyCreationContent
      companyId={companyId}
      company={data.company}
      fundId={fundId}
    />
  );
};
