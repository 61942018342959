// eslint-disable-next-line import/no-extraneous-dependencies

import { convertStringToBoolean } from 'technical/convert/string-to-boolean';

export enum Env {
  Local = 'local',
  Ci = 'ci',
  Prod = 'production',
  Test = 'test',
}

function retrieveRequiredEnvValue(key: string): string {
  const value = import.meta.env[key];
  if (value === undefined) {
    throw new Error(`Missing env variables : ${key}`);
  }
  return value;
}

function retrieveEnvValueWithDefault(
  key: string,
  defaultValue: string,
): string {
  const value = import.meta.env[key];
  if (value === undefined) {
    return defaultValue;
  }
  return value;
}

function isEnv(value: string): value is Env {
  return (Object.values(Env) as Array<string>).includes(value);
}

const env = retrieveRequiredEnvValue('VITE_ENVIRONMENT');

if (!isEnv(env)) {
  throw new Error('Unrecognized environment');
}

function isLogLevel(
  value: string,
): value is 'debug' | 'info' | 'warn' | 'error' {
  return ['debug', 'info', 'warn', 'error'].includes(value);
}

const logLevel = import.meta.env.VITE_LOG_LEVEL || 'info';

if (!isLogLevel(logLevel)) {
  throw new Error('Unrecognized logLevel');
}

const authMode = retrieveRequiredEnvValue('VITE_AUTHENTICATION_MODE');

function isAuthMode(value: string): value is 'custom' | 'auth0' {
  return ['custom', 'auth0'].includes(value);
}

if (!isAuthMode(authMode)) {
  throw new Error('Unrecognized authMode');
}

const config = {
  env,
  isDev: env === Env.Local,
  logLevel,
  version: retrieveEnvValueWithDefault('VITE_VERSION', 'none'),
  auth0: {
    domain:
      authMode === 'auth0' ? retrieveRequiredEnvValue('VITE_AUTH0_DOMAIN') : '',
    clientID:
      authMode === 'auth0'
        ? retrieveRequiredEnvValue('VITE_AUTH0_CLIENT_ID')
        : '',
    redirectUri:
      authMode === 'auth0'
        ? retrieveRequiredEnvValue('VITE_AUTH0_REDIRECT_URI')
        : '',
  },
  graphqlUri: retrieveRequiredEnvValue('VITE_GRAPHQL_URI'),
  graphqlWsUri: retrieveRequiredEnvValue('VITE_GRAPHQL_WS_URI'),
  analytics: {
    enabled: retrieveRequiredEnvValue('VITE_ANALYTICS_ENABLED') === 'true',
    gtm: retrieveRequiredEnvValue('VITE_GOOGLE_TAG_MANAGER_CONTAINER_ID'),
  },
  datadog: {
    enabled:
      import.meta.env.VITE_DATADOG_APP_ID &&
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    applicationId: retrieveRequiredEnvValue('VITE_DATADOG_APP_ID'),
    clientToken: retrieveRequiredEnvValue('VITE_DATADOG_CLIENT_TOKEN'),
    // Use default value that disable the following options for now. We will need to either add better cookie policy handling before handling it, or only use it for debug in test or dev
    sessionSampleRate: parseInt(
      retrieveEnvValueWithDefault('VITE_DATADOG_SAMPLE_RATE', '0'),
    ),
    sessionReplaySampleRate: parseInt(
      retrieveEnvValueWithDefault('VITE_DATADOG_REPLAY_SAMPLE_RATE', '0'),
    ),
    trackUserInteractions: convertStringToBoolean(
      import.meta.env.VITE_DATADOG_TRACK_USER_INTERACTION,
    ),
    trackResources: convertStringToBoolean(
      import.meta.env.VITE_DATADOG_TRACK_RESOURCES,
    ),
    trackLongTasks: convertStringToBoolean(
      import.meta.env.VITE_DATADOG_TRACK_LONG_TASK,
    ),
    enableSessionReplay: convertStringToBoolean(
      import.meta.env.VITE_DATADOG_ENABLE_SESSION_REPLAY,
    ),
  },
  // muiXPremiumLicenseKey: retrieveRequiredEnvValue('MUI_X_PREMIUM_LICENSE_KEY'),
  muiXPremiumLicenseKey: retrieveRequiredEnvValue(
    'VITE_MUI_X_PREMIUM_LICENSE_KEY',
  ),
  agGridProLicenseKey: retrieveRequiredEnvValue('VITE_AG_GRID_LICENSE_KEY'),
};

export default config;
