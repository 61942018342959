import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'translations/hook';

import styles from './index.module.scss';

export interface WarningDeclinedStepProps {
  declineComment: string;
}
export const WarningDeclinedStep = ({
  declineComment,
}: WarningDeclinedStepProps) => {
  const { t } = useTranslation();
  return (
    <Alert severity="warning" className={styles.warningBox}>
      <AlertTitle className={styles.title}>
        {t('pages.fundManager.lpCreation.declineReason.title')}
      </AlertTitle>
      {declineComment}
    </Alert>
  );
};
