export const getLocaleDateToISOString = (date: string): string => {
  const utcDate = new Date(date);

  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
  );

  // '2023-03-30T22:00:00.000Z' format that varies with location
  return localDate.toISOString();
};

// calculate the quarter number for a given date
export function getQuarter(date: string): number {
  const d = new Date(date);
  const month = d.getMonth() + 1; // Months are 0-indexed
  return Math.ceil(month / 3);
}

export function getQuartedDate(date: string): string {
  // get current location time
  const utcDate = new Date();
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
  );

  //  get date info
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const quarter = getQuarter(date);

  // set localTime to 00:00:00
  localDate.setUTCHours(0);
  localDate.setUTCMinutes(0);
  localDate.setUTCMilliseconds(0);

  // return ISO date of the last day of the identified quarter
  if (quarter === 1) {
    localDate.setUTCFullYear(year, 2, 31); // 31/03/YYYY
    return localDate.toISOString();
  } else if (quarter === 2) {
    localDate.setUTCFullYear(year, 5, 30); // 30/06/YYYY
    return localDate.toISOString();
  } else if (quarter === 3) {
    localDate.setUTCFullYear(year, 8, 30); // 30/09/YYYY
    return localDate.toISOString();
  } else {
    localDate.setUTCFullYear(year, 11, 31); // 31/12/YYYY
    return localDate.toISOString();
  }
}
