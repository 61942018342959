import { PieChart } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { computeOperationStatusRecap } from 'business/fund-manager/operation/services/compute-operation-status-recap';
import { OperationStatusRecap } from 'business/fund-manager/operation/services/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import {
  useGetOperationStatusRecapQuery,
  Web_Portal_OperationStatus_Enum,
} from 'generated/graphql';
import {
  MainSecondaryItem,
  MainSecondaryItemProps,
} from 'ui/main-secondary-item';
import { StatusCardAmount } from 'ui/status-card-amount/status-card-amount';
import { SummaryBar } from 'ui/summary-bar';

const SummaryBarContent = ({
  status,
  totalCalled,
  premium,
  totalPaid,
  remain,
}: OperationStatusRecap) => {
  const { currency } = useFund();

  const { t } = useTranslation();
  const items: MainSecondaryItemProps[] = [
    {
      main: (
        <StatusCardAmount value={Math.abs(totalCalled)} currency={currency} />
      ),
      secondary: (
        <Stack>
          <span>
            {t(
              totalCalled > 0
                ? 'pages.fundManager.operation.overview.cards.status.totalCalled'
                : 'pages.fundManager.operation.overview.cards.status.totalDistributed',
            )}
          </span>
          <small>
            {t(
              'pages.fundManager.operation.overview.cards.status.withoutPremium',
            )}
          </small>
        </Stack>
      ),
    },
    {
      main: <StatusCardAmount value={premium} currency={currency} />,
      secondary: t('pages.fundManager.operation.overview.cards.status.premium'),
    },
    {
      main: (
        <StatusCardAmount
          value={totalPaid}
          currency={currency}
          color="primary"
        />
      ),
      mainColor: 'primary',
      secondary: t(
        'pages.fundManager.operation.overview.cards.status.totalPaid',
      ),
    },
    {
      mainColor: 'secondary',
      main: (
        <StatusCardAmount
          value={remain}
          currency={currency}
          color="secondary"
        />
      ),
      secondary: t('pages.fundManager.operation.overview.cards.status.remain'),
    },
  ];

  return (
    <Stack
      direction="row"
      alignItems="stretch"
      spacing={3}
      justifyContent="space-between"
    >
      <Stack alignItems="center" alignSelf="center">
        <Chip
          label={t('pages.fundManager.operation.table.status.cell', {
            context: status,
          })}
          color={
            status === Web_Portal_OperationStatus_Enum.InProgress
              ? 'primary'
              : undefined
          }
          size="small"
        />
      </Stack>

      {items.map((item, i) => (
        <MainSecondaryItem {...item} key={i} />
      ))}
    </Stack>
  );
};

export const OverviewStatusCard = ({
  operationId,
}: {
  operationId: string;
}) => {
  const { t } = useTranslation();

  const { data, loading } = useGetOperationStatusRecapQuery({
    variables: {
      id: operationId,
    },
  });

  const statusRecap = computeOperationStatusRecap(data);

  return (
    <SummaryBar
      icon={<PieChart />}
      iconColor="secondary"
      title={t('pages.fundManager.operation.overview.cards.status.title')}
      loading={loading}
    >
      {statusRecap ? <SummaryBarContent {...statusRecap} /> : null}
    </SummaryBar>
  );
};
