import { Stack } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyTransactionCreationInput } from 'business/fund-manager/portfolio/company/services/types';
import { WebCompanyInstrumentTypeEnum } from 'generated/graphql';
import { FormInputText } from 'ui/form';
import { SelectInput } from 'ui/form/select-input';

interface Props {
  selectedCompanyName?: string;
}

export const CompanyTransactionCreateInstrumentForm = ({
  selectedCompanyName,
}: Props) => {
  const { control, setValue, resetField } =
    useFormContext<CompanyTransactionCreationInput>();
  const { t } = useTranslation();

  const watchedType = useWatch({
    control,
    name: 'instrument.type',
  });

  const buildNewInstrumentIdentifier = ({
    type,
    companyName,
  }: {
    type: string;
    companyName: string;
  }) => {
    return `${companyName} - ${type}`;
  };

  const setSecurityValue = ({
    type,
    identifier,
  }: {
    type: WebCompanyInstrumentTypeEnum | undefined;
    identifier: string | null;
  }) => {
    setValue('instrument.name', `${type} - ${identifier}`);
  };

  const handleTypeChange = (value: string | null) => {
    // Since 'instrument.type' validation accept only values contains WebCompanyInstrumentTypeEnum.
    // For reset the field value, we need to set 'instrument.type' to an empty string as a WebCompanyInstrumentTypeEnum.
    setValue('instrument.type', (value ?? '') as WebCompanyInstrumentTypeEnum);
    resetField('instrument.identifier');
    resetField('instrument.name');
    if (!value || !selectedCompanyName) {
      return;
    }

    const newIdentifier = buildNewInstrumentIdentifier({
      type: value,
      companyName: selectedCompanyName,
    });

    setSecurityValue({
      type: value as WebCompanyInstrumentTypeEnum,
      identifier: newIdentifier,
    });
    setValue('instrument.identifier', newIdentifier);
  };

  const handleIdentifierChange = (value: string | null) => {
    if (!value || !selectedCompanyName) {
      return;
    }

    setSecurityValue({ type: watchedType, identifier: value });
    setValue('instrument.identifier', value);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <SelectInput
          name="instrument.type"
          label={t(
            'pages.fundManager.portfolio.inventory.instrumentType.title',
          )}
          dataTestId="select-company-instrument-type"
          control={control}
          options={Object.values(WebCompanyInstrumentTypeEnum).map((id) => ({
            value: t(
              `pages.fundManager.portfolio.inventory.instrumentType.${id}`,
            ),
            id,
          }))}
          onChange={(value: string | null) => handleTypeChange(value)}
          required
          fullWidth
        />
        <FormInputText
          name="instrument.identifier"
          label={t(
            'pages.fundManager.portfolio.companyTransaction.form.identifier',
          )}
          control={control}
          required
          fullWidth
          onChange={(e) => handleIdentifierChange(e.target.value)}
        />
      </Stack>
      <FormInputText
        name="instrument.name"
        label={t(
          'pages.fundManager.portfolio.companyTransaction.form.security',
        )}
        control={control}
        required
        disabled
        fullWidth
      />
    </>
  );
};
