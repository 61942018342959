import { TFunction, useTranslation } from 'translations/hook';

import { RecapValuesContent } from 'business/fund-manager/operation/components/recap-values-content';
import {
  GetOperationGeneralInformationQuery,
  useGetOperationGeneralInformationQuery,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { DocumentationMenuIcon } from 'ui/icons';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SummaryBar } from 'ui/summary-bar';

const formatData = (
  data: GetOperationGeneralInformationQuery,
  t: TFunction,
) => [
  {
    value: t(
      'pages.fundManager.operation.creationRecap.generalInformation.fund',
    ),
    label: data.web_portal_operation_by_pk?.fund?.name ?? '',
  },
  {
    value: t(
      'pages.fundManager.operation.creationRecap.generalInformation.operationIdentifier',
    ),
    label: data.web_portal_operation_by_pk?.identifier ?? '',
  },
  {
    value: t(
      'pages.fundManager.operation.creationRecap.generalInformation.dueDate',
    ),
    label: data.web_portal_operation_by_pk?.date
      ? formatAsDate(data.web_portal_operation_by_pk.date)
      : '',
  },
];

interface GeneralInformationRecapCardProps {
  operationId: string;
}
export const GeneralInformationRecapCard = ({
  operationId,
}: GeneralInformationRecapCardProps) => {
  const { t } = useTranslation();

  const { data, loading, error } = useGetOperationGeneralInformationQuery({
    variables: { id: operationId },
  });

  return (
    <SummaryBar
      title={t(
        'pages.fundManager.operation.creationRecap.generalInformation.title',
      )}
      icon={<DocumentationMenuIcon />}
      iconColor="primary"
    >
      {error || loading || !data ? (
        <QueryStateDisplay error={error} loading={loading} />
      ) : (
        <RecapValuesContent content={formatData(data, t)} />
      )}
    </SummaryBar>
  );
};
