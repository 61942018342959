import { Outlet, RouteObject } from 'react-router';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { AdvisorFundDocumentType } from 'business/fund-manager/settings/advisor-document-type/pages/fund-document-type/fund-document-type';
import { LpDocumentType } from 'business/fund-manager/settings/advisor-document-type/pages/lp-document-type/lp-document-type';
import { CompanyMetricsTypeList } from 'business/fund-manager/settings/company-metrics-type/components/company-metrics-type-list';
import { CreateManagementCompany } from 'business/fund-manager/settings/management-company/components/create-management-company';
import { ListAdvisorManagementCompany } from 'business/fund-manager/settings/management-company/components/list-management-company';
import { CompanyManagerUpdate } from 'business/fund-manager/settings/management-company/components/update-management-company';
import { SettingsIndex } from 'business/fund-manager/settings/shared/components/settings-index';
import { SettingsPage } from 'business/fund-manager/settings/shared/components/settings-page';
import { FundManagerEmailTemplateCreation } from 'business/fund-manager/settings/template/pages/email-template-creation';
import { FundManagerEmailTemplateUpdate } from 'business/fund-manager/settings/template/pages/email-template-update';
import { PublicationData } from 'business/fund-manager/settings/template/pages/publication-data';
import { CompanyManagementTemplates } from 'business/fund-manager/settings/template/pages/template-list';
import { UploadDocumentTemplate } from 'business/fund-manager/settings/template/pages/upload-document-template/upload-document-template';
import { ManagementCompanyType } from 'generated/graphql';

export const settingsRoutes: RouteObject[] = [
  {
    path: FundManagerRoutes.Settings,
    element: (
      <SettingsPage>
        <Outlet />
      </SettingsPage>
    ),
    children: [
      {
        element: <SettingsIndex />,
        index: true,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorId,
        element: <SettingsIndex />,
      },
      {
        path: FundManagerRoutes.SettingsProfile,
        element: <CompanyManagerUpdate />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdProfile,
        element: <CompanyManagerUpdate />,
      },
      {
        path: FundManagerRoutes.SettingsTemplateList,
        element: <CompanyManagementTemplates />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdTemplateList,
        element: <CompanyManagementTemplates />,
      },
      {
        path: FundManagerRoutes.SettingsTemplateListIdEmailTemplate,
        element: <FundManagerEmailTemplateCreation />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdTemplateListIdEmailTemplate,
        element: <FundManagerEmailTemplateCreation />,
      },
      {
        path: FundManagerRoutes.SettingsTemplateListIdEmailTemplateId,
        element: <FundManagerEmailTemplateUpdate />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdTemplateListIdEmailTemplateId,
        element: <FundManagerEmailTemplateUpdate />,
      },
      {
        path: FundManagerRoutes.SettingsPublicationData,
        element: <PublicationData />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdPublicationData,
        element: <PublicationData />,
      },
      {
        path: FundManagerRoutes.SettingsTemplateListIdUploadDocX,
        element: <UploadDocumentTemplate />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdTemplateListIdUploadDocX,
        element: <UploadDocumentTemplate />,
      },
      {
        path: FundManagerRoutes.SettingsMetrics,
        element: <CompanyMetricsTypeList />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdMetrics,
        element: <CompanyMetricsTypeList />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisor,
        element: <ListAdvisorManagementCompany />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorCreate,
        element: (
          <CreateManagementCompany
            defaultType={ManagementCompanyType.Advisor}
          />
        ),
      },
      {
        path: FundManagerRoutes.SettingsLpDocumentationType,
        element: <LpDocumentType />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdLpDocumentationType,
        element: <LpDocumentType />,
      },
      {
        path: FundManagerRoutes.SettingsFundDocumentationType,
        element: <AdvisorFundDocumentType />,
      },
      {
        path: FundManagerRoutes.SettingsAdvisorIdFundDocumentationType,
        element: <AdvisorFundDocumentType />,
      },
    ],
  },
];
