import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { OnboardingSubscriptionAmount } from 'business/lp-platform/onboarding-subscription/pages/onboarding-subscription-completion/onboarding-subscription-completion.types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import {
  CurrencyEnum,
  OnboardingSubscriptionTypeEnum,
} from 'generated/graphql';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import { ActionButton } from 'ui/action-button';
import { Card } from 'ui/card';
import { FormInputRange, FormSection } from 'ui/form';
import { FormBankDetailsPart } from 'ui/form-bank-details-part/form-bank-details';
import { Typo } from 'ui/typo';
import { WarningDeclinedStep } from 'ui/warning-declined-step/warning-declined-step';

type FormOnboardingSubscriptionCompleteProps = OnboardingSubscriptionAmount & {
  currency: CurrencyEnum;
  fundName: string;
  onSubmit: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  declineComment?: string;
};

export const FormOnboardingSubscriptionComplete = ({
  fundName,
  currency,
  onSubmit,
  onCancel,
  isSubmitting,
  declineComment,
  ...rest
}: FormOnboardingSubscriptionCompleteProps) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <form onSubmit={onSubmit}>
      <Stack alignItems="center">
        <Card>
          <Stack
            sx={{ bgcolor: palette.backgroundCard }}
            padding={2}
            marginBottom={1}
            alignItems="center"
          >
            <Typo>{t('pages.onboardingSubscription.form.formTitle')}</Typo>
          </Stack>
          <Stack justifyContent="center" spacing={4} margin="auto" padding={2}>
            {declineComment && (
              <WarningDeclinedStep declineComment={declineComment} />
            )}
            <Stack alignItems="center" marginBottom={1}>
              <Typo color="neutral-light">
                {t('pages.onboardingSubscription.form.description', {
                  fund: fundName,
                })}
              </Typo>
            </Stack>
            <Stack alignItems="center">
              {rest.type === OnboardingSubscriptionTypeEnum.Fixed ? (
                <Typo size="2xl" color="primaryMain">
                  {formatToMonetaryAmount(rest.amount, { currency })}
                </Typo>
              ) : (
                <FormInputRange
                  name="amount"
                  required
                  label={t('pages.onboardingSubscription.form.amount', {
                    currency,
                  })}
                  min={rest.amountMin}
                  max={rest.amountMax}
                  valueFormatter={(value: number) =>
                    formatToMonetaryAmount(value, { currency })
                  }
                />
              )}
            </Stack>
            <Divider />
            <FormSection
              title={t('pages.onboardingSubscription.form.bankDetails')}
              lastOne
            >
              <Typo className="pb-4" color="neutral-light">
                {t('pages.onboardingSubscription.form.bankDetailsDescription')}
              </Typo>
              <FormBankDetailsPart name="bankDetails" />
            </FormSection>
            <Stack direction="row">
              <Stack flexGrow={1} alignItems="start">
                <ActionButton
                  variant="secondary"
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  {t('common.actions.cancel')}
                </ActionButton>
              </Stack>
              <Stack flexGrow={1} justifyContent="end" alignItems="end">
                <ActionButton
                  type="submit"
                  variant="submit"
                  loading={isSubmitting}
                >
                  {t('common.actions.approve')}
                </ActionButton>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </form>
  );
};
