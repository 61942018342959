import { ComponentProps } from 'react';
import { Outlet, RouteObject } from 'react-router';

import { LpCreationModuleNavigationProvider } from './providers/lp-creation-module-navigation-provider';
import { routes as lpCreationRoutes } from './router/routes';

const applyBasename = (
  basename: string,
  routes: RouteObject[],
): RouteObject[] =>
  routes.map(
    ({ path, children, ...rest }: RouteObject): RouteObject => ({
      ...rest,
      path: `${basename}${path}`,
      index: false,
      children: children ? applyBasename(basename, children) : undefined,
    }),
  );

export const getLpCreationRoutes = (
  props: Omit<
    ComponentProps<typeof LpCreationModuleNavigationProvider>,
    'children'
  >,
) => [
  {
    path: props.basename,
    element: (
      <LpCreationModuleNavigationProvider {...props}>
        <Outlet />
      </LpCreationModuleNavigationProvider>
    ),
    children: applyBasename(props.basename, lpCreationRoutes),
  },
];
