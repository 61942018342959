import { WarningAmber } from '@mui/icons-material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { ContactWithId } from 'business/shared/services/contact/types';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import { CheckBox } from 'ui/check-box';
import { createColumnHeader } from 'ui/column-header-with-sub-header';
import { InlineIconAndText } from 'ui/inline-icon-and-text';
import { TextWithOptionalParenthesisText } from 'ui/text-with-optional-parenthesis-text';

import { getLpContactsBooleanFields } from './constants';
import { UpdateLpContact } from './types';

const DEFAULT_WARNING_ICON = <WarningAmber color="secondary" />;

export const getEmailingPreferencesColumnDefinition = (
  t: TFunction,
  onChange: (params: UpdateLpContact) => void,
  countByKey: {
    [key: string]: number;
  },
  lpId: string,
): ColDef<ContactWithId>[] => {
  const columns: ColDef<ContactWithId>[] = [
    {
      headerName: t('pages.fundManager.lps.table.contact'),
      width: 300,
      autoHeaderHeight: true,
      cellRenderer: ({ data }: ICellRendererParams<ContactWithId>) => {
        const fullName = data ? getPersonFullName(data) : '';
        return data?.isLegalRepresentative ? (
          <TextWithOptionalParenthesisText
            main={fullName}
            secondary={t(
              'pages.fundManager.lps.table.legalRepresentativeAbbreviation',
            )}
          />
        ) : (
          <TextWithOptionalParenthesisText main={fullName} />
        );
      },
    },
  ];

  const lpContactsBooleanFields = getLpContactsBooleanFields();

  lpContactsBooleanFields.forEach((fieldName) =>
    columns.push({
      autoHeaderHeight: true,
      headerName: t(`pages.fundManager.lps.table.${fieldName}`),
      sortable: false,
      width: 200,
      type: AgGridColumnTypesEnum.INTERACTION,
      headerComponent: () => {
        const count = countByKey[fieldName];
        return createColumnHeader({
          headerName: t(`pages.fundManager.lps.table.${fieldName}`),
          subHeaderName: count > 0 ? count.toString() : '',
          subHeaderElement:
            count === 0 ? (
              <InlineIconAndText
                text={t(`pages.fundManager.lps.table.noRecipient`)}
                icon={DEFAULT_WARNING_ICON}
                color="secondary"
              />
            ) : null,
        });
      },
      cellRenderer: ({ data }: ICellRendererParams<ContactWithId>) => {
        if (!data) {
          return null;
        }
        return (
          <CheckBox
            field={fieldName}
            checked={!!data[fieldName]}
            onChange={() => {
              onChange({
                lpId,
                contactId: data.id,
                [fieldName]: !data[fieldName],
              });
            }}
          />
        );
      },
    }),
  );

  return columns;
};
