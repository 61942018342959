import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ComponentProps } from 'react';
import { TFunction } from 'translations/hook';

import { RejectOnboardingSubscriptionButton } from 'business/fund-manager/onboarding-subscription/components/onboarding-subscription-page/delete-onboarding-subscription-button';
import { OnboardingSubscriptionDetailButton } from 'business/fund-manager/onboarding-subscription/components/onboarding-subscription-page/onboarding-subscription-detail-button';
import { OnboardingSubscriptionRefetch } from 'business/fund-manager/onboarding-subscription/pages/onboarding-subscription-list/onboarding-subscription-list';
import { WebDocumentDownloadButton } from 'business/fund-manager/shared/components/web-download-button';
import { OnboardingSubscriptionTypeEnum } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { OnboardingSubscriptionAmount } from 'ui/onboarding-subscription-amount/onboarding-subscription-amount';

import { FormattedOnboardingSubscription } from './format-onboarding-subscriptions';

type CellParams = ICellRendererParams<FormattedOnboardingSubscription, string>;
type ColDefinition = ColDef<FormattedOnboardingSubscription>;
export type OnSeeDetails = (onboardingSubscriptionId: string) => void;

export const getOnboardingSubscriptionColumnDefinition = ({
  t,
  onSeeDetails,
  shouldSeeContract,
  shouldSeeReject,
  refetch,
}: {
  t: TFunction;
  onSeeDetails?: OnSeeDetails;
  shouldSeeContract: boolean;
  shouldSeeReject: boolean;
  refetch: OnboardingSubscriptionRefetch;
}): ColDefinition[] => {
  const columns: ColDefinition[] = [
    {
      field: 'fundName',
      headerName: t('pages.onboardingSubscription.table.fundName'),
    },
    {
      field: 'subscribingEntityName',
      headerName: t('pages.onboardingSubscription.table.subscribingEntityName'),
    },
    {
      field: 'email',
      headerName: t('pages.onboardingSubscription.table.email'),
    },
    {
      field: 'type',
      headerName: t('pages.onboardingSubscription.table.type'),
    },
    {
      type: 'interaction',
      headerName: t('pages.onboardingSubscription.table.amount'),
      cellRenderer: ({ data }: CellParams) => {
        if (!data) {
          return null;
        }
        const props: ComponentProps<typeof OnboardingSubscriptionAmount> =
          data.type === OnboardingSubscriptionTypeEnum.Fixed ||
          !!data.numericAmount
            ? {
                type: OnboardingSubscriptionTypeEnum.Fixed,
                numericAmount: data.numericAmount ?? 0,
              }
            : {
                type: OnboardingSubscriptionTypeEnum.Range,
                numericAmountMin: data.numericAmountMin ?? 0,
                numericAmountMax: data.numericAmountMax ?? 0,
              };

        return <OnboardingSubscriptionAmount {...props} />;
      },
    },
    {
      field: 'daysSinceLastInvite',
      headerName: t('pages.onboardingSubscription.table.daysSinceLastInvite'),
    },
    {
      field: 'statusLabel',
      headerName: t('pages.onboardingSubscription.table.status'),
    },
    ...((onSeeDetails
      ? [
          {
            maxWidth: 120,
            type: AgGridColumnTypesEnum.INTERACTION,
            cellRenderer: ({ data }: CellParams) => {
              return (
                <OnboardingSubscriptionDetailButton
                  data={data}
                  onSeeDetails={onSeeDetails}
                />
              );
            },
          },
        ]
      : []) satisfies ColDefinition[]),
    ...(shouldSeeContract
      ? [
          {
            headerName: t('pages.onboardingSubscription.table.contract'),
            flex: 1,
            type: AgGridColumnTypesEnum.INTERACTION,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
            },
            cellRenderer: ({ data }: CellParams) => {
              if (!data?.signedDocumentId) {
                return null;
              }
              return (
                <Stack direction="row" spacing={1}>
                  <WebDocumentDownloadButton
                    documentIds={[data?.signedDocumentId]}
                  />
                </Stack>
              );
            },
          },
        ]
      : []),
    ...(shouldSeeReject
      ? [
          {
            type: AgGridColumnTypesEnum.INTERACTION,
            maxWidth: 100,
            cellRenderer: ({ data }: CellParams) => (
              <RejectOnboardingSubscriptionButton
                data={data}
                refetch={refetch}
              />
            ),
          },
        ]
      : []),
  ];

  return columns;
};
