import { Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyInventoryTab } from 'business/fund-manager/portfolio/company/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { LinkTab } from 'ui/link-tab';

interface Props {
  activeTab: CompanyInventoryTab;
}

export const CompanyInventoryNavBar = ({ activeTab }: Props) => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();
  return (
    <Stack>
      <Tabs value={activeTab}>
        <LinkTab
          label={t('pages.fundManager.portfolio.inventory.synthesis')}
          to={FundManagerRoutes.PortfolioFundIdInventory.replace(
            ':fundId',
            fundId,
          )}
        />
        <LinkTab
          label={t('pages.fundManager.portfolio.inventory.inventoryDetails')}
          to={FundManagerRoutes.PortfolioFundIdInventoryDetails.replace(
            ':fundId',
            fundId,
          )}
        />
      </Tabs>
    </Stack>
  );
};
