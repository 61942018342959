import { MouseEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { useWebDocumentDownloadUrlLazyQuery } from 'generated/graphql';
import { DownloadButton } from 'ui/download-button';
import Loader from 'ui/loader';

interface Props {
  documentIds: string[];
  disabled?: boolean;
  loading?: boolean;
}

export const WebDocumentDownloadButton = ({
  documentIds,
  disabled,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const [getUrl] = useWebDocumentDownloadUrlLazyQuery({
    variables: { input: { ids: documentIds } },
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (documentIds.length) {
      getUrl().then(({ data }) => {
        if (data?.documentDownloadUrl.url) {
          window.open(data.documentDownloadUrl.url);
        }
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <DownloadButton
      onClick={handleClick}
      disabled={disabled}
      label={t('pages.document.table.downloadButton')}
    />
  );
};
