import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { getOperationValue } from 'business/lp-platform/financial-flows/services/cells/get-operation-value';
import { OperationType } from 'business/lp-platform/financial-flows/types';
import { GetOperationsQuery } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { numericComparisonOperators } from 'technical/filter/operators';
import { concatWithSpaceBetween } from 'technical/string/concat';
import { createColumnHeader } from 'ui/column-header-with-sub-header';

export const distributedRecallableColumn = (
  t: TFunction,
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'recallableDistribution',
    type: 'number',
    groupable: false,
    filterOperators: numericComparisonOperators,
    headerName: concatWithSpaceBetween([
      t('pages.financialFlows.table.distributedRecallable.headerName'),
      t('pages.financialFlows.table.distributedRecallable.subHeaderName'),
    ]),
    align: 'right',
    renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
      const { aggregationFunctionName } = aggregationRule || {};
      return createColumnHeader({
        headerName: t(
          'pages.financialFlows.table.distributedRecallable.headerName',
        ),
        subHeaderName: t(
          t('pages.financialFlows.table.distributedRecallable.subHeaderName'),
        ),
        aggregationFunctionName,
      });
    },
    valueFormatter: ({ value }) =>
      formatToMonetaryAmount(convertFrom10X8toNumber(value ?? 0)),
    valueGetter: ({ row: { type, numericAmount } }) =>
      getOperationValue(
        type,
        numericAmount,
        OperationType.RecallableDistribution,
      ),
  };
};
