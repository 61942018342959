import { Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Value {
  value: string | number | ReactNode;
  label: string | number;
}

interface Props {
  content: Value[];
}

export const RecapValuesContent = ({ content }: Props) => {
  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      {content.map(({ value, label }, i) => (
        <Grid item xs={3} key={`recap-value-${i}`}>
          <Stack>
            <Typography variant="h6">{value}</Typography>
            <Typography variant="body2">{label}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};
