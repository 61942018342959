import { Stack } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';

import { ModalHeader } from 'ui/custom-modal/header';

type FormModalContainerProps = PropsWithChildren<
  ComponentProps<typeof ModalHeader> & {
    onSubmit?: () => void;
    isSubmitting?: boolean;
    disableSubmit?: boolean;
  }
>;

export const FormModalContainer = ({
  children,
  onSubmit,
  disableSubmit = false,
  ...rest
}: FormModalContainerProps) => {
  return (
    <form onSubmit={onSubmit}>
      <ModalHeader
        {...rest}
        canSubmit={!!onSubmit}
        disableSubmit={disableSubmit}
      />

      <Stack padding={3} spacing={2}>
        {children}
      </Stack>
    </form>
  );
};
