import { Circle } from '@mui/icons-material';
import { Stack, SvgIconProps, Typography } from '@mui/material';

import styles from './index.module.scss';

interface Props extends SvgIconProps {
  legend: string;
}

const LegendItem = ({ legend, ...props }: Props) => {
  return (
    <Stack direction={'row'} style={{ alignItems: 'center' }} spacing={0.5}>
      <Circle {...props} className={styles.circle} />
      <Typography variant="caption">{legend}</Typography>
    </Stack>
  );
};

export default LegendItem;
