import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const CheckmarkRoundedIcon = ({ primaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM6 12.071L10.2426 16.3137L17.3137 9.24262L15.8995 7.8284L10.2426 13.4853L7.41421 10.6568L6 12.071Z"
        fill={primaryColor}
      />
    </SvgIcon>
  );
};
