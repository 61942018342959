import { Stack } from '@mui/material';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import SharedRoutes from 'business/shared/router/routes';
import { AuthAppLogo } from 'business/user/components/auth-logo';
import { PrivacyPolicy } from 'business/user/components/privacy-policy';
import { ActionButton } from 'ui/action-button';
import { Card } from 'ui/card';
import { InputError } from 'ui/form/input-error';
import Link from 'ui/link';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface Props extends PropsWithChildren {
  submitButtonLabel: string;
  title: string;
  description?: string;
  authError?: { key: string; context: string | null };
  className?: string;
  submitting?: boolean;
}

export function ResetPasswordCard({
  submitButtonLabel,
  title,
  description,
  authError,
  className,
  children,
  submitting,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card className={classnames(styles.resetPasswordCard, className)}>
      <Stack spacing={4} className={styles.card}>
        <AuthAppLogo />
        <Stack spacing={4}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <Typo size="xl">{title}</Typo>
              {description ? <Typo size="sm">{description}</Typo> : null}
            </Stack>
            {children}

            {authError ? (
              <InputError
                error={
                  authError.context !== null
                    ? t(authError.key, { context: authError.context })
                    : undefined
                }
              />
            ) : null}

            <ActionButton
              type="submit"
              variant="submit"
              data-test-id="submit-button"
              loading={submitting}
            >
              {submitButtonLabel}
            </ActionButton>
            <Link to={SharedRoutes.SignIn}>{t('common.back')}</Link>
          </Stack>

          <PrivacyPolicy />
        </Stack>
      </Stack>
    </Card>
  );
}
