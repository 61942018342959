import { useWebDocumentDownloadUrlLazyQuery } from 'generated/graphql';

export const useOnFundManagerDocumentDownload = () => {
  const [getUrl] = useWebDocumentDownloadUrlLazyQuery();

  return (documentId: string) =>
    getUrl({
      variables: { input: { ids: [documentId] } },
    }).then(({ data }) => data?.documentDownloadUrl.url ?? undefined);
};
