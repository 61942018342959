import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { ModalHeader } from 'ui/custom-modal/header';

interface ConfirmationModalProps extends PropsWithChildren {
  title: string;
  confirmationLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirming?: boolean;
}

export const ConfirmationModal = ({
  title,
  children,
  onCancel,
  onConfirm,
  confirming,
  confirmationLabel,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      open
      handleClose={onCancel}
      component={
        <>
          <ModalHeader title={title} onClose={onCancel} />

          <Stack padding={3} spacing={3}>
            {children}
            <Stack alignItems="center">
              <ActionButton
                variant="submit"
                onClick={onConfirm}
                loading={confirming}
              >
                {confirmationLabel ?? t('common.actions.confirm')}
              </ActionButton>
            </Stack>
          </Stack>
        </>
      }
    />
  );
};
