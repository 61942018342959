import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { SelectInput } from 'ui/form/select-input';
import { Option } from 'ui/types';

const headcountValues: string[] = [
  'NN',
  '0',
  '1',
  '2',
  '3',
  '11',
  '12',
  '21',
  '22',
  '31',
  '32',
  '41',
  '42',
  '51',
  '52',
  '53',
];

type SelectHeadcountInputProps = Omit<
  ComponentProps<typeof SelectInput>,
  'options' | 'onChange' | 'label' | 'placeholder'
>;

export const SelectHeadcountInput = (props: SelectHeadcountInputProps) => {
  const { t } = useTranslation();

  const options: Option[] = headcountValues.map((id) => ({
    id,
    value: t(`pages.fundManager.lp.headcount.values.${id}`),
  }));

  return (
    <SelectInput
      {...props}
      label={t('pages.fundManager.lp.headcount.label')}
      placeholder={t('pages.fundManager.lp.headcount.placeholder')}
      options={options}
    />
  );
};
