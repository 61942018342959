import { GridColDef } from '@mui/x-data-grid-premium';
import { format, isBefore } from 'date-fns';
import { TFunction } from 'translations/hook';

import { CompanyName } from 'business/lp-platform/portfolio/components/company-name';
import { Metrics, Statuses } from 'business/lp-platform/portfolio/enum';
import { GetCompaniesQuery } from 'generated/graphql';
import { insertInArray } from 'technical/array/insert';
import {
  convertFromX100toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { numericComparisonOperators } from 'technical/filter/operators';
import { ActiveChip } from 'ui/active-chip';
import { createColumnHeader } from 'ui/column-header-with-sub-header';

import { PortfolioCustomFilter } from './types';

const metricValue = (
  type: string,
  companyMetrics: {
    type: string;
    periodType: string;
    amount: number;
    dateStart: string;
  }[],
) => {
  if (!Array.isArray(companyMetrics)) {
    return null;
  }
  const typeFiltered = companyMetrics.filter((metric) => metric.type === type);
  if (!Array.isArray(typeFiltered)) {
    return null;
  }
  let max = typeFiltered[0];
  for (let i = 0; i < typeFiltered.length; i++) {
    max = isBefore(new Date(max.dateStart), new Date(typeFiltered[i].dateStart))
      ? typeFiltered[i]
      : max;
  }
  return {
    ...max,
    amount: max?.amount ? convertFromX100toNumber(max.amount) : 0,
  };
};

export const getPortfolioColumnDefinition = (
  t: TFunction,
  customValuesOptions: PortfolioCustomFilter,
  displayHeadcount?: boolean,
  displayGrossIrr?: boolean,
): GridColDef<GetCompaniesQuery['companies'][number]>[] => {
  const headCountCol: GridColDef<GetCompaniesQuery['companies'][number]> = {
    field: 'headcount',
    groupable: false,
    type: 'number',
    headerName: t('pages.portfolio.table.headcount'),
    filterOperators: numericComparisonOperators,
    align: 'right',
    renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
      const { aggregationFunctionName } = aggregationRule || {};
      return createColumnHeader({
        headerName: t('pages.portfolio.table.headcount'),
        aggregationFunctionName,
      });
    },
    renderCell: ({ value }) => {
      if (!value) {
        return '';
      }
      const headcount = metricValue(Metrics.headcount, value);
      return headcount
        ? `${headcount.amount} (${format(
            new Date(headcount.dateStart),
            'LLL yyyy',
          )})`
        : '';
    },
    valueGetter: ({ row: { company_metrics } }) => {
      return company_metrics
        ? metricValue(Metrics.headcount, company_metrics)
        : 0;
    },
  };

  const grossIrrCol: GridColDef<GetCompaniesQuery['companies'][number]> = {
    field: 'grossIrr',
    groupable: false,
    type: 'number',
    headerName: t('pages.portfolio.table.grossIrr'),
    filterOperators: numericComparisonOperators,
    align: 'right',

    renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
      const { aggregationFunctionName } = aggregationRule || {};
      return createColumnHeader({
        headerName: t('pages.portfolio.table.grossIrr'),
        aggregationFunctionName,
      });
    },
    valueFormatter: ({ value }) =>
      value ? convertFromX100toNumber(value) : '',
    valueGetter: ({ row: { grossIrr } }) => grossIrr ?? 0,
  };

  const columns: GridColDef<GetCompaniesQuery['companies'][number]>[] = [
    {
      field: 'name',
      type: 'singleSelect',
      valueOptions: customValuesOptions.name,
      headerName: t('pages.portfolio.table.companyName'),
      aggregable: false,
      renderCell: ({ rowNode, value, field }) => {
        if (
          value &&
          rowNode.type === 'group' &&
          field === rowNode.groupingField
        ) {
          return <CompanyName name={value} />;
        }
        if (value) {
          return <CompanyName name={value} />;
        }
        return '';
      },
      valueGetter: ({ row: { name } }) => name ?? '',
    },
    {
      field: 'sector',
      type: 'singleSelect',
      valueOptions: customValuesOptions.sector,
      headerName: t('pages.portfolio.table.businessSector'),
      aggregable: false,
      renderCell: ({ value }) => value,
      valueGetter: ({ row: { sector } }) => sector ?? '',
    },
    {
      field: 'fundName',
      type: 'singleSelect',
      valueOptions: customValuesOptions.funds,
      headerName: t('pages.portfolio.table.fund'),
      renderCell: ({ value }) => value,
      valueGetter: ({ row: { fund } }) => fund?.name ?? '',
      groupingValueGetter: ({
        row: {
          fund: { name },
        },
      }) => name,
    },
    {
      field: 'location',
      type: 'singleSelect',
      valueOptions: customValuesOptions.location,
      headerName: t('pages.portfolio.table.location'),
      aggregable: false,
      renderCell: ({ value }) => value,
      valueGetter: ({ row: { location } }) => location ?? '',
    },
    {
      field: 'turnover',
      groupable: false,
      type: 'number',
      headerName: t('pages.portfolio.table.turnover'),
      filterOperators: numericComparisonOperators,
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.portfolio.table.turnover'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(value) : '',
      valueGetter: ({ row: { company_metrics } }) =>
        company_metrics
          ? metricValue(Metrics.turnover, company_metrics)?.amount ?? 0
          : 0,
    },
    {
      field: 'totalInvested',
      groupable: false,
      type: 'number',
      headerName: t('pages.portfolio.table.totalInvested'),
      filterOperators: numericComparisonOperators,
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.portfolio.table.totalInvested'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFromX100toNumber(value)) : '',
      valueGetter: ({ row: { totalInvested } }) => totalInvested ?? 0,
    },
    {
      field: 'latestValuation',
      groupable: false,
      type: 'number',
      headerName: t('pages.portfolio.table.latestValuation'),
      filterOperators: numericComparisonOperators,
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.portfolio.table.latestValuation'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFromX100toNumber(value)) : '',
      valueGetter: ({ row: { latestValuation } }) => latestValuation ?? 0,
    },
    {
      field: 'status',
      groupable: false,
      type: 'singleSelect',
      valueOptions: customValuesOptions.status,
      headerName: t('pages.portfolio.table.companyStatus'),
      renderCell: ({ row: { status } }) =>
        status !== undefined ? (
          <ActiveChip
            active={status === Statuses.inPortfolio}
            activeLabel={t('pages.portfolio.table.companyStatus', {
              context: Statuses.inPortfolio,
            })}
            inactiveLabel={t('pages.portfolio.table.companyStatus', {
              context: Statuses.exited,
            })}
          />
        ) : (
          ''
        ),
      valueGetter: ({ row: { status } }) => status ?? '',
    },
  ];

  if (displayHeadcount) {
    return insertInArray<GridColDef<GetCompaniesQuery['companies'][number]>>({
      array: columns,
      item: headCountCol,
      index: 5,
    });
  }

  if (displayGrossIrr) {
    return insertInArray<GridColDef<GetCompaniesQuery['companies'][number]>>({
      array: columns,
      item: grossIrrCol,
      index: 6,
    });
  }
  return columns;
};
