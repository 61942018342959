import { BusinessOutlined, InputOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { GetFundCompaniesSynthesisQuery } from 'generated/graphql';
import {
  formatToMonetaryAmount,
  formatNumber,
} from 'technical/currency/formatters';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';

import styles from './index.module.scss';

interface Props {
  data: GetFundCompaniesSynthesisQuery['infos']['synthesis'];
}

export const CompanyInventorySynthesisCard = ({
  data: {
    companyCount,
    portfolioCompanyCount,
    cededCompanyCount,
    totalAcquisitionCost,
  },
}: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <InfoCard>
      <InfoCard.Title>
        {t('pages.fundManager.portfolio.inventory.card.company.title')}
      </InfoCard.Title>
      <InfoCard.Items size="auto">
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
        >
          <InfoItem
            className={styles.cardItem}
            value={formatNumber(companyCount, { precision: 0 })}
            description={t(
              'pages.fundManager.portfolio.inventory.card.company.totalCompanyCount',
              {
                count: companyCount,
              },
            )}
            imageSource={<BusinessOutlined />}
            size="normal"
            sx={{
              color: palette.secondaryIconColor,
              bgcolor: palette.secondaryBackgroundIconColor,
            }}
          />
          <InfoItem
            value={formatToMonetaryAmount(totalAcquisitionCost)}
            className={styles.cardItem}
            description={t(
              'pages.fundManager.portfolio.inventory.card.company.totalAcquisitionCost',
            )}
            imageSource={<InputOutlined />}
            size="normal"
            sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}
          />
          <InfoItem
            value={formatNumber(portfolioCompanyCount, { precision: 0 })}
            className={styles.cardItem}
            description={t(
              'pages.fundManager.portfolio.inventory.card.company.portfolioCompanyCount',
              {
                count: portfolioCompanyCount,
              },
            )}
            imageSource={<BusinessOutlined />}
            size="normal"
            sx={{
              color: 'primary.main',
              bgcolor: 'primary.lighter',
            }}
          />
          <InfoItem
            value={formatNumber(cededCompanyCount, { precision: 0 })}
            className={styles.cardItem}
            description={t(
              'pages.fundManager.portfolio.inventory.card.company.cededCompanyCount',
              {
                count: cededCompanyCount,
              },
            )}
            imageSource={<BusinessOutlined />}
            size="normal"
            sx={{
              color: 'primary.main',
              bgcolor: 'primary.lighter',
            }}
          />
        </Stack>
      </InfoCard.Items>
    </InfoCard>
  );
};
