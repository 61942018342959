import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import { useLpCreationModuleNavigationContext } from 'business/shared/lp-creation-module/services/hooks/use-lp-creation-module-navigation-context';
import ConnectedPage from 'business/user/helpers/connected-page';

export const ConnectedCreationPage = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { previousPath } = useLpCreationModuleNavigationContext();

  return (
    <ConnectedPage
      title={t('pages.fundManager.lpCreation.title')}
      path={previousPath}
    >
      {children}
    </ConnectedPage>
  );
};
