import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import { Web_Portal_PublicationStatus_Enum } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import Link from 'ui/link';

import { FundData, FundsFilteredFields } from './types';

export function getFundColumnDefinition(
  t: TFunction,
  customFilters: FundsFilteredFields,
): ColDef<FundData>[] {
  return [
    {
      field: 'fundName',
      headerName: t('pages.fundManager.fund.table.fundName'),
      chartDataType: 'category',
      filter: true,
      filterParams: customFilters.fundNames,
    },
    {
      field: 'vintageYear',
      type: AgGridColumnTypesEnum.NUMBER,
      chartDataType: 'category',
      headerName: t('pages.fundManager.fund.table.vintageYear'),
      filter: true,
      filterParams: customFilters.vintageYears,
    },
    {
      field: 'numericCommitments',
      headerName: t('pages.fundManager.fund.table.subscription'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'numericTotalDrawn',
      headerName: t('pages.fundManager.fund.table.paidIn'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'numericDistributedNonRecallable',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t('pages.fundManager.fund.table.distributedNonRecallable'),
    },
    {
      field: 'numericCurrentDistributed',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t('pages.fundManager.fund.table.distributedRecallable'),
    },
    {
      field: 'numericUnfundedCommitments',
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      headerName: t('pages.fundManager.fund.table.unfundedCommitment'),
    },
    {
      headerName: t('pages.fundManager.lps.table.details'),
      colId: 'interaction',
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({
        value,
      }: ICellRendererParams<
        FundData,
        | {
            isDraft: boolean;
            to: string;
            countPendingValidationSubscriptionsAndShares: number;
          }
        | undefined
      >) => {
        if (!value) {
          return null;
        }

        if (!value.isDraft) {
          return (
            <Stack direction="row" spacing={2} alignItems="center">
              <Link to={value.to}>
                {t('pages.fundManager.lps.table.details')}
              </Link>
              {value.countPendingValidationSubscriptionsAndShares > 0 && (
                <PendingChipCount
                  count={value?.countPendingValidationSubscriptionsAndShares}
                />
              )}
            </Stack>
          );
        }

        return (
          <Link to={value.to}>{t('pages.fundManager.fund.table.edit')}</Link>
        );
      },
      valueGetter: (params) => {
        if (!params.data) {
          return {};
        }

        if (
          params.data.publicationStatus ===
          Web_Portal_PublicationStatus_Enum.Draft
        ) {
          // if we have a fundId -> first step is already done
          // TODO improve this by loading bank details informations
          return {
            isDraft: true,
            to: FundManagerRoutes.FundCreateIdBank.replace(
              ':fundId',
              params.data.id,
            ),
          };
        }

        return {
          isDraft: false,
          to: FundManagerRoutes.FundId.replace(':fundId', params.data.id),
          countPendingValidationSubscriptionsAndShares:
            params.data.pendingValidationItemsCount,
        };
      },
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
  ];
}
