import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ValuationGraphDataByType } from 'business/shared/services/types';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';
import variables from 'ui/variables.module.scss';

interface Props {
  progressionGraphData: ValuationGraphDataByType;
  fileName: string;
}

export const ValuationProgressionGraph = ({
  progressionGraphData,
  fileName,
}: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const plotLayout: PlotLayout = {
    colorway: [palette.secondary[800]],
    autosize: true,
    barmode: 'stacked',
    showlegend: false,
    bargap: 0.15,
    bargroupgap: 0.3,
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    hoverlabel: {
      bgcolor: palette.backgroundForm,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTextSize),
        color: palette.text,
      },
      bordercolor: palette.backgroundForm,
    },
    xaxis: {
      tickformat: 'Q%q %Y',
      dtick: 'M24',
    },
    xaxis2: {
      // axe that filters which traces will be shown in rangeSLider (xaxis is set on x2)
      dtick: 'M24',
      tickformat: 'Q%q %Y',
      matches: 'x',
      overlaying: 'x',
      rangeslider: {
        thickness: parseFloat(variables.graphRangeSliderTickness),
      },
    },
    margin: {
      pad: parseFloat(variables.graphAxisPadding),
    },
  };

  const paidIn = {
    x: progressionGraphData.valuationDates,
    y: progressionGraphData.paidInValues.map(convertFrom10X8toNumber),
    type: 'bar',
    offsetgroup: 1,
    marker: {
      color: palette.primary[800],
    },
    hovertemplate: `<b>Q%{x|%q} - %{x|%Y}</b> <br>${t(
      'pages.fundShareValuations.graphs.valueProgression.paidIn',
    )}:  <b>%{y:,.8f} €</b><extra></extra>`,
  };

  const totalDistributed = {
    x: progressionGraphData.valuationDates,
    y: progressionGraphData.totalDistributedValues.map(convertFrom10X8toNumber),
    type: 'bar',
    offsetgroup: 2,
    marker: {
      color: palette.secondary[800],
    },
    hovertemplate: `<b>Q%{x|%q} - %{x|%Y}</b> <br>${t(
      'pages.fundShareValuations.graphs.valueProgression.totalDistributed',
    )}:  <b>%{y:,.8f} €</b><extra></extra>`,
  };

  const residual = {
    x: progressionGraphData.valuationDates,
    y: progressionGraphData.residualValues.map(convertFrom10X8toNumber),
    type: 'bar',
    offsetgroup: 2,
    marker: {
      color: palette.secondary[500],
    },
    customdata: progressionGraphData.valuationGraphDataByDate,
    hovertemplate: `<b>Q%{x|%q} - %{x|%Y}</b> <br>${t(
      'pages.fundShareValuations.graphs.valueProgression.residual',
    )}:  <b>%{y:,.8f} €</b><extra></extra>`,
  };

  const rangeSliderTrace = {
    name: 'rangeSlider',
    xaxis: 'x2',
    x: progressionGraphData.valuationDates,
    y: progressionGraphData.surmResidualAndTotalDistributedValues.map(
      convertFrom10X8toNumber,
    ),
    opacity: 0, // overriden by css in PlotChart
    hoverinfo: 'skip',
    type: PlotChartTypeEnum.scatter,
    fill: 'tonexty',
    fillcolor: 'transparent',
    line: {
      color: 'transparent',
    },
  };

  const chartData: any = [paidIn, residual, totalDistributed, rangeSliderTrace];

  return (
    <PlotChart dataArray={chartData} layout={plotLayout} fileName={fileName} />
  );
};
