import { LPFilter } from './types';

export function formatLpName({
  selectedLp,
  lpName,
  index,
}: {
  selectedLp: LPFilter[];
  lpName: string;
  index: number;
}) {
  return `${lpName}${selectedLp.length > 1 && index === 1 ? '...' : ''}`;
}
