import { Edit } from '@mui/icons-material';
import { ICellRendererParams } from 'ag-grid-community';

interface EditCellClueProps {
  params: ICellRendererParams;
}
export const EditCellClue = ({ params }: EditCellClueProps) => {
  const handleClick = () => {
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex!,
      colKey: params.column!.getId(),
    });
  };

  return (
    <div className="absolute inset-y-0 right-1 flex items-center">
      <Edit
        color="secondary"
        className="right-0 w-4 h-4 cursor-pointer"
        onClick={handleClick}
      />
    </div>
  );
};
