import { useThemeConfigQuery } from 'generated/graphql';
import { AppLogo } from 'ui/app-logo';
import { QueryStateDisplay } from 'ui/query-state-display';

interface AuthAppLogoProps {
  height?: number;
}
export const AuthAppLogo = ({ height }: AuthAppLogoProps) => {
  const { data, error, loading } = useThemeConfigQuery();

  if (loading || error || !data) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return <AppLogo height={height} path={data.themeConfig?.logo} />;
};
