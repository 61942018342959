import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import styles from './index.module.scss';

interface Props {
  text: string;
  icon: ReactElement;
  color:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

export const InlineIconAndText = ({ text, icon, color }: Props) => {
  return (
    <Stack className={styles.stack} direction="row" spacing={1}>
      {icon}
      <Typography color={color} className={styles.text}>
        {text}
      </Typography>
    </Stack>
  );
};
