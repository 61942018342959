import { simpleClauseBuilder } from 'technical/filter/build-where-condition';
import { QueryParams } from 'technical/filter/types';

function commitmentFundNameBuilder(gqlOperator: {
  [key: string]: unknown;
}): QueryParams {
  return {
    share: {
      fund: {
        name: gqlOperator,
      },
    },
  };
}

function commitmentShareTypeBuilder(gqlOperator: {
  [key: string]: unknown;
}): QueryParams {
  return {
    share: {
      type: gqlOperator,
    },
  };
}

export const buildCommitmentNestedClauses = (
  field: string,
  gqlOperator: {
    [key: string]: unknown;
  },
): QueryParams => {
  if (field === 'fundName') {
    return commitmentFundNameBuilder(gqlOperator);
  }
  if (field === 'shareType') {
    return commitmentShareTypeBuilder(gqlOperator);
  }
  return simpleClauseBuilder(field, gqlOperator);
};
