import { TFunction } from 'translations/hook';

export const getExceljsPreProcess = (t: TFunction, title?: string) => {
  return ({ workbook, worksheet }: any) => {
    const date = new Date();
    workbook.created = date;
    worksheet.name = title;

    worksheet.addRow([t('components.export.valuesFrom'), title]);
    worksheet.addRow([t('components.export.exportedOn'), date]);
    worksheet.addRow({});
  };
};
