import { Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';

import { useLanguageContext } from 'business/providers/language-provider';
import { getCountryNameByCountryCode } from 'technical/country-utils';
import { getFlagIcon } from 'technical/flag-icons';

import { EditCellClue } from './edit-cell-clue';
import { isCellEditable } from './utils';

export const CountryCell = (params: ICellRendererParams) => {
  const { language } = useLanguageContext();

  const value = params.value;

  const countryName = getCountryNameByCountryCode({ code: value, language });

  const isEditable = isCellEditable(params);

  return (
    <>
      {value && (
        <Stack direction="row" alignItems="center" spacing={0.6}>
          <img
            loading="lazy"
            width="20"
            src={getFlagIcon(value)}
            srcSet={getFlagIcon(value, true)}
            alt={`Flag of ${countryName}`}
          />
          <p className="truncate">{countryName}</p>
        </Stack>
      )}
      {isEditable && <EditCellClue params={params} />}
    </>
  );
};
