import { GridOptions } from 'ag-grid-community';
import { IAggFuncParams } from 'ag-grid-community';
import { LanguageEnum } from 'translations/types';

import { getCountryNameByCountryCode } from 'technical/country-utils';
import { COUNTRY_CODES } from 'technical/country-utils/constants';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
  formatNumber,
} from 'technical/currency/formatters';
import { formatAsLocaleDate } from 'technical/date';
import { BooleanCell } from 'ui/alternative-table/components/boolean-cell';
import { CountryCell } from 'ui/alternative-table/components/country-cell';
import { EmailCell } from 'ui/alternative-table/components/email-cell';
import { ScoreCell } from 'ui/alternative-table/components/score-cell';
import { TextCell } from 'ui/alternative-table/components/text-cell';
import { ValueCellTooltip } from 'ui/value-cell-tooltip';

import {
  formatRowValueFrom10X8ToMonetary,
  formatRowValueToMonetary,
} from './row-value-formatters';

export function arraySum<T>(params: IAggFuncParams<T>) {
  return params.values.reduce((acc, value: number) => acc + value, 0);
}

//www.ag-grid.com/javascript-data-grid/filter-number/#number-filter-options
const numberFilterOptions = [
  'equals',
  'notEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
  'inRange',
];

const centeredCellStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const getDefaultColumnTypes = (
  language: LanguageEnum,
): GridOptions['columnTypes'] => ({
  number: {
    cellStyle: {
      textAlign: 'right',
    },
  },
  amount: {
    aggFunc: 'sum', // aggregat used when group by
    valueFormatter: formatRowValueToMonetary,
    getQuickFilterText: formatRowValueToMonetary, // necessary to search displayed data and not row value
    useValueFormatterForExport: false, // option does not work when the value is a number in Excel or if there is a custom  export by default (both apply here)
    cellStyle: {
      textAlign: 'right',
    },
    chartDataType: 'series', // data = axe Y
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: numberFilterOptions,
    },
    tooltipComponent: ValueCellTooltip,
    tooltipValueGetter: ({ value, data }) => {
      return formatToMonetaryAmount(value ?? 0, {
        currency: data?.currency,
        precision: 8,
      });
    },
  },
  // The export value is divided in the same way in front/src/ui/alternative-table/index.tsx
  amountWith10X8divider: {
    aggFunc: 'sum', // aggregat used when group by
    valueFormatter: formatRowValueFrom10X8ToMonetary,
    getQuickFilterText: formatRowValueFrom10X8ToMonetary, // necessary to search displayed data and not row value
    useValueFormatterForExport: false, // option does not work when the value is a number in Excel or if there is a custom export by default (both apply here)
    cellStyle: {
      textAlign: 'right',
    },
    chartDataType: 'series', // data = axe Y
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: numberFilterOptions,
    },
    tooltipComponent: ValueCellTooltip,
    tooltipValueGetter: ({ value, data }) =>
      formatToMonetaryAmount(convertFrom10X8toNumber(value ?? 0), {
        currency: data?.currency,
        precision: 8,
      }),
  },
  // The export value is divided in the same way in front/src/ui/alternative-table/index.tsx
  valueWith10X8divider: {
    aggFunc: 'sum', // aggregat used when group by
    valueFormatter: (params) =>
      formatToMonetaryAmount(convertFrom10X8toNumber(params.value), {
        precision: 2,
      }),
    getQuickFilterText: (params) =>
      formatToMonetaryAmount(convertFrom10X8toNumber(params.value), {
        precision: 2,
      }), // necessary to search displayed data and not row value
    useValueFormatterForExport: false, // option does not work when the value is a number in Excel or if there is a custom export by default (both apply here)
    cellStyle: {
      textAlign: 'right',
    },
    chartDataType: 'series', // data = axe Y
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: numberFilterOptions,
    },
    tooltipComponent: ValueCellTooltip,
    tooltipValueGetter: ({ value }) =>
      formatNumber(convertFrom10X8toNumber(value), {
        precision: 8,
      }),
  },
  country: {
    cellRenderer: CountryCell,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: COUNTRY_CODES,
      formatValue: (code: string) =>
        getCountryNameByCountryCode({ code, language }),
      cellRenderer: CountryCell,
      allowTyping: true,
    },
  },
  // The export value is formated the same way in front/src/ui/alternative-table/index.tsx
  date: {
    valueFormatter: ({ value }) => (!value ? '' : formatAsLocaleDate(value)),
  },
  boolean: {
    cellStyle: centeredCellStyle,
    cellDataType: 'boolean',
    cellRenderer: BooleanCell,
    cellEditor: 'agCheckboxCellEditor',
  },
  score: {
    cellStyle: centeredCellStyle,
    cellRenderer: ScoreCell,
  },
  email: {
    cellRenderer: EmailCell,
  },
  interaction: {
    // do not include this colomn in table tools like charts, pivot... (not relevant)
    chartDataType: 'excluded',
    enablePivot: false,
    enableRowGroup: false,
    enableValue: false,
  },
  text: {
    cellRenderer: TextCell,
  },
});
