import * as yup from 'yup';

import { ManagementCompanyType } from 'generated/graphql';
import { nonEmptyStringSchema } from 'technical/validation';

export const managementCompanyTypeSchema = yup
  .mixed<ManagementCompanyType>()
  .oneOf(Object.values(ManagementCompanyType));

const addressInformationFormInputSchema = yup.object({
  id: yup.string(),
  streetLine: nonEmptyStringSchema.min(1).max(100),
  streetLine2: yup.string().max(100),
  zipCode: nonEmptyStringSchema.min(1).max(20),
  city: nonEmptyStringSchema.min(1).max(100),
  country: nonEmptyStringSchema.min(1).max(60),
});

const companyManagementInformationFormInputSchema = yup.object({
  name: nonEmptyStringSchema.min(1).max(50),
  legalForm: nonEmptyStringSchema.min(1).max(300),
  identifier: nonEmptyStringSchema.min(1).max(50),
  regulatorApprovalNumber: nonEmptyStringSchema.min(1).max(50),
  type: managementCompanyTypeSchema.required(),
});

export const updateCompanyManagerFormSchema = yup.object({
  companyInformation: companyManagementInformationFormInputSchema.concat(
    yup.object({
      id: nonEmptyStringSchema,
    }),
  ),
  addressInformation: addressInformationFormInputSchema,
});

export const createCompanyManagementSchema = yup.object({
  companyInformation: companyManagementInformationFormInputSchema,
  addressInformation: addressInformationFormInputSchema,
});
