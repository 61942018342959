import { useAppContext } from 'business/providers/app-provider';
import { FeatureFlagEnum } from 'business/shared/services/types';

export function useFeatureFlag(featureFlagKey: FeatureFlagEnum) {
  const { activeFeatureFlags } = useAppContext();

  return activeFeatureFlags.includes(featureFlagKey);
}

export const useFeatureFlags = (featureFlagKeys: FeatureFlagEnum[]) => {
  const { activeFeatureFlags } = useAppContext();

  return featureFlagKeys.map((flag) => activeFeatureFlags.includes(flag));
};
