import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { getFundPendingValidationShareTransfersColumnDefinition } from 'business/fund-manager/fund/services/get-fund-pending-validation-share-transfers-column-definition';
import { ShareTransfer } from 'business/fund-manager/fund/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import {
  FundPendingValidationShareTransfersQueryResult,
  Web_Portal_ManagementCompanyType_Enum,
  useValidateFundShareTransferMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { removeDuplicates } from 'technical/remove-duplicates';
import { AlternativeTable } from 'ui/alternative-table';
import { Typo } from 'ui/typo';

type ShareTransferTableToValidateProps = {
  fundId: string;
  onCompleted: () => void;
  loading: boolean;
  data: FundPendingValidationShareTransfersQueryResult['data'];
};

const Table = ({
  fundId,
  onCompleted,
  loading,
  data,
}: ShareTransferTableToValidateProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { currency } = useFund();

  const isFundAdmin = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.FundAdmin,
  );

  const computedDataPendingValidation: ShareTransfer[] = data
    ? data.fundPendingValidationShareTransfers.map(
        ({
          id,
          numericTotalPrice,
          date,
          numericAmount,
          share,
          lpViewSeller,
          lpViewBuyer,
        }) => {
          return {
            id: id,
            numericTotalPrice: numericTotalPrice ?? 0,
            numericAmount: numericAmount ?? 0,
            date: date ? formatAsDate(date) : null,
            shareType: share?.name ?? '',
            sellerName: lpViewSeller?.lpName ?? '',
            buyerName: lpViewBuyer?.lpName ?? '',
          };
        },
      )
    : [];

  const [validateFundShareTransferMutation] =
    useValidateFundShareTransferMutation({
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.validateShareTransfer'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        onCompleted();
      },
      onError: () => {
        enqueueSnackbar(t('errors.validateShareTransfer'), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const handleValidateShareTransfer = async (shareTransferId: string) => {
    await validateFundShareTransferMutation({
      variables: {
        input: {
          fundId,
          shareTransferId,
        },
      },
    });
  };

  const customFiltersPendingValidation = computedDataPendingValidation
    ? {
        sellerNames: removeDuplicates(
          computedDataPendingValidation,
          'sellerName',
        ),
        buyerNames: removeDuplicates(
          computedDataPendingValidation,
          'buyerName',
        ),
        shareTypes: removeDuplicates(
          computedDataPendingValidation,
          'shareType',
        ),
      }
    : {
        sellerNames: [],
        buyerNames: [],
        shareTypes: [],
      };

  const headersPendingValidation =
    getFundPendingValidationShareTransfersColumnDefinition({
      t,
      handleValidateShareTransfer,
      customFilters: customFiltersPendingValidation,
      shoudlShowValidationButton: isFundAdmin,
    });

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row">
        <Typo size="xl" color="primary">
          {t('pages.fundManager.shareTransfer.table.titlePendingValidation')}
        </Typo>
        <PendingChipCount count={computedDataPendingValidation.length} />
      </Stack>
      <AlternativeTable<ShareTransfer>
        fileName="share-transfers-to-validate"
        rowData={computedDataPendingValidation}
        columnDefs={headersPendingValidation}
        loading={loading}
        domLayout="autoHeight"
        context={{ currency: currency }}
        displaySidebar
      />
    </Stack>
  );
};

export const ShareTransferTableToValidate = ({
  fundId,
  data,
  onCompleted,
  loading,
}: ShareTransferTableToValidateProps) => {
  // Do not show table if no pendingValidation subscriptions
  if (data?.fundPendingValidationShareTransfers?.length === 0) {
    return null;
  }

  return (
    <Table
      fundId={fundId}
      onCompleted={onCompleted}
      loading={loading}
      data={data}
    />
  );
};
