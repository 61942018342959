import * as yup from 'yup';

import { clientTypeEnumSchema } from 'business/shared/lp-creation-module/services/validation';
import {
  ClientTypeEnum,
  OnboardingSubscriptionTypeEnum,
} from 'generated/graphql';
import {
  emailRequiredSchema,
  nonEmptyStringSchema,
  uuidRequiredSchema,
} from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

export const ONBOARDING_NB_LP_LIMIT = 10;
export const createOnboardingSubscriptionFormSchema = yup.object({
  fundId: nonEmptyStringSchema,
  managementCompanyId: yup.string(),
  subscribingEntityNames: yup
    .array(nonEmptyStringSchema)
    .min(1, ValidationErrors.REQUIRED)
    .max(ONBOARDING_NB_LP_LIMIT, ValidationErrors.TOO_LONG)
    .required(ValidationErrors.REQUIRED),
});

const onboardingSubscriptionTypeEnumSchema = yup
  .mixed<OnboardingSubscriptionTypeEnum>()
  .oneOf(Object.values(OnboardingSubscriptionTypeEnum));

const amountRangeSchema = yup.number().when('type', {
  is: (value: OnboardingSubscriptionTypeEnum) =>
    value === OnboardingSubscriptionTypeEnum.Range,
  then: (schema) => schema.positive().required(),
  otherwise: (schema) => schema.optional(),
});

const subscriptionLpShareSchema = yup.object({
  shareId: uuidRequiredSchema,
  type: onboardingSubscriptionTypeEnumSchema.required(),
  amount: yup.number().when('type', {
    is: (value: OnboardingSubscriptionTypeEnum) =>
      value === OnboardingSubscriptionTypeEnum.Fixed,
    then: (schema) => schema.positive().required(),
  }),
  amountMin: amountRangeSchema,
  amountMax: amountRangeSchema.test(
    'maxIsGreaterThanMin',
    'errors.maxIsGreaterThanMin',
    (value, testContext) => {
      const { amountMin, type } = testContext.parent; // Access other fields in the object
      if (type === OnboardingSubscriptionTypeEnum.Fixed) {
        // here field is always valid
        return true;
      }
      return (
        amountMin === undefined || value === undefined || value > amountMin
      );
    },
  ),
  taxOption: yup.boolean().required(ValidationErrors.REQUIRED),
});

const subscriptionLpSchema = yup.object({
  subscribingEntityName: nonEmptyStringSchema,
  shares: yup
    .array()
    .of(subscriptionLpShareSchema)
    .min(1, 'errors.selectAtLeastOneShare')
    .required(),
});

export const onboardingSubscriptionsFormSchema = yup.object({
  subscriptions: yup
    .array()
    .of(subscriptionLpSchema)
    .min(1)
    .required(ValidationErrors.REQUIRED),
});

export const createNewLpInOnboardingFormSchema = yup.object().shape({
  client: clientTypeEnumSchema,
  firstName: nonEmptyStringSchema,
  lastName: nonEmptyStringSchema,
  email: emailRequiredSchema,
  subscribingEntityName: yup.string().when('client', {
    is: (type: ClientTypeEnum) => type === ClientTypeEnum.LegalEntity,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional().default(undefined),
  }),
});

export const uploadSignedSubscriptionDocumentSchema = yup.object({
  onboardingSubscriptionId: nonEmptyStringSchema,
  subscriptionDocument: yup
    .object({
      filePath: nonEmptyStringSchema,
      fileName: nonEmptyStringSchema,
    })
    .required(),
});

export const selectSignatoryFormSchema = yup.object({
  signatoryId: uuidRequiredSchema,
});
