import { Stack } from '@mui/material';

import { computeFundShareValuation } from 'business/lp-platform/valuations/valuations.helper';
import { ValuationGraphData } from 'business/shared/services/types';
import { GetValuationsQuery } from 'generated/graphql';
import { formatRatioToPercentage } from 'technical/currency/formatters';
import { InfoCard } from 'ui/info-card';
import SynthesisItem from 'ui/synthesis-item';

import styles from './index.module.scss';

interface Props {
  data: GetValuationsQuery;
  lastPoint: ValuationGraphData;
}

export default function FundsShareValuationsSynthesis({
  data,
  lastPoint,
}: Props) {
  const { dpi, tvpi, netIrr } = computeFundShareValuation(data, lastPoint);
  const formattedIrr = isNaN(netIrr) ? 'N/A' : formatRatioToPercentage(netIrr);

  return (
    <Stack direction="row" className={styles.stack} spacing={2}>
      <InfoCard className={styles.card}>
        <SynthesisItem
          value={`${dpi.toFixed(2)} x`}
          description="DPI"
          color="secondary"
        ></SynthesisItem>
      </InfoCard>
      <InfoCard className={styles.card}>
        <SynthesisItem
          value={`${tvpi.toFixed(2)} x`}
          description="TVPI"
        ></SynthesisItem>
      </InfoCard>
      <InfoCard className={styles.card}>
        <SynthesisItem
          value={formattedIrr}
          description="Net IRR"
        ></SynthesisItem>
      </InfoCard>
    </Stack>
  );
}
