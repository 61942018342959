import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyMetricCreationForm } from 'business/fund-manager/portfolio/company/components/company-metric-creation-form';
import { getCompanyMetricsEvolutionColumnDefinition } from 'business/fund-manager/portfolio/company/services/get-company-metrics-evolution-column-definition';
import { CompanyMetricValue } from 'business/fund-manager/portfolio/company/services/types';
import { useCompanyMetricsEvolutionQuery } from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { CustomModal } from 'ui/custom-modal';

const MetricCreationModal = ({ onAdded }: { onAdded: () => void }) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();
  return (
    <>
      <ActionButton variant={'submit'} onClick={handleOpenModal}>
        {t('pages.fundManager.portfolio.company.metrics.create')}
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <CompanyMetricCreationForm
            onAdded={onAdded}
            onClose={handleCloseModal}
          />
        }
      />
    </>
  );
};

export const CompanyMetrics = () => {
  const { companyId = '' } = useParams();
  const { t } = useTranslation();

  const { data, refetch } = useCompanyMetricsEvolutionQuery({
    variables: {
      companyId,
    },
  });

  const getCompanyMetricsTypeTransaltion = (metricName: string) => {
    return t(`pages.fundManager.settings.companyMetrics.values.${metricName}`, {
      defaultValue: metricName,
    });
  };

  const rows: CompanyMetricValue[] = data?.companyMetricsEvolution
    ? data?.companyMetricsEvolution.map((metric) => {
        return {
          value: metric.value,
          metricName: `${getCompanyMetricsTypeTransaltion(
            metric.companyMetricsType.name,
          )}${
            metric.companyMetricsType.unit !== '9.99'
              ? ` (${metric.companyMetricsType.unit})`
              : ''
          }`,
          companyMetricsType: metric.companyMetricsType,
          date: new Date(metric.date),
        };
      })
    : [];

  const headers = getCompanyMetricsEvolutionColumnDefinition(t);

  return (
    <Stack spacing={2}>
      <Stack alignSelf="end">
        <MetricCreationModal onAdded={refetch} />
      </Stack>
      <AlternativeTable
        rowData={rows}
        columnDefs={headers}
        fileName="company-metric-evolution"
        displaySidebar
        domLayout="autoHeight"
        pivotMode
        autoGroupColumnDef={{
          cellRendererParams: {
            suppressCount: true,
          },
        }}
        suppressAggFuncInHeader
      ></AlternativeTable>
    </Stack>
  );
};
