import { TFunction } from 'translations/hook';

import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

import { LpValidationLegalEntity } from './types';

export const getCommonKYCKYBBooleans = ({
  data,
  t,
}: {
  data:
    | LpValidationLegalEntity['lpIndividualKYC']
    | LpValidationLegalEntity['lpLegalEntityKYB'];
  t: TFunction;
}): ValidationItemType[] => {
  if (!data) {
    return [];
  }

  return [
    {
      type: VALIDATION_ITEM_TYPES.BOOLEAN,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.professionnalClient',
      ),
      value: data.professionnalClient,
    },
    {
      type: VALIDATION_ITEM_TYPES.BOOLEAN,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.frenchEquitySavingsPlan',
      ),
      value: data.frenchEquitySavingsPlan,
    },
    {
      type: VALIDATION_ITEM_TYPES.BOOLEAN,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.ampereReporting',
      ),
      value: data.ampereReporting,
    },
    {
      type: VALIDATION_ITEM_TYPES.BOOLEAN,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.USRelatedPerson',
      ),
      value: data.USRelatedPerson,
    },
    {
      type: VALIDATION_ITEM_TYPES.BOOLEAN,
      label: t('pages.fundManager.lpValidation.legalEntityKyb.label.taxOption'),
      value: data.taxOption,
    },
  ];
};
