import {
  ValuationProgressEnum,
  WebCompanyTransactionTypeEnum,
  Web_Portal_CompanyTransactionType_Enum,
} from 'generated/graphql';

import { InstrumentValuationTable } from './types';

export function getInstrumentTotalQuantities(
  transactions?:
    | {
        quantity: number;
        type:
          | WebCompanyTransactionTypeEnum
          | Web_Portal_CompanyTransactionType_Enum
          | string;
      }[]
    | null,
) {
  if (!transactions) {
    return 0;
  }
  const stockQuantity = transactions.reduce((acc, { type, quantity }) => {
    if (
      type === WebCompanyTransactionTypeEnum.Acquisition ||
      type === Web_Portal_CompanyTransactionType_Enum.Acquisition
    ) {
      acc += quantity;
    } else {
      acc -= quantity;
    }
    return acc;
  }, 0);

  return stockQuantity;
}

/* Taken from pe3 confluence
 * https://pe3.atlassian.net/wiki/spaces/PE3/pages/1227718661/Valuation+inventory+table#a.-Valuation-progress
 */
export function getValuationProgress({
  accruedCoupon,
  fairValue,
  companyInstrument,
}: InstrumentValuationTable): ValuationProgressEnum {
  if (getInstrumentTotalQuantities(companyInstrument.transactions) === 0) {
    return ValuationProgressEnum.Exited;
  }
  if (!accruedCoupon || !fairValue) {
    return ValuationProgressEnum.PendingValuation;
  }
  return ValuationProgressEnum.Valuated;
}
