import { PropsWithChildren } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { fundAvailableRoutesRegex } from 'business/fund-manager/fund/services/constants';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { FundProvider } from 'business/providers/fund-provider';
import ConnectedPage from 'business/user/helpers/connected-page';
import { PublicationStatus, useGetFundQuery } from 'generated/graphql';
import { getPageTitle } from 'technical/get-page-title';
import { getPreviousUrlPath } from 'technical/get-previous-url-path';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundDetailPage = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { fundId = '' } = useParams();
  const { pathname } = useLocation();

  const detailPath = FundManagerRoutes.FundId.replace(':fundId', fundId);
  const path =
    pathname === detailPath
      ? FundManagerRoutes.Fund
      : getPreviousUrlPath(pathname);

  const { data, loading, error } = useGetFundQuery({
    variables: {
      input: { id: fundId },
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.fund) {
    return (
      <ConnectedPage path={path}>
        <QueryStateDisplay loading={loading} error={error} />
      </ConnectedPage>
    );
  }

  if (data.fund.publicationStatus === PublicationStatus.Draft) {
    // if fund is a draft you can't be here
    return (
      <Navigate
        to={FundManagerRoutes.FundCreateIdBank.replace(':fundId', fundId)}
        replace={true}
      />
    );
  }

  const title = getPageTitle(pathname, fundAvailableRoutesRegex);
  return (
    <ConnectedPage
      path={path}
      title={
        title
          ? t(`pages.fundManager.fund.title.${title}`, {
              fundName: data.fund.name,
            })
          : t('pages.fundManager.fund.title.main', { fundName: data.fund.name })
      }
    >
      <FundProvider fund={data.fund}>{children}</FundProvider>
    </ConnectedPage>
  );
};
