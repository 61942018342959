import { isNumber } from 'technical/number/is-number';

import { LuhnErrors } from './errors';

const SIREN_LENGTH = 9;

function splitDigits(value: number) {
  return { tens: Math.floor(value / 10), ones: value % 10 };
}

function computeLuhnOrThrow(value: string): number {
  const numbers = value.split('').map((nb, index) => {
    const multiplier = (index + 1) % 2 === 0 ? 2 : 1;

    if (!isNumber(nb)) {
      throw new Error(LuhnErrors.NOT_A_NUMBER);
    }

    return Number(nb) * multiplier;
  });

  return numbers.reduce((prev, curr) => {
    const digits = splitDigits(curr);
    return prev + digits.ones + digits.tens;
  }, 0);
}

export function isSiren(value?: string): boolean {
  if (!value) {
    return false;
  }
  try {
    if (value.length !== SIREN_LENGTH) {
      throw new Error(LuhnErrors.INVALID_LENGTH);
    }
    if (!isNumber(value)) {
      throw new Error(LuhnErrors.NOT_A_NUMBER);
    }
    if (computeLuhnOrThrow(value) % 10 !== 0) {
      throw new Error(LuhnErrors.NOT_LUHN);
    }
    return true;
  } catch (err) {
    return false;
  }
}
