import { InputLabel, Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { IFile } from 'technical/file-management/types';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FileInput } from 'ui/form';

interface FormInputFilesFieldProps {
  onFilesUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<IFile[]>;
  limit?: number;
  files: IFile[];
  name: string;
  hasError?: boolean;
}
export const FormInputFilesField = ({
  onFilesUpload,
  name,
  limit,
  files,
  hasError,
}: FormInputFilesFieldProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const onRemove = (targetFile: IFile) => {
    setValue(
      name,
      files.filter((file) => file !== targetFile),
    );
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      const uploadedFiles = await onFilesUpload(event);
      setValue(name, [...files, ...uploadedFiles]);
    } catch (e) {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    }
    setLoading(false);
  };

  return (
    <Stack>
      {!limit || limit > files.length ? (
        <FileInput
          onChange={handleChange}
          maximumFileSize="8 MB"
          loading={loading}
          hasError={hasError}
        />
      ) : null}

      {files
        ? files.map((file) => (
            <UploadedFile
              file={file}
              handleRemoveFile={onRemove}
              key={file.filePath}
            />
          ))
        : null}
    </Stack>
  );
};

interface FormInputFilesProps extends Omit<FormInputFilesFieldProps, 'files'> {
  label?: string;
  // TODO find away todo better than this
  control?: any;
  required?: boolean;
}

export const FormInputFiles = ({
  label,
  required,
  name,
  control,
  ...rest
}: FormInputFilesProps) => {
  const {
    field: { value: files = [] },
    fieldState: { error },
  } = useController<{ files: IFile[] }, 'files'>({
    control,
    name: name as 'files',
    rules: { required },
  });

  if (!label) {
    return <FormInputFilesField {...rest} name={name} files={files} />;
  }

  const hasError = !!error?.message;
  return (
    <Stack spacing={1}>
      <InputLabel error={hasError} required={required}>
        {label}
      </InputLabel>
      <Stack>
        <FormInputFilesField
          {...rest}
          name={name}
          files={files}
          hasError={hasError}
        />
        {error?.message ? <ErrorLabel label={error.message} /> : null}
      </Stack>
    </Stack>
  );
};
