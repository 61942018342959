import { computeOperationStats } from 'business/fund-manager/shared/services/compute-operation-values';
import { FinancialFlowRow } from 'business/shared/financial-flows/services/types';
import { getCurrencyEnumFromWebPortalEnum } from 'business/shared/services/types';
import {
  GetFundManagerOperationsForFundQuery,
  GetFundManagerOperationsForLpQuery,
} from 'generated/graphql';
import { isDefined } from 'technical/is-defined';

export function createFinancialFlowsRows(
  data:
    | GetFundManagerOperationsForFundQuery
    | GetFundManagerOperationsForLpQuery
    | undefined,
): FinancialFlowRow[] {
  if (!data) {
    return [];
  }

  return data.web_portal_operation
    .flatMap(({ date, identifier, fund, operationShares, status }) => {
      return operationShares.map((opeShare) => {
        if (!opeShare?.operationShareLps_aggregate?.aggregate?.sum) {
          return null;
        }
        const { drawdown, distribution, recallableDistribution } =
          computeOperationStats(
            opeShare.operationShareLps_aggregate.aggregate.sum,
          );

        return {
          id: opeShare.id,
          date: new Date(date),
          identifier: identifier ?? '',
          fundName: fund?.name ?? '',
          share: opeShare.share.name,
          status: status ?? '',
          drawdown,
          distributed: distribution,
          recallableDistributed: recallableDistribution,
          currency: getCurrencyEnumFromWebPortalEnum(fund?.currency),
        };
      });
    })
    .filter(isDefined);
}
