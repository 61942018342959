import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'translations/hook';

import PortfolioGraphs from 'business/lp-platform/portfolio/components/portfolio-graphs';
import { getPortfolioColumnDefinition } from 'business/lp-platform/portfolio/services/get-portfolio-column-definition';
import { buildPortfolioNestedClauses } from 'business/lp-platform/portfolio/services/portfolio-clause-builder';
import {
  PortfolioCustomFilter,
  isGetCompaniesQuery,
} from 'business/lp-platform/portfolio/services/types';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import { GetCompaniesQuery, useGetCompaniesQuery } from 'generated/graphql';
import { DEFAULT_WHERE_CLAUSE } from 'technical/filter/constants';
import { handleMultipleFilter } from 'technical/filter/filters';
import {
  FuzzyFilterDataKey,
  FuzzyFilterEnabledColumn,
} from 'technical/filter/fuzzy-filter/constants';
import { handleFuzzyFiltering } from 'technical/filter/fuzzy-filter/fuzzy-filter';
import { removeDuplicates } from 'technical/remove-duplicates';
import { Table, ToolbarContainer } from 'ui/table';

const PortfolioPage = () => {
  const { t } = useTranslation();
  const [searchedTerm, setSearchedTerm] = useState('');
  const [whereClause, setWhereClause] = useState(DEFAULT_WHERE_CLAUSE);
  const [customSectorFilter, setCustomSectorFilter] =
    useState<PortfolioCustomFilter>({
      name: [],
      sector: [],
      location: [],
      status: [],
      funds: [],
    });

  const displayHeadcount = useFeatureFlag(FeatureFlagEnum.DISPLAY_HEAD_COUNT);

  const displayGrossIrr = useFeatureFlag(FeatureFlagEnum.DISPLAY_GROSS_IRR);

  const toolbarRef = useRef<HTMLDivElement>(null);

  const headers: GridColDef<GetCompaniesQuery['companies'][number]>[] =
    getPortfolioColumnDefinition(
      t,
      customSectorFilter,
      displayHeadcount,
      displayGrossIrr,
    );

  const { data, loading, refetch } = useGetCompaniesQuery({
    variables: DEFAULT_WHERE_CLAUSE,
  });

  useEffect(() => {
    if (data && !customSectorFilter.name.length) {
      setCustomSectorFilter({
        name: removeDuplicates(data.companies, 'name'),
        sector: removeDuplicates(data.companies, 'sector'),
        location: removeDuplicates(data.companies, 'location'),
        status: removeDuplicates(data.companies, 'status'),
        funds: removeDuplicates(
          data.companies.map(({ fund }) => fund),
          'name',
        ),
      });
    }
  }, [data, customSectorFilter.name.length]);

  const rows =
    handleFuzzyFiltering({
      data,
      searchedTerm,
      dataKeys: [FuzzyFilterDataKey.Portfolio],
      enabledColumns: [FuzzyFilterEnabledColumn.CompanyName],
    }) || data;

  if (whereClause) {
    refetch(whereClause);
  }

  return (
    <ConnectedPage title={t('pages.portfolio.title')}>
      <Stack spacing={2}>
        <ToolbarContainer
          toolbarRef={toolbarRef}
          filterPlaceholders={[
            t('pages.portfolio.table.companyName'),
            t('pages.portfolio.table.businessSector'),
          ]}
        />
        {rows && isGetCompaniesQuery(rows) ? (
          <PortfolioGraphs data={rows} />
        ) : null}
        <Table<GetCompaniesQuery['companies'][number]>
          toolbarAnchor={toolbarRef.current}
          columns={headers}
          rows={rows && isGetCompaniesQuery(rows) ? rows.companies : []}
          getRowId={(row) => {
            return row.id;
          }}
          loading={loading}
          onFilterModelChange={(model) => {
            handleMultipleFilter(
              model,
              setWhereClause,
              buildPortfolioNestedClauses,
              setSearchedTerm,
            );
          }}
          initialState={{
            aggregation: {
              model: {
                turnover: 'sum',
                headcount: 'sum',
                grossIrr: 'sum',
                totalInvested: 'sum',
                latestValuation: 'sum',
              },
            },
          }}
          filterMode="server"
          columnHeaderHeight={60}
        />
      </Stack>
    </ConnectedPage>
  );
};

export default PortfolioPage;
