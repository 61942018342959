import { useParams } from 'react-router';

import { LpContactManager } from 'business/shared/components/lp-contact-manager';
import { DraftEditionContainer } from 'business/shared/lp-creation-module/components/draft-edition-container';
import { LegalEntityKYCContactForm } from 'business/shared/lp-creation-module/components/legal-entity-kyc-contact-form';
import { DeclineReason } from 'business/shared/lp-creation-module/components/lp-decline-reason/lp-decline-reason';
import { useDraftEditionStepsContext } from 'business/shared/lp-creation-module/services/hooks/use-draft-edition-steps-context';
import { useLpContacts } from 'business/shared/lp-creation-module/services/hooks/use-lp-contacts';
import { LegalEntityKycRole } from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpCreationLegalEntityKYC = () => {
  const { lpId = '' } = useParams();
  const { loading, contacts, error, refetch } = useLpContacts({
    lpId,
  });

  const { onNextStep } = useDraftEditionStepsContext();

  if (loading || error) {
    return (
      <DraftEditionContainer rawChildren>
        <QueryStateDisplay loading={loading} error={error} />
      </DraftEditionContainer>
    );
  }

  const haveLegalRepresentative = contacts.some((contact) =>
    contact.roles?.includes(LegalEntityKycRole.LegalRepresentative),
  );
  const haveUltimateBeneficialOwner = contacts.some((contact) =>
    contact.roles?.includes(LegalEntityKycRole.UltimateBeneficialOwner),
  );

  const canSubmit = haveLegalRepresentative && haveUltimateBeneficialOwner;

  return (
    <DraftEditionContainer
      rawChildren
      onSubmit={canSubmit ? onNextStep : undefined}
    >
      <DeclineReason />
      <LpContactManager
        header={
          !canSubmit ? (
            <ErrorLabel label="pages.fundManager.lpCreation.legalEntityKYC.mandatoryRoles" />
          ) : undefined
        }
        lpId={lpId}
        contacts={contacts}
        onAdded={refetch}
        onDeleted={refetch}
        formComponent={LegalEntityKYCContactForm}
      />
    </DraftEditionContainer>
  );
};
