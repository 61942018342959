import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { getLpIndividualKYCFormValues } from 'business/shared/lp-creation-module/services/get-kyc-form-values';
import { LpKYC } from 'business/shared/lp-creation-module/services/types';
import { lpIndividualKYCSchema } from 'business/shared/lp-creation-module/services/validation';
import {
  GetLpIndividualKycQuery,
  useUpsertLpIndividualKycMutation,
} from 'generated/graphql';

type Props = {
  data: GetLpIndividualKycQuery['lpKYC'];
  lpId: string;
  onCompleted: () => void;
};

export const useIndividualKYCForm = ({ data, lpId, onCompleted }: Props) => {
  const [upsertLpIndividualKyc, { loading: saving, error }] =
    useUpsertLpIndividualKycMutation({
      onCompleted,
    });

  const defaultValues = getLpIndividualKYCFormValues(data);

  const methods = useForm<LpKYC>({
    resolver: yupResolver<LpKYC>(lpIndividualKYCSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async ({ customAnswers, ...rest }: LpKYC) =>
    upsertLpIndividualKyc({
      variables: {
        input: {
          lpId,
          data: {
            ...rest,
            customAnswers: customAnswers ?? [],
          },
        },
      },
      // catch to avoid react-hook-form error
    }).catch(() => undefined);

  return {
    methods,
    onSubmit: handleSubmit(onSubmit),
    saving,
    error,
  };
};
