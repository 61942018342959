import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundNavBar } from 'business/fund-manager/fund/components/fund-nav-bar';
import { SharesManager } from 'business/fund-manager/fund/components/shares-manager';
import { FundNavBarTabEnum } from 'business/fund-manager/fund/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import {
  PublicationStatus,
  Web_Portal_ManagementCompanyType_Enum,
  useSharesQuery,
} from 'generated/graphql';
import { FormCard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundEditShare = () => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();
  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  const { data, loading, error, refetch } = useSharesQuery({
    variables: {
      input: {
        fundId,
        ...(isAdvisor
          ? {
              publicationStatus: PublicationStatus.Validated,
            }
          : {}),
      },
    },
  });

  return (
    <FundNavBar activeTab={FundNavBarTabEnum.Share}>
      <FormCard>
        {loading || error || !data ? (
          <QueryStateDisplay loading={loading} error={error} />
        ) : (
          <SharesManager
            title={t('pages.fundManager.fundEdition.share.title')}
            data={data}
            fundId={fundId}
            onCompleted={refetch}
          />
        )}
      </FormCard>
    </FundNavBar>
  );
};
