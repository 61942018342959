import { Stack } from '@mui/material';

import { InvestorsGraphs } from 'business/fund-manager/home/components/investors-graphs/investors-graphs';
import { GetFundManagerDashBoardInfosQueryResult } from 'generated/graphql';
import { InfoCard } from 'ui/info-card';

interface Props {
  title: string;
  path: string;
  data: NonNullable<
    GetFundManagerDashBoardInfosQueryResult['data']
  >['getFundManagerDashBoardInfos']['lpsOverview'];
}

export const InvestorsCard = ({ title, path, data }: Props) => {
  return (
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>
      <Stack padding={3}>
        <InvestorsGraphs
          data={data}
          fileName="investors-repartition-overview"
        />
      </Stack>
    </InfoCard>
  );
};
