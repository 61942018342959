import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';
import { LanguageEnum as localeEnum } from 'translations/types';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useLanguageContext } from 'business/providers/language-provider';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LanguageEnum, useLaunchSignatureMutation } from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';

import { SelectSignatoryModal } from './select-signatory-modal';

interface LaunchSignatureButtonProps {
  onboardingSubscriptionId: string;
  disabled: boolean;
}
export const LaunchSignatureButton = ({
  onboardingSubscriptionId,
  disabled,
}: LaunchSignatureButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguageContext();
  const navigate = useNavigate();

  const { open, handleCloseModal, handleOpenModal } = useModal();

  const [launchSignatureMutation, { loading }] = useLaunchSignatureMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      navigate(FundManagerRoutes.OnboardingSubscriptionRequested);
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const launchSignature = (managementCompanySignatoryId: string) => {
    launchSignatureMutation({
      variables: {
        input: {
          managementCompanySignatoryId,
          onboardingSubscriptionId,
          locale:
            language === localeEnum.fr ? LanguageEnum.Fr : LanguageEnum.En,
        },
      },
    });
  };

  return (
    <>
      <ActionButton
        variant="submit"
        loading={loading}
        disabled={disabled}
        onClick={handleOpenModal}
      >
        {t('pages.fundManager.onboardingSubscriptionSignature.launchSignature')}
      </ActionButton>
      <SelectSignatoryModal
        onboardingSubscriptionId={onboardingSubscriptionId}
        open={open}
        onClose={handleCloseModal}
        onSubmit={launchSignature}
      />
    </>
  );
};
