import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { PortfolioToolbar } from 'business/fund-manager/portfolio/components/portfolio-toolbar';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  useGetActiveFundsWithSharesQuery,
  useCompanyNameQuery,
  useFundValuationDateQuery,
} from 'generated/graphql';
import { convertPathToTranslationKey } from 'technical/convert-path-to-translation-key';
import { formatAsDate } from 'technical/date';
import { getPreviousUrlPath } from 'technical/get-previous-url-path';
import { QueryStateDisplay } from 'ui/query-state-display';

export const PortfolioPage = ({ children }: PropsWithChildren) => {
  const { fundId, companyId, valuationId } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useGetActiveFundsWithSharesQuery({
    fetchPolicy: 'network-only',
  });
  const { data: dataCompany } = useCompanyNameQuery({
    variables: { id: companyId ?? '' },
    skip: companyId === undefined,
  });

  const { data: dataValuation } = useFundValuationDateQuery({
    variables: { id: valuationId ?? '' },
    skip: valuationId === undefined,
  });

  const fundName: string | undefined = data?.funds
    ? data.funds.find((fund) => fund.id === fundId)?.name
    : '';

  const title = t('routes.' + convertPathToTranslationKey(pathname), {
    fundName,
    companyName: dataCompany?.company?.name ?? '',
    defaultValue: '',
    valuationDate: dataValuation?.valuation?.date
      ? formatAsDate(dataValuation.valuation.date)
      : '',
  });

  const previousPath = getPreviousUrlPath(pathname);

  if (loading || error || !data?.funds) {
    return (
      <ConnectedPage title={title} path={previousPath}>
        <QueryStateDisplay loading={loading} error={error} />
      </ConnectedPage>
    );
  }

  if (
    (!fundId && data.funds.length > 0) ||
    (!fundName && pathname !== FundManagerRoutes.Portfolio)
  ) {
    // we aren't on porfolio home and fund not seems to exists
    return (
      <Navigate
        to={FundManagerRoutes.PortfolioFundIdInventory.replace(
          ':fundId',
          data.funds[0].id,
        )}
      />
    );
  }

  const onChange = (newFundId: string) => {
    navigate(
      FundManagerRoutes.PortfolioFundIdInventory.replace(':fundId', newFundId),
    );
  };

  return (
    <ConnectedPage title={title} path={previousPath}>
      <Stack spacing={2}>
        <PortfolioToolbar
          onChange={onChange}
          funds={data.funds}
          fundId={fundId}
        />
        {children}
      </Stack>
    </ConnectedPage>
  );
};
