import { InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FormFromDateOf } from 'business/fund-manager/fund/components/form-from-date-of';
import { FundProfile } from 'business/fund-manager/fund/services/types';
import { FormSection, FormInputText } from 'ui/form';

export const FundTermForm = () => {
  const { control } = useFormContext<FundProfile>();
  const { t } = useTranslation();

  return (
    <FormSection title={t('pages.fundManager.fundCreation.form.term.title')}>
      <FormInputText
        name="termDelay"
        label={t('pages.fundManager.fundCreation.form.term.delay')}
        control={control}
        type="number"
        placeholder="10"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t('pages.fundManager.fundCreation.form.term.years')}
            </InputAdornment>
          ),
        }}
      />

      <FormFromDateOf name="termFromDateOf" required />

      <FormInputText
        name="termExtension"
        label={t('pages.fundManager.fundCreation.form.term.extension')}
        control={control}
        type="number"
        placeholder="1"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t('pages.fundManager.fundCreation.form.term.extensionYears')}
            </InputAdornment>
          ),
        }}
      />
    </FormSection>
  );
};
