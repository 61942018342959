import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CompanyInvestment } from 'business/fund-manager/portfolio/company/services/types';
import { companyInvestmentSchema } from 'business/fund-manager/portfolio/company/services/validation';
import {
  CompanyInvestmentQuery,
  useUpdateCompanyMutation,
  Web_Portal_CompanyListedOnStockExchangeEnum_Enum,
  CurrencyEnum,
  Web_Portal_Currency_Enum,
} from 'generated/graphql';

const getDefaultValues = (
  data: CompanyInvestmentQuery['company'],
): Omit<Partial<CompanyInvestment>, 'currency'> & { currency: string } => {
  return {
    currency: data?.currency ?? CurrencyEnum.Eur,
    listedOnStockExchange:
      data?.listedOnStockExchange ??
      Web_Portal_CompanyListedOnStockExchangeEnum_Enum.Unquoted,
    dealSourcing: data?.dealSourcing ?? undefined,
    dealType: data?.dealType ?? undefined,
    operationType: data?.operationType ?? undefined,
    fundStake: data?.fundStake ?? undefined,
    fundOnwershipShareHolding: data?.fundOnwershipShareHolding,
  };
};

interface UseCompanyInvestmentFormProps {
  company: NonNullable<CompanyInvestmentQuery['company']>;
  onCompleted?: () => void;
}

export const useCompanyInvestmentForm = ({
  company,
  onCompleted,
}: UseCompanyInvestmentFormProps) => {
  const { control, handleSubmit } = useForm<CompanyInvestment>({
    defaultValues: getDefaultValues(company),
    resolver: yupResolver(companyInvestmentSchema),
  });

  const [updateMutation, { loading, error }] = useUpdateCompanyMutation({
    onCompleted,
  });

  const onSubmit = (input: CompanyInvestment) => {
    return updateMutation({
      variables: {
        id: company.id,
        input: {
          ...input,
          currency: input.currency as Web_Portal_Currency_Enum,
        },
      },
    });
  };

  return { onSubmit: handleSubmit(onSubmit), loading, error, control };
};
