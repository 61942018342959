import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const CurrencyExchangeIcon = ({ primaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.81818 12C3.81818 13.9002 4.46595 15.6491 5.55271 17.0379C7.04768 15.0808 9.4057 13.8182 12.0586 13.8182C14.6771 13.8182 17.0084 15.0483 18.5059 16.9621C19.5573 15.5857 20.1818 13.8658 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12ZM12 20.1818C10.0453 20.1818 8.25062 19.4963 6.84336 18.3525C7.99413 16.7102 9.90099 15.6364 12.0587 15.6364C14.1895 15.6364 16.0758 16.6837 17.2308 18.2917C15.8127 19.4719 13.9892 20.1818 12 20.1818Z"
        fill="#363636"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.636 9.27308C15.636 11.2814 14.0079 12.9094 11.9996 12.9094C9.99134 12.9094 8.36328 11.2814 8.36328 9.27308C8.36328 7.26477 9.99134 5.63672 11.9996 5.63672C14.0079 5.63672 15.636 7.26477 15.636 9.27308ZM13.8178 9.27322C13.8178 10.2774 13.0038 11.0914 11.9996 11.0914C10.9955 11.0914 10.1815 10.2774 10.1815 9.27322C10.1815 8.26906 10.9955 7.45503 11.9996 7.45503C13.0038 7.45503 13.8178 8.26906 13.8178 9.27322Z"
        fill={primaryColor}
      />
    </SvgIcon>
  );
};
