import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { EmailTemplateOperationDropdown } from 'business/fund-manager/settings/template/components/email-template-operation-dropdown';
import { SendEmailTemplateTestForm } from 'business/fund-manager/settings/template/services/types';
import { sendEmailTemplateTestFormSchema } from 'business/fund-manager/settings/template/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useGetFundsAndLpsNameQuery,
  useSendTestEmailTemplateMutation,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { FormCard, FormInputDropdown, FormInputText } from 'ui/form';

import styles from './index.module.scss';

interface Props {
  previewValues: {
    subject: string;
    content: string;
  };
  handleClose: () => void;
}

export const FundManagerEmailTemplatePreviewForm = ({
  previewValues,
  handleClose,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: fundsAndLpsNames } = useGetFundsAndLpsNameQuery();

  const { handleSubmit, control } = useForm<SendEmailTemplateTestForm>({
    resolver: yupResolver(sendEmailTemplateTestFormSchema),
    defaultValues: {
      ...previewValues,
      email: '',
      lpId: '',
      fundId: '',
      operationId: '',
    },
  });

  const [sendTestEmailTemplate, { loading, error }] =
    useSendTestEmailTemplateMutation({
      onCompleted: () => {
        handleClose();
        enqueueSnackbar(t('successMessage.sendTestEmailTemplate'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  const onSubmit = (input: SendEmailTemplateTestForm) => {
    sendTestEmailTemplate({
      variables: {
        input,
      },
    });
  };

  return (
    <FormCard padding={3} className={styles.formCard}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} alignSelf="left">
          <FormInputText
            name="email"
            label={t('pages.fundManager.templates.form.preview.email')}
            control={control}
            required
            disabled={loading}
          />
          <FormInputDropdown
            name="fundId"
            label={t('pages.fundManager.templates.form.preview.fundName')}
            control={control}
            required
            options={
              fundsAndLpsNames?.fundNames
                ? fundsAndLpsNames.fundNames.map((fund) => ({
                    id: fund.id,
                    value: fund.name,
                  }))
                : [{ id: '', value: 'No data found' }]
            }
          />
          <FormInputDropdown
            name="lpId"
            label={t('pages.fundManager.templates.form.preview.lpName')}
            control={control}
            required
            options={
              fundsAndLpsNames?.lpNames
                ? fundsAndLpsNames.lpNames.map((lp) => ({
                    id: lp.id ?? '',
                    value: lp.lpName ?? '',
                  }))
                : [
                    {
                      id: '',
                      value: t(
                        'pages.fundManager.templates.form.preview.noData',
                      ),
                    },
                  ]
            }
          />
          <EmailTemplateOperationDropdown control={control} />
          <ActionButton variant="submit" type="submit" disabled={loading}>
            {t('pages.fundManager.templates.form.preview.send')}
          </ActionButton>
          {error ? <ErrorLabel label="errors.sendTestEmailTemplate" /> : null}
        </Stack>
      </form>
    </FormCard>
  );
};
