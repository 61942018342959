import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';
import { Typo } from 'ui/typo';

import { EmailStatusCodeEnum, OperationShareLpEmailing } from './types';

type Params = {
  t: TFunction;
  resetOpeShareLpStatus: (id: string) => void;
  resetOpeShareLpStatusLoading?: boolean;
};

export const getOperationEmailingColumnDefinition = ({
  t,
  resetOpeShareLpStatus,
  resetOpeShareLpStatusLoading = false,
}: Params): ColDef<OperationShareLpEmailing>[] => [
  {
    field: 'lpName',
    headerName: t('pages.fundManager.operation.emailing.table.lpName'),
    filter: true,
  },
  {
    field: 'shareName',
    headerName: t('pages.fundManager.operation.emailing.table.shareName'),
    filter: true,
  },
  {
    field: 'status',
    headerName: t('pages.fundManager.operation.emailing.table.status'),
    filter: true,
    cellRenderer: (params: ICellRendererParams<OperationShareLpEmailing>) => {
      if (!params.data?.statusCode) {
        return null;
      }

      return (
        <Typo color={params.data.statusCode} size="sm">
          {params.data.status}
        </Typo>
      );
    },
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  {
    field: 'emailSentAt',
    type: AgGridColumnTypesEnum.DATE,
    headerName: t('pages.fundManager.operation.emailing.table.sentAt'),
    filter: true,
  },
  {
    field: 'emailReceivers',
    headerName: t('pages.fundManager.operation.emailing.table.receivers'),
  },
  {
    headerName: t('pages.fundManager.operation.emailing.table.action'),
    type: AgGridColumnTypesEnum.INTERACTION,
    headerClass: 'ag-right-aligned-header',
    cellClass: 'ag-right-aligned-cell',
    cellRenderer: ({ data }: ICellRendererParams<OperationShareLpEmailing>) => {
      if (
        !data ||
        ![EmailStatusCodeEnum.NO_EMAIl, EmailStatusCodeEnum.ERROR].includes(
          data.statusCode,
        )
      ) {
        return null;
      }

      return (
        <ActionButton
          variant={'secondary'}
          onClick={() => resetOpeShareLpStatus(data.id)}
          loading={resetOpeShareLpStatusLoading}
        >
          {t(
            `pages.fundManager.operation.emailing.table.${
              data.shouldEmailLetter ? 'resend' : 'regenerate'
            }`,
          )}
        </ActionButton>
      );
    },
  },
];
