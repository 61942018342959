import { difference } from 'lodash';
import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useAppContext } from 'business/providers/app-provider';
import SharedRoutes from 'business/shared/router/routes';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { Roles } from 'business/user/types/user';

import { isPathLpPlatform } from './is-path-lp-platform';
import { hasRole } from './roles';

export const DEFAULT_NOT_CONNECTED_ROUTE = SharedRoutes.SignIn;
const DEFAULT_CONNECTED_ROUTE = LpPlatformRoutes.Home;
const DEFAULT_FUND_MANAGER_CONNECTED_ROUTE = FundManagerRoutes.Home;

interface ProtectedRouteProps extends PropsWithChildren {
  restrictedTo?: Roles;
  featureFlags?: FeatureFlagEnum[];
}
export const ProtectedRoute = ({
  children,
  restrictedTo,
  featureFlags = [],
}: ProtectedRouteProps) => {
  const { isConnected, user, activeFeatureFlags } = useAppContext();
  const { pathname } = useLocation();

  if (!isConnected || !user) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  if (isPathLpPlatform(pathname)) {
    // Data Manager has access to every route on LP Platform
    if (hasRole(user.roles, Roles.DATA_MANAGER)) {
      return <>{children}</>;
    }
    // Fund Manager don't have access to LP Platform
    if (hasRole(user.roles, Roles.FUND_MANAGER)) {
      return <Navigate to={DEFAULT_FUND_MANAGER_CONNECTED_ROUTE} />;
    }
  }

  const isRoleProtected = restrictedTo && !hasRole(user.roles, restrictedTo);
  const isFeatureFlagProtected =
    featureFlags.length && difference(featureFlags, activeFeatureFlags).length;

  if (isRoleProtected || isFeatureFlagProtected) {
    const defaultRoute = hasRole(user.roles, Roles.FUND_MANAGER)
      ? DEFAULT_FUND_MANAGER_CONNECTED_ROUTE
      : DEFAULT_CONNECTED_ROUTE;

    return <Navigate to={defaultRoute} />;
  }

  return <>{children}</>;
};

export const AnonymousRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { isConnected, user } = useAppContext();

  if (isConnected && user) {
    if (hasRole(user.roles, Roles.FUND_MANAGER)) {
      return <Navigate to={DEFAULT_FUND_MANAGER_CONNECTED_ROUTE} />;
    }
    return <Navigate to={DEFAULT_CONNECTED_ROUTE} />;
  }

  return <>{children}</>;
};
