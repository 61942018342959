import { PropsWithChildren } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { ContactTypeEnum } from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import { FormModalContainer } from 'ui/form-modal-container';
import { LpContactFormPart } from 'ui/lp-contact-form-part';
import { ContactFormContextEnum } from 'ui/types';

interface Props extends PropsWithChildren {
  context: ContactFormContextEnum;
  onClose: () => void;
  onSubmit: () => void;
  error?: string;
  isSubmitting?: boolean;
  typeFieldName?: string;
}

export const ModalLpContactForm = ({
  onClose,
  onSubmit,
  error,
  context,
  children,
  isSubmitting,
  typeFieldName = 'type',
}: Props) => {
  const { t } = useTranslation();
  const type = useWatch({ name: typeFieldName }) as ContactTypeEnum | undefined;

  return (
    <FormModalContainer
      title={t('pages.contact.form.formTitle', {
        context,
      })}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    >
      {error ? <ErrorLabel label={error} /> : null}
      {children}
      <LpContactFormPart name="contact" type={type} />
    </FormModalContainer>
  );
};
