import { Divider, Stack } from '@mui/material';
import { TFunction, useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { CurrencyEnum } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatAsDate } from 'technical/date';
import { Amount } from 'ui/amount';
import { DownloadDocumentButton } from 'ui/download-document-button/document-download-button';
import Link from 'ui/link';
import { Typo } from 'ui/typo';

import styles from './subscription-history-item.module.scss';
import { SubscriptionWithShareTransferOtherLp } from './types';

const getSubscriptionDocuments = ({
  subscription,
  t,
}: {
  subscription: SubscriptionWithShareTransferOtherLp;
  t: TFunction;
}): { id: string; label?: string }[] => {
  if (!subscription.shareTransfer) {
    return subscription.subscriptionDocument.map(({ documentId, type }) => ({
      id: documentId,
      label: t(`pages.fundManager.subscriptionDocument.type.${type}`, {
        defaultValue: type,
      }),
    }));
  }
  if (subscription.shareTransfer?.shareTransferDocument?.[0]) {
    return [
      { id: subscription.shareTransfer.shareTransferDocument[0].documentId },
    ];
  }

  return [];
};

type Props = {
  subscription: SubscriptionWithShareTransferOtherLp;
  onDocumentDownload: (id: string) => Promise<string | undefined>;
};

type ItemInfoLink = {
  type: 'link';
  link: string;
  text: string;
};
type ItemInfoNumericAmount = {
  type: 'amountWith10X8divider';
  numericAmount: number;
  currency: CurrencyEnum;
};

type ItemInfoText = {
  type: 'text';
  text: string;
};

type ItemInfoType = ItemInfoText | ItemInfoLink | ItemInfoNumericAmount;

const ItemInfo = ({ title, value }: { title: string; value: ItemInfoType }) => {
  return (
    <Stack
      flexDirection={'row'}
      className={styles.subscriptionHistoryItemInfo}
      justifyContent="space-between"
    >
      <Typo bold>{title}</Typo>

      {value.type === AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER ? (
        <Amount
          value={value.numericAmount}
          currency={value.currency}
          size="m"
          ellipsis
        />
      ) : value.type === 'link' ? (
        <Link to={value.link} className={styles.itemValue}>
          {value.text}
        </Link>
      ) : (
        <Typo className={styles.itemValue}>{value.text}</Typo>
      )}
    </Stack>
  );
};

const ShareTransferLpName = ({
  lp,
}: {
  lp: { id: string; lpName: string; isSeller: boolean };
}) => {
  const { t } = useTranslation();

  return (
    <Stack className={styles.subscriptionHistoryItemInfo}>
      <Typo bold>
        {lp.isSeller
          ? t('pages.fundManager.subscription.history.transferredFrom')
          : t('pages.fundManager.subscription.history.transferredTo')}
      </Typo>

      <Link
        className={styles.shareTransferLpName}
        to={FundManagerRoutes.LpId.replace(':lpId', lp.id)}
      >
        {lp?.lpName}
      </Link>
    </Stack>
  );
};

export const SubscriptionHistoryItem = ({
  subscription,
  onDocumentDownload,
}: Props) => {
  const { t } = useTranslation();
  const isPurchase = subscription.numericAmount > 0;
  const documents = getSubscriptionDocuments({ subscription, t });

  return (
    <Stack className={styles.subscriptionHistoryItem}>
      <Stack padding={3} className={styles.subscriptionHistoryItem}>
        <Stack spacing={3}>
          <Stack flexDirection={'row'} justifyContent="space-between">
            <Stack
              flexDirection={'column'}
              className={styles.subscriptionHistoryItemInfo}
            >
              <ItemInfo
                title={t('pages.fundManager.subscription.history.date')}
                value={{
                  type: 'text',
                  text: formatAsDate(subscription.date),
                }}
              />
              <ItemInfo
                title={t('pages.fundManager.subscription.history.fund')}
                value={{
                  type: 'link',
                  text: subscription.share.fund.name,
                  link: FundManagerRoutes.FundIdSubscription.replace(
                    ':fundId',
                    subscription.share.fund.id,
                  ),
                }}
              />
              <ItemInfo
                title={t('pages.fundManager.subscription.history.share')}
                value={{ type: 'text', text: subscription.share.name }}
              />
              {subscription.shareTransfer ? (
                <ItemInfo
                  title={
                    isPurchase
                      ? t(
                          'pages.fundManager.subscription.history.purchasePrice',
                        )
                      : t('pages.fundManager.subscription.history.salePrice')
                  }
                  value={{
                    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
                    numericAmount: subscription.shareTransfer.numericTotalPrice,
                    currency: subscription.share.fund.currency,
                  }}
                />
              ) : null}
            </Stack>
            <Stack className={styles.subscriptionHistoryItemInfo} spacing={1}>
              <Typo bold>
                {t('pages.fundManager.subscription.history.documentation')}
              </Typo>
              {documents.length ? (
                documents.map((doc) => (
                  <DownloadDocumentButton
                    key={doc.id}
                    documentId={doc.id}
                    label={doc?.label}
                    onDocumentDownload={onDocumentDownload}
                  />
                ))
              ) : (
                <Typo>
                  {t('pages.fundManager.subscription.history.noDocumentation')}
                </Typo>
              )}
            </Stack>
          </Stack>
          <Stack flexDirection={'row'} justifyContent="space-between">
            <Stack className={styles.subscriptionHistoryItemInfo}>
              <Typo bold>
                {t('pages.fundManager.subscription.history.subscription')}
              </Typo>
              <Amount
                value={subscription.numericAmount}
                currency={subscription.share.fund.currency}
                size="2xl"
                bold
                ellipsis
                color={isPurchase ? 'primary' : 'secondary'}
              />
            </Stack>
            {subscription.shareTransfer?.shareTransferOtherLp ? (
              <ShareTransferLpName
                lp={subscription.shareTransfer.shareTransferOtherLp}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};
