import { ContactWithId } from 'business/shared/services/contact/types';
import { ContactTypeEnum, useCompanyContactsQuery } from 'generated/graphql';

export const useCompanyContacts = ({ companyId }: { companyId: string }) => {
  const { loading, error, data, refetch } = useCompanyContactsQuery({
    variables: {
      companyId,
    },
  });

  const contacts: ContactWithId[] =
    data?.companyContacts?.map((companyContact) => {
      const { contact } = companyContact;
      return {
        type: ContactTypeEnum.Individual,
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        title: contact.title ?? undefined,
        phoneNumber1: contact.phoneNumber1 ?? undefined,
        phoneNumber2: contact.phoneNumber2 ?? undefined,
        companyName: contact.companyName ?? undefined,
        jobTitle: contact.jobTitle ?? undefined,
        publicationStatus: contact.publicationStatus,
        isLegalRepresentative: companyContact.isLegalRepresentative,
        canDelete: !companyContact.isLegalRepresentative,
        logo: contact.logo ?? undefined,
        address: {
          id: contact.address?.id ?? undefined,
          streetLine: contact.address?.streetLine ?? undefined,
          streetLine2: contact.address?.streetLine2 ?? undefined,
          city: contact.address?.city ?? undefined,
          country: contact.address?.country ?? undefined,
          zipCode: contact.address?.zipCode ?? undefined,
        },
      };
    }) ?? [];

  return { loading, error, refetch, contacts };
};
