import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { SelectInput } from 'ui/form/select-input';
import { Option } from 'ui/types';

import { legalFormsByCountryCode } from './legal-forms-by-country-code';

interface SelectLegalFormInputProps
  extends Omit<
    ComponentProps<typeof SelectInput>,
    'options' | 'onChange' | 'label' | 'placeholder'
  > {
  country: string;
}

export const SelectLegalFormInput = ({
  country,
  disabled,
  ...rest
}: SelectLegalFormInputProps) => {
  const { t } = useTranslation();

  const options: Option[] = (legalFormsByCountryCode?.[country] ?? []).sort(
    (a, b) => {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    },
  );

  return (
    <SelectInput
      {...rest}
      label={t('pages.fundManager.lp.legalForm.label')}
      placeholder={t('pages.fundManager.lp.legalForm.placeholder')}
      options={options}
      disabled={options.length === 0 || disabled}
    />
  );
};
