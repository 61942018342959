import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const PendingIcon = ({ primaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4V2H18V4H17V7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7V4H6ZM12 12C14.7614 12 17 14.2386 17 17V20H18V22H6V20H7V17C7 14.2386 9.23858 12 12 12ZM15 4H9V7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7V4ZM9 20V17C9 15.3431 10.3431 14 12 14C13.6569 14 15 15.3431 15 17V20H9ZM11 6H13V7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7V6Z"
        fill={primaryColor}
      />
    </SvgIcon>
  );
};
