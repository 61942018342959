import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { useLanguageContext } from 'business/providers/language-provider';
import { CompaniesQuery, PublicationStatus } from 'generated/graphql';
import { getRegionNameByRegionCode } from 'technical/country-utils';
import { Card } from 'ui/card';
import { CompanyCard } from 'ui/company-card';
import { getNaceValue } from 'ui/nace-code';

interface CompanyListContentProps {
  editPath: string;
  companies: CompaniesQuery['companies'];
}

export const CompanyList = ({
  editPath,
  companies,
}: CompanyListContentProps) => {
  const { language } = useLanguageContext();
  const { t } = useTranslation();
  if (!companies.length) {
    return (
      <Card>
        <Stack alignItems="center" padding={5}>
          <Typography variant="body2">{t('common.noData')}</Typography>
        </Stack>
      </Card>
    );
  }

  return (
    <Stack direction="row" gap={3} flexWrap="wrap">
      {companies.map((company) => (
        <CompanyCard
          to={editPath.replace(':companyId', company.id)}
          key={company.id}
          name={company.name}
          imageLink={company?.logoUrl}
          status={t('pages.fundManager.portfolio.company.status', {
            context:
              company.publicationStatus !== PublicationStatus.Draft
                ? company.status
                : PublicationStatus.Draft,
          })}
          subtitle={[
            getNaceValue({ value: company.codeNace, language })?.sector ?? '',
            getRegionNameByRegionCode({ code: company.geography, language }),
          ]}
          // complementaryInfo={[
          //   {
          //     label: t('pages.fundManager.portfolio.company.turnover'),
          //   },
          //   { label: t('pages.fundManager.portfolio.company.headcount') },
          // ]}
        />
      ))}
    </Stack>
  );
};
