import { ColDef } from 'ag-grid-community';
import { useTranslation } from 'translations/hook';

import { getOperationShareLpDisplayValues } from 'business/fund-manager/operation/services/get-operation-share-lp-display-values';
import { getOperationShareRecapColumnDefinition } from 'business/fund-manager/operation/services/get-share-recap-column-definition';
import {
  OperationSharesLp,
  OperationSharesLpDisplayValues,
} from 'business/fund-manager/operation/services/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { useUpdatePremiumValueMutation } from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';

interface Props {
  data: OperationSharesLp[];
  onPremiumUpdateCompleted?: () => void;
}

export const OperationShareRecapTable = ({
  data,
  onPremiumUpdateCompleted,
}: Props) => {
  const { t } = useTranslation();
  const operationShareLpDisplayValues: OperationSharesLpDisplayValues =
    getOperationShareLpDisplayValues(data);
  const { currency } = useFund();

  const [updatePremiumValue] = useUpdatePremiumValueMutation();

  const onPremiumUpdate = (newRow: { id: string; premium: number }) => {
    updatePremiumValue({
      onCompleted: () => onPremiumUpdateCompleted?.(),
      variables: {
        input: {
          operationShareLpId: newRow.id,
          premium: newRow.premium,
        },
      },
    });
  };

  const columnDefs: ColDef<OperationSharesLp>[] =
    getOperationShareRecapColumnDefinition(t, {
      ...operationShareLpDisplayValues,
      onPremiumUpdate,
      currency,
    });

  return (
    <AlternativeTable<OperationSharesLp>
      columnDefs={columnDefs}
      rowData={data}
      stopEditingWhenCellsLoseFocus
      context={{ currency }}
      fileName="share-transfers-to-validate"
      domLayout="autoHeight"
      enableCharts={false}
      enableRowGroup={false}
      enablePivot={false}
    />
  );
};
