import { useRef } from 'react';

import config from 'config';

// eslint-disable-next-line no-console
const initialConsoleError = console.error;
let prevConsoleError: typeof console.error | undefined = undefined;

export function useStartSilenceAGGridLicenseLogsOnDev() {
  const alreadyMonkeyPatchedRef = useRef(false);
  const alreadyMonkeyPatched = alreadyMonkeyPatchedRef.current;
  if (
    !alreadyMonkeyPatched &&
    config.isDev &&
    prevConsoleError !== initialConsoleError
  ) {
    // eslint-disable-next-line no-console
    prevConsoleError = initialConsoleError;
    // eslint-disable-next-line no-console
    console.error = (...args) => {
      if (
        prevConsoleError &&
        typeof args[0] === 'string' &&
        !args[0].startsWith('* ') &&
        !args[0].startsWith('**')
      ) {
        prevConsoleError(...args);
      }
    };
    alreadyMonkeyPatchedRef.current = true;
  }
}

export function stopSilenceAGGridLicenseLogsOnDev() {
  if (config.isDev && prevConsoleError) {
    // eslint-disable-next-line no-console
    console.error = prevConsoleError;
    prevConsoleError = undefined;
    // console.error('STOP SILENCE AG GRID LICENSE LOGS');
  }
}
