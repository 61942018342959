// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CreationPageContainer } from 'business/fund-manager/fund/components/creation-page-container';
import { SharesManager } from 'business/fund-manager/fund/components/shares-manager';
import { FundCreationStepEnum } from 'business/fund-manager/fund/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useCompleteFundMutation, useSharesQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundCreationShareStep = () => {
  const { fundId = '' } = useParams();
  const { data, loading, error, refetch } = useSharesQuery({
    variables: {
      input: {
        fundId,
      },
    },
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [publishFund] = useCompleteFundMutation({
    variables: {
      input: { id: fundId },
    },
    onCompleted: () => {
      navigate(FundManagerRoutes.Fund);
      enqueueSnackbar(t('successMessage.createFund'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  return (
    <CreationPageContainer
      activeStep={FundCreationStepEnum.Share}
      previousPath={FundManagerRoutes.FundCreateIdBank.replace(
        ':fundId',
        fundId,
      )}
      onSubmit={data && data.shares.length > 0 ? publishFund : undefined}
    >
      {loading || error || !data ? (
        <QueryStateDisplay loading={loading} error={error} />
      ) : (
        <SharesManager data={data} fundId={fundId} onCompleted={refetch} />
      )}
    </CreationPageContainer>
  );
};
