import { Chip, Divider, Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import { FormProvider, useController, useWatch } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { FundReportingCommitmentTable } from 'business/fund-manager/fund/components/fund-reporting-commitment-table/fund-reporting-commitment-table';
import { FundReportingShareInput } from 'business/fund-manager/fund/components/fund-reporting-share-input/fund-reporting-share-input';
import { FundReportingValuationDateInput } from 'business/fund-manager/fund/components/fund-reporting-valuation-date-input/fund-reporting-valuation-date-input';
import { getFundReportingContactsColumnDefinition } from 'business/fund-manager/fund/services/get-fund-reporting-contacts-column-definition';
import { getFundReportingTypeOptions } from 'business/fund-manager/fund/services/get-fund-reporting-type-options';
import { useFundReportingCreationForm } from 'business/fund-manager/fund/services/hooks/use-fund-reporting-creation-form';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  FundReportingTypeEnum,
  GetFundReportingContactsQuery,
  useGetFundReportingContactsQuery,
} from 'generated/graphql';
import { formatNumber } from 'technical/currency/formatters';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { Card } from 'ui/card';
import { CustomCkeditorInput } from 'ui/custom-ckeditor';
import { FormCard, FormInputFiles, FormInputText, SelectInput } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SectionTitle } from 'ui/section-title';
import { Typo } from 'ui/typo';

type AlternativeTableType =
  GetFundReportingContactsQuery['fundReportingContacts'][number] & {
    fullName: string;
    lpName: string;
  };

interface FundReportingContactsProps {
  type?: FundReportingTypeEnum;
  fundId: string;
}
const FundReportingContacts = ({
  type,
  fundId,
}: FundReportingContactsProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<AlternativeTableType>>(null);
  const onDownload = () => {
    gridRef?.current?.api.exportDataAsExcel();
  };

  const skip = !type;
  const { data, error, loading } = useGetFundReportingContactsQuery({
    skip,
    variables: {
      input: {
        fundId,
        type: type!,
      },
    },
  });

  if (!skip && error) {
    return <QueryStateDisplay error={error} />;
  }

  const rows =
    data?.fundReportingContacts?.map((contact) => {
      return {
        ...contact,
        fullName: contact.firstName + ' ' + contact.lastName,
        lpName: contact.lpContact.lpView.lpName,
      };
    }) ?? [];

  const headers = getFundReportingContactsColumnDefinition(t);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack overflow="hidden">
          {type ? (
            <Chip
              variant="outlined"
              label={
                <Stack direction="row" spacing={0.5}>
                  <Stack overflow="hidden">
                    <Typo size="sm" ellipsis>
                      {t(`pages.fundManager.reporting.type.${type}`)}
                    </Typo>
                  </Stack>
                  <Typo size="sm">
                    ({formatNumber(rows.length, { precision: 0 })})
                  </Typo>
                </Stack>
              }
            />
          ) : undefined}
        </Stack>
        <Stack flexGrow={1}></Stack>

        <Stack flexShrink={0}>
          <ActionButton
            variant="primary"
            disabled={loading || skip}
            onClick={onDownload}
            size="small"
          >
            {t('pages.fundManager.reporting.downloadList')}
          </ActionButton>
        </Stack>
      </Stack>
      <AlternativeTable<AlternativeTableType>
        fileName="fund-reporting-contacts-list"
        rowData={rows}
        gridRef={gridRef}
        columnDefs={headers}
        pagination
        paginationPageSize={10}
        domLayout="autoHeight"
        loading={loading}
        displaySidebar
      />
    </>
  );
};

export const FundReportingCreation = () => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();

  const {
    methods,
    onSubmit: handleSubmit,
    onFilesUpload,
  } = useFundReportingCreationForm(fundId);

  const type = useWatch({
    name: 'type',
    control: methods.control,
  });

  const {
    fieldState: { error: contentError },
  } = useController({ name: 'content', control: methods.control });

  return (
    <FormProvider {...methods}>
      <Stack spacing={2}>
        <FormCard spacing={2}>
          <SectionTitle title={t('pages.fundManager.reporting.settings')} />
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} flex={2}>
              <SelectInput
                name="type"
                label={t('pages.document.table.type')}
                options={getFundReportingTypeOptions(t)}
                size="small"
                required
                fullWidth
              />
              <FundReportingShareInput fundId={fundId} />
              <FundReportingValuationDateInput fundId={fundId} />
            </Stack>

            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              spacing={2}
              flex={2}
            >
              <Stack>
                <ActionButton
                  variant="secondary"
                  to={FundManagerRoutes.FundId.replace(':fundId', fundId)}
                  component={Link}
                >
                  {t('common.actions.cancel')}
                </ActionButton>
              </Stack>
              <Stack>
                <ActionButton variant="submit" onClick={handleSubmit}>
                  {t('pages.fundManager.reporting.launchEmailing')}
                </ActionButton>
              </Stack>
            </Stack>
          </Stack>
        </FormCard>

        <Stack direction="row" spacing={2}>
          <Card sx={{ width: '50%' }}>
            <Stack spacing={2} padding={2}>
              <SectionTitle
                title={t('pages.fundManager.templates.form.content')}
              />
              <Stack spacing={3}>
                <FormInputText
                  name="subject"
                  label={t('pages.fundManager.templates.form.subject')}
                  control={methods.control}
                  required
                />
              </Stack>
              <Stack>
                <CustomCkeditorInput
                  defaultData={''}
                  label={t('pages.fundManager.templates.form.content')}
                  onChange={(_event, editor) => {
                    // Because of the way CKEDITOR works, we cannot use the onChange methods directly here
                    // We register the field line 48 and we then have to manually set the value and trigger the verification
                    const ckData = editor.getData();
                    methods.setValue('content', ckData);
                    methods.trigger('content');
                  }}
                  minHeight={300}
                  error={contentError?.message}
                />
              </Stack>
              <Divider />
              <Stack alignItems="center" spacing={2}>
                <FormInputFiles
                  name="files"
                  onFilesUpload={onFilesUpload}
                  limit={1}
                  label={t('pages.fundManager.reporting.attachment')}
                />
              </Stack>
            </Stack>
          </Card>

          <Card sx={{ width: '50%' }}>
            <Stack padding={2} spacing={2}>
              <SectionTitle title={t('pages.fundManager.reporting.contacts')} />
              {type === FundReportingTypeEnum.CapitalAccountStatement ? (
                <FundReportingCommitmentTable fundId={fundId} />
              ) : (
                <FundReportingContacts type={type} fundId={fundId} />
              )}
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
