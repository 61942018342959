// eslint-disable-next-line import/no-extraneous-dependencies
import { CountryCode } from 'libphonenumber-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MuiTelInput, MuiTelInputInfo, matchIsValidTel } from 'mui-tel-input';
import { useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FormInputProps, FormInputsType } from 'ui/form/form-input-props';
import { TextInputProps } from 'ui/text-input';

export type FormInputPhoneProps<T extends FormInputsType> = FormInputProps<T> &
  TextInputProps & {
    required?: boolean;
    readOnly?: boolean;
    defaultCountry?: CountryCode;
    onChange?:
      | ((value: string, info: MuiTelInputInfo) => void)
      | ((value: string, info: MuiTelInputInfo) => void)
      | undefined;
  };

export const FormInputPhone = <T extends FormInputsType>({
  name,
  control,
  rules = { validate: matchIsValidTel },
  required,
  readOnly,
  onChange,
  disabled,
  ...rest
}: FormInputPhoneProps<T>) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController<{ value: string }>({
    control,
    name: name as 'value',
    rules: { ...rules, required },
  });

  return (
    <MuiTelInput
      {...rest}
      name={field.name}
      value={field.value ?? ''}
      onChange={onChange ?? field.onChange}
      inputRef={field.ref}
      onWheel={(e) => {
        const el = e.target as HTMLElement;
        return el.blur(); // disable scrolling
      }}
      required={required}
      disabled={disabled || isSubmitting}
      error={!!error}
      InputProps={{ readOnly }}
      helperText={error?.message ? t(error.message) : undefined}
      defaultCountry="FR"
      disableFormatting
    />
  );
};
