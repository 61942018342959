import { Stack, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { EscrowAccountPositions } from 'business/fund-manager/lp/components/escrow-account-positions';
import { EscrowCashflowsHistory } from 'business/fund-manager/lp/components/escrow-cashflows-history';
import { FinancialFlowNavBar } from 'business/fund-manager/lp/components/financial-flow-nav-bar';
import {
  SubscribedFundFilter,
  SubscribedFundFilterForm,
} from 'business/fund-manager/lp/components/subscribed-fund-filter';
import { FinancialFlowNavBarTabEnum } from 'business/fund-manager/lp/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { Card } from 'ui/card';

const CustomCard = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  const palette = usePalette();
  return (
    <Card>
      <Stack padding={2}>
        <Typography variant="h6">{title}</Typography>
        <Stack
          overflow="hidden"
          sx={{
            border: 1,
            borderColor: palette.secondary[500],
            borderRadius: 5,
          }}
        >
          <Stack overflow="auto">{children}</Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export const LpEscrowAccount = () => {
  const { lpId = '' } = useParams();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<
    SubscribedFundFilterForm | undefined
  >();

  return (
    <FinancialFlowNavBar activeTab={FinancialFlowNavBarTabEnum.escrowAccount}>
      <Stack spacing={3}>
        <SubscribedFundFilter
          lpId={lpId}
          onSubmit={setFilters}
          onReset={() => setFilters(undefined)}
        />
        <CustomCard title={t('pages.fundManager.lps.escrowAccount.positions')}>
          <EscrowAccountPositions {...filters} lpId={lpId} />
        </CustomCard>
        <CustomCard title={t('pages.fundManager.lps.escrowAccount.history')}>
          <EscrowCashflowsHistory {...filters} lpId={lpId} />
        </CustomCard>
      </Stack>
    </FinancialFlowNavBar>
  );
};
