import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { LpDocumentTypesQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';

export const getLpDocumentTypeColumnDefinition = ({
  t,
  handleOpenModal,
}: {
  t: TFunction;
  handleOpenModal: (
    lpDocumentType?: LpDocumentTypesQuery['lpDocumentTypes'][number],
  ) => void;
}): ColDef<LpDocumentTypesQuery['lpDocumentTypes'][number]>[] => {
  return [
    {
      field: 'name',
      headerName: t('pages.fundManager.settings.documentType.headers.name'),
      valueFormatter: ({ value }) =>
        t(`pages.fundManager.settings.lpDocumentType.values.${value}`, value),
    },
    {
      field: 'isMandatoryIndividual',
      headerName: t(
        'pages.fundManager.settings.lpDocumentType.headers.isMandatoryIndividual',
      ),
    },
    {
      field: 'isMandatoryLegalEntity',
      headerName: t(
        'pages.fundManager.settings.lpDocumentType.headers.isMandatoryLegalEntity',
      ),
    },
    {
      field: 'hasExpirationDate',
      headerName: t(
        'pages.fundManager.settings.lpDocumentType.headers.hasExpirationDate',
      ),
    },
    {
      headerName: t('pages.fundManager.settings.lpDocumentType.actions.edit'),
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({
        data,
      }: ICellRendererParams<
        LpDocumentTypesQuery['lpDocumentTypes'][number]
      >) => {
        if (!data) {
          return null;
        }
        return (
          <ActionButton
            variant={'submit'}
            onClick={() => handleOpenModal(data)}
          >
            {t('pages.fundManager.settings.documentType.actions.edit')}
          </ActionButton>
        );
      },
    },
  ];
};
