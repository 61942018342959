import { Stack } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { DisplayFundIrr } from 'business/fund-manager/fund/components/display-fund-irr';
import { FundShareValuationShareSelector } from 'business/fund-manager/fund/components/fund-share-valuation-share-selector';
import { getFundShareValuationColumnDefinition } from 'business/fund-manager/fund/services/get-fund-share-valuation-column-definition';
import { getFundShareValuationGraphData } from 'business/fund-manager/fund/services/get-fund-share-valuation-graph-data';
import { setDefaultShareFilter } from 'business/fund-manager/fund/services/set-default-share-filter';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import GraphsPanel from 'business/lp-platform/valuations/components/graphs-panel';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { getAggregagtedValuationGraphData } from 'business/shared/services/get-aggregated-valuation-graph-data';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  GetFundShareValuationQuery,
  useGetFundShareValuationQuery,
} from 'generated/graphql';
import { formatRatioToPercentage } from 'technical/currency/formatters';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { Card } from 'ui/card';
import { InfoCard } from 'ui/info-card';
import { QueryStateDisplay } from 'ui/query-state-display';
import SynthesisItem from 'ui/synthesis-item';

import styles from './index.module.scss';

interface NewValuationButtonProps extends PropsWithChildren {
  fundId: string;
}

const ValuationButton = ({ fundId, children }: NewValuationButtonProps) => {
  return (
    <ActionButton
      variant="submit"
      size="medium"
      component={Link}
      to={FundManagerRoutes.FundIdShareValuationCreate.replace(
        ':fundId',
        fundId,
      )}
    >
      {children}
    </ActionButton>
  );
};

export const FundShareValuationPage = () => {
  const { t } = useTranslation();

  const displayValuationSynthesis = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_VALUATION_SYNTHESIS,
  );

  const { fundId = '' } = useParams();
  const [shareFilter, setShareFilter] = useState<string>('');

  const { currency } = useFund();
  const displayShareValuations = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_SHARE_VALUATION,
  );

  const { data, error, loading } = useGetFundShareValuationQuery({
    variables: {
      input: {
        id: fundId,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (
    loading ||
    error ||
    !data?.fundShareValuations ||
    !data?.availableShares
  ) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  const options = data.availableShares;

  setDefaultShareFilter({ options, shareFilter, setShareFilter });

  const filteredValuations = shareFilter
    ? data.fundShareValuations
        .filter(
          (valuation): valuation is Exclude<typeof valuation, null> =>
            !!valuation,
        )
        .filter(({ share }) => share.id === shareFilter)
    : data.fundShareValuations;

  if (!displayShareValuations) {
    return null;
  }

  const headers = getFundShareValuationColumnDefinition({
    t,
    latestValuation: filteredValuations.sort((a, b) => b.date - a.date)[0],
    fundId,
  });

  const graphData = getFundShareValuationGraphData(filteredValuations);

  const { aggregatedData, lastPoint } =
    graphData.length > 0
      ? getAggregagtedValuationGraphData(graphData)
      : { aggregatedData: null, lastPoint: null };

  return (
    <Stack spacing={2} alignItems="stretch">
      <Card>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
          spacing={2}
        >
          <Stack>
            <FundShareValuationShareSelector
              name="fundShareValuationShareSelector"
              onChange={(shareId: string) => {
                setShareFilter(shareId);
              }}
              options={options}
              value={shareFilter}
              fullWidth
              size="small"
            />
          </Stack>

          {options.length ? (
            <Stack>
              <ValuationButton fundId={fundId}>
                {t('pages.fundManager.fundShareValuation.form.createNew')}
              </ValuationButton>
            </Stack>
          ) : null}
        </Stack>
      </Card>
      {displayValuationSynthesis && !!lastPoint?.tvpi ? (
        <Stack direction="row" padding={1} spacing={2}>
          <InfoCard className={styles.infoCard}>
            <SynthesisItem
              value={`${lastPoint.dpi.toFixed(2)} x`}
              description="DPI"
              color="secondary"
            ></SynthesisItem>
          </InfoCard>
          <InfoCard className={styles.infoCard}>
            <SynthesisItem
              value={`${lastPoint.tvpi.toFixed(2)} x`}
              description="TVPI"
            ></SynthesisItem>
          </InfoCard>
          <DisplayFundIrr
            selectedShareId={shareFilter}
            data={data.fundNetIrrByShare}
            className={styles.infoCard}
          />
          {data.fundGrossIrr ? (
            <InfoCard className={styles.infoCard}>
              <SynthesisItem
                value={formatRatioToPercentage(data.fundGrossIrr.grossIrr, {
                  precision: 2,
                })}
                description={t('pages.fundManager.fundShareValuation.grossIrr')}
              />
            </InfoCard>
          ) : (
            <></>
          )}
        </Stack>
      ) : null}
      <AlternativeTable<
        GetFundShareValuationQuery['fundShareValuations'][number]
      >
        fileName="fund-share-valuation"
        rowData={filteredValuations}
        columnDefs={headers}
        domLayout="autoHeight"
        loading={loading}
        context={{ currency: currency }}
        pagination={true}
        paginationPageSize={20}
      />
      {aggregatedData && <GraphsPanel data={aggregatedData} />}
    </Stack>
  );
};
