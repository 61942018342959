import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { DeclineConfirmation } from 'business/fund-manager/lp/services/types';
import { declineConfirmationSchema } from 'business/fund-manager/lp/services/validation';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';
import { FormInputText } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';

import styles from './index.module.scss';

interface DeclineConfirmationModalProps {
  open?: boolean;
  onCancel: () => void;
  onSubmit: (args: DeclineConfirmation) => void;
  title: string;
}

export const DeclineConfirmationModal = ({
  open = false,
  onCancel,
  onSubmit,
  title,
}: DeclineConfirmationModalProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<DeclineConfirmation>({
    resolver: yupResolver(declineConfirmationSchema),
  });

  return (
    <CustomModal
      open={open}
      handleClose={onCancel}
      component={
        <div className={styles.modal}>
          <FormModalContainer title={title} onClose={onCancel}>
            <FormInputText
              label={t('pages.fundManager.lpValidation.declineConfirm.comment')}
              fullWidth
              required
              name="comment"
              control={control}
              multiline
              rows={4}
            />
            <ActionButton
              variant="danger"
              loading={formState.isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {t('common.actions.decline')}
            </ActionButton>
          </FormModalContainer>
        </div>
      }
    />
  );
};
