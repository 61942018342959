import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { FileIcon } from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';

interface Props {
  title: string;
  data: { afterDateDocumentCount: number; totalDocumentCount: number };
  path: string;
}

export default function DocumentationCard({
  title,
  data: { afterDateDocumentCount, totalDocumentCount },
  path,
}: Props) {
  const { t } = useTranslation();
  const palette = usePalette();
  return (
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>
      <InfoCard.Items>
        <InfoItem
          value={afterDateDocumentCount.toString()}
          description={t(
            'pages.dashboard.cards.documentation.afterDateDocumentCount',
          )}
          imageSource={<FileIcon />}
          size="dense"
          sx={{
            color: palette.secondaryIconColor,
            bgcolor: palette.secondaryBackgroundIconColor,
          }}
        />
        <InfoItem
          value={totalDocumentCount.toString()}
          description={t(
            'pages.dashboard.cards.documentation.totalDocumentCount',
          )}
          imageSource={<FileIcon />}
          size="dense"
          sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}
        />
      </InfoCard.Items>
    </InfoCard>
  );
}
