import { compareAsc } from 'date-fns';

import {
  TypedOperationDeprecated,
  ValuationForIrrCalculation,
} from 'business/lp-platform/valuations/services/types';
import { xirr } from 'business/lp-platform/valuations/services/xirr';
import { ValuationCashflowTypeEnum } from 'generated/graphql';

const getUniqueShareIds = (valuations: ValuationForIrrCalculation[]) => {
  return new Set(valuations.map((x) => x.shareId));
};

const getMostRecentValuation = (
  shareId: string,
  valuations: ValuationForIrrCalculation[],
) => {
  const filtered = valuations.filter((x) => x.shareId === shareId);
  if (!filtered.length) {
    return null;
  }

  return filtered.reduce((a, b) =>
    new Date(a.valuationDate) > new Date(b.valuationDate) ? a : b,
  );
};

const getMostRecentsValuations = (
  ids: string[],
  valuations: ValuationForIrrCalculation[],
) => {
  const mostRecents: ValuationForIrrCalculation[] = [];
  ids.forEach((id) => {
    const keptValuation = getMostRecentValuation(id, valuations);
    if (keptValuation) {
      mostRecents.push(keptValuation);
    }
  });
  return mostRecents;
};

const getOperation = (
  valuation: ValuationForIrrCalculation,
): TypedOperationDeprecated[] => {
  const operations: TypedOperationDeprecated[] = [];
  valuation.share.operations.forEach((x) =>
    operations.push({
      date: new Date(x.date),
      amount: x.amount,
      type: x.type as ValuationCashflowTypeEnum,
    }),
  );

  operations.push({
    date: new Date(valuation.valuationDate),
    amount: valuation.residualValue,
    type: ValuationCashflowTypeEnum.ResidualValue,
  });

  return operations;
};

const getOperationsFromValuations = (
  valuations: ValuationForIrrCalculation[],
) => {
  const operations: TypedOperationDeprecated[][] = [];
  valuations.forEach((valuation) => operations.push(getOperation(valuation)));
  return operations.flat();
};

const setAmountPerType = (operation: TypedOperationDeprecated): number => {
  return operation.type === ValuationCashflowTypeEnum.Drawdown
    ? -operation.amount
    : operation.amount;
};

const getIrrFlux = (typedOperations: TypedOperationDeprecated[]) => {
  const dates: Date[] = [];
  const values: number[] = [];

  typedOperations.sort((a, b) => compareAsc(a.date, b.date));

  typedOperations.forEach((operation) => {
    if (
      !(
        operation.type === ValuationCashflowTypeEnum.ResidualValue &&
        operation.amount === 0
      )
    ) {
      dates.push(operation.date);
      values.push(setAmountPerType(operation));
    }
  });

  return { dates, values };
};

export const calculateIrr = (valuations: ValuationForIrrCalculation[]) => {
  if (valuations.length === 0) {
    return NaN;
  }

  const uniqueIds = getUniqueShareIds(valuations);
  const mostRecentValuations = getMostRecentsValuations(
    Array.from(uniqueIds.values()),
    valuations,
  );

  const flattenedOperations = getOperationsFromValuations(mostRecentValuations);
  const irrFlux = getIrrFlux(flattenedOperations);

  const irr = xirr(irrFlux.values, irrFlux.dates);
  return irr;
};
