import _ from 'lodash';

import { CustomAnswer } from 'business/shared/lp-creation-module/services/types';
import { Condition, LpCustomQuestionTypeEnum } from 'generated/graphql';

export const shouldDisplayFormPart = (
  condition: Condition,
  customAnswers: CustomAnswer[],
) => {
  const answer = customAnswers.find(
    ({ questionId }) => questionId === condition.questionId,
  );

  if (!answer) {
    return false;
  }

  if (answer.type === LpCustomQuestionTypeEnum.SelectMultiple) {
    return _.intersection(condition.values, answer.values).length > 0;
  }
  return condition.values.includes(answer.value!);
};
