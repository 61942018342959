import { CircularProgress, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { PaletteVarKey } from 'business/providers/theme/services/types';
import { Button, ButtonProps } from 'ui/button';

import styles from './index.module.scss';

export type ActionButtonVariant =
  | 'primary'
  | 'secondary'
  | 'submit'
  | 'danger'
  | 'edit';

type ColorVariant = {
  color: PaletteVarKey;
  bgColor: PaletteVarKey;
  hoverColor: PaletteVarKey;
  hoverBgColor: PaletteVarKey;
};

export const actionButtonVariant: Record<ActionButtonVariant, ColorVariant> = {
  primary: {
    color: 'primaryDark',
    bgColor: 'bgColorPrimaryActionButton',
    hoverColor: 'primaryLighter',
    hoverBgColor: 'primaryDark',
  },
  secondary: {
    color: 'secondaryDark',
    bgColor: 'secondaryLighter',
    hoverColor: 'secondaryLighter',
    hoverBgColor: 'secondaryDark',
  },
  submit: {
    color: 'primaryLighter',
    bgColor: 'primaryDark',
    hoverColor: 'primaryLighter',
    hoverBgColor: 'primaryDark',
  },
  danger: {
    color: 'dangerDark',
    bgColor: 'dangerLighter',
    hoverColor: 'dangerLighter',
    hoverBgColor: 'dangerDark',
  },
  edit: {
    color: 'secondaryDark',
    bgColor: 'secondaryLighter',
    hoverColor: 'secondaryLighter',
    hoverBgColor: 'secondaryDark',
  },
};

type ButtonSize = 'large' | 'medium' | 'small';

const loadingSizes: Record<ButtonSize, number> = {
  small: 10,
  medium: 15,
  large: 20,
};

export type ActionButtonProps<
  Component extends React.ElementType = React.ElementType,
> = Omit<ButtonProps<Component>, 'variant' | 'disabled' | 'size'> & {
  children: ReactNode;
  variant: ActionButtonVariant;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  dataTestId?: string;
  widthAuto?: boolean;
  disabled?: boolean;
  size?: ButtonSize;
  notAllCaps?: boolean;
  className?: string;
  tooltip?: ReactNode;
};

export const ActionButton = <Component extends React.ElementType>({
  children,
  variant,
  onClick,
  loading,
  className,
  disabled,
  widthAuto = false,
  size = 'medium',
  notAllCaps,
  tooltip,
  ...rest
}: ActionButtonProps<Component>) => {
  const palette = usePalette();
  const variantConfig = actionButtonVariant[variant];

  const button = (
    <Button
      {...rest}
      size={size}
      variant="contained"
      sx={{
        // MUI by defaults uppoercase the text of the button
        textTransform: notAllCaps ? 'none' : 'uppercase',
        color: palette[variantConfig.color],
        bgcolor: palette[variantConfig.bgColor],
        '&:hover': {
          color: palette[variantConfig.hoverColor],
          bgcolor: palette[variantConfig.hoverBgColor],
        },
      }}
      className={classNames(styles.button, className, {
        [styles.widthAuto]: widthAuto,
      })}
      disableElevation
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? <CircularProgress size={loadingSizes[size]} /> : children}
    </Button>
  );

  return tooltip ? (
    <Tooltip title={tooltip} disableInteractive>
      <span className="inline-flex">{button}</span>
    </Tooltip>
  ) : (
    button
  );
};
