import { Control } from 'react-hook-form';

import { LpContactType } from 'business/shared/lp-creation-module/services/types';
import { FormCheckboxesGrid } from 'ui/form/form-checkboxes-grid';

interface Props {
  control?: Control<LpContactType>;
  name?: string;
  readonly?: boolean;
}

type CheckboxKeys = Pick<
  LpContactType['contact'],
  | 'hasOperationalRights'
  | 'canAccessGeneralReports'
  | 'canAttendInvestorsMeeting'
  | 'canAccessESGReports'
  | 'canAccessAmpereReports'
  | 'canAccessCapitalAccountStatement'
>;

const checkboxNames: Array<{ label: string; itemName: keyof CheckboxKeys }> = [
  {
    label: 'pages.contact.form.label.communicationTypes.hasOperationalRights',
    itemName: 'hasOperationalRights',
  },
  {
    label:
      'pages.contact.form.label.communicationTypes.canAccessGeneralReports',
    itemName: 'canAccessGeneralReports',
  },
  {
    label:
      'pages.contact.form.label.communicationTypes.canAttendInvestorsMeeting',
    itemName: 'canAttendInvestorsMeeting',
  },
  {
    label: 'pages.contact.form.label.communicationTypes.canAccessESGReports',
    itemName: 'canAccessESGReports',
  },
  {
    label: 'pages.contact.form.label.communicationTypes.canAccessAmpereReports',
    itemName: 'canAccessAmpereReports',
  },
  {
    label:
      'pages.contact.form.label.communicationTypes.canAccessCapitalAccountStatement',
    itemName: 'canAccessCapitalAccountStatement',
  },
];

export const ContactCommunicationTypes = ({
  name,
  control,
  readonly = false,
}: Props) => {
  // chexboxes values

  return (
    <FormCheckboxesGrid<LpContactType>
      name={name}
      items={checkboxNames}
      control={control}
      readonly={readonly}
    />
  );
};
