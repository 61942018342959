import { Stack } from '@mui/material';
import Modal from '@mui/material/Modal';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

interface Props {
  open: boolean;
  handleClose: () => void;
  component: React.ReactNode;
}

export function CustomModal({ open, handleClose, component }: Props) {
  const palette = usePalette();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.container}
    >
      <Stack className={styles.stack} sx={{ bgcolor: palette.backgroundForm }}>
        <Stack overflow="scroll">{component}</Stack>
      </Stack>
    </Modal>
  );
}
