import { MutationFunctionOptions } from '@apollo/client';
import { Stack } from '@mui/material';
import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { LpDetailsButton } from 'business/fund-manager/lp/components/lp-details-button';
import { LpsFilteredFields } from 'business/fund-manager/lp/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import { getCreationStepDetails } from 'business/shared/lp-creation-module/services/get-creation-steps-details';
import {
  CustomColumnType,
  Exact,
  LpCustomColumnValueInput,
  LpTableQuery,
  PublicationStatus,
  SetLpCustomColumnValueMutation,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

export const getLpsColumnDefinition = ({
  t,
  customFilters,
  canValidateLp,
  customColumns,
  setLpCustomColumnValue,
  displayLpInvestorProfileStep,
}: {
  t: TFunction;
  customFilters: LpsFilteredFields;
  canValidateLp: boolean;
  customColumns: LpTableQuery['customColumns'];
  setLpCustomColumnValue: (
    options?: MutationFunctionOptions<
      SetLpCustomColumnValueMutation,
      Exact<{ input: LpCustomColumnValueInput }>
    >,
  ) => void;
  displayLpInvestorProfileStep: boolean;
}): ColDef<LpTableQuery['lps'][number]>[] => [
  {
    filter: true,
    filterParams: customFilters.lpNames,
    colId: 'lpName',
    headerName: t('pages.fundManager.lps.table.lpName'),
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }

      if (data.lpView?.lpName.length) {
        return data.lpView.lpName;
      }

      if (data.subscribingEntityName) {
        return data.subscribingEntityName;
      }

      if (data.legalRepresentative) {
        return data.legalRepresentative.subscribingEntityName;
      }
    },
  },
  {
    headerName: t('pages.fundManager.lps.table.legalRepresentative.name'),
    field: 'legalRepresentative.subscribingEntityName',
    filterParams: customFilters.legalRepresentatives,
    filter: true,
  },
  {
    headerName: t('pages.fundManager.lps.table.legalRepresentative.email'),
    field: 'legalRepresentative.email',
    type: AgGridColumnTypesEnum.EMAIL,
  },
  {
    headerName: t('pages.fundManager.lps.table.country'),
    colId: 'country',
    field: 'lpView.country',
    type: AgGridColumnTypesEnum.COUNTRY,
  },
  {
    headerName: t('pages.fundManager.lps.table.vigilanceScore'),
    colId: 'riskScore',
    field: 'lpView.riskScore',
    type: AgGridColumnTypesEnum.SCORE,
  },
  {
    headerName: t('pages.fundManager.lps.table.commitments'),
    field: 'commitmentTotalNumericAmount',
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
  {
    headerName: t('pages.fundManager.lps.table.client'),
    colId: 'clientType',
    valueGetter: ({ data }) =>
      data?.client
        ? t('pages.fundManager.lps.table.client', { context: data.client })
        : undefined,
  },
  {
    headerName: t(
      'pages.fundManager.lpCreation.form.KYC.categorization.investorType',
    ),
    colId: 'investorType',
    valueGetter: ({ data }) =>
      data?.lpView?.investorType
        ? t(
            'pages.fundManager.lpCreation.form.KYC.categorization.investorType',
            {
              context: data.lpView.investorType,
            },
          )
        : undefined,
  },
  {
    headerName: t('pages.fundManager.lps.table.taxOption'),
    colId: 'taxOption',
    field: 'lpView.taxOption',
    type: AgGridColumnTypesEnum.BOOLEAN,
  },
  {
    headerName: t('pages.fundManager.lps.table.address'),
    colId: 'address',
    valueGetter: ({ data }) =>
      data?.lpView?.address
        ? `${data.lpView.address.streetLine}, ${data.lpView.address.city}, ${data.lpView.address.zipCode}`
        : undefined,
  },
  {
    headerName: t('pages.fundManager.lps.table.bankDetails'),
    hide: true,
    colId: 'bankDetails',
    valueGetter: ({ data }) => {
      if (!data?.lpView?.lpFundBankDetails?.[0]) {
        return undefined;
      }
      const { bankDetails } = data.lpView.lpFundBankDetails[0];
      return `${bankDetails.name} - ${bankDetails.iban}, ${bankDetails.bic}`;
    },
  },
  ...customColumns.map(({ id, type, name }) => ({
    headerName: name,
    colId: id,
    type,
    editable: true,
    valueGetter: ({ data }: ValueGetterParams<LpTableQuery['lps'][number]>) => {
      const customColData = data?.customData?.find(
        (dataPoint) => dataPoint.customColumnId === id,
      );
      if (type === CustomColumnType.Boolean) {
        return customColData ? JSON.parse(customColData.value) : false;
      }
      return customColData ? JSON.parse(customColData.value) : undefined;
    },
    valueSetter: ({
      data,
      newValue,
    }: ValueSetterParams<LpTableQuery['lps'][number]>) => {
      if (data) {
        setLpCustomColumnValue({
          variables: {
            input: {
              lpId: data.id,
              customColumnId: id,
              value: JSON.stringify(newValue),
            },
          },
        });
        return true;
      }
      return false;
    },
  })),
  {
    headerName: t('pages.fundManager.lps.table.details'),
    colId: 'action',
    type: AgGridColumnTypesEnum.INTERACTION,
    cellRenderer: ({
      data,
      value,
    }: ICellRendererParams<LpTableQuery['lps'][number]>) => {
      if (!data || !value?.id) {
        return null;
      }

      if (data.publicationStatus === PublicationStatus.Draft) {
        return (
          <LpDetailsButton
            disabled={!canValidateLp}
            to={value.to}
            label={t('pages.fundManager.lps.table.modify')}
            variant={'primary'}
          />
        );
      }

      if (data.publicationStatus === PublicationStatus.PendingValidation) {
        return (
          <LpDetailsButton
            disabled={!canValidateLp}
            to={value.to}
            label={
              !canValidateLp
                ? t('pages.fundManager.lps.table.modify')
                : t('pages.fundManager.lps.table.validate')
            }
            variant={!canValidateLp ? 'primary' : 'submit'}
          />
        );
      }

      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <LpDetailsButton
            to={value.to}
            label={t('pages.fundManager.lps.table.details')}
            variant="secondary"
          />
          {value?.countPendingValidationSubscriptionOrContacts &&
          value.countPendingValidationSubscriptionOrContacts > 0 ? (
            <PendingChipCount
              count={value?.countPendingValidationSubscriptionOrContacts}
            />
          ) : null}
        </Stack>
      );
    },
    valueGetter: ({ data }) => {
      if (!data) {
        return {};
      }

      const countPendingValidationSubscriptionOrContacts =
        data.pendingValidationSubscriptionsCount +
        data.pendingValidationContactsCount;

      if (
        data.publicationStatus === PublicationStatus.PendingValidation ||
        data.publicationStatus === PublicationStatus.Draft
      ) {
        const { steps } = getCreationStepDetails({
          currentUrl: '',
          clientType: data.client,
          lpId: data.id,
          t,
          displayLpInvestorProfileStep,
          displayLpContactsStep: true,
        });

        // if we have a lpId -> first lp creation form step is already done
        return {
          id: data.id,
          isDraft: true,
          to: `${FundManagerRoutes.LpCreate}${steps?.[1].url}`,
        };
      }

      return {
        id: data.id,
        isDraft: false,
        to: FundManagerRoutes.LpId.replace(':lpId', data.id ?? ''),
        countPendingValidationSubscriptionOrContacts,
      };
    },
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    minWidth: 170,
    flex: 2,
  },
];
