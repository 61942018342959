// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse from 'fuse.js';

import {
  FuzzyFilterParams,
  FuzzyFilteringResult,
  searchOptions,
} from 'technical/filter/fuzzy-filter/constants';

const findRelatedData = (
  data: any,
  referencesKey: string[],
  relatedKey: string,
): FuzzyFilteringResult => {
  let result = {};

  referencesKey
    .filter((reference) => reference !== relatedKey)
    .forEach((reference) => {
      if (reference !== relatedKey) {
        const part = { [reference]: data[reference] };

        result = { ...result, ...part };
      }

      const relatedData = data[relatedKey].filter((row: any) =>
        data[reference].find((element: any) => element.id === row.id),
      );
      const part = { [relatedKey]: relatedData };

      result = { ...result, ...part };
    });

  return result;
};

export const handleFuzzyFiltering = ({
  data,
  searchedTerm,
  dataKeys,
  enabledColumns,
  relatedKey,
}: FuzzyFilterParams): FuzzyFilteringResult | null => {
  if (!data || !searchedTerm.length) {
    return null;
  }

  searchOptions.keys = enabledColumns;

  const filteredResult = dataKeys.reduce((acc, dataKey) => {
    const fuse = new Fuse(data[dataKey], searchOptions);
    const fuzzyData = fuse.search(searchedTerm).map((row) => row.item);
    const part =
      dataKey !== relatedKey
        ? { [dataKey]: fuzzyData }
        : { [dataKey]: data[dataKey] };
    return { ...acc, ...part };
  }, {});

  return relatedKey
    ? findRelatedData(filteredResult, dataKeys, relatedKey)
    : filteredResult;
};
