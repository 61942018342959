import { LanguageEnum } from 'translations/types';

interface CountryValues
  extends Record<LanguageEnum, { countryName: string; regionName: string }> {
  code: string;
  regionCode: string;
}

export const COUNTRY_DATA: Record<string, CountryValues> = {
  AF: {
    code: 'AF',
    regionCode: '142',
    en: {
      countryName: 'Afghanistan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Afghanistan',
      regionName: 'Asie',
    },
  },
  AL: {
    code: 'AL',
    regionCode: '150',
    en: {
      countryName: 'Albania',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Albanie',
      regionName: 'Europe',
    },
  },
  DZ: {
    code: 'DZ',
    regionCode: '2',
    en: {
      countryName: 'Algeria',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Algérie',
      regionName: 'Afrique',
    },
  },
  AS: {
    code: 'AS',
    regionCode: '9',
    en: {
      countryName: 'American Samoa',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Samoa américaines',
      regionName: 'Océanie',
    },
  },
  AD: {
    code: 'AD',
    regionCode: '150',
    en: {
      countryName: 'Andorra',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Andorre',
      regionName: 'Europe',
    },
  },
  AO: {
    code: 'AO',
    regionCode: '2',
    en: {
      countryName: 'Angola',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Angola',
      regionName: 'Afrique',
    },
  },
  AI: {
    code: 'AI',
    regionCode: '19',
    en: {
      countryName: 'Anguilla',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Anguilla',
      regionName: 'Amériques',
    },
  },
  AG: {
    code: 'AG',
    regionCode: '19',
    en: {
      countryName: 'Antigua and Barbuda',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Antigua-et-Barbuda',
      regionName: 'Amériques',
    },
  },
  AR: {
    code: 'AR',
    regionCode: '19',
    en: {
      countryName: 'Argentina',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Argentine',
      regionName: 'Amériques',
    },
  },
  AM: {
    code: 'AM',
    regionCode: '142',
    en: {
      countryName: 'Armenia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Arménie',
      regionName: 'Asie',
    },
  },
  AW: {
    code: 'AW',
    regionCode: '19',
    en: {
      countryName: 'Aruba',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Aruba',
      regionName: 'Amériques',
    },
  },
  AU: {
    code: 'AU',
    regionCode: '9',
    en: {
      countryName: 'Australia',
      regionName: 'Australia and New Zealand',
    },
    fr: {
      countryName: 'Australie',
      regionName: 'Australie et Nouvelle-Zélande',
    },
  },
  AT: {
    code: 'AT',
    regionCode: '150',
    en: {
      countryName: 'Austria',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Autriche',
      regionName: 'Europe',
    },
  },
  AZ: {
    code: 'AZ',
    regionCode: '142',
    en: {
      countryName: 'Azerbaijan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Azerbaïdjan',
      regionName: 'Asie',
    },
  },
  BS: {
    code: 'BS',
    regionCode: '19',
    en: {
      countryName: 'Bahamas',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Bahamas',
      regionName: 'Amériques',
    },
  },
  BH: {
    code: 'BH',
    regionCode: '142',
    en: {
      countryName: 'Bahrain',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Bahreïn',
      regionName: 'Asie',
    },
  },
  BD: {
    code: 'BD',
    regionCode: '142',
    en: {
      countryName: 'Bangladesh',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Bangladesh',
      regionName: 'Asie',
    },
  },
  BB: {
    code: 'BB',
    regionCode: '19',
    en: {
      countryName: 'Barbados',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Barbade',
      regionName: 'Amériques',
    },
  },
  BY: {
    code: 'BY',
    regionCode: '150',
    en: {
      countryName: 'Belarus',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Bélarus',
      regionName: 'Europe',
    },
  },
  BE: {
    code: 'BE',
    regionCode: '150',
    en: {
      countryName: 'Belgium',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Belgique',
      regionName: 'Europe',
    },
  },
  BZ: {
    code: 'BZ',
    regionCode: '19',
    en: {
      countryName: 'Belize',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Belize',
      regionName: 'Amériques',
    },
  },
  BJ: {
    code: 'BJ',
    regionCode: '2',
    en: {
      countryName: 'Benin',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Bénin',
      regionName: 'Afrique',
    },
  },
  BM: {
    code: 'BM',
    regionCode: '19',
    en: {
      countryName: 'Bermuda',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Bermudes',
      regionName: 'Amériques',
    },
  },
  BT: {
    code: 'BT',
    regionCode: '142',
    en: {
      countryName: 'Bhutan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Bhoutan',
      regionName: 'Asie',
    },
  },
  BO: {
    code: 'BO',
    regionCode: '19',
    en: {
      countryName: 'Bolivia',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Bolivie',
      regionName: 'Amériques',
    },
  },
  BQ: {
    code: 'BQ',
    regionCode: '19',
    en: {
      countryName: 'Bonaire, Sint Eustatius and Saba',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Bonaire, Saint-Eustache et Saba',
      regionName: 'Amériques',
    },
  },
  BA: {
    code: 'BA',
    regionCode: '150',
    en: {
      countryName: 'Bosnia and Herzegovina',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Bosnie-Herzégovine',
      regionName: 'Europe',
    },
  },
  BW: {
    code: 'BW',
    regionCode: '2',
    en: {
      countryName: 'Botswana',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Botswana',
      regionName: 'Afrique',
    },
  },
  BV: {
    code: 'BV',
    regionCode: '19',
    en: {
      countryName: 'Bouvet Island',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Bouvet',
      regionName: 'Amériques',
    },
  },
  BR: {
    code: 'BR',
    regionCode: '19',
    en: {
      countryName: 'Brazil',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Brésil',
      regionName: 'Amériques',
    },
  },
  IO: {
    code: 'IO',
    regionCode: '2',
    en: {
      countryName: 'British Indian Ocean Territory',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Indien',
      regionName: 'Afrique',
    },
  },
  BN: {
    code: 'BN',
    regionCode: '142',
    en: {
      countryName: 'Brunei Darussalam',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Brunéi Darussalam',
      regionName: 'Asie',
    },
  },
  BG: {
    code: 'BG',
    regionCode: '150',
    en: {
      countryName: 'Bulgaria',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Bulgarie',
      regionName: 'Europe',
    },
  },
  BF: {
    code: 'BF',
    regionCode: '2',
    en: {
      countryName: 'Burkina Faso',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Burkina Faso',
      regionName: 'Afrique',
    },
  },
  BI: {
    code: 'BI',
    regionCode: '2',
    en: {
      countryName: 'Burundi',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Burundi',
      regionName: 'Afrique',
    },
  },
  CV: {
    code: 'CV',
    regionCode: '2',
    en: {
      countryName: 'Cabo Verde',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Cabo Verde',
      regionName: 'Afrique',
    },
  },
  KH: {
    code: 'KH',
    regionCode: '142',
    en: {
      countryName: 'Cambodia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Cambodge',
      regionName: 'Asie',
    },
  },
  CM: {
    code: 'CM',
    regionCode: '2',
    en: {
      countryName: 'Cameroon',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Cameroun',
      regionName: 'Afrique',
    },
  },
  CA: {
    code: 'CA',
    regionCode: '19',
    en: {
      countryName: 'Canada',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Canada',
      regionName: 'Amériques',
    },
  },
  KY: {
    code: 'KY',
    regionCode: '19',
    en: {
      countryName: 'Cayman Islands',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Caïmans',
      regionName: 'Amériques',
    },
  },
  CF: {
    code: 'CF',
    regionCode: '2',
    en: {
      countryName: 'Central African Republic',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'République centrafricaine',
      regionName: 'Afrique',
    },
  },
  TD: {
    code: 'TD',
    regionCode: '2',
    en: {
      countryName: 'Chad',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Tchad',
      regionName: 'Afrique',
    },
  },
  CL: {
    code: 'CL',
    regionCode: '19',
    en: {
      countryName: 'Chile',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Chili',
      regionName: 'Amériques',
    },
  },
  CN: {
    code: 'CN',
    regionCode: '142',
    en: {
      countryName: 'China',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Chine',
      regionName: 'Asie',
    },
  },
  CX: {
    code: 'CX',
    regionCode: '142',
    en: {
      countryName: 'Christmas Island',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Christmas',
      regionName: 'Asie',
    },
  },
  CC: {
    code: 'CC',
    regionCode: '142',
    en: {
      countryName: 'Cocos (Keeling) Islands',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Cocos (Keeling)',
      regionName: 'Asie',
    },
  },
  CO: {
    code: 'CO',
    regionCode: '19',
    en: {
      countryName: 'Colombia',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Colombie',
      regionName: 'Amériques',
    },
  },
  KM: {
    code: 'KM',
    regionCode: '2',
    en: {
      countryName: 'Comoros',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Comores',
      regionName: 'Afrique',
    },
  },
  CG: {
    code: 'CG',
    regionCode: '2',
    en: {
      countryName: 'Congo',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Congo',
      regionName: 'Afrique',
    },
  },
  CD: {
    code: 'CD',
    regionCode: '2',
    en: {
      countryName: 'Congo, Democratic Republic of the',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Congo, République démocratique du',
      regionName: 'Afrique',
    },
  },
  CK: {
    code: 'CK',
    regionCode: '9',
    en: {
      countryName: 'Cook Islands',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Cook',
      regionName: 'Océanie',
    },
  },
  CR: {
    code: 'CR',
    regionCode: '19',
    en: {
      countryName: 'Costa Rica',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Costa Rica',
      regionName: 'Amériques',
    },
  },
  HR: {
    code: 'HR',
    regionCode: '150',
    en: {
      countryName: 'Croatia',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Croatie',
      regionName: 'Europe',
    },
  },
  CU: {
    code: 'CU',
    regionCode: '19',
    en: {
      countryName: 'Cuba',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Cuba',
      regionName: 'Amériques',
    },
  },
  CW: {
    code: 'CW',
    regionCode: '19',
    en: {
      countryName: 'Curaçao',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Curaçao',
      regionName: 'Amériques',
    },
  },
  CY: {
    code: 'CY',
    regionCode: '142',
    en: {
      countryName: 'Cyprus',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Chypre',
      regionName: 'Asie',
    },
  },
  CZ: {
    code: 'CZ',
    regionCode: '150',
    en: {
      countryName: 'Czech Republic',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'République tchèque',
      regionName: 'Europe',
    },
  },
  CI: {
    code: 'CI',
    regionCode: '2',
    en: {
      countryName: 'Ivory Coast',
      regionName: 'Africa',
    },
    fr: {
      countryName: "Côte d'Ivoire",
      regionName: 'Afrique',
    },
  },
  DK: {
    code: 'DK',
    regionCode: '150',
    en: {
      countryName: 'Denmark',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Danemark',
      regionName: 'Europe',
    },
  },
  DJ: {
    code: 'DJ',
    regionCode: '2',
    en: {
      countryName: 'Djibouti',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Djibouti',
      regionName: 'Afrique',
    },
  },
  DM: {
    code: 'DM',
    regionCode: '19',
    en: {
      countryName: 'Dominica',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Dominique',
      regionName: 'Amériques',
    },
  },
  DO: {
    code: 'DO',
    regionCode: '19',
    en: {
      countryName: 'Dominican Republic',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'République dominicaine',
      regionName: 'Amériques',
    },
  },
  EC: {
    code: 'EC',
    regionCode: '19',
    en: {
      countryName: 'Ecuador',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Équateur',
      regionName: 'Amériques',
    },
  },
  EG: {
    code: 'EG',
    regionCode: '2',
    en: {
      countryName: 'Egypt',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Égypte',
      regionName: 'Afrique',
    },
  },
  SV: {
    code: 'SV',
    regionCode: '19',
    en: {
      countryName: 'El Salvador',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Salvador',
      regionName: 'Amériques',
    },
  },
  GQ: {
    code: 'GQ',
    regionCode: '2',
    en: {
      countryName: 'Equatorial Guinea',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Guinée équatoriale',
      regionName: 'Afrique',
    },
  },
  ER: {
    code: 'ER',
    regionCode: '2',
    en: {
      countryName: 'Eritrea',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Érythrée',
      regionName: 'Afrique',
    },
  },
  EE: {
    code: 'EE',
    regionCode: '150',
    en: {
      countryName: 'Estonia',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Estonie',
      regionName: 'Europe',
    },
  },
  SZ: {
    code: 'SZ',
    regionCode: '2',
    en: {
      countryName: 'Eswatini',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Eswatini',
      regionName: 'Afrique',
    },
  },
  ET: {
    code: 'ET',
    regionCode: '2',
    en: {
      countryName: 'Ethiopia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Éthiopie',
      regionName: 'Afrique',
    },
  },
  FK: {
    code: 'FK',
    regionCode: '19',
    en: {
      countryName: 'Falkland Islands (Malvinas)',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Malouines',
      regionName: 'Amériques',
    },
  },
  FO: {
    code: 'FO',
    regionCode: '150',
    en: {
      countryName: 'Faroe Islands',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Féroé',
      regionName: 'Europe',
    },
  },
  FJ: {
    code: 'FJ',
    regionCode: '9',
    en: {
      countryName: 'Fiji',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Fidji',
      regionName: 'Océanie',
    },
  },
  FI: {
    code: 'FI',
    regionCode: '150',
    en: {
      countryName: 'Finland',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Finlande',
      regionName: 'Europe',
    },
  },
  FR: {
    code: 'FR',
    regionCode: '150',
    en: {
      countryName: 'France',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'France',
      regionName: 'Europe',
    },
  },
  GF: {
    code: 'GF',
    regionCode: '19',
    en: {
      countryName: 'French Guiana',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Guyane française',
      regionName: 'Amériques',
    },
  },
  PF: {
    code: 'PF',
    regionCode: '9',
    en: {
      countryName: 'French Polynesia',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Polynésie française',
      regionName: 'Océanie',
    },
  },
  TF: {
    code: 'TF',
    regionCode: '142',
    en: {
      countryName: 'French Southern Territories',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Terres australes françaises',
      regionName: 'Asie',
    },
  },
  GA: {
    code: 'GA',
    regionCode: '2',
    en: {
      countryName: 'Gabon',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Gabon',
      regionName: 'Afrique',
    },
  },
  GM: {
    code: 'GM',
    regionCode: '2',
    en: {
      countryName: 'Gambia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Gambie',
      regionName: 'Afrique',
    },
  },
  GE: {
    code: 'GE',
    regionCode: '142',
    en: {
      countryName: 'Georgia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Géorgie',
      regionName: 'Asie',
    },
  },
  DE: {
    code: 'DE',
    regionCode: '150',
    en: {
      countryName: 'Germany',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Allemagne',
      regionName: 'Europe',
    },
  },
  GH: {
    code: 'GH',
    regionCode: '2',
    en: {
      countryName: 'Ghana',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Ghana',
      regionName: 'Afrique',
    },
  },
  GI: {
    code: 'GI',
    regionCode: '150',
    en: {
      countryName: 'Gibraltar',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Gibraltar',
      regionName: 'Europe',
    },
  },
  GR: {
    code: 'GR',
    regionCode: '150',
    en: {
      countryName: 'Greece',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Grèce',
      regionName: 'Europe',
    },
  },
  GL: {
    code: 'GL',
    regionCode: '19',
    en: {
      countryName: 'Greenland',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Groenland',
      regionName: 'Amériques',
    },
  },
  GD: {
    code: 'GD',
    regionCode: '19',
    en: {
      countryName: 'Grenada',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Grenade',
      regionName: 'Amériques',
    },
  },
  GP: {
    code: 'GP',
    regionCode: '19',
    en: {
      countryName: 'Guadeloupe',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Guadeloupe',
      regionName: 'Amériques',
    },
  },
  GU: {
    code: 'GU',
    regionCode: '9',
    en: {
      countryName: 'Guam',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Guam',
      regionName: 'Océanie',
    },
  },
  GT: {
    code: 'GT',
    regionCode: '19',
    en: {
      countryName: 'Guatemala',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Guatemala',
      regionName: 'Amériques',
    },
  },
  GG: {
    code: 'GG',
    regionCode: '150',
    en: {
      countryName: 'Guernsey',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Guernesey',
      regionName: 'Europe',
    },
  },
  GN: {
    code: 'GN',
    regionCode: '2',
    en: {
      countryName: 'Guinea',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Guinée',
      regionName: 'Afrique',
    },
  },
  GW: {
    code: 'GW',
    regionCode: '2',
    en: {
      countryName: 'Guinea-Bissau',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Guinée-Bissau',
      regionName: 'Afrique',
    },
  },
  GY: {
    code: 'GY',
    regionCode: '19',
    en: {
      countryName: 'Guyana',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Guyana',
      regionName: 'Amériques',
    },
  },
  HT: {
    code: 'HT',
    regionCode: '19',
    en: {
      countryName: 'Haiti',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Haïti',
      regionName: 'Amériques',
    },
  },
  HM: {
    code: 'HM',
    regionCode: '142',
    en: {
      countryName: 'Heard Island and McDonald Islands',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Heard-et-îles McDonald',
      regionName: 'Asie',
    },
  },
  VA: {
    code: 'VA',
    regionCode: '150',
    en: {
      countryName: 'Holy See (Vatican City State)',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Saint-Siège (État de la Cité du Vatican)',
      regionName: 'Europe',
    },
  },
  HN: {
    code: 'HN',
    regionCode: '19',
    en: {
      countryName: 'Honduras',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Honduras',
      regionName: 'Amériques',
    },
  },
  HK: {
    code: 'HK',
    regionCode: '142',
    en: {
      countryName: 'Hong Kong',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Hong Kong',
      regionName: 'Asie',
    },
  },
  HU: {
    code: 'HU',
    regionCode: '150',
    en: {
      countryName: 'Hungary',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Hongrie',
      regionName: 'Europe',
    },
  },
  IS: {
    code: 'IS',
    regionCode: '150',
    en: {
      countryName: 'Iceland',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Islande',
      regionName: 'Europe',
    },
  },
  IN: {
    code: 'IN',
    regionCode: '142',
    en: {
      countryName: 'India',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Inde',
      regionName: 'Asie',
    },
  },
  ID: {
    code: 'ID',
    regionCode: '142',
    en: {
      countryName: 'Indonesia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Indonésie',
      regionName: 'Asie',
    },
  },
  IR: {
    code: 'IR',
    regionCode: '142',
    en: {
      countryName: 'Iran, Islamic Republic of',
      regionName: 'Asia',
    },
    fr: {
      countryName: "Iran, République islamique d'",
      regionName: 'Asie',
    },
  },
  IQ: {
    code: 'IQ',
    regionCode: '142',
    en: {
      countryName: 'Iraq',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Iraq',
      regionName: 'Asie',
    },
  },
  IE: {
    code: 'IE',
    regionCode: '150',
    en: {
      countryName: 'Ireland',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Irlande',
      regionName: 'Europe',
    },
  },
  IM: {
    code: 'IM',
    regionCode: '150',
    en: {
      countryName: 'Isle of Man',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Île de Man',
      regionName: 'Europe',
    },
  },
  IL: {
    code: 'IL',
    regionCode: '142',
    en: {
      countryName: 'Israel',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Israël',
      regionName: 'Asie',
    },
  },
  IT: {
    code: 'IT',
    regionCode: '150',
    en: {
      countryName: 'Italy',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Italie',
      regionName: 'Europe',
    },
  },
  JM: {
    code: 'JM',
    regionCode: '19',
    en: {
      countryName: 'Jamaica',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Jamaïque',
      regionName: 'Amériques',
    },
  },
  JP: {
    code: 'JP',
    regionCode: '142',
    en: {
      countryName: 'Japan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Japon',
      regionName: 'Asie',
    },
  },
  JE: {
    code: 'JE',
    regionCode: '150',
    en: {
      countryName: 'Jersey',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Jersey',
      regionName: 'Europe',
    },
  },
  JO: {
    code: 'JO',
    regionCode: '142',
    en: {
      countryName: 'Jordan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Jordanie',
      regionName: 'Asie',
    },
  },
  KZ: {
    code: 'KZ',
    regionCode: '142',
    en: {
      countryName: 'Kazakhstan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Kazakhstan',
      regionName: 'Asie',
    },
  },
  KE: {
    code: 'KE',
    regionCode: '2',
    en: {
      countryName: 'Kenya',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Kenya',
      regionName: 'Afrique',
    },
  },
  KI: {
    code: 'KI',
    regionCode: '9',
    en: {
      countryName: 'Kiribati',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Kiribati',
      regionName: 'Océanie',
    },
  },
  KP: {
    code: 'KP',
    regionCode: '142',
    en: {
      countryName: "Korea, Democratic People's Republic of",
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Corée, République populaire démocratique de',
      regionName: 'Asie',
    },
  },
  KR: {
    code: 'KR',
    regionCode: '142',
    en: {
      countryName: 'Korea, Republic of',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Corée, République de',
      regionName: 'Asie',
    },
  },
  KW: {
    code: 'KW',
    regionCode: '142',
    en: {
      countryName: 'Kuwait',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Koweït',
      regionName: 'Asie',
    },
  },
  KG: {
    code: 'KG',
    regionCode: '142',
    en: {
      countryName: 'Kyrgyzstan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Kirghizistan',
      regionName: 'Asie',
    },
  },
  LA: {
    code: 'LA',
    regionCode: '142',
    en: {
      countryName: "Lao People's Democratic Republic",
      regionName: 'Asia',
    },
    fr: {
      countryName: 'République démocratique populaire lao',
      regionName: 'Asie',
    },
  },
  LV: {
    code: 'LV',
    regionCode: '150',
    en: {
      countryName: 'Latvia',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Lettonie',
      regionName: 'Europe',
    },
  },
  LB: {
    code: 'LB',
    regionCode: '142',
    en: {
      countryName: 'Lebanon',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Liban',
      regionName: 'Asie',
    },
  },
  LS: {
    code: 'LS',
    regionCode: '2',
    en: {
      countryName: 'Lesotho',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Lesotho',
      regionName: 'Afrique',
    },
  },
  LR: {
    code: 'LR',
    regionCode: '2',
    en: {
      countryName: 'Liberia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Libéria',
      regionName: 'Afrique',
    },
  },
  LY: {
    code: 'LY',
    regionCode: '2',
    en: {
      countryName: 'Libya',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Libye',
      regionName: 'Afrique',
    },
  },
  LI: {
    code: 'LI',
    regionCode: '150',
    en: {
      countryName: 'Liechtenstein',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Liechtenstein',
      regionName: 'Europe',
    },
  },
  LT: {
    code: 'LT',
    regionCode: '150',
    en: {
      countryName: 'Lithuania',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Lituanie',
      regionName: 'Europe',
    },
  },
  LU: {
    code: 'LU',
    regionCode: '150',
    en: {
      countryName: 'Luxembourg',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Luxembourg',
      regionName: 'Europe',
    },
  },
  MO: {
    code: 'MO',
    regionCode: '142',
    en: {
      countryName: 'Macao',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Macao',
      regionName: 'Asie',
    },
  },
  MG: {
    code: 'MG',
    regionCode: '2',
    en: {
      countryName: 'Madagascar',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Madagascar',
      regionName: 'Afrique',
    },
  },
  MW: {
    code: 'MW',
    regionCode: '2',
    en: {
      countryName: 'Malawi',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Malawi',
      regionName: 'Afrique',
    },
  },
  MY: {
    code: 'MY',
    regionCode: '142',
    en: {
      countryName: 'Malaysia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Malaisie',
      regionName: 'Asie',
    },
  },
  MV: {
    code: 'MV',
    regionCode: '142',
    en: {
      countryName: 'Maldives',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Maldives',
      regionName: 'Asie',
    },
  },
  ML: {
    code: 'ML',
    regionCode: '2',
    en: {
      countryName: 'Mali',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Mali',
      regionName: 'Afrique',
    },
  },
  MT: {
    code: 'MT',
    regionCode: '150',
    en: {
      countryName: 'Malta',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Malte',
      regionName: 'Europe',
    },
  },
  MH: {
    code: 'MH',
    regionCode: '9',
    en: {
      countryName: 'Marshall Islands',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Îles Marshall',
      regionName: 'Océanie',
    },
  },
  MQ: {
    code: 'MQ',
    regionCode: '19',
    en: {
      countryName: 'Martinique',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Martinique',
      regionName: 'Amériques',
    },
  },
  MR: {
    code: 'MR',
    regionCode: '2',
    en: {
      countryName: 'Mauritania',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Mauritanie',
      regionName: 'Afrique',
    },
  },
  MU: {
    code: 'MU',
    regionCode: '2',
    en: {
      countryName: 'Mauritius',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Maurice',
      regionName: 'Afrique',
    },
  },
  YT: {
    code: 'YT',
    regionCode: '2',
    en: {
      countryName: 'Mayotte',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Mayotte',
      regionName: 'Afrique',
    },
  },
  MX: {
    code: 'MX',
    regionCode: '19',
    en: {
      countryName: 'Mexico',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Mexique',
      regionName: 'Amériques',
    },
  },
  FM: {
    code: 'FM',
    regionCode: '9',
    en: {
      countryName: 'Micronesia, Federated States of',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Micronésie, États fédérés de',
      regionName: 'Océanie',
    },
  },
  MD: {
    code: 'MD',
    regionCode: '150',
    en: {
      countryName: 'Moldova, Republic of',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Moldavie, République de',
      regionName: 'Europe',
    },
  },
  MC: {
    code: 'MC',
    regionCode: '150',
    en: {
      countryName: 'Monaco',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Monaco',
      regionName: 'Europe',
    },
  },
  MN: {
    code: 'MN',
    regionCode: '142',
    en: {
      countryName: 'Mongolia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Mongolie',
      regionName: 'Asie',
    },
  },
  ME: {
    code: 'ME',
    regionCode: '150',
    en: {
      countryName: 'Montenegro',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Monténégro',
      regionName: 'Europe',
    },
  },
  MS: {
    code: 'MS',
    regionCode: '19',
    en: {
      countryName: 'Montserrat',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Montserrat',
      regionName: 'Amériques',
    },
  },
  MA: {
    code: 'MA',
    regionCode: '2',
    en: {
      countryName: 'Morocco',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Maroc',
      regionName: 'Afrique',
    },
  },
  MZ: {
    code: 'MZ',
    regionCode: '2',
    en: {
      countryName: 'Mozambique',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Mozambique',
      regionName: 'Afrique',
    },
  },
  MM: {
    code: 'MM',
    regionCode: '142',
    en: {
      countryName: 'Myanmar',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Myanmar',
      regionName: 'Asie',
    },
  },
  NA: {
    code: 'NA',
    regionCode: '2',
    en: {
      countryName: 'Namibia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Namibie',
      regionName: 'Afrique',
    },
  },
  NR: {
    code: 'NR',
    regionCode: '9',
    en: {
      countryName: 'Nauru',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Nauru',
      regionName: 'Océanie',
    },
  },
  NP: {
    code: 'NP',
    regionCode: '142',
    en: {
      countryName: 'Nepal',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Népal',
      regionName: 'Asie',
    },
  },
  NL: {
    code: 'NL',
    regionCode: '150',
    en: {
      countryName: 'Netherlands',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Pays-Bas',
      regionName: 'Europe',
    },
  },
  NC: {
    code: 'NC',
    regionCode: '9',
    en: {
      countryName: 'New Caledonia',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Nouvelle-Calédonie',
      regionName: 'Océanie',
    },
  },
  NZ: {
    code: 'NZ',
    regionCode: '9',
    en: {
      countryName: 'New Zealand',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Nouvelle-Zélande',
      regionName: 'Océanie',
    },
  },
  NI: {
    code: 'NI',
    regionCode: '19',
    en: {
      countryName: 'Nicaragua',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Nicaragua',
      regionName: 'Amériques',
    },
  },
  NE: {
    code: 'NE',
    regionCode: '2',
    en: {
      countryName: 'Niger',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Niger',
      regionName: 'Afrique',
    },
  },
  NG: {
    code: 'NG',
    regionCode: '2',
    en: {
      countryName: 'Nigeria',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Nigéria',
      regionName: 'Afrique',
    },
  },
  NU: {
    code: 'NU',
    regionCode: '9',
    en: {
      countryName: 'Niue',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Niue',
      regionName: 'Océanie',
    },
  },
  NF: {
    code: 'NF',
    regionCode: '9',
    en: {
      countryName: 'Norfolk Island',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Île Norfolk',
      regionName: 'Océanie',
    },
  },
  MK: {
    code: 'MK',
    regionCode: '150',
    en: {
      countryName: 'North Macedonia, Republic of',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Macédoine du Nord, République de',
      regionName: 'Europe',
    },
  },
  MP: {
    code: 'MP',
    regionCode: '9',
    en: {
      countryName: 'Northern Mariana Islands',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Îles Mariannes du Nord',
      regionName: 'Océanie',
    },
  },
  NO: {
    code: 'NO',
    regionCode: '150',
    en: {
      countryName: 'Norway',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Norvège',
      regionName: 'Europe',
    },
  },
  OM: {
    code: 'OM',
    regionCode: '142',
    en: {
      countryName: 'Oman',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Oman',
      regionName: 'Asie',
    },
  },
  PK: {
    code: 'PK',
    regionCode: '142',
    en: {
      countryName: 'Pakistan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Pakistan',
      regionName: 'Asie',
    },
  },
  PW: {
    code: 'PW',
    regionCode: '9',
    en: {
      countryName: 'Palau',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Palaos',
      regionName: 'Océanie',
    },
  },
  PS: {
    code: 'PS',
    regionCode: '142',
    en: {
      countryName: 'Palestine',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Palestine',
      regionName: 'Asie',
    },
  },
  PA: {
    code: 'PA',
    regionCode: '19',
    en: {
      countryName: 'Panama',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Panama',
      regionName: 'Amériques',
    },
  },
  PG: {
    code: 'PG',
    regionCode: '9',
    en: {
      countryName: 'Papua New Guinea',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Papouasie-Nouvelle-Guinée',
      regionName: 'Océanie',
    },
  },
  PY: {
    code: 'PY',
    regionCode: '19',
    en: {
      countryName: 'Paraguay',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Paraguay',
      regionName: 'Amériques',
    },
  },
  PE: {
    code: 'PE',
    regionCode: '19',
    en: {
      countryName: 'Peru',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Pérou',
      regionName: 'Amériques',
    },
  },
  PH: {
    code: 'PH',
    regionCode: '142',
    en: {
      countryName: 'Philippines',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Philippines',
      regionName: 'Asie',
    },
  },
  PN: {
    code: 'PN',
    regionCode: '9',
    en: {
      countryName: 'Pitcairn',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Pitcairn',
      regionName: 'Océanie',
    },
  },
  PL: {
    code: 'PL',
    regionCode: '150',
    en: {
      countryName: 'Poland',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Pologne',
      regionName: 'Europe',
    },
  },
  PT: {
    code: 'PT',
    regionCode: '150',
    en: {
      countryName: 'Portugal',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Portugal',
      regionName: 'Europe',
    },
  },
  PR: {
    code: 'PR',
    regionCode: '19',
    en: {
      countryName: 'Puerto Rico',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Porto Rico',
      regionName: 'Amériques',
    },
  },
  QA: {
    code: 'QA',
    regionCode: '142',
    en: {
      countryName: 'Qatar',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Qatar',
      regionName: 'Asie',
    },
  },
  RE: {
    code: 'RE',
    regionCode: '2',
    en: {
      countryName: 'Réunion',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Réunion',
      regionName: 'Afrique',
    },
  },
  RO: {
    code: 'RO',
    regionCode: '150',
    en: {
      countryName: 'Romania',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Roumanie',
      regionName: 'Europe',
    },
  },
  RU: {
    code: 'RU',
    regionCode: '142',
    en: {
      countryName: 'Russian Federation',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Fédération de Russie',
      regionName: 'Asie',
    },
  },
  RW: {
    code: 'RW',
    regionCode: '2',
    en: {
      countryName: 'Rwanda',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Rwanda',
      regionName: 'Afrique',
    },
  },
  BL: {
    code: 'BL',
    regionCode: '19',
    en: {
      countryName: 'Saint Barthélemy',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Saint-Barthélemy',
      regionName: 'Amériques',
    },
  },
  SH: {
    code: 'SH',
    regionCode: '2',
    en: {
      countryName: 'Saint Helena, Ascension and Tristan da Cunha',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Sainte-Hélène, Ascension et Tristan da Cunha',
      regionName: 'Afrique',
    },
  },
  KN: {
    code: 'KN',
    regionCode: '19',
    en: {
      countryName: 'Saint Kitts and Nevis',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Saint-Christophe-et-Niévès',
      regionName: 'Amériques',
    },
  },
  LC: {
    code: 'LC',
    regionCode: '19',
    en: {
      countryName: 'Saint Lucia',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Sainte-Lucie',
      regionName: 'Amériques',
    },
  },
  MF: {
    code: 'MF',
    regionCode: '19',
    en: {
      countryName: 'Saint Martin',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Saint-Martin',
      regionName: 'Amériques',
    },
  },
  PM: {
    code: 'PM',
    regionCode: '19',
    en: {
      countryName: 'Saint Pierre and Miquelon',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Saint-Pierre-et-Miquelon',
      regionName: 'Amériques',
    },
  },
  VC: {
    code: 'VC',
    regionCode: '19',
    en: {
      countryName: 'Saint Vincent and the Grenadines',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Saint-Vincent-et-les Grenadines',
      regionName: 'Amériques',
    },
  },
  WS: {
    code: 'WS',
    regionCode: '9',
    en: {
      countryName: 'Samoa',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Samoa',
      regionName: 'Océanie',
    },
  },
  SM: {
    code: 'SM',
    regionCode: '150',
    en: {
      countryName: 'San Marino',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Saint-Marin',
      regionName: 'Europe',
    },
  },
  ST: {
    code: 'ST',
    regionCode: '2',
    en: {
      countryName: 'Sao Tome and Principe',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Sao Tomé-et-Principe',
      regionName: 'Afrique',
    },
  },
  SA: {
    code: 'SA',
    regionCode: '142',
    en: {
      countryName: 'Saudi Arabia',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Arabie saoudite',
      regionName: 'Asie',
    },
  },
  SN: {
    code: 'SN',
    regionCode: '2',
    en: {
      countryName: 'Senegal',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Sénégal',
      regionName: 'Afrique',
    },
  },
  RS: {
    code: 'RS',
    regionCode: '150',
    en: {
      countryName: 'Serbia',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Serbie',
      regionName: 'Europe',
    },
  },
  SC: {
    code: 'SC',
    regionCode: '2',
    en: {
      countryName: 'Seychelles',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Seychelles',
      regionName: 'Afrique',
    },
  },
  SL: {
    code: 'SL',
    regionCode: '2',
    en: {
      countryName: 'Sierra Leone',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Sierra Leone',
      regionName: 'Afrique',
    },
  },
  SG: {
    code: 'SG',
    regionCode: '142',
    en: {
      countryName: 'Singapore',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Singapour',
      regionName: 'Asie',
    },
  },
  SX: {
    code: 'SX',
    regionCode: '19',
    en: {
      countryName: 'Sint Maarten (Dutch part)',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Saint-Martin (partie néerlandaise)',
      regionName: 'Amériques',
    },
  },
  SK: {
    code: 'SK',
    regionCode: '150',
    en: {
      countryName: 'Slovakia',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Slovaquie',
      regionName: 'Europe',
    },
  },
  SI: {
    code: 'SI',
    regionCode: '150',
    en: {
      countryName: 'Slovenia',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Slovénie',
      regionName: 'Europe',
    },
  },
  SB: {
    code: 'SB',
    regionCode: '9',
    en: {
      countryName: 'Solomon Islands',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Îles Salomon',
      regionName: 'Océanie',
    },
  },
  SO: {
    code: 'SO',
    regionCode: '2',
    en: {
      countryName: 'Somalia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Somalie',
      regionName: 'Afrique',
    },
  },
  ZA: {
    code: 'ZA',
    regionCode: '2',
    en: {
      countryName: 'South Africa',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Afrique du Sud',
      regionName: 'Afrique',
    },
  },
  GS: {
    code: 'GS',
    regionCode: '19',
    en: {
      countryName: 'South Georgia and the South Sandwich Islands',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Géorgie du Sud et les îles Sandwich du Sud',
      regionName: 'Amériques',
    },
  },
  SS: {
    code: 'SS',
    regionCode: '2',
    en: {
      countryName: 'South Sudan',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Soudan du Sud',
      regionName: 'Afrique',
    },
  },
  ES: {
    code: 'ES',
    regionCode: '150',
    en: {
      countryName: 'Spain',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Espagne',
      regionName: 'Europe',
    },
  },
  LK: {
    code: 'LK',
    regionCode: '142',
    en: {
      countryName: 'Sri Lanka',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Sri Lanka',
      regionName: 'Asie',
    },
  },
  SD: {
    code: 'SD',
    regionCode: '2',
    en: {
      countryName: 'Sudan',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Soudan',
      regionName: 'Afrique',
    },
  },
  SR: {
    code: 'SR',
    regionCode: '19',
    en: {
      countryName: 'Suriname',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Surinam',
      regionName: 'Amériques',
    },
  },
  SJ: {
    code: 'SJ',
    regionCode: '150',
    en: {
      countryName: 'Svalbard and Jan Mayen',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Svalbard et Jan Mayen',
      regionName: 'Europe',
    },
  },
  SE: {
    code: 'SE',
    regionCode: '150',
    en: {
      countryName: 'Sweden',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Suède',
      regionName: 'Europe',
    },
  },
  CH: {
    code: 'CH',
    regionCode: '150',
    en: {
      countryName: 'Switzerland',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Suisse',
      regionName: 'Europe',
    },
  },
  SY: {
    code: 'SY',
    regionCode: '142',
    en: {
      countryName: 'Syrian Arab Republic',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'République arabe syrienne',
      regionName: 'Asie',
    },
  },
  TW: {
    code: 'TW',
    regionCode: '142',
    en: {
      countryName: 'Taiwan, Province of China',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Taïwan, Province de Chine',
      regionName: 'Asie',
    },
  },
  TJ: {
    code: 'TJ',
    regionCode: '142',
    en: {
      countryName: 'Tajikistan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Tadjikistan',
      regionName: 'Asie',
    },
  },
  TZ: {
    code: 'TZ',
    regionCode: '2',
    en: {
      countryName: 'Tanzania, United Republic of',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Tanzanie, République-Unie de',
      regionName: 'Afrique',
    },
  },
  TH: {
    code: 'TH',
    regionCode: '142',
    en: {
      countryName: 'Thailand',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Thaïlande',
      regionName: 'Asie',
    },
  },
  TL: {
    code: 'TL',
    regionCode: '142',
    en: {
      countryName: 'Timor-Leste',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Timor-Leste',
      regionName: 'Asie',
    },
  },
  TG: {
    code: 'TG',
    regionCode: '2',
    en: {
      countryName: 'Togo',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Togo',
      regionName: 'Afrique',
    },
  },
  TK: {
    code: 'TK',
    regionCode: '9',
    en: {
      countryName: 'Tokelau',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Tokelau',
      regionName: 'Océanie',
    },
  },
  TO: {
    code: 'TO',
    regionCode: '9',
    en: {
      countryName: 'Tonga',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Tonga',
      regionName: 'Océanie',
    },
  },
  TT: {
    code: 'TT',
    regionCode: '19',
    en: {
      countryName: 'Trinidad and Tobago',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Trinité-et-Tobago',
      regionName: 'Amériques',
    },
  },
  TN: {
    code: 'TN',
    regionCode: '2',
    en: {
      countryName: 'Tunisia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Tunisie',
      regionName: 'Afrique',
    },
  },
  TR: {
    code: 'TR',
    regionCode: '142',
    en: {
      countryName: 'Turkey',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Turquie',
      regionName: 'Asie',
    },
  },
  TM: {
    code: 'TM',
    regionCode: '142',
    en: {
      countryName: 'Turkmenistan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Turkménistan',
      regionName: 'Asie',
    },
  },
  TC: {
    code: 'TC',
    regionCode: '19',
    en: {
      countryName: 'Turks and Caicos Islands',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Îles Turques et Caïques',
      regionName: 'Amériques',
    },
  },
  TV: {
    code: 'TV',
    regionCode: '9',
    en: {
      countryName: 'Tuvalu',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Tuvalu',
      regionName: 'Océanie',
    },
  },
  UG: {
    code: 'UG',
    regionCode: '2',
    en: {
      countryName: 'Uganda',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Ouganda',
      regionName: 'Afrique',
    },
  },
  UA: {
    code: 'UA',
    regionCode: '150',
    en: {
      countryName: 'Ukraine',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Ukraine',
      regionName: 'Europe',
    },
  },
  AE: {
    code: 'AE',
    regionCode: '142',
    en: {
      countryName: 'United Arab Emirates',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Émirats arabes unis',
      regionName: 'Asie',
    },
  },
  GB: {
    code: 'GB',
    regionCode: '150',
    en: {
      countryName: 'United Kingdom of Great Britain and Northern Ireland',
      regionName: 'Europe',
    },
    fr: {
      countryName: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      regionName: 'Europe',
    },
  },
  US: {
    code: 'US',
    regionCode: '19',
    en: {
      countryName: 'United States of America',
      regionName: 'Americas',
    },
    fr: {
      countryName: "États-Unis d'Amérique",
      regionName: 'Amériques',
    },
  },
  UM: {
    code: 'UM',
    regionCode: '9',
    en: {
      countryName: 'United States Minor Outlying Islands',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Îles mineures éloignées des États-Unis',
      regionName: 'Océanie',
    },
  },
  UY: {
    code: 'UY',
    regionCode: '19',
    en: {
      countryName: 'Uruguay',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Uruguay',
      regionName: 'Amériques',
    },
  },
  UZ: {
    code: 'UZ',
    regionCode: '142',
    en: {
      countryName: 'Uzbekistan',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Ouzbékistan',
      regionName: 'Asie',
    },
  },
  VU: {
    code: 'VU',
    regionCode: '9',
    en: {
      countryName: 'Vanuatu',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Vanuatu',
      regionName: 'Océanie',
    },
  },
  VE: {
    code: 'VE',
    regionCode: '19',
    en: {
      countryName: 'Venezuela (Bolivarian Republic of)',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Venezuela (République bolivarienne du)',
      regionName: 'Amériques',
    },
  },
  VN: {
    code: 'VN',
    regionCode: '142',
    en: {
      countryName: 'Viet Nam',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Viet Nam',
      regionName: 'Asie',
    },
  },
  VG: {
    code: 'VG',
    regionCode: '19',
    en: {
      countryName: 'Virgin Islands (British)',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Îles Vierges britanniques',
      regionName: 'Amériques',
    },
  },
  VI: {
    code: 'VI',
    regionCode: '19',
    en: {
      countryName: 'Virgin Islands (U.S.)',
      regionName: 'Americas',
    },
    fr: {
      countryName: 'Îles Vierges des États-Unis',
      regionName: 'Amériques',
    },
  },
  WF: {
    code: 'WF',
    regionCode: '9',
    en: {
      countryName: 'Wallis and Futuna',
      regionName: 'Oceania',
    },
    fr: {
      countryName: 'Wallis-et-Futuna',
      regionName: 'Océanie',
    },
  },
  EH: {
    code: 'EH',
    regionCode: '2',
    en: {
      countryName: 'Western Sahara',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Sahara occidental',
      regionName: 'Afrique',
    },
  },
  YE: {
    code: 'YE',
    regionCode: '142',
    en: {
      countryName: 'Yemen',
      regionName: 'Asia',
    },
    fr: {
      countryName: 'Yémen',
      regionName: 'Asie',
    },
  },
  ZM: {
    code: 'ZM',
    regionCode: '2',
    en: {
      countryName: 'Zambia',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Zambie',
      regionName: 'Afrique',
    },
  },
  ZW: {
    code: 'ZW',
    regionCode: '2',
    en: {
      countryName: 'Zimbabwe',
      regionName: 'Africa',
    },
    fr: {
      countryName: 'Zimbabwe',
      regionName: 'Afrique',
    },
  },
  AX: {
    code: 'AX',
    regionCode: '150',
    en: {
      countryName: 'Åland Islands',
      regionName: 'Europe',
    },
    fr: {
      countryName: 'Åland',
      regionName: 'Europe',
    },
  },
} as const;

export const COUNTRY_CODES = Object.keys(COUNTRY_DATA);
