import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import SharedRoutes from 'business/shared/router/routes';
import { ResetPasswordCard } from 'business/user/components/reset-password-card';
import SuccessCard from 'business/user/components/success-card';
import PageAuth from 'business/user/pages/page-auth';
import authService from 'business/user/services/auth-service';
import { ForgotPasswordForm } from 'business/user/services/types';
import { forgotPasswordFormSchema } from 'business/user/services/validation';
import { FormInputText } from 'ui/form';

function ForgotPasswordPage() {
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmitClick = async (values: ForgotPasswordForm) => {
    await authService.requestPasswordReset(values.login);
    setShowSuccess(true);
  };

  const methods = useForm<ForgotPasswordForm>({
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const onCancelHandle = () => navigate(SharedRoutes.SignIn);
  const onSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    methods.handleSubmit(handleSubmitClick)();
  };

  if (showSuccess) {
    return (
      <PageAuth>
        <SuccessCard
          primaryButtonHandle={onCancelHandle}
          secondaryButtonHandle={onSubmit}
          dataTestId="success-forgot-password"
        />
      </PageAuth>
    );
  }

  return (
    <PageAuth>
      <form onSubmit={methods.handleSubmit(handleSubmitClick)}>
        <FormProvider {...methods}>
          <ResetPasswordCard
            submitButtonLabel={t('pages.forgotPassword.submit')}
            title={t('pages.forgotPassword.title')}
            description={t('pages.forgotPassword.description')}
          >
            <FormInputText
              fullWidth
              name="login"
              label={t('notConnected.login.email')}
              autoComplete="username"
              data-test-id="email-input"
            />
          </ResetPasswordCard>
        </FormProvider>
      </form>
    </PageAuth>
  );
}

export default ForgotPasswordPage;
