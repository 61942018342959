import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FundProfile } from 'business/fund-manager/fund/services/types';
import { FormInputText, FormSection } from 'ui/form';

export const KeyEconomicTermForm = () => {
  const { control } = useFormContext<FundProfile>();
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('pages.fundManager.fundCreation.form.keyEconomicTerms.title')}
    >
      <FormInputText
        multiline
        minRows={3}
        name="managementFees"
        label={t(
          'pages.fundManager.fundCreation.form.keyEconomicTerms.managementFees.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.keyEconomicTerms.managementFees.placeholder',
        )}
        control={control}
      />

      <FormInputText
        multiline
        minRows={3}
        name="feeOffsets"
        label={t(
          'pages.fundManager.fundCreation.form.keyEconomicTerms.feeOffsets.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.keyEconomicTerms.feeOffsets.placeholder',
        )}
        control={control}
      />

      <FormInputText
        multiline
        minRows={3}
        name="carriedInterest"
        label={t(
          'pages.fundManager.fundCreation.form.keyEconomicTerms.carriedInterest.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.keyEconomicTerms.carriedInterest.placeholder',
        )}
        control={control}
      />
    </FormSection>
  );
};
