import { OutputOutlined } from '@mui/icons-material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useValuationSynthesisQuery } from 'generated/graphql';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import { PortfolioValuationIcon, TotalInvestedIcon } from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

export const CompanyInventoryKeyMetricsCard = () => {
  const { t } = useTranslation();
  const palette = usePalette();

  const { fundId = '' } = useParams();

  const { data, loading, error } = useValuationSynthesisQuery({
    variables: { fundId },
    fetchPolicy: 'network-only',
  });

  if (loading || error) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }
  if (!data?.valuationSynthesis) {
    return null;
  }

  const { fairValue, accruedCoupon, exitValue, proceeds } =
    data.valuationSynthesis;

  return (
    <InfoCard>
      <InfoCard.Title>
        {t('pages.fundManager.portfolio.inventory.card.keyMetrics.title')}
      </InfoCard.Title>
      <InfoCard.Items size="auto">
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
        >
          <InfoItem
            className={styles.cardItem}
            value={formatToMonetaryAmount(fairValue)}
            description={t(
              'pages.fundManager.portfolio.inventory.card.keyMetrics.fairValue',
            )}
            imageSource={
              <PortfolioValuationIcon
                primaryColor={palette.secondaryIconColor}
              />
            }
            size="normal"
            sx={{
              color: palette.secondaryIconColor,
              bgcolor: palette.secondaryBackgroundIconColor,
            }}
          />
          <InfoItem
            value={formatToMonetaryAmount(accruedCoupon)}
            className={styles.cardItem}
            description={t(
              'pages.fundManager.portfolio.inventory.card.keyMetrics.accruedCoupon',
            )}
            imageSource={<ConfirmationNumberIcon />}
            size="normal"
            iconColor="primary"
          />
          <InfoItem
            value={formatToMonetaryAmount(proceeds)}
            className={styles.cardItem}
            description={t(
              'pages.fundManager.portfolio.inventory.card.keyMetrics.proceeds',
            )}
            imageSource={
              <TotalInvestedIcon primaryColor={palette.neutralIconColor} />
            }
            size="normal"
            iconColor="primary"
          />
          <InfoItem
            value={formatToMonetaryAmount(exitValue)}
            className={styles.cardItem}
            description={t(
              'pages.fundManager.portfolio.inventory.card.keyMetrics.exitValue',
            )}
            imageSource={<OutputOutlined />}
            size="normal"
            iconColor="primary"
          />
        </Stack>
      </InfoCard.Items>
    </InfoCard>
  );
};
