import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';

import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import Loader from 'ui/loader';

type QueryStateDisplayProps = {
  loading?: boolean;
  error?: Error | ApolloError;
};

export const QueryStateDisplay = ({
  loading,
  error,
}: QueryStateDisplayProps) => {
  return (
    <Stack alignItems="center" margin={4}>
      {loading ? (
        <Loader />
      ) : (
        <ErrorLabel label={error?.message ?? ValidationErrors.GENERIC} />
      )}
    </Stack>
  );
};
