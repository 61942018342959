import { Drawer } from '@mui/material';

import { useOnFundManagerDocumentDownload } from 'business/shared/services/hooks/use-on-fund-manager-document-download';
import { useGetLpSubscriptionsOfShareQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SubscriptionHistoryItem } from 'ui/subscription-history-item/subscription-history-item';

interface SubscriptionHistoryDrawerProps {
  lpId: string;
  fundId?: string;
  shareId: string;
  open: boolean;
  onClose: () => void;
}
export const SubscriptionHistoryDrawer = ({
  lpId,
  shareId,
  open,
  onClose,
}: SubscriptionHistoryDrawerProps) => {
  const { data, loading, error } = useGetLpSubscriptionsOfShareQuery({
    variables: {
      input: {
        lpId,
        shareId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const onDocumentDownload = useOnFundManagerDocumentDownload();

  if (
    loading ||
    error ||
    !data?.subscriptionsWithDocumentShareAndShareTransfer
  ) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  const subscriptionData =
    data.subscriptionsWithDocumentShareAndShareTransfer.map(
      ({ shareTransfer, ...subscription }) => {
        if (!shareTransfer) {
          return subscription;
        }

        const isOtherLpSeller = shareTransfer.lpViewSeller.id !== lpId;

        return {
          ...subscription,
          shareTransfer: {
            ...shareTransfer,
            shareTransferOtherLp: {
              isSeller: isOtherLpSeller,
              id: isOtherLpSeller
                ? shareTransfer.lpViewSeller.id
                : shareTransfer.lpViewBuyer.id,
              lpName: isOtherLpSeller
                ? shareTransfer.lpViewSeller.lpName
                : shareTransfer.lpViewBuyer.lpName,
            },
          },
        };
      },
    );

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      {subscriptionData.map((subscription) => (
        <SubscriptionHistoryItem
          subscription={subscription}
          key={subscription.id}
          onDocumentDownload={onDocumentDownload}
        />
      ))}
    </Drawer>
  );
};
