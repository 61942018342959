import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';

import { FormInputsType } from 'ui/form/form-input-props';
import { FormInputText, FormInputTextProps } from 'ui/form/form-input-text';

export const FormInputPassword = <T extends FormInputsType>(
  props: FormInputTextProps<T>,
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <FormInputText
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
