import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation, TFunction } from 'translations/hook';

import {
  ShareTransferForm as ShareTransferFormType,
  ShareTransferErrors,
} from 'business/fund-manager/fund/services/types';
import { getShareTransferFormSchema } from 'business/fund-manager/fund/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  GetLpIdAndNameQuery,
  useCreateShareTransferMutation,
  useGetCommitmentForLpAndShareLazyQuery,
  useGetFundInfosQuery,
  useGetFundLastLaunchedOperationDateQuery,
  useGetLpIdAndNameQuery,
  useGetLpSubscribedToShareLazyQuery,
  useGetSharesByFundIdQuery,
  useSubscriptionBankDetailsByLpAndFundQuery,
} from 'generated/graphql';
import {
  convertFromX100toNumber,
  convertFrom10X8toNumber,
} from 'technical/currency/formatters';
import { useOnFilesUpload } from 'technical/file-management/use-on-files-upload';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';
import { ShareTransferForm } from 'ui/share-transfer-form/share-transfer-form';

const getLpView = (lpView?: GetLpIdAndNameQuery['web_portal_lpView']) =>
  (lpView ?? []).map((v) => ({
    id: v.id ?? '',
    lpName: v.lpName ?? '',
  }));

const getLabelError = ({ error, t }: { error?: ApolloError; t: TFunction }) => {
  if (!error) {
    return undefined;
  }

  if (
    error.message &&
    Object.values<string>(ShareTransferErrors).includes(error.message)
  ) {
    return t('errors.shareTransferCreation', { context: error });
  }
  return error?.message ?? ValidationErrors.GENERIC;
};

interface Props {
  handleClose: () => void;
  fundId: string;
  onCompleted?: () => void;
}

export const FundShareTransferForm = ({
  handleClose,
  fundId,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: fundData, loading: fundDataLoading } = useGetFundInfosQuery({
    variables: { fundId: { _eq: fundId } },
  });

  const { data: dateOfLastOperationData, loading: dateOfLastOperationLoading } =
    useGetFundLastLaunchedOperationDateQuery({
      variables: { fundId },
    });

  const { data: shareData, loading: shareDataLoading } =
    useGetSharesByFundIdQuery({
      variables: { fundId },
    });

  const { data: lpBuyerDataRaw, loading: lpBuyerDataLoading } =
    useGetLpIdAndNameQuery();

  const lpBuyers = getLpView(lpBuyerDataRaw?.web_portal_lpView);
  const [getSellerLpQuery] = useGetLpSubscribedToShareLazyQuery();

  const [getCommitmentForLpAndShare] = useGetCommitmentForLpAndShareLazyQuery();

  const [createShareTransfer, { error }] = useCreateShareTransferMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.shareTransferCreation'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      handleClose();
      onCompleted?.();
    },
  });

  const [lpSellerOptions, setLpSellerOptions] = useState<
    {
      id: string;
      lpName: string;
    }[]
  >([]);

  const [lpBuyerOptions, setLpBuyerOptions] = useState<
    {
      id: string;
      lpName: string;
    }[]
  >(lpBuyers);

  const [commitmentOfLp, setCommitmentOfLp] = useState(0);

  const [nominalValue, setNominalValueOfShare] = useState(0);

  const [priceByShare, setPriceByShare] = useState(0);

  const dateOfLastOperation =
    dateOfLastOperationData?.web_portal_operation?.[0]?.date ?? undefined;

  const methods = useForm<ShareTransferFormType>({
    resolver: yupResolver<ShareTransferFormType>(
      getShareTransferFormSchema({
        commitment: commitmentOfLp,
        dateOfLastOperation,
        t,
      }),
    ),
    defaultValues: {
      fundId: fundId,
      shareId: '',
      lpSellerId: '',
      lpBuyerId: '',
      amount: 0,
      totalPrice: 0,
    },
  });

  const onSubmit: SubmitHandler<ShareTransferFormType> = async (formData) =>
    createShareTransfer({
      variables: {
        input: {
          fundId: formData.fundId,
          shareId: formData.shareId,
          lpBuyerId: formData.lpBuyerId,
          lpSellerId: formData.lpSellerId,
          amount: formData.amount,
          totalPrice: formData.totalPrice,
          date: formData.date.toISOString(),
          bankDetails: formData.bankDetails,
          file: formData.files?.[0]
            ? {
                name: formData.files[0].name,
                path: formData.files[0].filePath,
              }
            : undefined,
        },
      },
    }).catch(() => undefined);

  const { handleSubmit, setValue, getValues } = methods;

  const lpId = useWatch({ name: 'lpBuyerId', control: methods.control });
  const lpSellerId = useWatch({ name: 'lpSellerId', control: methods.control });
  const shareId = useWatch({ name: 'shareId', control: methods.control });

  useSubscriptionBankDetailsByLpAndFundQuery({
    variables: {
      lpId,
      fundId,
    },
    onCompleted: (data) => {
      if (data.bankDetails.length === 0) {
        methods.setValue('bankDetails', {
          name: '',
          bic: '',
          iban: '',
          comment: '',
        });
        return;
      }

      const bankDetails = data.bankDetails[0];
      methods.setValue('bankDetails', {
        name: bankDetails.name ?? '',
        bic: bankDetails.bic ?? '',
        iban: bankDetails.iban ?? '',
        comment: bankDetails.comment ?? '',
      });
    },
    skip: !lpId,
  });

  const onFilesUpload = useOnFilesUpload({
    getFiles: () => getValues('files') ?? [],
  });

  // ////////////////////
  // on form event actions
  // ////////////////////

  const onShareSelected = async (newShareId: string) => {
    const { data } = await getSellerLpQuery({
      variables: { shareId: newShareId },
    });

    setValue('shareId', newShareId);

    setNominalValueOfShare(
      shareData?.shares?.find((share) => share.id === newShareId)
        ?.nominalValue ?? 0,
    );

    setLpSellerOptions(getLpView(data?.web_portal_lpView));
    setLpBuyerOptions(lpBuyers);

    //reset lpValue and commitment

    setValue('lpSellerId', '');
    setValue('amount', 0);
  };

  const onLpSellerSelected = async (value: string) => {
    const lpSelectedId = value;

    const { data } = await getCommitmentForLpAndShare({
      variables: {
        shareId: getValues('shareId'),
        lpId: lpSelectedId,
      },
    });

    setCommitmentOfLp(
      data?.web_portal_commitment_aggregate?.aggregate?.sum?.numericAmount ?? 0,
    );

    setLpBuyerOptions(lpBuyers?.filter((lp) => lp.id !== lpSelectedId) ?? []);

    setValue('lpSellerId', lpSelectedId);
    setValue('amount', 0);
  };

  const onAmountChange = (amount: number) => {
    setValue('amount', amount);
    setValue('numberOfShares', amount / convertFromX100toNumber(nominalValue));
  };

  const onNumberOfSharesChange = (numberOfShares: number) => {
    setValue('amount', numberOfShares * convertFromX100toNumber(nominalValue));
    setValue('numberOfShares', numberOfShares);
  };

  const onTotalPriceChange = (totalPrice: number) => {
    setValue('totalPrice', totalPrice);
    const numberOfSharesValue = getValues('numberOfShares') ?? 0;

    setPriceByShare(
      !!numberOfSharesValue ? totalPrice / numberOfSharesValue : 0,
    );
  };

  const loading =
    fundDataLoading ||
    lpBuyerDataLoading ||
    dateOfLastOperationLoading ||
    shareDataLoading;

  if (loading) {
    return <QueryStateDisplay loading={loading} />;
  }

  const labelError = getLabelError({ error, t });

  return (
    <FormModalContainer
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={methods.formState.isSubmitting}
      title={t('pages.fundManager.shareTransfer.form.title')}
      onClose={handleClose}
    >
      <FormProvider {...methods}>
        {labelError ? <ErrorLabel label={labelError} /> : null}
        <ShareTransferForm
          onFilesUpload={onFilesUpload}
          fund={{
            id: fundId,
            value: fundData?.fundInfos?.[0]?.fundName ?? '',
          }}
          shares={shareData?.shares.map((data) => {
            return { id: data.id, value: data.name };
          })}
          onShareChange={onShareSelected}
          sellers={lpSellerOptions?.map((data) => {
            return { id: data.id, value: data.lpName };
          })}
          onSellerChange={onLpSellerSelected}
          buyers={lpBuyerOptions.map((data) => {
            return { id: data.id, value: data.lpName };
          })}
          onAmountChange={onAmountChange}
          onNumberOfSharesChange={onNumberOfSharesChange}
          onTotalPriceChange={onTotalPriceChange}
          commitmentOfLp={
            lpSellerId ? convertFrom10X8toNumber(commitmentOfLp) : undefined
          }
          priceByShare={lpSellerId ? priceByShare : undefined}
          numberOfShareAvailables={
            shareId && !!nominalValue
              ? convertFrom10X8toNumber(commitmentOfLp) /
                convertFromX100toNumber(nominalValue)
              : undefined
          }
        />
      </FormProvider>
    </FormModalContainer>
  );
};
