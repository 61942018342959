import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'translations/hook';

import {
  ContactListItem,
  ContactListItemProps,
} from 'business/shared/components/contact-list-item';
import { ContactWithId } from 'business/shared/services/contact/types';
import { ActionButton } from 'ui/action-button';

const sortContacts = <T extends ContactWithId>(contacts: T[]) =>
  contacts.sort((a, b) => {
    if (a.isLegalRepresentative) {
      return -1;
    }
    if (b.isLegalRepresentative) {
      return 1;
    }
    if (a.firstName > b.firstName) {
      return -1;
    }
    return 1;
  });

type ContactListManagerProps<T extends ContactWithId> = Omit<
  ContactListItemProps<T>,
  'contact'
> & {
  contacts: T[];
  onAdd?: () => void;
  header?: ReactNode;
};

export const ContactListManager = <T extends ContactWithId = ContactWithId>({
  contacts,
  onAdd,
  header,
  ...rest
}: ContactListManagerProps<T>) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} flexGrow={1}>
      {onAdd || header ? (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          {header ?? null}
          {onAdd ? (
            <ActionButton variant="submit" onClick={onAdd}>
              {t('pages.contact.addContact')}
            </ActionButton>
          ) : null}
        </Stack>
      ) : null}

      {contacts.length ? (
        <Stack spacing={2}>
          {sortContacts(contacts).map((contact) => (
            <ContactListItem {...rest} key={contact.id} contact={contact} />
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};
