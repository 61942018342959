import { Stack, Tabs } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationNavBarTabEnum } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import { useGetPendingValidationCashFlowsCountInOperationQuery } from 'generated/graphql';
import { LinkTab } from 'ui/link-tab';

type OperationNavBarProps = PropsWithChildren<{
  activeTab: OperationNavBarTabEnum;
  action?: ReactNode;
}>;

// COUNT BADGE
const ChipCountPendingValidationCashFlowsInOperation = ({
  operationId,
}: {
  operationId: string;
}) => {
  const { data, loading, error } =
    useGetPendingValidationCashFlowsCountInOperationQuery({
      variables: {
        operationId,
      },
    });

  return (
    <PendingChipCount
      loading={loading}
      error={error}
      count={data?.pendingValidationCashFlowCountInOperation?.count}
    />
  );
};

export const OperationNavBar = ({
  activeTab,
  children,
  action,
}: OperationNavBarProps) => {
  const { operationId = '' } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Tabs value={activeTab}>
          <LinkTab
            to={FundManagerRoutes.OperationId.replace(
              ':operationId',
              operationId,
            )}
            label={t('pages.fundManager.operation.nav.overview')}
          />

          <Stack direction="row" alignItems="center">
            <LinkTab
              to={FundManagerRoutes.OperationIdInvestors.replace(
                ':operationId',
                operationId,
              )}
              label={t('pages.fundManager.operation.nav.investors')}
            />
            <ChipCountPendingValidationCashFlowsInOperation
              operationId={operationId}
            />
          </Stack>
          <LinkTab
            to={FundManagerRoutes.OperationIdEmailing.replace(
              ':operationId',
              operationId,
            )}
            label={t('pages.fundManager.operation.nav.emailing')}
          />
        </Tabs>
        {action ? action : null}
      </Stack>
      {children}
    </>
  );
};
