import { get } from 'lodash';

import {
  CompleteCustomThemePalette,
  CompleteThemePalette,
  MuiColors,
  PaletteMandatoryVars,
  SolidColors,
  ThemePalette,
  ThemePaletteConfig,
  ThemePaletteKey,
} from 'business/providers/theme/services/types';

// source: { main: 'primary.100' } , solidColors: { green { 100: '', 200: '' } }
const applyColors = <T extends Record<string, string>>(
  source: T,
  solidColors: Record<string, SolidColors>,
): T => {
  return Object.keys(source).reduce((acc, key) => {
    const matchingColor = get(solidColors, source[key]);

    if (!matchingColor) {
      throw new Error(`key ${key} not found color: ${source[key]}`);
    }
    return { ...acc, [key]: matchingColor };
  }, {}) as T;
};

const extractMUIColors = (
  key: ThemePaletteKey,
  source: ThemePalette,
): MuiColors => {
  const mainKey = `${key}Main` as PaletteMandatoryVars;
  const darkKey = `${key}Dark` as PaletteMandatoryVars;
  const lightKey = `${key}Light` as PaletteMandatoryVars;
  const lighterKey = `${key}Lighter` as PaletteMandatoryVars;

  if (
    !source[mainKey] ||
    !source[darkKey] ||
    !source[lightKey] ||
    !source[lighterKey]
  ) {
    throw new Error(
      `extractMUIColors - key ${key} not found color: ${source[key]}`,
    );
  }

  return {
    main: source[mainKey],
    dark: source[darkKey],
    light: source[lightKey],
    lighter: source[lighterKey],
  };
};

export const formatConfigColors = (paletteConfigSource: ThemePaletteConfig) => {
  const { solidColors, pe3, backgroundAuth, backgroundGeneral } =
    paletteConfigSource;

  // verify that all solid colors are complete: [100, ..., 900]
  const requiredSolidColorKeys = Array.from({ length: 9 }).map(
    (_, index) => (index + 1) * 100,
  );

  Object.values(solidColors).forEach((solidColor) => {
    if (
      !requiredSolidColorKeys.every((key) =>
        Object.prototype.hasOwnProperty.call(solidColor, key),
      )
    ) {
      throw new Error('missing a solid color value');
    }
  });

  const themePaletteColors: Record<ThemePaletteKey, SolidColors> = {
    primary: solidColors[pe3.primary],
    secondary: solidColors[pe3.secondary],
    tertiary: solidColors[pe3.tertiary],
  };

  // { primary: {100: ''}, green: {100: ''}}
  const solidAndThemeColors = {
    ...solidColors,
    ...themePaletteColors,
  };

  const formattedPe3: CompleteThemePalette = {
    ...applyColors(pe3, solidAndThemeColors),
    ...themePaletteColors,
    backgroundAuth,
    backgroundGeneral,
  };

  const output: CompleteCustomThemePalette = {
    pe3: formattedPe3,
    primary: extractMUIColors('primary', formattedPe3),
    secondary: extractMUIColors('secondary', formattedPe3),
    tertiary: extractMUIColors('tertiary', formattedPe3),
    solidColors,
  };

  return output;
};
