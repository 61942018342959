import { ComponentProps } from 'react';
import { useTranslation } from 'translations/hook';

import { Select } from 'ui/select';

interface Props
  extends Omit<ComponentProps<typeof Select>, 'onChange' | 'label'> {
  onChange: (share: any) => void;
  value?: any;
  options: { id: string; value: string }[];
}

export const FundShareValuationShareSelector = ({
  onChange,
  size = 'small',
  ...rest
}: Props) => {
  const { t } = useTranslation();

  return (
    <Select
      {...rest}
      size={size}
      id="CompanyStatusSelector"
      label={t('pages.fundManager.fundShareValuation.table.shareType')}
      disabled={false}
      onChange={onChange}
    />
  );
};
