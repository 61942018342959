import { SvgIcon } from '@mui/material';

interface Props {
  primaryColor: string;
}

export const GrossIrrIcon = ({ primaryColor }: Props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <mask id="path-1-inside-1_3325_3663" fill="white">
        <rect x="5" y="16" width="3.83333" height="5" rx="1" />
      </mask>
      <rect
        x="5"
        y="16"
        width="3.83333"
        height="5"
        rx="1"
        fill="none"
        stroke={primaryColor}
        strokeWidth="3"
        mask="url(#path-1-inside-1_3325_3663)"
      />
      <mask id="path-2-inside-2_3325_3663" fill="white">
        <rect x="9.79199" y="13" width="3.83333" height="8" rx="1" />
      </mask>
      <rect
        x="9.79199"
        y="13"
        width="3.83333"
        height="8"
        rx="1"
        fill="none"
        stroke={primaryColor}
        strokeWidth="3"
        mask="url(#path-2-inside-2_3325_3663)"
      />
      <mask id="path-3-inside-3_3325_3663" fill="white">
        <rect x="14.583" y="8" width="3.83333" height="13" rx="1" />
      </mask>
      <rect
        x="14.583"
        y="8"
        width="3.83333"
        height="13"
        rx="1"
        fill="none"
        stroke={primaryColor}
        strokeWidth="3"
        mask="url(#path-3-inside-3_3325_3663)"
      />
      <path
        d="M5.47949 14C8.03505 12.3333 11.7087 11 16.0212 4M16.0212 4H14.1045M16.0212 4L16.5003 6"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
