import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import GraphPanel from 'business/fund-manager/lp/components/financial-flow-graphs';
import { getFinancialFlowsColumnDefinition } from 'business/fund-manager/shared/services/financial-flows/get-financial-flows-column-definition';
import { createFinancialFlowsRows } from 'business/shared/financial-flows/services/create-financial-flows-rows';
import { FinancialFlowRow } from 'business/shared/financial-flows/services/types';
import {
  useGetFundManagerOperationsForFundQuery,
  useFundOperationsRecapQuery,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { FinancialFlowSynthesis } from 'ui/financial-flow-synthesis';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundFinancialFlowPage = () => {
  const { t } = useTranslation();

  const { fundId = '' } = useParams();

  const { data, error, loading } = useGetFundManagerOperationsForFundQuery({
    variables: { id: fundId },
  });

  const {
    data: fundOperationsRecapData,
    error: fundOperationsRecapError,
    loading: fundOperationsRecapLoading,
  } = useFundOperationsRecapQuery({
    variables: {
      input: {
        fundId,
      },
    },
  });

  const headers = getFinancialFlowsColumnDefinition(t);
  const rows = createFinancialFlowsRows(data);

  if (error) {
    return <QueryStateDisplay error={error} />;
  }
  if (fundOperationsRecapError || fundOperationsRecapLoading) {
    return (
      <QueryStateDisplay
        error={fundOperationsRecapError}
        loading={fundOperationsRecapLoading}
      />
    );
  }

  return (
    <Stack spacing={2}>
      <FinancialFlowSynthesis
        drawdown={fundOperationsRecapData?.fundOperationsRecap.totalDrawn}
        distribution={
          fundOperationsRecapData?.fundOperationsRecap
            .totalDistributedNonRecallable
        }
        recallableDistribution={
          fundOperationsRecapData?.fundOperationsRecap
            .totalDistributedRecallable
        }
      />
      <GraphPanel data={rows} />
      <AlternativeTable<FinancialFlowRow>
        fileName={'fund-financial-flow'}
        columnDefs={headers}
        rowData={rows}
        domLayout="autoHeight"
        loading={loading}
        groupIncludeFooter
        groupIncludeTotalFooter
        displaySidebar
      />
    </Stack>
  );
};
