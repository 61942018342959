import { addNumbersOrNull } from 'technical/number/add-numbers-or-null';

import {
  DeprecatedOperationCalculationValues,
  OperationCalculationValues,
} from './types';

export const computeOperationDrawndown = ({
  investment,
  fees,
  other,
}: {
  investment?: number | undefined | null;
  fees?: number | undefined | null;
  other?: number | undefined | null;
}) => {
  return addNumbersOrNull([investment, fees, other]);
};

export const computeOperationDistribution = ({
  returnOfCost,
  capitalGain,
  interest,
  dividend,
}: {
  returnOfCost?: number | undefined | null;
  capitalGain?: number | undefined | null;
  interest?: number | undefined | null;
  dividend?: number | undefined | null;
}) => {
  return addNumbersOrNull([returnOfCost, capitalGain, interest, dividend]);
};

export const computeOperationRecallableDistribution = ({
  currentDistributed,
}: {
  currentDistributed?: number | undefined | null;
}) => {
  return addNumbersOrNull([currentDistributed]);
};

export const computeOperationAmount = (args: {
  investment?: number | undefined | null;
  interest?: number | undefined | null;
  fees?: number | undefined | null;
  dividend?: number | undefined | null;
  currentDistributed?: number | undefined | null;
  capitalGain?: number | undefined | null;
  other?: number | undefined | null;
  returnOfCost?: number | undefined | null;
}) => {
  return (
    computeOperationDrawndown(args) -
    computeOperationDistribution(args) -
    (args.currentDistributed ?? 0)
  );
};

export const computeOperationAmountWithPremium = (args: {
  investment?: number | undefined | null;
  interest?: number | undefined | null;
  fees?: number | undefined | null;
  dividend?: number | undefined | null;
  currentDistributed?: number | undefined | null;
  capitalGain?: number | undefined | null;
  other?: number | undefined | null;
  returnOfCost?: number | undefined | null;
  premium?: number | undefined | null;
}) => {
  return computeOperationAmount(args) + (args.premium ?? 0);
};

export function deprecatedComputeOperationStats(
  args: DeprecatedOperationCalculationValues,
) {
  return {
    drawdown: computeOperationDrawndown(args),
    distribution: computeOperationDistribution(args),
    recallableDistribution: args.currentDistributed ?? 0,
    amount: computeOperationAmount(args),
  };
}

export function computeOperationStats(args: OperationCalculationValues) {
  return {
    drawdown: computeOperationDrawndown({
      investment: args.numericInvestment,
      fees: args.numericFees,
      other: args.numericOther,
    }),
    distribution: computeOperationDistribution({
      returnOfCost: args.numericReturnOfCost,
      capitalGain: args.numericCapitalGain,
      interest: args.numericInterest,
      dividend: args.numericDividend,
    }),
    recallableDistribution: args.numericCurrentDistributed ?? 0,
    amount: computeOperationAmount({
      investment: args.numericInvestment,
      interest: args.numericInterest,
      fees: args.numericFees,
      dividend: args.numericDividend,
      currentDistributed: args.numericCurrentDistributed,
      capitalGain: args.numericCapitalGain,
      other: args.numericOther,
      returnOfCost: args.numericReturnOfCost,
    }),
  };
}

export const computeOperationAmountToPay = (
  args: DeprecatedOperationCalculationValues,
) => {
  // I also thought that the computation was :
  // drawdown - (distribution + recallableDistribution)
  // but Celine answered that it was :
  // drawdown - (distribution - recallableDistribution)
  // In the case of the amount to pay, we need to add premium
  return (
    computeOperationDrawndown(args) -
    computeOperationDistribution(args) +
    computeOperationRecallableDistribution(args) +
    (args.premium ?? 0)
  );
};

export const isOperationDistributedRecallable = ({
  currentDistributed,
  fees,
  investment,
  other,
}: DeprecatedOperationCalculationValues) => {
  return currentDistributed && !fees && !investment && !other ? true : false;
};
