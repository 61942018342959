import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { forwardRef } from 'react';

import { logEvent } from 'technical/analytics';

export type ButtonProps<Component extends React.ElementType> =
  MuiButtonProps<Component> & {
    eventName?: string;
    logEventData?: object;
  };

export const Button = forwardRef(function Button<
  Component extends React.ElementType = React.ElementType,
>(
  {
    onClick: originalOnClick,
    eventName = 'unknown_event',
    logEventData,
    ...rest
  }: ButtonProps<Component>,
  ref: React.Ref<any>,
) {
  return (
    <MuiButton
      {...rest}
      ref={ref}
      onClick={(...args) => {
        logEvent('button_clicked', {
          button_event: eventName,
          ...logEventData,
        });
        if (originalOnClick) {
          originalOnClick(...args);
        }
      }}
    />
  );
});
