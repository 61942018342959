import { Divider, Stack } from '@mui/material';
import { sumBy } from 'lodash';
import { useTranslation } from 'translations/hook';

import { InstrumentValuationForm } from 'business/fund-manager/portfolio/valuation/components/instrument-valuation-form';
import { getInstrumentTotalQuantities } from 'business/fund-manager/portfolio/valuation/services/get-valuation-progress';
import { getInstrumentValuationSoldQuantity } from 'business/fund-manager/portfolio/valuation/services/get-valuation-sold-quantity';
import {
  GetInstrumentValuationDetailsQuery,
  WebCompanyTransactionTypeEnum,
} from 'generated/graphql';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import { MainSecondaryItem } from 'ui/main-secondary-item';

import styles from './index.module.scss';

interface Props {
  instrumentValuationId: string;
  data: GetInstrumentValuationDetailsQuery;
  handleClose: () => void;
}

export const InstrumentValuation = ({
  instrumentValuationId,
  data,
  handleClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack padding={3} spacing={2}>
        <InstrumentValuationForm
          id={instrumentValuationId}
          fairValue={data.instrumentValuation?.fairValue ?? 0}
          accruedCoupon={data.instrumentValuation?.accruedCoupon ?? 0}
          quantity={getInstrumentTotalQuantities(
            data.instrumentValuation?.companyInstrument?.transactions,
          )}
          productToDate={sumBy(data.instrumentProceeds.proceeds, 'amount')}
          onCompleted={handleClose}
        />
      </Stack>
      <Divider />
      <Stack
        padding={3}
        spacing={2}
        direction="row"
        justifyContent="space-between"
      >
        <MainSecondaryItem
          main={t(
            'pages.fundManager.portfolio.valuationInstrument.labels.exitQuantity',
          )}
          secondary={
            data.instrumentValuation?.companyInstrument?.transactions
              ? data.instrumentValuation.companyInstrument.transactions.reduce(
                  (acc, { quantity, type }) =>
                    type === WebCompanyTransactionTypeEnum.Transfer
                      ? acc + quantity
                      : acc,
                  0,
                )
              : 0
          }
          alignItems="center"
          className={styles.container}
          mainClassName={styles.title}
          secondaryClassName={styles.mainNumber}
          mainVariant="overline"
        />
        <MainSecondaryItem
          main={t(
            'pages.fundManager.portfolio.valuationInstrument.labels.exitQuantityValue',
          )}
          secondary={formatToMonetaryAmount(
            getInstrumentValuationSoldQuantity(
              data.instrumentValuation?.companyInstrument?.transactions,
            ),
          )}
          alignItems="center"
          className={styles.container}
          mainClassName={styles.title}
          secondaryClassName={styles.mainNumber}
          mainVariant="overline"
        />
      </Stack>
    </>
  );
};
