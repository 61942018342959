/**
 * this function go back to level above for url
 * Example: /fund-manager/<uuid value>/dashboard => /fund-manager/<uuid value>
 */
export const getPreviousUrlPath = (pathname: string) =>
  pathname
    // first we erase last part of url
    .replace(/\/[^/]+\/?$/, '')

    // then we deal creation step
    // because those step are a bunch of url
    // like this:
    // - :id/edit/step
    // - :id/edit/contact
    // So for this we need to go upper than edit directly
    // but not for url like:
    // - :id/edit/contact/:id
    .replace(/\/edit\/?$/, '')
    // then we deal creation step
    // because those step are a bunch of url
    // like this:
    // - create/:id
    // - create/:id/step2
    // So for this we need to go upper than create directly
    // but not for url like:
    // - create/:id/contact/:id
    .replace(/\/create(\/[^/]*)?\/?$/, '');
