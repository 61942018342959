import { Outlet, RouteObject } from 'react-router';

import CommitmentPage from 'business/lp-platform/commitment/page';
import ContactPage from 'business/lp-platform/contact/pages';
import { ContactUsPage } from 'business/lp-platform/contact-us/pages';
import DocumentPage from 'business/lp-platform/document/pages';
import FinancialFlowsPage from 'business/lp-platform/financial-flows/pages';
import { Home as HomePage } from 'business/lp-platform/home/pages/home';
import { OnboardingSubscriptionCompletion } from 'business/lp-platform/onboarding-subscription/pages/onboarding-subscription-completion/onboarding-subscription-completion';
import { OnboardingSubscriptionSignature } from 'business/lp-platform/onboarding-subscription/pages/onboarding-subscription-signature/onboarding-subscription-signature';
import PortfolioPage from 'business/lp-platform/portfolio/page';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import SecurityLogPage from 'business/lp-platform/security-log/pages';
import { FundShareValuations } from 'business/lp-platform/valuations/pages';
import { getLpCreationRoutes } from 'business/shared/lp-creation-module';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { ProtectedRoute } from 'business/user/helpers/protected-route-container';
import { Roles } from 'business/user/types/user';

const lpCreationRoutes = getLpCreationRoutes({
  basename: LpPlatformRoutes.LpOnboardingSubscriptionCreateIdLp,
  previousPath: LpPlatformRoutes.Home,
  editPath: LpPlatformRoutes.LpOnboardingSubscriptionCreateIdLp,
  // go to LpPlatformRoutes.LpOnboardingSubscriptionCreateId route
  finalPath: '/../',
  getBasenameWithParams: ({
    basename,
    params,
  }: {
    basename: string;
    params: Record<string, undefined | string>;
  }) =>
    basename.replace(
      ':onboardingSubscriptionId',
      params?.onboardingSubscriptionId ?? '',
    ),
});

export const lpPlatformRoutes: RouteObject[] = [
  {
    path: LpPlatformRoutes.Home,
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.Contact,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <ContactPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.ContactUs,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <ContactUsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.Document,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <DocumentPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.FinancialFlows,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <FinancialFlowsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.Commitment,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <CommitmentPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.FundShareValuations,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <FundShareValuations />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.Portfolio,
    element: (
      <ProtectedRoute restrictedTo={Roles.USER}>
        <PortfolioPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.SecurityLog,
    element: (
      <ProtectedRoute restrictedTo={Roles.DATA_MANAGER}>
        <SecurityLogPage />
      </ProtectedRoute>
    ),
  },
  {
    path: LpPlatformRoutes.OnboardingSubscription,
    element: (
      <ProtectedRoute
        restrictedTo={Roles.ONBOARDING_USER}
        featureFlags={[FeatureFlagEnum.MODULE_ONBOARDING_SUBSCRIPTION]}
      >
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: LpPlatformRoutes.LpOnboardingSubscriptionCreateId,
        element: <OnboardingSubscriptionCompletion />,
      },
      {
        path: LpPlatformRoutes.LpOnboardingSubscriptionCreateIdSign,
        element: <OnboardingSubscriptionSignature />,
      },
      ...lpCreationRoutes,
    ],
  },
];
