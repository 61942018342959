import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import LpInformationCard from 'business/lp-platform/home/components/lp-information-card';
import {
  FundManagerDashboardLpQuery,
  useWebDocumentDownloadUrlQuery,
} from 'generated/graphql';
import { InfoCard } from 'ui/info-card';
import { TextInput } from 'ui/text-input';

interface Props {
  infos: FundManagerDashboardLpQuery['lpDashboardInformation']['lpInformation'];
  lpName: string;
  logoDocumentId?: string | null;
}

const GeneralInfosCard = ({ infos, lpName, logoDocumentId }: Props) => {
  const { t } = useTranslation();

  const { data } = useWebDocumentDownloadUrlQuery({
    variables: {
      input: {
        ids: [logoDocumentId ?? ''],
      },
    },
    fetchPolicy: 'network-only',
    skip: !logoDocumentId,
  });

  return (
    <InfoCard dataTestId="lp-general-card">
      <InfoCard.Title
        path={FundManagerRoutes.LpIdEditProfile.replace(
          ':lpId',
          infos.id ?? '',
        )}
      >
        {t('pages.dashboard.cards.generalInfos.title')}
      </InfoCard.Title>
      <LpInformationCard
        lpName={lpName}
        address={infos.address}
        logoPath={data?.documentDownloadUrl.url}
      >
        <>
          <Divider />
          <Stack flexGrow={1}>
            <TextInput
              multiline
              rows={5}
              label={t('pages.dashboard.cards.generalInfos.comment.label')}
              type="text"
              placeholder={t(
                'pages.dashboard.cards.generalInfos.comment.placeholder',
              )}
            />
          </Stack>
        </>
      </LpInformationCard>
    </InfoCard>
  );
};

export default GeneralInfosCard;
