import { LpCreationStepEnum } from 'generated/graphql';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

import { getDeclineReason } from './get-decline-reason';
import {
  CardProps,
  GetCardItemsParams,
  LpValidationLegalEntity,
} from './types';

type DocumentCardData =
  LpValidationLegalEntity['lpDocumentsValidationState'] & { lpId: string };

export const getDocumentCardItems = (
  { data, t, declineReasons }: GetCardItemsParams<DocumentCardData>,
  onClickDownload: ({
    id,
    fileName,
  }: {
    id: string;
    fileName: string;
  }) => Promise<void>,
): CardProps | undefined => {
  const items: ValidationItemType[] = data.lpDocuments.map((lpdoc) => ({
    type: VALIDATION_ITEM_TYPES.LINK,
    value: {
      onClick: () =>
        onClickDownload({
          id: lpdoc.document.id,
          fileName: lpdoc.document.name ?? '',
        }),
      label: t(
        `pages.fundManager.settings.lpDocumentType.values.${lpdoc.lpDocumentType?.name}`,
        { defaultValue: lpdoc.lpDocumentType?.name },
      ),
    },
  }));

  if (items.length === 0) {
    items.push({
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: '',
      value: t('pages.fundManager.lpValidation.lpDocuments.noDocuments'),
    });
  }

  const status = data.status;
  const step = LpCreationStepEnum.LpDocument;

  return {
    title: t('pages.fundManager.lpValidation.lpDocuments.title'),
    type: 'single',
    entity: {
      items,
    },
    entityId: data.lpId,
    status,
    step,
    declineReason: getDeclineReason({ status, step, declineReasons }),
  };
};
