import { SvgIcon } from '@mui/material';

interface Props {
  primaryColor: string;
}

export const TotalInvestedIcon = ({ primaryColor }: Props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M11.8463 8.87864H19.6847C20.3583 8.87864 20.9043 8.33261 20.9043 7.65905V7.65905C20.9043 6.98548 20.3583 6.43945 19.6847 6.43945H8.73376C8.0602 6.43945 7.51417 6.98548 7.51417 7.65905V7.65905"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <rect
        width="13.3901"
        height="2.43919"
        rx="1.21959"
        transform="matrix(-1 0 0 1 20.1162 4)"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <path
        d="M14.2081 11.3181H21.2589C21.9325 11.3181 22.4785 10.7721 22.4785 10.0985V10.0985C22.4785 9.42494 21.9325 8.87891 21.2589 8.87891H11.8452"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <path
        d="M13.8154 11.3174H19.6847C20.3583 11.3174 20.9043 11.8634 20.9043 12.537V12.537C20.9043 13.2105 20.3583 13.7566 19.6847 13.7566H14.9969"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <path
        d="M14.2092 16.1963H19.6847C20.3583 16.1963 20.9043 16.7423 20.9043 17.4159V17.4159C20.9043 18.0894 20.3583 18.6355 19.6847 18.6355H13.0278"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <path
        d="M14.6022 13.7568H18.1085C18.7821 13.7568 19.3281 14.3029 19.3281 14.9764V14.9764V14.9764C19.3281 15.65 18.7821 16.196 18.1085 16.196H14.6022"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <path
        d="M2.50948 14.164C2.50948 17.7146 5.29379 20.565 8.69458 20.565C12.0954 20.565 14.8797 17.7146 14.8797 14.164C14.8797 10.6134 12.0954 7.76289 8.69458 7.76289C5.29379 7.76289 2.50948 10.6134 2.50948 14.164Z"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.01993"
      />
      <path
        strokeWidth="0.5"
        stroke={primaryColor}
        d="M8.78605 16.9751C8.2313 16.9751 7.77004 16.7932 7.40229 16.4293C7.03453 16.0653 6.81949 15.572 6.75716 14.9493C6.75716 14.9493 6.73119 14.6512 7.14619 14.6512C7.56118 14.6512 7.56123 14.9493 7.56123 14.9493C7.6111 15.2647 7.73888 15.5397 7.94457 15.7742C8.1565 16.0006 8.44011 16.1139 8.7954 16.1139C9.12575 16.1139 9.36885 16.0249 9.52468 15.847C9.6805 15.661 9.75842 15.4426 9.75842 15.1919C9.75842 14.828 9.65557 14.5854 9.44988 14.4641C9.25042 14.3428 8.96681 14.2336 8.59905 14.1366C8.34973 14.0719 8.1004 13.9789 7.85108 13.8576C7.60175 13.7363 7.39294 13.5664 7.22464 13.3481C7.05635 13.1217 6.9722 12.8265 6.9722 12.4626C6.9722 11.9369 7.1218 11.5083 7.42099 11.1767C7.72641 10.8371 8.1378 10.6672 8.65515 10.6672C9.14757 10.6672 9.54961 10.829 9.86127 11.1525C10.1792 11.4679 10.363 11.9207 10.4129 12.5111C10.4129 12.5111 10.4129 12.7531 10.0857 12.7699C9.75842 12.7866 9.63687 12.5111 9.63687 12.5111C9.60571 12.2038 9.50286 11.9652 9.32833 11.7954C9.16004 11.6175 8.93253 11.5285 8.6458 11.5285C8.36531 11.5285 8.14715 11.6053 7.99132 11.759C7.84173 11.9126 7.76693 12.1148 7.76693 12.3655C7.76693 12.6081 7.86354 12.7982 8.05677 12.9357C8.25623 13.0731 8.52426 13.1904 8.86085 13.2874C9.14757 13.3683 9.41871 13.4694 9.67427 13.5907C9.93606 13.7039 10.148 13.8778 10.3101 14.1123C10.4783 14.3388 10.5625 14.6703 10.5625 15.107C10.5687 15.6489 10.4098 16.0977 10.0857 16.4535C9.76777 16.8013 9.33457 16.9751 8.78605 16.9751Z"
      />
      <path
        strokeWidth="0.5"
        stroke={primaryColor}
        d="M8.31364 10.3906C8.31364 10.1155 8.48789 9.89258 8.70284 9.89258C8.91779 9.89258 9.09204 10.1155 9.09204 10.3906V10.7226C9.09204 10.9976 8.91779 11.2206 8.70284 11.2206C8.48789 11.2206 8.31364 10.9976 8.31364 10.7226V10.3906Z"
      />
      <path
        strokeWidth="0.5"
        stroke={primaryColor}
        d="M8.31364 16.9198C8.31364 16.6448 8.48789 16.4218 8.70284 16.4218C8.91779 16.4218 9.09204 16.6448 9.09204 16.9198V17.2518C9.09204 17.5268 8.91779 17.7498 8.70284 17.7498C8.48789 17.7498 8.31364 17.5268 8.31364 17.2518V16.9198Z"
      />
      <path
        d="M8.78605 16.9751C8.2313 16.9751 7.77004 16.7932 7.40229 16.4293C7.03453 16.0653 6.81949 15.572 6.75716 14.9493C6.75716 14.9493 6.73119 14.6512 7.14619 14.6512C7.56118 14.6512 7.56123 14.9493 7.56123 14.9493C7.6111 15.2647 7.73888 15.5397 7.94457 15.7742C8.1565 16.0006 8.44011 16.1139 8.7954 16.1139C9.12575 16.1139 9.36885 16.0249 9.52468 15.847C9.6805 15.661 9.75842 15.4426 9.75842 15.1919C9.75842 14.828 9.65557 14.5854 9.44988 14.4641C9.25042 14.3428 8.96681 14.2336 8.59905 14.1366C8.34973 14.0719 8.1004 13.9789 7.85108 13.8576C7.60175 13.7363 7.39294 13.5664 7.22464 13.3481C7.05635 13.1217 6.9722 12.8265 6.9722 12.4626C6.9722 11.9369 7.1218 11.5083 7.42099 11.1767C7.72641 10.8371 8.1378 10.6672 8.65515 10.6672C9.14757 10.6672 9.54961 10.829 9.86127 11.1525C10.1792 11.4679 10.363 11.9207 10.4129 12.5111C10.4129 12.5111 10.4129 12.7531 10.0857 12.7699C9.75842 12.7866 9.63687 12.5111 9.63687 12.5111C9.60571 12.2038 9.50286 11.9652 9.32833 11.7954C9.16004 11.6175 8.93253 11.5285 8.6458 11.5285C8.36531 11.5285 8.14715 11.6053 7.99132 11.759C7.84173 11.9126 7.76693 12.1148 7.76693 12.3655C7.76693 12.6081 7.86354 12.7982 8.05677 12.9357C8.25623 13.0731 8.52426 13.1904 8.86085 13.2874C9.14757 13.3683 9.41871 13.4694 9.67427 13.5907C9.93606 13.7039 10.148 13.8778 10.3101 14.1123C10.4783 14.3388 10.5625 14.6703 10.5625 15.107C10.5687 15.6489 10.4098 16.0977 10.0857 16.4535C9.76777 16.8013 9.33457 16.9751 8.78605 16.9751Z"
        fill="none"
        stroke={primaryColor}
        strokeWidth="0.514095"
      />
      <path
        d="M8.31364 10.3906C8.31364 10.1155 8.48789 9.89258 8.70284 9.89258C8.91779 9.89258 9.09204 10.1155 9.09204 10.3906V10.7226C9.09204 10.9976 8.91779 11.2206 8.70284 11.2206C8.48789 11.2206 8.31364 10.9976 8.31364 10.7226V10.3906Z"
        fill="none"
        stroke={primaryColor}
        strokeWidth="0.514095"
      />
      <path
        d="M8.31364 16.9198C8.31364 16.6448 8.48789 16.4218 8.70284 16.4218C8.91779 16.4218 9.09204 16.6448 9.09204 16.9198V17.2518C9.09204 17.5268 8.91779 17.7498 8.70284 17.7498C8.48789 17.7498 8.31364 17.5268 8.31364 17.2518V16.9198Z"
        fill="none"
        stroke={primaryColor}
        strokeWidth="0.514095"
      />
    </SvgIcon>
  );
};
