import { ComponentProps } from 'react';
import { useController } from 'react-hook-form';

import { NaceCodeFormPart } from 'ui/nace-code';

interface NaceCodeInputProps
  extends Omit<ComponentProps<typeof NaceCodeFormPart>, 'onChange'> {
  control: any;
  name: string;
}

export const NaceCodeInput = ({
  name,
  required,
  control,
  ...rest
}: NaceCodeInputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: { required },
  });

  return (
    <NaceCodeFormPart
      {...rest}
      required={required}
      name={field.name}
      value={field.value}
      error={error}
      onChange={(value: string | undefined) => field.onChange(value ?? '')}
    />
  );
};
