import i18next from 'i18next';

import {
  Dataviz_DocumentType,
  Web_Portal_TemplateList,
} from 'generated/graphql';
import { FuzzyFilterEnabledColumn } from 'technical/filter/fuzzy-filter/constants';

export const getLocalLabel = (
  type: Pick<
    Dataviz_DocumentType | Web_Portal_TemplateList,
    'labelEn' | 'labelFr'
  >,
) => {
  const appLanguage = i18next.resolvedLanguage;
  switch (appLanguage) {
    case 'en':
      return type.labelEn;
    case 'fr':
      return type.labelFr;
    default:
      return type.labelEn;
  }
};

export const getLocalDocumentType = () => {
  const appLanguage = i18next.resolvedLanguage;
  switch (appLanguage) {
    case 'en':
      return FuzzyFilterEnabledColumn.DocumentTypeEnLabel;
    case 'fr':
      return FuzzyFilterEnabledColumn.DocumentTypeFrLabel;
    default:
      return FuzzyFilterEnabledColumn.DocumentTypeEnLabel;
  }
};
