import { simpleClauseBuilder } from 'technical/filter/build-where-condition';
import { QueryParams } from 'technical/filter/types';
import { isString, isStringArray } from 'technical/typeguard';

function financialFlowFundNameBuilder(gqlOperator: {
  [key: string]: unknown;
}): QueryParams {
  return {
    share: {
      fund: {
        name: gqlOperator,
      },
    },
  };
}

function financialFlowShareTypeBuilder(gqlOperator: {
  [key: string]: unknown;
}): QueryParams {
  return {
    share: {
      type: gqlOperator,
    },
  };
}

function financialFlowOperationIdentifierBuilder(gqlOperator: {
  [key: string]: unknown;
}): QueryParams {
  const termValue = Object.values(gqlOperator)[0];
  const operatorKey = Object.keys(gqlOperator)[0];

  if (isString(termValue)) {
    const [operationType, identifier] = termValue.split(' ');

    return {
      _and: [
        { type: { [operatorKey]: operationType } },
        { identifier: { [operatorKey]: identifier } },
      ],
    };
  }

  if (isStringArray(termValue)) {
    let operationTypeArray: string[] = [];
    let identifierArray: string[] = [];

    termValue.forEach((value) => {
      const [operationType, identifier] = value.split(' ');
      operationTypeArray = Array.from(
        new Set([...operationTypeArray, operationType]),
      );
      identifierArray = Array.from(new Set([...identifierArray, identifier]));
    });

    return {
      _and: [
        { type: { [operatorKey]: operationTypeArray } },
        { identifier: { [operatorKey]: identifierArray } },
      ],
    };
  }

  return {};
}

export const buildFinancialFlowNestedClauses = (
  field: string,
  gqlOperator: {
    [key: string]: unknown;
  },
): QueryParams => {
  if (field === 'fundName') {
    return financialFlowFundNameBuilder(gqlOperator);
  }
  if (field === 'shareType') {
    return financialFlowShareTypeBuilder(gqlOperator);
  }
  if (field === 'identifier') {
    return financialFlowOperationIdentifierBuilder(gqlOperator);
  }
  return simpleClauseBuilder(field, gqlOperator);
};
