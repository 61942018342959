import { LinearProgress } from '@mui/material';

import styles from './index.module.scss';

const getValidPercent = (value: number) => {
  if (value < 0) {
    return 0;
  }

  if (value > 1) {
    return 100;
  }

  return value * 100;
};

interface Props {
  value: number;
}

export const CustomProgressBar = ({ value }: Props) => {
  return (
    <LinearProgress
      className={styles.linearProgress}
      color="primary"
      variant="determinate"
      value={getValidPercent(value)}
    />
  );
};
