import { Avatar, Stack, Typography } from '@mui/material';

import styles from './index.module.scss';

interface Props {
  name: string;
  logoPath: string;
  description?: string;
  alt?: string;
  customAvatar?: string;
}

const VerticalAvatarTitle = ({
  name,
  logoPath,
  description,
  alt,
  customAvatar,
}: Props) => {
  return (
    <Stack direction={'column'} spacing={2} className={styles.centerElements}>
      <Avatar
        alt={alt ?? name}
        src={logoPath}
        sx={{ height: '72px', width: '72px' }}
      >
        {customAvatar}
      </Avatar>
      <Stack>
        <Typography className={styles.subtitle} align="center">
          {name}
        </Typography>
        {description ? (
          <Typography variant="body2">{description}</Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default VerticalAvatarTitle;
