import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { NavigateFunction } from 'react-router';
import { TFunction } from 'translations/hook';

import Status from 'business/fund-manager/operation/components/status';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import {
  CurrencyEnum,
  GetOperationsWithDetailsQuery,
  PublicationStatus,
  Web_Portal_ManagementCompanyType_Enum,
  Web_Portal_OperationStatus_Enum,
  Web_Portal_PublicationStatus_Enum,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatRowValueFrom10X8ToMonetary } from 'technical/ag-grid/row-value-formatters';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { PercentBarCell } from 'ui/alternative-table/components/percent-bar-cell';
import { ValidationButton } from 'ui/validation-button';

import { OperationsFilteredFields } from './types';

export function getOperationColumnDefinition({
  t,
  customFilters,
  navigate,
  managementCompanyType,
}: {
  t: TFunction;
  customFilters: OperationsFilteredFields;
  navigate: NavigateFunction;
  managementCompanyType?: Web_Portal_ManagementCompanyType_Enum;
}): ColDef<
  GetOperationsWithDetailsQuery['getOperationsWithDetails'][number]
>[] {
  return [
    {
      field: 'fundName',
      filterParams: customFilters.fundNames,
      headerName: t('pages.fundManager.operation.table.fund'),
    },
    {
      field: 'identifier',
      headerName: t('pages.fundManager.operation.table.operationIdentifier'),
    },
    {
      headerName: t('pages.fundManager.operation.table.type.title'),
      field: 'type',
      valueFormatter: ({ value }) =>
        value ? t(`pages.fundManager.operation.table.type.cell_${value}`) : '-',
    },
    {
      headerName: t('pages.fundManager.operation.table.amountWithPremium'),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
      colId: 'amountWithPremium',
      valueFormatter: ({
        value,
        node,
      }: {
        value: number | string;
        node: IRowNode<
          GetOperationsWithDetailsQuery['getOperationsWithDetails'][number]
        > | null;
      }) => {
        return typeof value === 'string'
          ? value
          : formatRowValueFrom10X8ToMonetary({
              value,
              node,
              context: { currency: CurrencyEnum.Eur },
            });
      },
      valueGetter: ({ data, node }) => {
        if (node?.group) {
          return '';
        }

        if (!data?.type) {
          // no type means no share lp so nothing to display
          return '-';
        }
        return Math.abs(data.amountWithPremium);
      },
      filterValueGetter: ({ data, node }) => {
        if (node?.group) {
          return '';
        }

        if (!data?.type) {
          // no type means no share lp so nothing to display
          return '-';
        }

        return convertFrom10X8toNumber(Math.abs(data.amountWithPremium));
      },
      getQuickFilterText: ({ data }) =>
        convertFrom10X8toNumber(Math.abs(data.amountWithPremium)).toString(),
    },
    {
      headerName: t('pages.fundManager.operation.table.date'),
      type: AgGridColumnTypesEnum.DATE,
      field: 'date',
      filter: 'agDateColumnFilter',
    },
    {
      headerName: t('pages.fundManager.operation.table.status.title'),
      filterParams: customFilters.status.map((value) =>
        t('pages.fundManager.operation.table.status.cell', {
          context: value,
        }),
      ),
      colId: 'status',
      valueGetter: ({ data }) =>
        data?.publicationStatus === PublicationStatus.PendingValidation
          ? PublicationStatus.PendingValidation
          : data?.status,
      cellRenderer: (params: ICellRendererParams) => (
        <Status status={params.value} />
      ),
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      headerName: t('pages.fundManager.operation.table.progress'),
      type: 'interaction',
      cellRenderer: (params: ICellRendererParams) => (
        <PercentBarCell
          percent={params.data.cashRatio}
          label={formatToMonetaryAmount(
            convertFrom10X8toNumber(params.data.totalCashFlowAmount),
            { currency: params.data.currency },
          )}
        />
      ),
    },
    {
      headerName: t('pages.fundManager.operation.table.details.header'),
      type: AgGridColumnTypesEnum.INTERACTION,
      colId: 'interaction',
      cellRenderer: ({
        value,
        data,
      }: ICellRendererParams<
        GetOperationsWithDetailsQuery['getOperationsWithDetails'][number]
      >) => {
        if (!value || !data) {
          return null;
        }

        const isCellPendingValidation =
          managementCompanyType !==
            Web_Portal_ManagementCompanyType_Enum.Advisor &&
          data.publicationStatus === PublicationStatus.PendingValidation;

        const showPendingValidationCashflowCount =
          managementCompanyType !==
            Web_Portal_ManagementCompanyType_Enum.Advisor &&
          data.publicationStatus === PublicationStatus.Validated;

        return (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="start"
            height="100%"
          >
            <ValidationButton
              onClick={() => navigate(value)}
              color={isCellPendingValidation ? 'validate' : undefined}
            >
              {isCellPendingValidation
                ? t('pages.fundManager.operation.table.validate')
                : t('pages.fundManager.operation.table.details.label')}
            </ValidationButton>
            {showPendingValidationCashflowCount &&
              data?.pendingValidationCashflowCount > 0 && (
                <PendingChipCount
                  count={data?.pendingValidationCashflowCount}
                />
              )}
          </Stack>
        );
      },
      valueGetter: ({ data }) => {
        if (!data) {
          return null;
        }

        if (data.status !== Web_Portal_OperationStatus_Enum.Draft) {
          if (
            managementCompanyType !==
              Web_Portal_ManagementCompanyType_Enum.Advisor &&
            data.publicationStatus ===
              Web_Portal_PublicationStatus_Enum.PendingValidation
          ) {
            return FundManagerRoutes.OperationCreateIdRecap.replace(
              ':operationId',
              data.id,
            );
          }

          return FundManagerRoutes.OperationId.replace(':operationId', data.id);
        }

        // if there is a type there is share lp
        if (!!data.type) {
          return FundManagerRoutes.OperationCreateIdSharesLp.replace(
            ':operationId',
            data.id,
          );
        }

        return FundManagerRoutes.OperationCreateIdShares.replace(
          ':operationId',
          data.id,
        );
      },
      headerClass: 'ag-right-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
  ];
}
