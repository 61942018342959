import { ApolloProvider } from '@apollo/client';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { LicenseManager } from 'ag-grid-enterprise';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SnackbarProvider } from 'notistack';

import { CustomThemeProvider } from 'business/providers/theme/components';
import config from 'config';
import { ErrorBoundary } from 'technical/error-boundary';
// eslint-disable-next-line import/order
import apolloClient from 'technical/graphql/client';

import './app.css';
import './app.scss';

import { ConsentBanner } from 'ui/cookie/consent-banner';

import { ProductAnalyticsSetup } from './analytics/product-analytics-setup';
import { AppProvider } from './providers/app-provider';
import { LanguageProvider } from './providers/language-provider';
import Router from './router';

LicenseInfo.setLicenseKey(config.muiXPremiumLicenseKey);
LicenseManager.setLicenseKey(config.agGridProLicenseKey);

export const App = () => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <CustomThemeProvider>
          <LanguageProvider>
            <SnackbarProvider maxSnack={3}>
              <AppProvider>
                <>
                  <ConsentBanner />
                  <Router />
                  <ProductAnalyticsSetup />
                </>
              </AppProvider>
            </SnackbarProvider>
          </LanguageProvider>
        </CustomThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
