import { FC } from 'react';
import { useTranslation } from 'translations/hook';

import { FormattedOnboardingSubscription } from 'business/fund-manager/onboarding-subscription/services/format-onboarding-subscriptions';
import { OnSeeDetails } from 'business/fund-manager/onboarding-subscription/services/get-onboarding-subscription-column-definition';
import { onboardingStatusNoDetailButton } from 'business/fund-manager/onboarding-subscription/services/types';
import { OnboardingSubscriptionStatusEnum } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';

interface Props {
  data: FormattedOnboardingSubscription | undefined;
  onSeeDetails: OnSeeDetails;
}

export const OnboardingSubscriptionDetailButton: FC<Props> = (props) => {
  const { data, onSeeDetails } = props;
  const { t } = useTranslation();
  const enableDetails =
    data &&
    !onboardingStatusNoDetailButton.includes(
      data.status as OnboardingSubscriptionStatusEnum,
    );

  return (
    <div className="flex items-center h-full">
      <ActionButton
        variant="secondary"
        size="small"
        disabled={!enableDetails}
        onClick={enableDetails ? () => onSeeDetails(data.id) : undefined}
        className="flex-1"
        tooltip={
          enableDetails
            ? t('pages.fundManager.onboardingSubscriptions.tooltipDetails')
            : t('pages.fundManager.onboardingSubscriptions.tooltipNoDetails')
        }
      >
        {t('common.seeDetails')}
      </ActionButton>
    </div>
  );
};
