import DownloadIcon from '@mui/icons-material/Download';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { GENERATED_FILE_NAME } from 'business/fund-manager/settings/template/services/constants';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { usePreviewDocumentInSettingsMutation } from 'generated/graphql';
import { downloadFile } from 'technical/download-file';
import { ValidationErrors } from 'technical/validation/types';
import { Button } from 'ui/button';

export const DownloadDocumentTemplatePreview = ({
  documentTemplateId,
}: {
  documentTemplateId: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const palette = usePalette();
  const [previewDoc, { loading }] = usePreviewDocumentInSettingsMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: 'error',
      });
    },
  });
  const onClick = async ({}) => {
    const resp = await previewDoc({
      variables: {
        input: {
          documentTemplateId,
        },
      },
    });
    if (
      resp.data?.previewDocumentInSettings?.success &&
      resp.data?.previewDocumentInSettings.url
    ) {
      downloadFile(
        resp.data.previewDocumentInSettings.url,
        GENERATED_FILE_NAME,
      );
    }
  };
  return (
    <>
      <Button
        onClick={onClick}
        sx={{ color: palette.secondary[800] }}
        disabled={loading}
      >
        <DownloadIcon />
      </Button>
    </>
  );
};
