import * as yup from 'yup';

import {
  ActiveFundsAndLpsQuery,
  OnboardingSubscriptionStatusEnum,
  OnboardingSubscriptionsQuery,
} from 'generated/graphql';

import {
  createNewLpInOnboardingFormSchema,
  createOnboardingSubscriptionFormSchema,
  onboardingSubscriptionsFormSchema,
  selectSignatoryFormSchema,
  uploadSignedSubscriptionDocumentSchema,
} from './validation';

export enum OnboardingSubscriptionNavBarTabEnum {
  Requested = 0,
  Unsuccessful = 1,
  History = 2,
}

export type SubscriptionsPerTab = Record<
  OnboardingSubscriptionNavBarTabEnum,
  OnboardingSubscriptionsQuery['requested']
>;

export type CountsPerTab = Record<OnboardingSubscriptionNavBarTabEnum, number>;

export type CreateOnboardingSubscription = yup.InferType<
  typeof createOnboardingSubscriptionFormSchema
>;

export type OnboardingSubscriptionsForm = yup.InferType<
  typeof onboardingSubscriptionsFormSchema
>;

export const onboardingStatusWaitingForLpActions = [
  OnboardingSubscriptionStatusEnum.WaitingForLpSignature,
  OnboardingSubscriptionStatusEnum.InvitationSent,
  OnboardingSubscriptionStatusEnum.SubInfoDeclined,
];

export const onboardingStatusNoDetailButton = [
  OnboardingSubscriptionStatusEnum.WaitingForLpSignature,
  OnboardingSubscriptionStatusEnum.InvitationSent,
  OnboardingSubscriptionStatusEnum.SubInfoDeclined,
];

export type CreateNewLpInOnboardingForm = yup.InferType<
  typeof createNewLpInOnboardingFormSchema
>;

export function isLPFromActiveFundsAndLpsQuery(
  lp:
    | CreateNewLpInOnboardingForm
    | ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
): lp is ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number] {
  return (
    (lp as ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number]).id !==
    undefined
  );
}

export type UploadSignedSubscriptionDocument = yup.InferType<
  typeof uploadSignedSubscriptionDocumentSchema
>;

export type SelectSignatoryForm = yup.InferType<
  typeof selectSignatoryFormSchema
>;
