import { UpdateCompanyManagerForm } from 'business/fund-manager/settings/management-company/services/types';
import { managementCompanyTypeSchema } from 'business/fund-manager/settings/management-company/services/validation';
import { GetManagementCompanyQuery } from 'generated/graphql';

export const setFormDefaultValues = (
  data: NonNullable<GetManagementCompanyQuery['companyInfos']>,
): UpdateCompanyManagerForm => {
  const {
    id,
    name,
    identifier,
    legalForm,
    regulatorApprovalNumber,
    address,
    type,
  } = data;
  return {
    companyInformation: {
      id,
      name,
      legalForm,
      identifier,
      regulatorApprovalNumber,
      type: managementCompanyTypeSchema.required().validateSync(type),
    },
    addressInformation: {
      id: address?.id ?? '',
      streetLine: address?.streetLine ?? '',
      streetLine2: address?.streetLine2 ?? '',
      zipCode: address?.zipCode ?? '',
      city: address?.city ?? '',
      country: address?.country ?? '',
    },
  };
};
