import { yupResolver } from '@hookform/resolvers/yup';
import { InfoOutlined } from '@mui/icons-material';
import { Divider, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CreateValuationFormInput } from 'business/fund-manager/portfolio/valuation/services/types';
import { createValuationForm } from 'business/fund-manager/portfolio/valuation/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useCreateValuationMutation } from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { Button } from 'ui/button';
import { FormCard, FormInputDate } from 'ui/form';

export const ValuationCreationForm = () => {
  const { fundId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<CreateValuationFormInput>({
    resolver: yupResolver(createValuationForm),
  });

  const [createValuation, { loading }] = useCreateValuationMutation();
  const onSubmit: SubmitHandler<CreateValuationFormInput> = (input) => {
    createValuation({
      variables: { input: { fundId, date: formatAsDate(input.date) } },
      onCompleted: (data) => {
        navigate(
          FundManagerRoutes.PortfolioFundIdValuationId.replace(
            ':fundId',
            fundId,
          ).replace(':valuationId', data.createValuation.id),
        );
        enqueueSnackbar(t('successMessage.createValuation'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: (error) => {
        enqueueSnackbar(t(error.message), {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <Typography variant="h6">
          {t('pages.fundManager.portfolio.valuation.form.title')}
        </Typography>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          justifyContent="space-around"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <InfoOutlined />
            <Stack>
              <Typography>
                {t('pages.fundManager.portfolio.valuation.form.noValuation')}
              </Typography>

              <Typography>
                <Trans
                  i18nKey={
                    'pages.fundManager.portfolio.valuation.form.clickToCreate'
                  }
                />
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <FormInputDate
              name="date"
              control={control}
              label={t('pages.fundManager.portfolio.valuation.form.date')}
            />

            <Button
              variant="contained"
              type="submit"
              data-test-id="create-new-valuation"
              disabled={loading}
            >
              {t('pages.fundManager.portfolio.valuation.form.submit')}
            </Button>
          </Stack>
        </Stack>
      </FormCard>
    </form>
  );
};
