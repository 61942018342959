import { ValuationGraphData } from 'business/shared/services/types';
import { GetFundShareValuationQuery } from 'generated/graphql';

export const getFundShareValuationGraphData = (
  queryData: GetFundShareValuationQuery['fundShareValuations'],
): ValuationGraphData[] => {
  return queryData
    .filter(
      (valuation): valuation is Exclude<typeof valuation, null> => !!valuation,
    )
    .map((data) => ({
      valuationDate: data.date,
      // TODO rename paidIn to drawn
      paidIn: data.numericTotalDrawn,
      distributed: data.numericTotalDistributed,
      distributedRecallable: data.numericTotalDistributedRecallable,
      residual: data.numericResidualValue,
      dpi: data.dpi,
      rvpi: data.rvpi,
      tvpi: data.tvpi,
    }));
};
