import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import ConnectedPage from 'business/user/helpers/connected-page';
import { FormCard, FormWizzard } from 'ui/form';

type CreationPageContainerProps = PropsWithChildren<{
  activeStep?: number;
  onSubmit?: () => void;
  previousPath: string;
}>;

export const CreationPageContainer = ({
  children,
  activeStep = 0,
  onSubmit,
  previousPath,
}: CreationPageContainerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const steps = [
    {
      label: t('pages.fundManager.fundCreation.form.generalInformation.title'),
    },
    {
      label: t('pages.fundManager.fundCreation.form.bankDetails.stepper'),
    },
    {
      label: t('pages.fundManager.fundCreation.form.share.title'),
    },
  ];

  return (
    <ConnectedPage title={t('pages.fundManager.fundCreation.title')}>
      <FormWizzard
        steps={steps}
        activeStep={activeStep}
        onNext={onSubmit}
        onPrevious={() => navigate(previousPath)}
      />
      <FormCard>{children}</FormCard>
    </ConnectedPage>
  );
};
