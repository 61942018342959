import * as yup from 'yup';

import { DefaultDataRankEnum, DefaultPeriodTypeEnum } from 'generated/graphql';
import { nonEmptyStringSchema } from 'technical/validation';

export const createOrUpdateCompanyMetricsTypeFormSchema = yup.object({
  id: yup.string(),
  name: nonEmptyStringSchema,
  unit: nonEmptyStringSchema,
  managementCompanyId: nonEmptyStringSchema,
  isDeletable: yup.boolean().required(),
  defaultPeriodType: yup
    .mixed<DefaultPeriodTypeEnum>()
    .oneOf(Object.values(DefaultPeriodTypeEnum))
    .required('errors.required'),
  defaultDataRank: yup
    .mixed<DefaultDataRankEnum>()
    .oneOf(Object.values(DefaultDataRankEnum))
    .required('errors.required'),
});
