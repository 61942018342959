import { useTranslation } from 'translations/hook';

import { LpOnboardingDocument } from 'business/fund-manager/fund/services/types';
import { getLpOnboardingDocumentsColumnDefinition } from 'business/shared/services/document/get-lp-onboarding-documents-column-definition';
import { getLpOnboardingDocumentsRows } from 'business/shared/services/document/get-lp-onboarding-documents-rows';
import { GetLpKycDocumentsQueryResult } from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';

import styles from './index.module.scss';

interface LpDocumentationTableProps {
  data: GetLpKycDocumentsQueryResult['data'];
  loading: boolean;
}
export const LpDocumentationTable = ({
  data,
  loading,
}: LpDocumentationTableProps) => {
  const { t } = useTranslation();

  const rows: LpOnboardingDocument[] = getLpOnboardingDocumentsRows({
    data,
    t,
  });

  const headers = getLpOnboardingDocumentsColumnDefinition({
    t,
    documentStatusClassName: styles.documentStatus,
  });

  return (
    <AlternativeTable<LpOnboardingDocument>
      fileName={'Investor-Documents'}
      rowData={rows}
      columnDefs={headers}
      loading={loading}
      domLayout="autoHeight"
    />
  );
};
