import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { Web_Portal_ManagementCompanyType_Enum } from 'generated/graphql';

export const FundCreationGuard = ({ children }: PropsWithChildren) => {
  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  if (isAdvisor) {
    return <Navigate to={FundManagerRoutes.Fund} replace />;
  }

  return <>{children}</>;
};
