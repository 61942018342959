export const sideBarDefinition = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: true,
      },
      hiddenByDefault: true,
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: true,
      },
      hiddenByDefault: true,
    },
  ],
};
