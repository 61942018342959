import { Divider, Stack } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import { OnBoardingStatusEnum } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { EmptyContent } from 'ui/empty-card/content';
import { ValidationCardContainer } from 'ui/validation-card-container/validation-card-container';
import { ValidationItem, ValidationItemType } from 'ui/validation-item';

export type ValidationEntityTypeSingle = {
  type: 'single';
  entity: {
    items: ValidationItemType[];
    onEdit?: () => void;
  };
};

export type ValidationEntityTypeMultiple = {
  type: 'multiple';
  entities: ValidationEntityTypeSingle['entity'][];
};

export type ValidationEntityTypeItem =
  | ValidationEntityTypeSingle
  | ValidationEntityTypeMultiple;

export type ValidationEntityProps = PropsWithChildren<ValidationEntityTypeItem>;

const ValidationEntityBlock = ({
  items,
  onEdit,
}: ValidationEntityTypeSingle['entity']) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={2}>
      <Stack overflow="hidden" gap={1}>
        {items.map((item, itemIndex) => (
          <ValidationItem key={`entityBlock__${itemIndex}`} item={item} />
        ))}
        {onEdit ? (
          <Stack alignSelf="center" justifyContent="end" flexGrow={1}>
            <ActionButton size="small" variant="edit" onClick={onEdit}>
              {t('common.actions.edit')}
            </ActionButton>
          </Stack>
        ) : null}
      </Stack>
      <Divider orientation="vertical" />
    </Stack>
  );
};

const EmptyValidationEntity = () => (
  <Stack
    direction={'column'}
    gap={2}
    flexGrow={1}
    padding={3}
    alignItems="center"
    justifyContent="center"
  >
    <EmptyContent />
  </Stack>
);

const ValidationEntity = ({
  children,
  ...rest
}: PropsWithChildren<ValidationEntityProps>) => {
  const { t } = useTranslation();
  if (rest.type === 'single') {
    const { entity } = rest;

    return (
      <Stack flexGrow={1}>
        <Stack
          direction="column"
          gap={1}
          flexGrow={1}
          padding={1}
          flexWrap="wrap"
          overflow="auto"
          sx={{ maxHeight: '200px' }}
        >
          {entity.items.map((item, i) => (
            <ValidationItem key={`${item.type}_${i}`} item={item} />
          ))}
          {children}
        </Stack>
        {entity.onEdit ? (
          <Stack alignSelf="end">
            <ActionButton size="small" variant="edit" onClick={entity.onEdit}>
              {t('common.actions.edit')}
            </ActionButton>
          </Stack>
        ) : null}
      </Stack>
    );
  }

  if (rest.entities.length === 0) {
    return <EmptyValidationEntity />;
  }

  return (
    <Stack
      direction="row"
      alignItems="strech"
      flexWrap="wrap"
      gap={2}
      padding={1}
    >
      {rest.entities.map((entity, entityIndex) => (
        <ValidationEntityBlock key={`entity-${entityIndex}`} {...entity} />
      ))}
    </Stack>
  );
};

type ValidationCardProps = ValidationEntityProps &
  ComponentProps<typeof ValidationCardContainer>;

export function ValidationCard({
  status = OnBoardingStatusEnum.PendingValidation,
  declineReason,
  onApprove,
  onDecline,
  approving,
  declining,
  title,
  expand = false,
  ...rest
}: ValidationCardProps) {
  return (
    <ValidationCardContainer
      expand={expand}
      declineReason={declineReason}
      title={title}
      status={status}
      onApprove={onApprove}
      onDecline={onDecline}
      approving={approving}
      declining={declining}
    >
      <Stack direction={'column'} gap={2} flexGrow={1}>
        <ValidationEntity {...rest} />
      </Stack>
    </ValidationCardContainer>
  );
}
