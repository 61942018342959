import { useTranslation } from 'translations/hook';

import {
  GraphData,
  OperationType,
} from 'business/lp-platform/financial-flows/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';
import variables from 'ui/variables.module.scss';

interface Props {
  financialFlowsData: GraphData;
  fileName: string;
}
export const FinancialFlowsGraph = ({
  financialFlowsData,
  fileName,
}: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const plotLayout: PlotLayout = {
    colorway: [palette.secondary[800]],
    xaxis: {
      tickformat: '%Y',
      dtick: 'M24',
    },
    xaxis2: {
      // axe that filters which traces will be shown in rangeSLider (xaxis is set on x2)
      tickformat: '%Y',
      dtick: 'M24',
      matches: 'x',
      overlaying: 'x',
      rangeslider: {
        thickness: parseFloat(variables.graphRangeSliderTickness),
      },
    },
    autosize: true,
    showlegend: false,
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    hoverlabel: {
      bgcolor: palette.backgroundForm,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTextSize),
        color: palette.text,
      },
      bordercolor: palette.backgroundForm,
    },
    barmode: 'stacked',
    margin: {
      pad: parseFloat(variables.graphAxisPadding),
    },
  };

  const drawdowns = {
    name: OperationType.Drawdown,
    x: financialFlowsData.dueDates,
    y: financialFlowsData.drawdownAmounts,
    customdata: financialFlowsData.customData,
    hovertemplate: `<b>Q%{x|%q} - %{x|%Y}</b><br><br>${t(
      'pages.financialFlows.graph.drawdowns',
    )}: <b>%{customdata.drawdownCount}</b><br>${t(
      'pages.financialFlows.graph.totalDrawdown',
    )}: <b>%{customdata.formattedAbsoluteAmountDrawdown}</b><extra></extra>`,
    marker: {
      color: palette.primary[800],
    },
    offsetgroup: 2,
    type: PlotChartTypeEnum.bar,
  };

  const distributions = {
    name: OperationType.Distribution,
    x: financialFlowsData.dueDates,
    y: financialFlowsData.distributionAmounts,
    customdata: financialFlowsData.customData,
    hovertemplate: `<b>Q%{x|%q} - %{x|%Y}</b><br><br>${t(
      'pages.financialFlows.graph.distribution',
    )}: <b>%{customdata.distributionCount}</b><br>${t(
      'pages.financialFlows.graph.totaldistributed',
    )}: <b>%{customdata.formattedDistributionAmount}</b><extra></extra>`,
    marker: {
      color: palette.secondary[800],
    },
    offsetgroup: 2,
    type: PlotChartTypeEnum.bar,
  };

  const cumulatedCashFlow = {
    name: 'cumulativeCashflow',
    x: financialFlowsData.dueDates,
    y: financialFlowsData.cumulativeCashflowValues,
    customdata: financialFlowsData.customData,
    hovertemplate: `${t(
      'pages.financialFlows.graph.date',
    )}:  <b>%{x|%Y-%m-%d}</b> <br> ${t(
      'pages.financialFlows.graph.cumulativeCashflow',
    )}:  <b>%{customdata.formattedCumulativeCashflowValue}</b><extra></extra>`,
    line: {
      color: palette.secondary[500],
    },
    type: PlotChartTypeEnum.scatter,
  };

  const rangeSliderTrace = {
    name: 'rangeSLider',
    xaxis: 'x2',
    x: financialFlowsData.dueDates,
    y: financialFlowsData.cumulativeCashflowValues,
    opacity: 0, // overriden by css in PlotChart
    hoverinfo: 'skip',
    type: PlotChartTypeEnum.scatter,
    fill: 'tonexty',
    fillcolor: 'transparent',
    line: {
      color: 'transparent',
    },
  };

  const dataChart = [
    drawdowns,
    distributions,
    cumulatedCashFlow,
    rangeSliderTrace,
  ];

  return (
    <PlotChart dataArray={dataChart} layout={plotLayout} fileName={fileName} />
  );
};
