import Plot from 'react-plotly.js';

// NOTE: we need to use a regular scss file, not a module file with style otherwise plotly css overriting does not work
import './style.scss';
import { PlotData, PlotLayout } from './types';
interface PlotChartProps {
  dataArray: PlotData[]; // A chart can take multiple dataArrays in params
  layout?: PlotLayout;
  fileName?: string;
  displayModeBar?: boolean;
}

type PlotConfig = {
  scrollZoom?: boolean;
  responsive?: boolean;
  showTips?: boolean;
  displayModeBar?: boolean;
  displaylogo?: boolean; // hide plotly logo
  modeBarButtonsToRemove?: string[]; // hide options from pltoly interraction bar on graphs
  toImageButtonOptions?: {
    format?: string; // one of png, svg, jpeg, webp
    filename?: string;
    height?: number;
    width?: number;
    scale?: number; // Multiply title/legend/axis/canvas sizes by this factor},
  };
};

const PlotChart = ({
  dataArray,
  layout,
  fileName,
  displayModeBar = true,
}: PlotChartProps) => {
  const config: PlotConfig = {
    scrollZoom: false,
    // NOTE responsive: you MUST put your charts into a height sized container (setting height in plotLayour it not enough).
    // Otherwhise charts might break on window resize
    responsive: true,
    showTips: false,
    displaylogo: false,
    displayModeBar: displayModeBar,
    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
    toImageButtonOptions: {
      format: 'png',
      filename: fileName,
      height: 500,
      width: 1400,
      scale: 3, // Multiply title/legend/axis/canvas sizes by this factor},
    },
  };

  return (
    <Plot
      data={dataArray}
      layout={layout}
      useResizeHandler={false}
      style={{ width: '100%', height: '100%' }}
      config={config}
      className="PlotlyCustomPlot"
    />
  );
};

export default PlotChart;
