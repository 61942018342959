import { ComponentProps } from 'react';

import { customQuestionSchema } from 'business/shared/lp-creation-module/services/validation';
import { CustomQuestionsQuery } from 'generated/graphql';
import { isCustomQuestionTypeInput } from 'technical/custom-questions';
import { FormSection } from 'ui/form';

import { CustomFormInput } from './custom-form-input';
import { CustomFormText } from './custom-form-text';

const isValidCustomQuestion = (
  customQuestion: CustomQuestionsQuery['customQuestions'][number],
): customQuestion is ComponentProps<typeof CustomFormInput> =>
  customQuestionSchema.isType(customQuestion);

interface CustomQuestionsFormPartProps {
  items: CustomQuestionsQuery['customQuestions'];
}
export const CustomQuestionsFormPart = ({
  items,
}: CustomQuestionsFormPartProps) => {
  // Store ID of the answer in the array "customAnswers" for react hook form
  // If the "custom question" is a text component, we don't want to increment i
  // Therefore we can't use the index given by the 'map' function
  let i = 0;

  return (
    <FormSection lastOne>
      {items
        .filter(isValidCustomQuestion)
        .map(({ id, ...rest }) =>
          isCustomQuestionTypeInput(rest.type) ? (
            <CustomFormInput
              {...rest}
              id={id}
              name={`customAnswers.${i++}`}
              key={id}
            />
          ) : (
            <CustomFormText {...rest} key={id} />
          ),
        )}
    </FormSection>
  );
};
