import { ICellRendererParams } from 'ag-grid-community';

import { EditCellClue } from './edit-cell-clue';
import { isCellEditable } from './utils';

export const TextCell = (params: ICellRendererParams) => {
  const isEditable = isCellEditable(params);

  return (
    <>
      <div className="relative h-full w-full flex items-center">
        <p className="truncate">{params.value}</p>
      </div>
      {isEditable && <EditCellClue params={params} />}
    </>
  );
};
