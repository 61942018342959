import { ApolloError } from '@apollo/client';

import { PendingChip } from 'ui/pending-chip';

type Props = {
  count?: number;
  loading?: boolean;
  error?: ApolloError;
};

export const PendingChipCount = ({ count, loading, error }: Props) => {
  // do not display chip if 0
  if (loading || error || !count) {
    return null;
  }

  return <PendingChip label={count} />;
};
