import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { PublicationStatus, SharesQuery } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { ActionButton } from 'ui/action-button';

export const getFundSharesColumnDefinition = ({
  t,
  validateShare,
  isFundManager,
}: {
  t: TFunction;
  validateShare: (id: string) => Promise<void>;
  isFundManager: boolean;
}): ColDef<SharesQuery['shares'][number]>[] => {
  const columns: ColDef<SharesQuery['shares'][number]>[] = [
    {
      field: 'name',
      headerName: t('pages.fundManager.fundShares.shareList.table.name'),
      filter: true,
    },
    {
      field: 'numericNominalValue',
      headerName: t(
        'pages.fundManager.fundShares.shareList.table.nominalValue',
      ),
      type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
    },
    {
      field: 'isin',
      headerName: t('pages.fundManager.fundShares.shareList.table.isin'),
    },
  ];

  if (isFundManager) {
    columns.push({
      field: 'publicationStatus',
      headerName: t(
        'pages.fundManager.fundShares.shareList.table.publicationStatus.headerName',
      ),
      type: AgGridColumnTypesEnum.INTERACTION,
      cellRenderer: ({
        value,
        data,
      }: ICellRendererParams<SharesQuery['shares'][number]>) => {
        if (
          [PublicationStatus.Draft, PublicationStatus.Validated].includes(
            value,
          ) ||
          !data
        ) {
          return null;
        }
        return (
          <ActionButton
            variant={'submit'}
            onClick={() => validateShare(data.id)}
          >
            {t(
              'pages.fundManager.fundShares.shareList.table.publicationStatus.validateButton',
            )}
          </ActionButton>
        );
      },
    });
  }

  return columns;
};
