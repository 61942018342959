export const deprecatedGetAmountPerColumn = (
  type: string,
  amount: number,
  column: string,
) => (type === column ? amount : 0);

export const getAmountPerColumn = (
  type: string,
  numericAmount: number,
  column: string,
) => (type === column ? numericAmount : 0);
