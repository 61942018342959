import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 10C23 9.32542 22.9242 8.65461 22.775 8C22.6628 7.5077 22.5091 7.02455 22.3149 6.55585C21.8626 5.46392 21.1997 4.47177 20.364 3.63604C19.5282 2.80031 18.5361 2.13738 17.4442 1.68508C16.9754 1.49094 16.4923 1.33724 16 1.22504C15.3454 1.07584 14.6746 1 14 1V10H23ZM20.4672 7.32122C20.5593 7.54361 20.6397 7.77018 20.7082 8H16V3.2918C16.2298 3.36031 16.4564 3.44073 16.6788 3.53284C17.5281 3.88463 18.2997 4.40024 18.9497 5.05025C19.5998 5.70026 20.1154 6.47194 20.4672 7.32122ZM10 5C5.02944 5 1 9.02944 1 14C1 18.9706 5.02944 23 10 23C14.9706 23 19 18.9706 19 14C19 13.3254 18.9242 12.6546 18.775 12H12V5.22504C11.3454 5.07584 10.6746 5 10 5ZM10 14H16.8035C16.8035 17.7575 13.7575 20.8035 10 20.8035C6.24252 20.8035 3.19648 17.7575 3.19648 14C3.19648 10.2425 6.24252 7.19648 10 7.19648V14Z"
      />
    </SvgIcon>
  );
};
