import { Box } from '@mui/material';
import React from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

interface Props {
  content: React.ReactNode;
}

const SideBar = ({ content }: Props) => {
  const palette = usePalette();
  return (
    <Box sx={{ bgcolor: palette.backgroundForm }} className={styles.sideBar}>
      {content}
    </Box>
  );
};

export default SideBar;
