import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloudIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7381 19.9964C14.8187 19.9988 14.8996 20 14.9807 20C19.399 20 22.9807 16.4183 22.9807 12C22.9807 7.58172 19.399 4 14.9807 4C12.4543 4 10.2015 5.17108 8.73534 7H7.51953C3.92968 7 1.01953 9.91015 1.01953 13.5C1.01953 17.0899 3.92968 20 7.51953 20H14.5195C14.5927 20 14.6656 19.9988 14.7381 19.9964ZM16.6914 17.721C19.0416 16.9522 20.9807 14.6815 20.9807 12C20.9807 8.68629 18.2944 6 14.9807 6C11.667 6 8.98071 8.68629 8.98071 12H6.98071C6.98071 10.9391 7.18722 9.92643 7.56223 9H7.51953C5.03425 9 3.01953 11.0147 3.01953 13.5C3.01953 15.9853 5.03425 18 7.51953 18H14.5195C15.0693 18 15.9042 17.9014 16.6914 17.721Z"
      />
    </SvgIcon>
  );
};
