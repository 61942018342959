import { SelectChangeEvent, Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ChangeEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { useFund } from 'business/providers/fund-provider/use-fund';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { formatToMonetaryAmount } from 'technical/currency/formatters';
import { IFile } from 'technical/file-management/types';
import { Card } from 'ui/card';
import {
  FormInputDate,
  FormInputDropdown,
  FormInputFiles,
  FormInputText,
  FormSection,
  SelectInput,
} from 'ui/form';
import { FormBankDetailsPart } from 'ui/form-bank-details-part/form-bank-details';
import { Option } from 'ui/types';
import { Typo } from 'ui/typo';

interface ShareTransferFormProps {
  fund: Option;
  shares?: Option[];
  onShareChange: (value: string) => void;
  sellers: Option[];
  onSellerChange: (value: string) => void;
  buyers: Option[];
  onAmountChange: (value: number) => void;
  onNumberOfSharesChange: (value: number) => void;
  onTotalPriceChange: (value: number) => void;
  commitmentOfLp?: number;
  priceByShare?: number;
  numberOfShareAvailables?: number;
  onFilesUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<IFile[]>;
}

export const ShareTransferForm = ({
  fund,
  shares,
  onShareChange,
  sellers,
  onSellerChange,
  buyers,
  onAmountChange,
  onNumberOfSharesChange,
  onTotalPriceChange,
  commitmentOfLp,
  numberOfShareAvailables,
  priceByShare,
  onFilesUpload,
}: ShareTransferFormProps) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const { currency } = useFund();

  const noBuyers = buyers.length === 0;
  const noSellers = sellers.length === 0;

  const handleShareChange = (value: SelectChangeEvent<string>) => {
    const targetValue = value.target.value;
    onShareChange(targetValue);
  };

  const makeOnNumberChange =
    (onChange: (value: number) => void) =>
    (value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChange(Number(value.target.value));

  return (
    <Stack spacing={2} direction="column">
      <Stack direction="row" spacing={2}>
        <FormInputDropdown
          fullWidth
          name="fundId"
          label={t('pages.fundManager.shareTransfer.form.fund')}
          options={[fund]}
          disabled
        />
        <FormInputDropdown
          fullWidth
          name="shareId"
          label={t('pages.fundManager.shareTransfer.form.share')}
          options={shares}
          onChange={handleShareChange}
        />
      </Stack>

      <FormInputDate
        name="date"
        label={t('pages.fundManager.shareTransfer.form.date')}
        required
        fullWidth
      />
      <Stack direction="row" spacing={2}>
        <SelectInput
          fullWidth
          name="lpSellerId"
          label={t('pages.fundManager.shareTransfer.form.lpSeller')}
          options={sellers}
          disabled={noSellers}
          onChange={onSellerChange}
        />
        <SelectInput
          fullWidth
          name="lpBuyerId"
          label={t('pages.fundManager.shareTransfer.form.lpBuyer')}
          options={buyers}
          disabled={noBuyers}
        />
      </Stack>
      <Card sx={{ backgroundColor: palette.backgroundCard }}>
        <Stack direction="row" spacing={1} padding={2}>
          <Stack spacing={1} alignItems="center" flexGrow={1}>
            <FormInputText
              disabled={!commitmentOfLp}
              name="amount"
              label={t('pages.fundManager.shareTransfer.form.amount')}
              type="number"
              onChange={makeOnNumberChange(onAmountChange)}
            />

            <Typo bold>
              {t('pages.fundManager.shareTransfer.availableAmount')}
            </Typo>
            <Typo bold size="lg">
              {formatToMonetaryAmount(commitmentOfLp ?? 0, { currency })}
            </Typo>
          </Stack>
          <Stack spacing={1} alignItems="center" flexGrow={1}>
            <FormInputText
              disabled={!numberOfShareAvailables}
              name="numberOfShares"
              label={t('pages.fundManager.shareTransfer.form.numberOfShares')}
              type="number"
              onChange={makeOnNumberChange(onNumberOfSharesChange)}
            />
            <Typo bold>
              {t('pages.fundManager.shareTransfer.numberOfShares')}
            </Typo>
            <Typo bold size="lg">
              {numberOfShareAvailables ?? 0}
            </Typo>
          </Stack>
          <Stack spacing={1} alignItems="center" flexGrow={1}>
            <FormInputText
              name="totalPrice"
              type="number"
              label={t('pages.fundManager.shareTransfer.form.totalPrice')}
              onChange={makeOnNumberChange(onTotalPriceChange)}
            />
            <Typo bold>
              {t('pages.fundManager.shareTransfer.priceByShares')}
            </Typo>
            <Typo bold size="lg">
              {formatToMonetaryAmount(priceByShare ?? 0, { currency })}
            </Typo>
          </Stack>
        </Stack>
      </Card>
      <FormSection
        title={t('pages.fundManager.shareTransfer.buyerBankDetails')}
      >
        <FormBankDetailsPart name="bankDetails" />
      </FormSection>
      <FormSection
        lastOne
        title={t('pages.fundManager.shareTransfer.documentation')}
      >
        <FormInputFiles name="files" onFilesUpload={onFilesUpload} limit={1} />
      </FormSection>
    </Stack>
  );
};
