import React from 'react';

import Page from 'ui/layout/page';

interface Props {
  children?: React.ReactNode;
}

function DisconnectedPage(props: Props) {
  return <Page {...props} />;
}

export default DisconnectedPage;
