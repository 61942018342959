import { Button, ButtonProps as MuiButtonProps, Stack } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { AllRoutes } from 'business/shared/router/routes';
import Link from 'ui/link';

import styles from './index.module.scss';

interface MenuLinkProps extends MuiButtonProps {
  className?: string;
  path: AllRoutes;
  children: React.ReactNode;
  dataTestId?: string;
  active?: boolean;
  title?: string;
}

function MenuLink({
  children,
  className,
  path,
  dataTestId,
  active,
  title,
  ...props
}: MenuLinkProps) {
  const palette = usePalette();

  const secondaryColor = palette.primary[100];
  const hoverColor = palette.primary[100];

  return (
    <Stack
      direction="row"
      className={styles.menuItem}
      sx={{
        '&:hover': {
          background: hoverColor,
        },
        background: active ? secondaryColor : '',
      }}
    >
      <Link to={path} className={styles.menuItemLink} preserveParentTextColor>
        <Button
          // Makes the button transparent
          variant="text"
          className={classnames(className, 'menuLink')}
          data-test-id={dataTestId}
          sx={{ bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}
          {...props}
        >
          {children}
        </Button>
        <p className={styles.title}>{title}</p>
      </Link>
    </Stack>
  );
}

export default MenuLink;
