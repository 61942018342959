import { ICellRendererParams } from 'ag-grid-community';

import { computeAmlVigilanceAverage } from 'business/fund-manager/lp/services/compute-aml-vigilance-average';
import { PaletteVarKey } from 'business/providers/theme/services/types';
import { Typo } from 'ui/typo';
import { TypoColor, TypoColorEnum } from 'ui/typo/types';

const getScoreColor = (score: number): TypoColor | PaletteVarKey => {
  switch (score) {
    case 0:
    case 1:
    case 2:
      return TypoColorEnum.primary;
    case 3:
    case 4:
      return TypoColorEnum.secondary;
    case 5:
      return TypoColorEnum.error;
  }
  return TypoColorEnum.primary;
};

export const ScoreCell = ({ value }: ICellRendererParams) => {
  if (!value) {
    return <>-</>;
  }

  const score = computeAmlVigilanceAverage(value);

  return <Typo color={getScoreColor(value)}>{score}</Typo>;
};
