import { computeAmlVigilanceAverage } from './compute-aml-vigilance-average';
import { RiskData } from './types';

export const computeAmlRiskAverages = (rows: RiskData[]) => {
  // only take into account rows for wich user has selected a value
  const rowsWithValue = rows.filter((row) => !!row.riskvalue);

  // calculate risk average
  const riskTotal = rowsWithValue.reduce((acc, curr) => {
    return acc + (curr.riskvalue ?? 0);
  }, 0);

  const criteriaNumber = rowsWithValue.length;
  const riskAverage = criteriaNumber > 0 ? riskTotal / criteriaNumber : 0;
  const displayedRiskAverage = riskAverage ? Math.round(riskAverage) : 0;

  // calculate vigilance average (an riskAverage of n is translated to the nth letter of the alphabet)
  const vigilanceAverage = computeAmlVigilanceAverage(displayedRiskAverage);

  return { displayedRiskAverage, riskAverage, vigilanceAverage };
};
