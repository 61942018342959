import { ArrowBack, MailOutline } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useAppContext } from 'business/providers/app-provider';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { hasRole } from 'business/user/helpers/roles';
import { Roles } from 'business/user/types/user';
import { AppLogo } from 'ui/app-logo';
import { Button } from 'ui/button';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

export interface NavBarProps {
  title?: string;
  logo?: string;
  children?: React.ReactNode;
  path?: string;
}
export default function NavBar({ title, logo, children, path }: NavBarProps) {
  const { t } = useTranslation();
  const palette = usePalette();

  const isLpPlatform = useIsLpPlatform();
  const { user } = useAppContext();

  const displayContactForLP =
    isLpPlatform &&
    user &&
    (hasRole(user.roles, Roles.USER) ||
      hasRole(user.roles, Roles.DATA_MANAGER));

  const displayContactForGP = !isLpPlatform;

  return (
    <Box className={styles.header} sx={{ bgcolor: palette.backgroundForm }}>
      <Stack
        direction="row"
        spacing={4}
        className={classNames(styles.autoWidth, styles.verticalCentering)}
      >
        <AppLogo width={24} height={24} path={logo} clickable />
        <Stack spacing={1} direction="row" alignItems="center">
          {path ? (
            <IconButton
              color="primary"
              data-test-id="nav-bar-return-link"
              component={Link}
              to={path}
            >
              <ArrowBack />
            </IconButton>
          ) : null}
          <Typography marginLeft={path ? 0 : 4} variant="h6">
            {title}
          </Typography>
        </Stack>
        {children}
      </Stack>
      {displayContactForLP ? (
        <Button component={Link} to={LpPlatformRoutes.ContactUs}>
          <Stack direction="row" spacing={0.5}>
            <MailOutline color="primary" />
            <Typo color="primary">
              {t('pages.dashboard.requestDataChange')}
            </Typo>
          </Stack>
        </Button>
      ) : null}
      {displayContactForGP ? (
        <Button component={Link} to={FundManagerRoutes.ContactSupport}>
          <Stack direction="row" spacing={0.5}>
            <MailOutline color="primary" />
            <Typo color="primary">{t('pages.dashboard.contactSupport')}</Typo>
          </Stack>
        </Button>
      ) : null}
    </Box>
  );
}
