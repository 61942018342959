import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useTranslation } from 'translations/hook';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { hasRole } from 'business/user/helpers/roles';
import useUserData from 'business/user/provider/use-user-data';
import { Roles } from 'business/user/types/user';
import {
  CommitmentsMenuIcon,
  CurrencyExchangeIcon,
  DocumentationMenuIcon,
  DrawdownMenuIcon,
  LpMenuIcon,
  PortfolioMenuIcon,
  ValuationMenuIcon,
} from 'ui/icons';

export const useLpMenuItems = () => {
  const { t } = useTranslation();
  const palette = usePalette();

  const { user } = useUserData();

  if (user === undefined) {
    return [];
  }

  const primaryColor = palette.primary[800];
  const secondaryColor = palette.primary[100];

  return [
    {
      logo: <CurrencyExchangeIcon primaryColor={primaryColor} />,
      path: LpPlatformRoutes.Home,
      alt: 'generalInformation',
      title: t('components.sideMenu.dashboard'),
    },

    ...(hasRole(user.roles, Roles.DATA_MANAGER) ||
    hasRole(user.roles, Roles.USER)
      ? [
          {
            logo: (
              <LpMenuIcon
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            ),
            path: LpPlatformRoutes.Contact,
            alt: 'lp',
            title: t('components.sideMenu.contact'),
          },
          {
            logo: (
              <CommitmentsMenuIcon
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            ),
            path: LpPlatformRoutes.Commitment,
            alt: 'commitment',
            dataTestId: 'commitment-button',
            title: t('components.sideMenu.commitment'),
          },
          {
            logo: (
              <DrawdownMenuIcon
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            ),
            path: LpPlatformRoutes.FinancialFlows,
            alt: 'drawdown',
            dataTestId: 'financial-flows-button',
            title: t('components.sideMenu.financialFlows'),
          },
          {
            logo: <DocumentationMenuIcon primaryColor={primaryColor} />,
            path: LpPlatformRoutes.Document,
            alt: 'documentation',
            title: t('components.sideMenu.document'),
          },
          {
            logo: <ValuationMenuIcon primaryColor={primaryColor} />,
            path: LpPlatformRoutes.FundShareValuations,
            alt: 'valuation',
            dataTestId: 'valuation-button',
            title: t('components.sideMenu.fundShareValuations'),
          },
          {
            logo: (
              <PortfolioMenuIcon
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            ),
            path: LpPlatformRoutes.Portfolio,
            alt: 'portfolio',
            dataTestId: 'portfolio-button',
            title: t('components.sideMenu.portfolio'),
          },
        ]
      : []),

    ...(hasRole(user.roles, Roles.DATA_MANAGER)
      ? [
          {
            logo: <HttpsOutlinedIcon sx={{ color: primaryColor }} />,
            path: LpPlatformRoutes.SecurityLog,
            alt: 'securityLog',
            title: t('components.sideMenu.securityLog'),
          },
        ]
      : []),
  ];
};
