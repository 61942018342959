import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetCommitmentsQuery } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatRatioToPercentage,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { numericComparisonOperators } from 'technical/filter/operators';
import { concatWithSpaceBetween } from 'technical/string/concat';
import { createColumnHeader } from 'ui/column-header-with-sub-header';
import { CustomProgressBar } from 'ui/progression-bar';

import { CommitmentFilteredFields } from './types';

export function getCommitmentColumnDefinition(
  t: TFunction,
  customFilters: CommitmentFilteredFields,
): GridColDef<GetCommitmentsQuery['dataviz_commitment'][number]>[] {
  return [
    {
      type: 'singleSelect',
      valueOptions: customFilters.fundNames,
      headerName: t('pages.commitment.table.fundName'),
      field: 'fundName',
      aggregable: false,
      valueFormatter: ({ value }) => value,
      valueGetter: ({ row: { share } }) => share?.fund?.name ?? '',
      groupingValueGetter: ({ row: { share } }) => share.fund.name,
    },
    {
      field: 'shareType',
      align: 'center',
      type: 'singleSelect',
      valueOptions: customFilters.shareTypes,
      headerName: t('pages.commitment.table.shareType'),
      aggregable: false,
      valueFormatter: ({ value }) => value,
      valueGetter: ({ row: { share } }) => share?.type ?? '',
      groupingValueGetter: ({ row: { share } }) => share.type,
    },
    {
      field: 'commitmentAmount',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      align: 'right',
      headerName: t('pages.commitment.table.commitmentAmount'),
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.commitment.table.commitmentAmount'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFrom10X8toNumber(value)) : '',
      valueGetter: ({ row: { numericAmount } }) => numericAmount,
    },
    {
      field: 'unfundedCommitment',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      align: 'right',
      headerName: t('pages.commitment.table.unfundedCommitment'),
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.commitment.table.unfundedCommitment'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFrom10X8toNumber(value)) : '',
      valueGetter: ({ row: { numericUnfundedCommitment } }) =>
        numericUnfundedCommitment,
    },
    {
      field: 'paidInAmount',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      align: 'right',
      headerName: t('pages.commitment.table.paidIn'),
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.commitment.table.paidIn'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFrom10X8toNumber(value)) : '',
      valueGetter: ({ row: { numericTotalDrawn } }) => numericTotalDrawn,
    },
    {
      field: 'distributedNonRecallableAmount',
      groupable: false,
      type: 'number',
      filterOperators: numericComparisonOperators,
      align: 'right',
      headerName: concatWithSpaceBetween([
        t('pages.commitment.table.distributedNonRecallable.headerName'),
        t('pages.commitment.table.distributedNonRecallable.subHeaderName'),
      ]),
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t(
            'pages.commitment.table.distributedNonRecallable.headerName',
          ),
          subHeaderName: t(
            'pages.commitment.table.distributedNonRecallable.subHeaderName',
          ),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFrom10X8toNumber(value)) : '',
      valueGetter: ({ row: { numericTotalDistributed } }) =>
        numericTotalDistributed,
    },
    {
      field: 'distributedRecallableAmount',
      groupable: false,
      type: 'number',
      filterOperators: numericComparisonOperators,
      align: 'right',
      headerName: concatWithSpaceBetween([
        t('pages.commitment.table.distributedRecallable.headerName'),
        t('pages.commitment.table.distributedRecallable.subHeaderName'),
      ]),
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t(
            'pages.commitment.table.distributedRecallable.headerName',
          ),
          subHeaderName: t(
            'pages.commitment.table.distributedRecallable.subHeaderName',
          ),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        value ? formatToMonetaryAmount(convertFrom10X8toNumber(value)) : '',
      valueGetter: ({ row: { numericTotalDistributed } }) =>
        numericTotalDistributed,
    },
    {
      field: 'progress',
      align: 'center',
      type: 'number',
      headerName: t('pages.commitment.table.progression'),
      aggregable: false,
      groupable: false,
      filterable: false,
      renderCell: ({ value }) =>
        value ? <CustomProgressBar value={value} /> : '',
      valueGetter: ({ row: { numericTotalDrawn, numericAmount } }) =>
        numericTotalDrawn && numericAmount
          ? numericTotalDrawn / numericAmount
          : '',
      // this next line is used for csv exports
      valueFormatter: ({ value }) => formatRatioToPercentage(value),
    },
  ];
}
