import { Stack, Tabs } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundNavBarTabEnum } from 'business/fund-manager/fund/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import { useGetPendingValidationSharesCountQuery } from 'generated/graphql';
import { LinkTab } from 'ui/link-tab';

import styles from './index.module.scss';

type FundNavBarProps = PropsWithChildren<{
  activeTab: FundNavBarTabEnum;
}>;

const ChipWithPendingShares = ({ fundId }: { fundId: string }) => {
  const { data, loading, error } = useGetPendingValidationSharesCountQuery({
    variables: {
      fundId,
    },
  });

  return (
    <PendingChipCount
      loading={loading}
      error={error}
      count={data?.countPendingValdiationSharesAgg?.aggregate?.count}
    />
  );
};

export const FundNavBar = ({ activeTab, children }: FundNavBarProps) => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();

  return (
    <Stack className={styles.container}>
      <Tabs value={activeTab}>
        <LinkTab
          to={FundManagerRoutes.FundIdEditProfile.replace(':fundId', fundId)}
          label={t(
            'pages.fundManager.fundCreation.form.generalInformation.title',
          )}
        />
        <LinkTab
          to={FundManagerRoutes.FundIdEditBank.replace(':fundId', fundId)}
          label={t('pages.fundManager.fundCreation.form.bankDetails.title')}
        />

        <Stack direction="row" alignItems="center">
          <LinkTab
            to={FundManagerRoutes.FundIdEditShare.replace(':fundId', fundId)}
            label={t('pages.fundManager.fundEdition.share.title')}
          />

          <ChipWithPendingShares fundId={fundId} />
        </Stack>
      </Tabs>
      <Stack className={styles.content}>{children}</Stack>
    </Stack>
  );
};
