// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import { FundManagerEmailTemplateForm } from 'business/fund-manager/settings/template/components/email-template-form';
import { TemplateFormWithDynamicContent } from 'business/fund-manager/settings/template/components/template-form-with-dynamic-content';
import { EmailTemplateCreationForm } from 'business/fund-manager/settings/template/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useGetTemplateQuery,
  useUpdateEmailTemplateMutation,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundManagerEmailTemplateUpdate = () => {
  const { managementCompanyId, navigateSettings } = useNavigateSettings();
  const { t } = useTranslation();
  const { emailTemplateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data,
    loading: templateLoading,
    error: templateError,
  } = useGetTemplateQuery({
    variables: { templateId: emailTemplateId },
    fetchPolicy: 'network-only',
  });

  const [updateEmailTemplateMutation, { loading, error }] =
    useUpdateEmailTemplateMutation({
      onCompleted: () => {
        navigateSettings(FundManagerRoutes.SettingsTemplateList);
        enqueueSnackbar(t('successMessage.updateEmailTemplate'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  const onSubmit = (value: EmailTemplateCreationForm) => {
    updateEmailTemplateMutation({
      variables: {
        content: value.content,
        subject: value.subject,
        templateId: emailTemplateId,
      },
    });
  };

  const defaultValues = data
    ? {
        subject: data?.web_portal_emailTemplate[0].subject,
        content: data?.web_portal_emailTemplate[0].content,
      }
    : undefined;

  if (templateError || templateLoading) {
    return (
      <QueryStateDisplay error={templateError} loading={templateLoading} />
    );
  }

  return (
    <TemplateFormWithDynamicContent managementCompanyId={managementCompanyId}>
      <FundManagerEmailTemplateForm
        error={error}
        onSubmit={onSubmit}
        loading={loading}
        defaultValues={defaultValues}
      />
    </TemplateFormWithDynamicContent>
  );
};
