import { GetLpIndividualKycQuery, LpInvestorTypeEnum } from 'generated/graphql';

import { LpKYC } from './types';

export const getLpIndividualKYCFormValues = (
  kycValues?: GetLpIndividualKycQuery['lpKYC'],
): LpKYC => {
  if (kycValues) {
    return {
      // needed because there is a conflict between LpInvestorTypeEnum and Web_Portal_LpInvestorType_Enum while they have same content !
      // TODO: find another more proper way to handle that when possible
      investorType:
        (kycValues.investorType as unknown as LpInvestorTypeEnum) ??
        LpInvestorTypeEnum.Private,
      taxPayerIdentificationNumber:
        kycValues.taxPayerIdentificationNumber ?? '',
      investorGroup: kycValues.investorGroup ?? undefined,
      professionnalClient: kycValues.professionnalClient ?? false,
      USRelatedPerson: kycValues.USRelatedPerson ?? false,
      frenchEquitySavingsPlan: kycValues.frenchEquitySavingsPlan ?? false,
      taxOption: kycValues.taxOption ?? false,
      ampereReporting: kycValues.ampereReporting ?? false,
    };
  }

  return {
    investorType: LpInvestorTypeEnum.Private,
    investorGroup: undefined,
    taxPayerIdentificationNumber: '',
    professionnalClient: false,
    USRelatedPerson: false,
    frenchEquitySavingsPlan: false,
    taxOption: false,
    ampereReporting: false,
  };
};
