import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { OperationDownloadButton } from 'business/lp-platform/financial-flows/components/operation-download-button';
import { GetOperationsQuery } from 'generated/graphql';

export const operationColumn = (
  t: TFunction,
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'download',
    disableExport: true,
    headerName: t('pages.document.table.action'),
    align: 'center',
    groupable: false,
    aggregable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row: { id } }) => {
      return id ? <OperationDownloadButton operationId={id} /> : null;
    },
  };
};
