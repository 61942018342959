import { SelectChangeEvent, Stack } from '@mui/material';

import { FormInputDropdown, FormInputDropdownProps } from 'ui/form';

interface Props extends FormInputDropdownProps {
  name: string;
  label?: string;
  control: any;
  options?: { id: string; value: string }[];
  onChange?: (event: SelectChangeEvent<string>) => void;
  disabled: boolean;
}

export function OperationShareCommitmentStatusForm({
  name,
  label,
  control,
  options,
  onChange,
  disabled,
  ...rest
}: Props) {
  return (
    // TODO: change this to set the size in the parent component.
    <Stack sx={{ width: '100%' }}>
      <FormInputDropdown
        {...rest}
        name={name}
        label={label}
        control={control}
        onChange={onChange}
        options={options}
        disabled={disabled}
      />
    </Stack>
  );
}
