import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import {
  getComitmentsByClientType,
  getLpsByClientTypeGraphData,
  getVigilanceScoresGraphData,
} from 'business/fund-manager/home/services/get-investors-graphs-data';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { GetFundManagerDashBoardInfosQueryResult } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryInMillions,
} from 'technical/currency/formatters';
import PlotChart from 'technical/plotly-charts';
import { PlotLayout } from 'technical/plotly-charts/types';
import { Typo } from 'ui/typo';
import variables from 'ui/variables.module.scss';

import styles from './index.module.scss';
type Props = {
  fileName: string;
  data: NonNullable<
    GetFundManagerDashBoardInfosQueryResult['data']
  >['getFundManagerDashBoardInfos']['lpsOverview'];
};

interface GraphContainerProps extends PropsWithChildren {
  title: string;
  total: string;
}

const GraphContainer = ({ title, total, children }: GraphContainerProps) => {
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typo bold>{title}</Typo>
        <Typo>{total}</Typo>
      </Stack>
      <div className={styles.graphContainer}>{children}</div>
    </Stack>
  );
};

// 3 different graphs
export const InvestorsGraphs = ({ fileName, data }: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const colorRange = [
    palette.primary[800],
    palette.secondary[800],
    palette.tertiary[300],
    palette.primary[900],
    palette.secondary[900],
    palette.tertiary[400],
    palette.primary[400],
    palette.secondary[500],
    palette.tertiary[300],
    palette.primary[100],
    palette.secondary[100],
    palette.tertiary[100],
  ];

  const plotLayout: PlotLayout = {
    colorway: colorRange,
    autosize: true,
    barmode: 'stack',
    showlegend: false,
    bargap: 0,
    bargroupgap: 0,
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    hoverlabel: {
      bgcolor: palette.backgroundForm,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTextSize),
        color: palette.text,
      },
      bordercolor: palette.backgroundForm,
    },
    xaxis: {
      showticklabels: false,
      showgrid: false,
    },
    yaxis: {
      showticklabels: false,
      showgrid: false,
      visible: false,
    },
    margin: {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
    padding: {
      t: 0,
      l: 0,
      r: 0,
      b: 0,
    },
  };

  const comitmentsByClientType = getComitmentsByClientType({
    data,
    colorRange,
    t,
  });

  const lpsByClientType = getLpsByClientTypeGraphData({
    data,
    colorRange,
    t,
  });

  const vigilanceScores = getVigilanceScoresGraphData({
    data,
    colorRange,
    t,
  });

  return (
    <Stack spacing={3}>
      <GraphContainer
        title={t('pages.fundManager.dashboard.cards.investors.AUM')}
        total={formatToMonetaryInMillions(
          convertFrom10X8toNumber(data.totalCommitmentAmount),
        )}
      >
        <PlotChart
          dataArray={comitmentsByClientType}
          layout={plotLayout}
          fileName={fileName}
          displayModeBar={false}
        />
      </GraphContainer>
      <GraphContainer
        title={t('pages.fundManager.dashboard.cards.investors.quantity')}
        total={t('pages.fundManager.dashboard.cards.investors.lpCount', {
          count: data.totalLps,
        })}
      >
        <PlotChart
          dataArray={lpsByClientType}
          layout={plotLayout}
          fileName={fileName}
          displayModeBar={false}
        />
      </GraphContainer>
      <GraphContainer
        title={t('pages.fundManager.dashboard.cards.investors.vigilanceByLp')}
        total={t('pages.fundManager.dashboard.cards.investors.lpCount', {
          count: data.totalLps,
        })}
      >
        <PlotChart
          dataArray={vigilanceScores}
          layout={plotLayout}
          fileName={fileName}
          displayModeBar={false}
        />
      </GraphContainer>
    </Stack>
  );
};
