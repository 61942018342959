import { Stack, Typography, TypographyVariant } from '@mui/material';
import { ComponentProps, ReactElement } from 'react';

import { PaletteColor } from 'ui/types';

export type MainSecondaryItemProps = ComponentProps<typeof Stack> & {
  main: string | number | ReactElement;
  mainColor?: PaletteColor;
  secondaryColor?: PaletteColor;
  secondary: string | number | ReactElement;
  mainVariant?: TypographyVariant;
  secondaryVariant?: TypographyVariant;
  mainClassName?: string;
  secondaryClassName?: string;
  noWrap?: boolean;
};

export const MainSecondaryItem = ({
  main,
  mainColor,
  secondary,
  mainVariant = 'h6',
  secondaryVariant = 'body2',
  secondaryColor,
  mainClassName,
  secondaryClassName,
  noWrap,
  ...rest
}: MainSecondaryItemProps) => (
  <Stack {...rest}>
    <Typography
      variant={mainVariant}
      color={mainColor}
      className={mainClassName}
      noWrap={noWrap}
    >
      {main}
    </Typography>
    <Typography
      variant={secondaryVariant}
      className={secondaryClassName}
      color={secondaryColor}
      noWrap={noWrap}
    >
      {secondary}
    </Typography>
  </Stack>
);
