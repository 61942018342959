import { useSnackbar } from 'notistack';
import { Navigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { LpAmlRiskAssessment } from 'business/fund-manager/lp/components/lp-aml-risk-assessment/lp-aml-risk-assessment';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import SharedRoutes from 'business/shared/router/routes';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { useUpsertRiskAssessmentMutation } from 'generated/graphql';

const LpAmlRiskAssessmentPageContent = ({ lpId }: { lpId: string }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [createRiskAssessmentMutation] = useUpsertRiskAssessmentMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.approveSubscriptionRiskAssessment'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      enqueueSnackbar(t('errors.approveSubscriptionRiskAssessment'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const onSubmit = (riskAssessmentIds: string[]) =>
    createRiskAssessmentMutation({
      variables: {
        input: {
          lpId,
          riskAssessmentIds,
        },
      },
    }).catch(() => undefined);

  return <LpAmlRiskAssessment lpId={lpId} onSubmit={onSubmit} />;
};

export const LpAmlRiskAssessmentPage = () => {
  const { lpId = '' } = useParams();

  const displayRiskAssessment = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_RISK_ASSESSMENT,
  );

  if (!displayRiskAssessment) {
    return <Navigate to={SharedRoutes.Error} />;
  }

  return <LpAmlRiskAssessmentPageContent lpId={lpId} />;
};
