import { Divider, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CreateCashflowModal } from 'business/fund-manager/operation/components/create-cashflow-modal';
import { OperationInvestorCashFlowNavBar } from 'business/fund-manager/operation/components/operation-investor-cashflow-nav-bar';
import { getCashflowColumnDefs } from 'business/fund-manager/operation/services/get-cashflow-column-defs';
import { getCashflowsRows } from 'business/fund-manager/operation/services/get-cashflows-rows';
import { OperationInvestorsNavBarTabEnum } from 'business/fund-manager/operation/services/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import {
  CurrencyEnum,
  Web_Portal_PublicationStatus_Enum,
  useGetOperationShareLpCashFlowQuery,
} from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { AlternativeTable } from 'ui/alternative-table';
import { Card } from 'ui/card';
import { QueryStateDisplay } from 'ui/query-state-display';

type HeaderNumberProps = {
  value: number;
  title: string;
  currency?: CurrencyEnum;
};

const HeaderNumber = ({ value, title, currency }: HeaderNumberProps) => (
  <Stack>
    <Typography variant="h3">
      {formatToMonetaryAmount(convertFrom10X8toNumber(value), { currency })}
    </Typography>
    <Typography variant="body1">{title}</Typography>
  </Stack>
);

export const OperationInvestorCashflows = () => {
  const { t } = useTranslation();
  const { operationShareLpId = '' } = useParams();
  const { data, loading, error, refetch } = useGetOperationShareLpCashFlowQuery(
    {
      variables: {
        operationShareLpId,
        publicationStatus: Web_Portal_PublicationStatus_Enum.Validated,
      },
      fetchPolicy: 'network-only', // TODO: find a better way to refresh cache of table
    },
  );

  const { currency } = useFund();

  if (loading || error || data === undefined) {
    return (
      <OperationInvestorCashFlowNavBar
        activeTab={OperationInvestorsNavBarTabEnum.Validated}
      >
        <QueryStateDisplay loading={loading} error={error} />
      </OperationInvestorCashFlowNavBar>
    );
  }

  const cashflowRows = getCashflowsRows(data.web_portal_cashflow);

  const cashflowResume = cashflowRows.reduce(
    (acc, row) => ({
      paidIn: acc.paidIn + (row?.paidIn ?? 0),
      paidOut: acc.paidOut + (row?.paidOut ?? 0),
    }),
    { paidIn: 0, paidOut: 0 },
  );

  const columnDefs = getCashflowColumnDefs({ t });

  return (
    <OperationInvestorCashFlowNavBar
      activeTab={OperationInvestorsNavBarTabEnum.Validated}
    >
      <Card>
        <Stack spacing={2}>
          <Stack direction="row" spacing={4} padding={4} alignItems="center">
            <Stack flexGrow={1} direction="row" spacing={4}>
              <HeaderNumber
                value={cashflowResume.paidIn}
                title={t('pages.fundManager.investorCashflows.totalPaidIn')}
                currency={currency}
              />

              <HeaderNumber
                value={cashflowResume.paidOut}
                title={t('pages.fundManager.investorCashflows.totalPaidOut')}
                currency={currency}
              />
            </Stack>
            <CreateCashflowModal
              operationShareLpId={operationShareLpId}
              onCreated={refetch}
            />
          </Stack>
          <Stack>
            <Divider />
            <AlternativeTable
              fileName="operation-investor-cashflows"
              rowData={cashflowRows}
              columnDefs={columnDefs}
              domLayout="autoHeight"
              sideBar="filters"
              displaySidebar
              context={{ currency }}
            />
          </Stack>
        </Stack>
      </Card>
    </OperationInvestorCashFlowNavBar>
  );
};
