import { Stack } from '@mui/material';
import { ComponentProps, useState } from 'react';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { CollapseCard } from 'ui/collapse-card/collapse-card';
import { OnboardingSubscriptionItem } from 'ui/onboarding-subscription-item/onboarding-subscription-item';
import { Typo } from 'ui/typo';

interface OnboardingSubscriptionCardProps {
  title: string;
  items: (ComponentProps<typeof OnboardingSubscriptionItem> & { id: string })[];
}
export const OnboardingSubscriptionCard = ({
  title,
  items,
}: OnboardingSubscriptionCardProps) => {
  const palette = usePalette();
  const [expand, setExpand] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <CollapseCard
      title={
        <Typo bold uppercase>
          {title}
        </Typo>
      }
      expandIconText={
        items.length > 3 ? (
          <Typo color="primaryLight">
            {!expand
              ? t(
                  'pages.dashboard.cards.onboardingSubscriptions.expandText.show',
                )
              : t(
                  'pages.dashboard.cards.onboardingSubscriptions.expandText.hide',
                )}
          </Typo>
        ) : null
      }
      expand={expand}
      onToggle={() => setExpand(!expand)}
    >
      <Stack
        direction="row"
        overflow="auto"
        justifyContent="center"
        style={{
          backgroundColor: palette.infoRevealCardBgColor,
        }}
      >
        <Stack
          useFlexGap
          padding={1}
          spacing={1}
          direction="row"
          flexWrap={expand ? 'wrap' : undefined}
        >
          {(expand ? items : items.slice(0, 3)).map(({ id, ...item }) => (
            <OnboardingSubscriptionItem {...item} key={id} />
          ))}
        </Stack>
      </Stack>
    </CollapseCard>
  );
};
