import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Divider, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import { Navigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyMetricsCard } from 'business/fund-manager/portfolio/company/components/company-metrics-card';
import { ContactsCard } from 'business/fund-manager/portfolio/company/components/contacts-card';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useLanguageContext } from 'business/providers/language-provider';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  CompanyDashboardQuery,
  Web_Portal_PublicationStatus_Enum,
  useCompanyDashboardQuery,
  useGetCompanyDashboardMetricsQuery,
  useWebDocumentDownloadUrlQuery,
} from 'generated/graphql';
import { DashboardGrid } from 'ui/dashboard-grid/dashboard-grid';
import { DisplayImage } from 'ui/display-image';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';
import { getNaceValue } from 'ui/nace-code';
import { QueryStateDisplay } from 'ui/query-state-display';

type DashboardProps = {
  id: string;
  fundId: string;
  company: NonNullable<CompanyDashboardQuery['company']>;
};

export const Dashboard = ({ id, fundId, company }: DashboardProps) => {
  const { companyId = '' } = useParams();
  const { t } = useTranslation();
  const { language } = useLanguageContext();

  const displayCompanyMetrics = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_COMPANY_METRICS,
  );
  const { data, loading, error } = useGetCompanyDashboardMetricsQuery({
    variables: {
      companyId,
    },
  });

  const { data: logoData } = useWebDocumentDownloadUrlQuery({
    variables: {
      input: {
        ids: [company.logoDocuments[0]?.documentId],
      },
    },
    skip: !company.logoDocuments[0]?.documentId,
  });

  const logoUrl = logoData?.documentDownloadUrl.url;

  if (company.publicationStatus === Web_Portal_PublicationStatus_Enum.Draft) {
    return (
      <Navigate
        to={FundManagerRoutes.PortfolioFundIdCompanyCreateId.replace(
          ':fundId',
          fundId,
        ).replace(':companyId', id)}
      />
    );
  }

  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <DashboardGrid>
      <InfoCard dataTestId="company-profile-card">
        <InfoCard.Title
          path={FundManagerRoutes.PortfolioFundIdCompanyIdEditProfile.replace(
            ':fundId',
            fundId,
          ).replace(':companyId', id)}
        >
          {t('pages.fundManager.portfolio.company.creation.profile')}
        </InfoCard.Title>
        <InfoCard.Items size="l">
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={1}
            overflow="hidden"
          >
            <DisplayImage src={logoUrl} />
            <Typography variant="h5" whiteSpace="nowrap">
              {company.name}
            </Typography>
            <Typography variant="body2">
              {
                /* TODO geography when define */
                getNaceValue({ value: company.codeNace, language })?.sector ??
                  ''
              }
            </Typography>
          </Stack>
        </InfoCard.Items>
        <Divider />
        <Stack spacing={1}>
          <Stack padding={2}>
            <InfoItem
              description={
                data?.headcount?.date
                  ? t('pages.fundManager.portfolio.company.lastKnownTurnover', {
                      year: getYear(data.headcount.date),
                    })
                  : t('pages.fundManager.portfolio.company.turnover')
              }
              imageSource={<TrendingUpIcon />}
              iconColor="secondary"
              value={data?.turnover?.value ?? '-'}
            />
          </Stack>
          <Divider />
          <Stack padding={2}>
            <InfoItem
              description={t('pages.fundManager.portfolio.company.headcount')}
              imageSource={<PeopleIcon />}
              iconColor="primary"
              value={data?.headcount?.value ?? '-'}
            />
          </Stack>
        </Stack>
      </InfoCard>
      <ContactsCard
        contact={company?.companyContacts?.[0]?.contactView ?? undefined}
        count={company?.otherContactsCount?.aggregate?.count ?? 0}
        path={FundManagerRoutes.PortfolioFundIdCompanyIdEditContact.replace(
          ':fundId',
          fundId,
        ).replace(':companyId', id)}
      />
      {displayCompanyMetrics ? (
        <CompanyMetricsCard data={data?.monitoringMetricsCount} />
      ) : null}
    </DashboardGrid>
  );
};

export const CompanyDashboard = () => {
  const { companyId: id = '', fundId = '' } = useParams();
  const { loading, error, data } = useCompanyDashboardQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.company) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return <Dashboard id={id} fundId={fundId} company={data.company} />;
};
