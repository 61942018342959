import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { FundFinancialFlowCard } from 'business/fund-manager/fund/dashboard/components/fund-financial-flow-card';
import FundProfileCard from 'business/fund-manager/fund/dashboard/components/fund-profile-card';
import { FundReportingCard } from 'business/fund-manager/fund/dashboard/components/fund-reporting-card/fund-reporting-card';
import { FundShareValuation } from 'business/fund-manager/fund/dashboard/components/fund-share-valuation';
import { FundSubscriptions } from 'business/fund-manager/fund/dashboard/components/fund-subscriptions';
import { getFundProfileData } from 'business/fund-manager/fund/dashboard/services/get-fund-profile-data';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { DiligenceCard } from 'business/shared/components/diligence-card';
import DocumentationCard from 'business/shared/components/documentation-card';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  useFundLpDocumentUpToDatenessQuery,
  useFundRelatedWebDocumentCountQuery,
  useGetFundInfosQuery,
  useGetFundLpsConformityQuery,
  useLastFundReportingEmailQuery,
} from 'generated/graphql';
import { getNinetyDaysAgo } from 'technical/date';
import { DashboardGrid } from 'ui/dashboard-grid/dashboard-grid';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundDashboard = () => {
  const { t } = useTranslation();
  const { fundId = '' } = useParams();

  const displayDocumentation = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_DOCUMENTATION,
  );

  const displayFundValuation = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_FUND_VALUATION,
  );

  const displayRiskAssessment = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_RISK_ASSESSMENT,
  );

  const displayReporting = useFeatureFlag(FeatureFlagEnum.DISPLAY_REPORTING);

  const startDate = useMemo(() => getNinetyDaysAgo(), []);
  const {
    data: fundData,
    loading: fundloading,
    error: fundError,
  } = useGetFundInfosQuery({
    variables: {
      fundId: {
        _eq: fundId,
      },
    },
    // TODO for edition purpose to avoid miss information
    // we need to find an efficient way to update cache
    // fetchPolicy: 'network-only',
  });

  const {
    data: fundDocumentCount,
    loading: fundDocumentCountLoading,
    error: fundDocumentCountError,
  } = useFundRelatedWebDocumentCountQuery({
    variables: {
      input: {
        fundId,
        startDate,
      },
    },
  });

  const {
    data: lpsConformityData,
    loading: lpsConformityLoading,
    error: lpsConformityError,
  } = useGetFundLpsConformityQuery({
    variables: { input: { id: fundId } },
    fetchPolicy: 'network-only',
  });

  const {
    data: fundLpDocumentUpToDateness,
    loading: fundLpDocumentUpToDatenessLoading,
    error: fundLpDocumentUpToDatenessError,
  } = useFundLpDocumentUpToDatenessQuery({
    variables: { input: { fundId } },
    fetchPolicy: 'network-only',
  });

  const {
    data: lastFundReportingEmail,
    loading: lastFundReportingEmailLoading,
    error: lastFundReportingEmailError,
  } = useLastFundReportingEmailQuery({
    variables: { input: { fundId } },
    fetchPolicy: 'network-only',
  });

  const loading =
    fundloading ||
    fundDocumentCountLoading ||
    lpsConformityLoading ||
    lastFundReportingEmailLoading ||
    fundLpDocumentUpToDatenessLoading;

  const error =
    fundError ||
    fundDocumentCountError ||
    lpsConformityError ||
    lastFundReportingEmailError ||
    fundLpDocumentUpToDatenessError;
  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const fundName = fundData?.fundInfos?.[0].fundName ?? '';
  const computedData = getFundProfileData({
    data: fundData?.fundInfos,
  });

  return (
    <DashboardGrid>
      <FundProfileCard
        title={t('pages.dashboard.cards.fundProfile.title')}
        fundName={fundName}
        fundId={fundId}
        data={computedData}
      />
      {fundId ? (
        <>
          <FundSubscriptions fundId={fundId} />
          <FundFinancialFlowCard fundId={fundId} />
          {displayDocumentation ? (
            <DocumentationCard
              title={t('pages.dashboard.cards.documentation.title')}
              data={{
                afterDateDocumentCount:
                  fundDocumentCount?.fundRelatedWebDocumentCount
                    .afterDateDocumentCount ?? 0,
                totalDocumentCount:
                  fundDocumentCount?.fundRelatedWebDocumentCount
                    .totalDocumentCount ?? 0,
              }}
              path={FundManagerRoutes.FundIdDocument.replace(':fundId', fundId)}
            />
          ) : null}
          {displayFundValuation ? <FundShareValuation fundId={fundId} /> : null}
          {displayReporting ? (
            <FundReportingCard
              title={t('pages.dashboard.cards.reporting.title')}
              path={FundManagerRoutes.FundIdReportingCreate.replace(
                ':fundId',
                fundId,
              )}
              lastFundReportingEmail={
                lastFundReportingEmail?.lastFundReportingEmail
              }
            />
          ) : null}
          {displayRiskAssessment ? (
            <DiligenceCard
              path={FundManagerRoutes.FundIdDiligence.replace(
                ':fundId',
                fundId,
              )}
              title={t('pages.dashboard.cards.riskAssessment.title')}
              riskScore={
                lpsConformityData?.fundLpsConformity?.lpRiskAverage ?? undefined
              }
              completionPercent={
                lpsConformityData?.fundLpsConformity?.lpCompletionPercent ??
                undefined
              }
              fundLpDocumentUpToDateness={
                fundLpDocumentUpToDateness?.fundLpDocumentUpToDateness
              }
            />
          ) : null}
        </>
      ) : null}
    </DashboardGrid>
  );
};
