import { ValueFormatterParams } from 'ag-grid-community';

import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';

export const formatRowValueFrom10X8ToMonetary = (
  input: Pick<ValueFormatterParams, 'value' | 'node' | 'context'>,
) => {
  return formatRowValueToMonetary({
    ...input,
    value: convertFrom10X8toNumber(input.value ?? 0),
  });
};

export const formatRowValueToMonetary = ({
  value,
  node,
  context,
}: Pick<ValueFormatterParams, 'value' | 'node' | 'context'>) => {
  if (value !== 0 && !value) {
    // we are in a group row
    return '';
  }
  const currency = node?.data?.currency
    ? node?.data?.currency
    : context?.currency;

  return formatToMonetaryAmount(value, {
    currency,
  });
};
