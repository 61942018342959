import * as yup from 'yup';

import {
  nonEmptyStringSchema,
  regexValidationSchema,
} from 'technical/validation';

const isinRgex = /^[A-Z]{2}[A-Z0-9]{9}\d$/;

export const shareCreationFormInputSchema = yup.object({
  fundId: nonEmptyStringSchema,
  name: nonEmptyStringSchema.min(1).max(50),
  nominalValue: yup.number().max(Infinity).required(),
  isin: regexValidationSchema(
    isinRgex,
    'Value provided does not match ISIN regex pattern. e.g. US0378331005 (Apple Inc.)',
  ).required(),
});
