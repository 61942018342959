import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetValuationsQuery } from 'generated/graphql';
import {
  convertFromX100toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { formatAsDate } from 'technical/date';
import {
  dateComparisonOperators,
  numericComparisonOperators,
} from 'technical/filter/operators';
import { concatWithSpaceBetween } from 'technical/string/concat';
import { createColumnHeader } from 'ui/column-header-with-sub-header';

import { ValuationCustomFilterFields } from './types';

export const getValuationColumnDefinition = (
  t: TFunction,
  customFilters: ValuationCustomFilterFields,
): GridColDef<GetValuationsQuery['valuations'][number]>[] => {
  return [
    {
      field: 'valuationDate',
      filterOperators: dateComparisonOperators,
      headerName: t('pages.fundShareValuations.table.valuationDate'),
      aggregable: false,
      valueGetter: ({ row: { valuationDate } }) =>
        valuationDate ? formatAsDate(valuationDate) : '',
      groupingValueGetter: ({ row: { valuationDate } }) => {
        return formatAsDate(valuationDate);
      },
    },
    {
      field: 'fund',
      valueOptions: customFilters.funds,
      type: 'singleSelect',
      headerName: t('pages.fundShareValuations.table.fund'),
      aggregable: false,
      valueGetter: ({ row: { share } }) => share?.fund?.name ?? '',
      groupingValueGetter: ({ row: { share } }) => share.fund.name,
    },
    {
      field: 'shareType',
      valueOptions: customFilters.shares,
      type: 'singleSelect',
      headerName: t('pages.fundShareValuations.table.shareType'),
      aggregable: false,
      valueGetter: ({ row: { share } }) => share?.type ?? '',
      groupingValueGetter: ({ row: { share } }) => share.type,
    },
    {
      field: 'paidIn',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      headerName: t('pages.fundShareValuations.table.paidIn'),
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.fundShareValuations.table.paidIn'),
          subHeaderName: t(
            'pages.fundShareValuations.table.distributedNonRecallable.subHeaderName',
          ),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        formatToMonetaryAmount(convertFromX100toNumber(value)),
      valueGetter: ({ row: { paidIn } }) =>
        paidIn !== undefined ? paidIn : '',
    },
    {
      field: 'distributedNonRecallable',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      headerName: concatWithSpaceBetween([
        t(
          'pages.fundShareValuations.table.distributedNonRecallable.headerName',
        ),
        t(
          'pages.fundShareValuations.table.distributedNonRecallable.subHeaderName',
        ),
      ]),
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t(
            'pages.fundShareValuations.table.distributedNonRecallable.headerName',
          ),
          subHeaderName: t(
            'pages.fundShareValuations.table.distributedNonRecallable.subHeaderName',
          ),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        formatToMonetaryAmount(convertFromX100toNumber(value)),
      valueGetter: ({ row: { distributedNonRecallable } }) =>
        distributedNonRecallable !== undefined ? distributedNonRecallable : '',
    },
    {
      field: 'distributedRecallable',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      headerName: concatWithSpaceBetween([
        t('pages.fundShareValuations.table.distributedRecallable.headerName'),
        t(
          'pages.fundShareValuations.table.distributedRecallable.subHeaderName',
        ),
      ]),
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t(
            'pages.fundShareValuations.table.distributedRecallable.headerName',
          ),
          subHeaderName: t(
            'pages.fundShareValuations.table.distributedRecallable.subHeaderName',
          ),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        formatToMonetaryAmount(convertFromX100toNumber(value)),
      valueGetter: ({ row: { distributedRecallable } }) =>
        distributedRecallable !== undefined ? distributedRecallable : '',
    },
    {
      field: 'residualValue',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      headerName: t('pages.fundShareValuations.table.residualValue'),
      align: 'right',
      renderHeader: ({ aggregation: { aggregationRule } = {} }) => {
        const { aggregationFunctionName } = aggregationRule || {};
        return createColumnHeader({
          headerName: t('pages.fundShareValuations.table.residualValue'),
          aggregationFunctionName,
        });
      },
      valueFormatter: ({ value }) =>
        formatToMonetaryAmount(convertFromX100toNumber(value)),
      valueGetter: ({ row: { residualValue } }) =>
        residualValue !== undefined ? residualValue : '',
    },
    {
      field: 'rvpi',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      headerName: t('pages.fundShareValuations.table.rvpi'),
      align: 'right',
      headerAlign: 'right',
      aggregable: false,
      valueFormatter: ({ value }) =>
        `${convertFromX100toNumber(value)}${value !== 0 ? ' x' : ''}`,
      valueGetter: ({ row: { rvpi } }) => (rvpi !== undefined ? rvpi : ''),
    },
    {
      field: 'dpi',
      type: 'number',
      groupable: false,
      headerName: t('pages.fundShareValuations.dpi'),
      filterOperators: numericComparisonOperators,
      align: 'right',
      aggregable: false,
      valueFormatter: ({ value }) =>
        `${convertFromX100toNumber(value)}${value !== 0 ? ' x' : ''}`,
      valueGetter: ({ row: { dpi } }) => (dpi !== undefined ? dpi : ''),
    },
    {
      field: 'tvpi',
      type: 'number',
      groupable: false,
      filterOperators: numericComparisonOperators,
      headerName: t('pages.fundShareValuations.tvpi'),
      align: 'right',
      aggregable: false,
      valueFormatter: ({ value }) =>
        `${convertFromX100toNumber(value)}${value !== 0 ? ' x' : ''}`,
      valueGetter: ({ row: { rvpi, dpi } }) =>
        rvpi !== undefined && dpi !== undefined ? rvpi + dpi : '',
    },
  ];
};
