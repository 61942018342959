import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import { Stack, Typography } from '@mui/material';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ErrorLabel } from 'ui/error-label';

import styles from './index.module.scss';

interface Props {
  onChange?:
    | ((event: Event<string, unknown>, editor: ClassicEditor) => void)
    | undefined;
  minHeight?: number;
  label?: string;
  defaultData: string | undefined;
  error?: string;
}

export const CustomCkeditorInput = ({
  onChange,
  minHeight,
  defaultData,
  label,
  error,
}: Props) => {
  const palette = usePalette();
  function minHeightPlugin(editor: any) {
    editor.ui.view.editable.extendTemplate({
      attributes: {
        style: {
          tag: 'input',
          id: 'custom-ckeditor',
          minHeight: `${minHeight ?? 100}px`,
        },
      },
    });
  }
  return (
    <Stack>
      {label ? (
        <Typography
          className={styles.label}
          sx={{
            backgroundColor: palette.backgroundForm,
            color: palette.backgroundCard,
          }}
        >
          {label}
        </Typography>
      ) : (
        <></>
      )}
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [minHeightPlugin],
        }}
        data={defaultData}
        onChange={onChange}
      />

      {error ? <ErrorLabel label={error} /> : null}
    </Stack>
  );
};
