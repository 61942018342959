import { Stack } from '@mui/material';
import { TFunction, useTranslation } from 'translations/hook';

import {
  OperationCashflow,
  OperationType,
} from 'business/lp-platform/financial-flows/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ThemePalette } from 'business/providers/theme/services/types';
import { CurrencyEnum } from 'generated/graphql';
import { CombinedArrowsIcon, LeftArrowIcon, RightArrowIcon } from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { StatNumericAmountItem } from 'ui/stat-numeric-amount-item';

import styles from './index.module.scss';

interface Props {
  data: OperationCashflow[];
}

// Code already duplicated here `front/src/business/fund-manager/lp/components/financialFlowCard/index.tsx`
// if you have to duplicate it again, please find a way to make it more generic
const getTypeAggregate = (data: OperationCashflow[], type: string) =>
  data.reduce((previous, current) => {
    return previous + (current.type === type ? current.numericAmount : 0);
  }, 0);

const aggregateData = ({
  data,
  t,
  palette,
}: {
  data: OperationCashflow[];
  t: TFunction;
  palette: ThemePalette;
}) => {
  const drawn = getTypeAggregate(data, OperationType.Drawdown);
  const distributed = getTypeAggregate(data, OperationType.Distribution);
  const recallableDistributed = getTypeAggregate(
    data,
    OperationType.RecallableDistribution,
  );

  return [
    {
      type: OperationType.Drawdown,
      value: drawn,
      description: t('pages.financialFlows.synthesis.drawn'),
      icon: <LeftArrowIcon />,
      color: { color: 'primary.main', bgcolor: 'primary.lighter' },
    },
    {
      type: OperationType.Distribution,
      value: distributed,
      description: t('pages.financialFlows.synthesis.distributedNonRecallable'),
      icon: <RightArrowIcon />,
      color: { color: 'secondary.main', bgcolor: 'secondary.lighter' },
    },
    {
      type: OperationType.RecallableDistribution,
      value: recallableDistributed,
      description: t('pages.financialFlows.synthesis.distributedRecallable'),
      icon: <CombinedArrowsIcon />,
      color: {
        color: palette.neutralIconColor,
        bgcolor: palette.neutralBackGroundIconColor,
      },
    },
  ];
};

export const Synthesis = ({ data }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const aggregates = aggregateData({ data, t, palette });

  return (
    <Stack direction="row" spacing={3} className={styles.stack}>
      {aggregates.map((element) => (
        // TODO: fix currency with multiCurrency feature, set EUR by default for now
        <InfoCard className={styles.card} key={element.type}>
          <StatNumericAmountItem
            value={element.value}
            description={element.description}
            imageSource={element.icon}
            sx={element.color}
            currency={CurrencyEnum.Eur}
          />
        </InfoCard>
      ))}
    </Stack>
  );
};
