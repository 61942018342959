import { useFormContext } from 'react-hook-form';

import {
  CustomQuestionsWithAnswersQuery,
  LpCreationStepEnum,
  useCustomQuestionsWithAnswersQuery,
} from 'generated/graphql';
import { setFormCustomAnswersValue } from 'technical/custom-questions';
import { CustomQuestionsFormPart } from 'ui/form/custom-form-part/custom-form-part';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpCustomQuestions = ({
  lpId,
  entityStep,
}: {
  lpId: string;
  entityStep: LpCreationStepEnum;
}) => {
  const { setValue } = useFormContext();

  const { data, loading, error } = useCustomQuestionsWithAnswersQuery({
    fetchPolicy: 'network-only',
    variables: {
      questionInput: {
        entityStep,
        lpId,
      },
      answerInput: {
        entityStep,
        lpId,
      },
    },
    onCompleted: (response: CustomQuestionsWithAnswersQuery) => {
      setFormCustomAnswersValue(
        {
          questions: response.customQuestions,
          answers: response.customQuestionAnswers,
        },
        setValue,
      );
    },
  });

  if (loading || error || !data) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (!data.customQuestions.length) {
    return null;
  }

  return <CustomQuestionsFormPart items={data.customQuestions} />;
};
