import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import SharedRoutes from 'business/shared/router/routes';
import { ResetPasswordCard } from 'business/user/components/reset-password-card';
import PageAuth from 'business/user/pages/page-auth';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import { SetPasswordForm } from 'business/user/services/types';
import { setPasswordFormSchema } from 'business/user/services/validation';
import { FormInputPassword } from 'ui/form';

interface SetPasswordProps {
  titleKey: string;
  submitLabelKey: string;
  onSubmit: (values: SetPasswordForm) => Promise<void>;
}

const defaultValues: SetPasswordForm = {
  password: '',
  passwordConfirmation: '',
};

export const SetPassword = ({
  titleKey,
  submitLabelKey,
  onSubmit,
}: SetPasswordProps) => {
  const [errorContext, setErrorContext] = useState<string | null>(null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = async (values: SetPasswordForm) => {
    setErrorContext(null);
    try {
      await onSubmit(values);
      navigate(SharedRoutes.SignIn);
    } catch (error) {
      setErrorContext(getAuthErrorContextFromAuthProviderError(error).context);
    }
  };

  const methods = useForm<SetPasswordForm>({
    defaultValues,
    resolver: yupResolver(setPasswordFormSchema(t)),
  });

  return (
    <PageAuth>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <FormProvider {...methods}>
          <ResetPasswordCard
            submitButtonLabel={t(submitLabelKey)}
            title={t(titleKey)}
            authError={{ key: 'errors.resetPassword', context: errorContext }}
            submitting={methods.formState.isSubmitting}
          >
            <Stack spacing={2}>
              <FormInputPassword
                id="password"
                name="password"
                label={t('notConnected.login.password')}
                autoComplete="new-password"
                helperText={t('pages.resetPassword.helper')}
              />
              <FormInputPassword
                id="passwordConfirmation"
                name="passwordConfirmation"
                label={t('notConnected.login.password')}
                autoComplete="new-password"
                helperText={t('pages.resetPassword.mustMatch')}
              />
            </Stack>
          </ResetPasswordCard>
        </FormProvider>
      </form>
    </PageAuth>
  );
};
