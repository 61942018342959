import SearchIcon from '@mui/icons-material/Search';
import { debounce, InputAdornment } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { TextInput } from 'ui/text-input';

interface Props {
  onChange: (value: string) => void;
  size?: 'small' | 'medium';
  name?: string;
  label?: string;
}

export const SearchBar = ({ label, onChange, size, name }: Props) => {
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value.toLowerCase().trim());
  };

  return (
    <TextInput
      name={name}
      size={size}
      variant="outlined"
      label={label ?? t('components.searchBar.label')}
      onChange={debounce(handleChange, 500)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
