import { GetCommitmentsQuery } from 'generated/graphql';

import { AggregatedValuesType } from './types';

export function getAggregatedAndOrderedValuesForGraphAndSynthesis(
  commitments: Pick<
    GetCommitmentsQuery['dataviz_commitment'][number],
    'numericAmount' | 'share'
  >[],
  size = 5,
): { aggregatedValues: AggregatedValuesType[]; totalAmount: number } {
  const aggregatedValues = commitments
    .reduce(
      (
        accumulator: AggregatedValuesType[],
        currentCommitment: Pick<
          GetCommitmentsQuery['dataviz_commitment'][number],
          'numericAmount' | 'share'
        >,
      ) => {
        const aggregatedFunds = accumulator.map((value) => value.fund);
        const index = aggregatedFunds.indexOf(
          currentCommitment.share.fund.name,
        );
        if (index >= 0) {
          accumulator[index].amount += currentCommitment.numericAmount;
        } else {
          accumulator.push({
            fund: currentCommitment.share.fund.name,
            amount: currentCommitment.numericAmount,
          });
        }
        return accumulator;
      },
      [],
    )
    .sort((a, b) => b.amount - a.amount)
    .slice(0, size);

  const totalAmount = aggregatedValues.reduce((a, b) => a + b.amount, 0);
  return { aggregatedValues, totalAmount };
}
