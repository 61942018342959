import { EditOutlined } from '@mui/icons-material';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { Button } from 'ui/button';

import styles from './index.module.scss';
interface Props {
  onClick?: () => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const EditButton = ({ onClick, disabled, size }: Props) => {
  const palette = usePalette();
  return (
    <Button
      size={size ?? 'medium'}
      variant="outlined"
      sx={{
        color: 'text',
        bgcolor: 'transparent',
        borderColor: 'text',
        '&:hover': {
          bgcolor: palette.neutralBackGroundIconColor,
          borderColor: 'text',
        },
      }}
      disabled={disabled || !onClick}
      disableElevation
      onClick={onClick ? onClick : undefined}
    >
      <EditOutlined className={styles.iconSize} />
    </Button>
  );
};
