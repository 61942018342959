import { Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { OperationStatus } from 'business/fund-manager/operation/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { PublicationStatus } from 'generated/graphql';

interface Props {
  status: OperationStatus | PublicationStatus;
}

interface StatusTextProps {
  status: string;
  color: string;
}

const StatusText = ({ status, color }: StatusTextProps) => (
  <Typography sx={{ color }} variant="body2">
    {status}
  </Typography>
);

const Status = ({ status }: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  switch (status) {
    case PublicationStatus.PendingValidation:
      return (
        <StatusText
          status={t('pages.fundManager.operation.table.status.cell', {
            context: status,
          })}
          color={palette.statusTextPendingValidation}
        />
      );
    case OperationStatus.DRAFT:
      return (
        <StatusText
          status={t('pages.fundManager.operation.table.status.cell', {
            context: status,
          })}
          color={palette.statusTextDraft}
        />
      );
    case OperationStatus.DONE:
      return (
        <StatusText
          status={t('pages.fundManager.operation.table.status.cell', {
            context: status,
          })}
          color={palette.statusTextDone}
        />
      );
    case OperationStatus.IN_PROGRESS:
      return (
        <StatusText
          status={t('pages.fundManager.operation.table.status.cell', {
            context: status,
          })}
          color={palette.statusTextInProgress}
        />
      );
    default:
      return null;
  }
};

export default Status;
