import { CheckboxProps, Checkbox as MuiCheckBox, Stack } from '@mui/material';

import { Typo } from 'ui/typo';

import styles from './index.module.scss';

export interface PE3CheckboxProps {
  checked: boolean;
  label?: string;
  field: string;
  onChange?: (checked: boolean) => void;
  onAfterChange?: (checked: boolean) => void;
  dataTestId?: string;
  disabled?: boolean;
  sx?: CheckboxProps['sx'];
  className?: string;
}

export function CheckBox({
  checked,
  label,
  field,
  dataTestId,
  onChange,
  disabled,
  sx,
  className,
}: PE3CheckboxProps) {
  return (
    <Stack direction="row" alignItems="center" sx={sx}>
      <MuiCheckBox
        checked={checked}
        id={field}
        name={field}
        size="small"
        onChange={(_, isChecked: boolean) => onChange?.(isChecked)}
        data-test-id={dataTestId}
        disabled={disabled}
        className={className}
      />
      {label ? (
        <label htmlFor={field}>
          <Typo color="neutral-light" className={styles.label}>
            {label}
          </Typo>
        </label>
      ) : null}
    </Stack>
  );
}
