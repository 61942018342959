import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { FundManagerEmailTemplateForm } from 'business/fund-manager/settings/template/components/email-template-form';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  useGetTemplateByOperationIdQuery,
  useUpsertOperationEmailTemplateMutation,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const OperationEmailTemplateForm = () => {
  const { t } = useTranslation();
  const { operationId = '' } = useParams();

  const [
    upsertOperationEmailTemplateMutation,
    { error: upsertError, loading: upsertLoading },
  ] = useUpsertOperationEmailTemplateMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.updateOperationEmailTemplate'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      enqueueSnackbar(t('errors.updateOperationEmailTemplate'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const { data, loading, error } = useGetTemplateByOperationIdQuery({
    variables: {
      id: operationId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  const defaultValues = data?.web_portal_operationTemplateEmail?.[0]
    ? {
        subject: data.web_portal_operationTemplateEmail[0].subject,
        content: data.web_portal_operationTemplateEmail[0].content,
      }
    : undefined;

  return (
    <FundManagerEmailTemplateForm
      error={error || upsertError}
      onSubmit={({ content, subject }) => {
        if (content && subject) {
          upsertOperationEmailTemplateMutation({
            variables: {
              input: {
                operationId,
                content,
                subject,
              },
            },
          });
        }
      }}
      loading={loading || upsertLoading}
      defaultValues={defaultValues}
      hidePreview
    />
  );
};
