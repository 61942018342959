import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetContactsQuery } from 'generated/graphql';
import { ActiveChip } from 'ui/active-chip';
import TrueFalseIndicator from 'ui/true-false-indicator';

export function getContactColumnDefinition(
  t: TFunction,
): GridColDef<GetContactsQuery['dataviz_lpContact'][number]>[] {
  return [
    {
      field: 'hasLpPortalAccess',
      align: 'center',
      headerName: t('pages.contact.table.hasLpPortalAccess'),
      type: 'boolean',
      groupable: false,
      aggregable: false,
      renderCell: ({ row: { hasLpPortalAccess } }) =>
        hasLpPortalAccess !== undefined ? (
          <ActiveChip
            active={hasLpPortalAccess}
            activeLabel={t('pages.contact.chip.active')}
            inactiveLabel={t('pages.contact.chip.inactive')}
          />
        ) : (
          ''
        ),
      valueGetter: ({ row: { hasLpPortalAccess } }) =>
        hasLpPortalAccess
          ? t('pages.contact.chip.active')
          : t('pages.contact.chip.inactive'),
      // next line is used to format data for csv exports
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'contactName',
      groupable: false,
      headerName: t('pages.contact.table.contactName'),
      aggregable: false,
      valueGetter: ({ row: { contact } }) => contact?.fullName ?? '',
    },
    {
      minWidth: 400,
      field: 'email',
      groupable: false,
      headerName: t('pages.contact.table.email'),
      aggregable: false,
      valueGetter: ({ row: { contact } }) => contact?.email ?? '',
    },
    {
      field: 'hasOperationalRights',
      groupable: false,
      align: 'center',
      headerName: t('pages.contact.table.hasOperationalRights'),
      type: 'boolean',
      aggregable: false,
      renderCell: ({ value }) =>
        value !== undefined ? <TrueFalseIndicator value={value} /> : '',
      valueGetter: ({ row: { hasOperationalRights } }) =>
        hasOperationalRights !== undefined ? hasOperationalRights : '',
    },
    {
      field: 'canAccessGeneralReports',
      groupable: false,
      align: 'center',
      headerName: t('pages.contact.table.canAccessGeneralReports'),
      type: 'boolean',
      aggregable: false,
      renderCell: ({ value }) =>
        value !== undefined ? <TrueFalseIndicator value={value} /> : '',
      valueGetter: ({ row: { canAccessGeneralReports } }) =>
        canAccessGeneralReports !== undefined ? canAccessGeneralReports : '',
    },
    {
      field: 'canAttendInvestorsMeeting',
      groupable: false,
      align: 'center',
      headerName: t('pages.contact.table.canAttendInvestorsMeeting'),
      type: 'boolean',
      aggregable: false,
      renderCell: ({ value }) =>
        value !== undefined ? <TrueFalseIndicator value={value} /> : '',
      valueGetter: ({ row: { canAccessGeneralReports } }) =>
        canAccessGeneralReports !== undefined ? canAccessGeneralReports : '',
    },
    {
      field: 'canAccessESGReports',
      groupable: false,
      align: 'center',
      headerName: t('pages.contact.table.canAccessESGReports'),
      type: 'boolean',
      aggregable: false,
      renderCell: ({ value }) =>
        value !== undefined ? <TrueFalseIndicator value={value} /> : '',
      valueGetter: ({ row: { canAccessESGReports } }) =>
        canAccessESGReports !== undefined ? canAccessESGReports : '',
    },
    {
      field: 'canAccessAmpereReports',
      groupable: false,
      align: 'center',
      headerName: t('pages.contact.table.canAccessAmpereReports'),
      type: 'boolean',
      aggregable: false,
      renderCell: ({ value }) =>
        value !== undefined ? <TrueFalseIndicator value={value} /> : '',
      valueGetter: ({ row: { canAccessAmpereReports } }) =>
        canAccessAmpereReports !== undefined ? canAccessAmpereReports : '',
    },
    {
      field: 'canAccessCapitalAccountStatement',
      groupable: false,
      align: 'center',
      headerName: t('pages.contact.table.canAccessCapitalAccountStatement'),
      type: 'boolean',
      aggregable: false,
      renderCell: ({ value }) =>
        value !== undefined ? <TrueFalseIndicator value={value} /> : '',
      valueGetter: ({ row: { canAccessCapitalAccountStatement } }) =>
        canAccessCapitalAccountStatement !== undefined
          ? canAccessCapitalAccountStatement
          : '',
    },
  ];
}
