import { Control, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import {
  useGetManagementCompaniesAssociatedToLpQuery,
  Web_Portal_ManagementCompanyType_Enum,
} from 'generated/graphql';
import { SelectInput } from 'ui/form';

interface Props<T extends FieldValues> {
  control: Control<T>;
  saving?: boolean;
}

export const ManagementCompanyDropdown = <T extends FieldValues>({
  control,
  saving,
}: Props<T>) => {
  const { lpId } = useParams();
  const { t } = useTranslation();
  const { data, loading, error } = useGetManagementCompaniesAssociatedToLpQuery(
    {
      variables: {
        input: {
          lpId,
        },
      },
    },
  );

  const isGeneralPartnerOrAdvisor = useManagementCompanyTypeGuard([
    Web_Portal_ManagementCompanyType_Enum.Advisor,
    Web_Portal_ManagementCompanyType_Enum.GeneralPartner,
  ]);

  if (isGeneralPartnerOrAdvisor) {
    return null;
  }

  return (
    <SelectInput
      fullWidth
      name="managementCompanyId"
      label={t(
        'pages.fundManager.fundCreation.form.generalInformation.managementCompany',
      )}
      control={control}
      required
      disabled={
        loading ||
        saving ||
        error !== undefined ||
        !data?.managementCompaniesAssociatedToLp
      }
      options={(data?.managementCompaniesAssociatedToLp ?? []).map(
        ({ id, name }) => ({
          id,
          value: name,
        }),
      )}
      error={!!error}
      helperText={error?.message}
      dataTestId="select-fund-managementCompanyId"
    />
  );
};
