import * as yup from 'yup';

import { ContactTypeEnumSchema } from 'business/shared/lp-creation-module/services/validation';
import { addressSchema } from 'business/shared/services/validation';
import {
  emailRequiredSchema,
  nonEmptyStringSchema,
  uuidOptionnalSchema,
} from 'technical/validation';

export const contactSchema = yup.object({
  type: ContactTypeEnumSchema,
  email: emailRequiredSchema,
  title: yup.string(),
  firstName: nonEmptyStringSchema.min(2).max(50),
  lastName: nonEmptyStringSchema.min(2).max(50),
  phoneNumber1: yup.string(),
  phoneNumber2: yup.string(),
  companyName: yup.string(),
  jobTitle: yup.string(),
  comment: yup.string().max(300),
  address: addressSchema.concat(
    yup.object({
      id: uuidOptionnalSchema,
    }),
  ),
});
