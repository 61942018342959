import * as yup from 'yup';

import { ValidationErrors } from './types';

// Yup config for default error messages
yup.setLocale({
  mixed: {
    default: ValidationErrors.INVALID,
    required: ValidationErrors.REQUIRED,
  },
  number: {
    positive: ValidationErrors.NUMBER_TOO_SMALL,
  },
});

export const nonEmptyStringSchema = yup
  .string()
  .trim()
  .min(1, ValidationErrors.REQUIRED)
  .required();

export const noFutureDateRequiredSchema = yup
  .date()
  .test(
    'noFutureDate',
    'errors.noFutureDate',
    (value: Date | undefined) => !value || value <= new Date(),
  )
  .required();

export const emailSchema = yup
  .string()
  .trim()
  .email(ValidationErrors.EMAIL_INVALID)
  .lowercase(ValidationErrors.EMAIL_LOWERCASE);

export const emailRequiredSchema = emailSchema.required();

export const floatNumberSchema = yup
  .number()
  // Accept NaN, which is returned for empty "number" fields.
  // If a value is required, it would be an extra validation step.
  .transform((value) => (isNaN(value) ? undefined : value))
  .optional()
  .typeError(ValidationErrors.INVALID);

export const nonNegativeNumberSchema = yup
  .number()
  .min(0)
  .typeError(ValidationErrors.NUMBER_TOO_SMALL);

export const regexValidationSchema = (
  regex: RegExp,
  errorMessage: string,
  required = false,
) => {
  const regexSchema = yup.string().matches(regex, errorMessage);
  if (required) {
    return regexSchema.required();
  }
  return regexSchema;
};

export const uuidRequiredSchema = yup
  .string()
  .trim()
  .matches(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
    ValidationErrors.INVALID,
  )
  .required(ValidationErrors.REQUIRED);

export const uuidOptionnalSchema = yup
  .string()
  .trim()
  .matches(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
    { excludeEmptyString: true, message: ValidationErrors.INVALID },
  )
  .optional();
