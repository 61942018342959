import { Stack } from '@mui/material';
import { useLocation } from 'react-router';

import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import MenuItem from 'ui/menu-item';

import { useFundManagerMenuItems } from './use-fund-manager-menu-items';
import { useLpMenuItems } from './use-lp-menu-items';

export const MenuItems = () => {
  const { pathname } = useLocation();

  const isLpPlatform = useIsLpPlatform();

  const lpMenuItems = useLpMenuItems();
  const fundManagerMenuItems = useFundManagerMenuItems();

  const menuItems = isLpPlatform ? lpMenuItems : fundManagerMenuItems;

  return (
    <Stack spacing={2}>
      {menuItems.map((props) => (
        <MenuItem {...props} key={props.alt} active={pathname === props.path} />
      ))}
    </Stack>
  );
};
