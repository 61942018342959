export enum TypoSizeEnum {
  xs = 'xs',
  sm = 'sm', // MUI variant body2
  m = 'm', // default value // MUI variant body1
  lg = 'lg',
  xl = 'xl',
  // add underscore because we use it directly in sass and .<number> not working in sass
  '2xl' = '2xl', // MUI variant H5
  '3xl' = '3xl',
  '4xl' = '4xl',
}

export type TypoSize = `${TypoSizeEnum}`;

export enum TypoColorEnum {
  primary = 'primary',
  secondary = 'secondary',
  neutral = 'neutral',
  neutralLight = 'neutral-light',
  light = 'light',
  error = 'error',
}

export type TypoColor = `${TypoColorEnum}`;

export enum TypoAlignEnum {
  center = 'center',
  left = 'left',
  right = 'right',
}
export type TypoAlign = `${TypoAlignEnum}`;
