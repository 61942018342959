import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpCreationRoutes } from 'business/shared/lp-creation-module/router/types';
import { getCreationSteps } from 'business/shared/lp-creation-module/services/get-creation-steps';
import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  ClientTypeEnum,
  useCreateLpGeneralProfileMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';

import { useLpCreationModuleNavigate } from './use-lp-creation-module-navigate';
import { useProfileForm } from './use-profile-form';

export const useCreateLpGeneralProfileForm = () => {
  const navigateInModule = useLpCreationModuleNavigate();

  const displayLpInvestorProfileStep = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
  );

  const { t } = useTranslation();
  // will create a draft lp with minimum info
  const [createLpGeneralProfileMutation, { error, loading: saving }] =
    useCreateLpGeneralProfileMutation({
      onCompleted: (data) => {
        const { id, client } = data.createLpGeneralProfile;

        navigateInModule(LpCreationRoutes.CreateId.replace(':lpId', id), {
          replace: true,
        });

        if (client) {
          const steps = getCreationSteps({
            clientType: client as ClientTypeEnum,
            displayLpInvestorProfileStep,
            displayLpContactsStep: true,
          });

          navigateInModule(
            steps[1].url.replace(':lpId', data.createLpGeneralProfile.id),
          );
        }
      },
      onError: () =>
        enqueueSnackbar(t('errors.createLp'), {
          variant: VariantTypeEnum.ERROR,
        }),
    });

  const onSubmit = async ({
    individualLegalRepresentative,
    customAnswers = [],
    ...rest
  }: LpProfile) =>
    createLpGeneralProfileMutation({
      variables: {
        input: {
          ...rest,
          ...(rest.client === ClientTypeEnum.Individual
            ? {
                individualLegalRepresentative: {
                  ...individualLegalRepresentative,
                  birthDate: individualLegalRepresentative.birthDate
                    ? formatAsDate(individualLegalRepresentative.birthDate)
                    : null,
                  email: individualLegalRepresentative.email ?? '',
                  firstName: individualLegalRepresentative.firstName ?? '',
                  lastName: individualLegalRepresentative.lastName ?? '',
                },
              }
            : {}),
          customAnswers,
        },
      },
    });

  const { onSubmit: onFormSubmit, methods } = useProfileForm({
    onSubmit,
  });

  return {
    methods,
    onSubmit: onFormSubmit,
    saving,
    error,
  };
};
