import { useParams } from 'react-router-dom';

import { CompanyInventoryKeyMetricsCard } from 'business/fund-manager/portfolio/company/components/company-inventory-key-metrics-card';
import { CompanyInventoryNavBar } from 'business/fund-manager/portfolio/company/components/company-inventory-nav-bar';
import { CompanyInventorySynthesisCard } from 'business/fund-manager/portfolio/company/components/company-inventory-synthesis-card';
import { CompanyInventoryTab } from 'business/fund-manager/portfolio/company/services/types';
import { useGetFundCompaniesSynthesisQuery } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const InventoryDashboard = () => {
  const { fundId = '' } = useParams();

  const { data, loading, error } = useGetFundCompaniesSynthesisQuery({
    variables: { fundId },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.infos) {
    return (
      <>
        <CompanyInventoryNavBar activeTab={CompanyInventoryTab.Synthesis} />
        <QueryStateDisplay error={error} loading={loading} />
      </>
    );
  }
  return (
    <>
      <CompanyInventoryNavBar activeTab={CompanyInventoryTab.Synthesis} />
      <CompanyInventorySynthesisCard data={data.infos.synthesis} />
      <CompanyInventoryKeyMetricsCard />
    </>
  );
};
