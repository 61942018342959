import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyTransactionCreationInput } from 'business/fund-manager/portfolio/company/services/types';
import { companyTransactionCreationInputSchema } from 'business/fund-manager/portfolio/company/services/validation';
import { CompanyTransactionCreateInstrumentForm } from 'business/fund-manager/portfolio/inventory/components/instrument-transaction-instrument-create-form';
import { InstrumentTransactionExistingInstrumentForm } from 'business/fund-manager/portfolio/inventory/components/instrument-transaction-instrument-existing-form';
import { InstrumentTransactionSocietyForm } from 'business/fund-manager/portfolio/inventory/components/instrument-transaction-society-form';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  GetFundCompaniesInfosQuery,
  WebCompanyTransactionCreationModeEnum,
  WebCompanyTransactionTypeEnum,
  useCreateCompanyTransactionMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { ActionButton } from 'ui/action-button';
import { ModalHeader } from 'ui/custom-modal/header';
import { ErrorLabel } from 'ui/error-label';
import { FormInputDate, FormInputText, FormToggleButton } from 'ui/form';

interface Props {
  initialValues: GetFundCompaniesInfosQuery['companies'];
  onCompleted: () => void;
  onClose: () => void;
}

export const InstrumentTransactionCreationModal = ({
  initialValues,
  onCompleted,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const methods = useForm<CompanyTransactionCreationInput>({
    resolver: yupResolver(companyTransactionCreationInputSchema),
    defaultValues: {
      transaction: {
        mode: WebCompanyTransactionCreationModeEnum.Create,
        type: WebCompanyTransactionTypeEnum.Acquisition,
      },
    },
  });

  const [createTransaction, { loading, error }] =
    useCreateCompanyTransactionMutation({
      refetchQueries: [
        'getPortfolioValuationDetails',
        'getPortfolioInventoryDetails',
      ],
      onCompleted: () => {
        onCompleted?.();
        enqueueSnackbar(t('successMessage.createCompanyTransaction'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  const { handleSubmit, control } = methods;
  const onSubmit = (input: CompanyTransactionCreationInput) => {
    return createTransaction({
      variables: {
        input: {
          ...input,
          transaction: {
            ...input.transaction,
            date: formatAsDate(input.transaction.date),
          },
        },
      },
      // In our input components we are using a prop from
      // react-hook-form `isSubmitting` to disable our fields.
      // To change the isSubmitting value, react-hook-form needs to know
      // if our promise failed. Thus the catch allows us to re-enable
      // the previously disabled fields
    }).catch(() => undefined);
  };

  const mode = useWatch<CompanyTransactionCreationInput>({
    name: 'transaction.mode',
    control,
  });
  const availableTransactionType = Object.values(WebCompanyTransactionTypeEnum)
    .filter((value) =>
      mode === WebCompanyTransactionCreationModeEnum.Create
        ? value !== WebCompanyTransactionTypeEnum.Transfer
        : true,
    )
    .map((value) => ({
      label: t(
        `pages.fundManager.portfolio.companyTransaction.form.transactionType.${value}`,
      ),
      value,
    }));

  const selectedCompanyId = useWatch<CompanyTransactionCreationInput>({
    name: 'instrument.companyId',
    control,
  });

  const selectedCompanyName = initialValues.find(
    ({ id }) => id === selectedCompanyId,
  )?.name;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader
        title={t('pages.fundManager.portfolio.companyTransaction.createNew')}
        onClose={onClose}
        labelAction={t('common.actions.create')}
      />
      <FormProvider {...methods}>
        <Stack spacing={3} padding={4}>
          <InstrumentTransactionSocietyForm
            initialValues={initialValues}
            loading={loading}
          />
          <Divider />
          {mode && mode === WebCompanyTransactionCreationModeEnum.Create ? (
            <CompanyTransactionCreateInstrumentForm
              selectedCompanyName={selectedCompanyName}
            />
          ) : (
            <InstrumentTransactionExistingInstrumentForm
              initialValues={initialValues}
              loading={loading}
            />
          )}
          <Divider />
          <FormToggleButton
            name="transaction.type"
            label={t(
              'pages.fundManager.portfolio.companyTransaction.form.transactionType.title',
            )}
            control={control}
            options={availableTransactionType}
            fullWidth
          />
          <FormInputDate
            name="transaction.date"
            label={t('pages.fundManager.shareTransfer.form.date')}
            control={control}
            required
          />
          <Stack direction="row" spacing={2}>
            <FormInputText
              type="number"
              name="transaction.quantity"
              label={t(
                'pages.fundManager.portfolio.companyTransaction.form.quantity',
              )}
              control={control}
              required
              fullWidth
            />
            <FormInputText
              type="number"
              name="transaction.totalQuantity"
              label={t(
                'pages.fundManager.portfolio.companyTransaction.form.totalQuantity',
              )}
              control={control}
              required
              fullWidth
            />
            <FormInputText
              type="number"
              name="transaction.nominalValue"
              label={t(
                'pages.fundManager.portfolio.companyTransaction.form.nominalValue',
              )}
              control={control}
              required
              fullWidth
            />
          </Stack>
          <Divider />
          {error ? <ErrorLabel label={error.message} /> : null}
          <ActionButton
            size="small"
            variant="submit"
            type="submit"
            loading={loading}
          >
            {t('common.actions.create')}
          </ActionButton>
        </Stack>
      </FormProvider>
    </form>
  );
};
