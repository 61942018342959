import EmailIcon from '@mui/icons-material/Email';
import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import {
  LastFundReportingEmailQuery,
  LastFundReportingEmailStatusEnum,
} from 'generated/graphql';
import { InfoCard } from 'ui/info-card';
import { InfoDescriptionItem } from 'ui/info-description-item';

interface Props {
  title: string;
  path: string;
  lastFundReportingEmail: LastFundReportingEmailQuery['lastFundReportingEmail'];
}

const FundReportingCardInfoItem = ({
  lastFundReportingEmail,
}: {
  lastFundReportingEmail: LastFundReportingEmailQuery['lastFundReportingEmail'];
}) => {
  const { t } = useTranslation();

  if (!lastFundReportingEmail) {
    return (
      <InfoDescriptionItem
        description={t('pages.dashboard.cards.reporting.noReportSent')}
        imageSource={<EmailIcon />}
        iconColor="secondary"
        size="dense"
      />
    );
  }

  const description = t('pages.dashboard.cards.reporting.lastReportSentOn', {
    date: new Date(lastFundReportingEmail.sendingDate).toLocaleDateString(),
  });
  const subDescription = t(
    `pages.dashboard.cards.reporting.lastreportStatus.${lastFundReportingEmail.status}`,
  );

  if (
    lastFundReportingEmail.status === LastFundReportingEmailStatusEnum.Error
  ) {
    return (
      <InfoDescriptionItem
        description={
          <Stack>
            <span>{description}</span>
            <span>{subDescription}</span>
          </Stack>
        }
        imageSource={<EmailIcon />}
        iconColor="danger"
        size="dense"
      />
    );
  }
  return (
    <InfoDescriptionItem
      description={
        <Stack>
          <span>{description}</span>
          <span>{subDescription}</span>
        </Stack>
      }
      imageSource={<EmailIcon />}
      iconColor="primary"
      size="dense"
    />
  );
};

export const FundReportingCard = ({
  title,
  path,
  lastFundReportingEmail,
}: Props) => {
  return (
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>
      <InfoCard.Items size="auto">
        <FundReportingCardInfoItem
          lastFundReportingEmail={lastFundReportingEmail}
        />
      </InfoCard.Items>
    </InfoCard>
  );
};
