import { PropsWithChildren, HTMLAttributes, FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { useGridConfig } from './grid-table';

const colSpans = {
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
};
export type ColSpans = keyof typeof colSpans;

export const opShareStandardCellWidth = 'w-40';
export const opShareCellBaseProps = 'p-2 truncate flex items-center';
export const opShareCellProps = `${opShareCellBaseProps} ${opShareStandardCellWidth}`;

export interface GridCellProps
  extends PropsWithChildren,
    HTMLAttributes<HTMLDivElement> {
  rowOpen?: boolean;
  colOpen?: boolean;
  colSpan?: ColSpans;
  noWidth?: boolean;
}

export const GridCell: FC<GridCellProps> = (props) => {
  const { children, className, rowOpen, colOpen, colSpan, noWidth, ...others } =
    props;
  const { cellWidthClass, cellHeightClass } = useGridConfig();

  return (
    <div
      {...others}
      className={twMerge(
        opShareCellBaseProps,
        !colSpan && !noWidth && cellWidthClass,
        cellHeightClass,
        className,
        colSpan && colSpans[colSpan],
        (colOpen != null || rowOpen != null) && 'transition-all opacity-1',
        rowOpen === false && 'h-0 py-0 opacity-0',
        colOpen === false && 'w-0 px-0 opacity-0',
      )}
    >
      {children}
    </div>
  );
};
