// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CreationPageContainer } from 'business/fund-manager/fund/components/creation-page-container';
import { FundCreationForm } from 'business/fund-manager/fund/components/fund-creation-form';
import { getProfileFormValues } from 'business/fund-manager/fund/services/get-profile-form-values';
import {
  FundNavBarTabEnum,
  FundProfile,
} from 'business/fund-manager/fund/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  Web_Portal_PublicationStatus_Enum,
  useGetFundProfileQuery,
  useUpdateFundProfileMutation,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const FundDraftEdition = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { fundId = '' } = useParams();
  const navigate = useNavigate();
  const {
    data: fundData,
    loading,
    error: loadError,
  } = useGetFundProfileQuery({
    variables: { id: fundId },
  });

  const [updateMutation, { error: saveError, loading: saving }] =
    useUpdateFundProfileMutation({
      onError: () =>
        enqueueSnackbar(t('errors.updateFund'), {
          variant: VariantTypeEnum.ERROR,
        }),
      onCompleted: () => {
        navigate(FundManagerRoutes.FundCreateIdBank.replace(':fundId', fundId));
        enqueueSnackbar(t('successMessage.updateFund'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  if (loading || loadError || !fundData?.fundProfile) {
    return (
      <CreationPageContainer
        activeStep={FundNavBarTabEnum.Profile}
        previousPath={FundManagerRoutes.Fund}
      >
        <QueryStateDisplay loading={loading} error={loadError} />
      </CreationPageContainer>
    );
  }

  if (
    fundData.fundProfile.publicationStatus !==
    Web_Portal_PublicationStatus_Enum.Draft
  ) {
    return (
      <Navigate
        to={FundManagerRoutes.FundId.replace(':fundId', fundId)}
        replace
      />
    );
  }

  const onSubmit = (data: FundProfile) =>
    updateMutation({
      variables: {
        input: {
          id: fundId,
          data,
        },
      },
    });

  return (
    <FundCreationForm
      onSubmit={onSubmit}
      loading={saving}
      error={saveError}
      defaultValues={getProfileFormValues(fundData.fundProfile)}
    />
  );
};
