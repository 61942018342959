import { Stack } from '@mui/material';
import { Navigate, useParams } from 'react-router';

import { OnboardingSubscriptionReadyForSignature } from 'business/fund-manager/onboarding-subscription/components/onboarding-subscription-ready-for-signature/onboarding-subscription-ready-for-signature';
import { OnboardingValidation } from 'business/fund-manager/onboarding-subscription/components/onboarding-validation';
import { ProfileValidation } from 'business/fund-manager/onboarding-subscription/components/profile-validation/profile-validation';
import { RiskAssessmentsForm } from 'business/fund-manager/onboarding-subscription/components/risk-assessments-form/risk-assessements-form';
import { onboardingStatusWaitingForLpActions } from 'business/fund-manager/onboarding-subscription/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { SignatureIframe } from 'business/shared/components/onboarding-subscription-signature-iframe/onboarding-subscription-signature-iframe';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  OnboardingSubscriptionStatusEnum,
  useOnboardingSubscriptionStateQuery,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './onboarding-subscription.module.scss';

export const OnboardingSubscription = () => {
  const { onboardingSubscriptionId = '' } = useParams();
  const displaySignature = useFeatureFlag(FeatureFlagEnum.DISPLAY_SIGNATURE);

  const { data, loading, error } = useOnboardingSubscriptionStateQuery({
    variables: {
      onboardingSubscriptionId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data || !data.onboardingSubscriptionState) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (
    onboardingStatusWaitingForLpActions.includes(
      data.onboardingSubscriptionState.status,
    )
  ) {
    return <Navigate to={FundManagerRoutes.OnboardingSubscriptionRequested} />;
  }

  if (
    data.onboardingSubscriptionState.status ===
    OnboardingSubscriptionStatusEnum.SubInfoValidated
  ) {
    return (
      <RiskAssessmentsForm
        onboardingSubscriptionId={data.onboardingSubscriptionState.id}
        lpId={data.onboardingSubscriptionState.lpState.id}
      />
    );
  }

  // SIGNATURE
  if (displaySignature) {
    if (
      data.onboardingSubscriptionState.status ===
      OnboardingSubscriptionStatusEnum.ReadyForSignature
    ) {
      return (
        <OnboardingSubscriptionReadyForSignature
          onboardingSubscriptionId={data.onboardingSubscriptionState.id}
        />
      );
    }

    if (
      data.onboardingSubscriptionState.status ===
      OnboardingSubscriptionStatusEnum.WaitingForGpSignature
    ) {
      return (
        <Stack>
          <SignatureIframe
            onboardingSubscriptionId={data.onboardingSubscriptionState.id}
            redirectionLink={FundManagerRoutes.OnboardingSubscriptionHistory}
          />
        </Stack>
      );
    }
  }

  return (
    <Stack className={styles.card}>
      <Stack spacing={2} className={styles.cardContent}>
        {[
          OnboardingSubscriptionStatusEnum.ProfileCompleted,
          OnboardingSubscriptionStatusEnum.ProfileDeclined,
          OnboardingSubscriptionStatusEnum.SubInfoCompleted,
        ].includes(data.onboardingSubscriptionState.status) ? (
          <ProfileValidation {...data.onboardingSubscriptionState} />
        ) : (
          <OnboardingValidation {...data.onboardingSubscriptionState} />
        )}
      </Stack>
    </Stack>
  );
};
