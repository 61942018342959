import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OnboardingSubscriptionNavBar } from 'business/fund-manager/onboarding-subscription/components/onboarding-subscription-navbar';
import {
  FormattedOnboardingSubscription,
  formatOnboardingSubscription,
} from 'business/fund-manager/onboarding-subscription/services/format-onboarding-subscriptions';
import { getOnboardingSubscriptionColumnDefinition } from 'business/fund-manager/onboarding-subscription/services/get-onboarding-subscription-column-definition';
import {
  CountsPerTab,
  OnboardingSubscriptionNavBarTabEnum,
} from 'business/fund-manager/onboarding-subscription/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useLanguageContext } from 'business/providers/language-provider';
import {
  OnboardingSubscriptionStatusEnum,
  useOnboardingSubscriptionsQuery,
} from 'generated/graphql';
import { getDateFnsLocaleFromLanguage } from 'technical/localization';
import { AlternativeTable } from 'ui/alternative-table';
import { ErrorLabel } from 'ui/error-label';

type Props = {
  activeTab: OnboardingSubscriptionNavBarTabEnum;
};

const tabSubscriptionStatusMap = {
  [OnboardingSubscriptionNavBarTabEnum.Requested]: [
    OnboardingSubscriptionStatusEnum.InvitationSent,
    OnboardingSubscriptionStatusEnum.ProfileCompleted,
    OnboardingSubscriptionStatusEnum.ProfileValidated,
    OnboardingSubscriptionStatusEnum.ProfileDeclined,
    OnboardingSubscriptionStatusEnum.SubInfoCompleted,
    OnboardingSubscriptionStatusEnum.SubInfoDeclined,
    OnboardingSubscriptionStatusEnum.SubInfoValidated,
    OnboardingSubscriptionStatusEnum.ReadyForSignature,
    OnboardingSubscriptionStatusEnum.WaitingForLpSignature,
    OnboardingSubscriptionStatusEnum.WaitingForGpSignature,
  ],
  [OnboardingSubscriptionNavBarTabEnum.Unsuccessful]: [
    OnboardingSubscriptionStatusEnum.Rejected,
  ],
  [OnboardingSubscriptionNavBarTabEnum.History]: [
    OnboardingSubscriptionStatusEnum.Activated,
  ],
};

export type OnboardingSubscriptionRefetch = ReturnType<
  typeof useOnboardingSubscriptionsQuery
>['refetch'];

export const OnboardingSubscriptionList = ({ activeTab }: Props) => {
  const { t } = useTranslation();
  const { language } = useLanguageContext();
  const locale = getDateFnsLocaleFromLanguage(language);
  const navigate = useNavigate();
  const { data, loading, error, refetch } = useOnboardingSubscriptionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      requestedStatus: {
        onboardingStatus:
          tabSubscriptionStatusMap[
            OnboardingSubscriptionNavBarTabEnum.Requested
          ],
      },
      unsuccessfulStatus: {
        onboardingStatus:
          tabSubscriptionStatusMap[
            OnboardingSubscriptionNavBarTabEnum.Unsuccessful
          ],
      },
      historyStatus: {
        onboardingStatus:
          tabSubscriptionStatusMap[OnboardingSubscriptionNavBarTabEnum.History],
      },
    },
  });

  const subscriptionsPerTab = {
    [OnboardingSubscriptionNavBarTabEnum.Requested]: data?.requested ?? [],
    [OnboardingSubscriptionNavBarTabEnum.Unsuccessful]:
      data?.unsuccessful ?? [],
    [OnboardingSubscriptionNavBarTabEnum.History]: data?.history ?? [],
  };

  const rowData: FormattedOnboardingSubscription[] =
    subscriptionsPerTab?.[activeTab].map((row) =>
      formatOnboardingSubscription({ data: row, t, locale }),
    ) ?? [];

  const onSeeDetails = (id: string) =>
    navigate(
      FundManagerRoutes.OnboardingSubscriptionId.replace(
        ':onboardingSubscriptionId',
        id,
      ),
    );

  const headers = getOnboardingSubscriptionColumnDefinition({
    t,
    shouldSeeContract:
      activeTab === OnboardingSubscriptionNavBarTabEnum.History,
    onSeeDetails:
      activeTab === OnboardingSubscriptionNavBarTabEnum.Requested
        ? onSeeDetails
        : undefined,
    shouldSeeReject:
      activeTab === OnboardingSubscriptionNavBarTabEnum.Requested,
    refetch,
  });

  const counts: CountsPerTab = {
    [OnboardingSubscriptionNavBarTabEnum.Requested]:
      subscriptionsPerTab?.[OnboardingSubscriptionNavBarTabEnum.Requested]
        .length ?? 0,
    [OnboardingSubscriptionNavBarTabEnum.Unsuccessful]:
      subscriptionsPerTab?.[OnboardingSubscriptionNavBarTabEnum.Unsuccessful]
        .length ?? 0,
    [OnboardingSubscriptionNavBarTabEnum.History]:
      subscriptionsPerTab?.[OnboardingSubscriptionNavBarTabEnum.History]
        .length ?? 0,
  };
  return (
    <OnboardingSubscriptionNavBar activeTab={activeTab} counts={counts}>
      {error?.message ? <ErrorLabel label={error.message} /> : null}
      <AlternativeTable<FormattedOnboardingSubscription>
        rowData={rowData}
        fileName="onboarding-subscriptions"
        columnDefs={headers}
        domLayout="autoHeight"
        displaySidebar
        loading={loading}
      />
    </OnboardingSubscriptionNavBar>
  );
};
