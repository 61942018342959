import BlockIcon from '@mui/icons-material/Block';
import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { Typo } from 'ui/typo';

interface EmptyCardProps {
  title?: string;
  subtitle?: string;
}

export const EmptyContent = ({ title, subtitle }: EmptyCardProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack marginBottom={3}>
        <BlockIcon />
      </Stack>
      <Typo size="xl">{title ?? t('common.noData')}</Typo>
      {subtitle ? <Typo size="sm">{subtitle}</Typo> : null}
    </>
  );
};
