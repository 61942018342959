import { simpleClauseBuilder } from 'technical/filter/build-where-condition';
import { QueryParams } from 'technical/filter/types';

export const shareTypeClause = (gqlOperator: { [key: string]: unknown }) => {
  return {
    share: {
      type: gqlOperator,
    },
  };
};

export const fundNameClause = (gqlOperator: { [key: string]: unknown }) => {
  return {
    share: {
      fund: {
        name: gqlOperator,
      },
    },
  };
};

export const buildValuationNestedClauses = (
  field: string,
  gqlOperator: {
    [key: string]: unknown;
  },
): QueryParams => {
  if (field === 'shareType') {
    return shareTypeClause(gqlOperator);
  }
  if (field === 'fund') {
    return fundNameClause(gqlOperator);
  }
  return simpleClauseBuilder(field, gqlOperator);
};
