import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { useFundSubscriptionValuesQuery } from 'generated/graphql';
import { ChartIcon, FileIcon, RightArrowIcon } from 'ui/icons';
import { QueryStateDisplay } from 'ui/query-state-display';
import { StatNumericAmountCard } from 'ui/stat-numeric-amount-card';
import { StatNumericAmountItemProps } from 'ui/stat-numeric-amount-item';
import { Typo } from 'ui/typo';

interface Props {
  fundId: string;
}

export const FundSubscriptions = ({ fundId }: Props) => {
  const { t } = useTranslation();
  const { data, loading, error } = useFundSubscriptionValuesQuery({
    variables: { input: { id: fundId } },
    fetchPolicy: 'network-only',
  });

  const { currency } = useFund();

  if (loading || error || !data?.subscriptions) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  const {
    numericCommitments,
    lpCount,
    numericTotalDrawn,
    numericUnfundedCommitments,
    countPendingValidationSubscriptions,
    countPendingValidationShareTransfers,
  } = data.subscriptions;

  const pendingValidationItemsCount =
    countPendingValidationSubscriptions + countPendingValidationShareTransfers;

  const items: StatNumericAmountItemProps[] = [
    {
      value: numericCommitments,
      currency,
      description: (
        <Stack direction="row" spacing={0.5}>
          <Typo>
            {t('pages.dashboard.cards.subscriptions.totalSubscriptions')}
          </Typo>
          <Typo bold>
            {t('pages.dashboard.cards.subscriptions.lpCount', {
              count: lpCount,
            })}
          </Typo>
        </Stack>
      ),
      imageSource: <ChartIcon />,
      size: 'normal',
      iconColor: 'secondary',
    },
    {
      value: numericTotalDrawn,
      currency,
      description: t('pages.dashboard.cards.subscriptions.totalDrawn'),
      imageSource: <RightArrowIcon />,
      size: 'normal',
      iconColor: 'primary',
    },
    {
      value: numericUnfundedCommitments,
      currency,
      description: t(
        'pages.dashboard.cards.subscriptions.unfundedSubscriptions',
      ),
      imageSource: <FileIcon />,
      size: 'normal',
      iconColor: 'secondary',
    },
  ];

  return (
    <StatNumericAmountCard
      chipContent={pendingValidationItemsCount}
      items={items}
      title={t('pages.dashboard.cards.subscriptions.title')}
      path={`${FundManagerRoutes.FundIdSubscription.replace(
        ':fundId',
        fundId,
      )}`}
    />
  );
};
