export enum LpPlatformRoutes {
  Home = '/lp/',
  Contact = '/lp/contact',
  Document = '/lp/document',
  ContactUs = '/lp/contact-us',
  Commitment = '/lp/commitment',
  FinancialFlows = '/lp/financial-flows',
  FundShareValuations = '/lp/fund-share-valuations',
  Portfolio = '/lp/portfolio',
  SecurityLog = '/lp/security-log',

  OnboardingSubscription = '/lp/onboarding',
  LpOnboardingSubscriptionCreateId = '/lp/onboarding/create/:onboardingSubscriptionId',
  LpOnboardingSubscriptionCreateIdLp = '/lp/onboarding/create/:onboardingSubscriptionId/lp',
  LpOnboardingSubscriptionCreateIdSign = '/lp/onboarding/create/:onboardingSubscriptionId/sign',
}

export default LpPlatformRoutes;
