import _ from 'lodash';
import { LanguageEnum } from 'translations/types';

import { useLanguageContext } from 'business/providers/language-provider';
import { CustomQuestionOption } from 'business/shared/lp-creation-module/services/types';
import {
  Condition,
  CustomQuestionsWithAnswersQuery,
  LpCreationStepEnum,
  LpCustomQuestionTypeEnum,
  useCustomQuestionsWithAnswersQuery,
} from 'generated/graphql';
import { isCustomQuestionTypeInput } from 'technical/custom-questions';
import { QueryStateDisplay } from 'ui/query-state-display';
import { VALIDATION_ITEM_TYPES, ValidationItem } from 'ui/validation-item';

export const shouldDisplayAnswer = (
  condition: Condition | null | undefined,
  customAnswers: CustomQuestionsWithAnswersQuery['customQuestionAnswers'],
) => {
  if (!condition) {
    return true;
  }

  const answer = customAnswers.find(
    ({ questionId }) => questionId === condition.questionId,
  );

  if (!answer) {
    return false;
  }

  if (answer.answer?.values) {
    return _.intersection(condition.values, answer.answer.values).length;
  }
  return condition.values.includes(answer.answer?.value ?? '');
};

const getAnswerLabel = (
  {
    questionId,
    type,
    language,
    choices,
  }: {
    questionId: string;
    type: LpCustomQuestionTypeEnum;
    language: LanguageEnum;
    choices: CustomQuestionOption['choices'];
  },
  answers: CustomQuestionsWithAnswersQuery['customQuestionAnswers'],
) => {
  const answer = answers.find(
    ({ questionId: answerQuestionId }) => answerQuestionId === questionId,
  );

  if (!answer || !answer.answer) {
    return '';
  }

  if (
    type === LpCustomQuestionTypeEnum.SelectMultiple &&
    answer.answer.values &&
    choices?.options
  ) {
    const optionsChoices = answer.answer.values.map((value: string) => {
      const option = choices.options.find((choice) => choice.value === value);

      return option?.label[language];
    });

    return optionsChoices.join(', ');
  }

  if (!answer.answer?.value) {
    return '';
  }

  if (type === LpCustomQuestionTypeEnum.Select && choices?.options) {
    const selectedAnswer = choices.options.find(
      (option) => option.value === answer.answer?.value,
    );
    return selectedAnswer?.label[language] ?? '';
  }

  return answer.answer.value;
};

interface CustomQuestionValidationPartProps {
  lpId: string;
  entityStep: LpCreationStepEnum;
}
export const CustomQuestionValidationPart = ({
  lpId,
  entityStep,
}: CustomQuestionValidationPartProps) => {
  const { language } = useLanguageContext();

  const { data, loading, error } = useCustomQuestionsWithAnswersQuery({
    variables: {
      questionInput: {
        entityStep,
        lpId,
      },
      answerInput: {
        entityStep,
        lpId,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (error || loading || !data) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  if (!data.customQuestions.length) {
    return null;
  }

  return (
    <>
      {data.customQuestions
        .filter(({ type }) => isCustomQuestionTypeInput(type))
        .filter(({ condition }) =>
          shouldDisplayAnswer(condition, data.customQuestionAnswers),
        )
        .map(({ id, labelEn, labelFr, choices, type }) => (
          <ValidationItem
            key={`step-custom-${id}`}
            item={{
              type: VALIDATION_ITEM_TYPES.TEXT,
              label: language === LanguageEnum.en ? labelEn : labelFr,
              value: getAnswerLabel(
                { questionId: id, type, choices, language },
                data.customQuestionAnswers,
              ),
            }}
          />
        ))}
    </>
  );
};
