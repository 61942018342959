import { Divider, Stack } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

// TODO LogoUploadForm should be splitted with ui for a part and business for another
// ONLY ui must be imported here
import { LogoUploadForm } from 'business/fund-manager/shared/components/logo-upload-form';
import { getFieldName } from 'technical/get-field-name';
import { FormInputText } from 'ui/form';
import { FormInputPhone } from 'ui/form/form-input-phone/form-input-phone';
import { Typo } from 'ui/typo';

import { ContactIdentityForm } from './contact-identity-form';
import { Contact } from './types';

export interface ContactFormProps {
  control?: Control<any>;
  name?: string;
  withPicture?: boolean;
  onFileUpload: (formData: { filePath: string; uploadDate: Date }) => void;
  onExistingLogoDelete: () => void;
  existingLogo?: {
    url: string;
    name: string;
  };
}

export const ContactForm = ({
  name,
  control,
  onFileUpload,
  existingLogo,
  onExistingLogoDelete,
}: ContactFormProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <ContactIdentityForm name={name} control={control} />
      <FormInputText
        id="email"
        name={getFieldName<Contact>('email', name)}
        label={t('pages.contact.form.label.email')}
        type="email"
        required
        control={control}
      />
      <Stack direction="row" spacing={2}>
        <FormInputPhone
          id="phoneNumber1"
          name={getFieldName<Contact>('phoneNumber1', name)}
          label={t('pages.contact.form.label.mobile')}
          control={control}
          fullWidth
        />
        <FormInputPhone
          id="phoneNumber2"
          name={getFieldName<Contact>('phoneNumber2', name)}
          label={t('pages.contact.form.label.phone')}
          fullWidth
          control={control}
        />
      </Stack>
      <Divider light />
      <Stack direction="row" spacing={2}>
        <FormInputText
          id="companyName"
          fullWidth
          name={getFieldName<Contact>('companyName', name)}
          label={t('pages.contact.form.label.companyName')}
          control={control}
        />
        <FormInputText
          id="jobTitle"
          fullWidth
          name={getFieldName<Contact>('jobTitle', name)}
          label={t('pages.contact.form.label.jobTitle')}
          control={control}
        />
      </Stack>
      <Divider light />
      <Stack>
        <FormInputText
          id="comment"
          name={getFieldName<Contact>('comment', name)}
          multiline
          rows={3}
          label={t('pages.contact.form.label.comment')}
          control={control}
        />
      </Stack>
      {onFileUpload ? (
        <Stack>
          <Typo color="neutral">
            {t('pages.fundManager.portfolio.company.creation.uploadLogo')}
          </Typo>
          <LogoUploadForm
            onSubmit={onFileUpload}
            onExistingLogoDelete={onExistingLogoDelete}
            existingLogo={existingLogo}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};
