import { LpCreationRoutes } from 'business/shared/lp-creation-module/router/types';
import { ClientTypeEnum, LpCreationStepEnum } from 'generated/graphql';

import { LpCreationStep } from './types';

export const getCreationSteps = ({
  clientType,
  displayLpInvestorProfileStep,
  displayLpContactsStep,
}: {
  clientType: ClientTypeEnum;
  displayLpInvestorProfileStep: boolean;
  displayLpContactsStep: boolean;
}): LpCreationStep[] => {
  if (clientType === ClientTypeEnum.Individual) {
    return [
      {
        label: 'pages.fundManager.lpCreation.steps.generalInformation',
        url: LpCreationRoutes.CreateId,
        step: LpCreationStepEnum.IndividualGeneralInfos,
      },
      {
        label: 'pages.fundManager.lpCreation.steps.KYC',
        url: LpCreationRoutes.CreateIdIndividualKYC,
        step: LpCreationStepEnum.IndividualKyc,
      },
      ...(displayLpInvestorProfileStep
        ? [
            {
              label: 'pages.fundManager.lpCreation.steps.investorProfile',
              url: LpCreationRoutes.CreateIdInvestorProfile,
              step: LpCreationStepEnum.IndividualInvestorProfile,
            },
          ]
        : []),
      {
        label: 'pages.fundManager.lpCreation.steps.KYCDocumentation',
        url: LpCreationRoutes.CreateIdKYCDocuments,
        step: LpCreationStepEnum.LpDocument,
      },
      ...(displayLpContactsStep
        ? [
            {
              label: 'pages.fundManager.lpCreation.steps.contacts',
              url: LpCreationRoutes.CreateIdContact,
              step: LpCreationStepEnum.Contacts,
            },
          ]
        : []),
    ];
  }

  return [
    {
      label: 'pages.fundManager.lpCreation.steps.generalInformation',
      url: LpCreationRoutes.CreateId,
      step: LpCreationStepEnum.LegalEntityGeneralInfos,
    },
    {
      label: 'pages.fundManager.lpCreation.steps.KYB',
      url: LpCreationRoutes.CreateIdKYB,
      step: LpCreationStepEnum.LegalEntityKyb,
    },
    ...(displayLpInvestorProfileStep
      ? [
          {
            label: 'pages.fundManager.lpCreation.steps.investorProfile',
            url: LpCreationRoutes.CreateIdInvestorProfile,
            step: LpCreationStepEnum.LegalEntityInvestorProfile,
          },
        ]
      : []),
    {
      label: 'pages.fundManager.lpCreation.steps.KYC',
      url: LpCreationRoutes.CreateIdLegalEntityKYC,
      step: LpCreationStepEnum.LegalEntityKyc,
    },
    {
      label: 'pages.fundManager.lpCreation.steps.KYCDocumentation',
      url: LpCreationRoutes.CreateIdKYCDocuments,
      step: LpCreationStepEnum.LpDocument,
    },
    ...(displayLpContactsStep
      ? [
          {
            label: 'pages.fundManager.lpCreation.steps.contacts',
            url: LpCreationRoutes.CreateIdContact,
            step: LpCreationStepEnum.Contacts,
          },
        ]
      : []),
  ];
};
