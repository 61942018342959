import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { FormFundShareValuation } from 'business/fund-manager/fund/components/form-fund-share-valution/form-fund-share-valuation';
import { DeleteLastShareValuationButton } from 'business/fund-manager/fund/pages/fund-share-valuation/delete-last-share-valuation-button';
import {
  FundShareValuationComputationValues,
  FundShareValuationEdited,
} from 'business/fund-manager/fund/services/types';
import { fundShareValuationComputationSchema } from 'business/fund-manager/fund/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  FundValidatedShareWithSubscriptionsQuery,
  useFundValidatedShareWithSubscriptionsQuery,
  useUpsertManyFundShareValuationsMutation,
} from 'generated/graphql';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';
import { formatAsDate } from 'technical/date';
import { handleError } from 'technical/error-reporting/error-utils';
import { ActionButton } from 'ui/action-button';
import { Card } from 'ui/card';
import { ErrorLabel } from 'ui/error-label';
import { FormInputDate } from 'ui/form';
import { FormGridSelectableList } from 'ui/form/form-grid-selectable-list';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SectionTitle } from 'ui/section-title';

const FormNavBarWithValuationDateSelector = ({
  fundId,
  onSubmit,
}: {
  fundId: string;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Stack paddingX={2} paddingY={1} spacing={1}>
        <SectionTitle
          title={t('pages.fundManager.fundShareValuation.form.sectionPeriod')}
        />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <FormInputDate
              size="small"
              name="date"
              fullWidth
              label={t('pages.fundManager.fundShareValuation.form.date')}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <Stack>
              <ActionButton
                variant="secondary"
                to={FundManagerRoutes.FundId.replace(':fundId', fundId)}
                component={Link}
              >
                {t('common.actions.cancel')}
              </ActionButton>
            </Stack>
            <Stack>
              <ActionButton variant="submit" onClick={onSubmit}>
                {t(
                  'pages.fundManager.fundShareValuationCreation.form.validate',
                )}
              </ActionButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

const CheckingDataFormNavBar = ({
  onCancel,
  onSave,
  isSubmitting,
  error,
}: {
  onCancel: () => void;
  onSave: () => void;
  isSubmitting?: boolean;
  error?: ApolloError;
}) => {
  const { t } = useTranslation();
  return (
    <Stack paddingX={2} paddingY={1} spacing={1}>
      <Stack
        direction="row"
        justifyContent="start"
        alignItems="center"
        spacing={2}
      >
        <Stack>
          <ActionButton variant="secondary" onClick={onCancel}>
            <Stack spacing={2} direction="row">
              <ArrowBack />
              {t('pages.fundManager.fundShareValuationCreation.form.goBack')}
            </Stack>
          </ActionButton>
        </Stack>
        <Stack>
          <ActionButton
            variant="submit"
            onClick={onSave}
            loading={isSubmitting}
          >
            {t('pages.fundManager.fundShareValuationCreation.form.confirm')}
          </ActionButton>
        </Stack>
        {error ? (
          <Stack flexGrow={1}>
            <ErrorLabel label={error.message} />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export interface FormGridValuationShares {
  share: FundValidatedShareWithSubscriptionsQuery['fundValidatedShareWithSubscriptions'][number];
  id: string;
  label: string;
}

export const FundShareValuationCreationPage = ({
  fundId,
  formGridShares,
  fundShareValuation,
}: {
  fundId: string;
  formGridShares: FormGridValuationShares[];
  fundShareValuation?: FundShareValuationEdited;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isEdition = !!fundShareValuation;

  const [upsertManyFundShareValuations, { loading, error }] =
    useUpsertManyFundShareValuationsMutation({
      onError: () =>
        enqueueSnackbar(t('errors.updateFund'), {
          variant: VariantTypeEnum.ERROR,
        }),
      onCompleted: () => {
        navigate(
          FundManagerRoutes.FundIdShareValuation.replace(':fundId', fundId),
        );
        enqueueSnackbar(t('successMessage.updateFund'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  const [checkingData, setCheckingData] = useState(false);

  const methods = useForm<FundShareValuationComputationValues>({
    resolver: yupResolver(fundShareValuationComputationSchema),
    defaultValues: {
      fundId,
      date: isEdition ? fundShareValuation?.date : formatAsDate(new Date()),
      shareValuations: formGridShares.map(({ share }) => ({
        id: isEdition ? fundShareValuation?.id : undefined,
        selected: isEdition ? true : undefined,
        date: share?.lastFundShareValuation?.date,
        lastResidualValue: convertFrom10X8toNumber(
          share?.lastFundShareValuation?.numericResidualValue ?? 0,
        ),
        managementFees: convertFrom10X8toNumber(
          fundShareValuation?.numericManagementFees,
        ),
        partnershipExpenses: convertFrom10X8toNumber(
          fundShareValuation?.numericPartnershipExpenses,
        ),
        totalOffsetsToFeesExpenses: convertFrom10X8toNumber(
          fundShareValuation?.numericTotalOffsetsToFeesExpenses,
        ),
        interestIncome: convertFrom10X8toNumber(
          fundShareValuation?.numericInterestIncome,
        ),
        dividendIncome: convertFrom10X8toNumber(
          fundShareValuation?.numericDividendIncome,
        ),
        interestExpense: convertFrom10X8toNumber(
          fundShareValuation?.numericInterestExpense,
        ),
        otherIncomeExpense: convertFrom10X8toNumber(
          fundShareValuation?.numericOtherIncomeExpense,
        ),
        accruedCarriedInterest: convertFrom10X8toNumber(
          fundShareValuation?.numericAccruedCarriedInterest,
        ),
        realizedPortfolio: convertFrom10X8toNumber(
          fundShareValuation?.numericRealizedPortfolio,
        ),
        unrealizedPortfolio: convertFrom10X8toNumber(
          fundShareValuation?.numericUnrealizedPortfolio,
        ),
        shareId: share.id,
        lastFundShareValuationId:
          fundShareValuation?.lastFundShareValuationId ?? undefined,
        totalDrawn: convertFrom10X8toNumber(
          fundShareValuation?.numericTotalDrawn,
        ),
        totalDistributedNonRecallable: convertFrom10X8toNumber(
          fundShareValuation?.numericTotalDistributedRecallable,
        ),
        totalDistributedRecallable: convertFrom10X8toNumber(
          fundShareValuation?.numericTotalDistributedNonRecallable,
        ),
      })),
    },
  });

  const onCancel = () => {
    setCheckingData(false);
  };
  const onError = (formError: any) => {
    let errorMessage: string | undefined;
    // Check if there's a root-level error message for shareValuations
    if (formError?.shareValuations?.root?.message) {
      errorMessage = formError.shareValuations.root.message;
    }
    // Check for errors in the shareValuations array and display the first one found
    if (!errorMessage && formError?.shareValuations?.length) {
      for (let i = 0; i < formError.shareValuations.length; i++) {
        if (formError.shareValuations[i]?.message) {
          errorMessage = formError.shareValuations[i].message;
          break;
        }
      }
    }
    if (errorMessage) {
      enqueueSnackbar(t(errorMessage), {
        variant: VariantTypeEnum.ERROR,
      });
    }
  };

  const onSave = () => {
    try {
      // The revalidation of data makes sure correctly typed data are sent to the server
      const data = fundShareValuationComputationSchema.validateSync(
        methods.getValues(),
      );
      const payload = {
        fundId: data.fundId,
        date: formatAsDate(data.date),
        snapshotedAt: data.snapshotedAt,
        shareValuations: data.shareValuations
          .filter((shareValuation) => shareValuation.selected)
          .map((shareValuation) => {
            const {
              shareId,
              id,
              accruedCarriedInterest,
              dividendIncome,
              interestExpense,
              interestIncome,
              managementFees,
              otherIncomeExpense,
              partnershipExpenses,
              realizedPortfolio,
              totalOffsetsToFeesExpenses,
              unrealizedPortfolio,
              lastFundShareValuationId,
            } = shareValuation;
            return {
              shareId,
              id,
              accruedCarriedInterest,
              dividendIncome,
              interestExpense,
              interestIncome,
              lastFundShareValuationId,
              managementFees,
              otherIncomeExpense,
              partnershipExpenses,
              realizedPortfolio,
              totalOffsetsToFeesExpenses,
              unrealizedPortfolio,
            };
          }),
      };
      upsertManyFundShareValuations({ variables: { input: payload } });
    } catch (err: any) {
      handleError(err);
      enqueueSnackbar(t('errors.updateValuationStatus'), {
        variant: VariantTypeEnum.ERROR,
      });
    }
  };

  const onSubmit = () => {
    setCheckingData(true);
  };
  return (
    <FormProvider {...methods}>
      <Stack spacing={2}>
        {!isEdition &&
          (!checkingData ? (
            // Step 1: Select the valuation date and validate
            <FormNavBarWithValuationDateSelector
              fundId={fundId}
              // The validation is ran on the form through methods.handleSubmit
              onSubmit={methods.handleSubmit(onSubmit, onError)}
            />
          ) : (
            // Step 2: Display the form to check the data and confirm the new valuations
            <CheckingDataFormNavBar
              onCancel={onCancel}
              // The validation is ran again through onSave
              onSave={onSave}
              error={error}
              isSubmitting={loading}
            />
          ))}
        <Card>
          <Stack paddingX={2} paddingY={1} spacing={1}>
            {isEdition ? (
              // Edition: single share valuation editable
              <div className="flex flex-col gap-4 items-center">
                <div className="flex flex-col">
                  <div className="p-4 flex justify-between items-center font-bold">
                    {t('pages.fundManager.shared.shareWithName', {
                      shareName: formGridShares[0].label,
                    })}{' '}
                    - {formatAsDate(fundShareValuation.date)}
                    <ActionButton
                      variant="submit"
                      onClick={onSave}
                      loading={loading}
                    >
                      {t(
                        'pages.fundManager.fundShareValuationCreation.form.confirmEdit',
                      )}
                    </ActionButton>
                  </div>
                  <FormFundShareValuation
                    name={'shareValuations.0'}
                    readonly={false}
                    {...formGridShares[0]}
                    fundShareValuation={fundShareValuation}
                  />
                </div>
                {/* Deactivate button if the form has been modified */}
                <DeleteLastShareValuationButton
                  valuationId={fundShareValuation.id}
                  disabled={methods.formState.isDirty}
                />
              </div>
            ) : (
              // Creation: multiple shares are checkable with the corresponding valuation
              <FormGridSelectableList
                name="shareValuations"
                items={formGridShares}
                readonly={checkingData}
                component={FormFundShareValuation}
              />
            )}
          </Stack>
        </Card>
      </Stack>
    </FormProvider>
  );
};

export const FundShareValuationCreation = () => {
  const { fundId = '' } = useParams();

  const { loading, error, data } = useFundValidatedShareWithSubscriptionsQuery({
    variables: {
      input: { id: fundId },
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.fundValidatedShareWithSubscriptions) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const formGridShares = data.fundValidatedShareWithSubscriptions.map(
    (share) => ({
      share,
      id: share.id,
      label: share.name,
    }),
  );

  return (
    <FundShareValuationCreationPage
      fundId={fundId}
      formGridShares={formGridShares}
    />
  );
};
