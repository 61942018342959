import { Locale } from 'date-fns';
import { format } from 'date-fns-tz';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { getSharesCommonValuationDates } from 'business/fund-manager/fund/services/get-shares-common-valuation-dates';
import { useLanguageContext } from 'business/providers/language-provider';
import {
  FundReportingTypeEnum,
  GetFundShareValuationQuery,
  useGetFundShareValuationQuery,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { getDateFnsLocaleFromLanguage } from 'technical/localization';
import { SelectInput } from 'ui/form/select-input';

const getFundReportingValuationDateOptions = (
  fundShareValuation: GetFundShareValuationQuery | undefined,
  selectedSharesIds: string[] | undefined,
  locale: Locale,
) => {
  if (
    !fundShareValuation ||
    !selectedSharesIds ||
    selectedSharesIds.length === 0
  ) {
    return [];
  }

  const commonValuationDates = getSharesCommonValuationDates(
    fundShareValuation.fundShareValuations,
    selectedSharesIds,
  );
  return commonValuationDates.map((date) => ({
    // We need to preserve the precision of the datetime to the ms
    id: formatAsDate(date),
    value: format(date, 'PPP', { locale }),
  }));
};

interface FundReportingValuationDateInputProps {
  fundId: string;
}
export const FundReportingValuationDateInput = ({
  fundId,
}: FundReportingValuationDateInputProps) => {
  const { t } = useTranslation();

  const { resetField } = useFormContext();

  const { data: fundShareValuation } = useGetFundShareValuationQuery({
    variables: {
      input: {
        id: fundId,
      },
    },
  });

  const [type, shareIds] = useWatch({
    name: ['type', 'shareIds'],
  });

  useEffect(() => {
    resetField('valuationDate');
  }, [resetField, shareIds]);

  const { language } = useLanguageContext();

  const locale = getDateFnsLocaleFromLanguage(language);

  return (
    <SelectInput
      name="valuationDate"
      label={t('pages.fundManager.reporting.valuationDate')}
      options={getFundReportingValuationDateOptions(
        fundShareValuation,
        shareIds,
        locale,
      )}
      size="small"
      disabled={
        type !== FundReportingTypeEnum.CapitalAccountStatement ||
        !shareIds ||
        !shareIds.length
      }
      required
      fullWidth
    />
  );
};
