import { Avatar, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { FundManagerDashboardLpQuery } from 'generated/graphql';
import { InfoCard } from 'ui/info-card';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface Props {
  lpContacts: FundManagerDashboardLpQuery['lpDashboardInformation']['lpContacts'];
  pendingValidationContacts: number | undefined;
}

/**
 * code duplicate in fundManager/portofolio/company/components/contacts-card
 * So if you duplicate it again think about making a ui/component ;)
 */
export const ContactsCard = ({
  lpContacts,
  pendingValidationContacts,
}: Props) => {
  const { lpId = '' } = useParams();
  const { t } = useTranslation();

  const legalRepresentative = lpContacts.filter(
    (contact) => contact.isLegalRepresentative,
  )?.[0].contactView;

  const contactsCount = lpContacts.filter((lpContact) => {
    return !lpContact.isLegalRepresentative;
  }).length;

  return (
    <InfoCard>
      <InfoCard.Title
        path={FundManagerRoutes.LpIdContacts.replace(':lpId', lpId)}
        chipContent={pendingValidationContacts}
      >
        Contacts
      </InfoCard.Title>
      <Stack padding={3} direction="row" spacing={5}>
        <Avatar className={styles.avatar} variant="rounded"></Avatar>
        <Stack spacing={2}>
          <Stack>
            <Stack direction="row" spacing={0.5}>
              <Typo size="xl">
                {legalRepresentative ? legalRepresentative.title : ''}
              </Typo>
              <Typo bold size="xl">
                {legalRepresentative
                  ? `${legalRepresentative.firstName} ${legalRepresentative.lastName}`
                  : ''}
              </Typo>
            </Stack>
            <Typo>
              {t('pages.dashboard.cards.contacts.legalRepresentative')}
            </Typo>
          </Stack>
          <Stack>
            <Typo color="primaryDark">
              {`+ ${t('pages.dashboard.cards.contacts.contactWithCount', {
                count: contactsCount,
              })}`}
            </Typo>
          </Stack>
        </Stack>
      </Stack>
    </InfoCard>
  );
};
