import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip } from '@mui/material';

import { CurrencyEnum } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { Typo, TypoProps } from 'ui/typo';

import styles from './index.module.scss';

interface Props extends TypoProps {
  value: number;
  currency: CurrencyEnum;
}

export const Amount = ({ value, currency, ...restTypoProps }: Props) => {
  const decimalValue = convertFrom10X8toNumber(value);
  return (
    <Typo {...restTypoProps} component="div">
      <Stack spacing={0.5} direction="row" alignItems="stretch">
        <span>{formatToMonetaryAmount(decimalValue, { currency })}</span>
        <Stack justifyContent="center">
          <Tooltip
            title={formatToMonetaryAmount(decimalValue, {
              currency,
              precision: 8,
            })}
            arrow
          >
            <InfoOutlinedIcon className={styles.icon} />
          </Tooltip>
        </Stack>
      </Stack>
    </Typo>
  );
};
