import { Stack } from '@mui/material';

import { CustomProgressBar } from 'ui/progression-bar';
import { Typo } from 'ui/typo';

interface PercentBarCellProps {
  percent: number;
  label: string;
}
export const PercentBarCell = ({ percent, label }: PercentBarCellProps) => (
  <Stack spacing={1} alignItems="flex-end">
    <Typo>{label}</Typo>
    <CustomProgressBar value={percent} />
  </Stack>
);
