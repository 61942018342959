import { useController } from 'react-hook-form';

import { Select, SelectProps } from 'ui/select';

interface SelectInputProps
  extends Omit<SelectProps, 'defaultValue' | 'onChange'> {
  defaultValue?: string | undefined;
  control?: any;
  onChange?: (value: string) => void;
  multiple?: boolean;
}

export function SelectInput({
  name,
  required,
  control,
  onChange,
  disabled,
  ...rest
}: SelectInputProps) {
  const {
    field: { value, onChange: onFormChange },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController<{ value: string }>({
    control,
    name: name as 'value',
    rules: { required },
  });

  return (
    <Select
      {...rest}
      value={value}
      error={!!error}
      helperText={error?.message}
      name={name}
      required={required}
      disabled={disabled || isSubmitting}
      onChange={(newValue: string | null) =>
        onChange ? onChange(newValue ?? '') : onFormChange(newValue ?? '')
      }
    />
  );
}
