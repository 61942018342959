import { ContactWithId } from 'business/shared/services/contact/types';

const booleanFieldToNumber = (field: boolean) => (field ? 1 : 0);

export const getEmailingPreferencesCountByKeys = (data: ContactWithId[]) => {
  return data.reduce(
    (acc, contact) => {
      return {
        isLegalRepresentative:
          acc.isLegalRepresentative +
          booleanFieldToNumber(!!contact.isLegalRepresentative),
        hasOperationalRights:
          acc.hasOperationalRights +
          booleanFieldToNumber(!!contact.hasOperationalRights),
        hasLpPortalAccess:
          acc.hasLpPortalAccess +
          booleanFieldToNumber(!!contact.hasLpPortalAccess),
        canAccessGeneralReports:
          acc.canAccessGeneralReports +
          booleanFieldToNumber(!!contact.canAccessGeneralReports),
        canAttendInvestorsMeeting:
          acc.canAttendInvestorsMeeting +
          booleanFieldToNumber(!!contact.canAttendInvestorsMeeting),
        canAccessESGReports:
          acc.canAccessESGReports +
          booleanFieldToNumber(!!contact.canAccessESGReports),
        canAccessAmpereReports:
          acc.canAccessAmpereReports +
          booleanFieldToNumber(!!contact.canAccessAmpereReports),
        canAccessCapitalAccountStatement:
          acc.canAccessCapitalAccountStatement +
          booleanFieldToNumber(!!contact.canAccessCapitalAccountStatement),
      };
    },
    {
      isLegalRepresentative: 0,
      hasOperationalRights: 0,
      hasLpPortalAccess: 0,
      canAccessGeneralReports: 0,
      canAttendInvestorsMeeting: 0,
      canAccessESGReports: 0,
      canAccessAmpereReports: 0,
      canAccessCapitalAccountStatement: 0,
    },
  );
};
