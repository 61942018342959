import * as yup from 'yup';

import {
  CurrencyEnum,
  FundShareValuationToEditQuery,
  GetFundShareValuationQuery,
  GetFundsQuery,
} from 'generated/graphql';

import {
  deprecatedCreateFundShareValuationSchema,
  fundBankDetailsSchema,
  fundProfileSchema,
  fundReportingCreationSchema,
  getShareTransferFormSchema,
  residualValueComputationSchema,
  fundShareValuationComputationSchema,
} from './validation';

export interface FundsFilteredFields {
  fundNames: string[];
  vintageYears: number[];
}

export enum ShareTransferErrors {
  AMOUNT_TOO_BIG = 'amount-too-big',
  INVALID_DATE = 'invalid-date',
}

export type FundProfile = yup.InferType<typeof fundProfileSchema>;

export type FundColumnDef = GetFundsQuery['web_portal_fund'][number] & {
  linkToDashBoard: string;
};

export type FundBankDetails = yup.InferType<typeof fundBankDetailsSchema>;

export type FundSubscription = {
  id: string;
  date: string | null;
  amount: number;
  lpName: string;
  lpId: string;
  lpCountry: string | null;
  fundName: string;
  fundId: string;
  shareType: string;
};

export type ShareTransfer = {
  id: string;
  numericTotalPrice: bigint;
  date: string | null;
  numericAmount: bigint;
  sellerName: string;
  buyerName: string;
  shareType: string;
};

export type FundSubscriptionFilteredFields = {
  lpNames: string[];
  shares: string[];
};

export type ShareTransferFilteredFields = {
  sellerNames: string[];
  buyerNames: string[];
  shareTypes: string[];
};

export interface FundData {
  id: string;
  fundName: string;
  vintageYear: number;
  publicationStatus: string;
  numericCommitments: number;
  numericTotalDrawn: number;
  numericDistributedNonRecallable: number;
  numericCurrentDistributed: number;
  numericUnfundedCommitments: number;
  subscribers: number;
  currency?: CurrencyEnum;
  pendingValidationItemsCount: number;
}

// fake schema only for type definition
// TODO refacto getShareTransferFormSchema
const shareTransferSchema = getShareTransferFormSchema({
  commitment: 0,
  t: () => '',
});
export type ShareTransferForm = yup.InferType<typeof shareTransferSchema>;

export enum FundNavBarTabEnum {
  Profile = 0,
  Bank = 1,
  Share = 2,
}

// same but prefer to split than above
export enum FundCreationStepEnum {
  Profile = 0,
  Bank = 1,
  Share = 2,
}

export type DeprecatedFundShareValuationCreationInput = yup.InferType<
  typeof deprecatedCreateFundShareValuationSchema
>;

export type ResidualValueComputationValues = yup.InferType<
  typeof residualValueComputationSchema
> & {
  lastResidualValue?: number;
};

export type FundShareValuationComputationValues = yup.InferType<
  typeof fundShareValuationComputationSchema
>;

export enum FundShareValuationFormModeEnum {
  Creation = 'creation',
  Edition = 'edition',
}

export interface FundShareValuationFormInitialValues {
  paidIn: number;
  distributedRecallable: number;
  distributedNonRecallable: number;
  residualValue?: number;
  date?: number;
}

export enum DocumentStatusEnum {
  Expired = 'expired',
  Validated = 'validated',
  PendingValidation = 'pendingValidation',
  Declined = 'declined',
  Requested = 'requested',
}

export type LpOnboardingDocument = {
  name: string;
  documentId: string;
  date: string;
  type?: string;
  status: DocumentStatusEnum;
  expirationDate?: string;
};

export type FundReportingCreation = yup.InferType<
  typeof fundReportingCreationSchema
>;

export type FundShareValuationFetched =
  GetFundShareValuationQuery['fundShareValuations'][number];

export type FundShareValuationEdited =
  FundShareValuationToEditQuery['fundShareValuationToEdit'];
