import { InfoItem, InfoItemProps } from 'ui/info-item';
import { StatInfoCard } from 'ui/stat-info-card/stat-info-card';
import {
  StatNumericAmountItem,
  StatNumericAmountItemProps,
} from 'ui/stat-numeric-amount-item';

interface DeprecatedProps {
  items: InfoItemProps[];
  path?: string;
  title: string;
}
// this component is used to display numbers
// but not all numbers are in decimal yet, delete it once it is not
// in use anymore
export const DeprecatedStatInfoCard = ({ items, ...rest }: DeprecatedProps) => {
  return (
    <StatInfoCard {...rest}>
      {items.map(({ value, description, ...restItem }) => (
        <InfoItem
          {...restItem}
          key={`${value}_${description}`}
          value={value}
          description={description}
          size="dense"
        />
      ))}
    </StatInfoCard>
  );
};

interface Props {
  items: StatNumericAmountItemProps[];
  path?: string;
  titleChipContent?: number;
  title: string;
  chipContent?: number;
}

export const StatNumericAmountCard = ({ items, ...rest }: Props) => {
  return (
    <StatInfoCard {...rest}>
      {items.map(({ value, description, ...restItem }) => (
        <StatNumericAmountItem
          {...restItem}
          key={`${value}_${description}`}
          value={value}
          description={description}
        />
      ))}
    </StatInfoCard>
  );
};
