import { SvgIcon } from '@mui/material';

import { SvgProps } from './types/props';

export const ValuationMenuIcon = ({ primaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <mask id="path-1-inside-1_1283_32014" fill="white">
        <rect x="5" y="16" width="4" height="5" rx="1" />
      </mask>
      <rect
        x="5"
        y="16"
        width="4"
        height="5"
        rx="1"
        fill="none"
        stroke="#363636"
        strokeWidth="3"
        mask="url(#path-1-inside-1_1283_32014)"
      />
      <mask id="path-2-inside-2_1283_32014" fill="white">
        <rect x="10" y="13" width="4" height="8" rx="1" />
      </mask>
      <rect
        x="10"
        y="13"
        width="4"
        height="8"
        rx="1"
        fill="none"
        stroke="#363636"
        strokeWidth="3"
        mask="url(#path-2-inside-2_1283_32014)"
      />
      <mask id="path-3-inside-3_1283_32014" fill="white">
        <rect x="15" y="8" width="4" height="13" rx="1" />
      </mask>
      <rect
        x="15"
        y="8"
        width="4"
        height="13"
        rx="1"
        fill="none"
        stroke="#363636"
        strokeWidth="3"
        mask="url(#path-3-inside-3_1283_32014)"
      />
      <path
        d="M5.5 14C8.16667 12.3333 12 11 16.5 4M16.5 4H14.5M16.5 4L17 6"
        fill="none"
        stroke={primaryColor}
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
