import FundManagerRoutes from 'business/fund-manager/router/routes';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { LpCreationRoutes } from 'business/shared/lp-creation-module/router/types';

export enum SharedRoutes {
  LoginCallback = '/login/callback',

  SignIn = '/signin',
  ValidateMFA = '/validate-mfa',
  SetupMFA = '/setup-mfa',
  ForgotPassword = '/forgot-password',
  // In this enum we use splats route (the * at the end of the url)
  // https://reactrouter.com/en/main/route/route#splats
  ResetPassword = '/reset-password/*',
  ConnectedResetPassword = '/reset-password',
  CreatePassword = '/create-password/*',
  Error = '/error',
}

export default SharedRoutes;

export const AllRoutes = {
  ...SharedRoutes,
  ...LpCreationRoutes,
  ...LpPlatformRoutes,
  ...FundManagerRoutes,
};
export type AllRoutes =
  | SharedRoutes
  | LpCreationRoutes
  | LpPlatformRoutes
  | FundManagerRoutes;
