import { CircleRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { ActiveFundsAndLpsQuery } from 'generated/graphql';

export const getSelectableLpColumnDefinition = (
  t: TFunction,
): ColDef<
  Pick<
    ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
    'subscribingEntityName'
  > & {
    id?: string;
  }
>[] => {
  return [
    {
      field: 'subscribingEntityName',
      headerName: t(
        'pages.fundManager.onboardingSubscriptionCreate.table.lpName',
      ),
      headerCheckboxSelection: false,
      checkboxSelection: true,
      cellRenderer: ({
        data,
      }: {
        data: Pick<
          ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
          'subscribingEntityName'
        > & {
          id?: string;
        };
      }) => {
        if (data?.id) {
          return data.subscribingEntityName;
        }
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack>{data.subscribingEntityName}</Stack>
            <CircleRounded color="secondary" fontSize="small" />
          </Stack>
        );
      },
    },
  ];
};
