import { get } from 'lodash';
import { TFunction } from 'translations/hook';

import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import {
  VALIDATION_ITEM_TYPES,
  ValidationItemType,
  ValidationTextItem,
} from 'ui/validation-item';

import { getAddressItems } from './get-address-items';
import { GetCardItemsParams, LpValidationLegalEntity } from './types';

interface WipValidationTextItem extends Omit<ValidationTextItem, 'value'> {
  value?: string;
}

const getContactDetailsCardItems = ({
  contact,
  t,
}: {
  contact: Record<string, undefined | null | string>;
  t: TFunction;
}): ValidationTextItem[] =>
  [
    'email',
    'phoneNumber1',
    'phoneNumber2',
    'companyName',
    'jobTitle',
    'comment',
  ]
    .map(
      (key: string): WipValidationTextItem => ({
        type: VALIDATION_ITEM_TYPES.TEXT,
        label: t(`pages.contact.form.label.${key}`),
        value: get(contact, key) ?? undefined,
      }),
    )
    .filter((v: WipValidationTextItem): v is ValidationTextItem => !!v.value);

interface GetContactItemsParams
  extends Omit<
    GetCardItemsParams<
      Partial<
        NonNullable<
          LpValidationLegalEntity['lpIndividualGeneralInfos']
        >['contactIndividualEntity']
      >
    >,
    'declineReasons'
  > {
  isLegalRepresentative?: boolean;
}

export const getContactItems = ({
  data: { ...contact },
  t,
  language,
  isLegalRepresentative,
}: GetContactItemsParams): ValidationItemType[] => [
  {
    type: VALIDATION_ITEM_TYPES.TEXT,
    label: t(
      isLegalRepresentative
        ? 'pages.fundManager.lpValidation.individualGeneralInformation.legalRepresentative'
        : 'pages.fundManager.lpValidation.contacts.name',
    ),
    value: getPersonFullName({
      // TODO: find a way to remove PARTIAL constraints to avoid TS '?' and '||'
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
    }),
  },
  ...getContactDetailsCardItems({
    contact: contact as Record<string, undefined | null | string>,
    t,
  }),
  ...(contact?.address
    ? getAddressItems({ address: contact?.address, t, language })
    : []),
];
