import { Stack } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { getFieldName } from 'technical/get-field-name';
import { FormInputText } from 'ui/form';
import { FormDropdownCountry } from 'ui/form/form-dropdown-country';

interface AddressFormPartProps {
  control: any;
  name?: string;
  required?: boolean;
  countryDisabled?: boolean;
  onCountryChanged?: (country: string | null) => void;
  hiddenCountry?: boolean;
  readonly?: boolean;
}

// TODO make translation more generic
export const AddressFormPart = ({
  control,
  name,
  required,
  countryDisabled,
  onCountryChanged,
  readonly = false,
  hiddenCountry = false,
}: AddressFormPartProps) => {
  const { t } = useTranslation();

  const { field: country } = useController({
    control,
    name: getFieldName('country', name),
  });

  const handleCountryChange = (value: string | null) => {
    country.onChange(value);
    onCountryChanged?.(value ?? '');
  };

  return (
    <>
      <FormInputText
        label={t('pages.fundManager.fundCreation.form.bankDetails.streetLine')}
        name={getFieldName('streetLine', name)}
        control={control}
        required={required}
        disabled={readonly}
      />

      <FormInputText
        label={t('pages.fundManager.fundCreation.form.bankDetails.streetLine2')}
        name={getFieldName('streetLine2', name)}
        control={control}
        disabled={readonly}
      />

      <Stack alignSelf="stretch" spacing={1} direction="row">
        <FormInputText
          label={t('pages.fundManager.fundCreation.form.bankDetails.city')}
          name={getFieldName('city', name)}
          control={control}
          required={required}
          disabled={readonly}
          fullWidth
        />

        <FormInputText
          label={t('pages.fundManager.fundCreation.form.bankDetails.zipcode')}
          name={getFieldName('zipCode', name)}
          control={control}
          required={required}
          disabled={readonly}
          fullWidth
        />

        {!hiddenCountry ? (
          <FormDropdownCountry
            label={t('pages.fundManager.fundCreation.form.bankDetails.country')}
            name={getFieldName('country', name)}
            control={control}
            required={required}
            disabled={countryDisabled || readonly}
            fullWidth
            onChange={handleCountryChange}
          />
        ) : null}
      </Stack>
    </>
  );
};
