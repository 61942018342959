import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import {
  CompanyMetricForm,
  Quarters,
} from 'business/fund-manager/portfolio/company/services/types';
import { DefaultPeriodTypeEnum } from 'generated/graphql';
import { FormInputDropdown, FormInputText } from 'ui/form';

export const PeriodFormInput = () => {
  const { control } = useFormContext<CompanyMetricForm>();
  const periodType = useWatch<CompanyMetricForm>({ name: 'periodType' });
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      {periodType === DefaultPeriodTypeEnum.Monthly ? (
        <FormInputDropdown
          label={t('pages.fundManager.portfolio.company.metrics.form.month')}
          name="month"
          control={control}
          fullWidth
          // creates an array containing
          // { "month", "index of month" }
          options={Array.from({ length: 12 }, (_, index) => ({
            value: format(new Date(0, index, 1), 'MMMM'),
            id: (index + 1).toString(),
          }))}
        />
      ) : periodType === DefaultPeriodTypeEnum.Quarterly ? (
        <FormInputDropdown
          label={t('pages.fundManager.portfolio.company.metrics.form.quarter')}
          name="quarter"
          control={control}
          fullWidth
          options={Object.values(Quarters).map((val) => ({
            id: val,
            value: val.toUpperCase(),
          }))}
        />
      ) : null}
      <FormInputText
        label={t('pages.fundManager.portfolio.company.metrics.form.year')}
        name="year"
        control={control}
        required
        fullWidth
      />
    </Stack>
  );
};
