import { TFunction } from 'translations/hook';

import { LpDocTypeWithDocumentQueryResult } from 'generated/graphql';

export const getLpDocumentTypeOptions = (
  t: TFunction,
  lpDocumentTypes: LpDocTypeWithDocumentQueryResult['data'],
) => {
  return lpDocumentTypes?.lpDocTypeWithDocument
    ? lpDocumentTypes.lpDocTypeWithDocument.map((lpDocumentType) => {
        return {
          value: t(
            `pages.fundManager.settings.lpDocumentType.values.${lpDocumentType.name}`,
            { defaultValue: lpDocumentType.name },
          ),
          id: `${lpDocumentType.id}`,
        };
      })
    : [];
};
