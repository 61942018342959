import { TFunction } from 'translations/hook';

import { GetAvailableMergeFieldsQuery } from 'generated/graphql';

export const getMergeFieldsTranslatedValue = (
  data:
    | GetAvailableMergeFieldsQuery['getAvailableMergeFieldsList']['mergeFieldsList']
    | undefined,
  t: TFunction,
) => {
  if (!data) {
    return [];
  }

  return data.map((list) => {
    return {
      context: list.context,
      mergedFields: list.mergeFieldsName.map((name) => {
        return {
          name,
          description: t(
            `pages.fundManager.templates.dynamicContent.availableMergeFields.${name}`,
          ),
        };
      }),
    };
  });
};
