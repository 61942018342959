import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { getYear } from 'date-fns';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { MetricTypeSelect } from 'business/fund-manager/portfolio/company/components/metric-type-select';
import { PeriodFormInput } from 'business/fund-manager/portfolio/company/components/period-form-input';
import { CompanyMetricForm } from 'business/fund-manager/portfolio/company/services/types';
import { companyMetricFormSchema } from 'business/fund-manager/portfolio/company/services/validation';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  DefaultDataRankEnum,
  DefaultPeriodTypeEnum,
  useCreateCompanyMetricMutation,
} from 'generated/graphql';
import { useOnFilesUpload } from 'technical/file-management/use-on-files-upload';
import {
  FormInputDate,
  FormInputFiles,
  FormInputText,
  FormToggleButton,
  SelectInput,
} from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';

interface Props {
  onClose: () => void;
  onAdded: () => void;
}

export const CompanyMetricCreationForm = ({ onClose, onAdded }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { companyId = '' } = useParams();

  const methods = useForm<CompanyMetricForm>({
    resolver: yupResolver<CompanyMetricForm>(companyMetricFormSchema),
    defaultValues: {
      // for periodType and dataRank, I choose to auto-assign the first values
      // shown. For more context I invite you to see what this composant looks like
      periodType: DefaultPeriodTypeEnum.Monthly,
      dataRank: DefaultDataRankEnum.Budget,
      year: getYear(new Date()),
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;

  const [createCompanyMetricMutation] = useCreateCompanyMetricMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.createCompanyMetrics'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onAdded();
      onClose();
    },
    onError: (error: ApolloError) => {
      enqueueSnackbar(t(`errors.createCompanyMetrics.${error.message}`), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const onSubmit = async ({
    month,
    files,
    fileName,
    fileDate,
    ...restInput
  }: CompanyMetricForm) =>
    createCompanyMetricMutation({
      variables: {
        input: {
          ...restInput,
          month: month ? +month : undefined,
          companyId,
          file:
            files?.[0] && fileName && fileDate
              ? {
                  name: fileName,
                  date: fileDate,
                  path: files[0].filePath,
                }
              : undefined,
        },
      },
    }).catch(() => undefined);

  const onFilesUpload = useOnFilesUpload({
    getFiles: () => getValues('files') ?? [],
  });

  return (
    <FormModalContainer
      title={t('pages.fundManager.portfolio.company.metrics.modal.title')}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      isSubmitting={isSubmitting}
      labelAction={t('common.actions.save')}
    >
      <FormProvider {...methods}>
        <FormToggleButton
          name="periodType"
          label={t(
            'pages.fundManager.portfolio.company.metrics.form.periodType.label',
          )}
          options={Object.values(DefaultPeriodTypeEnum).map((value) => ({
            label: t(
              'pages.fundManager.portfolio.company.metrics.form.periodType.label',
              {
                context: value,
              },
            ),
            value,
          }))}
          required
          fullWidth
        />
        <PeriodFormInput />

        <SelectInput
          name="dataRank"
          label={t('pages.fundManager.portfolio.company.metrics.form.dataRank')}
          options={Object.values(DefaultDataRankEnum).map((value) => ({
            value: t(
              'pages.fundManager.portfolio.company.metrics.form.dataRank',
              { context: value },
            ),
            id: value,
          }))}
          required
          fullWidth
        />

        <MetricTypeSelect />

        <FormInputFiles
          label={t(
            'pages.fundManager.portfolio.company.metrics.form.uploadDocumentation',
          )}
          name="files"
          limit={1}
          onFilesUpload={onFilesUpload}
        />

        <Stack direction="row" spacing={2}>
          <FormInputText
            label={t(
              'pages.fundManager.portfolio.company.metrics.form.documentName',
            )}
            fullWidth
            name="fileName"
          />

          <FormInputDate
            label={t(
              'pages.fundManager.portfolio.company.metrics.form.documentDate',
            )}
            fullWidth
            name="fileDate"
          />
        </Stack>

        <FormInputText
          label={t('pages.fundManager.portfolio.company.metrics.form.comment')}
          fullWidth
          name="comment"
          multiline
          rows={4}
        />
      </FormProvider>
    </FormModalContainer>
  );
};
