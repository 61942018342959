import { ApolloError } from '@apollo/client';
import { Divider, Stack, Typography } from '@mui/material';
import { FormEventHandler } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { Web_Portal_ManagementCompanyType_Enum } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import {
  FormCard,
  FormDropdownCountry,
  FormInputDropdown,
  FormInputText,
} from 'ui/form';

interface Props<T extends FieldValues> {
  control: Control<T>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onCountryChange: (value: string | null) => void;
  error?: ApolloError;
  unchangeableType?: boolean;
}

export const CompanyManagerForm = <T extends FieldValues>({
  control,
  onSubmit,
  onCountryChange,
  error,
  unchangeableType = false,
}: Props<T>) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <form onSubmit={onSubmit}>
      <FormCard>
        <Stack spacing={5}>
          <Typography variant="h5" sx={{ color: palette.primary[900] }}>
            {t('pages.fundManager.FundManagerCompanyUpdate.title')}
          </Typography>
          <Stack spacing={3}>
            <FormInputText
              name="companyInformation.name"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.name',
              )}
              control={control}
              required
            />
            <FormInputText
              name="addressInformation.streetLine"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.addressInformation.streetLine',
              )}
              control={control}
              required
            />
            <FormInputText
              name="addressInformation.streetLine2"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.addressInformation.streetLine2',
              )}
              control={control}
            />
            <Stack direction="row" spacing={4}>
              <FormInputText
                type="number"
                name="addressInformation.zipCode"
                label={t(
                  'pages.fundManager.FundManagerCompanyUpdate.form.addressInformation.zipCode',
                )}
                control={control}
                required
                fullWidth
              />
              <FormInputText
                name="addressInformation.city"
                label={t(
                  'pages.fundManager.FundManagerCompanyUpdate.form.addressInformation.city',
                )}
                control={control}
                required
                fullWidth
              />
              <FormDropdownCountry
                name="addressInformation.country"
                label={t(
                  'pages.fundManager.FundManagerCompanyUpdate.form.addressInformation.country',
                )}
                control={control}
                required
                onChange={onCountryChange}
                fullWidth
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={3}>
            <FormInputDropdown
              disabled={unchangeableType}
              name="companyInformation.type"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.type.title',
              )}
              placeholder={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.type.title',
              )}
              control={control}
              options={Object.values(Web_Portal_ManagementCompanyType_Enum).map(
                (id) => ({
                  id,
                  value: t(
                    `pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.type.values.${id}`,
                  ),
                }),
              )}
            />
            <FormInputText
              name="companyInformation.legalForm"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.legalForm',
              )}
              control={control}
              required
            />
            <FormInputText
              name="companyInformation.identifier"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.identifier',
              )}
              control={control}
              required
            />
            <FormInputText
              name="companyInformation.regulatorApprovalNumber"
              label={t(
                'pages.fundManager.FundManagerCompanyUpdate.form.companyInformation.regulatorApprovalNumber',
              )}
              control={control}
              required
            />
            <Stack>
              <ActionButton variant="submit" type="submit">
                {t('pages.fundManager.FundManagerCompanyUpdate.submit')}
              </ActionButton>
              {error ? <ErrorLabel label={error.message} /> : null}
            </Stack>
          </Stack>
        </Stack>
      </FormCard>
    </form>
  );
};
