import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FinancialFlowErrors } from 'business/fund-manager/operation/services/types';
import { createCashflowFormSchema } from 'business/fund-manager/operation/services/validation';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  CashflowEmittedByEnum,
  CashflowTypeEnum,
  Web_Portal_ManagementCompanyType_Enum,
  Web_Portal_PublicationStatus_Enum,
  refetchGetPendingValidationShareLpCashFlowCountQuery,
  useCreateCashflowMutation,
} from 'generated/graphql';
import {
  convertFromNumberTo10X8,
  convertFromNumberToX100,
} from 'technical/currency/formatters';
import { formatAsDate } from 'technical/date';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FormInputDate, FormInputText, FormToggleButton } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';

interface FormInputs {
  date: Date;
  type: CashflowTypeEnum;
  emittedBy: CashflowEmittedByEnum;
  amount: number;
}

interface Props {
  handleClose: () => void;
  operationShareLpId: string;
  onCreated?: () => void;
}

const CreateCashflowForm = ({
  handleClose,
  operationShareLpId,
  onCreated,
}: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(createCashflowFormSchema),
    defaultValues: {
      date: new Date(),
      amount: 0,
      type: CashflowTypeEnum.NetWireTransfer,
      emittedBy: CashflowEmittedByEnum.Fund,
    },
  });

  const [createCashflow] = useCreateCashflowMutation({
    refetchQueries: [
      refetchGetPendingValidationShareLpCashFlowCountQuery({
        operationShareLpId,
      }),
    ],
    onCompleted: () => {
      onCreated?.();
      enqueueSnackbar(
        t('successMessage.createCashflow', {
          context: isAdvisor
            ? Web_Portal_PublicationStatus_Enum.PendingValidation
            : Web_Portal_PublicationStatus_Enum.Validated,
        }),
        {
          variant: VariantTypeEnum.SUCCESS,
        },
      );
    },
    onError: () => {
      enqueueSnackbar(t('errors.createCashflow'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = async ({
    date,
    amount,
    type,
    emittedBy,
  }) => {
    try {
      const { data } = await createCashflow({
        variables: {
          input: {
            date: formatAsDate(date),
            amount: Math.trunc(convertFromNumberToX100(amount)).toString(10),
            numericAmount: Math.trunc(convertFromNumberTo10X8(amount)).toString(
              10,
            ),
            type,
            emittedBy,
            operationShareLpId,
          },
        },
      });

      if (!data?.createCashflow?.success && data?.createCashflow?.message) {
        throw Error(data?.createCashflow?.message);
      } else {
        handleClose();
      }
    } catch (err) {
      if (
        typeof err === 'string' &&
        Object.values<string>(FinancialFlowErrors).includes(err)
      ) {
        setError(t('errors.financialFlowCreation', { context: err }));
      } else {
        setError(t(ValidationErrors.GENERIC));
      }
    }
  };
  return (
    <FormModalContainer
      onSubmit={handleSubmit(onSubmit)}
      onClose={handleClose}
      title={t('pages.fundManager.financialFlowCreation.formTitle')}
      labelAction={t('pages.fundManager.financialFlowCreation.submit')}
    >
      <Stack spacing={2} direction={'row'}>
        <FormInputDate<FormInputs>
          name="date"
          control={control}
          label={t('pages.fundManager.financialFlowCreation.date')}
          fullWidth
        />
        <FormInputText<FormInputs>
          name="amount"
          control={control}
          label={t('pages.fundManager.financialFlowCreation.amount')}
          type="number"
          fullWidth
          rules={{
            validate: {
              minLength: (value: number) => value > 0,
            },
          }}
        />
      </Stack>
      <Stack spacing={2} direction={'row'}>
        <FormToggleButton<FormInputs>
          name="type"
          control={control}
          label={t('pages.fundManager.financialFlowCreation.type.label')}
          options={[
            {
              label: t('pages.fundManager.financialFlowCreation.type.value', {
                context: CashflowTypeEnum.NetWireTransfer,
              }),
              value: CashflowTypeEnum.NetWireTransfer,
            },
            {
              label: t('pages.fundManager.financialFlowCreation.type.value', {
                context: CashflowTypeEnum.EscrowAccountUse,
              }),
              value: CashflowTypeEnum.EscrowAccountUse,
            },
          ]}
        />
        <FormToggleButton<FormInputs>
          name="emittedBy"
          control={control}
          label={t('pages.fundManager.financialFlowCreation.emittedBy.label')}
          options={[
            {
              label: t(
                'pages.fundManager.financialFlowCreation.emittedBy.value',
                { context: CashflowEmittedByEnum.Fund },
              ),
              value: CashflowEmittedByEnum.Fund,
            },
            {
              label: t(
                'pages.fundManager.financialFlowCreation.emittedBy.value',
                { context: CashflowEmittedByEnum.Investor },
              ),
              value: CashflowEmittedByEnum.Investor,
            },
          ]}
        />
      </Stack>
      {error ? <ErrorLabel label={error} /> : null}
    </FormModalContainer>
  );
};

export default CreateCashflowForm;
