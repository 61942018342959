import { PropsWithChildren } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { LinkTabs } from 'ui/link-tabs';

export const LpEditionTabs = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { lpId = '' } = useParams();

  const tabs = [
    {
      to: FundManagerRoutes.LpIdEditProfile.replace(':lpId', lpId),
      label: t('pages.fundManager.lpCreation.steps.generalInformation'),
    },
    {
      to: FundManagerRoutes.LpIdEditBankDetails.replace(':lpId', lpId),
      label: t('pages.fundManager.lp.bankDetails.title'),
    },
  ];
  const activeTab = tabs.findIndex((tab) => pathname.startsWith(tab.to));

  return (
    <LinkTabs activeTab={activeTab} tabs={tabs}>
      {children}
    </LinkTabs>
  );
};
