export enum FuzzyFilterDataKey {
  Commitments = 'dataviz_commitment',
  Documents = 'documents',
  FinancialFlowOperation = 'operations',
  FinancialFlowCashFlow = 'cashflow',
  Portfolio = 'companies',
  Valuations = 'valuations',
  ValuationsAggregate = 'dataviz_valuation_aggregate',
}
export interface FuzzyFilterParams {
  data: any;
  searchedTerm: string;
  dataKeys: FuzzyFilterDataKey[];
  enabledColumns: FuzzyFilterEnabledColumn[];
  relatedKey?: FuzzyFilterDataKey;
}

export interface FuzzyFilteringResult {
  [key: string]: any[];
}

export enum FuzzyFilterEnabledColumn {
  ShareType = 'share.type',
  FundName = 'share.fund.name',
  CompanyName = 'name',
  CompanySector = 'sector',
  DocumentTypeFrLabel = 'type.labelFr',
  DocumentTypeEnLabel = 'type.labelEn',
}

export const searchOptions = {
  shouldSort: true,
  threshold: 0.5,
  matchAllTokens: true,
  minMatchCharLength: 1,
  keys: [''],
};
