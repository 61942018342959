import {
  AccountBalanceOutlined,
  CurrencyExchange,
  Groups3Outlined,
  HomeOutlined,
  Settings,
} from '@mui/icons-material';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { hasRole } from 'business/user/helpers/roles';
import useUserData from 'business/user/provider/use-user-data';
import { Roles } from 'business/user/types/user';
import { PortfolioMenuIcon } from 'ui/icons/portfolio-menu';

export const useFundManagerMenuItems = () => {
  const { t } = useTranslation();
  const palette = usePalette();

  const { user } = useUserData();
  const displayPortfolio = useFeatureFlag(FeatureFlagEnum.DISPLAY_PORTFOLIO);

  if (user === undefined) {
    return [];
  }

  return [
    {
      logo: <HomeOutlined color="primary" />,
      path: FundManagerRoutes.Home,
      alt: 'dashboard',
      title: t('components.sideMenu.dashboard'),
    },
    ...(hasRole(user.roles, Roles.FUND_MANAGER_BACK_OFFICE)
      ? [
          {
            logo: <Groups3Outlined color="primary" />,
            path: FundManagerRoutes.Lp,
            alt: 'lps',
            title: t('components.sideMenu.lps'),
          },
          {
            logo: <AccountBalanceOutlined color="primary" />,
            path: FundManagerRoutes.Fund,
            alt: 'funds',
            title: t('components.sideMenu.funds'),
          },
          {
            logo: <CurrencyExchange color="primary" />,
            path: FundManagerRoutes.Operations,
            alt: 'operations',
            title: t('components.sideMenu.operations'),
            dataTestId: 'operations-button',
          },
        ]
      : []),
    ...(displayPortfolio && hasRole(user.roles, Roles.FUND_MANAGER_FRONT_OFFICE)
      ? [
          {
            logo: (
              <PortfolioMenuIcon
                primaryColor={palette.primary[800]}
                secondaryColor={palette.primary[100]}
              />
            ),
            path: FundManagerRoutes.Portfolio,
            alt: 'portfolio',
            title: t('components.sideMenu.portfolio'),
            dataTestId: 'portfolio-button',
          },
        ]
      : []),
    {
      logo: <Settings color="primary" />,
      path: FundManagerRoutes.Settings,
      alt: 'settings',
      title: t('components.sideMenu.settings'),
    },
  ];
};
