import { Stack } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { Select } from 'ui/select';
import { TextInput } from 'ui/text-input';

import { useNaceFormData } from './use-nace-form-data';

interface NaceCodeFormPartProps {
  name: string;
  value?: string;
  error?: FieldError;
  hideSector?: boolean;
  onChange: (codeNace: string | undefined) => void;
  required?: boolean;
}

export function NaceCodeFormPart({
  value,
  name,
  onChange,
  hideSector = false,
  error,
  required,
}: NaceCodeFormPartProps) {
  const { t } = useTranslation();
  const { onCodeChange, codeOptions, descriptionOptions, nace } =
    useNaceFormData({ onChange, value });

  const hasError = !!error;

  return (
    <Stack spacing={2} direction={'column'}>
      <Stack spacing={2} direction="row" justifyContent="stretch" useFlexGap>
        <Select
          key="naceCode"
          name={name}
          label={t('pages.fundManager.operation.codeNace.label')}
          value={value ?? ''}
          error={hasError}
          helperText={error?.message}
          fullWidth
          options={codeOptions}
          onChange={onCodeChange}
          required={required}
        />

        <Select
          key="description"
          name={`${name}Description`}
          label={t('pages.fundManager.operation.codeNace.description')}
          value={value ?? ''}
          onChange={onCodeChange}
          options={descriptionOptions}
          fullWidth
          error={hasError}
          helperText={error?.message}
          required={required}
        />
      </Stack>
      {!hideSector ? (
        <TextInput
          key="sector"
          name={`${name}Sector`}
          label={t('pages.fundManager.operation.codeNace.businessActivity')}
          value={nace?.sector ?? ''}
          type="text"
          fullWidth
          disabled
          required={required}
          error={hasError}
        />
      ) : null}
    </Stack>
  );
}
