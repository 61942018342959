import { Stack } from '@mui/material';
import { useState } from 'react';

import authService from 'business/user/services/auth-service';
import { LanguageSelector } from 'ui/language-selector';
import LogOffButton from 'ui/log-off';

import { MenuItems } from './components/menu-items';
import styles from './index.module.scss';

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={styles.container}
      onMouseOver={() => setCollapsed(true)}
      onMouseLeave={() => setCollapsed(false)}
    >
      <MenuItems />
      <Stack className={styles.menuBottomStack} spacing={2}>
        <LanguageSelector collapsed={collapsed} />
        <LogOffButton
          className={styles.horizontalMargin}
          onClick={authService.logout}
        />
      </Stack>
    </div>
  );
};

export default SideMenu;
