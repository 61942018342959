import { LpCreationStepEnum } from 'generated/graphql';
import { VALIDATION_ITEM_TYPES, ValidationItemType } from 'ui/validation-item';

import { getCommonKYCKYBBooleans } from './get-common-kyb-kyc-booleans';
import { getContactItems } from './get-contact-items';
import { getDeclineReason } from './get-decline-reason';
import { getDocumentCardItems } from './get-document-card-items';
import { getInvestorProfileValidationCard } from './get-investor-profile-card';
import { getLpContactsValidationCard } from './get-lp-contacts-validation-card';
import {
  CardProps,
  GetCardItemsFn,
  GetCardItemsParams,
  LpValidationLegalEntity,
} from './types';
import { getCommunicationTypesItem } from './utils';

const getGeneralInfosCardItems: GetCardItemsFn<
  LpValidationLegalEntity['lpIndividualGeneralInfos']
> = ({ data, t, language, declineReasons }) => {
  if (!data?.contactIndividualEntity) {
    return undefined;
  }

  const items: ValidationItemType[] = [
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.label',
      ),
      value: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.individual',
      ),
    },
    ...getContactItems({
      data: data.contactIndividualEntity,
      t,
      language,
      isLegalRepresentative: true,
    }),
    ...getCommunicationTypesItem({ data, t }),
  ];
  const status = data.contactIndividualEntity.onboardingStatus;
  const step = LpCreationStepEnum.IndividualGeneralInfos;

  return {
    title: t('pages.fundManager.lpCreation.form.generalInformation.title'),
    type: 'single',
    entity: {
      items,
    },
    declineReason: getDeclineReason({
      status,
      step,
      declineReasons,
    }),
    status,
    step,
    entityId: data.contactIndividualEntity.contactId,
  };
};

const getKYCCardItems: GetCardItemsFn<
  LpValidationLegalEntity['lpIndividualKYC']
> = ({ data, t, declineReasons }) => {
  if (!data) {
    return undefined;
  }

  const lpCategorization: ValidationItemType[] = [
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyc.label.taxPayerIdentificationNumber',
      ),
      value: data.taxPayerIdentificationNumber,
    },
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.investorType',
      ),
      value: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.investorType',
        { context: data.investorType },
      ),
    },
  ];

  if (data.investorGroup) {
    lpCategorization.push({
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.investorGroup',
      ),
      value: t(
        'pages.fundManager.lpValidation.legalEntityKyb.label.investorGroup',
        { context: data.investorGroup },
      ),
    });
  }

  const step = LpCreationStepEnum.IndividualKyc;
  const status = data.onBoardingStatus;

  return {
    title: t('pages.fundManager.lpValidation.legalEntityKyc.title'),
    type: 'single',
    entity: {
      items: [...lpCategorization, ...getCommonKYCKYBBooleans({ data, t })],
    },
    entityId: data.id,
    step,
    status,
    declineReason: getDeclineReason({
      step,
      status,
      declineReasons,
    }),
  };
};

export const getIndividualValidationCards = (
  {
    data,
    withLpInvestorProfileStep,
    ...rest
  }: GetCardItemsParams<LpValidationLegalEntity> & {
    withLpInvestorProfileStep: boolean;
  },
  onClickDownload: ({
    id,
    fileName,
  }: {
    id: string;
    fileName: string;
  }) => Promise<void>,
): CardProps[] =>
  [
    getGeneralInfosCardItems({
      ...rest,
      data: data.lpIndividualGeneralInfos,
    }),
    getKYCCardItems({
      ...rest,
      data: data.lpIndividualKYC,
    }),
    ...(withLpInvestorProfileStep
      ? [getInvestorProfileValidationCard({ ...rest, data })]
      : []),
    getLpContactsValidationCard({
      ...rest,
      data: data.lpContacts,
    }),
    getDocumentCardItems(
      {
        ...rest,
        data: { lpId: data.id, ...data.lpDocumentsValidationState },
      },
      onClickDownload,
    ),
  ].filter((v: CardProps | undefined): v is CardProps => !!v);
