import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import styles from './index.module.scss';

interface Props extends PropsWithChildren {
  text: string;
  onClick: () => void;
}
// TODO rename this component this isn't a tool type at all
export const TooltipWithTextAndIcon = ({ text, children, onClick }: Props) => {
  return (
    <Stack className={styles.stack} onClick={onClick}>
      <Tooltip title={text}>
        <IconButton color="primary">
          <Stack direction="row" spacing={0.5}>
            {children}
            <Typography className={styles.text}>{text}</Typography>
          </Stack>
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
