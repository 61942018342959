import { AccessTime, Check, Clear, InfoOutlined } from '@mui/icons-material';
import { Chip, Stack, Tooltip } from '@mui/material';
import { ComponentProps, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ThemePalette } from 'business/providers/theme/services/types';
import { OnBoardingStatusEnum } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { CollapseCard } from 'ui/collapse-card/collapse-card';
import { Typo } from 'ui/typo';
import { TypoColorEnum } from 'ui/typo/types';

const getStatusSx = ({
  palette,
  status,
}: {
  palette: ThemePalette;
  status: OnBoardingStatusEnum;
}) => {
  switch (status) {
    case OnBoardingStatusEnum.Declined:
      return {
        border: 1,
        borderColor: palette.dangerMain,
      };

    case OnBoardingStatusEnum.Validated:
      return {
        border: 1,
        borderColor: palette.primaryMain,
      };
  }
};

const OnboardingStatusVariant: Record<
  `${OnBoardingStatusEnum}`,
  Pick<ComponentProps<typeof Chip>, 'color' | 'variant' | 'icon'> & {
    typoColor?: TypoColorEnum;
  }
> = {
  pendingValidation: {
    color: 'secondary',
    variant: 'outlined',
    icon: <AccessTime />,
    typoColor: TypoColorEnum.secondary,
  },
  declined: {
    color: 'error',
    variant: 'outlined',
    icon: <Clear />,
    typoColor: TypoColorEnum.error,
  },
  validated: {
    color: 'primary',
    variant: 'outlined',
    icon: <Check />,
    typoColor: TypoColorEnum.primary,
  },
  draft: {},
};

interface StatusChipProps {
  value: OnBoardingStatusEnum;
  declineReason?: string;
}

const StatusChip = ({ value, declineReason }: StatusChipProps) => {
  const { t } = useTranslation();
  const { typoColor, ...variant } = OnboardingStatusVariant[value];
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Chip
        {...variant}
        size="small"
        label={t('components.onboardingStatus', {
          context: value,
        })}
      />

      {declineReason ? (
        <Typo color={typoColor}>
          <Tooltip title={declineReason}>
            <InfoOutlined fontSize="inherit" />
          </Tooltip>
        </Typo>
      ) : null}
    </Stack>
  );
};

interface ValidationCardContainerProps extends PropsWithChildren {
  title: string;
  status?: OnBoardingStatusEnum;
  onApprove?: () => void;
  onDecline?: () => void;
  approving?: boolean;
  declining?: boolean;
  declineReason?: string;
  expand?: boolean;
}

export const ValidationCardContainer = ({
  title,
  children,
  onApprove,
  onDecline,
  declining,
  declineReason,
  approving,
  status = OnBoardingStatusEnum.PendingValidation,
  expand: expandRaw = false,
}: ValidationCardContainerProps) => {
  const palette = usePalette();
  const [expand, setExpand] = useState<boolean>(expandRaw);
  return (
    <CollapseCard
      sx={getStatusSx({ status, palette })}
      title={
        <Stack direction="row">
          <Stack flexGrow={1} direction="row" spacing={2}>
            <Typo bold>{title}</Typo>
            <StatusChip value={status} declineReason={declineReason} />
          </Stack>
          {expand ? (
            <Stack flexShrink={0} direction="row" spacing={2}>
              {onApprove ? (
                <ActionButton
                  variant="primary"
                  size="small"
                  onClick={onApprove}
                  disabled={declining}
                  loading={approving}
                  widthAuto
                >
                  <Check fontSize="inherit" />
                </ActionButton>
              ) : null}
              {onDecline ? (
                <ActionButton
                  variant="danger"
                  size="small"
                  onClick={onDecline}
                  disabled={approving}
                  loading={declining}
                  widthAuto
                >
                  <Clear fontSize="inherit" />
                </ActionButton>
              ) : null}
            </Stack>
          ) : null}
        </Stack>
      }
      expand={expand}
      onToggle={() => setExpand(!expand)}
    >
      {expand ? (
        <Stack direction="row" overflow="auto" paddingX={2} paddingY={1}>
          {children}
        </Stack>
      ) : null}
    </CollapseCard>
  );
};
