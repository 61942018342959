import * as yup from 'yup';

import { nonEmptyStringSchema } from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

export const lpDocumentSchema = yup.object().shape({
  type: nonEmptyStringSchema,
  emissionDate: yup.date(),
  expirationDate: yup.date(),
  fileName: nonEmptyStringSchema,
  filePath: nonEmptyStringSchema,
});

export const lpDocumentTypeSchema = yup.object().shape({
  name: nonEmptyStringSchema,
  isMandatoryIndividual: yup.boolean().required(),
  isMandatoryLegalEntity: yup.boolean().required(),
  hasExpirationDate: yup.boolean().required(),
});

export const uploadDocumentTemplateSchema = yup.object({
  filePath: yup
    .string()
    .matches(/\.docx$/, ValidationErrors.WRONG_EXTENSION)
    .required(),
});
