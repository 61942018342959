import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { useAppContext } from 'business/providers/app-provider';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import { lpProfileSchema } from 'business/shared/lp-creation-module/services/validation';
import { ClientTypeEnum } from 'generated/graphql';
import { Web_Portal_ManagementCompanyType_Enum } from 'generated/graphql';
import { handleError } from 'technical/error-reporting/error-utils';

import type { useFormContext } from 'react-hook-form';

type Errors = ReturnType<
  typeof useFormContext<LpProfile>
>['formState']['errors'];

type UseProfileFormProps = {
  onSubmit: (args: LpProfile) => Promise<unknown>;
  defaultValues?: LpProfile;
};

export const useProfileForm = ({
  onSubmit,
  defaultValues,
}: UseProfileFormProps) => {
  const { user } = useAppContext();
  const { t, i18n } = useTranslation();

  const isGeneralPartnerOrAdvisor = useManagementCompanyTypeGuard([
    Web_Portal_ManagementCompanyType_Enum.Advisor,
    Web_Portal_ManagementCompanyType_Enum.GeneralPartner,
  ]);

  const methods = useForm<LpProfile>({
    resolver: yupResolver<LpProfile>(lpProfileSchema),
    defaultValues: defaultValues ?? {
      client: ClientTypeEnum.LegalEntity,
      managementCompanyId: isGeneralPartnerOrAdvisor
        ? user?.managementCompany?.id
        : '',
      country: '',
      subscribingEntityName: '',
      address: {
        streetLine: '',
        zipCode: '',
        city: '',
      },
    },
  });

  const { handleSubmit } = methods;

  // catch to avoid react-hook-form error
  const onFormSubmit = (value: LpProfile) => onSubmit(value).catch(handleError);

  return {
    onSubmit: handleSubmit(onFormSubmit, (errors: Errors) => {
      handleError(errors);
      const [fieldName, firstError] = getFirstFormError(errors) ?? [];
      if (firstError) {
        const translationKey = `pages.fundManager.lpCreation.form.${fieldName}.label`;
        const keyExists = i18n.exists(translationKey);
        enqueueSnackbar(
          t('pages.fundManager.lpCreation.form.errorSnackbar', {
            fieldName: keyExists ? t(translationKey) : fieldName,
            error: t(firstError),
          }),
          {
            variant: VariantTypeEnum.ERROR,
          },
        );
      }
    }),
    methods,
  };
};

function getFirstFormError(errors: Errors): [string, string] | undefined {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const value = errors[key as keyof Errors];
      if (value?.message) {
        return [key, value.message];
      } else if (value) {
        const nestedError = getFirstFormError(value as Errors);
        if (nestedError) {
          return nestedError;
        }
      }
    }
  }
  return undefined;
}
