import { SvgIcon } from '@mui/material';

import { SvgProps } from 'ui/icons/types/props';

export const WarningIcon = ({ primaryColor }: SvgProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M19.8518 17.3771L12.9468 5.54121C12.5259 4.81973 11.4742 4.81946 11.0532 5.54121L4.14819 17.3771C3.72741 18.0983 4.25266 19 5.095 19H18.9049C19.7464 19 20.2729 18.099 19.8518 17.3771ZM18.9049 18.0726H5.095C4.97488 18.0726 4.89916 17.9446 4.95975 17.8407L11.8647 6.00492C11.9248 5.90194 12.0747 5.90107 12.1353 6.00492L19.0402 17.8408C19.1004 17.944 19.0259 18.0726 18.9049 18.0726Z"
        fill={primaryColor}
        stroke={primaryColor}
      />
      <path
        d="M12.4266 15.5565C12.5727 15.7929 12.4995 16.1031 12.2631 16.2493C12.0266 16.3954 11.7165 16.3222 11.5703 16.0858C11.4242 15.8493 11.4974 15.5391 11.7338 15.393C11.9703 15.2468 12.2804 15.32 12.4266 15.5565Z"
        fill={primaryColor}
        stroke={primaryColor}
        strokeWidth="1.00044"
      />
      <rect x="11" y="9" width="2" height="5" rx="1" fill={primaryColor} />
    </SvgIcon>
  );
};
