import classnames from 'classnames';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

export interface LinkProps {
  to: string;
  children: React.ReactNode;
  prefix?: string;
  align?: 'center' | 'left' | 'right';
  className?: string;
  preserveParentTextColor?: boolean;
}

export default function Link({
  to,
  children,
  prefix,
  className,
  align,
  preserveParentTextColor,
}: LinkProps) {
  const palette = usePalette();

  // This component could be reworked to use Mui Link component
  // https://mui.com/material-ui/guides/routing/#link
  const LinkComponent = () => (
    <ReactRouterLink
      className={classnames(styles.link, {
        ...(className ? { [className]: !prefix } : {}),
      })}
      style={{
        textAlign: align,
        ...(!preserveParentTextColor && { color: palette.secondary[800] }),
      }}
      to={to}
    >
      {children}
    </ReactRouterLink>
  );

  if (prefix) {
    return (
      <p className={classnames(className, styles.prefixedLink)}>
        {prefix}
        {/* Space between the prefix and the link */}
        {` `}
        <LinkComponent />
      </p>
    );
  }

  return <LinkComponent />;
}
