import { GetCommitmentsQuery } from 'generated/graphql';

export enum Distribution {
  NON_RECALLABLE = 'Recal. distribution',
  RECALLABLE = 'Distribution',
}

export interface Commitment {
  type: string;
  distributedAmount: number;
}

export interface AggregatedValuesType {
  fund: string;
  amount: number;
}

export interface CommitmentFilteredFields {
  fundNames: string[];
  shareTypes: string[];
}

export const isGetCommitmentsQuery = (
  query: any,
): query is GetCommitmentsQuery => {
  return query.dataviz_commitment.every(
    (commitment: any) =>
      typeof commitment === 'object' &&
      commitment.__typename === 'dataviz_commitment' &&
      typeof commitment.id === 'string' &&
      typeof commitment.numericAmount === 'number' &&
      typeof commitment.numericTotalDrawn === 'number' &&
      typeof commitment.numericUnfundedCommitment === 'number' &&
      typeof commitment.numericTotalDistributed === 'number' &&
      typeof commitment.numericTotalDistributedRecallable === 'number' &&
      typeof commitment.share === 'object' &&
      commitment.share.__typename === 'dataviz_share' &&
      typeof commitment.share.type === 'string' &&
      typeof commitment.share.fund === 'object' &&
      commitment.share.fund.__typename === 'dataviz_fund' &&
      typeof commitment.share.fund.name === 'string',
  );
};
