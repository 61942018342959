import {
  Stepper as MUIStepper,
  Step,
  Stack,
  StepLabel,
  StepButton,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'translations/hook';

import { Button } from 'ui/button';
import Spacer from 'ui/spacer';

type FormWizzardStep = {
  label: string;
  declineComment?: string;
  onClick?: () => void;
};

interface StepperProps {
  steps: FormWizzardStep[];
  activeStep: number;
}

const Stepper = ({ activeStep, steps }: StepperProps) => {
  return (
    <MUIStepper activeStep={activeStep}>
      {steps.map(({ label, declineComment, onClick }, index) => {
        return (
          <Step key={label}>
            <StepButton onClick={onClick}>
              <StepLabel error={!!declineComment && index === activeStep}>
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </MUIStepper>
  );
};
interface ActionButtonsProps {
  onNext?: () => void;
  onPrevious?: () => void;
  labelNext?: string;
  labelPrevious: string;
  isSubmitting?: boolean;
}
const ActionButtons = ({
  onNext,
  onPrevious,
  labelNext,
  labelPrevious,
  isSubmitting,
}: ActionButtonsProps) => {
  return (
    <Spacer justify="space-between">
      <Button color="inherit" onClick={onPrevious} disabled={!onPrevious}>
        {labelPrevious}
      </Button>
      <Button onClick={onNext} disabled={isSubmitting || !onNext}>
        {isSubmitting ? <CircularProgress size={15} /> : labelNext}
      </Button>
    </Spacer>
  );
};

interface FormWizzardProps extends StepperProps {
  onNext?: () => void;
  onPrevious?: () => void;
  labelAction?: string;
  isSubmitting?: boolean;
}

export const FormWizzard = ({
  steps,
  onNext,
  activeStep,
  onPrevious,
  labelAction,
  isSubmitting,
}: FormWizzardProps) => {
  const { t } = useTranslation();

  const labelNext = labelAction
    ? labelAction
    : activeStep < steps.length - 1
    ? t('common.actions.continue')
    : t('common.actions.save');

  const labelPrevious =
    activeStep === 0 ? t('common.actions.cancel') : t('common.actions.back');

  return (
    <Stack padding={1}>
      <Stepper activeStep={activeStep} steps={steps} />
      <ActionButtons
        labelNext={labelNext}
        labelPrevious={labelPrevious}
        onNext={onNext}
        onPrevious={onPrevious}
        isSubmitting={isSubmitting}
      />
    </Stack>
  );
};

FormWizzard.Stepper = Stepper;
FormWizzard.Actions = ActionButtons;
