import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { SolidColorKey } from 'business/providers/theme/services/types';
import { GetFundPerformanceValuesQuery } from 'generated/graphql';
import { isDefined } from 'technical/is-defined';
import PlotChart from 'technical/plotly-charts';
import { PlotChartTypeEnum, PlotLayout } from 'technical/plotly-charts/types';
import variables from 'ui/variables.module.scss';

interface Props {
  performanceData: GetFundPerformanceValuesQuery['fundPerformances'];
  fileName: string;
}

export const TVPIPerformanceGraph = ({ performanceData, fileName }: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const plotLayout: PlotLayout = {
    colorway: [palette.primary[900]],
    autosize: true,
    showlegend: true,
    title: {
      text: 'TVPI',
      automargin: true,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTitleSize),
        color: palette.text,
      },
      xref: 'paper',
      x: 0,
    },
    xaxis: {
      tickformat: '%x',
      dtick: 'M24',
    },
    yaxis: {
      tickformat: '.2f',
      ticksuffix: ' x',
    },
    legend: {
      orientation: 'h',
      x: 0,
      y: -0.15,
      yref: 'paper',
    },
    font: {
      size: parseFloat(variables.graphTextSize),
      color: palette.text,
      family: variables.graphFontFamily,
    },
    hoverlabel: {
      bgcolor: palette.backgroundForm,
      font: {
        family: variables.graphFontFamily,
        size: parseFloat(variables.graphTextSize),
        color: palette.text,
      },
      bordercolor: palette.backgroundForm,
    },
    margin: {
      t: parseFloat(variables.graphAxisPadding),
      b: parseFloat(variables.graphAxisPadding),
    },
  };

  function defineColorFromIndex(index: number) {
    if (index % 2 === 0) {
      return palette.primary[((10 - (index % 10)) * 100) as SolidColorKey];
    }
    return palette.secondary[((10 - (index % 10)) * 100) as SolidColorKey];
  }

  const dataChart =
    performanceData
      .filter((performance) => isDefined(performance))
      .map((performance, index) => {
        return {
          name: performance?.shareName,
          x: performance?.valuationDateArray,
          y: performance?.valuationTvpiArray,
          hoveron: 'points',
          hovertemplate: `<b>%{x|%Y-%m-%d}</b> <br>${t(
            'pages.fundShareValuations.tvpi',
          )}:  <b>%{y}</b>`,
          hoverinfo: 'all',
          line: {
            color: defineColorFromIndex(index),
          },
          type: PlotChartTypeEnum.scatter,
          mode: 'lines', // change to 'line+markers' to see points
          marker: {
            color: defineColorFromIndex(index),
          },
        };
      }) ?? [];

  return (
    <PlotChart dataArray={dataChart} layout={plotLayout} fileName={fileName} />
  );
};
