import { Tooltip } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useIsLpOnboardingUser } from 'business/fund-manager/shared/services/hooks/use-is-lp-onboarding-user';
import { ManagementCompanyDropdown } from 'business/shared/components/management-company-dropdown';
import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import { ClientTypeEnum } from 'generated/graphql';
import { FormSection, FormToggleButton } from 'ui/form';
import { LpContactFormPart } from 'ui/lp-contact-form-part';
import { LpLegalEntityFormPart } from 'ui/lp-legal-entity-form-part';

type LpGeneralInformationFormProps = {
  control: Control<LpProfile>;
  saving: boolean;
  action?: ReactElement | ReactNode;
  isUpdate?: boolean;
};

export const LpGeneralInformationForm = ({
  control,
  saving,
  action,
  isUpdate,
}: LpGeneralInformationFormProps) => {
  const { t } = useTranslation();

  const isOnboardingUser = useIsLpOnboardingUser();

  const { field: client } = useController({
    control,
    name: 'client',
  });

  const handleClientTypeChange = (value: string | null) => {
    client.onChange(value);
  };

  const clientTypeToggleOptions = [
    {
      label: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.legalEntity',
      ),
      value: ClientTypeEnum.LegalEntity,
      action: () => handleClientTypeChange(ClientTypeEnum.LegalEntity),
    },
    {
      label: t(
        'pages.fundManager.lpCreation.form.generalInformation.client.individual',
      ),
      value: ClientTypeEnum.Individual,
      action: () => handleClientTypeChange(ClientTypeEnum.Individual),
    },
  ];

  return (
    <FormSection
      title={t('pages.fundManager.lpCreation.form.generalInformation.title')}
      action={action}
      lastOne
    >
      {!isOnboardingUser && (
        <>
          <ManagementCompanyDropdown control={control} saving={saving} />

          <Tooltip
            title={
              isOnboardingUser
                ? t(
                    'pages.fundManager.lpCreation.form.generalInformation.client.disabledToggleTooltip',
                  )
                : null
            }
            placement="top-start"
          >
            {/* to display a tooltip on a disabled button you need to wrap it in a div */}
            <div>
              <FormToggleButton
                name={client.name}
                disabled={saving || isUpdate || isOnboardingUser}
                label={t(
                  'pages.fundManager.lpCreation.form.generalInformation.client.label',
                )}
                options={clientTypeToggleOptions}
                required
              />
            </div>
          </Tooltip>
        </>
      )}

      {client.value === ClientTypeEnum.LegalEntity ? (
        <LpLegalEntityFormPart control={control} saving={saving} />
      ) : (
        <LpContactFormPart
          control={control}
          name="individualLegalRepresentative"
          isAddressRequired={true}
        />
      )}
    </FormSection>
  );
};
