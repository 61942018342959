import { WarningAmber } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import styles from './index.module.scss';

interface Props {
  text: {
    main: string;
    secondary?: string;
    mainColor?: string;
    secondaryColor?: string;
  };
  icon?: React.ReactElement;
  component?: React.ReactElement;
}
export const EmptyTableView = ({ text, component, icon }: Props) => {
  return (
    <Stack className={styles.stack} spacing={3}>
      <Stack className={styles.centered}>{icon ?? <WarningAmber />}</Stack>
      <Stack spacing={3}>
        <Stack className={styles.centered}>
          <Typography color={text.mainColor}>{text.main}</Typography>
          <Typography>{text.secondary}</Typography>
        </Stack>
        <Stack className={styles.centered}>{component}</Stack>
      </Stack>
    </Stack>
  );
};
