import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  Resolver,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import {
  CommitmentStatusEnum,
  OpFieldNames,
  OperationCreationStepEnum,
  SharesFormValues,
} from 'business/fund-manager/operation/services/types';
import {
  makeSharesSelectionSchema,
  shareValueNames,
} from 'business/fund-manager/operation/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { GetFundSharesByOperationIdQuery } from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';

import { OpSharesConfigTable } from './op-shares-config-table';

const getDefaultValues = (
  shares: GetFundSharesByOperationIdQuery,
): SharesFormValues => {
  return shares?.web_portal_share.reduce<SharesFormValues>((acc, share) => {
    return {
      ...acc,
      [share.id]: {
        shareId: share.id,
        commitmentStatus: CommitmentStatusEnum.All,
        [OpFieldNames.selected]: false,
        [OpFieldNames.drawn]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.managementFees]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.otherFees]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.returnOfCost]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.capitalGain]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.interest]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.dividend]: {
          [OpFieldNames.selected]: true,
        },
        [OpFieldNames.currentDistributed]: {
          [OpFieldNames.selected]: true,
        },
      },
    };
  }, {} as SharesFormValues);
};

interface Props {
  shares: GetFundSharesByOperationIdQuery;
  operationId: string;
  submitShares: (arg: any) => Promise<unknown>;
  loading?: boolean;
  error?: ApolloError;
}

export const ShareSelectionForm = ({
  shares,
  operationId,
  submitShares,
  loading,
  error,
}: Props) => {
  const navigate = useNavigate();
  // This dynamic yup schema is hard to infer properly, so we force cast to the expected type
  // To solve it, we should refactor the form model and use a list of shares instead of a map of shares. Yup can handle arrays well, and the array index can be passed down to components / put in the context, to replace the shareId when reading / writing values.
  const resolver = yupResolver(
    makeSharesSelectionSchema(shares),
  ) as unknown as Resolver<SharesFormValues>;
  const methods = useForm<SharesFormValues>({
    defaultValues: getDefaultValues(shares),
    resolver,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<SharesFormValues> = (data) => {
    const operationShares = shares.web_portal_share
      .filter((share) => {
        const values = data[share.id];
        return shareValueNames.some((valueName) => {
          return !!values[valueName]?.perShare;
        });
      })
      .map((share) => {
        const values = data[share.id];
        return {
          shareId: share.id,
          operationId,
          investment: values.drawn?.perShare,
          fees: values.fees?.perShare,
          other: values.other?.perShare,
          returnOfCost: values.returnOfCost?.perShare,
          capitalGain: values.capitalGain?.perShare,
          interest: values.interest?.perShare,
          dividend: values.dividend?.perShare,
          currentDistributed: values.currentDistributed?.perShare,
          commitmentStatus:
            values.commitmentStatus === CommitmentStatusEnum.All
              ? undefined
              : values.commitmentStatus,
        };
      });

    return submitShares({
      variables: {
        operationShares,
      },
    }).catch(() => undefined);
  };

  return (
    <OperationCreationContainer
      activeStep={OperationCreationStepEnum.Shares}
      onNext={isValid || loading ? handleSubmit(onSubmit) : undefined}
      onPrevious={() =>
        navigate(
          FundManagerRoutes.OperationCreateId.replace(
            ':operationId',
            operationId,
          ),
        )
      }
      isSubmitting={isSubmitting}
    >
      <FormProvider {...methods}>
        {error?.message ? <ErrorLabel label={error.message} /> : null}
        <OpSharesConfigTable shares={shares?.web_portal_share ?? []} />
        {/* Once the new table is validated and deployed in prod, delete the below code and the related components. */}
        {/* <Grid container spacing={2}>
          {shares?.web_portal_share.map((share) => (
            <ShareSelectionCard key={share.id} share={share} />
          ))}
        </Grid> */}
      </FormProvider>
    </OperationCreationContainer>
  );
};

// TODO tester avec des scénarios de souscriptions plus complexes et fournis
// TODO UI
