import { FormHelperText, InputLabel, Stack } from '@mui/material';
import { useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FormInputProps, FormInputsType } from 'ui/form/form-input-props';
import ToggleButton from 'ui/toggle-button';
import { ToggleButtonOption } from 'ui/toggle-button/type';

type Option = Omit<ToggleButtonOption, 'action'>;

type FormToggleButtonProps<T extends FormInputsType> = Omit<
  FormInputProps<T>,
  'options' | 'label'
> & {
  required?: boolean;
  options: Option[];
  fullWidth?: boolean;
  allowEmpty?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  label?: string;
};

export const FormToggleButton = <T extends FormInputsType>({
  name,
  control,
  label,
  rules = {},
  required,
  options,
  fullWidth,
  allowEmpty,
  onChange,
  disabled,
  ...rest
}: FormToggleButtonProps<T>) => {
  const { t } = useTranslation();
  const {
    field: { onChange: onFormChange, value },
    fieldState: { error },
  } = useController<{ value: string }>({
    // ok this is strange but this only goal of this is to have field.onChange function typed find
    // if you have a better solution feel free to purpose
    name: name as `value`,
    rules: { ...rules, required },
    control,
  });

  const hasError = !!error;

  return (
    <Stack flexGrow={fullWidth ? 1 : undefined} spacing={1}>
      {label ? (
        <InputLabel error={hasError} required={required}>
          {label}
        </InputLabel>
      ) : null}
      <ToggleButton
        {...rest}
        allowEmpty={allowEmpty}
        fullWidth={fullWidth}
        color={error ? 'error' : 'primary'}
        options={options.map((opt: Option) => ({
          ...opt,
          action: () =>
            onChange
              ? onChange(opt?.value ?? opt.label)
              : onFormChange(opt?.value ?? opt.label),
        }))}
        value={value}
        disabled={disabled}
      />
      {hasError ? (
        <FormHelperText error={hasError}>
          {t(error?.message ?? '')}
        </FormHelperText>
      ) : null}
    </Stack>
  );
};
