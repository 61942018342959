import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { LpAmlRiskForm } from 'business/fund-manager/lp/services/types';
import { amlFormSchema } from 'business/fund-manager/lp/services/validation';

type UseAmlFormProps = {
  onSubmit: (args: LpAmlRiskForm) => void;
  defaultValues?: LpAmlRiskForm;
};

export const useAmlForm = ({ onSubmit, defaultValues }: UseAmlFormProps) => {
  const methods = useForm<LpAmlRiskForm>({
    resolver: yupResolver<LpAmlRiskForm>(amlFormSchema),
    defaultValues: defaultValues ?? {
      interactionsWithInvestors: '',
      lpTransparency: '',
      structureTransparency: '',
      lpNature: '',
    },
  });
  const { handleSubmit } = methods;

  return {
    onSubmit: handleSubmit(onSubmit),
    methods,
  };
};
