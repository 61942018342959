import { OnBoardingStatusEnum } from 'generated/graphql';

import { LpValidationLegalEntity } from './types';

export const getLpContactsOnboardingStatus = (
  lpContacts: LpValidationLegalEntity['lpContacts'],
) => {
  const statuses = lpContacts.reduce((acc, contact) => {
    acc.add(contact.contactIndividualEntity.onboardingStatus);
    return acc;
  }, new Set());

  if (statuses.has(OnBoardingStatusEnum.Declined)) {
    return OnBoardingStatusEnum.Declined;
  }

  if (statuses.size !== 1) {
    return undefined;
  }

  if (statuses.has(OnBoardingStatusEnum.Validated)) {
    return OnBoardingStatusEnum.Validated;
  }

  return undefined;
};
