import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { OperationType } from 'business/lp-platform/financial-flows/types';
import { GetOperationsQuery } from 'generated/graphql';
import { CombinedArrowsIcon, LeftArrowIcon, RightArrowIcon } from 'ui/icons';

const getArrowIcon = (type: string) => {
  switch (type) {
    case OperationType.Drawdown:
      return <LeftArrowIcon color="secondary" />;
    case OperationType.Distribution:
      return <RightArrowIcon color="primary" />;
    case OperationType.RecallableDistribution:
      return <CombinedArrowsIcon color="action" />;
  }
};

const isIdentifierANumber = (identifier: string) => {
  return !isNaN(+identifier);
};

const renderOperationIdentifierCell = (
  t: TFunction,
  type: string,
  identifier: string,
) => {
  const idtf = isIdentifierANumber(identifier) ? `#${identifier}` : identifier;
  const icon = getArrowIcon(type);
  return (
    <Stack direction="row" spacing={1}>
      {icon}
      <Typography variant="body2">{`${t(
        'pages.financialFlows.table.operationIdentifier',
        { context: type },
      )} ${idtf}`}</Typography>
    </Stack>
  );
};
const getOperationIdentifierValue = (
  t: TFunction,
  type: string,
  identifier: string,
) => {
  const idtf = isIdentifierANumber(identifier) ? `#${identifier}` : identifier;
  return `${t('pages.financialFlows.table.operationIdentifier', {
    context: type,
  })} ${idtf}`;
};

export const operationIdentifierColumn = (
  t: TFunction,
  valueOptions: string[],
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'identifier',
    type: 'singleSelect',
    valueOptions,
    groupable: false,
    aggregable: false,
    headerName: t('pages.financialFlows.table.operationIdentifier'),
    renderCell: ({ row: { type, identifier } }) =>
      type && identifier
        ? renderOperationIdentifierCell(t, type, identifier)
        : '',
    valueGetter: ({ row: { type, identifier } }) =>
      type && identifier
        ? getOperationIdentifierValue(t, type, identifier)
        : '',
  };
};
