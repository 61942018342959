import {
  CurrencyEnum,
  FundFromDateEnum,
  GetFundInfosQuery,
} from 'generated/graphql';

import { getFundPeriods } from './get-fund-periods';

export interface FundProfileData {
  periods?: ReturnType<typeof getFundPeriods>;
  countPendingValidationShares?: number;
}

export const getFundProfileData = ({
  data,
}: {
  data?: GetFundInfosQuery['fundInfos'];
}): FundProfileData | undefined => {
  if (!data) {
    return undefined;
  }

  const fundData = data[0];

  const countPendingValidationShares =
    fundData.countPendingValidationSharesAgg.aggregate?.count;

  return {
    periods: getFundPeriods({
      ...fundData,
      // we use as here because this is same enum but one came from hasura and the other from custom-server
      investmentPeriodFromDateOf:
        fundData.investmentPeriodFromDateOf as unknown as FundFromDateEnum,
      // we use as here because this is same enum but one came from hasura and the other from custom-server
      currency: fundData.currency as unknown as CurrencyEnum,
      duration: fundData.duration ?? 0,
    }),
    countPendingValidationShares,
  };
};
