import { OnboardingSubscriptionStatusEnum } from 'generated/graphql';

export const ONBOARDING_SUBSCRIPTION_GP_STEPS_ORDERED = [
  [OnboardingSubscriptionStatusEnum.InvitationSent],
  [
    OnboardingSubscriptionStatusEnum.ProfileCompleted,
    OnboardingSubscriptionStatusEnum.SubInfoCompleted,
    OnboardingSubscriptionStatusEnum.ProfileDeclined,
  ],
  [
    OnboardingSubscriptionStatusEnum.ProfileValidated,
    OnboardingSubscriptionStatusEnum.SubInfoDeclined,
  ],
  [OnboardingSubscriptionStatusEnum.SubInfoValidated],
  [
    OnboardingSubscriptionStatusEnum.ReadyForSignature,
    OnboardingSubscriptionStatusEnum.Rejected,
  ],
  [OnboardingSubscriptionStatusEnum.WaitingForLpSignature],
  [OnboardingSubscriptionStatusEnum.WaitingForGpSignature],
];

export const ONBOARDING_SUBSCRIPTION_GP_STEPS_COUNT =
  ONBOARDING_SUBSCRIPTION_GP_STEPS_ORDERED.length;
