import { MutationOptions } from '@apollo/client';
import { i18n } from 'translations';

import {
  MAX_LP_DISPLAYED,
  getStoredLp,
} from 'business/lp-platform/nav-bar/services/lp-filters';
import { LPFilter } from 'business/lp-platform/nav-bar/services/types';
import { router } from 'business/router/services';
import SharedRoutes from 'business/shared/router/routes';
import fetchUser from 'business/user/services/user';
import { UserData } from 'business/user/types/user';
import {
  AskForEmailVerificationDocument,
  AskForPasswordResetDocument,
  CreatePasswordDocument,
  LogoutUserDocument,
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  ResetPasswordDocument,
  SignInDocument,
  SignInMutation,
  ValidateEmailDocument,
  ValidateMfaDocument,
  ValidateMfaMutation,
  ValidateMfaSetupDocument,
  ValidateMfaSetupMutation,
} from 'generated/graphql';
import { setUserId } from 'technical/analytics';
import { toPgArray } from 'technical/array/to-pg-array';
import {
  LockedEmailError,
  WrongCredentialsError,
} from 'technical/auth/providers';
import errorReporting from 'technical/error-reporting';
import client from 'technical/graphql/client';
import { removeItem } from 'technical/local-storage';
import logger from 'technical/logger';
import { ValidationErrors } from 'technical/validation/types';

/**
 * @todo - Actually i18n.t send a string | null response
 * Current work have been provided for the useTranslation hook
 * We need to check if we want to pursue in this direction or not
 * by providing an i18n object from technical/translation
 * */

let authResult: UserData | null = null;
let accessToken: string | null = null;

export const goToLogin = () => {
  router.navigate(SharedRoutes.SignIn);
};

export function isAuthenticated() {
  return !!authResult;
}

export function getAccessToken() {
  return accessToken;
}

export function getPreAuthToken() {
  return localStorage.getItem('preAuthToken');
}

export function persistPreAuth(token: string) {
  localStorage.setItem('preAuthToken', token);
}

export function unpersistPreAuth() {
  removeItem('preAuthToken');
}

export async function persistAuth(token: string) {
  accessToken = token;
  const user = await fetchUser(client);
  unpersistPreAuth();
  if (user) {
    const storedLpFilters = localStorage.getItem('lpFilters');
    if (!storedLpFilters || storedLpFilters === '[]') {
      // Limits the number of LP select to avoid
      // long loading times when the user is connecting
      localStorage.setItem(
        'lpFilters',
        JSON.stringify(user.lps.slice(0, MAX_LP_DISPLAYED)),
      );
    }
    authResult = user;
    setUserId(user.userId);
  }
}

export function cleanBrowserEnv() {
  authResult = null;
  accessToken = null;
  removeItem('lpFilters');
  setUserId(undefined);
  errorReporting.removeUser();
}

export async function unpersistAuth(): Promise<void> {
  cleanBrowserEnv();
  await client.mutate({
    mutation: LogoutUserDocument,
  });
}

// Use a promise cache system to avoid multi call asking token refresh at the same time
let cachedRenewToken: Promise<void> | null = null;

async function renewTokenRequest(filteredLpIds: string) {
  // Do not launch the next request with an access token, it may lead
  // to an infinite loop if the current access token is outdated
  accessToken = null;

  try {
    const mutateParams: MutationOptions<
      RefreshTokenMutation,
      RefreshTokenMutationVariables
    > = {
      mutation: RefreshTokenDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          filteredLpIds,
        },
      },
    };
    const { data, errors } = await client.mutate<
      RefreshTokenMutation,
      RefreshTokenMutationVariables
    >(mutateParams);

    if (errors) {
      throw new Error('Token renewal have failed');
    }

    if (data?.queryRefreshToken?.success && data.queryRefreshToken.idToken) {
      await persistAuth(data.queryRefreshToken.idToken);
    } else {
      throw new Error(
        data?.queryRefreshToken?.message || 'Token renewal have failed',
      );
    }
  } catch (err) {
    // avoid keep renewtoken into error state
    cachedRenewToken = null;
    throw err;
  }

  cachedRenewToken = null;
}

// The only public way to ask a renew of the access token
export async function renewToken() {
  if (cachedRenewToken) {
    return cachedRenewToken;
  }

  const localStorageLps: LPFilter[] = getStoredLp();
  cachedRenewToken = renewTokenRequest(
    toPgArray(localStorageLps.map((lp) => lp.id)),
  );

  return cachedRenewToken;
}

export const initAuthentication = async () => {
  try {
    await renewToken();
  } catch (error) {
    if (
      error instanceof Error &&
      error.message !== 'login-required' &&
      error.message !== 'email-not-verified'
    ) {
      logger.error(error);
    }
  }
};

export const signIn = async (email: string, password: string) => {
  // to clear all cached information before login
  cleanBrowserEnv();
  const { data } = await client.mutate<SignInMutation>({
    mutation: SignInDocument,
    variables: {
      input: {
        email,
        password,
      },
    },
  });

  //Check for error
  if (!data || !data.signIn) {
    throw new Error(data?.signIn?.message ?? undefined);
  }

  if (data.signIn.message && data.signIn.lockedUntil) {
    throw new LockedEmailError(data.signIn.message, data.signIn.lockedUntil);
  }
  if (data.signIn.message && data.signIn.remainingTrials) {
    throw new WrongCredentialsError(
      data.signIn.message,
      data.signIn.remainingTrials,
    );
  }

  if (!data.signIn.idToken) {
    throw new Error(data.signIn.message ?? undefined);
  }

  //Check for 2FA requirement
  if (data.signIn.mfa_required) {
    //Store current token and redirect to 2FA page
    persistPreAuth(data.signIn.idToken);
    if (data.signIn.totp_initialisation_required) {
      router.navigate(SharedRoutes.SetupMFA);
    } else {
      router.navigate(SharedRoutes.ValidateMFA);
    }
  }
  // 1. Store Token
  await persistAuth(data.signIn.idToken);
  // 2. Go To LoginCallBack so the app can "requestReboostrap" and handle the user as logged-in
  router.navigate(SharedRoutes.LoginCallback);
};

export const setupTotp = async (passcode: string) => {
  //Use preAuth token only for this call
  accessToken = getPreAuthToken();
  const { data } = await client.mutate<ValidateMfaSetupMutation>({
    mutation: ValidateMfaSetupDocument,
    variables: {
      input: {
        passcode,
      },
    },
  });
  accessToken = null;
  //Check for error
  if (
    !data ||
    !data?.validateMfaSetup?.success ||
    !data?.validateMfaSetup?.idToken
  ) {
    throw new Error(data?.validateMfaSetup?.message ?? undefined);
  }

  // 1. Store Token
  await persistAuth(data.validateMfaSetup.idToken);
  // 2. Go To LoginCallBack so the app can "requestReboostrap" and handle the user as logged-in
  router.navigate(SharedRoutes.LoginCallback);
};

export const validateMfa = async (code: string) => {
  //Use preAuth token only for this call
  accessToken = getPreAuthToken();
  const { data } = await client.mutate<ValidateMfaMutation>({
    mutation: ValidateMfaDocument,
    variables: {
      input: {
        code,
      },
    },
  });
  accessToken = null;
  //Check for error
  if (
    !data ||
    !data.validateMfa ||
    !data.validateMfa.success ||
    !data.validateMfa.idToken
  ) {
    throw new Error(data?.validateMfa?.message ?? undefined);
  }

  // 1. Store Token
  await persistAuth(data.validateMfa.idToken);
  // 2. Go To LoginCallBack so the app can "requestReboostrap" and handle the user as logged-in
  router.navigate(SharedRoutes.LoginCallback);
};

export const refreshEmailValidation = async (email: string) => {
  await client.mutate({
    mutation: AskForEmailVerificationDocument,
    variables: {
      input: {
        email,
      },
    },
  });
};

export const requestLoginCallback = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    if (authResult) {
      return resolve();
    }

    return reject(new Error('An error occured during authentication'));
  });

export const requestEmailVerificationCallback = async (token: string) => {
  // Add the verification of the token
  if (!token) {
    throw new Error(i18n.t('errors.signIn') || '');
  } else {
    try {
      const { data } = await client.mutate({
        mutation: ValidateEmailDocument,
        variables: {
          input: {
            token,
          },
        },
      });
      const response = data?.validateEmail;

      if (response?.success && response?.idToken) {
        await persistAuth(data.validateEmail.idToken);
        router.navigate(SharedRoutes.LoginCallback);
        return;
      }
      // TODO implement email verification
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(
          i18n.t('errors.signIn', {
            context: error.message,
          }) || '',
        );
      }
    }
  }
};

export const getAuthResult = () => authResult;

export const logout = async () => {
  await unpersistAuth();
  // Instead of using History, we use window.location so
  // The app is re-bootstrap and informations concerning our user
  // are up to date: ie he's not here anymore
  window.location.href = SharedRoutes.SignIn;
};

export const forgotPassword = async (email: string) => {
  const { data, errors } = await client.mutate({
    mutation: AskForPasswordResetDocument,
    variables: {
      input: {
        email,
      },
    },
  });
  if (errors || !data?.askForPasswordReset?.success) {
    throw new Error(i18n.t(ValidationErrors.GENERIC) || '');
  }
};

// Token can be null because if the user is logged in he does not require
// a token to reset his password
export const resetPassword = async (
  token: string | null,
  password: string,
  passwordConfirmation: string,
): Promise<void> => {
  const { data, errors } = await client.mutate({
    mutation: ResetPasswordDocument,
    variables: {
      input: {
        token,
        password,
        passwordConfirmation,
      },
    },
  });

  if (errors) {
    throw new Error(i18n.t(ValidationErrors.GENERIC) || '');
  }
  if (!data.resetPassword.success) {
    throw new Error(
      i18n.t('errors.resetPassword', { context: data.resetPassword.message }) ||
        '',
    );
  }

  if (isAuthenticated()) {
    unpersistAuth();
    window.location.href = SharedRoutes.SignIn;
  }
};

export const createPassword = async (
  token: string | null,
  password: string,
  passwordConfirmation: string,
): Promise<void> => {
  const { data, errors } = await client.mutate({
    mutation: CreatePasswordDocument,
    variables: {
      input: {
        token,
        password,
        passwordConfirmation,
      },
    },
  });

  if (errors) {
    throw new Error(i18n.t(ValidationErrors.GENERIC) || '');
  }
  if (!data.createPassword.success) {
    throw new Error(
      i18n.t('errors.resetPassword', {
        context: data.createPassword.message,
      }) || '',
    );
  }
};
