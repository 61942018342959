import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import { LpDocumentTypeForm } from 'business/shared/services/document/types';
import { lpDocumentTypeSchema } from 'business/shared/services/document/validation';
import {
  LpDocumentTypesQuery,
  useUpsertLpDocumentTypeMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { CustomModal } from 'ui/custom-modal';
import { FormInputCheckBox, FormInputText } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  managementCompanyId: string;
  lpDocumentType: LpDocumentTypesQuery['lpDocumentTypes'][number] | null;
}

export const LpDocumentTypeModal = ({
  lpDocumentType,
  managementCompanyId,
  onClose,
  open,
}: Props) => {
  const { t } = useTranslation();
  const context = lpDocumentType ? 'update' : 'create';
  const methods = useForm<LpDocumentTypeForm>({
    resolver: yupResolver<LpDocumentTypeForm>(lpDocumentTypeSchema),
    defaultValues: lpDocumentType
      ? {
          name: lpDocumentType.name,
          isMandatoryIndividual: lpDocumentType.isMandatoryIndividual,
          isMandatoryLegalEntity: lpDocumentType.isMandatoryLegalEntity,
          hasExpirationDate: lpDocumentType.hasExpirationDate,
        }
      : {
          isMandatoryIndividual: false,
          isMandatoryLegalEntity: false,
          hasExpirationDate: false,
        },
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const [
    upsertLpDocumentType,
    { loading: upsertLpDocumentTypeLoading, error: upsertLpDocumentTypeError },
  ] = useUpsertLpDocumentTypeMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(
        t(
          context === 'create'
            ? 'successMessage.createDocumentType'
            : 'successMessage.updateDocumentType',
        ),
        {
          variant: VariantTypeEnum.SUCCESS,
        },
      );
      handleClose();
    },
  });

  const onSubmit = (data: LpDocumentTypeForm) =>
    upsertLpDocumentType({
      variables: {
        input: {
          ...data,
          ...(lpDocumentType?.id ? { id: lpDocumentType.id } : {}),
          managementCompanyId,
        },
      },
    }).catch(() => undefined);

  if (upsertLpDocumentTypeLoading || upsertLpDocumentTypeError) {
    return (
      <QueryStateDisplay
        loading={upsertLpDocumentTypeLoading}
        error={upsertLpDocumentTypeError}
      />
    );
  }

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      component={
        <FormProvider {...methods}>
          <FormModalContainer
            title={t(
              `pages.fundManager.settings.documentType.form.title.${context}`,
            )}
            onClose={onClose}
            onSubmit={methods.handleSubmit(onSubmit)}
            isSubmitting={methods.formState.isSubmitting}
            labelAction={t('common.actions.save')}
            disableSubmit={!methods.formState.isValid}
          >
            <Stack spacing={4}>
              <FormInputText
                name="name"
                label={t(
                  'pages.fundManager.settings.documentType.headers.name',
                )}
                required
                disabled={context === 'update'}
              />
              <FormInputCheckBox
                label={t(
                  'pages.fundManager.settings.lpDocumentType.headers.isMandatoryIndividual',
                )}
                name="isMandatoryIndividual"
                required
              />
              <FormInputCheckBox
                label={t(
                  'pages.fundManager.settings.lpDocumentType.headers.isMandatoryLegalEntity',
                )}
                name="isMandatoryLegalEntity"
                required
              />
              <FormInputCheckBox
                label={t(
                  'pages.fundManager.settings.lpDocumentType.headers.hasExpirationDate',
                )}
                name="hasExpirationDate"
                required
              />
            </Stack>
          </FormModalContainer>
        </FormProvider>
      }
    />
  );
};
