import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFuture, isToday, toDate } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import {
  OperationCreationInput,
  OperationCreationStepEnum,
} from 'business/fund-manager/operation/services/types';
import { operationCreationInputSchema } from 'business/fund-manager/operation/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  UpdateOperationInput,
  useGetActiveFundsWithSharesQuery,
} from 'generated/graphql';
import { ErrorLabel } from 'ui/error-label';
import {
  FormInputCheckBox,
  FormInputDate,
  FormInputText,
  FormSection,
} from 'ui/form';
import { SelectInput } from 'ui/form/select-input';
import { QueryStateDisplay } from 'ui/query-state-display';

export const CreateOperationForm = ({
  onSubmit,
  loading,
  error,
  defaultValues,
}: {
  onSubmit: (data: any) => void;
  loading: boolean;
  error: ApolloError | undefined;
  defaultValues?: UpdateOperationInput;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: fundsData,
    loading: fundsLoading,
    error: fundsError,
  } = useGetActiveFundsWithSharesQuery();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<OperationCreationInput>({
    defaultValues: defaultValues ?? {
      fundId: '',
      identifier: '',
      date: new Date(),
      shouldEmailLetters: true,
    },
    resolver: yupResolver(operationCreationInputSchema),
  });

  if (!fundsData || fundsLoading || fundsError) {
    return <QueryStateDisplay error={fundsError} loading={fundsLoading} />;
  }

  const fundOptions = fundsData.funds.map(({ id, name }) => ({
    id,
    value: name,
  }));

  return (
    <OperationCreationContainer
      activeStep={OperationCreationStepEnum.Details}
      onNext={!loading && isValid ? handleSubmit(onSubmit) : undefined}
      onPrevious={() => navigate(FundManagerRoutes.Operations)}
    >
      {error ? <ErrorLabel label={error.message} /> : null}
      <FormSection
        title={t('pages.fundManager.operationCreation.step.setup')}
        lastOne
        horizontal
      >
        <SelectInput
          dataTestId="select-fund-operation"
          control={control}
          options={fundOptions}
          disableClearable
          label={t('pages.fundManager.operationCreation.fundInput')}
          name="fundId"
          required
          sx={{ minWidth: 300 }}
        />
        <FormInputText<OperationCreationInput>
          name="identifier"
          control={control}
          label={t('pages.fundManager.operationCreation.operationNumberInput')}
          required
          sx={{ minWidth: 300 }}
        />
        <FormInputDate<OperationCreationInput>
          name="date"
          control={control}
          label={t('pages.fundManager.operationCreation.dueDateInput')}
          rules={{
            validate: (value: Date) =>
              isToday(toDate(value)) || isFuture(toDate(value)),
          }}
          required
          sx={{ minWidth: 300 }}
        />
      </FormSection>
      <FormInputCheckBox<OperationCreationInput>
        name="shouldEmailLetters"
        control={control}
        label={t('pages.fundManager.operationCreation.shouldEmailLetters')}
        required
        sx={{ minWidth: 300 }}
      />
    </OperationCreationContainer>
  );
};
