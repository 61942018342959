import { Box } from '@mui/material';
import { ReactElement } from 'react';

import { AllRoutes } from 'business/shared/router/routes';
import MenuLink from 'ui/menu/menu-link';

import styles from './index.module.scss';

interface Props {
  path: AllRoutes;
  logo: string | ReactElement;
  alt: string;
  dataTestId?: string;
  title?: string;
  active?: boolean;
}

const MenuItem = ({ path, logo, alt, dataTestId, title, active }: Props) => {
  const dimensions = { width: 24, height: 24 };
  return (
    <MenuLink path={path} dataTestId={dataTestId} title={title} active={active}>
      <Box
        sx={{ width: dimensions.width, height: dimensions.height }}
        className={styles.appLogo}
        aria-label={alt}
      >
        {logo}
      </Box>
    </MenuLink>
  );
};

export default MenuItem;
