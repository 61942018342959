import { ThemePaletteConfig } from 'business/providers/theme/services/types';

// our default Palette is the most complex example of what a client can give as a color config.
export const defaultPalette: ThemePaletteConfig = {
  backgroundAuth:
    'linear-gradient(292.24deg, #24695C -29.97%, #17433B 79.34%, #17433B 125.07%)', // leave hard coded for now
  backgroundGeneral: 'linear-gradient(180deg, #F9F7F6 0%, #F9F5F2 100%)', // leave hard coded for now
  pe3: {
    // theme main colors (mandatory)
    primary: 'green',
    secondary: 'ochre',
    tertiary: 'terracotta',

    // primary (mandatory)
    primaryMain: 'primary.800',
    primaryDark: 'primary.900',
    primaryLight: 'primary.400',
    primaryLighter: 'primary.100',

    // secondary (mandatory)
    secondaryMain: 'secondary.800',
    secondaryDark: 'secondary.900',
    secondaryLight: 'secondary.500',
    secondaryLighter: 'secondary.100',

    // tertiary (mandatory)
    tertiaryMain: 'tertiary.300',
    tertiaryDark: 'tertiary.400',
    tertiaryLight: 'tertiary.200',
    tertiaryLighter: 'tertiary.100',

    // danger
    dangerDark: 'red.900',
    dangerMain: 'red.800',
    dangerLight: 'red.300',
    dangerLighter: 'red.100',

    backgroundCookieConsent: 'brown.400',
    backgroundCard: 'taupe.100',
    backgroundForm: 'white.100',
    text: 'black.900',
    textLight: 'black.100',
    error: 'red.500',
    tableCellDivider: 'brown.200',
    secondaryIconColor: 'ochre.900',
    secondaryBackgroundIconColor: 'ochre.200',
    neutralIconColor: 'grey.600',
    neutralBackGroundIconColor: 'grey.200',
    neutralBorderColor: 'grey.400',

    onboardingDetailCardBg: 'ochre.100',

    infoRevealCardBgColor: 'brown.200',

    // bd-validation card
    bgColorValidationCardPendingValidation: 'white.100',
    bgColorPrimaryActionButton: 'primary.200',

    // status text
    statusTextPendingValidation: 'primary.400',
    statusTextDraft: 'secondary.400',
    statusTextDone: 'secondary.800',
    statusTextInProgress: 'primary.600',

    // emailStatus
    emailStatusSent: 'primary.800',
    emailStatusPending: 'secondary.800',
    emailStatusNoEmail: 'secondary.600',
    emailStatusError: 'red.700',

    // document status
    documentStatusValidated: 'primary.800',
    documentStatusPendingValidation: 'secondary.800',
    documentStatusExpired: 'orange.500', // TODO: add a required color danger (carefull to update production custom themes too)
    documentStatusRequested: 'blue.500', // TODO: add a required color ? (carefull to update production custom themes too)
  },
  solidColors: {
    white: {
      100: '#ffffff',
      200: '#f2f2f2',
      300: '#f5f5f5',
      400: '#e5e5e5',
      500: '#d4d4d4',
      600: '#a3a3a3',
      700: '#737373',
      800: '#525252',
      900: '#404040',
    },
    black: {
      100: '#646464',
      200: '#575757',
      300: '#4a4a4a',
      400: '#3e3e3e',
      500: '#323232',
      600: '#252525',
      700: '#181818',
      800: '#191919',
      900: '#000000',
    },
    grey: {
      100: '#f4f4f4',
      200: '#E8E8E8',
      300: '#BDBDBD',
      400: '#C0BCBC',
      500: '#969393',
      600: '#848484',
      700: '#6D6D6D',
      800: '#454545',
      900: '#363636',
    },
    green: {
      100: '#EBF8F5', // lighter
      200: '#D2E9E5',
      300: '#B1D9D1',
      400: '#8FC8BD', // light
      500: '#63B3A3',
      600: '#369D89',
      700: '#24927C',
      800: '#1C8273', // main
      900: '#186254', // dark
    },
    brown: {
      100: '#F8F7F5',
      200: '#ede9e4',
      300: '#D4C0B0',
      400: '#936139',
      500: '#7F6C58',
      600: '#645646',
      700: '#4B4136',
      800: '#332D26',
      900: '#1D1A17',
    },
    ochre: {
      100: '#F9F5F0', // lighter
      200: '#F4ECE4',
      300: '#E5D9CE',
      400: '#E0C7AF',
      500: '#D3B08F', // light
      600: '#D0AA88',
      700: '#C79970',
      800: '#C18E60', // main
      900: '#9A724D', // dark
    },
    terracotta: {
      100: '#F4EAE9', // lighter
      200: '#EAD6D3', // light
      300: '#E1C5C1', // main
      400: '#B7A19D', // dark
      500: '#A8928D',
      600: '#99827E',
      700: '#8A736E',
      800: '#7B635F',
      900: '#6C544F',
    },
    red: {
      100: '#ffdbdb',
      200: '#ffa6a6',
      300: '#ff8282',
      400: '#ff4d4d',
      500: '#ff0000',
      600: '#e60000',
      700: '#cd0000',
      800: '#9a0000',
      900: '#670000',
    },
    orange: {
      100: '#FDD6AA',
      200: '#FDC788',
      300: '#FEB766',
      400: '#FF9822',
      500: '#FF8800',
      600: '#DC7500',
      700: '#B96200',
      800: '#965000',
      900: '#723D00',
    },
    taupe: {
      100: '#ebe7e7',
      200: '#dfd9d9',
      300: '#c8bdbd',
      400: '#b1a1a1',
      500: '#a59393',
      600: '#998585',
      700: '#806b6b',
      800: '#725f5f',
      900: '#564848',
    },
    blue: {
      100: '#BFCEF6',
      200: '#9FB6F2',
      300: '#7F9EED',
      400: '#5F85E9',
      500: '#3F6DE4',
      600: '#2A4998',
      700: '#203772',
      800: '#15244C',
      900: '#0B1226',
    },
  },
};
