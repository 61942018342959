import { useTranslation } from 'translations/hook';

import CommitmentCard from 'business/lp-platform/home/components/commitment-card';
import ContactsCard from 'business/lp-platform/home/components/contacts-card';
import { FundsShareValuationsCard } from 'business/lp-platform/home/components/funds-share-valuations-card';
import GeneralInfosCard from 'business/lp-platform/home/components/general-infos-card';
import { PortfolioCard } from 'business/lp-platform/home/components/portfolio-card';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import DocumentationCard from 'business/shared/components/documentation-card';
import { GetDashBoardInfosQuery } from 'generated/graphql';
import { FinancialFlowCard } from 'ui/financial-flow-card';

import styles from './dashboard-lp-user.module.scss';

export const DashboardLpUser = ({
  data,
}: {
  data: NonNullable<GetDashBoardInfosQuery>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GeneralInfosCard
        title={t('pages.dashboard.cards.generalInfos.title')}
        data={data}
      />
      <ContactsCard
        title={t('pages.dashboard.cards.contacts.title')}
        data={data}
      />
      <DocumentationCard
        title={t('pages.dashboard.cards.documentation.title')}
        data={{
          afterDateDocumentCount:
            data.afterDateDocumentCount?.aggregate?.count ?? 0,
          totalDocumentCount: data.totalDocumentCount?.aggregate?.count ?? 0,
        }}
        path={LpPlatformRoutes.Document}
      />
      <div className={styles.subGrid}>
        <CommitmentCard
          title={t('pages.dashboard.cards.commitment.title')}
          data={{
            funds: data.funds,
            numericAmount:
              data.totalCommitment.aggregate?.sum?.numericAmount ?? 0,
            numericUnfundedCommitment:
              data.totalCommitment.aggregate?.sum?.numericUnfundedCommitment ??
              0,
            numericTotalDrawn:
              data.totalCommitment.aggregate?.sum?.numericTotalDrawn ?? 0,
          }}
        />
        <FinancialFlowCard
          title={t('pages.dashboard.cards.financialFlows.title')}
          data={{
            drawdown:
              data.totalCommitment.aggregate?.sum?.numericTotalDrawn ?? 0,
            distribution:
              data.totalCommitment.aggregate?.sum?.numericTotalDistributed ?? 0,
            recallableDistribution:
              data.totalCommitment.aggregate?.sum
                ?.numericTotalDistributedRecallable ?? 0,
          }}
          path={LpPlatformRoutes.FinancialFlows}
        />
        <FundsShareValuationsCard
          className={styles.subLastLine}
          title={t('pages.dashboard.cards.fundShareValuations.title')}
          tvpi={data.valuationShareLpSummary.tvpi}
          numericResidualValue={
            data.valuationShareLpSummary.numericResidualValue
          }
          cashflows={data.valuationShareLpSummary.cashflows}
        />
      </div>
      <PortfolioCard
        title={t('pages.dashboard.cards.portfolio.title')}
        data={data}
      />
    </>
  );
};
