import {
  PropsWithChildren,
  FC,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { GridCellProps } from './grid-cell';

interface GridRowProps extends PropsWithChildren {
  className?: string;
  rowOpen?: boolean;
}

export const GridRow: FC<GridRowProps> = (props) => {
  const { children, rowOpen } = props;

  let firstElementUpdated = false;
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement<GridCellProps>(child)) {
      let className = child.props.className;
      if (!firstElementUpdated) {
        firstElementUpdated = true;
        className = twMerge('col-start-1', className);
      }
      const childProps: GridCellProps = { className };
      if (rowOpen != null) {
        childProps.rowOpen = rowOpen;
      }
      return cloneElement(child, childProps);
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};
