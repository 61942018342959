import { Tab } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

// copy / past from @mui/material docs
type LinkTabProps = {
  label: string | ReactNode;
  to: string;
  disabled?: boolean;
};

export const LinkTab = (props: LinkTabProps) => (
  <Tab {...props} component={Link} />
);
