import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { AddOrEditDocumentTemplate } from 'business/fund-manager/settings/template/components/add-or-edit-document-template';
import { getLocalLabel } from 'business/localisation-helper';
import { GetTemplatesByManagementCompanyQuery } from 'generated/graphql';

export const getTemplatesColumnDefinition = (
  t: TFunction,
  routePrefix: string,
): ColDef<
  GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number]
>[] => {
  return [
    {
      headerName: t('pages.fundManager.templates.table.name'),
      colId: 'name',
      valueGetter: ({ data }) => {
        let label = {
          labelEn: '',
          labelFr: '',
        };
        if (data?.labelEn && data?.labelFr) {
          label = {
            labelEn: data.labelEn,
            labelFr: data.labelFr,
          };
        }
        return getLocalLabel(label);
      },
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('pages.fundManager.templates.table.type'),
      colId: 'type',
      valueGetter: ({ data }) => {
        return data?.type
          ? t('pages.fundManager.templates.type', {
              context: `${data?.type}`,
            })
          : '';
      },
      flex: 1,
    },
    {
      headerName: t('pages.fundManager.templates.table.add'),
      type: 'interaction',
      cellRenderer: ({
        data,
      }: ICellRendererParams<
        GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number]
      >) =>
        data ? (
          <AddOrEditDocumentTemplate data={data} routePrefix={routePrefix} />
        ) : (
          <></>
        ),
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'ag-right-aligned-header',
    },
  ];
};
