import { LanguageEnum } from 'translations/types';

import { NACE_CODES } from './nace-codes';
import { INaceCode } from './types';

export const getNaceValue = ({
  value,
  language,
}: {
  value?: string | null;
  language: LanguageEnum;
}): INaceCode | undefined => {
  const codeNace = value && value in NACE_CODES ? value : undefined;

  return codeNace
    ? {
        code: NACE_CODES[codeNace].code,
        description: NACE_CODES[codeNace][language].level4,
        sector: NACE_CODES[codeNace][language].level1,
      }
    : undefined;
};
