import {
  AccessTime,
  CheckCircleOutline,
  Create,
  Draw,
  Error,
} from '@mui/icons-material';
import { Button, Divider, Grid, Link, Stack, Tooltip } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { OnboardingSubscriptionStatusEnum } from 'generated/graphql';
import { Card } from 'ui/card';
import { OnboardingSubscriptionAmount } from 'ui/onboarding-subscription-amount/onboarding-subscription-amount';
import { PeriodBlock } from 'ui/period-block/period-block';
import { Typo } from 'ui/typo';

import styles from './onboarding-subscription-item.module.scss';

const StatusIconButton = ({
  status,
  link,
}: {
  status: OnboardingSubscriptionStatusEnum;
  link?: string;
}) => {
  const { t } = useTranslation();
  const palette = usePalette();

  switch (status) {
    case OnboardingSubscriptionStatusEnum.InvitationSent:
    case OnboardingSubscriptionStatusEnum.ProfileCompleted:
      return (
        <Tooltip
          title={t(
            'pages.dashboard.cards.onboardingSubscriptions.cta.edit.tooltip',
          )}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={Link}
            href={link}
            className="space-x-1"
          >
            <Create fontSize="small" />
            <span>
              {status === OnboardingSubscriptionStatusEnum.InvitationSent
                ? t(
                    'pages.dashboard.cards.onboardingSubscriptions.cta.begin.title',
                  )
                : t(
                    'pages.dashboard.cards.onboardingSubscriptions.cta.edit.title',
                  )}
            </span>
          </Button>
        </Tooltip>
      );

    case OnboardingSubscriptionStatusEnum.WaitingForLpSignature:
      return (
        <Tooltip
          title={t(
            'pages.dashboard.cards.onboardingSubscriptions.cta.sign.tooltip',
          )}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component={Link}
            href={link}
            className="space-x-1"
          >
            <Draw fontSize="small" />
            <span>
              {t(
                'pages.dashboard.cards.onboardingSubscriptions.cta.sign.title',
              )}
            </span>
          </Button>
        </Tooltip>
      );

    case OnboardingSubscriptionStatusEnum.SubInfoDeclined:
    case OnboardingSubscriptionStatusEnum.ProfileDeclined:
      return (
        <Tooltip
          title={t(
            'pages.dashboard.cards.onboardingSubscriptions.cta.declined.tooltip',
          )}
        >
          <Button
            size="small"
            variant="outlined"
            color="error"
            component={Link}
            href={link}
            className="space-x-1"
          >
            <Error />
            <span>
              {t(
                'pages.dashboard.cards.onboardingSubscriptions.cta.declined.title',
              )}
            </span>
          </Button>
        </Tooltip>
      );

    case OnboardingSubscriptionStatusEnum.WaitingForGpSignature:
    case OnboardingSubscriptionStatusEnum.ReadyForSignature:
    case OnboardingSubscriptionStatusEnum.Rejected:
    case OnboardingSubscriptionStatusEnum.ProfileValidated:
    case OnboardingSubscriptionStatusEnum.SubInfoCompleted:
    case OnboardingSubscriptionStatusEnum.SubInfoValidated:
      return (
        <Tooltip
          title={t(
            'pages.dashboard.cards.onboardingSubscriptions.cta.pending.tooltip',
          )}
        >
          <div>
            <Button
              size="small"
              variant="outlined"
              disabled
              className="space-x-1"
            >
              <AccessTime fontSize="small" />
              <span>
                {t(
                  'pages.dashboard.cards.onboardingSubscriptions.cta.pending.title',
                )}
              </span>
            </Button>
          </div>
        </Tooltip>
      );

    case OnboardingSubscriptionStatusEnum.Activated:
      return (
        <Button
          size="small"
          variant="outlined"
          sx={{ color: palette.primaryLight }}
          component={Link}
          href={link}
        >
          <CheckCircleOutline fontSize="small" />
        </Button>
      );

    default:
      return null;
  }
};

const DetailCard = ({
  title,
  label,
}: {
  title: string;
  label: string | ReactNode;
}) => {
  const palette = usePalette();
  return (
    <Grid item xs={4}>
      <Stack
        overflow="hidden"
        padding={1}
        className={styles.details}
        sx={{
          backgroundColor: palette.onboardingDetailCardBg,
        }}
      >
        <Typo size="m" ellipsis bold>
          {title}
        </Typo>
        <Typo size="sm" color="neutral" ellipsis>
          {label}
        </Typo>
      </Stack>
    </Grid>
  );
};

interface OnboardingSubscriptionItemProps {
  lpName: string;
  fundName: string;
  shareName: string;
  link?: string;
  fundPeriods?: Record<
    'investment' | 'liquidation',
    { start: string; end: string }
  >;
  subscriptionAmount: ComponentProps<typeof OnboardingSubscriptionAmount>;
  status: OnboardingSubscriptionStatusEnum;
}
export const OnboardingSubscriptionItem = ({
  lpName,
  fundName,
  link,
  shareName,
  subscriptionAmount,
  status,
  fundPeriods,
}: OnboardingSubscriptionItemProps) => {
  const { t } = useTranslation();

  return (
    <Card className={styles.onboardingSubscriptionCard}>
      <Stack>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Stack flexGrow={1}>
            <Typo size="lg" bold>
              {lpName}
            </Typo>
          </Stack>
          <Stack flexShrink={0}>
            <StatusIconButton link={link} status={status} />
          </Stack>
        </Stack>
        <Divider />
        <Stack padding={1.5} alignItems="center">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <DetailCard
              title={t('components.onboardingSubscriptionItem.fund')}
              label={fundName}
            />
            <DetailCard
              title={t('components.onboardingSubscriptionItem.share')}
              label={shareName}
            />
            <DetailCard
              title={t('components.onboardingSubscriptionItem.amount')}
              label={<OnboardingSubscriptionAmount {...subscriptionAmount} />}
            />
          </Grid>
          {fundPeriods ? (
            <>
              <Stack alignSelf="stretch" marginY={2}>
                <Divider />
              </Stack>
              <Stack spacing={2} direction="row">
                <PeriodBlock
                  title={t(
                    'pages.dashboard.cards.fundProfile.investmentPeriod',
                  )}
                  period={fundPeriods.investment}
                />
                <Stack alignSelf="stretch">
                  <Divider orientation="vertical" variant="middle" />
                </Stack>
                <PeriodBlock
                  title={t(
                    'pages.dashboard.cards.fundProfile.liquidationPeriod',
                  )}
                  period={fundPeriods.liquidation}
                />
              </Stack>
            </>
          ) : null}
        </Stack>
      </Stack>
    </Card>
  );
};
