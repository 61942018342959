import { ApolloClient } from '@apollo/client';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

import { ManagementCompany } from 'business/fund-manager/settings/management-company/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { hasRole } from 'business/user/helpers/roles';
import {
  Lp,
  Roles,
  UserData,
  UserWithPrivateInfos,
  isUserWithPrivateInfos,
} from 'business/user/types/user';
import {
  GetAllLpsDocument,
  GetAllLpsQuery,
  GetAllLpsQueryVariables,
  GetCurrentUserManagementCompanyInformationDocument,
  GetCurrentUserManagementCompanyInformationQuery,
  GetUserLpsDocument,
  GetUserLpsQuery,
  QueryMyUserDocument,
  QueryMyUserQuery,
  QueryMyUserQueryVariables,
} from 'generated/graphql';
import logger from 'technical/logger';

async function fetchUsersLps(client: ApolloClient<object>): Promise<Lp[]> {
  const lpsQuery = await client.query<GetUserLpsQuery>({
    query: GetUserLpsDocument,
    fetchPolicy: 'network-only',
  });

  if (lpsQuery.data.userLps) {
    return lpsQuery.data.userLps;
  }

  return [];
}

async function fetchCurrentUserManagementCompany(
  client: ApolloClient<object>,
  finalUser: UserWithPrivateInfos,
): Promise<ManagementCompany | null> {
  if (!finalUser.roles.includes(Roles.FUND_MANAGER)) {
    return null;
  }
  try {
    const { data } =
      await client.query<GetCurrentUserManagementCompanyInformationQuery>({
        query: GetCurrentUserManagementCompanyInformationDocument,
        fetchPolicy: 'network-only',
      });

    if (
      data.web_portal_userManagementCompany &&
      data.web_portal_userManagementCompany.length > 0
    ) {
      return data.web_portal_userManagementCompany[0].managementCompany;
    }
  } catch (err) {
    logger.error(err);
  }
  return null;
}

async function fetchAllLps(client: ApolloClient<object>): Promise<Lp[]> {
  const lpsQuery = await client.query<GetAllLpsQuery, GetAllLpsQueryVariables>({
    query: GetAllLpsDocument,
    fetchPolicy: 'network-only',
  });

  if (lpsQuery.errors?.length || lpsQuery.errors) {
    logger.error('GetAllLpsQuery error at login', lpsQuery);
    throw new Error('cannot access lps');
  }
  if (!lpsQuery.data.dataviz_lp) {
    logger.error('GetAllLpsQuery successful but no lps returned', lpsQuery);
    enqueueSnackbar(t('errors.successfulLoginButNoLpsFound'), {
      variant: VariantTypeEnum.ERROR,
    });
    return [];
  }
  return lpsQuery.data.dataviz_lp.map((lp) => ({
    id: lp.id,
    name: lp.lpName,
  }));
}

async function fetchLps(
  client: ApolloClient<object>,
  user: UserWithPrivateInfos,
) {
  if (hasRole(user.roles, Roles.DATA_MANAGER)) {
    return fetchAllLps(client);
  }
  if (hasRole(user.roles, Roles.USER)) {
    return fetchUsersLps(client);
  }
  // if user is fund manager we do not need any lpNames
  return [];
}

export default async function fetchUser(
  client: ApolloClient<object>,
): Promise<UserData | undefined> {
  // try to get my user
  const data = await client.query<QueryMyUserQuery, QueryMyUserQueryVariables>({
    query: QueryMyUserDocument,
    fetchPolicy: 'network-only',
  });

  if (data) {
    const {
      data: {
        userPrivate: [finalUser],
      },
    } = data;
    const { user, ...rest } = finalUser;

    if (!finalUser || !user) {
      return undefined;
    }

    if (!isUserWithPrivateInfos(rest)) {
      throw new Error('cannot access private infos');
    }

    const lps = await fetchLps(client, rest);
    const managementCompany = await fetchCurrentUserManagementCompany(
      client,
      rest,
    );

    return {
      ...rest,
      user,
      lps,
      managementCompany,
    };
  }

  return undefined;
}
