import { isAfter, isBefore } from 'date-fns';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { TFunction } from 'translations/hook';

import { FormInputDateValue } from 'ui/form';

export interface ValidateDateArgs {
  date: FormInputDateValue;
  valuationCreationDate: Date | undefined;
  valuationLastDate: Date | undefined;
  clearErrors: UseFormClearErrors<{
    proceed: number;
    date: Date;
  }>;
  setError: UseFormSetError<{
    date: Date;
    proceed: number;
  }>;
  t: TFunction;
}

export const isDateBetweenCreatedValuationAndLastValuation = (
  date: Date,
  valuationCreationDate?: Date,
  valuationLastDate?: Date | undefined,
) => {
  return (
    valuationCreationDate &&
    ((!valuationLastDate && isBefore(date, valuationCreationDate)) ||
      (valuationLastDate &&
        isBefore(date, valuationCreationDate) &&
        isAfter(date, valuationLastDate)))
  );
};

export const validateDate = ({
  date,
  valuationCreationDate,
  valuationLastDate,
  clearErrors,
  setError,
  t,
}: ValidateDateArgs) => {
  if (!date) {
    return;
  }
  clearErrors('date');

  if (
    !isDateBetweenCreatedValuationAndLastValuation(
      date,
      valuationCreationDate,
      valuationLastDate,
    )
  ) {
    setError('date', {
      type: 'manual',
      message: t(
        'pages.fundManager.portfolio.valuationInstrumentProceed.form.errors.date_inferior_than_valuation_creation_date',
      ),
    });
    return false;
  }
  return true;
};
