import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { WebDocumentDownloadButton } from 'business/fund-manager/shared/components/web-download-button';
import { WebDocumentWithType } from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';

export const getDocumentColumnDefinition = (
  t: TFunction,
): ColDef<WebDocumentWithType>[] => {
  const columns: ColDef<WebDocumentWithType>[] = [
    {
      headerName: t('pages.document.table.name'),
      field: 'name',
    },
    {
      headerName: t('pages.document.table.type'),
      field: 'type',
    },
    {
      headerName: t('pages.document.table.uploadDate'),
      type: AgGridColumnTypesEnum.DATE,
      field: 'uploadDate',
    },
    {
      headerName: t('pages.document.table.emissionDate'),
      type: AgGridColumnTypesEnum.DATE,
      field: 'emissionDate',
    },
    {
      headerName: t('pages.document.table.expirationDate'),
      type: AgGridColumnTypesEnum.DATE,
      field: 'expiredAt',
    },
    {
      headerName: t('pages.document.table.action'),
      type: AgGridColumnTypesEnum.INTERACTION,
      colId: 'action',
      cellRenderer: ({ data }: ICellRendererParams<WebDocumentWithType>) => {
        return data?.id ? (
          <WebDocumentDownloadButton documentIds={[data.id]} />
        ) : null;
      },
    },
  ];

  return columns;
};
