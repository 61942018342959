import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundBankForm } from 'business/fund-manager/fund/components/fund-bank-form';
import { FundNavBar } from 'business/fund-manager/fund/components/fund-nav-bar';
import {
  FundBankDetails,
  FundNavBarTabEnum,
} from 'business/fund-manager/fund/services/types';
import {
  isBankFormQuery,
  useBankForm,
} from 'business/fund-manager/fund/services/use-bank-form';
import { fundBankDetailsSchema } from 'business/fund-manager/fund/services/validation';
import { ActionButton } from 'ui/action-button';
import { FormCard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

type FundEditBankFormProps = {
  defaultValues: FundBankDetails;
  onSubmit: (args: FundBankDetails) => void;
  loading?: boolean;
  error?: ApolloError;
};

export const FundEditBankForm = ({
  defaultValues,
  loading,
  error,
  onSubmit,
}: FundEditBankFormProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<FundBankDetails>({
    resolver: yupResolver<FundBankDetails>(fundBankDetailsSchema),
    defaultValues,
  });

  const saveAction = (
    <ActionButton variant="primary" type="submit" loading={loading}>
      {t('common.actions.save')}
    </ActionButton>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <FundBankForm control={control} action={saveAction} error={error} />
        {saveAction}
      </FormCard>
    </form>
  );
};

export const FundEditBank = () => {
  const { fundId = '' } = useParams();

  const bankForm = useBankForm({ fundId });

  if (isBankFormQuery(bankForm)) {
    const { loading, error } = bankForm;
    return (
      <FundNavBar activeTab={FundNavBarTabEnum.Bank}>
        <FormCard>
          <QueryStateDisplay loading={loading} error={error} />
        </FormCard>
      </FundNavBar>
    );
  }

  const { onSubmit, saving, saveError, defaultValues } = bankForm;

  return (
    <FundNavBar activeTab={FundNavBarTabEnum.Bank}>
      <FundEditBankForm
        loading={saving}
        onSubmit={onSubmit}
        error={saveError}
        defaultValues={defaultValues}
      />
    </FundNavBar>
  );
};
