import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetOperationsQuery } from 'generated/graphql';

export const fundColumn = (
  t: TFunction,
  filters: string[],
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'fundName',
    headerName: t('pages.financialFlows.table.fund'),
    aggregable: false,
    type: 'singleSelect',
    valueOptions: filters,
    renderCell: ({ value }) => value,
    valueGetter: ({ row: { share } }) => share?.fund?.name ?? '',
    groupingValueGetter: ({ row: { share } }) => share.fund.name,
  };
};
