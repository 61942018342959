import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useFundOperationsRecapQuery } from 'generated/graphql';
import { FinancialFlowCard } from 'ui/financial-flow-card';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props {
  fundId: string;
}
export const FundFinancialFlowCard = ({ fundId }: Props) => {
  const { t } = useTranslation();

  const financialFlowPageLink = `${FundManagerRoutes.FundIdFinancialFlow.replace(
    ':fundId',
    fundId,
  )}`;

  const { data, loading, error } = useFundOperationsRecapQuery({
    variables: {
      input: {
        fundId,
      },
    },
  });

  if (loading || error || !data?.fundOperationsRecap) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  return (
    <FinancialFlowCard
      data={{
        drawdown: data.fundOperationsRecap.totalDrawn,
        distribution: data.fundOperationsRecap.totalDistributedNonRecallable,
        recallableDistribution:
          data.fundOperationsRecap.totalDistributedRecallable,
      }}
      title={t('pages.dashboard.cards.financialFlows.title')}
      path={financialFlowPageLink}
    />
  );
};
