import { FormikProps, FormikState, getIn } from 'formik';
import get from 'lodash.get';
import { TFunction } from 'translations/hook';

export const formikStatus = (formik: FormikState<any>, fieldName: string) => {
  return get(formik.errors, fieldName) && get(formik.touched, fieldName)
    ? 'error'
    : undefined;
};

export const formikErrorMessage = (
  formik: FormikProps<any>,
  fieldName: string,
) =>
  getIn(formik.touched, fieldName)
    ? getIn(formik.errors, fieldName)
    : undefined;

export const getAllPasswordRules = (t: TFunction) => {
  return [
    t('errors.signUp', { context: 'password-too-short' }),
    t('errors.signUp', { context: 'password-doesnt-contain-lowercase' }),
    t('errors.signUp', { context: 'password-doesnt-contain-uppercase' }),
    t('errors.signUp', { context: 'password-doesnt-contain-number' }),
    t('errors.signUp', { context: 'password-doesnt-contain-symbol' }),
  ];
};
