export enum LpCreationRoutes {
  Create = '/',
  CreateId = '/:lpId',
  CreateIdKYB = '/:lpId/kyb',
  CreateIdIndividualKYC = '/:lpId/individual-kyc',
  CreateIdInvestorProfile = '/:lpId/investor-profile',
  CreateIdKYCDocuments = '/:lpId/kyc-documentation',
  CreateIdLegalEntityKYC = '/:lpId/legal-entity-kyc',
  CreateIdContact = '/:lpId/contact',
}
