import { computeOperationAmountToPay } from 'business/fund-manager/shared/services/compute-operation-values';
import { GetOperationDetailsQuery } from 'generated/graphql';

import { OperationSharesLpValues } from './types';

export const getOperationShareLpsValues = (
  operationsSharesLp: GetOperationDetailsQuery['getOperationDetails'],
): OperationSharesLpValues[] => {
  if (!operationsSharesLp) {
    return [];
  }

  return operationsSharesLp.map((op) => {
    const drawnMinusDistributed = op.drawnMinusDistributed ?? 0;
    const totalCashFlowAmount = op.totalCashFlowAmount ?? 0;
    const cashRatio = op.cashRatio ?? 1;

    const amountToPay = computeOperationAmountToPay({
      investment: op.numericInvestment ?? 0,
      fees: op.numericFees ?? 0,
      premium: op.numericPremium ?? 0,
      other: op.numericOther ?? 0,
      returnOfCost: op.numericReturnOfCost ?? 0,
      capitalGain: op.numericCapitalGain ?? 0,
      interest: op.numericInterest ?? 0,
      dividend: op.numericDividend ?? 0,
      currentDistributed: op.numericCurrentDistributed ?? 0,
    });

    const type = amountToPay < 0 ? 'distribution' : 'drawdown';

    return {
      id: op.id,
      lpName: op.lp.subscribingEntityName,
      shareName: op.operationShare.share.name,
      type,
      countPendingValidationCashflow: op.countPendingValidationCashflow ?? 0,
      remaining:
        (type === 'drawdown'
          ? drawnMinusDistributed
          : drawnMinusDistributed * -1) - totalCashFlowAmount,
      received: totalCashFlowAmount,
      amount: type === 'drawdown' ? amountToPay : amountToPay * -1,
      cashRatio,
    };
  });
};
