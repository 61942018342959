import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { OnboardingGraph } from 'business/fund-manager/home/components/onboarding-graph/onboarding-graph';
import { getOnboardingGraphData } from 'business/fund-manager/home/services/get-onboarding-graph-data';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { useOnboardingSubscriptionStatsQuery } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { InfoCard } from 'ui/info-card';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface Props {
  title: string;
  path: string;
}

export const OnboardingCard = ({ title, path }: Props) => {
  const { t } = useTranslation();
  const { currency } = useFund();
  const { loading, error, data } = useOnboardingSubscriptionStatsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <InfoCard>
      <InfoCard.Title path={path}>{title}</InfoCard.Title>

      <Stack padding={3}>
        {loading || error || !data?.stats ? (
          <QueryStateDisplay loading={true} error={error} />
        ) : (
          <div className={styles.graphContainer}>
            <OnboardingGraph
              data={getOnboardingGraphData(data.stats, t)}
              fileName="investors-repartition-overview"
            />
          </div>
        )}
      </Stack>

      {loading || error || !data?.stats ? null : (
        <InfoCard.Footer>
          <Stack className={styles.footer}>
            <Stack flexDirection="row">
              <Typo bold>
                {data?.stats?.totalOnboardingSubscriptionsCount}&nbsp;
              </Typo>
              <Typo>
                {t(
                  `pages.fundManager.dashboard.cards.onboardingSubscriptions.onboarding`,
                  { count: data?.stats?.totalOnboardingSubscriptionsCount },
                )}
              </Typo>
            </Stack>
            <Stack flexDirection="row">
              <Typo bold>
                {formatToMonetaryAmount(
                  convertFrom10X8toNumber(
                    data?.stats?.totalOnboardingSubscriptionsAmount,
                  ),
                  { currency },
                )}
                &nbsp;
              </Typo>
              <Typo>
                {t(
                  `pages.fundManager.dashboard.cards.onboardingSubscriptions.totalAmount`,
                )}
              </Typo>
            </Stack>
          </Stack>
        </InfoCard.Footer>
      )}
    </InfoCard>
  );
};
