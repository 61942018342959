import { Card, Grid, Stack } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hook';

import { OnBoardingStatusEnum } from 'generated/graphql';
import { MainSecondaryItem } from 'ui/main-secondary-item';
import { OnboardingStatusLabel } from 'ui/onboarding-status-label';

type CardsContentItemProps = Pick<
  ComponentProps<typeof MainSecondaryItem>,
  'main' | 'secondary'
>;

const CardsContentItem = (props: CardsContentItemProps) => (
  <Grid item md={2}>
    <Stack spacing={2} direction="row" alignItems={'center'}>
      <Stack overflow="hidden">
        <MainSecondaryItem {...props} noWrap />
      </Stack>
    </Stack>
  </Grid>
);

interface FundBankDetailsCardProps extends PropsWithChildren {
  fundName: string;
  name?: string;
  iban: string;
  bic: string;
  onboardingStatus: OnBoardingStatusEnum;
}
export const LpFundBankDetailsCard = ({
  fundName,
  onboardingStatus,
  iban,
  bic,
  name,
  children,
}: FundBankDetailsCardProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
      >
        <CardsContentItem
          main={fundName}
          secondary={t('pages.fundManager.lp.bankDetails.fund')}
        />

        <CardsContentItem secondary={`${iban} / ${bic}`} main={name ?? '-'} />
        <Grid item md={3}>
          <Stack spacing={2} direction="row" alignItems={'center'}>
            <Stack>
              <OnboardingStatusLabel value={onboardingStatus} />
            </Stack>
          </Stack>
        </Grid>

        <Grid item md={2} justifyContent="flex-end" display="flex">
          <Stack spacing={2} alignItems="center" direction="row">
            {children}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
