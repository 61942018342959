import { useTranslation } from 'translations/hook';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { GetDashBoardInfosQuery } from 'generated/graphql';
import {
  convertFromX100toNumber,
  formatRatioToPercentage,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import {
  GrossIrrIcon,
  MoicIcon,
  NewInvestmentIcon,
  PortfolioValuationIcon,
  TotalInvestedIcon,
} from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';

interface Props {
  title: string;
  data: Pick<GetDashBoardInfosQuery, 'portfolio' | 'moic' | 'grossIrr'>;
}

export const PortfolioCard = ({ title, data }: Props) => {
  const { t } = useTranslation();

  const displayGrossIrr = useFeatureFlag(FeatureFlagEnum.DISPLAY_GROSS_IRR);

  const displayDashboardMoic = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_DASHBOARD_MOIC,
  );

  const palette = usePalette();
  return (
    <InfoCard>
      <InfoCard.Title path={LpPlatformRoutes.Portfolio}>{title}</InfoCard.Title>
      <InfoCard.Items>
        <InfoItem
          value={`${data.portfolio.aggregate?.nbCompanies ?? 0}`}
          description={t('pages.dashboard.cards.portfolio.portfolioCompanies')}
          imageSource={
            <NewInvestmentIcon
              primaryColor={palette.tertiary[900]}
              secondaryColor={palette.tertiary[100]}
            />
          }
          sx={{
            color: palette.tertiary[900],
            bgcolor: palette.tertiary[100],
          }}
        />
        <InfoItem
          value={`${formatToMonetaryAmount(
            convertFromX100toNumber(
              data.portfolio.aggregate?.sum?.totalInvested ?? 0,
            ),
          )}`}
          description={t('pages.dashboard.cards.portfolio.amountInvested')}
          imageSource={<TotalInvestedIcon primaryColor={palette.primaryMain} />}
          sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}
        />
        <InfoItem
          value={`${formatToMonetaryAmount(
            convertFromX100toNumber(
              data.portfolio.aggregate?.sum?.latestValuation ?? 0,
            ),
          )}`}
          description={t('pages.dashboard.cards.portfolio.portfolioValuation')}
          imageSource={
            <PortfolioValuationIcon primaryColor={'secondary.main'} />
          }
          sx={{ color: 'secondary.main', bgcolor: 'secondary.lighter' }}
        />
        {displayDashboardMoic ? (
          <InfoItem
            value={`${Number(data.moic.result).toFixed(2)} x`}
            description={t('pages.dashboard.cards.portfolio.moic')}
            imageSource={<MoicIcon />}
            sx={{
              color: palette.neutralIconColor,
              bgcolor: palette.neutralBackGroundIconColor,
            }}
          />
        ) : (
          <></>
        )}
        {displayGrossIrr ? (
          <InfoItem
            value={
              !data.grossIrr.result
                ? 'N/A'
                : formatRatioToPercentage(data.grossIrr.result)
            }
            description={t('pages.dashboard.cards.portfolio.grossIrr')}
            imageSource={<GrossIrrIcon primaryColor={palette.secondary[900]} />}
            sx={{ color: 'secondary.dark', bgcolor: 'secondary.lighter' }}
          />
        ) : (
          <></>
        )}
      </InfoCard.Items>
    </InfoCard>
  );
};
