import { TFunction } from 'translations/hook';

import { EmailStatusCodeEnum } from './types';

type Params = {
  t: TFunction;
  waitingForGeneration: boolean;
  hasGenerationError: boolean;
  errorCode?: string | null;
  hasEmailSent: boolean;
};

export const getOperationEmailingStatus = ({
  t,
  waitingForGeneration,
  hasGenerationError,
  errorCode,
  hasEmailSent,
}: Params) => {
  if (hasGenerationError) {
    return {
      status: t(
        'pages.fundManager.operation.emailing.table.emailStatus.error',
        {
          context: errorCode,
        },
      ),
      statusCode: EmailStatusCodeEnum.ERROR,
    };
  }
  if (waitingForGeneration) {
    return {
      status: t(
        'pages.fundManager.operation.emailing.table.emailStatus.pending',
      ),
      statusCode: EmailStatusCodeEnum.PENDING,
    };
  }
  if (hasEmailSent) {
    return {
      status: t('pages.fundManager.operation.emailing.table.emailStatus.sent'),
      statusCode: EmailStatusCodeEnum.SENT,
    };
  }
  return {
    status: t(
      'pages.fundManager.operation.emailing.table.emailStatus.externalCommunication',
    ),
    statusCode: EmailStatusCodeEnum.NO_EMAIl,
  };
};
