export const AgGridColumnTypesEnum = {
  NUMBER: 'number',
  AMOUNT: 'amount',
  AMOUNT_WITH_10X8_DIVIDER: 'amountWith10X8divider',
  VALUE_WITH_10X8_DIVIDER: 'valueWith10X8divider',
  INTERACTION: 'interaction',
  COUNTRY: 'country',
  BOOLEAN: 'boolean',
  SCORE: 'score',
  EMAIL: 'email',
  TEXT: 'text',
  // If the date is an ISO string, it will be displayed as is. Else it will be cast to a Date object and displayed as a date (timezone adjustments will apply).
  DATE: 'date',
} as const;
