import { Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { CompanyMetricsTypeForm } from 'business/fund-manager/settings/company-metrics-type/components/company-metrics-type-form';
import { CompanyMetricsTypeFormContextEnum } from 'business/fund-manager/settings/company-metrics-type/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  GetManagementCompanyMetricsTypesQuery,
  Web_Portal_ManagementCompanyType_Enum,
  useDeleteCompanyMetricsMutation,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { CustomModal } from 'ui/custom-modal';
import { DefaultDeleteButton } from 'ui/default-delete-button';
import { EditButton } from 'ui/edit-button';

interface Props {
  rowData: GetManagementCompanyMetricsTypesQuery['managementCompanyMetricsTypes'][number];
  onModified: () => void;
}

export const CompanyMetricsTypeActionColumn = ({
  rowData,
  onModified,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const canEdit = useManagementCompanyTypeGuard([
    Web_Portal_ManagementCompanyType_Enum.FundAdmin,
    Web_Portal_ManagementCompanyType_Enum.GeneralPartner,
  ]);
  const { id, isDeletable } = rowData;
  const [deleteCompanyMetric] = useDeleteCompanyMetricsMutation({
    onError: () =>
      enqueueSnackbar(t('errors.deleteCompanyMetrics'), {
        variant: VariantTypeEnum.ERROR,
      }),
    onCompleted: () =>
      enqueueSnackbar(t('successMessage.deleteCompanyMetrics'), {
        variant: VariantTypeEnum.SUCCESS,
      }),
    variables: { id },
    // TODO: bad practice, to remove
    refetchQueries: ['GetManagementCompanyMetricsTypes'],
  });
  if (!canEdit) {
    return <></>;
  }
  const defaultValues = rowData;
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <EditButton size="small" onClick={handleOpenModal} />
      <DefaultDeleteButton
        disabled={!isDeletable}
        onClick={() => deleteCompanyMetric()}
        size="small"
      />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <CompanyMetricsTypeForm
            context={CompanyMetricsTypeFormContextEnum.Edit}
            closeForm={handleCloseModal}
            initialValues={defaultValues}
            onCompleted={onModified}
          />
        }
      />
    </Stack>
  );
};
