import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { MergeFieldList } from 'business/fund-manager/settings/template/components/template-merge-field-list';
import { getMergeFieldsTranslatedValue } from 'business/fund-manager/settings/template/services/get-merge-fields-translated-values';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useGetAvailableMergeFieldsQuery } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ButtonClose } from 'ui/button-close';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SearchBar } from 'ui/search-bar';
import { SectionTitle } from 'ui/section-title';

import styles from './index.module.scss';

interface Props {
  handleClose: () => void;
  managementCompanyId: string;
}

const TemplateDynamicContentContainer = ({
  handleClose,
  managementCompanyId,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading, error } = useGetAvailableMergeFieldsQuery({
    variables: { id: managementCompanyId },
  });
  const [searched, setSearched] = useState('');

  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const handleSearch = (value: string) => {
    setSearched(value);
  };

  const items = getMergeFieldsTranslatedValue(
    data?.getAvailableMergeFieldsList.mergeFieldsList,
    t,
  );

  const displayedItems = items.map((item) => {
    return {
      context: item.context,
      mergedFields: item.mergedFields.filter(
        (mergedField) =>
          mergedField.name.includes(searched) ||
          mergedField.description.includes(searched),
      ),
    };
  });

  return (
    <Stack className={styles.container} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <SectionTitle
          title={t('pages.fundManager.templates.dynamicContent.title')}
        />
        <Stack alignItems="flex-end">
          <ButtonClose onClick={handleClose} />
        </Stack>
      </Stack>
      <SearchBar onChange={handleSearch} />
      <Stack>
        {displayedItems.map((item) => (
          <MergeFieldList
            contextName={item.context}
            items={item.mergedFields}
            key={item.context}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const TemplateDynamicContent = ({
  managementCompanyId,
}: {
  managementCompanyId: string;
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const palette = usePalette();

  const handleOpen = () => {
    setCollapsed(true);
  };

  const handleClose = () => {
    setCollapsed(false);
  };

  return (
    <Stack
      className={styles.container}
      sx={{ backgroundColor: palette.backgroundForm }}
      padding={3}
    >
      {!collapsed ? (
        <ActionButton variant="secondary" onClick={handleOpen}>
          {t('pages.fundManager.templates.dynamicContent.insert')}
        </ActionButton>
      ) : null}

      {collapsed ? (
        <TemplateDynamicContentContainer
          handleClose={handleClose}
          managementCompanyId={managementCompanyId}
        />
      ) : null}
    </Stack>
  );
};
