import { useFund } from 'business/providers/fund-provider/use-fund';
import { OnboardingSubscriptionTypeEnum } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';

import styles from './onboarding-subscription-amount.module.scss';

type OnboardingSubscriptionAmountProps =
  | { type: OnboardingSubscriptionTypeEnum.Fixed; numericAmount: number }
  | {
      type: OnboardingSubscriptionTypeEnum.Range;
      numericAmountMax: number;
      numericAmountMin: number;
    };

export const OnboardingSubscriptionAmount = (
  props: OnboardingSubscriptionAmountProps,
) => {
  const { currency } = useFund();

  if (props.type === OnboardingSubscriptionTypeEnum.Fixed) {
    return (
      <>
        {formatToMonetaryAmount(convertFrom10X8toNumber(props.numericAmount), {
          currency,
        })}
      </>
    );
  }

  return (
    <>
      <span>
        {formatToMonetaryAmount(
          convertFrom10X8toNumber(props.numericAmountMin),
          {
            currency,
          },
        )}
      </span>
      <span className={styles.amountSeparator}>-</span>
      <span>
        {formatToMonetaryAmount(
          convertFrom10X8toNumber(props.numericAmountMax),
          {
            currency,
          },
        )}
      </span>
    </>
  );
};
