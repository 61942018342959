import { Avatar, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { areMultipleLpStored } from 'business/lp-platform/nav-bar/services/lp-filters';
import { InfoCard } from 'ui/info-card';
import { InfoDetailItem } from 'ui/info-details';

import styles from './index.module.scss';

interface Address {
  streetLine: string;
  additionalInfos?: string | null;
  zipCode: string;
  city: string;
  country: string;
  [key: string]: unknown;
}

interface Props extends PropsWithChildren {
  lpName?: string;
  logoPath?: string | null;
  address?: Address | null;
}

const LpInformationCard = ({ lpName, logoPath, address, children }: Props) => {
  const isAddressDisplayed = !areMultipleLpStored() && address;
  return (
    <InfoCard.Items>
      <Stack spacing={4}>
        <Stack direction="row" spacing={4}>
          <Avatar
            alt={lpName}
            src={logoPath ?? ''}
            sx={{ height: '72px', width: '72px' }}
            className={styles.avatar}
          />
          <Stack
            className={!isAddressDisplayed ? styles.verticallyCenterText : ''}
          >
            <Typography className={styles.subtitle} align="left">
              {lpName ?? ''}
            </Typography>
            {isAddressDisplayed ? (
              <>
                <InfoDetailItem value={address.streetLine} />
                {address.additionalInfos ? (
                  <InfoDetailItem value={address.additionalInfos} />
                ) : null}
                <InfoDetailItem value={address.zipCode + ' ' + address.city} />
                <InfoDetailItem value={address.country} />
              </>
            ) : null}
          </Stack>
        </Stack>
        {children}
      </Stack>
    </InfoCard.Items>
  );
};

export default LpInformationCard;
