import { TFunction } from 'translations/hook';
import * as yup from 'yup';

import { email, passwordConfirmation } from 'business/user/validations/string';
import { password } from 'business/user/validations/string';
import { emailRequiredSchema } from 'technical/validation';

export const forgotPasswordFormSchema = yup.object({
  login: emailRequiredSchema,
});

export const setPasswordFormSchema = (t: TFunction) =>
  yup.object({
    password: password(t),
    passwordConfirmation: passwordConfirmation(t),
  });

export const loginFormSchema = (t: TFunction) =>
  yup.object({
    login: email(t),
    password: password(t),
  });
