import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ServerError,
  ServerParseError,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { router } from 'business/router/services';
import SharedRoutes from 'business/shared/router/routes';
import {
  getAccessToken,
  renewToken,
} from 'business/user/services/auth-service';
import config from 'config/index';
import { stringify } from 'technical/convert/convert-utils';
import { handleError } from 'technical/error-reporting/error-utils';
import logger from 'technical/logger';

import { cache } from './cache';

const authLink = setContext((_, { headers }: any) => {
  // get the authentication token from auth service if it exists
  const token = getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // if token does not exist do not set authorization header
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
});

export function makeFetch(
  fetch: WindowOrWorkerGlobalScope['fetch'],
  loggerInstance: typeof logger,
  authServiceInstance: {
    renewToken: () => Promise<void>;
    getAccessToken: () => string | null;
  },
): WindowOrWorkerGlobalScope['fetch'] {
  return (input, init) =>
    fetch(input, init).then(async (response) => {
      const json = await response.json();
      if (json.errors && json.errors[0]?.extensions?.code === 'access-denied') {
        loggerInstance.info('Renewing token');
        try {
          await authServiceInstance.renewToken();
        } catch (error) {
          //If expired, redirect to login
          if ((error as any)?.message === 'jwt expired') {
            // Instead of using History, we use window.location so
            // The app is re-bootstrap and informations concerning our user
            // are up to date: ie he's not here anymore
            window.location.href = SharedRoutes.SignIn;
          }
          throw error;
        }
        const newToken = authServiceInstance.getAccessToken();
        loggerInstance.info('Token renewed!');
        // Updating headers with new token
        return fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            authorization: `Bearer ${newToken}`,
          },
        });
      }

      // Recreating json ad text method that ca be called only one beefore forwardig
      return {
        ...response,
        json: () => Promise.resolve(json),
        text: () => Promise.resolve(JSON.stringify(json)),
      };
    });
}

const httpLink = new HttpLink({
  uri: `${config.graphqlUri}/v1/graphql`,
  credentials: 'include',
  // Custom fetch to handle reconnection on jwt expired
  fetch: makeFetch(fetch, logger, { renewToken, getAccessToken }),
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // General case: log and keep throwing an error for backward compatibility.
  if (graphQLErrors) {
    for (const {
      message,
      locations,
      path /* , extensions */,
    } of graphQLErrors) {
      handleError(
        `[-GraphQL error]: Message: ${message} - Location: ${JSON.stringify(
          locations,
        )} - Path: ${path}`,
      );
    }
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[-Network error]`);
    handleError(
      stringify(
        (networkError as ServerError)?.result ||
          (networkError as ServerParseError)?.bodyText ||
          (networkError as Error | ServerParseError)?.message ||
          networkError,
      ),
    );
  }

  if (networkError) {
    router.navigate(SharedRoutes.Error);
  }
});
const client = new SubscriptionClient(`${config.graphqlWsUri}/v1/graphql`, {
  reconnect: true,
  timeout: 30000,
  connectionParams: () => {
    const token = localStorage.getItem('token');

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
});

const wsLink = new WebSocketLink(client);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = ApolloLink.split(
  // split based on operation type
  ({ query }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore https://hasura.io/blog/moving-from-apollo-boost-to-graphql-subscriptions-with-apollo-client-cc0373e0adb0/
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);
const apolloClient = new ApolloClient({
  link: from([errorLink, link]),
  cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
