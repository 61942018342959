import { ValuationData } from 'business/lp-platform/valuations/types';
import { ValuationGraphData } from 'business/shared/services/types';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';

export const getValuationGraphData = (
  queryData: ValuationData[],
): ValuationGraphData[] =>
  queryData.map((data) => ({
    valuationDate: data.valuationDate,
    paidIn: data.numericPaidIn,
    distributed: data.numericDistributedNonRecallable,
    distributedRecallable: data.numericDistributedRecallable,
    residual: data.numericResidualValue,
    dpi: convertFrom10X8toNumber(data.numericDpi),
    rvpi: convertFrom10X8toNumber(data.numericRvpi),
  }));
