import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { CompanyMetricsTypeActionColumn } from 'business/fund-manager/settings/company-metrics-type/components/company-metrics-type-action';
import { GetManagementCompanyMetricsTypesQuery } from 'generated/graphql';

export function getCompanyMetricsTypeColumnDefinition(
  t: TFunction,
  onModified: () => void,
): ColDef<
  GetManagementCompanyMetricsTypesQuery['managementCompanyMetricsTypes'][number]
>[] {
  return [
    {
      field: 'name',
      // combining field and filter: true, ag-grid already knows
      // which options to display
      filter: true,
      headerName: t('pages.fundManager.settings.companyMetrics.table.name'),
      flex: 1,
    },
    {
      field: 'unit',
      filter: false,
      headerName: t('pages.fundManager.settings.companyMetrics.table.unit'),
      flex: 1,
    },
    {
      headerName: t(
        'pages.fundManager.settings.companyMetrics.table.action.header',
      ),
      flex: 1,
      headerClass: 'ag-right-aligned-header',
      // cannot use styles unfortunately
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      cellRenderer: ({
        data,
      }: ICellRendererParams<
        GetManagementCompanyMetricsTypesQuery['managementCompanyMetricsTypes'][number]
      >) => {
        if (!data) {
          return null;
        }

        return (
          <CompanyMetricsTypeActionColumn
            rowData={data}
            onModified={onModified}
          />
        );
      },
    },
  ];
}
