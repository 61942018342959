import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { Typo } from 'ui/typo';

interface NoDataCardProps {
  text?: string;
}
export const NoDataCard = ({ text }: NoDataCardProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1} padding={5} alignItems="center" justifyContent="center">
      <Stack marginBottom={3}>
        <ErrorOutlineOutlined />
      </Stack>
      <Typo size="sm">{text ?? t('common.noData')}</Typo>
    </Stack>
  );
};
