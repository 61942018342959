import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import {
  DocumentationNavBar,
  DocumentationTab,
} from 'business/fund-manager/settings/advisor-document-type/components/documentation-nav-bar/documentation-nav-bar';
import { LpDocumentTypeModal } from 'business/fund-manager/settings/advisor-document-type/components/upsert-lp-document-type-modal';
import { getLpDocumentTypeColumnDefinition } from 'business/fund-manager/settings/advisor-document-type/services/get-lp-document-type-column-definition';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import {
  LpDocumentTypesQuery,
  useLpDocumentTypesQuery,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpDocumentType = () => {
  const { t } = useTranslation();
  const { managementCompanyId, routePrefix } = useNavigateSettings();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lpDocTypeBeingEdited, setLpDocTypeBeingEdited] = useState<
    LpDocumentTypesQuery['lpDocumentTypes'][number] | null
  >(null);

  const { data, loading, error, refetch } = useLpDocumentTypesQuery({
    fetchPolicy: 'network-only',
    variables: {
      managementCompanyId,
    },
  });

  if (loading || error || !data) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const handleOpenModal = (
    lpDocTypeToEdit?: LpDocumentTypesQuery['lpDocumentTypes'][number],
  ) => {
    if (lpDocTypeToEdit) {
      setLpDocTypeBeingEdited(lpDocTypeToEdit);
    }
    setIsModalOpen(true);
  };
  const headers = getLpDocumentTypeColumnDefinition({ t, handleOpenModal });

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLpDocTypeBeingEdited(null);
    refetch();
  };

  return (
    <Stack spacing={2}>
      <DocumentationNavBar
        activeTab={DocumentationTab.Lp}
        routePrefix={routePrefix}
        button={
          <ActionButton
            size="small"
            variant="submit"
            onClick={() => setIsModalOpen(true)}
            data-test-id="create-operation-button"
          >
            {t('pages.fundManager.settings.documentType.actions.add')}
          </ActionButton>
        }
      />
      <Stack spacing={2}>
        {isModalOpen ? (
          <LpDocumentTypeModal
            open
            onClose={handleCloseModal}
            managementCompanyId={managementCompanyId}
            lpDocumentType={lpDocTypeBeingEdited}
          />
        ) : null}
        <AlternativeTable<LpDocumentTypesQuery['lpDocumentTypes'][number]>
          columnDefs={headers}
          rowData={data.lpDocumentTypes}
          domLayout="autoHeight"
          fileName={'advisor-lp-document-type'}
        />
      </Stack>
    </Stack>
  );
};
