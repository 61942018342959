import { TFunction } from 'translations/hook';
import * as yup from 'yup';
import yupPassword from 'yup-password';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'yup-phone-lite';

import {
  ConditionalValidationParams,
  IStringValidation,
} from 'business/user/validations/types';
import { ValidationErrors } from 'technical/validation/types';

yupPassword(yup);

const MAX_PASSWORD_LENGTH = 64;
const MIN_PASSPHRASE_LENGTH = 20;
const MAX_MFA_LENGTH = 6;
const MIN_MFA_LENGTH = 6;

export const email = (t: TFunction) =>
  yup
    .string()
    .email(t(ValidationErrors.EMAIL_INVALID))
    .lowercase(t(ValidationErrors.EMAIL_LOWERCASE))
    .required(t(ValidationErrors.REQUIRED));

const simplePassword = (t: TFunction) =>
  yup
    .string()
    .password()
    .min(8, t('errors.signUp', { context: 'password-too-short' }))
    .max(MAX_PASSWORD_LENGTH, t('errors.too_long'))
    .minUppercase(
      1,
      t('errors.signUp', { context: 'password-doesnt-contain-uppercase' }),
    )
    .minLowercase(
      1,
      t('errors.signUp', { context: 'password-doesnt-contain-lowercase' }),
    )
    .minNumbers(
      1,
      t('errors.signUp', { context: 'password-doesnt-contain-number' }),
    )
    .minSymbols(
      1,
      t('errors.signUp', { context: 'password-doesnt-contain-symbol' }),
    )
    .required(t('errors.required'));

const passphrase = (t: TFunction) =>
  yup
    .string()
    .min(MIN_PASSPHRASE_LENGTH, t('errors.too_short'))
    .max(MAX_PASSWORD_LENGTH, t('errors.too_long'))
    .required(t('errors.required'));

export const mfaCode = yup
  .string()
  .min(MIN_MFA_LENGTH, 'errors.too_short')
  .max(MAX_MFA_LENGTH, 'errors.too_long')
  .required('errors.required');

export const name = (t: TFunction, required?: boolean) => {
  const schema = yup
    .string()
    .min(2, t('errors.too_short'))
    .max(50, t('errors.too_long'));

  return required ? schema.required() : schema;
};

export const title = (t: TFunction) =>
  yup
    .string()
    .min(2, t('errors.too_short'))
    .max(3, t('errors.too_long'))
    .required(t('errors.required'));

export const message = (t: TFunction, required?: boolean) => {
  const schema = yup
    .string()
    .min(2, t('errors.too_short'))
    .max(300, t('errors.too_long'));

  return required ? schema.required() : schema;
};

export const id = (t: TFunction) =>
  yup
    .string()
    .min(2, t('errors.too_short'))
    .max(100, t('errors.too_long'))
    .required(t('errors.required'));

export const boolean = (t: TFunction) =>
  yup.boolean().required(t('errors.required'));

export const simpleSelect = (t: TFunction) =>
  yup.string().required(t('errors.required'));

export const password = (t: TFunction) =>
  yup.lazy((value) => {
    if (value && value.length >= MIN_PASSPHRASE_LENGTH) {
      return passphrase(t);
    }
    return simplePassword(t);
  });

export const passwordConfirmation = (t: TFunction) =>
  yup
    .string()
    .required(t('errors.required'))
    .oneOf([yup.ref('password')], t('errors.passwords_did_not_match'));

export const phone = (t: TFunction) =>
  yup
    .string()
    .phone('FR', t('errors.wrong_phone_format'))
    .required(t('errors.required'));

export const optionalPhoneNumber = (fieldName: string, t: TFunction) => {
  return yup.string().when(fieldName, {
    is: (value: string) => value?.length > 0,
    then: () => phone(t),
    otherwise: () => yup.string(),
  });
};

export const phoneNumberSchema = yup
  .string()
  .phone('FR', 'errors.wrong_phone_format')
  .optional();

const stringValidation = (minValue: number, maxValue: number, t: TFunction) => {
  return yup
    .string()
    .min(minValue, t('errors.too_short'))
    .max(maxValue, t('errors.too_long'));
};

export const stringValidationSchema = (
  { minValue, maxValue, required }: IStringValidation,
  t: TFunction,
) => {
  const schema = stringValidation(minValue, maxValue, t);

  if (required) {
    return schema.required(t('errors.required'));
  }
  return schema;
};

export const requiredStringWhenFieldEquals = (
  { dependingFieldName, expectedValue }: ConditionalValidationParams,
  { minValue, maxValue, fieldName }: IStringValidation,
  t: TFunction,
) => {
  return yup.string().when(dependingFieldName, {
    is: (value: string) => value === expectedValue,
    then: () =>
      stringValidationSchema(
        {
          minValue,
          maxValue,
          required: true,
          fieldName,
        },
        t,
      ),
    otherwise: () =>
      stringValidationSchema(
        {
          minValue: 0,
          maxValue,
          required: false,
          fieldName,
        },
        t,
      ),
  });
};
