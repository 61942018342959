import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  // NOTE: key name must be the same name as the GQL type of the query
  typePolicies: {
    web_portal_companyContact: {
      keyFields: ['companyId', 'contactId'],
    },
    LpContact: {
      keyFields: ['lpId', 'contactIndividualEntityId'],
    },
    CompanyContact: {
      keyFields: ['companyId', 'contactId'],
    },
    DeclineReason: {
      keyFields: ['lpId', 'step'],
    },
    LpDocument: {
      keyFields: ['lpId', 'documentId'],
    },
  },
  // here we say to apollo how to deal with cache object keys
  // I use it to merge custom-server/gql.typename and hasura.typename
  // to let apollo update right object after mutations
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'LpExecutive':
        return `web_portal_lpExecutive:${responseObject.id}`;
      case 'LpSignatory':
        return `web_portal_lpSignatory:${responseObject.id}`;
      case 'LpUltimateBeneficialOwner':
        return `web_portal_lpUltimateBeneficialOwner:${responseObject.id}`;
      case 'Lp':
        return `web_portal_lp:${responseObject.id}`;
      case 'Address':
        return `web_portal_address:${responseObject.id}`;
      case 'BankDetails':
        return `web_portal_bankDetails:${responseObject.id}`;
      case 'FundProfile':
        return `web_portal_fund:${responseObject.id}`;
      case 'FundCompany':
        return `web_portal_company:${responseObject.id}`;
      case 'Share':
        return `web_portal_share:${responseObject.id}`;
      case 'OperationShareLp':
        return `web_portal_operationShareLp:${responseObject.id}`;
      // //////////////
      // contact
      case 'CompanyContactDeprecated':
      case 'Contact':
      case 'UpdateContactResponse':
        return `web_portal_contact:${responseObject.id}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});
