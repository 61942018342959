import { Box, Divider, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { useTranslation } from 'translations/hook';

import { getAggregatedAndOrderedValuesForGraphAndSynthesis } from 'business/lp-platform/commitment/services/get-aggregated-and-ordered-values-for-graph-and-synthesis';
import { getCommitmentSynthesisColumnDefinition } from 'business/lp-platform/commitment/services/get-commitment-synthesis-column-definition';
import { AggregatedValuesType } from 'business/lp-platform/commitment/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { GetCommitmentsQuery } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { PieGraph } from 'ui/graph/pie-graph';
import { Table } from 'ui/table';

import styles from './index.module.scss';

interface Props {
  commitments: GetCommitmentsQuery['dataviz_commitment'];
  loading: boolean;
}

const GraphAndSynthesisPanel = ({ commitments, loading }: Props) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const palette = usePalette();

  const colorRange = [
    palette.primary[900],
    palette.primary[400],
    palette.secondary[800],
    palette.secondary[500],
    palette.tertiary[300],
  ];

  const { aggregatedValues, totalAmount } =
    getAggregatedAndOrderedValuesForGraphAndSynthesis(commitments);

  const graphLabels = aggregatedValues.map(({ fund }) => fund);
  const graphValues = aggregatedValues.map(({ amount }) => amount);

  const headers: GridColDef<AggregatedValuesType>[] =
    getCommitmentSynthesisColumnDefinition({
      t,
      totalAmount,
      colorRange,
      apiRef,
    });

  const columns = headers.map((header) => ({ ...header, sortable: false }));

  return (
    <Box
      className={styles.container}
      sx={{ bgcolor: palette.backgroundForm }}
      data-test-id="commitments-graph-synthesis-panel"
    >
      <Stack direction={'row'} className={styles.titleBar}>
        <h6 className={styles.title}>{t('pages.commitment.graph.title')}</h6>
      </Stack>
      <Divider light />

      <Grid2 container spacing={0} height="auto">
        <Grid2 xs={5} container justifyContent={'center'}>
          <Stack>
            <PieGraph
              labels={graphLabels}
              values={graphValues}
              colorRange={colorRange}
              fileName={t('pages.commitment.graph.fileName')}
            />
            <Typography variant="h4">
              {formatToMonetaryAmount(convertFrom10X8toNumber(totalAmount))}
            </Typography>
            {t('pages.commitment.graph.subtitle')}
          </Stack>
        </Grid2>
        <Grid2
          xs={7}
          className={styles.rightStack}
          sx={{ borderColor: palette.text }}
        >
          <Table<AggregatedValuesType>
            columns={columns}
            rows={aggregatedValues}
            loading={loading}
            getRowId={(row) => `${row.fund}`}
            apiRef={apiRef}
            hideFooter
            toolbar={false}
            disableColumnMenu
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            rowHeight={61}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default GraphAndSynthesisPanel;
