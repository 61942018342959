import { UseFormSetValue, useWatch } from 'react-hook-form';

import { LpProfile } from 'business/shared/lp-creation-module/services/types';
import {
  LpGeneralInformationAutocompleteResultQuery,
  useLpGeneralInformationAutocompleteResultLazyQuery,
} from 'generated/graphql';
import { isSiren } from 'technical/is-siren';

interface Props {
  setValue: UseFormSetValue<LpProfile>;
}

const keys: (keyof Omit<
  LpGeneralInformationAutocompleteResultQuery['generalInformationAutocompleteResult']['content'],
  'address'
>)[] = [
  'subscribingEntityName',
  'country',
  'uniqueCompanyIdentifier',
  'registrationLocation',
];

const addressKeys: (keyof NonNullable<
  LpGeneralInformationAutocompleteResultQuery['generalInformationAutocompleteResult']['content']['address']
>)[] = ['streetLine', 'streetLine2', 'zipCode', 'city'];

export const useGeneralProfileAutocomplete = ({ setValue }: Props) => {
  const siren = useWatch({
    name: 'uniqueCompanyIdentifier',
  });
  const [getAutocompleteResult, { loading, error }] =
    useLpGeneralInformationAutocompleteResultLazyQuery();

  const fillFormData = (
    content: LpGeneralInformationAutocompleteResultQuery['generalInformationAutocompleteResult']['content'],
  ) => {
    keys.forEach((key) => {
      setValue(key, content[key]);
    });

    addressKeys.forEach((key) => {
      setValue(`address.${key}`, content.address?.[key] ?? '');
    });
  };

  const fetchAndSetAutocompleteResult = async () => {
    if (!siren) {
      return;
    }

    const { data } = await getAutocompleteResult({
      variables: { input: { siren } },
    });

    setValue('isAutocomplete', true);

    if (!data?.generalInformationAutocompleteResult) {
      return;
    }

    fillFormData(data.generalInformationAutocompleteResult.content);
  };

  return {
    fetchAndSetAutocompleteResult,
    disabled: !isSiren(siren),
    loading,
    error: error?.message,
  };
};
