import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { SettingsCard } from 'business/fund-manager/settings/shared/components/settings-card';
import { replaceRouteWithPrefix } from 'business/fund-manager/settings/shared/hooks/route-prefix-utils';
import { useNavigateSettings } from 'business/fund-manager/settings/shared/hooks/use-navigate-settings';
import {
  ManagementCompanyType,
  useGetManagementCompanyQuery,
} from 'generated/graphql';
import { DashboardGrid } from 'ui/dashboard-grid/dashboard-grid';
import {
  CurrencyExchangeIcon,
  DocumentationMenuIcon,
  ValuationMenuIcon,
} from 'ui/icons';
import { QueryStateDisplay } from 'ui/query-state-display';

export const SettingsIndex = () => {
  const { t } = useTranslation();
  const { routePrefix, managementCompanyId } = useNavigateSettings();

  const {
    data: dataManagementCompany,
    loading,
    error,
  } = useGetManagementCompanyQuery({
    variables: { id: managementCompanyId },
  });

  if (error || !dataManagementCompany?.companyInfos || loading) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  const isFundAdmin =
    dataManagementCompany?.companyInfos.type ===
    ManagementCompanyType.FundAdmin;

  return (
    <DashboardGrid>
      <SettingsCard
        path={replaceRouteWithPrefix(
          FundManagerRoutes.SettingsProfile,
          routePrefix,
        )}
        title={t('pages.fundManager.FundManagerCompanyUpdate.title')}
      >
        <CurrencyExchangeIcon />
      </SettingsCard>

      {isFundAdmin ? (
        <>
          <SettingsCard
            path={replaceRouteWithPrefix(
              FundManagerRoutes.SettingsAdvisor,
              routePrefix,
            )}
            title={t('pages.fundManager.managementCompanyAdvisorList.title')}
          >
            <CurrencyExchangeIcon />
          </SettingsCard>
          <SettingsCard
            path={replaceRouteWithPrefix(
              FundManagerRoutes.SettingsAdvisorCreate,
              routePrefix,
            )}
            title={t('pages.fundManager.managementCompanyAdvisorCreate.title')}
          >
            <CurrencyExchangeIcon />
          </SettingsCard>
        </>
      ) : (
        <>
          <SettingsCard
            path={replaceRouteWithPrefix(
              FundManagerRoutes.SettingsTemplateList,
              routePrefix,
            )}
            title={t('pages.fundManager.settings.publicationData.title')}
          >
            <DocumentationMenuIcon />
          </SettingsCard>
          <SettingsCard
            path={replaceRouteWithPrefix(
              FundManagerRoutes.SettingsMetrics,
              routePrefix,
            )}
            title={t('pages.fundManager.settings.companyMetrics.title')}
          >
            <ValuationMenuIcon />
          </SettingsCard>
          <SettingsCard
            path={replaceRouteWithPrefix(
              FundManagerRoutes.SettingsLpDocumentationType,
              routePrefix,
            )}
            title={t(
              'pages.fundManager.managementCompanyDocumentationType.title',
            )}
          >
            <DocumentationMenuIcon />
          </SettingsCard>
        </>
      )}
    </DashboardGrid>
  );
};
