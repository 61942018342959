import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';

import { formatLpName } from 'business/lp-platform/nav-bar/services/format-lp-name';
import {
  getSelectedLps,
  getStoredLp,
} from 'business/lp-platform/nav-bar/services/lp-filters';
import { LPFilter } from 'business/lp-platform/nav-bar/services/types';
import { useAppContext } from 'business/providers/app-provider';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LpMultiSelector = () => {
  const storedLps = getStoredLp();
  const { setLpContext, user } = useAppContext();
  const [lps, setLp] = useState<LPFilter[]>(
    user?.lps ? getSelectedLps(storedLps, user.lps) : [],
  );
  const [lpsOnOpen, setLpsOnOpen] = useState<LPFilter[]>([]);

  if (!lps || lps.length === 1) {
    return null;
  }

  const getSelectedLpCount = () => {
    return lps.filter((lp) => lp.selected).length;
  };

  const isLpChecked = (lpName: string) => {
    return lps.find(({ name }) => lpName === name)?.selected || false;
  };

  const canLpBeUnchecked = (lpName: string) => {
    return !isLpChecked(lpName) || getSelectedLpCount() > 1;
  };

  const handleSelectChange = (lpName: string | LPFilter) => {
    const selectedLp = lps.map((lp) =>
      lp.name === lpName ? { ...lp, selected: !lp.selected } : lp,
    );

    setLpContext(selectedLp.filter(({ selected }) => selected));

    return selectedLp;
  };

  const handleChange = async (event: SelectChangeEvent<LPFilter[]>) => {
    const {
      target: { value },
    } = event;

    // Need a better way to get selected value.
    const lpName = value[value.length - 1];
    if (canLpBeUnchecked(lpName as string)) {
      setLp(handleSelectChange(lpName));
    }
  };

  const sameLpsSelected = (
    selectedOnOpen: LPFilter[],
    selectedOnClose: LPFilter[],
  ) => {
    return (
      selectedOnOpen.length === selectedOnClose.length &&
      selectedOnOpen.every(
        (lp: LPFilter, index: number) => lp.id === selectedOnClose[index].id,
      )
    );
  };

  const handleOpen = () => {
    const alreadySelected = lps.filter((lp) => lp.selected);
    setLpsOnOpen(alreadySelected);
  };

  const handleClose = () => {
    const lpsOnClose = lps.filter((lp) => lp.selected);
    if (!sameLpsSelected(lpsOnOpen, lpsOnClose)) {
      window.location.reload();
    }
  };

  return (
    <Select
      multiple
      value={lps}
      onChange={handleChange}
      renderValue={(selected) =>
        selected
          .filter(({ selected: selectedFilter }) => selectedFilter)
          .map(({ name }, index) =>
            formatLpName({ selectedLp: selected, lpName: name, index }),
          )
          .slice(0, 2)
          .join(' | ')
      }
      MenuProps={MenuProps}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {lps.map(({ name, selected }) => {
        return (
          <MenuItem key={name} value={name}>
            <Checkbox
              checked={selected}
              disabled={getSelectedLpCount() === 1}
            />
            <ListItemText primary={name} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default LpMultiSelector;
