import { InputOutlined, OutputOutlined } from '@mui/icons-material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { useValuationSynthesisQuery } from 'generated/graphql';
import {
  formatToMonetaryAmount,
  formatNumber,
} from 'technical/currency/formatters';
import { formatAsDate } from 'technical/date';
import { MoicIcon, PortfolioValuationIcon, TotalInvestedIcon } from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

export const ValuationSynthesisCard = () => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();
  const palette = usePalette();
  const { data, loading, error } = useValuationSynthesisQuery({
    variables: { fundId },
    fetchPolicy: 'network-only',
  });

  if (error || loading) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (!data?.valuationSynthesis) {
    return null;
  }

  return (
    <InfoCard>
      <InfoCard.Title>
        {data.valuationSynthesis.date
          ? t('pages.fundManager.portfolio.valuationInstrument.card.title', {
              date: formatAsDate(new Date(data.valuationSynthesis.date)),
            })
          : t(
              'pages.fundManager.portfolio.valuationInstrument.card.noDateTitle',
            )}
      </InfoCard.Title>
      <InfoCard.Items>
        <Stack direction="row" justifyContent="space-evenly">
          <InfoItem
            className={styles.item}
            value={formatToMonetaryAmount(
              data.valuationSynthesis.acquisitionCost,
            )}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.acquisitionCost',
            )}
            imageSource={<InputOutlined />}
            size="normal"
            sx={{
              color: 'primary.main',
              bgcolor: 'primary.lighter',
            }}
          />
          <InfoItem
            className={styles.item}
            value={formatToMonetaryAmount(data.valuationSynthesis.fairValue)}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.fairValue',
            )}
            imageSource={
              <PortfolioValuationIcon
                primaryColor={palette.secondaryIconColor}
              />
            }
            size="normal"
            sx={{
              color: palette.secondaryIconColor,
              bgcolor: palette.secondaryBackgroundIconColor,
            }}
          />
          <InfoItem
            className={styles.item}
            value={formatToMonetaryAmount(
              data.valuationSynthesis.accruedCoupon,
            )}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.accruedCoupon',
            )}
            imageSource={<ConfirmationNumberIcon />}
            size="normal"
            sx={{
              color: palette.secondaryIconColor,
              bgcolor: palette.secondaryBackgroundIconColor,
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-evenly">
          <InfoItem
            className={styles.item}
            value={formatToMonetaryAmount(data.valuationSynthesis.proceeds)}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.products',
            )}
            imageSource={
              <TotalInvestedIcon primaryColor={palette.secondaryIconColor} />
            }
            size="normal"
            sx={{
              color: palette.secondaryIconColor,
              bgcolor: palette.secondaryBackgroundIconColor,
            }}
          />
          <InfoItem
            className={styles.item}
            value={formatToMonetaryAmount(data.valuationSynthesis.exitValue)}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.exitValue',
            )}
            imageSource={<OutputOutlined />}
            size="normal"
            sx={{
              color: 'primary.main',
              bgcolor: 'primary.lighter',
            }}
          />
          <InfoItem
            className={styles.item}
            value={formatToMonetaryAmount(
              data.valuationSynthesis.latentCapitalGainLoss,
            )}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.latentCapitalGainLoss',
            )}
            imageSource={<TrendingUpIcon />}
            size="normal"
            sx={{
              color: 'primary.main',
              bgcolor: 'primary.lighter',
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-evenly">
          <InfoItem
            className={styles.item}
            value={formatNumber(data.valuationSynthesis.moic, {
              precision: 2,
            })}
            description={t(
              'pages.fundManager.portfolio.valuationInstrument.card.moic',
            )}
            imageSource={<MoicIcon />}
            size="normal"
            sx={{
              color: palette.secondaryIconColor,
              bgcolor: palette.secondaryBackgroundIconColor,
            }}
          />
        </Stack>
      </InfoCard.Items>
    </InfoCard>
  );
};
