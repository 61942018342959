import classnames from 'classnames';
import React from 'react';

import { ReactDiv } from 'technical/type';

import styles from './index.module.scss';

interface SpaceProps extends ReactDiv {
  direction?: 'vertical' | 'horizontal';
  size?: 'small' | 'middle' | 'large';
  align?: 'center' | 'start' | 'end' | 'baseline';
  justify?:
    | 'start'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'end'
    | 'stretch';
}

/**
 * Generic Componant to space out and align children
 * We are not using https://ant.design/components/space/ as it wraps all children in new divs, creating unexpected behaviour with flex or blocks, and require to hack a 100% width on those newly created divs
 */
const Spacer: React.FC<SpaceProps> = ({
  children,
  direction = 'horizontal',
  size = 'middle',
  justify,
  align,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classnames(
        styles.spaceContainer,
        styles[direction],
        styles[`size-${size}`],
        align && styles[`align-${align}`],
        justify && styles[`justify-${justify}`],
        rest.className,
      )}
    >
      {children}
    </div>
  );
};

export default Spacer;
