import { calculateIrr } from 'business/lp-platform/valuations/services/calculate-irr';
import { ValuationGraphData } from 'business/shared/services/types';
import { GetValuationsQuery } from 'generated/graphql';

export function computeFundShareValuation(
  data: GetValuationsQuery,
  lastPoint: ValuationGraphData,
): {
  tvpi: number;
  dpi: number;
  netIrr: number;
} {
  const netIrr = calculateIrr(data.valuations);
  return {
    tvpi: lastPoint.dpi + lastPoint.rvpi,
    dpi: lastPoint.dpi,
    netIrr,
  };
}
