import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetOperationsQuery } from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { dateComparisonOperators } from 'technical/filter/operators';

export const dateColumn = (
  t: TFunction,
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'date',
    filterOperators: dateComparisonOperators,
    aggregable: false,
    headerName: t('pages.financialFlows.table.date'),
    valueFormatter: ({ value }) => value,
    valueGetter: ({ row: { date } }) => (date ? formatAsDate(date) : ''),
    groupingValueGetter: ({ row: { date } }) => {
      return formatAsDate(date);
    },
  };
};
