import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { getFieldName } from 'technical/get-field-name';
import { FormInputText } from 'ui/form/form-input-text';

export const FormBankDetailsPart = ({ name = '' }: { name?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormInputText
        label={t('components.bankDetails.name')}
        name={getFieldName('name', name)}
        required
      />
      <Stack direction="row" spacing={1}>
        <FormInputText
          fullWidth
          label={t('components.bankDetails.iban')}
          name={getFieldName('iban', name)}
          required
        />
        <FormInputText
          fullWidth
          label={t('components.bankDetails.bic')}
          name={getFieldName('bic', name)}
          required
        />
      </Stack>
      <FormInputText
        fullWidth
        label={t('components.bankDetails.comment')}
        name={getFieldName('comment', name)}
      />
    </>
  );
};
