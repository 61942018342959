import {
  areMultipleLpStored,
  getStoredLp,
} from 'business/lp-platform/nav-bar/services/lp-filters';
import { GetDashBoardInfosQuery } from 'generated/graphql';

export const getSelectedLpsData = (
  data: Pick<GetDashBoardInfosQuery, 'dataviz_lp'>,
) => {
  if (areMultipleLpStored()) {
    const selectedLpsNames = getStoredLp().map((lp) => lp.name);
    return data.dataviz_lp.filter((dt) => selectedLpsNames.includes(dt.lpName));
  } else {
    const selectedLpName = getStoredLp()[0];
    const selectedLp = data.dataviz_lp.find(
      ({ lpName }) => lpName === selectedLpName.name,
    );
    return selectedLp ? [selectedLp] : [];
  }
};
