import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { CopyToClipboardButton } from 'ui/copy-to-clipboard-button';

export interface MergedFieldProps {
  name: string;
  description: string;
}
export const MergeFieldItem = ({ name, description }: MergedFieldProps) => {
  const { t } = useTranslation();
  const mergeFieldName = `{{${name}}}`;
  return (
    <Stack>
      <Stack justifyContent="space-between" direction="row" padding={1}>
        <Stack spacing={1}>
          <Typography fontWeight="bold">{`{{${name}}}`}</Typography>
          <Typography>{description}</Typography>
        </Stack>
        <CopyToClipboardButton
          textToCopy={mergeFieldName}
          message={t('common.copyToClipBoard')}
        />
      </Stack>
      <Divider />
    </Stack>
  );
};
