import { Divider, Stack } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { getValuationProgress } from 'business/fund-manager/portfolio/valuation/services/get-valuation-progress';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  useGetLatestValuationsQuery,
  ValuationProgressEnum,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { InfoCard } from 'ui/info-card';
import { MainSecondaryItem } from 'ui/main-secondary-item';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

export const ValuationEditForm = () => {
  const { t } = useTranslation();
  const { fundId = '' } = useParams();

  const { data, loading, error } = useGetLatestValuationsQuery({
    variables: {
      fundId,
    },
    fetchPolicy: 'network-only',
  });

  if (
    loading ||
    error ||
    !data?.web_portal_valuation ||
    data.web_portal_valuation.length === 0
  ) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }
  // since we fetch by date desc, we can safely choose the valuation
  // at index 0
  const latestValuation = data.web_portal_valuation[0];

  const { pendingValuation, valuated } =
    latestValuation.companyInstrumentValuations
      .map((curr) => getValuationProgress(curr))
      .reduce(
        (acc, curr) => {
          if (curr === ValuationProgressEnum.Valuated) {
            acc.valuated += 1;
          }
          if (curr === ValuationProgressEnum.PendingValuation) {
            acc.pendingValuation += 1;
          }
          return acc;
        },
        {
          pendingValuation: 0,
          valuated: 0,
        },
      );

  return (
    <InfoCard>
      <InfoCard.Title
        path={FundManagerRoutes.PortfolioFundIdValuationId.replace(
          ':fundId',
          fundId,
        ).replace(':valuationId', latestValuation.id)}
      >
        {t('pages.fundManager.portfolio.valuation.edit.title')}
      </InfoCard.Title>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
        className={styles.cardBody}
      >
        <MainSecondaryItem
          main={formatAsDate(latestValuation.date)}
          secondary={t(
            'pages.fundManager.portfolio.valuation.edit.label.inProgress',
          )}
        />
        <MainSecondaryItem
          main={t('pages.fundManager.portfolio.valuation.edit.label.progress')}
          secondary={t(
            'pages.fundManager.portfolio.valuation.edit.count.progress',
            {
              number: Math.trunc(
                (valuated / (valuated + pendingValuation)) * 100,
              ),
            },
          )}
          alignItems="center"
        />
        <MainSecondaryItem
          main={t('pages.fundManager.portfolio.valuation.edit.label.valuated')}
          secondary={t(
            'pages.fundManager.portfolio.valuation.edit.count.security',
            { count: valuated },
          )}
          alignItems="center"
        />
      </Stack>
    </InfoCard>
  );
};
