import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { CreationPageContainer } from 'business/fund-manager/fund/components/creation-page-container';
import { FundBankForm } from 'business/fund-manager/fund/components/fund-bank-form';
import {
  FundBankDetails,
  FundCreationStepEnum,
} from 'business/fund-manager/fund/services/types';
import {
  isBankFormQuery,
  useBankForm,
} from 'business/fund-manager/fund/services/use-bank-form';
import { fundBankDetailsSchema } from 'business/fund-manager/fund/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { QueryStateDisplay } from 'ui/query-state-display';

type FundCreationBankStepFormProps = {
  previousPath: string;
  onSubmit: (input: FundBankDetails) => void;
  loading?: boolean;
  error?: ApolloError;
  defaultValues: FundBankDetails;
};

export const FundCreationBankStepForm = ({
  previousPath,
  defaultValues,
  onSubmit,
  loading,
  error,
}: FundCreationBankStepFormProps) => {
  const { control, handleSubmit } = useForm<FundBankDetails>({
    resolver: yupResolver<FundBankDetails>(fundBankDetailsSchema),
    defaultValues,
  });

  return (
    <CreationPageContainer
      activeStep={FundCreationStepEnum.Bank}
      onSubmit={!loading ? handleSubmit(onSubmit) : undefined}
      previousPath={previousPath}
    >
      <FundBankForm control={control} error={error} />
    </CreationPageContainer>
  );
};

export const FundCreationBankStep = () => {
  const { fundId = '' } = useParams();
  const navigate = useNavigate();

  const previousPath = FundManagerRoutes.FundCreateId.replace(
    ':fundId',
    fundId,
  );
  const bankForm = useBankForm({
    fundId,
    onCompleted: () => {
      navigate(FundManagerRoutes.FundCreateIdShare.replace(':fundId', fundId));
    },
  });

  if (isBankFormQuery(bankForm)) {
    const { loading, error } = bankForm;
    return (
      <CreationPageContainer
        activeStep={FundCreationStepEnum.Bank}
        previousPath={previousPath}
      >
        <QueryStateDisplay loading={loading} error={error} />
      </CreationPageContainer>
    );
  }

  const { onSubmit, saving, saveError, defaultValues } = bankForm;
  return (
    <FundCreationBankStepForm
      previousPath={previousPath}
      loading={saving}
      onSubmit={onSubmit}
      error={saveError}
      defaultValues={defaultValues}
    />
  );
};
