import { useTranslation } from 'translations/hook';

import { getFormattedMandatoryLpDocumentTypeWithDoc } from 'business/shared/lp-creation-module/services/get-formatted-lp-document-types-with-doc';
import { getLpDocumentTypeWithDocColumnDefinition } from 'business/shared/lp-creation-module/services/get-lp-document-type-with-doc-column-definition';
import { LpDocumentTypeWithDocument } from 'business/shared/lp-creation-module/services/types';
import {
  ClientTypeEnum,
  LpDocTypeWithDocumentQueryResult,
} from 'generated/graphql';
import { SimpleTable } from 'ui/simple-table/simple-table';

type LpDocumentationTypesTableListProps = {
  data: LpDocTypeWithDocumentQueryResult['data'];
  lpType: ClientTypeEnum;
  onDocumentAdd: (documentTypeId: string) => void;
};
export const LpDocumentationTypesTableList = ({
  data,
  lpType,
  onDocumentAdd,
}: LpDocumentationTypesTableListProps) => {
  const { t } = useTranslation();

  const formattedData: LpDocumentTypeWithDocument[] =
    getFormattedMandatoryLpDocumentTypeWithDoc(data, lpType, t);

  const headers = getLpDocumentTypeWithDocColumnDefinition({
    onSelect: onDocumentAdd,
  });

  return (
    <SimpleTable<LpDocumentTypeWithDocument>
      columnDefs={headers}
      rowData={formattedData}
      domLayout="autoHeight"
      defaultColDef={{
        flex: 1,
        minWidth: 150,
        sortable: false,
        resizable: true,
        filter: false,
        suppressMenu: true,
      }}
    />
  );
};
