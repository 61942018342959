export type PaletteColor = 'primary' | 'secondary' | 'tertiary';

export enum ContactFormContextEnum {
  EDIT = 'edit',
  ADD = 'add',
  VALIDATE = 'validate',
}

export interface Option<T extends string = string> {
  // id is the actual value sent back
  id: T;
  // value is the displayed value
  value: string;
}
