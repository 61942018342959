import { useWatch } from 'react-hook-form';
import { LanguageEnum } from 'translations/types';

import { useLanguageContext } from 'business/providers/language-provider';
import { Condition, LpCustomQuestionTypeEnum } from 'generated/graphql';
import {
  CustomQuestionTextTitle,
  isCustomQuestionTypeTitle,
} from 'technical/custom-questions';
import { Typo } from 'ui/typo';
import { TypoSize, TypoSizeEnum } from 'ui/typo/types';

import { shouldDisplayFormPart } from './custom-form-part.utils';

const getSize = (type: CustomQuestionTextTitle): TypoSize => {
  switch (type) {
    case LpCustomQuestionTypeEnum.H1:
      return TypoSizeEnum['4xl'];
    case LpCustomQuestionTypeEnum.H2:
      return TypoSizeEnum['3xl'];
    case LpCustomQuestionTypeEnum.H3:
      return TypoSizeEnum['2xl'];
    case LpCustomQuestionTypeEnum.H4:
      return TypoSizeEnum.xl;
    case LpCustomQuestionTypeEnum.H5:
      return TypoSizeEnum.lg;
    case LpCustomQuestionTypeEnum.H6:
      return TypoSizeEnum.m;
    default:
      return TypoSizeEnum.sm;
  }
};

interface CustomFormTextProps {
  type: LpCustomQuestionTypeEnum;
  labelEn: string;
  labelFr: string;
  condition: Condition | null;
}
export const CustomFormText = ({
  type,
  labelEn,
  labelFr,
  condition,
}: CustomFormTextProps) => {
  const { language } = useLanguageContext();

  const customAnswers = useWatch({ name: 'customAnswers' });

  if (condition && !shouldDisplayFormPart(condition, customAnswers)) {
    return null;
  }

  const label = language === LanguageEnum.en ? labelEn : labelFr;

  if (isCustomQuestionTypeTitle(type)) {
    return (
      <Typo size={getSize(type)} color="primary" component={type}>
        {label}
      </Typo>
    );
  } else if (type === LpCustomQuestionTypeEnum.P) {
    return (
      <Typo color="neutral" component={type}>
        {label}
      </Typo>
    );
  }

  throw new Error('Unknown custom question type');
};
