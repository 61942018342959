import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import LegalRepresentativeItem from 'business/lp-platform/home/components/legal-representative-item';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { GetDashBoardInfosQuery } from 'generated/graphql';
import { CloudIcon, PersonIcon } from 'ui/icons';
import { InfoCard } from 'ui/info-card';
import { InfoItem } from 'ui/info-item';

import styles from './index.module.scss';

interface Props {
  data: Pick<
    GetDashBoardInfosQuery,
    'legalRepresentatives' | 'contacts' | 'contactsWithLpPortalAccess'
  >;
}

const SingleLpContactInformation = ({ data }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  return (
    <InfoCard.Items>
      {data.legalRepresentatives.length ? (
        <LegalRepresentativeItem data={data.legalRepresentatives[0]} />
      ) : (
        <></>
      )}
      <Stack direction="row" className={styles.stack}>
        <InfoItem
          value={data.contacts.aggregate?.count.toString() ?? ''}
          description={t('pages.dashboard.cards.contacts.contactsNumber')}
          imageSource={<PersonIcon />}
          size="dense"
          sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}
        />
        <InfoItem
          value={
            data.contactsWithLpPortalAccess.aggregate?.count.toString() ?? ''
          }
          description={t('pages.dashboard.cards.contacts.portalAccessNumber')}
          imageSource={<CloudIcon />}
          size="dense"
          sx={{
            color: palette.secondaryIconColor,
            bgcolor: palette.secondaryBackgroundIconColor,
          }}
        />
      </Stack>
    </InfoCard.Items>
  );
};

export default SingleLpContactInformation;
