import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { useIsLpPlatform } from 'business/fund-manager/shared/services/hooks/use-is-lp-platform';
import { getCreationStepDetails } from 'business/shared/lp-creation-module/services/get-creation-steps-details';
import { useLpCreationModuleNavigate } from 'business/shared/lp-creation-module/services/hooks/use-lp-creation-module-navigate';
import { useLpCreationModuleNavigationContext } from 'business/shared/lp-creation-module/services/hooks/use-lp-creation-module-navigation-context';
import { useFeatureFlags } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import {
  ClientTypeEnum,
  LpCreationStepEnum,
  useLpOnboardingSubscriptionStateQuery,
  useLpQuery,
} from 'generated/graphql';
import { FormWizzard } from 'ui/form';
import { QueryStateDisplay } from 'ui/query-state-display';

import { DraftEditionStepsContext } from './context';

export interface DeclineReason {
  id: string;
  lpId: string;
  step: LpCreationStepEnum;
  comment: string;
}

interface DraftEditionStepsProps extends PropsWithChildren {
  lpId: string;
  clientType: ClientTypeEnum;
  declineReasons?: DeclineReason[];
}
const DraftEditionSteps = ({
  lpId,
  clientType,
  declineReasons,
  children,
}: DraftEditionStepsProps) => {
  const { previousPath: previousAppPath, finalPath: finalAppPath } =
    useLpCreationModuleNavigationContext();
  const navigateInModule = useLpCreationModuleNavigate();
  const navigateInApp = useNavigate();

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [displayLpInvestorProfileStep, displayLpContactsStep] = useFeatureFlags(
    [
      FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
      FeatureFlagEnum.DISPLAY_DIGITAL_ONBOARDING_CONTACT_SECTION,
    ],
  );

  const isLpPlatform = useIsLpPlatform();

  const { steps, activeStepIndex, previousStepPath, nextStepPath } =
    getCreationStepDetails({
      lpId,
      t,
      clientType,
      currentUrl: pathname,
      declineReasons,
      displayLpInvestorProfileStep,
      displayLpContactsStep: displayLpContactsStep || !isLpPlatform,
    });

  const onNextStep = () => {
    if (nextStepPath) {
      navigateInModule(nextStepPath);
      return;
    }

    if (finalAppPath) {
      navigateInModule(finalAppPath);
      return;
    }
    // no next path means creation ended so we go to previous app step
    navigateInApp(previousAppPath);
  };

  const onPreviousStep = () => {
    if (previousStepPath) {
      navigateInModule(previousStepPath);
      return;
    }

    return navigateInApp(previousAppPath);
  };

  return (
    <DraftEditionStepsContext.Provider
      value={{ steps, activeStepIndex, onNextStep, onPreviousStep }}
    >
      <Stack>
        <FormWizzard.Stepper steps={steps} activeStep={activeStepIndex} />
        {children}
      </Stack>
    </DraftEditionStepsContext.Provider>
  );
};

export const DraftEditionStepsProvider = ({ children }: PropsWithChildren) => {
  const { lpId = '', onboardingSubscriptionId } = useParams();

  const { data, loading, error } = useLpQuery({
    variables: {
      input: {
        id: lpId,
      },
    },
  });

  const {
    data: dataState,
    loading: loadingState,
    error: errorState,
  } = useLpOnboardingSubscriptionStateQuery({
    variables: { id: onboardingSubscriptionId ?? '' },
    skip: !onboardingSubscriptionId,
    fetchPolicy: 'network-only', // ignore cache
  });

  if (onboardingSubscriptionId && (errorState || loadingState || !dataState)) {
    return <QueryStateDisplay loading={loadingState} error={errorState} />;
  }

  if (loading || error || !data || !data.lp) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <DraftEditionSteps
      lpId={lpId}
      clientType={data.lp.client}
      declineReasons={
        dataState?.onboardingSubscriptionState.lpState.declineReasons
      }
    >
      {children}
    </DraftEditionSteps>
  );
};
