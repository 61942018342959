import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { DeclineConfirmationModal } from 'business/fund-manager/lp/components/decline-confirmation-modal';
import { getOnboardingSubscriptionValidationCard } from 'business/fund-manager/lp/services/validation-cards/get-onboarding-subscription-validation-card';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  OnboardingSubscriptionStateQuery,
  useDeclineOnboardingSubscriptionMutation,
  useValidateOnboardingSubscriptionMutation,
} from 'generated/graphql';
import { ValidationCard } from 'ui/validation-card';

export const OnboardingValidation = (
  props: NonNullable<
    OnboardingSubscriptionStateQuery['onboardingSubscriptionState']
  >,
) => {
  const { t } = useTranslation();
  const [confirmDecline, setConfirmDecline] = useState<boolean>(false);

  const subscriptionCard = getOnboardingSubscriptionValidationCard({
    data: props,
    t,
  });

  const [validate] = useValidateOnboardingSubscriptionMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.validateLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      enqueueSnackbar(t('errors.validateLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const [decline] = useDeclineOnboardingSubscriptionMutation({
    onCompleted: () => {
      setConfirmDecline(false);
      enqueueSnackbar(t('successMessage.declineLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
    onError: () => {
      setConfirmDecline(false);
      enqueueSnackbar(t('errors.declineLegalEntityGeneralInfos'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });
  const onValidate = () =>
    validate({ variables: { id: props.id } }).catch(() => undefined);

  const onDecline = ({ comment }: { comment: string }) =>
    decline({
      variables: {
        input: {
          id: props.id,
          comment,
        },
      },
    }).catch(() => undefined);

  return (
    <>
      <Grid item xs={4}>
        <ValidationCard
          {...subscriptionCard}
          onApprove={onValidate}
          onDecline={() => setConfirmDecline(true)}
          expand
        />
      </Grid>
      {confirmDecline ? (
        <DeclineConfirmationModal
          open
          onCancel={() => setConfirmDecline(false)}
          onSubmit={onDecline}
          title={t('common.actions.decline')}
        />
      ) : null}
    </>
  );
};
