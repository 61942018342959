import { Paper } from '@mui/material';
import classNames from 'classnames';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { LogOffIcon } from 'ui/icons';

import styles from './index.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

const LogOffButton = ({ className, onClick }: Props) => {
  const palette = usePalette();
  return (
    <Paper
      sx={{ borderRadius: 2 }}
      className={classNames(styles.buttonContainer, className)}
      onClick={onClick}
    >
      <LogOffIcon
        primaryColor={palette.primary[800]}
        secondaryColor={palette.primary[100]}
      />
    </Paper>
  );
};

export default LogOffButton;
