import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useTranslation } from 'translations/hook';

import { OnboardingSubscriptionRefetch } from 'business/fund-manager/onboarding-subscription/pages/onboarding-subscription-list/onboarding-subscription-list';
import { FormattedOnboardingSubscription } from 'business/fund-manager/onboarding-subscription/services/format-onboarding-subscriptions';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useRejectOnboardingSubscriptionMutation } from 'generated/graphql';
import { OnErrorDisplaySnackbar } from 'ui/error-snackbar/error-snackbar';

interface Props {
  data: FormattedOnboardingSubscription | undefined;
  refetch: OnboardingSubscriptionRefetch;
}

export const RejectOnboardingSubscriptionButton: FC<Props> = (props) => {
  const { data, refetch } = props;
  const { t } = useTranslation();

  const [rejectOnboardingSubscription] =
    useRejectOnboardingSubscriptionMutation({
      onCompleted: () => {
        refetch();
        enqueueSnackbar(t('successMessage.onboardingSubscriptionRejected'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: OnErrorDisplaySnackbar,
    });

  const { enqueueSnackbar } = useSnackbar();

  const doDeleteOS = useCallback(async () => {
    if (!data?.id) {
      throw new Error(
        'pages.fundManager.onboardingSubscriptions.errors.subscriptionNotFound',
      );
    }

    if (
      !confirm(t('pages.fundManager.onboardingSubscriptions.confirmDelete'))
    ) {
      return;
    }

    await rejectOnboardingSubscription({ variables: { id: data.id } });
  }, [data?.id, t, rejectOnboardingSubscription]);

  return (
    <Tooltip
      title={t('pages.fundManager.onboardingSubscriptions.tooltipDelete')}
      disableInteractive
    >
      <div className="flex items-center h-full">
        <IconButton
          aria-label={t('common.actions.delete')}
          onClick={doDeleteOS}
        >
          <CancelIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};
