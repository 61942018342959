import { Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { ValuationTab } from 'business/fund-manager/portfolio/valuation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { LinkTab } from 'ui/link-tab';

interface Props {
  activeTab: ValuationTab;
}

export const ValuationNavBar = ({ activeTab }: Props) => {
  const { fundId = '' } = useParams();
  const { t } = useTranslation();
  return (
    <Stack>
      <Tabs value={activeTab}>
        <LinkTab
          label={t('pages.fundManager.portfolio.valuation.tab.synthesis')}
          to={FundManagerRoutes.PortfolioFundIdValuation.replace(
            ':fundId',
            fundId,
          )}
        />
        <LinkTab
          label={t('pages.fundManager.portfolio.valuation.tab.history')}
          to={FundManagerRoutes.PortfolioFundIdValuationHistory.replace(
            ':fundId',
            fundId,
          )}
        />
      </Tabs>
    </Stack>
  );
};
