import { LpTableQuery } from 'generated/graphql';
import { isDefined } from 'technical/is-defined';
import { removeDuplicates } from 'technical/remove-duplicates';

import { LpsFilteredFields } from './types';

export function getLpCustomFilters(
  data: LpTableQuery | undefined,
): LpsFilteredFields {
  if (!data) {
    return {
      lpNames: [],
      legalRepresentatives: [],
    };
  }

  const mappedLegalRepresentatives = data.lps
    .map((lp) => {
      if (
        lp.legalRepresentative &&
        lp.legalRepresentative.subscribingEntityName
      ) {
        return {
          legalRepresentative: lp.legalRepresentative.subscribingEntityName,
        };
      }
      return null;
    })
    .filter(isDefined);

  return {
    lpNames: removeDuplicates(data.lps, 'lpView')
      .map((lp) => lp?.lpName)
      .filter((value): value is string => !!value),
    legalRepresentatives: removeDuplicates(
      mappedLegalRepresentatives,
      'legalRepresentative',
    ),
  };
}
