import { PropsWithChildren } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { getOnboardingSubscriptionGPStepIndex } from 'business/fund-manager/onboarding-subscription/services/get-onboarding-subscription-gp-step-index';
import { ONBOARDING_SUBSCRIPTION_GP_STEPS_COUNT } from 'business/fund-manager/onboarding-subscription/services/onboarding-subscription.constants';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import ConnectedPage from 'business/user/helpers/connected-page';
import { useOnboardingSubscriptionQuery } from 'generated/graphql';
import { convertPathToTranslationKey } from 'technical/convert-path-to-translation-key';
import { getPreviousUrlPath } from 'technical/get-previous-url-path';
import { QueryStateDisplay } from 'ui/query-state-display';

const rootPaths: string[] = [
  FundManagerRoutes.OnboardingSubscriptionRequested,
  FundManagerRoutes.OnboardingSubscriptionUnsuccessful,
  FundManagerRoutes.OnboardingSubscriptionHistory,
];

const getPreviousPath = (pathname: string) =>
  rootPaths.includes(pathname)
    ? FundManagerRoutes.Home
    : getPreviousUrlPath(pathname);

export const OnboardingSubscriptionPage = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { onboardingSubscriptionId } = useParams();
  const { pathname } = useLocation();
  const previousPath = getPreviousPath(pathname);

  const { data, loading, error } = useOnboardingSubscriptionQuery({
    variables: {
      id: onboardingSubscriptionId ?? '',
    },
  });

  const title = data
    ? t(`routes.${convertPathToTranslationKey(pathname)}`, {
        lpName: data.onboardingSubscription.lpView.lpName,
        fundName: data.onboardingSubscription.share.fund.name,
        shareName: data.onboardingSubscription.share.name,
        stepNumber: getOnboardingSubscriptionGPStepIndex(data.state.status),
        stepCount: ONBOARDING_SUBSCRIPTION_GP_STEPS_COUNT,
      })
    : undefined;

  return (
    <ConnectedPage title={title} path={previousPath}>
      {loading || error ? (
        <QueryStateDisplay loading={loading} error={error} />
      ) : (
        children
      )}
    </ConnectedPage>
  );
};

export const OnboardingSubscriptionsPage = ({
  children,
}: PropsWithChildren) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const previousPath = getPreviousPath(pathname);

  const title = t(`routes.${convertPathToTranslationKey(pathname)}`);

  return (
    <ConnectedPage title={title} path={previousPath}>
      {children}
    </ConnectedPage>
  );
};
