import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { ValuationCreationForm } from 'business/fund-manager/portfolio/valuation/components/valuation-creation-form';
import { ValuationEditForm } from 'business/fund-manager/portfolio/valuation/components/valuation-edit-form';
import { ValuationNavBar } from 'business/fund-manager/portfolio/valuation/components/valuation-nav-bar';
import { ValuationSynthesisCard } from 'business/fund-manager/portfolio/valuation/components/valuation-synthesis-card';
import { ValuationTab } from 'business/fund-manager/portfolio/valuation/services/types';
import {
  Web_Portal_ValuationStatus_Enum,
  useGetWebValuationsQuery,
} from 'generated/graphql';
import { EmptyCard } from 'ui/empty-card';
import { QueryStateDisplay } from 'ui/query-state-display';

export const PortfolioValuation = () => {
  const { t } = useTranslation();

  const { fundId = '' } = useParams();

  const { data, loading, error } = useGetWebValuationsQuery({
    variables: { fundId },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.web_portal_valuation) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (data.companyInstrument.length === 0) {
    return (
      <EmptyCard
        title={t('pages.fundManager.portfolio.valuation.missingSecurity.main')}
        subtitle={t(
          'pages.fundManager.portfolio.valuation.missingSecurity.secondary',
        )}
      />
    );
  }

  const hasValuationInProgress = data.web_portal_valuation.some(
    (valuation) =>
      valuation.status === Web_Portal_ValuationStatus_Enum.InProgress,
  );

  return (
    <>
      <ValuationNavBar activeTab={ValuationTab.Synthesis} />
      {hasValuationInProgress ? (
        <ValuationEditForm />
      ) : (
        <ValuationCreationForm />
      )}
      <ValuationSynthesisCard />
    </>
  );
};
