import { createBrowserRouter } from 'react-router-dom';

import { fundManagerRoutes } from 'business/fund-manager/router/services';
import { lpPlatformRoutes } from 'business/lp-platform/router/services';
import { sharedRoutesProps } from 'business/shared/router/services';

export const router = createBrowserRouter([
  ...lpPlatformRoutes,
  ...fundManagerRoutes,
  ...sharedRoutesProps,
]);
