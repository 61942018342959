import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { InstrumentValuationModal } from 'business/fund-manager/portfolio/valuation/components/instrument-valuation-modal';
import { ValuationNavBar } from 'business/fund-manager/portfolio/valuation/components/valuation-nav-bar';
import { getValuationHistoryColumnDefinition } from 'business/fund-manager/portfolio/valuation/services/get-valuation-history-column-definition';
import { ValuationTab } from 'business/fund-manager/portfolio/valuation/services/types';
import {
  GetPortfolioValuationDetailsQuery,
  useGetPortfolioValuationDetailsQuery,
} from 'generated/graphql';
import useModal from 'technical/modal/use-modal';
import { AlternativeTable } from 'ui/alternative-table';
import { CustomModal } from 'ui/custom-modal';
import { QueryStateDisplay } from 'ui/query-state-display';

export type PortfolioInstrumentValuation =
  GetPortfolioValuationDetailsQuery['instrumentValuations'][number];

export const PortfolioValuationHistory = () => {
  const { t } = useTranslation();
  const { fundId = '' } = useParams();

  const { data, loading, error } = useGetPortfolioValuationDetailsQuery({
    variables: { fundId },
    fetchPolicy: 'network-only',
  });

  const [instrumentIds, setInstrumentIds] = useState<
    | {
        instrumentValuationId: string;
        companyInstrumentId: string;
      }
    | undefined
  >();

  const { open, handleOpenModal, handleCloseModal } = useModal();

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const handleOpenInstrumenValuationModal = (ids: {
    instrumentValuationId: string;
    companyInstrumentId: string;
  }) => {
    setInstrumentIds(ids);
    handleOpenModal();
  };

  const headers = getValuationHistoryColumnDefinition({
    t,
    handleOpenModal: handleOpenInstrumenValuationModal,
  });
  return (
    <>
      <ValuationNavBar activeTab={ValuationTab.History} />
      <AlternativeTable<PortfolioInstrumentValuation>
        suppressAggFuncInHeader={true}
        hideSearchbar
        fileName="instrument-valuations"
        rowData={data ? data.instrumentValuations : []}
        columnDefs={headers}
        domLayout="autoHeight"
        displaySidebar
        loading={loading}
        expandable
      />
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <InstrumentValuationModal
            instrumentValuationId={instrumentIds?.instrumentValuationId ?? ''}
            companyInstrumentId={instrumentIds?.companyInstrumentId ?? ''}
            handleClose={handleCloseModal}
            isEdition={true}
          />
        }
      />
    </>
  );
};
