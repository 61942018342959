// eslint-disable-next-line import/no-extraneous-dependencies
import { enqueueSnackbar } from 'notistack';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CreateOperationForm } from 'business/fund-manager/operation/components/create-operation-form';
import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import { getOperationDraftValues } from 'business/fund-manager/operation/services/get-operation-draft-values';
import {
  OperationCreationStepEnum,
  UpdateOperationInput,
} from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  Web_Portal_OperationStatus_Enum,
  useGetOperationsDraftDetailsQuery,
  useUpdateOperationMutation,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const OperationDraftEdition = () => {
  const { t } = useTranslation();
  const { operationId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetOperationsDraftDetailsQuery({
    variables: { operationId },
    fetchPolicy: 'network-only',
  });

  const [updateOperation, { loading: saveLoading, error: saveError }] =
    useUpdateOperationMutation({
      ignoreResults: false,
      onCompleted: (updated) => {
        navigate(
          FundManagerRoutes.OperationCreateIdShares.replace(
            ':operationId',
            updated.updateOperation.id,
          ),
        );
        enqueueSnackbar(t('successMessage.updateOperation'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
    });

  const onSubmit = async (input: UpdateOperationInput) => {
    await updateOperation({
      variables: {
        input: { ...input, operationId },
      },
    });
  };

  if (loading || error || !data?.web_portal_operation) {
    return (
      <OperationCreationContainer
        activeStep={OperationCreationStepEnum.Details}
        onPrevious={() => navigate(FundManagerRoutes.Operations)}
      >
        <QueryStateDisplay loading={loading} error={error} />;
      </OperationCreationContainer>
    );
  }

  const operationStatus = data.web_portal_operation[0].status;

  if (operationStatus !== Web_Portal_OperationStatus_Enum.Draft) {
    return (
      <Navigate
        to={FundManagerRoutes.OperationId.replace(':operationId', operationId)}
      />
    );
  }

  return (
    <CreateOperationForm
      onSubmit={onSubmit}
      loading={saveLoading}
      error={saveError}
      defaultValues={getOperationDraftValues(data.web_portal_operation)}
    />
  );
};
