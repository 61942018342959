import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

interface Props {
  open: boolean;
  handleClose: () => void;
  text: {
    main: string;
    secondary: string;
  };
}

export default function BasicModal({ open, handleClose, text }: Props) {
  const palette = usePalette();
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={styles.modalBox}
          onClick={handleClose}
          sx={{ bgcolor: palette.backgroundForm }}
        >
          <Typography
            className={styles.modalTitle}
            id="modal-modal-title"
            variant="h6"
          >
            {text.main}
          </Typography>
          <Typography
            className={styles.modalTitle}
            id="modal-modal-description"
          >
            {text.secondary}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
