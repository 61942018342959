import { ApolloError } from '@apollo/client';
import { Divider, Stack, Tab, Tabs } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { InstrumentValuation } from 'business/fund-manager/portfolio/valuation/components/instrument-valuation';
import { CompanyInstrumentProceed } from 'business/fund-manager/portfolio/valuation/components/instrument-valuation-proceed';
import { InstrumentValuationTabEnum } from 'business/fund-manager/portfolio/valuation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import {
  useDeleteValuationMutation,
  useGetInstrumentValuationDetailsQuery,
} from 'generated/graphql';
import { formatAsLocaleDate } from 'technical/date';
import { ActionButton } from 'ui/action-button';
import { ModalHeader } from 'ui/custom-modal/header';
import { MainSecondaryItem } from 'ui/main-secondary-item';
import { QueryStateDisplay } from 'ui/query-state-display';

import styles from './index.module.scss';

const InstrumentValuationTabs = ({
  activeTab,
  handleTabChange,
}: {
  activeTab: InstrumentValuationTabEnum;
  handleTabChange: (
    _event: React.SyntheticEvent,
    tab: InstrumentValuationTabEnum,
  ) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab
        value={InstrumentValuationTabEnum.Valuation}
        label={t(
          'pages.fundManager.portfolio.valuationInstrument.tab.edit_valuation',
        )}
      />
      <Tab
        value={InstrumentValuationTabEnum.ProceedForm}
        label={t(
          'pages.fundManager.portfolio.valuationInstrument.tab.edit_proceed',
        )}
      />
    </Tabs>
  );
};

interface Props {
  instrumentValuationId: string;
  companyInstrumentId: string;
  handleClose: () => void;
  isEdition?: boolean;
}

export const InstrumentValuationModal = ({
  instrumentValuationId,
  companyInstrumentId,
  handleClose,
  isEdition = false,
}: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  const { data, loading, error } = useGetInstrumentValuationDetailsQuery({
    variables: { instrumentValuationId, companyInstrumentId },
    fetchPolicy: 'network-only',
  });

  const [activeTab, setActiveTab] = useState(
    InstrumentValuationTabEnum.Valuation,
  );

  const handleTabChange = (
    _event: React.SyntheticEvent,
    tab: InstrumentValuationTabEnum,
  ) => {
    setActiveTab(tab);
  };
  const formatedDate = formatAsLocaleDate(
    data?.instrumentValuation?.valuation?.date
      ? data.instrumentValuation.valuation.date
      : '',
  );
  if (
    error ||
    loading ||
    !data?.instrumentValuation ||
    !data?.instrumentValuation.companyInstrument ||
    !data?.instrumentValuation.companyInstrument.company
  ) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }

  return (
    <Stack
      sx={{ backgroundColor: palette.backgroundForm }}
      spacing={2}
      className={styles.modal}
    >
      <ModalHeader
        labelClose={t(
          'pages.fundManager.portfolio.valuationInstrument.form.close.button',
        )}
        title={t('pages.fundManager.portfolio.valuation.edit.modalTitle', {
          date: formatedDate,
        })}
        onClose={handleClose}
      />
      <Stack direction="row" padding={3} spacing={2}>
        <MainSecondaryItem
          alignItems="center"
          className={styles.container}
          mainClassName={styles.title}
          mainVariant="overline"
          main={t(
            'pages.fundManager.portfolio.valuationInstrument.labels.company',
          )}
          secondary={data.instrumentValuation.companyInstrument.company.name}
        />
        <MainSecondaryItem
          alignItems="center"
          className={styles.container}
          mainClassName={styles.title}
          mainVariant="overline"
          main={t(
            'pages.fundManager.portfolio.valuationInstrument.labels.identifier',
          )}
          secondary={data.instrumentValuation.companyInstrument.identifier}
        />
      </Stack>
      <Divider />
      <Stack justifyContent="center" direction="row">
        <InstrumentValuationTabs
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </Stack>

      {activeTab === InstrumentValuationTabEnum.ProceedForm ? (
        <CompanyInstrumentProceed companyInstrumentId={companyInstrumentId} />
      ) : null}
      {activeTab === InstrumentValuationTabEnum.Valuation ? (
        <InstrumentValuation
          instrumentValuationId={instrumentValuationId}
          data={data}
          handleClose={handleClose}
        />
      ) : null}
      {isEdition && data.instrumentValuation.valuation ? (
        <DeletePortfolioValuation
          valuation={data.instrumentValuation.valuation}
          handleClose={handleClose}
        />
      ) : null}
    </Stack>
  );
};

export const DeletePortfolioValuation = ({
  valuation,
  handleClose,
}: {
  valuation: {
    id: string;
    date: string;
  };
  handleClose?: () => void;
}) => {
  const navigate = useNavigate();
  const { fundId = '' } = useParams();
  const { t } = useTranslation();
  const formatedDate = formatAsLocaleDate(valuation.date);
  const [deleteValuation, { loading: loadingDelete }] =
    useDeleteValuationMutation({
      refetchQueries: [
        'getPortfolioValuationDetails',
        'getPortfolioInventoryDetails',
      ],
      onCompleted: () => {
        if (handleClose) {
          handleClose();
        }
        navigate(
          FundManagerRoutes.PortfolioFundIdValuationHistory.replace(
            ':fundId',
            fundId,
          ),
        );
        enqueueSnackbar(t('successMessage.deleteCompanyTransaction'), {
          variant: VariantTypeEnum.SUCCESS,
        });
      },
      onError: (err: ApolloError) => {
        enqueueSnackbar(err.message, {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const handleClickDelete = useCallback(async () => {
    if (
      !confirm(
        t(
          'pages.fundManager.portfolio.valuation.edit.modalConfirmationDeleteValuation',
          {
            date: formatedDate,
          },
        ),
      )
    ) {
      return;
    }

    deleteValuation({
      variables: {
        id: valuation.id,
      },
    }).catch(() => undefined);
  }, [formatedDate, valuation, t, deleteValuation]);

  if (valuation === null || valuation === undefined) {
    return null;
  }

  return (
    <ActionButton
      size="small"
      variant="danger"
      onClick={handleClickDelete}
      loading={loadingDelete}
    >
      {t('pages.fundManager.portfolio.valuation.edit.modalDeleteValuation', {
        date: formatedDate,
      })}
    </ActionButton>
  );
};
