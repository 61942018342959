import { Navigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  OnboardingSubscriptionStatusEnum,
  OnboardingSubscriptionTypeEnum,
  useOnboardingSubscriptionQuery,
} from 'generated/graphql';
import { convertFrom10X8toNumber } from 'technical/currency/formatters';
import { QueryStateDisplay } from 'ui/query-state-display';

import { LpCreationRedirect } from './components/lp-creation-redirect';
import { OnboardingSubscriptionCompletionForm } from './components/onboarding-subscription-completion-form';
import { OnboardingSubscriptionAmount } from './onboarding-subscription-completion.types';

export const OnboardingSubscriptionCompletion = () => {
  const { t } = useTranslation();
  const { onboardingSubscriptionId = '' } = useParams();
  const displaySignature = useFeatureFlag(FeatureFlagEnum.DISPLAY_SIGNATURE);

  const { loading, data, error } = useOnboardingSubscriptionQuery({
    variables: {
      id: onboardingSubscriptionId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.onboardingSubscription) {
    return (
      <ConnectedPage>
        <QueryStateDisplay loading={loading} error={error} />
      </ConnectedPage>
    );
  }

  const { share, bankDetails, id, lpView, declineComment, ...rest } =
    data.onboardingSubscription;
  const lpState = data.state.lpState;

  if (
    rest.status === OnboardingSubscriptionStatusEnum.InvitationSent ||
    rest.status === OnboardingSubscriptionStatusEnum.ProfileDeclined
  ) {
    return (
      <LpCreationRedirect
        lpId={lpView.id}
        onboardingSubscriptionId={id}
        clientType={lpState.client}
        declineReasons={lpState.declineReasons}
      />
    );
  }

  if (
    rest.status === OnboardingSubscriptionStatusEnum.WaitingForLpSignature &&
    displaySignature
  ) {
    return (
      <Navigate
        to={LpPlatformRoutes.LpOnboardingSubscriptionCreateIdSign.replace(
          ':onboardingSubscriptionId',
          id,
        )}
      />
    );
  }

  if (
    rest.status !== OnboardingSubscriptionStatusEnum.ProfileCompleted &&
    rest.status !== OnboardingSubscriptionStatusEnum.SubInfoDeclined
  ) {
    return <Navigate to={LpPlatformRoutes.Home} />;
  }

  const amountProps: OnboardingSubscriptionAmount =
    rest.type === OnboardingSubscriptionTypeEnum.Fixed
      ? {
          type: OnboardingSubscriptionTypeEnum.Fixed,
          amount: convertFrom10X8toNumber(rest.numericAmount),
        }
      : {
          type: OnboardingSubscriptionTypeEnum.Range,
          amountMin: convertFrom10X8toNumber(rest.numericAmountMin),
          amountMax: convertFrom10X8toNumber(rest.numericAmountMax),
        };

  return (
    <ConnectedPage
      path={LpPlatformRoutes.Home}
      title={t('pages.onboardingSubscription.form.title', {
        fund: share.fund.name,
        share: share.name,
      })}
    >
      <OnboardingSubscriptionCompletionForm
        {...amountProps}
        id={id}
        fundName={share.fund.name}
        currency={share.fund.currency}
        defaultBankDetails={bankDetails ?? undefined}
        declineComment={declineComment ?? undefined}
      />
    </ConnectedPage>
  );
};
