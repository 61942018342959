import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { FundReportingCreation } from 'business/fund-manager/fund/services/types';
import { fundReportingCreationSchema } from 'business/fund-manager/fund/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useOnFilesUpload } from 'technical/file-management/use-on-files-upload';

import { useUploadReportingDocument } from './use-fund-reporting-document-upload';

export const useFundReportingCreationForm = (fundId: string) => {
  const methods = useForm<FundReportingCreation>({
    resolver: yupResolver<FundReportingCreation>(fundReportingCreationSchema),
    defaultValues: {
      fundId,
    },
  });

  const onFilesUpload = useOnFilesUpload({
    getFiles: () => methods.getValues('files') ?? [],
  });

  const navigate = useNavigate();

  const { uploadReportingDocument, loading } = useUploadReportingDocument(
    () => {
      navigate(FundManagerRoutes.FundId.replace(':fundId', fundId));
    },
  );

  const onSubmit = (input: FundReportingCreation) => {
    return uploadReportingDocument(input).catch(undefined);
  };

  return {
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
    onFilesUpload,
    loading,
  };
};
