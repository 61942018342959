import { ArrowForward } from '@mui/icons-material';
import { Divider, IconButton, Stack } from '@mui/material';
import classNames from 'classnames';
import { Children, ComponentProps, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'ui/card';
import { Info, InfoItem } from 'ui/info-item';
import { PendingChip } from 'ui/pending-chip';

import styles from './index.module.scss';

interface Props extends Pick<ComponentProps<typeof Card>, 'sx'> {
  className?: string;
  children?: ReactNode[] | ReactNode;
  dataTestId?: string;
}

interface TitleProps {
  children?: string | ReactNode;
  path?: string;
  chipContent?: number;
}

interface FooterProps {
  children?: string | ReactNode;
}

interface DataProps {
  data: Info[];
}

interface ItemsProps {
  children: ReactElement[] | ReactElement;
  size?: 'l' | 's' | 'auto';
  noPaddingTop?: boolean;
}

export function InfoCard({ children, ...rest }: Props) {
  return <Card {...rest}>{children}</Card>;
}

const Title = ({ children, path, chipContent }: TitleProps) => {
  return (
    <>
      <Stack direction="row" className={styles.titleBar}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <h6 className={styles.title}>{children}</h6>
          {chipContent ? <PendingChip label={chipContent} /> : null}
        </Stack>

        {path ? (
          <IconButton component={Link} to={path} color="primary">
            <ArrowForward />
          </IconButton>
        ) : null}
      </Stack>
      <Divider light={true} />
    </>
  );
};

const Data = ({ data }: DataProps) => (
  <div className={styles.content}>
    {data?.map((d) => (
      <InfoItem
        value={d.value}
        imageSource={d.imageSource}
        variant={d.variant}
        key={d.key}
        description={d.description}
      />
    ))}
  </div>
);

const Items = ({ children, size = 's', noPaddingTop }: ItemsProps) => {
  const mappedChildren = Array.isArray(children) ? children : [children];
  return (
    <div
      className={classNames(
        styles.content,
        noPaddingTop ? styles.noPaddingTop : {},
      )}
    >
      {Children.map(mappedChildren, (child, i) => (
        <div key={`${child.key}-container`}>
          <div
            className={classNames(styles.child, {
              [styles.largeContent]: size === 'l',
              [styles.smallContent]: size === 's',
            })}
          >
            {child}
          </div>
          {i !== mappedChildren.length - 1 ? <Divider light={true} /> : null}
        </div>
      ))}
    </div>
  );
};

const Footer = ({ children }: FooterProps) => {
  return (
    <>
      <Divider light={true} />
      <Stack direction="row" className={styles.footer}>
        <Stack spacing={1} className={styles.footerInner}>
          {children}
        </Stack>
      </Stack>
    </>
  );
};

InfoCard.Title = Title;
InfoCard.Data = Data;
InfoCard.Items = Items;
InfoCard.Footer = Footer;
