import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FundProfile } from 'business/fund-manager/fund/services/types';
import { FundStageEnum } from 'generated/graphql';
import { FormSection, FormInputDropdown, FormInputText } from 'ui/form';
import Spacer from 'ui/spacer';

import styles from './index.module.scss';

export const InvestmentFocusForm = () => {
  const { control } = useFormContext<FundProfile>();
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('pages.fundManager.fundCreation.form.investmentFocus.title')}
    >
      <FormInputDropdown
        name="stage"
        label={t(
          'pages.fundManager.fundCreation.form.investmentFocus.stage.label',
        )}
        placeholder={t(
          'pages.fundManager.fundCreation.form.investmentFocus.stage.placeholder',
        )}
        control={control}
        options={Object.values(FundStageEnum).map((id) => ({
          id,
          value: t(
            `pages.fundManager.fundCreation.form.investmentFocus.stage.values.${id}`,
          ),
        }))}
      />

      <Spacer justify="stretch">
        <FormInputText
          className={styles.grow}
          name="sector"
          label={t(
            'pages.fundManager.fundCreation.form.investmentFocus.sector.label',
          )}
          placeholder={t(
            'pages.fundManager.fundCreation.form.investmentFocus.sector.placeholder',
          )}
          control={control}
        />

        <FormInputText
          className={styles.grow}
          name="geography"
          label={t(
            'pages.fundManager.fundCreation.form.investmentFocus.geography.label',
          )}
          placeholder={t(
            'pages.fundManager.fundCreation.form.investmentFocus.geography.placeholder',
          )}
          control={control}
        />
      </Spacer>
    </FormSection>
  );
};
