import { toString } from 'lodash';
import { FC, PropsWithChildren, createContext, useContext } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { GetFundSharesByOperationIdQuery } from 'generated/graphql';

import { CommitmentsForSummary } from './op-shares-config-table';
import { calcNumberOfShares } from './use-fetch-share-commitments';

export type OpCreationShareInput =
  GetFundSharesByOperationIdQuery['web_portal_share'][number];

export function useWatchNum(name: string) {
  return toNum(useWatch({ name }));
}

export function useWatchMultipleNum(names: string[]) {
  const { watch } = useFormContext();
  return names.map((name) => toNum(watch(name)));
}

export function toNum(str: string | number | null | undefined) {
  if (str == null) {
    return 0;
  }
  if (typeof str === 'number') {
    return str;
  }
  if (typeof str !== 'string') {
    throw new Error(`${JSON.stringify(str)} field value is not a string`);
  }
  const num = str === '' ? 0 : +str;
  if (isNaN(num)) {
    throw new Error(`${JSON.stringify(str)} field value is not a number`);
  }
  return num;
}

export function toStr(value: any, decimals?: number) {
  return toString(
    decimals != null && typeof value === 'number'
      ? round(value, decimals)
      : value,
  );
}

function round(num: number, decimals: number) {
  return Math.round(num * 10 ** decimals) / 10 ** decimals;
}

// Sum all numbers in the given array
export function sum(numbers: number[]) {
  return numbers.reduce((acc, num) => acc + num, 0);
}

// Commitments for summary provider

const SharesContext = createContext<OpCreationShareInput[] | null>(null);
const CommitmentsContext = createContext<CommitmentsForSummary | null>(null);
const TotalNumberOfSharesContext = createContext<number | null>(null);
const TotalCommitmentContext = createContext<number | null>(null);

export function useCommitments() {
  const data = useContext(CommitmentsContext);
  if (data == null) {
    throw new Error('useCommitments must be used within a SharesTableProvider');
  }
  return data;
}

export function useTotalNumberOfShares() {
  const data = useContext(TotalNumberOfSharesContext);
  if (data == null) {
    throw new Error(
      'useTotalNumberOfShares must be used within a SharesTableProvider',
    );
  }
  return data;
}

export function useTotalCommitment() {
  const data = useContext(TotalCommitmentContext);
  if (data == null) {
    throw new Error(
      'useTotalCommitment must be used within a SharesTableProvider',
    );
  }
  return data;
}

export function useShares() {
  const data = useContext(SharesContext);
  if (data == null) {
    throw new Error('useShares must be used within a SharesTableProvider');
  }
  return data;
}

interface SharesTableProviderProps extends PropsWithChildren {
  shares: OpCreationShareInput[];
  commitments: CommitmentsForSummary;
}

export const SharesTableProvider: FC<SharesTableProviderProps> =
  function OperationShareDataProvider({ children, shares, commitments }) {
    const totalNumberOfShares = Object.values(commitments).reduce(
      (acc, { commitment, nominalValue }) =>
        acc + calcNumberOfShares(commitment, nominalValue),
      0,
    );
    const totalCommitment = Object.values(commitments).reduce(
      (acc, { commitment }) => acc + commitment,
      0,
    );
    return (
      <SharesContext.Provider value={shares}>
        <CommitmentsContext.Provider value={commitments}>
          <TotalNumberOfSharesContext.Provider value={totalNumberOfShares}>
            <TotalCommitmentContext.Provider value={totalCommitment}>
              {children}
            </TotalCommitmentContext.Provider>
          </TotalNumberOfSharesContext.Provider>
        </CommitmentsContext.Provider>
      </SharesContext.Provider>
    );
  };
