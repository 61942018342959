import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { ValuationCreationGraph } from 'business/lp-platform/valuations/components/valuation-creation-graph';
import { ValuationProgressionGraph } from 'business/lp-platform/valuations/components/valuation-progression-graph';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ValuationGraphDataByType } from 'business/shared/services/types';
import LegendItem from 'ui/legend-item';

import styles from './index.module.scss';

interface Props {
  data: ValuationGraphDataByType;
}

const GraphsPanel = ({ data }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Stack data-test-id="valuation-graphs-panel" spacing={2}>
      <Box sx={{ bgcolor: palette.backgroundForm }} className={styles.box}>
        <Stack direction={'row'} className={styles.titleBar}>
          <h6 className={styles.title}>
            {t('pages.fundShareValuations.graphs.valueCreation.title')}
          </h6>
          <Stack direction={'row'} spacing={2}>
            <LegendItem
              sx={{ color: 'secondary.light' }}
              legend={t('pages.fundShareValuations.tvpi')}
            />
            <LegendItem
              color="primary"
              legend={t('pages.fundShareValuations.rvpi')}
            />
            <LegendItem
              color="secondary"
              legend={t('pages.fundShareValuations.dpi')}
            />
          </Stack>
        </Stack>
        <Divider light />
        <div className={styles.graphContainer}>
          <ValuationCreationGraph
            valuationCreationData={data}
            fileName={t(
              'pages.fundShareValuations.graphs.valueCreation.fileName',
            )}
          />
        </div>
      </Box>
      <Box sx={{ bgcolor: palette.backgroundForm }} className={styles.box}>
        <Stack direction={'row'} className={styles.titleBar}>
          <h6 className={styles.title}>
            {t('pages.fundShareValuations.graphs.valueProgression.title')}
          </h6>
          <Stack direction={'row'} spacing={2}>
            <LegendItem
              color="primary"
              legend={t(
                'pages.fundShareValuations.graphs.valueProgression.legend.paidIn',
              )}
            />
            <LegendItem
              color="secondary"
              legend={t(
                'pages.fundShareValuations.graphs.valueProgression.legend.totalDistributed',
              )}
            />
            <LegendItem
              sx={{ color: 'secondary.light' }}
              legend={t(
                'pages.fundShareValuations.graphs.valueProgression.legend.residual',
              )}
            />
          </Stack>
        </Stack>
        <Divider light />
        <div className={styles.graphContainer}>
          <ValuationProgressionGraph
            progressionGraphData={data}
            fileName={t(
              'pages.fundShareValuations.graphs.valueProgression.fileName',
            )}
          />
        </div>
      </Box>
    </Stack>
  );
};

export default GraphsPanel;
