import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useDeleteLastFundShareValuationMutation } from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { ActionButton } from 'ui/action-button';

interface Props {
  valuationId: string;
  disabled?: boolean;
}

export const DeleteLastShareValuationButton: FC<Props> = (props) => {
  const { valuationId, disabled = false } = props;
  const { t, i18n } = useTranslation();

  const { fundId = '' } = useParams();
  const navigate = useNavigate();
  const [deleteLastFundShareValuation] =
    useDeleteLastFundShareValuationMutation({
      onCompleted: () => {
        enqueueSnackbar(t('successMessage.shareValuationDeleted'), {
          variant: VariantTypeEnum.SUCCESS,
        });
        navigate(
          FundManagerRoutes.FundIdShareValuation.replace(':fundId', fundId),
        );
      },
      onError: (error: ApolloError) => {
        const errorMsg = i18n.exists(error?.message)
          ? t(error?.message)
          : t(ValidationErrors.GENERIC);
        enqueueSnackbar(errorMsg, {
          variant: VariantTypeEnum.ERROR,
        });
      },
    });

  const { enqueueSnackbar } = useSnackbar();

  const deleteLastShareValuation = useCallback(async () => {
    if (!valuationId) {
      throw new Error(
        'pages.fundManager.fundShareValuationCreation.form.error-valuationNotFound',
      );
    }
    if (
      !confirm(
        t(
          'pages.fundManager.fundShareValuationCreation.form.confirmDeleteShareValuation',
        ),
      )
    ) {
      return;
    }

    await deleteLastFundShareValuation({ variables: { valuationId } });
  }, [valuationId, t, deleteLastFundShareValuation]);

  return (
    // If disabled, display a tooltip with the reason

    <ActionButton
      variant="danger"
      onClick={deleteLastShareValuation}
      disabled={disabled}
      tooltip={
        disabled
          ? t(
              'pages.fundManager.fundShareValuationCreation.form.disabledTooltip',
            )
          : ''
      }
    >
      {t(
        'pages.fundManager.fundShareValuationCreation.form.deleteShareValuationButton',
      )}
    </ActionButton>
  );
};
