import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ColumnHeaderWithSubHeaderProps } from 'ui/column-header-with-sub-header/types';

import styles from './index.module.scss';

export function ColumnHeaderWithSubHeader({
  headerName,
  subHeaderName,
  subHeaderElement,
  aggregationFunctionName,
}: ColumnHeaderWithSubHeaderProps) {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Stack className={styles.stack}>
      <Stack>
        <Typography className={styles.header} variant="body2">
          {headerName}
        </Typography>
      </Stack>
      {subHeaderName ? (
        <Stack>
          <Typography
            className={styles.header__subHeader}
            variant="body2"
            sx={{ color: palette.primary[800] }}
          >
            {subHeaderName}
          </Typography>
        </Stack>
      ) : null}
      {subHeaderElement ? subHeaderElement : null}
      {aggregationFunctionName ? (
        <Typography
          className={styles.aggregationFunctionName}
          variant="body2"
          sx={{ color: palette.primary[900] }}
        >
          {t(`aggregations.${aggregationFunctionName}`)}
        </Typography>
      ) : null}
    </Stack>
  );
}
