import { Avatar, Stack, SxProps } from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import {
  PaletteVarKey,
  ThemePalette,
} from 'business/providers/theme/services/types';

import styles from './index.module.scss';

type IconColor = 'primary' | 'secondary' | 'neutral' | 'danger';

export interface Props extends PropsWithChildren {
  size: 'dense' | 'normal' | 'large';
  imageSource: ReactElement | string;
  variant: 'rounded' | 'circular';
  className?: string;
  iconColor?: IconColor;
  sx?: SxProps;
}

const spacingMap: Record<'dense' | 'normal' | 'large', number> = {
  dense: 1.5,
  normal: 3,
  large: 4,
};

const iconColors: Record<
  IconColor,
  Record<'color' | 'bgcolor', PaletteVarKey>
> = {
  primary: { color: 'primaryMain', bgcolor: 'primaryLighter' },
  secondary: {
    color: 'secondaryIconColor',
    bgcolor: 'secondaryBackgroundIconColor',
  },
  neutral: {
    color: 'neutralIconColor',
    bgcolor: 'neutralBackGroundIconColor',
  },
  danger: {
    color: 'dangerMain',
    bgcolor: 'dangerLighter',
  },
};

const getIconSx = ({
  sx = {},
  iconColor,
  palette,
}: {
  sx?: SxProps;
  iconColor?: IconColor;
  palette: ThemePalette;
}) => ({
  ...(iconColor
    ? {
        color: palette[iconColors[iconColor].color],
        bgcolor: palette[iconColors[iconColor].bgcolor],
      }
    : {}),
  ...sx,
});

export const InfoItemContainer = ({
  size,
  className,
  imageSource,
  variant,
  iconColor,
  sx,
  children,
}: Props) => {
  const palette = usePalette();
  const iconSx = getIconSx({ sx, iconColor, palette });

  const spacing = spacingMap[size];

  const icon =
    typeof imageSource === 'string' ? (
      <Avatar
        variant={variant}
        src={imageSource}
        className={styles[variant]}
        sx={iconSx}
      />
    ) : (
      <Avatar variant={variant} sx={iconSx} className={styles[variant]}>
        {imageSource}
      </Avatar>
    );

  return (
    <Stack
      spacing={spacing}
      direction="row"
      className={classNames(styles.stack, className)}
      alignItems="center"
    >
      {icon}
      {children}
    </Stack>
  );
};
