import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { sumBy } from 'lodash';
import { TFunction } from 'translations/hook';

import { PortfolioInstrumentValuation } from 'business/fund-manager/portfolio/valuation/pages/portfolio-valuation-history';
import { Statuses } from 'business/lp-platform/portfolio/enum';
import {
  GetPortfolioValuationDetailsQuery,
  WebCompanyTransactionTypeEnum,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { formatNumber } from 'technical/currency/formatters';
import { addNumbersOrNull } from 'technical/number/add-numbers-or-null';
import { ActionButton } from 'ui/action-button';

export function getValuationHistoryColumnDefinition({
  t,
  handleOpenModal,
}: {
  t: TFunction;
  handleOpenModal: (data: {
    instrumentValuationId: string;
    companyInstrumentId: string;
  }) => void;
}): ColDef<PortfolioInstrumentValuation>[] {
  const sharesInPortfolio = (data?: PortfolioInstrumentValuation) => {
    return data?.companyInstrument?.companyTransaction?.reduce(
      (acc, { quantity, type }) => {
        return type === WebCompanyTransactionTypeEnum.Acquisition
          ? acc + quantity
          : acc - quantity;
      },
      0,
    );
  };
  return [
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationHistory.table.date.title',
      ),
      field: 'valuation.date',
      type: AgGridColumnTypesEnum.DATE,
      filter: true,
      rowGroup: true,
      hide: true,
      sortIndex: 1,
      sort: 'desc',
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationHistory.table.status.title',
      ),
      field: 'valuation.status',
      filter: true,
      cellRenderer: ({
        data,
      }: ICellRendererParams<
        GetPortfolioValuationDetailsQuery['instrumentValuations'][number]
      >) =>
        t('pages.fundManager.portfolio.valuationHistory.table.status.content', {
          context: data?.valuation?.status,
        }),
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationInstrument.labels.identifier',
      ),
      field: 'companyInstrument.name',
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.inventory.table.quantityInPortfolio',
      ),
      type: AgGridColumnTypesEnum.NUMBER,
      colId: 'quantityInPortfolio',
      aggFunc: 'sum',
      valueFormatter: ({ value }) =>
        value === 0
          ? t('pages.portfolio.table.companyStatus', {
              context: Statuses.exited,
            })
          : formatNumber(value, { precision: 0 }),
      valueGetter: ({ data }) => sharesInPortfolio(data),
      wrapHeaderText: true,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationHistory.table.fairValue.total',
      ),
      type: AgGridColumnTypesEnum.AMOUNT,
      colId: 'fairValue',
      aggFunc: 'sum',
      valueGetter: ({ data }) => {
        return (
          addNumbersOrNull([data?.fairValue, data?.accruedCoupon]) *
          Math.abs(sharesInPortfolio(data) ?? 0)
        );
      },
      wrapHeaderText: true,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationHistory.table.fairValue.unitary',
      ),
      field: 'fairValue',
      aggFunc: 'none',
      type: AgGridColumnTypesEnum.AMOUNT,
      valueGetter: ({ data }) => (data?.fairValue ? data.fairValue : 0),
      wrapHeaderText: true,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationHistory.table.accruedCoupons.unitary',
      ),
      field: 'accruedCoupon',
      aggFunc: 'none',
      type: AgGridColumnTypesEnum.AMOUNT,
      valueGetter: ({ data }) => (data?.accruedCoupon ? data.accruedCoupon : 0),
      wrapHeaderText: true,
    },
    {
      headerName: t(
        'pages.fundManager.portfolio.valuationHistory.table.proceeds.title',
      ),
      type: AgGridColumnTypesEnum.AMOUNT,
      colId: 'proceeds',
      aggFunc: 'sum',
      valueGetter: ({ data }) => {
        return (
          sumBy(data?.companyInstrument?.companyInstrumentProceeds, 'amount') *
          Math.abs(sharesInPortfolio(data) ?? 0)
        );
      },
    },
    {
      headerName: t('common.actions.edit'),
      type: AgGridColumnTypesEnum.INTERACTION,
      flex: 2,
      cellRenderer: ({ data }: ICellRendererParams) => {
        return (
          <ActionButton
            variant="secondary"
            onClick={() =>
              handleOpenModal({
                instrumentValuationId: data.id,
                companyInstrumentId: data.instrumentId,
              })
            }
          >
            {t('common.actions.edit')}
          </ActionButton>
        );
      },
    },
  ];
}
