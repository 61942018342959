import { FundValuationConfirmation } from './types';

export function isConfirmationPossible(data?: FundValuationConfirmation[]) {
  // to be able to confirm we want at least the fairValue or the accruedCoupon
  // to not be null
  if (!data || data.length === 0) {
    return false;
  }

  return data.every(
    (row) => row.accruedCoupon !== null || row.fairValue !== null,
  );
}
