import React, { createContext, useContext } from 'react';

import { getStoredLp } from 'business/lp-platform/nav-bar/services/lp-filters';
import { LPFilter } from 'business/lp-platform/nav-bar/services/types';
import { FeatureFlagEnum } from 'business/shared/services/types';
import useUserData from 'business/user/provider/use-user-data';
import { UserData } from 'business/user/types/user';
import { ConfigClientVariableKeyEnum } from 'generated/graphql';
import { Flex } from 'ui/flex';
import Loader from 'ui/loader';

import { useLoadConfig } from './services/hooks/use-load-config';

export interface AppContextAttributes {
  user: UserData | undefined;
  isConnected: boolean;
  appBootstraped: boolean;
  setLpContext: (attributes: LPFilter[]) => void;
  lpFilters: LPFilter[];
  requestRebootstrap: () => Promise<void>;
  activeFeatureFlags: FeatureFlagEnum[];
  configClient: Partial<Record<ConfigClientVariableKeyEnum, string>>;
}

const AppContext = createContext<AppContextAttributes>({
  user: undefined,
  isConnected: false,
  appBootstraped: false,
  setLpContext: () => {},
  lpFilters: getStoredLp(),
  requestRebootstrap: () => Promise.resolve(),
  activeFeatureFlags: [],
  configClient: {},
});

export const useAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactElement;
}

function AppBootstrapper({ children }: Props) {
  const { appBootstraped } = useAppContext();
  return appBootstraped ? (
    children
  ) : (
    <Flex
      alignItems="center"
      justify="center"
      style={{ flex: 1, height: '100vh' }}
    >
      <Loader />
    </Flex>
  );
}

export function AppProvider({ children }: Props) {
  const { isBootstraped, setLpContext, lpFilters, ...userData } = useUserData();
  const { configLoaded, activeFeatureFlags, configClient } = useLoadConfig();

  return (
    <AppContext.Provider
      value={{
        ...userData,
        appBootstraped: isBootstraped && configLoaded,
        setLpContext,
        lpFilters,
        activeFeatureFlags,
        configClient,
      }}
    >
      <AppBootstrapper>{children}</AppBootstrapper>
    </AppContext.Provider>
  );
}
