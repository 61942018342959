import { ApolloError } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import {
  useGetOperationIdentifierAssociatedEmailsCountQuery,
  useGetOperationSettingsQuery,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { QueryStateDisplay } from 'ui/query-state-display';
import { SectionTitle } from 'ui/section-title';

import styles from './index.module.scss';

type Props = {
  operationId: string;
  handleClose: () => void;
  onLaunch: () => void;
  launchError: ApolloError | undefined;
  isSubmitting?: boolean;
};
export const LaunchOperationConfirmation = ({
  operationId,
  handleClose,
  onLaunch,
  launchError,
  isSubmitting,
}: Props) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const operationSettingsQueryResult = useGetOperationSettingsQuery({
    variables: { id: operationId },
  });

  const { data, error, loading } =
    useGetOperationIdentifierAssociatedEmailsCountQuery({
      variables: { input: { operationId } },
    });

  if (
    loading ||
    error ||
    !data?.getOperationIdentifierAndAssociatedEmailsCount
  ) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  if (
    operationSettingsQueryResult.loading ||
    operationSettingsQueryResult.error ||
    !operationSettingsQueryResult.data
  ) {
    return (
      <QueryStateDisplay
        loading={operationSettingsQueryResult.loading}
        error={operationSettingsQueryResult.error}
      />
    );
  }

  const shouldEmailLetters =
    operationSettingsQueryResult.data.web_portal_operation_by_pk
      ?.shouldEmailLetters;

  const {
    getOperationIdentifierAndAssociatedEmailsCount: { count, identifier },
  } = data;

  return (
    <Stack
      sx={{ backgroundColor: palette.backgroundForm }}
      padding={3}
      spacing={4}
      alignItems="center"
      className={styles.container}
    >
      <SectionTitle
        title={t(
          'pages.fundManager.operation.launchOperationConfirmation.title',
        )}
      />
      <Stack spacing={2}>
        <Typography textAlign="center">
          {t(
            `pages.fundManager.operation.launchOperationConfirmation.${
              shouldEmailLetters
                ? 'send_investors_count'
                : 'generate_investors_count'
            }`,
            {
              count,
              identifier,
            },
          )}
        </Typography>
        <Typography textAlign="center">
          {t(
            'pages.fundManager.operation.launchOperationConfirmation.confirmLaunching',
          )}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <ActionButton
          variant="submit"
          onClick={onLaunch}
          data-test-id="launch-emailing-modal-button"
          loading={isSubmitting}
        >
          {t(
            `pages.fundManager.operation.launchOperationConfirmation.button.${
              shouldEmailLetters ? 'launchEmailing' : 'launchOperation'
            }`,
          )}
        </ActionButton>
        <ActionButton variant="secondary" onClick={handleClose}>
          {t('common.actions.cancel')}
        </ActionButton>
      </Stack>

      {launchError ? <ErrorLabel label={launchError.message} /> : null}
    </Stack>
  );
};
