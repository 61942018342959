import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  UploadFundDocumentInput,
  useUploadFundDocumentMutation,
} from 'generated/graphql';
import logger from 'technical/logger';

interface UseUploadFundDocumentProps {
  onCompleted?: () => void;
}

export const useUploadFundDocument = ({
  onCompleted,
}: UseUploadFundDocumentProps = {}) => {
  const { t } = useTranslation();
  const [uploadFundDocument, { loading }] = useUploadFundDocumentMutation({
    onError: (err) => {
      logger.error({
        err,
      });
      enqueueSnackbar(t('errors.upload-failed'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.uploadDocument'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onCompleted?.();
    },
  });

  const uploadDocument = (input: UploadFundDocumentInput) =>
    uploadFundDocument({ variables: { input } });

  return {
    uploadDocument,
    loading,
  };
};
