import { CheckCircle, AccessTime } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetOperationsQuery } from 'generated/graphql';

const renderStatusCell = (t: TFunction, status: 'completed' | 'pending') => {
  const color = status === 'completed' ? 'primary' : 'secondary';
  const icon =
    status === 'completed' ? (
      <CheckCircle color={color} />
    ) : (
      <AccessTime color={color} />
    );
  return (
    <Stack direction="row" spacing={1}>
      {icon}
      <Typography variant="body2" color={color}>
        {t('pages.financialFlows.table.status', {
          context: status,
          defaultValue: '',
        })}
      </Typography>
    </Stack>
  );
};

export const statusColumn = (
  t: TFunction,
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'status',
    type: 'singleSelect',
    groupable: false,
    aggregable: false,
    valueOptions: ['completed', 'pending'],
    headerName: t('pages.financialFlows.table.status'),
    renderCell: ({ value }) =>
      value !== 'completed' && value !== 'pending'
        ? ''
        : renderStatusCell(t, value),
    valueGetter: ({ row: { status } }) => status ?? '',
  };
};
