import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import classNames from 'classnames';

import { useToggleButton } from './hook';
import styles from './index.module.scss';
import { ToggleButtonOption } from './type';

export interface ToggleButtonProps extends ToggleButtonGroupProps {
  options: ToggleButtonOption[];
  toggleGroupClassName?: string;
  buttonClassName?: string;
  allowEmpty?: boolean;
  disabled?: boolean;
}

const ToggleButton = ({
  options,
  toggleGroupClassName,
  buttonClassName,
  color = 'primary',
  value,
  allowEmpty,
  disabled,
  ...rest
}: ToggleButtonProps) => {
  const { alignment, handleChange } = useToggleButton({
    options,
    value,
    allowEmpty,
  });

  if (options.length === 0) {
    return null;
  }

  return (
    <ToggleButtonGroup
      {...rest}
      color={color}
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      className={classNames(
        styles.toggleButtonText,
        toggleGroupClassName ?? '',
      )}
      disabled={disabled}
    >
      {options.map((option) => (
        <MuiToggleButton
          value={option.value ?? option.label}
          key={option.label}
          className={buttonClassName}
        >
          {option.label}
        </MuiToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButton;
