import { TFunction } from 'translations/hook';

import {
  OnBoardingStatusEnum,
  OnboardingSubscriptionStateQuery,
  OnboardingSubscriptionStatusEnum,
} from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';
import { ValidationEntityTypeItem } from 'ui/validation-card';
import { ValidationItemType, VALIDATION_ITEM_TYPES } from 'ui/validation-item';

import { getBankDetails } from './get-bank-details';

const getSubscriptionCardStatus = (
  status: OnboardingSubscriptionStatusEnum,
) => {
  if (status === OnboardingSubscriptionStatusEnum.SubInfoDeclined) {
    return OnBoardingStatusEnum.Declined;
  }
  return status === OnboardingSubscriptionStatusEnum.SubInfoValidated
    ? OnBoardingStatusEnum.Validated
    : undefined;
};

export const getOnboardingSubscriptionValidationCard = ({
  data,
  t,
}: {
  data: Omit<
    NonNullable<
      OnboardingSubscriptionStateQuery['onboardingSubscriptionState']
    >,
    'lpState'
  >;
  t: TFunction;
}): {
  title: string;
  status?: OnBoardingStatusEnum;
} & ValidationEntityTypeItem => {
  const items: ValidationItemType[] = [
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t('pages.fundManager.onboardingSubscriptionValidation.fund'),
      value: data.share.fund.name,
    },
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t('pages.fundManager.onboardingSubscriptionValidation.share'),
      value: data.share.name,
    },
    {
      type: VALIDATION_ITEM_TYPES.TEXT,
      label: t('pages.fundManager.onboardingSubscriptionValidation.amount'),
      value: formatToMonetaryAmount(
        convertFrom10X8toNumber(data.numericAmount),
        {
          currency: data.share.fund.currency,
        },
      ),
    },
    ...(data.bankDetails
      ? getBankDetails({ bankDetails: data.bankDetails, t })
      : []),
  ];

  return {
    title: t('pages.fundManager.onboardingSubscriptionValidation.title'),
    type: 'single',
    entity: {
      items,
    },
    status: getSubscriptionCardStatus(data.status),
  };
};
