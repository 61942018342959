import {
  GraphData,
  OperationCashflow,
} from 'business/lp-platform/financial-flows/types';
import { CurrencyEnum } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatToMonetaryAmount,
} from 'technical/currency/formatters';

import { getQuartedDate } from './date-utils';

export const getGraphData = (
  queryData: OperationCashflow[],
  currency: CurrencyEnum,
  shouldFormatDecimals = false,
): GraphData => {
  // sort dates (oldest -> newest)
  const sortedDataByDate = [...queryData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  // get plolty formatted data
  return sortedDataByDate.reduce(
    (accumulator: GraphData, currentValue: OperationCashflow) => {
      const quarterDate = getQuartedDate(currentValue.date);
      const lastQuarterDate =
        accumulator.dueDates[accumulator.dueDates.length - 1];
      let indexToModify;
      let numericAmount;

      // deprecatedFormatDecimalValue if necessary
      // set negative value for drawdowns operations to calculate cashflow later
      if (shouldFormatDecimals) {
        numericAmount =
          currentValue.type === 'drawdown'
            ? -convertFrom10X8toNumber(currentValue.numericAmount)
            : convertFrom10X8toNumber(currentValue.numericAmount);
      } else {
        numericAmount =
          currentValue.type === 'drawdown'
            ? -currentValue.numericAmount
            : currentValue.numericAmount;
      }

      // define quarter index to process (existing or not)
      if (quarterDate === lastQuarterDate) {
        indexToModify = accumulator.dueDates.length - 1;
      } else {
        indexToModify = !accumulator.dueDates.length
          ? 0
          : accumulator.dueDates.length;
      }

      // add date
      accumulator.dueDates[indexToModify] = quarterDate;

      // sum the previous values of same type for the current quarter
      if (currentValue.type === 'drawdown') {
        // DRAWDOWN case
        accumulator.distributionAmounts[indexToModify] =
          (accumulator.distributionAmounts[indexToModify] || 0) + 0; // 0 if value does not exists yet

        accumulator.drawdownAmounts[indexToModify] =
          (accumulator.drawdownAmounts[indexToModify] || 0) + numericAmount; // 0 if value does not exists yet
      } else {
        // DISTRIBUTION case
        accumulator.distributionAmounts[indexToModify] =
          (accumulator.distributionAmounts[indexToModify] || 0) + numericAmount; // 0 if value does not exists yet

        accumulator.drawdownAmounts[indexToModify] =
          (accumulator.drawdownAmounts[indexToModify] || 0) + 0; // 0 if value does not exists yet
      }

      // add/update distributionCount and drawdownCount of the quarter
      // add/update cumulativeCashflowValues of the quarter
      // add/update the absoluteAmountDrawdown to display an absolute value in Plolty (for the tooltip)
      if (accumulator.cumulativeCashflowValues[indexToModify]) {
        // case 1: if existing amount for the current quarter: sum the amounts
        accumulator.cumulativeCashflowValues[indexToModify] =
          accumulator.cumulativeCashflowValues[indexToModify] + numericAmount;

        accumulator.customData[indexToModify].formattedCumulativeCashflowValue =
          formatToMonetaryAmount(
            accumulator.cumulativeCashflowValues[indexToModify],
            { currency },
          );

        accumulator.customData[indexToModify].distributionCount +=
          currentValue.type === 'drawdown' ? 0 : 1;
        accumulator.customData[indexToModify].drawdownCount +=
          currentValue.type === 'drawdown' ? 1 : 0;
        accumulator.customData[indexToModify].absoluteAmountDrawdown +=
          currentValue.type === 'drawdown' ? Math.abs(numericAmount) : 0;
        accumulator.customData[indexToModify].formattedAbsoluteAmountDrawdown =
          formatToMonetaryAmount(
            accumulator.customData[indexToModify].absoluteAmountDrawdown,
            { currency },
          );
        accumulator.customData[indexToModify].formattedDistributionAmount =
          formatToMonetaryAmount(
            accumulator.distributionAmounts[indexToModify],
            {
              currency,
            },
          );
      } else {
        // case 2: if quarter does not exists yet: sum(current amount + cumulated cashflow from previous quarter)
        const previousQuarterCumulativeCashflow =
          accumulator.cumulativeCashflowValues[indexToModify - 1] || 0; // 0 if no previous quarter

        accumulator.cumulativeCashflowValues[indexToModify] =
          previousQuarterCumulativeCashflow + numericAmount;

        accumulator.customData[indexToModify] = {
          formattedCumulativeCashflowValue: formatToMonetaryAmount(
            accumulator.cumulativeCashflowValues[indexToModify],
            { currency },
          ),
          distributionCount: currentValue.type === 'drawdown' ? 0 : 1,
          drawdownCount: currentValue.type === 'drawdown' ? 1 : 0,
          absoluteAmountDrawdown:
            currentValue.type === 'drawdown' ? Math.abs(numericAmount) : 0,
          formattedAbsoluteAmountDrawdown: formatToMonetaryAmount(
            currentValue.type === 'drawdown' ? Math.abs(numericAmount) : 0,
            { currency },
          ),
          formattedDistributionAmount: formatToMonetaryAmount(
            accumulator.distributionAmounts[indexToModify],
            { currency },
          ),
        };
      }

      return accumulator;
    },
    {
      dueDates: [],
      distributionAmounts: [],
      drawdownAmounts: [],
      cumulativeCashflowValues: [],
      customData: [],
    },
  );
};
