import { useTranslation } from 'translations/hook';

import { FundGraphCard } from 'business/fund-manager/home/components/funds-graph-card';
import { InvestorsCard } from 'business/fund-manager/home/components/investors-card/investors-card';
import { OnboardingCard } from 'business/fund-manager/home/components/onboarding-card/onboarding-card';
import { OperationsCard } from 'business/fund-manager/home/components/operations-card';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import { useGetFundManagerDashBoardInfosQuery } from 'generated/graphql';
import { DashboardGrid } from 'ui/dashboard-grid/dashboard-grid';
import { QueryStateDisplay } from 'ui/query-state-display';

const HomeContent = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useGetFundManagerDashBoardInfosQuery({
    fetchPolicy: 'network-only',
  });
  const displayOperationCard = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_OPERATION_CARD,
  );

  const isOnboardingSubscriptionActive = useFeatureFlag(
    FeatureFlagEnum.MODULE_ONBOARDING_SUBSCRIPTION,
  );

  if (loading || error || !data?.getFundManagerDashBoardInfos) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <DashboardGrid>
      {isOnboardingSubscriptionActive ? (
        <OnboardingCard
          title={t(
            'pages.fundManager.dashboard.cards.onboardingSubscriptions.title',
          )}
          path={FundManagerRoutes.OnboardingSubscription}
        />
      ) : null}
      <FundGraphCard
        title={t('pages.fundManager.dashboard.cards.funds.title')}
        path={FundManagerRoutes.Fund}
        data={data.getFundManagerDashBoardInfos}
      />
      <InvestorsCard
        title={t('pages.fundManager.dashboard.cards.investors.title')}
        path={FundManagerRoutes.Lp}
        data={data.getFundManagerDashBoardInfos.lpsOverview}
      />
      {displayOperationCard ? (
        <OperationsCard
          title={t('pages.fundManager.dashboard.cards.operations.title')}
          path={FundManagerRoutes.Operations}
        />
      ) : null}
    </DashboardGrid>
  );
};

export const HomePage = () => {
  const { t } = useTranslation();

  return (
    <ConnectedPage title={t('pages.fundManager.dashboard.title')}>
      <HomeContent />
    </ConnectedPage>
  );
};
