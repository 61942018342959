import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { PublicationData as PublicationDataType } from 'business/fund-manager/settings/template/services/types';
import { publicationDataSchema } from 'business/fund-manager/settings/template/services/validation';
import { useUpdatePublicationDataMutation } from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { ErrorLabel } from 'ui/error-label';
import { FormInputText, FormSection, FormCard } from 'ui/form';

type PublicationDataFormProps = {
  defaultValues: PublicationDataType;
};

export const PublicationDataForm = ({
  defaultValues,
}: PublicationDataFormProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<PublicationDataType>({
    resolver: yupResolver(publicationDataSchema),
    defaultValues,
  });

  const [updatePublicationData, { loading, error }] =
    useUpdatePublicationDataMutation();

  const onSubmit = ({ id, ...input }: PublicationDataType) => {
    updatePublicationData({
      variables: {
        id,
        input,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <FormSection
          title={t('pages.fundManager.settings.publicationData.form.title')}
          subtitle={t(
            'pages.fundManager.settings.publicationData.form.subtitle',
          )}
        >
          <FormInputText
            name="senderEmail"
            control={control}
            label={t(
              'pages.fundManager.settings.publicationData.form.senderEmail',
            )}
          />

          <FormInputText
            name="senderName"
            control={control}
            label={t(
              'pages.fundManager.settings.publicationData.form.senderName',
            )}
          />

          <FormInputText
            name="crmEmail"
            control={control}
            label={t(
              'pages.fundManager.settings.publicationData.form.crmEmail.label',
            )}
            placeholder={t(
              'pages.fundManager.settings.publicationData.form.crmEmail.placeholder',
            )}
          />

          <FormInputText
            name="fundContactInformation"
            control={control}
            label={t(
              'pages.fundManager.settings.publicationData.form.fundContactInformation.label',
            )}
            placeholder={t(
              'pages.fundManager.settings.publicationData.form.fundContactInformation.placeholder',
            )}
            multiline
          />

          <FormInputText
            name="footerInformation"
            control={control}
            label={t(
              'pages.fundManager.settings.publicationData.form.footerInformation.label',
            )}
            placeholder={t(
              'pages.fundManager.settings.publicationData.form.footerInformation.placeholder',
            )}
            multiline
          />
        </FormSection>
        {error ? <ErrorLabel label={error.message} /> : null}
        <ActionButton variant="primary" type="submit" loading={loading}>
          {t('common.actions.save')}
        </ActionButton>
      </FormCard>
    </form>
  );
};
