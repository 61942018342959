import { Stack } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_AGGREGATION_FUNCTIONS,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridValidRowModel,
  enUS,
  frFR,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { getExceljsPreProcess } from 'ui/table/export/excel-export';
import TableHeader from 'ui/table/header';
import { toolbarContainerId } from 'ui/table/toolbar-container/container-id';

import styles from './index.module.scss';

interface Props<T extends GridValidRowModel> extends DataGridPremiumProps<T> {
  title?: string;
  pagination?: boolean;
  toolbar?: boolean;
  toolbarAnchor?: HTMLDivElement | null;
}

export const Table = <T extends GridValidRowModel = any>({
  title,
  columns,
  rows,
  pagination = true,
  toolbar = true,
  // Needed to put the toolbar menus at the right place with the portal
  toolbarAnchor,
  ...props
}: Props<T>) => {
  const { t } = useTranslation();
  const styledHeaders = columns.map((column) => ({
    minWidth: 200,
    ...column,
    headerClassName: styles.tableHeader,
    flex: 1,
  }));

  const gridApiRef = useGridApiRef();

  const exceljsPreProcess = getExceljsPreProcess(t, title);
  const excelOptions = { exceljsPreProcess };
  const gridExport = <GridToolbarExport excelOptions={excelOptions} />;
  const apiRef = props.apiRef ?? gridApiRef;

  const customToolbar = (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {gridExport}
    </GridToolbarContainer>
  );

  const Toolbar = () =>
    ReactDOM.createPortal(
      <Stack direction={'row'} justifyContent={'space-between'}>
        {customToolbar}
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput.split(',').map((value) => value.trim())
          }
          quickFilterFormatter={(quickFilterValues) =>
            quickFilterValues ? quickFilterValues.join(', ') : ''
          }
          variant="outlined"
        />
      </Stack>,
      document.getElementById(toolbarContainerId)!,
    );

  const palette = usePalette();

  const slots = toolbar
    ? { toolbar: Toolbar, ...props.slots }
    : { ...props.slots };

  const slotProps = toolbarAnchor
    ? { panel: { anchorEl: toolbarAnchor }, ...props.slotProps }
    : { ...props.slotProps };

  const lng = i18next.resolvedLanguage;

  const initialSate = {
    pagination: { paginationModel: { pageSize: 10 } },
    ...props.initialState,
    ...useKeepGroupedColumnsHidden({
      apiRef,
      ...props.initialState?.rowGrouping,
    }),
  };
  return (
    <div>
      {title ? <TableHeader title={title} /> : null}
      <div className={styles.datagridContainer}>
        <DataGridPremium
          apiRef={apiRef}
          localeText={
            (lng === 'en' ? enUS : frFR).components.MuiDataGrid.defaultProps
              .localeText
          }
          {...props}
          rows={rows}
          columns={styledHeaders}
          getRowClassName={() => styles.row}
          sx={{
            '.MuiDataGrid-columnHeaders': {
              borderBottomColor: 'secondary.main',
            },
            '.MuiDataGrid-main': {
              backgroundColor: palette.backgroundForm,
            },
            '.MuiDataGrid-footerContainer': {
              backgroundColor: palette.backgroundForm,
            },
          }}
          className={styles.table}
          // The toolbar need a HTML Element with `gridToolbarId` as id to be instanciated
          slots={slots}
          slotProps={slotProps}
          aggregationFunctions={Object.fromEntries(
            Object.entries(GRID_AGGREGATION_FUNCTIONS).filter(
              ([name]) => name !== 'size',
            ),
          )}
          initialState={initialSate}
          pageSizeOptions={[10, 25, 100]}
          pagination={pagination}
        />
      </div>
    </div>
  );
};
