import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { OperationCashflow } from 'business/lp-platform/financial-flows/types';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { FinancialFlowsGraph } from 'business/shared/financial-flows/components';
import { getGraphData } from 'business/shared/financial-flows/services/get-graph-data';
import LegendItem from 'ui/legend-item';

import styles from './index.module.scss';

interface Props {
  data: OperationCashflow[];
}

// Code already duplicated here front/src/business/fund-manager/lp/components/financialFlowGraphs/index.tsx
// if you have to duplicate it again, please find a way to make it more generic
export const GraphPanelLp = ({ data }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const { currency } = useFund();

  const graphData = getGraphData(data, currency, true);

  return (
    <Box
      sx={{ bgcolor: palette.backgroundForm }}
      data-test-id="financial-flows-graph"
    >
      <Stack direction={'row'} className={styles.titleBar}>
        <h6 className={styles.title}>
          {t('pages.financialFlows.graph.title')}
        </h6>
        <Stack direction={'row'} spacing={2}>
          <LegendItem
            color="primary"
            legend={t('pages.financialFlows.graph.drawdown')}
          />
          <LegendItem
            color="secondary"
            legend={t('pages.financialFlows.graph.distribution')}
          />
          <LegendItem
            sx={{ color: 'secondary.light' }}
            legend={t('pages.financialFlows.graph.cumulativeCashflow')}
          />
        </Stack>
      </Stack>
      <Divider light />
      <div className={styles.graphContainer}>
        <FinancialFlowsGraph
          financialFlowsData={graphData}
          fileName={t('pages.financialFlows.graph.fileName')}
        />
      </div>
    </Box>
  );
};
