export interface IErrorReporting {
  error(error: any, data: any): void;
  info(error: any, data: any): void;
  warning(error: any, data: any): void;
  setUser(user: { id: string; username?: string; email?: string }): void;
  removeUser(): void;
}

export interface ErrorReportingOptions {
  applicationId: string;
  clientToken: string;
  sessionSampleRate: number;
  sessionReplaySampleRate: number;
  trackUserInteractions: boolean;
  trackResources: boolean;
  trackLongTasks: boolean;
  enableSessionReplay: boolean;
}

export enum SeverityLevel {
  'fatal',
  'error',
  'warning',
  'log',
  'info',
  'debug',
  'critical',
}
export const SeverityLevelsToReport = [
  SeverityLevel.fatal,
  SeverityLevel.error,
  SeverityLevel.critical,
  SeverityLevel.warning,
];
