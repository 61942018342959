import { Chip as MuiChip } from '@mui/material';
import classnames from 'classnames';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import styles from './index.module.scss';

interface Props {
  active?: boolean;
  activeLabel: string;
  inactiveLabel: string;
}

export const ActiveChip = ({ active, activeLabel, inactiveLabel }: Props) => {
  const palette = usePalette();
  const chipColor = active ? palette.primary[800] : palette.secondary[800];
  return (
    <MuiChip
      className={classnames(styles.buttonSize, {
        [styles.active]: active,
        [styles.inactive]: !active,
      })}
      sx={{
        borderColor: chipColor,
        color: chipColor,
      }}
      label={active ? activeLabel : inactiveLabel}
      variant="outlined"
    />
  );
};
