export const franceDepartments = [
  { id: '01', value: 'Ain (01)' },
  { id: '02', value: 'Aisne (02)' },
  { id: '03', value: 'Allier (03)' },
  { id: '04', value: 'Alpes-de-Haute-Provence (04)' },
  { id: '05', value: 'Hautes-Alpes (05)' },
  { id: '06', value: 'Alpes-Maritimes (06)' },
  { id: '07', value: 'Ardèche (07)' },
  { id: '08', value: 'Ardennes (08)' },
  { id: '09', value: 'Ariège (09)' },
  { id: '10', value: 'Aube (10)' },
  { id: '11', value: 'Aude (11)' },
  { id: '12', value: 'Aveyron (12)' },
  { id: '13', value: 'Bouches-du-Rhône (13)' },
  { id: '14', value: 'Calvados (14)' },
  { id: '15', value: 'Cantal (15)' },
  { id: '16', value: 'Charente (16)' },
  { id: '17', value: 'Charente-Maritime (17)' },
  { id: '18', value: 'Cher (18)' },
  { id: '19', value: 'Corrèze (19)' },
  { id: '2A', value: 'Corse-du-Sud (2A)' },
  { id: '2B', value: 'Haute-Corse (2B)' },
  { id: '21', value: "Côte-d'Or (21)" },
  { id: '22', value: "Côtes-d'Armor (22)" },
  { id: '23', value: 'Creuse (23)' },
  { id: '24', value: 'Dordogne (24)' },
  { id: '25', value: 'Doubs (25)' },
  { id: '26', value: 'Drôme (26)' },
  { id: '27', value: 'Eure (27)' },
  { id: '28', value: 'Eure-et-Loir (28)' },
  { id: '29', value: 'Finistère (29)' },
  { id: '30', value: 'Gard (30)' },
  { id: '31', value: 'Haute-Garonne (31)' },
  { id: '32', value: 'Gers (32)' },
  { id: '33', value: 'Gironde (33)' },
  { id: '34', value: 'Hérault (34)' },
  { id: '35', value: 'Ille-et-Vilaine (35)' },
  { id: '36', value: 'Indre (36)' },
  { id: '37', value: 'Indre-et-Loire (37)' },
  { id: '38', value: 'Isère (38)' },
  { id: '39', value: 'Jura (39)' },
  { id: '40', value: 'Landes (40)' },
  { id: '41', value: 'Loir-et-Cher (41)' },
  { id: '42', value: 'Loire (42)' },
  { id: '43', value: 'Haute-Loire (43)' },
  { id: '44', value: 'Loire-Atlantique (44)' },
  { id: '45', value: 'Loiret (45)' },
  { id: '46', value: 'Lot (46)' },
  { id: '47', value: 'Lot-et-Garonne (47)' },
  { id: '48', value: 'Lozère (48)' },
  { id: '49', value: 'Maine-et-Loire (49)' },
  { id: '50', value: 'Manche (50)' },
  { id: '51', value: 'Marne (51)' },
  { id: '52', value: 'Haute-Marne (52)' },
  { id: '53', value: 'Mayenne (53)' },
  { id: '54', value: 'Meurthe-et-Moselle (54)' },
  { id: '55', value: 'Meuse (55)' },
  { id: '56', value: 'Morbihan (56)' },
  { id: '57', value: 'Moselle (57)' },
  { id: '58', value: 'Nièvre (58)' },
  { id: '59', value: 'Nord (59)' },
  { id: '60', value: 'Oise (60)' },
  { id: '61', value: 'Orne (61)' },
  { id: '62', value: 'Pas-de-Calais (62)' },
  { id: '63', value: 'Puy-de-Dôme (63)' },
  { id: '64', value: 'Pyrénées-Atlantiques (64)' },
  { id: '65', value: 'Hautes-Pyrénées (65)' },
  { id: '66', value: 'Pyrénées-Orientales (66)' },
  { id: '67', value: 'Bas-Rhin (67)' },
  { id: '68', value: 'Haut-Rhin (68)' },
  { id: '69D', value: 'Rhône (69D)' },
  { id: '69M', value: 'Métropole de Lyon (69M)' },
  { id: '70', value: 'Haute-Saône (70)' },
  { id: '71', value: 'Saône-et-Loire (71)' },
  { id: '72', value: 'Sarthe (72)' },
  { id: '73', value: 'Savoie (73)' },
  { id: '74', value: 'Haute-Savoie (74)' },
  { id: '75', value: 'Paris (75)' },
  { id: '76', value: 'Seine-Maritime (76)' },
  { id: '77', value: 'Seine-et-Marne (77)' },
  { id: '78', value: 'Yvelines (78)' },
  { id: '79', value: 'Deux-Sèvres (79)' },
  { id: '80', value: 'Somme (80)' },
  { id: '81', value: 'Tarn (81)' },
  { id: '82', value: 'Tarn-et-Garonne (82)' },
  { id: '83', value: 'Var (83)' },
  { id: '84', value: 'Vaucluse (84)' },
  { id: '85', value: 'Vendée (85)' },
  { id: '86', value: 'Vienne (86)' },
  { id: '87', value: 'Haute-Vienne (87)' },
  { id: '88', value: 'Vosges (88)' },
  { id: '89', value: 'Yonne (89)' },
  { id: '90', value: 'Territoire de Belfort (90)' },
  { id: '91', value: 'Essonne (91)' },
  { id: '92', value: 'Hauts-de-Seine (92)' },
  { id: '93', value: 'Seine-Saint-Denis (93)' },
  { id: '94', value: 'Val-de-Marne (94)' },
  { id: '95', value: "Val-d'Oise (95)" },
  { id: '971', value: 'Guadeloupe (971)' },
  { id: '972', value: 'Martinique (972)' },
  { id: '973', value: 'Guyane (973)' },
  { id: '974', value: 'La Réunion (974)' },
  { id: '975', value: 'Saint-Pierre-et-Miquelon (975)' },
  { id: '976', value: 'Mayotte (976)' },
  { id: '977', value: 'Saint-Barthélemy (977)' },
  { id: '978', value: 'Saint-Martin (978)' },
  { id: '984', value: 'Terres australes et antarctiques françaises (984)' },
  { id: '986', value: 'Wallis-et-Futuna (986)' },
  { id: '987', value: 'Polynésie française (987)' },
  { id: '988', value: 'Nouvelle-Calédonie (988)' },
  { id: '989', value: 'Île de Clipperton (989)' },
];
