import { Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyCreationWizzard } from 'business/fund-manager/portfolio/company/components/company-creation-wizzard';
import { getCompanyDocumentColumnDefinition } from 'business/fund-manager/portfolio/company/components/company-document-table/column-definition';
import { CompanyDocumentUploadFormCta } from 'business/fund-manager/portfolio/company/components/company-document-upload-form-cta';
import { CompanyCreationStepEnum } from 'business/fund-manager/portfolio/company/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  GetCompanyDocumentQuery,
  useGetCompanyDocumentQuery,
} from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';
import { SectionTitle } from 'ui/section-title';

type CompanyDocumentData =
  GetCompanyDocumentQuery['web_portal_companyDocument'][number];

export const CompanyCreationDocumentation = () => {
  const { t } = useTranslation();
  const { fundId = '', companyId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetCompanyDocumentQuery({
    variables: {
      companyId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <CompanyCreationWizzard
      activeStep={CompanyCreationStepEnum.documentation}
      onSubmit={() =>
        navigate(
          FundManagerRoutes.PortfolioFundIdCompanyCreateIdContact.replace(
            ':fundId',
            fundId,
          ).replace(':companyId', companyId),
        )
      }
      previousPath={FundManagerRoutes.PortfolioFundIdCompanyCreateIdInvestments.replace(
        ':fundId',
        fundId,
      ).replace(':companyId', companyId)}
      rawChildren
    >
      <>
        <Stack direction="row" justifyContent="space-between">
          <SectionTitle
            title={t('pages.fundManager.portfolio.company.documentation.title')}
          />

          <CompanyDocumentUploadFormCta
            companyId={companyId}
            onAdded={refetch}
          />
        </Stack>
        <AlternativeTable<CompanyDocumentData>
          fileName="company-creation-documents"
          columnDefs={getCompanyDocumentColumnDefinition(t)}
          rowData={data?.web_portal_companyDocument ?? []}
          loading={loading}
          domLayout="autoHeight"
        />
      </>
    </CompanyCreationWizzard>
  );
};
