import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { FundFromDateEnum } from 'generated/graphql';
import { FormToggleButton } from 'ui/form';

type FormFromDateOfProps = {
  name: string;
  required?: boolean;
};

export const FormFromDateOf = ({ name, required }: FormFromDateOfProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <FormToggleButton
      fullWidth
      control={control}
      label={t('pages.fundManager.fundCreation.form.common.fromDateOf.label')}
      name={name}
      required={required}
      options={[
        {
          label: t(
            'pages.fundManager.fundCreation.form.common.fromDateOf.firstClosingDate',
          ),
          value: FundFromDateEnum.FirstClosingDate,
        },
        {
          label: t(
            'pages.fundManager.fundCreation.form.common.fromDateOf.finalClosingDate',
          ),
          value: FundFromDateEnum.FinalClosingDate,
        },
        {
          label: t(
            'pages.fundManager.fundCreation.form.common.fromDateOf.firstInvestmentDate',
          ),
          value: FundFromDateEnum.FirstInvestmentDate,
        },
      ]}
    />
  );
};
