import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useRef } from 'react';
import { useTranslation } from 'translations/hook';

import { getSecurityLogColumnDefinition } from 'business/lp-platform/security-log/services/get-security-log-column-definition';
import ConnectedPage from 'business/user/helpers/connected-page';
import { GetSecurityLogQuery, useGetSecurityLogQuery } from 'generated/graphql';
import { Table, ToolbarContainer } from 'ui/table';

const SecurityLog = () => {
  const { t } = useTranslation();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const headers: GridColDef<GetSecurityLogQuery['securityLog'][number]>[] =
    getSecurityLogColumnDefinition(t);

  const { data, loading } = useGetSecurityLogQuery();

  return (
    <ConnectedPage title={t('pages.securityLog.title')}>
      <Stack spacing={2}>
        <ToolbarContainer toolbarRef={toolbarRef} filterPlaceholders={[]} />
        <Table<GetSecurityLogQuery['securityLog'][number]>
          toolbarAnchor={toolbarRef.current}
          columns={headers}
          rows={data ? data.securityLog : []}
          loading={loading}
        />
      </Stack>
    </ConnectedPage>
  );
};

export default SecurityLog;
