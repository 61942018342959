import { QueryResult } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { OperationCreationContainer } from 'business/fund-manager/operation/components/operation-creation-container';
import { OperationShareRecapTable } from 'business/fund-manager/operation/components/operation-share-recap-table';
import {
  OperationCreationStepEnum,
  OperationSharesLp,
} from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import {
  GetOperationShareQuery,
  useGetOperationShareQuery,
} from 'generated/graphql';
import { addNumbersOrNull } from 'technical/number/add-numbers-or-null';
import { QueryStateDisplay } from 'ui/query-state-display';

type CreateOperationSharesRecapContentProps = Pick<
  QueryResult<GetOperationShareQuery>,
  'data' | 'loading' | 'error'
> & {
  onPremiumUpdateCompleted?: () => void;
};

const CreateOperationSharesRecapContent = ({
  loading,
  data,
  error,
  onPremiumUpdateCompleted,
}: CreateOperationSharesRecapContentProps) => {
  if (loading || error || data === undefined) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const ope: OperationSharesLp[] = (
    data.web_portal_operationShare ?? []
  ).flatMap(({ operationShareLps, share }) => {
    const opeShareLps = operationShareLps.map((opeShareLpData) => {
      const cashflow = data.escrowAccountUsage.cashflows.find(
        ({ operationShareLpId }) => operationShareLpId === opeShareLpData.id,
      );

      const distributed = addNumbersOrNull([
        opeShareLpData.numericReturnOfCost,
        opeShareLpData.numericCapitalGain,
        opeShareLpData.numericInterest,
        opeShareLpData.numericDividend,
      ]);

      const drawn = addNumbersOrNull([
        opeShareLpData.numericInvestment,
        opeShareLpData.numericFees,
        opeShareLpData.numericOther,
        opeShareLpData.numericPremium,
      ]);

      return {
        ...opeShareLpData,
        emittedBy: cashflow?.emittedBy,
        escrowAmount: cashflow?.numericAmount,
        id: opeShareLpData.id,
        lpView: {
          id: opeShareLpData.lp.lpView?.id ?? '',
          lpName: opeShareLpData.lp.lpView?.lpName ?? '',
        },
        shareName: share.name,
        // the drawn should also sum the premium value
        // but since premium is a dynamic value
        // I'm making another sum in the table
        // column definition
        distributed,
        witholdings: opeShareLpData.numericWitholdings,
        drawn,
        netDistributed: distributed - opeShareLpData.numericWitholdings,
      };
    });

    return opeShareLps;
  });

  return (
    <OperationShareRecapTable
      data={ope}
      onPremiumUpdateCompleted={onPremiumUpdateCompleted}
    />
  );
};

export const CreateOperationSharesRecap = () => {
  const { operationId = '' } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetOperationShareQuery({
    variables: {
      operationId,
      stringOperationId: operationId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <OperationCreationContainer
      activeStep={OperationCreationStepEnum.SharesLp}
      onNext={() => {
        navigate(
          FundManagerRoutes.OperationCreateIdPreviewEmailDocument.replace(
            ':operationId',
            operationId,
          ),
        );
      }}
      labelAction={t('common.actions.save')}
      onPrevious={() =>
        navigate(
          FundManagerRoutes.OperationCreateIdShares.replace(
            ':operationId',
            operationId,
          ),
        )
      }
      rawChildren
    >
      <CreateOperationSharesRecapContent
        data={data}
        loading={loading}
        error={error}
        onPremiumUpdateCompleted={refetch}
      />
    </OperationCreationContainer>
  );
};
