import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useRef } from 'react';
import { useTranslation } from 'translations/hook';

import { getContactColumnDefinition } from 'business/lp-platform/contact/services/get-contact-column-definition';
import ConnectedPage from 'business/user/helpers/connected-page';
import { GetContactsQuery, useGetContactsQuery } from 'generated/graphql';
import { Table, ToolbarContainer } from 'ui/table';

const Contact = () => {
  const { t } = useTranslation();

  const headers: GridColDef<GetContactsQuery['dataviz_lpContact'][number]>[] =
    getContactColumnDefinition(t);

  const toolbarRef = useRef<HTMLDivElement>(null);

  const { data, loading } = useGetContactsQuery();

  return (
    <ConnectedPage title={t('pages.contact.title')}>
      <Stack spacing={2}>
        <ToolbarContainer
          toolbarRef={toolbarRef}
          filterPlaceholders={[
            t('pages.contact.table.contactName'),
            t('pages.contact.table.email'),
          ]}
        />
        <Table<GetContactsQuery['dataviz_lpContact'][number]>
          toolbarAnchor={toolbarRef.current}
          columns={headers}
          rows={data ? data.dataviz_lpContact : []}
          getRowId={(row) => `${row.lp.lpName}-${row.contact?.email}`}
          loading={loading}
        />
      </Stack>
    </ConnectedPage>
  );
};

export default Contact;
