import { createContext } from 'react';

interface LpCreationModuleNavigationContextInterface {
  getAbsolutePath: (subpath: string) => string;
  editPath: string;
  previousPath: string;
  finalPath?: string;
}

export const LpCreationModuleNavigationContext =
  createContext<LpCreationModuleNavigationContextInterface>({
    getAbsolutePath: () => '',
    editPath: '',
    previousPath: '',
  });
