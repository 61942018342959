import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { CreateOperationForm } from 'business/fund-manager/operation/components/create-operation-form';
import { OperationCreationInput } from 'business/fund-manager/operation/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useCreateOperationMutation } from 'generated/graphql';

export const CreateOperationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createOperation, { loading, error }] = useCreateOperationMutation({
    ignoreResults: false,
    onCompleted: (created) => {
      navigate(
        FundManagerRoutes.OperationCreateIdShares.replace(
          ':operationId',
          created.createOperation.id,
        ),
      );
      enqueueSnackbar(t('successMessage.createOperation'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const onSubmit = async (input: OperationCreationInput) => {
    await createOperation({
      variables: {
        input,
      },
    });
  };

  return (
    <CreateOperationForm onSubmit={onSubmit} loading={loading} error={error} />
  );
};
