import { Slider, Stack } from '@mui/material';
import { useController } from 'react-hook-form';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FormInputProps, FormInputsType } from 'ui/form/form-input-props';
import { Typo } from 'ui/typo';

export type FormInputRangeProps<T extends FormInputsType> = Omit<
  FormInputProps<T>,
  'label'
> & {
  min: number;
  max: number;
  step?: number;
  required?: boolean;
  disabled?: boolean;
  valueFormatter: (value: number) => string;
  label: string;
};

export const FormInputRange = <T extends FormInputsType>({
  name,
  control,
  label,
  required,
  max,
  min,
  step = 1,
  valueFormatter,
  disabled,
}: FormInputRangeProps<T>) => {
  const palette = usePalette();
  const {
    field: { onChange, value },
    fieldState: { error: fieldError },
    formState: { isSubmitting },
  } = useController<{ value: number }>({
    control,
    name: name as 'value',
    rules: { required },
  });

  const handleChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onChange(newValue);
    }
  };

  return (
    <Stack paddingX={2}>
      <Typo color={!!fieldError ? 'dangerMain' : undefined}>
        {label}: <strong>{valueFormatter(value)}</strong>
      </Typo>
      <Stack justifyContent="space-between">
        <Slider
          sx={
            !!fieldError
              ? {
                  color: palette.dangerMain,
                }
              : undefined
          }
          disabled={isSubmitting || disabled}
          value={value}
          min={min}
          step={step}
          max={max}
          getAriaValueText={valueFormatter}
          valueLabelFormat={valueFormatter}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
        <Stack justifyContent="space-between" direction="row">
          <Typo size="sm">{valueFormatter(min)}</Typo>
          <Typo size="sm">{valueFormatter(max)}</Typo>
        </Stack>
      </Stack>
      {fieldError ? (
        <ErrorLabel label={fieldError.message ?? ValidationErrors.GENERIC} />
      ) : null}
    </Stack>
  );
};
