import { ColumnHeaderWithSubHeader } from 'ui/column-header-with-sub-header/column-header-with-sub-header';
import { ColumnHeaderWithSubHeaderProps } from 'ui/column-header-with-sub-header/types';

export const createColumnHeader = ({
  headerName,
  subHeaderName,
  subHeaderElement,
  aggregationFunctionName,
}: ColumnHeaderWithSubHeaderProps) => {
  return (
    <ColumnHeaderWithSubHeader
      headerName={headerName}
      subHeaderName={subHeaderName}
      subHeaderElement={subHeaderElement}
      aggregationFunctionName={aggregationFunctionName}
    />
  );
};
