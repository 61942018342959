import * as yup from 'yup';

import { floatNumberSchema } from '.';

export enum ValidationErrors {
  TOO_SHORT = 'errors.too_short',
  TOO_LONG = 'errors.too_long',
  REQUIRED = 'errors.required',
  ONLY_DIGIT = 'errors.only_digit',
  INVALID = 'errors.field_invalid',
  GENERIC = 'errors.generic',
  NUMBER_TOO_SMALL = 'errors.too_small_number',
  WRONG_EXTENSION = 'errors.wrong_extension',
  EMAIL_INVALID = 'errors.wrong_email_address',
  EMAIL_LOWERCASE = 'errors.lowercaseEmail',
  MAX_IMG_DIMENSIONS_EXCEEDED = 'errors.maxDimensionsExceeded',
}

export type FloatNumberSchemaType = yup.InferType<typeof floatNumberSchema>;
