import { PropsWithChildren } from 'react';

import { InfoCard } from 'ui/info-card';

import styles from './dashboard-card.module.scss';

interface DashboardCardProps extends PropsWithChildren {
  path?: string;
  title: string;
}

export const DashboardCard = ({
  title,
  path,
  children,
}: DashboardCardProps) => (
  <InfoCard className={styles.dashboardCard}>
    <InfoCard.Title path={path}>{title}</InfoCard.Title>
    {children}
  </InfoCard>
);
