import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { CompanyContactModal } from 'business/shared/components/company-contact-modal/company-contact-modal';
import {
  CompanyContact,
  Contact,
  ContactWithId,
} from 'business/shared/services/contact/types';
import { contactSchema } from 'business/shared/services/contact/validation';
import { ContactTypeEnum, PublicationStatus } from 'generated/graphql';
import { ContactFormContextEnum } from 'ui/types';

interface ContactFormProps {
  onClose: () => void;
  context: ContactFormContextEnum;
  error?: ApolloError;
  loading?: boolean;
  contact?: ContactWithId;
  onSubmit: (contact: Contact | ContactWithId) => void;
  onFileUpload: (formData: { filePath: string; uploadDate: Date }) => void;
  onExistingLogoDelete: () => void;
  shouldShowExistingLogo: boolean;
}

export const CompanyContactForm = ({
  error,
  onSubmit,
  loading = false,
  onClose,
  context,
  contact,
  onFileUpload,
  onExistingLogoDelete,
  shouldShowExistingLogo,
  ...rest
}: ContactFormProps) => {
  const { t } = useTranslation();

  const existingContactLogo =
    shouldShowExistingLogo && contact?.logo?.url
      ? {
          url: contact.logo.url,
          name: contact.logo.name,
        }
      : undefined;

  const methods = useForm<CompanyContact>({
    resolver: yupResolver<CompanyContact>(contactSchema),
    defaultValues: {
      title: contact?.title ?? '',
      phoneNumber1: contact?.phoneNumber1 ?? '',
      phoneNumber2: contact?.phoneNumber2 ?? '',
      companyName: contact?.companyName ?? '',
      jobTitle: contact?.jobTitle ?? '',
      comment: contact?.comment ?? '',
      email: contact?.email ?? '',
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
      // we can't spread address object because of undefined values
      // https://react-hook-form.com/docs/useform#defaultValues
      address: {
        id: contact?.address.id ?? '',
        streetLine: contact?.address.streetLine ?? '',
        streetLine2: contact?.address.streetLine2 ?? '',
        city: contact?.address.city ?? '',
        country: contact?.address.country ?? '',
        zipCode: contact?.address.zipCode ?? '',
      },
    },
  });

  const handleSubmit = (input: CompanyContact) => {
    const contactToSubmit = {
      ...input,
      id: contact?.id,
      type: ContactTypeEnum.Individual,
      logo: existingContactLogo ? contact?.logo : undefined,
    };
    onSubmit(contactToSubmit);
  };

  const labelAction = t('pages.contact.form.label.submit', {
    context:
      contact?.publicationStatus === PublicationStatus.PendingValidation
        ? ContactFormContextEnum.VALIDATE
        : context,
  });

  return (
    <FormProvider {...methods}>
      <CompanyContactModal
        {...rest}
        onSubmit={methods.handleSubmit(handleSubmit)}
        onClose={onClose}
        isSubmitting={loading}
        context={context}
        control={methods.control}
        error={error}
        labelAction={labelAction}
        onFileUpload={onFileUpload}
        onExistingLogoDelete={onExistingLogoDelete}
        existingLogo={existingContactLogo}
      />
    </FormProvider>
  );
};
