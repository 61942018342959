import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'translations/hook';

import { GetOperationsQuery } from 'generated/graphql';

const getShareTypeValue = ({
  row: {
    share: { type },
  },
}: {
  row: { share: { type: string } };
}) => type;

export const shareTypeColumn = (
  t: TFunction,
  filters: string[],
): GridColDef<GetOperationsQuery['operations'][number]> => {
  return {
    field: 'shareType',
    type: 'singleSelect',
    valueOptions: filters,
    headerName: t('pages.financialFlows.table.shareType'),
    aggregable: false,
    renderCell: ({ row: { share } }) =>
      share?.type ? getShareTypeValue({ row: { share } }) : '',
    valueGetter: ({ row: { share } }) =>
      share?.type ? getShareTypeValue({ row: { share } }) : '',
    groupingValueGetter: ({ row: { share } }) => {
      return getShareTypeValue({ row: { share } });
    },
  };
};
