import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

type ColorsByRisk = {
  [key: number]: {
    text: string;
    background: string;
  };
};

export const useRiskAssessmentColors = (): ColorsByRisk => {
  const palette = usePalette();

  return {
    0: {
      text: palette.backgroundCard,
      background: palette.backgroundCard,
    },
    1: {
      text: palette.primary[900],
      background: palette.primary[100],
    },
    2: {
      text: palette.primary[900],
      background: palette.primary[400],
    },
    3: {
      text: palette.secondary[900],
      background: palette.secondary[100],
    },
    4: {
      text: palette.secondary[900],
      background: palette.secondary[500],
    },
    5: {
      text: palette.tertiary[400],
      background: palette.tertiary[300],
    },
  };
};
