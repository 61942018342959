import { Avatar, Card, CardContent, Grid, Stack } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { ThemePalette } from 'business/providers/theme/services/types';
import Loader from 'ui/loader';
import { Typo } from 'ui/typo';

import styles from './index.module.scss';

interface Props extends PropsWithChildren {
  title: string;
  subtitle?: string;
  iconColor?: keyof ThemePalette;
  icon: ReactNode;
  loading?: boolean;
  footer?: ReactNode;
}

export const SummaryBar = ({
  title,
  subtitle,
  iconColor,
  icon,
  children,
  loading,
  footer,
}: Props) => {
  const palette = usePalette();
  return (
    <Card className={styles.card}>
      <CardContent>
        <Grid container alignItems="center" justifyContent={'space-between'}>
          <Grid item xs={3}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                variant="rounded"
                sx={{
                  background: iconColor ? palette[iconColor][400] : undefined,
                }}
              >
                {icon}
              </Avatar>
              <Stack>
                <Typo size="2xl" component="h5">
                  {title}
                </Typo>
                {subtitle ? <Typo size="sm">{subtitle}</Typo> : null}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            {loading ? <Loader /> : children}
          </Grid>
        </Grid>
      </CardContent>
      {footer ?? null}
    </Card>
  );
};
