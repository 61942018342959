import 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';
import { useRef } from 'react';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

// CookieConsent from Osana v3 implementation add the Object directly into Window
// As Soon As v4 is available on NPM : Need to change our implementation to a more
// module oriented kind.

declare global {
  interface Window {
    cookieconsent: any;
  }
}

export function ConsentBanner() {
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);

  const palette = usePalette();
  // We assume CC is always defined as the import of cookieconsent is not async.
  const CC = window.cookieconsent;
  CC.initialise({
    container: container.current,
    palette: {
      popup: {
        background: palette.backgroundCookieConsent,
        text: palette.backgroundForm,
      },
      button: {
        background: palette.primary[800],
        color: palette.backgroundForm,
      },
    },
    showLink: false,
    type: 'opt-in',
    cookie: {
      name: 'cookieconsent',
    },
    position: 'bottom-right',
    content: {
      message: t('components.cookies.mainText'),
      deny: t('components.cookies.declineAll').toUpperCase(),
      allow: t('components.cookies.acceptAll').toUpperCase(),
      policy: t('components.cookies.title'),
    },
    revokable: true,
    location: false,
    law: { contryCode: 'FR' },
  });

  return <div ref={container} />;
}
