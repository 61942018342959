import { ActionButton } from 'ui/action-button';

interface Props {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const ModalButton = ({
  label,
  type,
  onClick,
  loading,
  disabled = false,
}: Props) => {
  return (
    <ActionButton
      variant={type !== 'submit' ? 'secondary' : 'submit'}
      type={type}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </ActionButton>
  );
};
