import { PropsWithChildren } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundProvider } from 'business/providers/fund-provider';
import { getCurrencyEnumFromWebPortalEnum } from 'business/shared/services/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  CurrencyEnum,
  useOperationIdentifierByPkQuery,
} from 'generated/graphql';
import { convertPathToTranslationKey } from 'technical/convert-path-to-translation-key';
import { getPreviousUrlPath } from 'technical/get-previous-url-path';
import { QueryStateDisplay } from 'ui/query-state-display';

export const OperationPage = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { operationId } = useParams();
  const { pathname } = useLocation();

  const { data, loading, error } = useOperationIdentifierByPkQuery({
    variables: { id: operationId ?? '' },
    skip: operationId === undefined,
  });

  const previousPath = getPreviousUrlPath(pathname);

  if (loading || error || (operationId && !data?.operation)) {
    return (
      <ConnectedPage path={previousPath}>
        <QueryStateDisplay loading={loading} error={error} />
      </ConnectedPage>
    );
  }

  const fundInAPIFormat = {
    currency: data?.operation?.fund?.currency
      ? getCurrencyEnumFromWebPortalEnum(data?.operation?.fund?.currency)
      : CurrencyEnum.Eur,
    managementCompanyId: data?.operation?.fund?.managementCompanyId,
  };

  const title = t('routes.' + convertPathToTranslationKey(pathname), {
    identifier: data?.operation?.identifier ?? '',
  });

  return (
    <ConnectedPage title={title} path={previousPath}>
      <FundProvider fund={fundInAPIFormat}>{children}</FundProvider>
    </ConnectedPage>
  );
};
