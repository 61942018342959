import PodcastsIcon from '@mui/icons-material/Podcasts';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hook';

import { ContactsCard } from 'business/fund-manager/lp/components/contacts-card';
import GeneralInfosCard from 'business/fund-manager/lp/components/general-infos-card';
import { LpFinancialFlowCard } from 'business/fund-manager/lp/components/lp-financial-flow-card';
import { LpSubscriptions } from 'business/fund-manager/lp/components/lp-subscriptions-card';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { DiligenceCard } from 'business/shared/components/diligence-card';
import DocumentationCard from 'business/shared/components/documentation-card';
import { useFeatureFlag } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { useFundManagerDashboardLpQuery } from 'generated/graphql';
import {
  convertFrom10X8toNumber,
  formatNumber,
} from 'technical/currency/formatters';
import { getNinetyDaysAgo } from 'technical/date';
import { isNumber } from 'technical/number/is-number';
import { DashboardGrid } from 'ui/dashboard-grid/dashboard-grid';
import { InfoItem, InfoItemProps } from 'ui/info-item';
import { QueryStateDisplay } from 'ui/query-state-display';
import { StatInfoCard } from 'ui/stat-info-card/stat-info-card';

export const LpDashboard = () => {
  const { t } = useTranslation();
  const { lpId = '' } = useParams();
  const { currency } = useFund();

  const displayDocumentation = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_DOCUMENTATION,
  );

  const displayRiskAssessment = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_RISK_ASSESSMENT,
  );

  const displayLpPerformance = useFeatureFlag(
    FeatureFlagEnum.DISPLAY_LP_PERFORMANCE,
  );

  const startDate = useMemo(() => getNinetyDaysAgo(), []);

  const { data, loading, error } = useFundManagerDashboardLpQuery({
    variables: {
      // here I choose to let splitted all the query params for easier maintenance
      // but perhaps it's a wrong idea :/
      lpRelatedWebDocumentCountInput: { lpId, startDate },
      lpKYCDocumentsInput: {
        lpId,
        expiredOnly: true,
      },
      lpId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.lpDashboardInformation.lpInformation) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const { lpDashboardInformation } = data;
  const lpView = lpDashboardInformation.lpInformation;

  const lpName = lpView.lpName ?? '';
  const logoDocumentId = lpView.logoDocumentId;

  const pendingValidationContacts =
    lpDashboardInformation.lpContactsPendingValidationCount;

  const numberOfExpiredDocuments = data.lpKYCDocuments.length ?? 0;

  const performanceItems: (Omit<InfoItemProps, 'value'> & {
    description: string;
    value: number;
  })[] = [
    {
      value: data.lpPerformance.tvpi,
      description: t('pages.fundManager.lp.dashboard.performance.tvpi'),
      imageSource: <PodcastsIcon />,
      iconColor: 'primary',
    },
    {
      value: data.lpPerformance.dpi,
      description: t('pages.fundManager.lp.dashboard.performance.dpi'),
      imageSource: <PodcastsIcon />,
      iconColor: 'secondary',
    },
    {
      value: data.lpPerformance.rvpi,
      description: t('pages.fundManager.lp.dashboard.performance.rvpi'),
      imageSource: <PodcastsIcon />,
      iconColor: 'neutral',
    },
  ];

  return (
    <DashboardGrid>
      <GeneralInfosCard
        infos={lpView}
        lpName={lpName}
        logoDocumentId={logoDocumentId}
      />
      <Stack spacing={2}>
        <ContactsCard
          lpContacts={lpDashboardInformation.lpContacts}
          pendingValidationContacts={pendingValidationContacts}
        />

        {displayDocumentation ? (
          <DocumentationCard
            title={t('pages.dashboard.cards.documentation.title')}
            data={{
              afterDateDocumentCount:
                data.lpRelatedWebDocumentCount.afterDateDocumentCount ?? 0,
              totalDocumentCount:
                data.lpRelatedWebDocumentCount.totalDocumentCount ?? 0,
            }}
            path={FundManagerRoutes.LpIdDocument.replace(':lpId', lpId)}
          />
        ) : null}
      </Stack>
      {displayRiskAssessment ? (
        <DiligenceCard
          title={t('pages.dashboard.cards.riskAssessment.title')}
          path={FundManagerRoutes.LpIdDiligenceAmlRiskAssessment.replace(
            ':lpId',
            lpId,
          )}
          riskScore={
            lpView.riskScore && isNumber(lpView.riskScore)
              ? +lpView.riskScore
              : undefined
          }
          conformity={lpView.isComplete ?? false}
          numberOfdocumentsToUpdate={numberOfExpiredDocuments}
        />
      ) : null}
      <LpFinancialFlowCard lpId={lpId} />
      <LpSubscriptions lpId={lpId} currency={currency} />
      {displayLpPerformance ? (
        <StatInfoCard
          title={t('pages.fundManager.lp.dashboard.performance.title')}
        >
          {performanceItems.map((item) => (
            <InfoItem
              key={item.description}
              {...item}
              value={
                item.value
                  ? `${formatNumber(convertFrom10X8toNumber(item.value), {
                      precision: 2,
                    })} x`
                  : '-'
              }
              size="dense"
            />
          ))}
        </StatInfoCard>
      ) : null}
    </DashboardGrid>
  );
};
