import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'translations/hook';

import { GraphPanelLp } from 'business/lp-platform/financial-flows/components/graph-panel';
import { Synthesis } from 'business/lp-platform/financial-flows/components/synthesis';
import { buildFinancialFlowNestedClauses } from 'business/lp-platform/financial-flows/services/financial-flows-clause-builder';
import { getFinancialFlowsColumnDefinition } from 'business/lp-platform/financial-flows/services/get-financial-flows-column-definition';
import {
  FinancialFlowsFilterType,
  isGetCashflowQuery,
  isGetOperationsQuery,
} from 'business/lp-platform/financial-flows/types';
import ConnectedPage from 'business/user/helpers/connected-page';
import {
  Dataviz_Operation_Bool_Exp,
  GetOperationsQuery,
  useGetCashflowQuery,
  useGetOperationsQuery,
} from 'generated/graphql';
import { DEFAULT_WHERE_CLAUSE } from 'technical/filter/constants';
import { handleMultipleFilter } from 'technical/filter/filters';
import {
  FuzzyFilterDataKey,
  FuzzyFilterEnabledColumn,
} from 'technical/filter/fuzzy-filter/constants';
import { handleFuzzyFiltering } from 'technical/filter/fuzzy-filter/fuzzy-filter';
import { removeDuplicates } from 'technical/remove-duplicates';
import { Table, ToolbarContainer } from 'ui/table';

const FinancialFlows = () => {
  const { t } = useTranslation();
  const [searchedTerm, setSearchedTerm] = useState('');
  const [whereClause, setWhereClause] = useState(DEFAULT_WHERE_CLAUSE);
  const [customFilters, setCustomFilters] = useState<FinancialFlowsFilterType>({
    funds: [],
    shares: [],
    identifiers: [],
  });
  const toolbarRef = useRef<HTMLDivElement>(null);
  const headers: GridColDef<GetOperationsQuery['operations'][number]>[] =
    getFinancialFlowsColumnDefinition(t, customFilters);

  const {
    data,
    loading,
    refetch: refetchOperations,
  } = useGetOperationsQuery({
    variables: DEFAULT_WHERE_CLAUSE,
  });

  const { data: cashflowData, refetch: refetchCashflow } = useGetCashflowQuery({
    variables: DEFAULT_WHERE_CLAUSE,
  });

  const refetch = async (where: { where: Dataviz_Operation_Bool_Exp }) => {
    await refetchCashflow(where);
    await refetchOperations(where);
  };

  // If we have no data it loops for ever
  // thus I added this useEffect
  // we should ask someone knowledgeable about it
  useEffect(() => {
    if (data && !customFilters.funds.length) {
      setCustomFilters({
        funds: removeDuplicates(
          data.operations.map(({ share }) => share.fund),
          'name',
        ),
        shares: removeDuplicates(
          data.operations.map(({ share }) => share),
          'type',
        ),
        identifiers: removeDuplicates(data.operations, 'type'),
      });
    }
  }, [data, customFilters.funds.length]);

  const rows = handleFuzzyFiltering({
    data: { ...data, ...cashflowData },
    searchedTerm,
    dataKeys: [
      FuzzyFilterDataKey.FinancialFlowOperation,
      FuzzyFilterDataKey.FinancialFlowCashFlow,
    ],
    enabledColumns: [
      FuzzyFilterEnabledColumn.ShareType,
      FuzzyFilterEnabledColumn.FundName,
    ],
    relatedKey: FuzzyFilterDataKey.FinancialFlowCashFlow,
  }) || { ...data, ...cashflowData };

  if (Object.keys(whereClause.where).length !== 0) {
    refetch(whereClause);
  }

  return (
    <ConnectedPage title={t('pages.financialFlows.title')}>
      <Stack spacing={2}>
        <ToolbarContainer
          toolbarRef={toolbarRef}
          filterPlaceholders={[
            t('pages.financialFlows.table.fund'),
            t('pages.financialFlows.table.shareType'),
          ]}
        />
        {rows && isGetOperationsQuery(rows) ? (
          <Synthesis data={rows.operations} />
        ) : null}
        {rows && isGetCashflowQuery(rows) ? (
          <GraphPanelLp data={rows.cashflow} />
        ) : null}
        <Table<GetOperationsQuery['operations'][number]>
          toolbarAnchor={toolbarRef.current}
          title={t('pages.financialFlows.table.history')}
          columns={headers}
          rows={rows && isGetOperationsQuery(rows) ? rows.operations : []}
          getRowId={(row) => row.id ?? ''}
          loading={loading}
          onFilterModelChange={(model) => {
            handleMultipleFilter(
              model,
              setWhereClause,
              buildFinancialFlowNestedClauses,
              setSearchedTerm,
            );
          }}
          filterMode="server"
          initialState={{
            pinnedColumns: { left: ['date', 'fundName', 'shareType'] },
            aggregation: {
              model: {
                drawdown: 'sum',
                distribution: 'sum',
                recallableDistribution: 'sum',
              },
            },
          }}
          columnHeaderHeight={60}
        />
      </Stack>
    </ConnectedPage>
  );
};

export default FinancialFlows;
