export function convertPathToTranslationKey(path: string): string {
  return (
    path
      // replace id
      .replace(/[0-9a-fA-F-]{36}/g, 'id')
      // replace all '/'
      .replaceAll('/', '_')
      // remove first /
      .replace(/^_/, '')
  );
}
