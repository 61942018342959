import { LpCreationStepEnum, OnBoardingStatusEnum } from 'generated/graphql';

import { LpValidationLegalEntity } from './types';

export const getDeclineReason = ({
  step,
  status,
  declineReasons,
}: {
  step: LpCreationStepEnum;
  status?: OnBoardingStatusEnum;
  declineReasons: LpValidationLegalEntity['declineReasons'];
}) => {
  if (status === OnBoardingStatusEnum.Validated) {
    return undefined;
  }

  return declineReasons.find((reason) => reason.step === step)?.comment;
};
