import { Stack } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { Card } from 'ui/card';

import styles from './index.module.scss';

interface FormCardProps {
  children: ReactNode;
  className?: string;
  padding?: number;
  spacing?: number;
}
export const FormCard = ({
  children,
  className,
  padding,
  spacing,
}: FormCardProps) => (
  <Card className={classNames(styles.formCard, className)}>
    <Stack padding={padding ?? 2} spacing={spacing ?? 4}>
      {children}
    </Stack>
  </Card>
);
