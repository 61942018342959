import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { SubscriptionDocumentTypeEnum } from 'generated/graphql';
import { IFile } from 'technical/file-management/types';
import { ErrorLabel } from 'ui/error-label';
import {
  FormInputCheckBox,
  FormInputDate,
  FormInputFiles,
  FormInputText,
  FormSection,
  SelectInput,
} from 'ui/form';
import { FormBankDetailsPart } from 'ui/form-bank-details-part/form-bank-details';
import { Option } from 'ui/types';

interface SubscriptionFileInputProps {
  type: SubscriptionDocumentTypeEnum;
  name: string;
  onFilesUpload: (
    type: SubscriptionDocumentTypeEnum,
    e: ChangeEvent<HTMLInputElement>,
  ) => Promise<IFile[]>;
}

const SubscriptionFileInput = ({
  name,
  type,
  onFilesUpload,
}: SubscriptionFileInputProps) => {
  const { t } = useTranslation();
  return (
    <FormInputFiles
      name={name}
      label={t(`pages.fundManager.subscriptionDocument.type.${type}`)}
      onFilesUpload={(e) => onFilesUpload(type, e)}
      limit={1}
    />
  );
};

interface SubscriptionFormProps
  extends Pick<SubscriptionFileInputProps, 'onFilesUpload'> {
  readOnlyLpId?: boolean;
  readOnlyFundId?: boolean;
  onCompleted?: () => void;
  fundsOptions: Option[];
  lpsOptions: Option[];
  sharesOptions: Option[];
  error?: ApolloError;
  sharesLoading?: boolean;
  onLpChange: (id: string | null) => void;
  onFundChange: (id: string | null) => void;
}

export const SubscriptionForm = ({
  readOnlyFundId,
  readOnlyLpId,
  lpsOptions,
  fundsOptions,
  error,
  sharesLoading,
  sharesOptions,
  onLpChange,
  onFundChange,
  onFilesUpload,
}: SubscriptionFormProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <FormSection
        title={t('pages.fundManager.subscription.form.header.title')}
      >
        <Stack direction="row" spacing={2}>
          <SelectInput
            options={fundsOptions}
            disabled={readOnlyFundId}
            fullWidth
            label={t('pages.fundManager.subscription.form.fund.label')}
            name="fundId"
            required
            onChange={onFundChange}
          />
          <SelectInput
            label={t('pages.fundManager.subscription.form.lp.label')}
            name="lpId"
            fullWidth
            onChange={onLpChange}
            required
            disabled={readOnlyLpId}
            options={lpsOptions}
          />
          <FormInputText
            fullWidth
            required
            type="number"
            name="amount"
            label={t(
              'pages.fundManager.subscription.form.subscriptionAmount.label',
            )}
            placeholder={t(
              'pages.fundManager.subscription.form.subscriptionAmount.placeholder',
            )}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormInputDate
            name="date"
            label={t(
              'pages.fundManager.subscription.form.subscriptionDate.label',
            )}
            required
            fullWidth
          />
          <SelectInput
            name="shareId"
            label={t('pages.fundManager.subscription.form.share.label')}
            fullWidth
            required
            loading={sharesLoading}
            options={sharesOptions}
          />
        </Stack>

        <Stack justifyContent="end" direction="row">
          <FormInputCheckBox
            name="taxOption"
            label={t('pages.fundManager.subscription.form.taxOption')}
          />
        </Stack>
      </FormSection>

      <FormSection
        title={t('pages.fundManager.lpCreation.form.KYC.bankDetails.title')}
      >
        <FormBankDetailsPart name="bankDetails" />
      </FormSection>
      <FormSection
        lastOne
        title={t('pages.fundManager.subscription.form.document.sectionTitle')}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="stretch"
          spacing={2}
        >
          <Stack flex={1} alignItems="stretch">
            <SubscriptionFileInput
              name="subscriptionLetters"
              type={SubscriptionDocumentTypeEnum.SubscriptionLetter}
              onFilesUpload={onFilesUpload}
            />
          </Stack>
          <Stack flex={1} alignItems="stretch">
            <SubscriptionFileInput
              name="sideLetters"
              type={SubscriptionDocumentTypeEnum.SideLetter}
              onFilesUpload={onFilesUpload}
            />
          </Stack>
        </Stack>
      </FormSection>

      {error ? <ErrorLabel label={error.message} /> : null}
    </Stack>
  );
};
