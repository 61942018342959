import { useState } from 'react';

import { ToggleButtonOption } from './type';

interface Props {
  options: ToggleButtonOption[];
  value?: string;
  allowEmpty?: boolean;
}

export const useToggleButton = ({
  options,
  value: valueRaw,
  allowEmpty,
}: Props) => {
  const [alignment, setAlignment] = useState<string | undefined>(() => {
    if (valueRaw) {
      return valueRaw;
    }
    if (!allowEmpty && options.length) {
      return options[0].value ?? options[0].label;
    }
    return undefined;
  });

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLabel: string,
  ) => {
    const selectedOption = options.find(({ label, value }) => {
      if (value) {
        return value === newLabel;
      }
      return label === newLabel;
    });

    if (selectedOption) {
      selectedOption.action();
      setAlignment(newLabel);
    }
  };

  return { alignment, handleChange };
};
