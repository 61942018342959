import { Stack, Typography } from '@mui/material';

import {
  MergeFieldItem,
  MergedFieldProps,
} from 'business/fund-manager/settings/template/components/template-merge-field-item';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

interface Props {
  contextName: string;
  items: MergedFieldProps[];
}

export const MergeFieldList = ({ contextName, items }: Props) => {
  const palette = usePalette();

  return (
    <Stack>
      <Stack sx={{ backgroundColor: palette.backgroundCard }} padding={1}>
        <Typography>{contextName}</Typography>
      </Stack>
      {items.map((item) => (
        <MergeFieldItem
          name={item.name}
          description={item.description}
          key={item.name}
        />
      ))}
    </Stack>
  );
};
