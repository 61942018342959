export type Operation = {
  row: {
    type: string;
    amount: number;
  };
};

export type OperationCashflow = {
  id: string;
  type: string;
  amount: number;
  date: string;
};

export type GraphData = {
  operationPk: string;
  operation: string;
  amount: number;
  dueDate: string;
};

export enum OperationType {
  Drawdown = 'drawdown',
  Distribution = 'distribution',
  RecallableDistribution = 'recallableDistribution',
}

export interface FinancialFlowsFilterType {
  funds: string[];
  shares: string[];
  identifiers: string[];
}
