import { ColDef } from 'ag-grid-community';
import { TFunction } from 'translations/hook';

import { GetFundReportingContactsQuery } from 'generated/graphql';

export function getFundReportingContactsColumnDefinition(t: TFunction): ColDef<
  GetFundReportingContactsQuery['fundReportingContacts'][number] & {
    fullName: string;
    lpName: string;
  }
>[] {
  return [
    {
      field: 'lpName',
      headerName: t('pages.fundManager.fundDiligence.table.lpName'),
    },
    {
      field: 'fullName',
      headerName: t('pages.contact.table.contactName'),
    },
    {
      field: 'email',
      hide: true,
      headerName: t('pages.contact.table.email'),
    },
  ];
}
