import { useTranslation } from 'translations/hook';

import ThemedBrownButton from 'ui/themed-brown-button';

interface Props {
  handleOpenModal?: () => void;
}

const AddShareButton = ({ handleOpenModal }: Props) => {
  const { t } = useTranslation();
  return (
    <ThemedBrownButton
      text={t('pages.fundManager.fundShares.shareList.table.addShare')}
      onClick={handleOpenModal}
    />
  );
};

export default AddShareButton;
