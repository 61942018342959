import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { i18n } from 'translations';
import { LanguageEnum } from 'translations/types';

import { getLanguageLocale } from 'technical/localization';

export interface ILanguageContext {
  locale: string;
  setLocale: (locale: string) => void;
  language: LanguageEnum;
}

const LanguageContext = createContext<ILanguageContext>({
  locale: '',
  language: LanguageEnum.en,
  setLocale: () => {},
});

export const useLanguageContext = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [locale, setLocale] = useState<string>(i18n.language);

  const onLocaleChange = (newLocale: string) => {
    i18n.changeLanguage(newLocale);
    setLocale(newLocale);
  };

  return (
    <LanguageContext.Provider
      value={{
        locale,
        language: i18n.resolvedLanguage as LanguageEnum,
        setLocale: onLocaleChange,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getLanguageLocale(locale)}
      >
        {children}
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};
