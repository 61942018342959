import { merge } from 'lodash';

import { formatConfigColors } from './format-config-colors';
import { ThemePaletteConfig } from './types';

const deepCopy = <T>(value: T): T => {
  return JSON.parse(JSON.stringify(value)) as T;
};

export const getPalette = ({
  configColors,
  defaultPalette,
}: {
  configColors?: string;
  defaultPalette: ThemePaletteConfig;
}) => {
  try {
    if (!configColors) {
      return formatConfigColors(defaultPalette);
    }

    // merge client color palette with our default palette (override only the values set by the client)
    const mergedPaletteConfig = merge(
      deepCopy(defaultPalette),
      JSON.parse(configColors),
    );

    return formatConfigColors(mergedPaletteConfig);
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(
      'ERROR while formatting config colors, returning default palette',
      error,
    );
    return formatConfigColors(defaultPalette);
  }
};
