import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyContactManager } from 'business/fund-manager/portfolio/company/components/company-contact-manager';
import { CompanyCreationWizzard } from 'business/fund-manager/portfolio/company/components/company-creation-wizzard';
import { useCompanyContacts } from 'business/fund-manager/portfolio/company/services/hooks/use-company-contacts';
import { CompanyCreationStepEnum } from 'business/fund-manager/portfolio/company/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  Web_Portal_PublicationStatus_Enum,
  useUpdateCompanyMutation,
} from 'generated/graphql';
import { ValidationErrors } from 'technical/validation/types';
import { QueryStateDisplay } from 'ui/query-state-display';

export const CompanyCreationContact = () => {
  const { fundId = '', companyId = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, error, contacts, refetch } = useCompanyContacts({
    companyId,
  });

  const [updateCompany] = useUpdateCompanyMutation({
    onError: () => {
      enqueueSnackbar(t(ValidationErrors.GENERIC), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.createCompany'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      navigate(
        FundManagerRoutes.PortfolioFundIdCompany.replace(':fundId', fundId),
      );
    },
  });

  const haveLegalRepresentative = contacts.some((c) => c.isLegalRepresentative);

  const onSubmit = () => {
    updateCompany({
      variables: {
        id: companyId,
        input: {
          publicationStatus: Web_Portal_PublicationStatus_Enum.Validated,
        },
      },
    });
  };

  return (
    <CompanyCreationWizzard
      activeStep={CompanyCreationStepEnum.contacts}
      rawChildren
      onSubmit={haveLegalRepresentative ? onSubmit : undefined}
      previousPath={FundManagerRoutes.PortfolioFundIdCompanyCreateIdDocumentation.replace(
        ':fundId',
        fundId,
      ).replace(':companyId', companyId)}
    >
      {loading || error ? (
        <QueryStateDisplay loading={loading} error={error} />
      ) : (
        <Stack alignSelf="stretch">
          <CompanyContactManager
            companyId={companyId}
            contacts={contacts}
            onAdded={refetch}
            onDeleted={refetch}
            onUpdated={refetch}
          />
        </Stack>
      )}
    </CompanyCreationWizzard>
  );
};
