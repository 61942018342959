import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { getOperationColumnDefinition } from 'business/fund-manager/operation/services/get-operation-column-definition';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useAppContext } from 'business/providers/app-provider';
import { router } from 'business/router/services';
import {
  GetOperationsWithDetailsQuery,
  useGetOperationsWithDetailsQuery,
} from 'generated/graphql';
import { removeDuplicates } from 'technical/remove-duplicates';
import { ActionButton } from 'ui/action-button';
import { AlternativeTable } from 'ui/alternative-table';
import Spacer from 'ui/spacer';

const getFilters = (
  operations: GetOperationsWithDetailsQuery['getOperationsWithDetails'],
) => ({
  fundNames: removeDuplicates(operations, 'fundName'),
  status: removeDuplicates(operations, 'status'),
});

export const OperationList = () => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { data, loading } = useGetOperationsWithDetailsQuery({
    fetchPolicy: 'network-only',
  });
  const navigate = useNavigate();

  const filters = getFilters(data?.getOperationsWithDetails ?? []);
  const headers = getOperationColumnDefinition({
    t,
    customFilters: filters,
    navigate,
    managementCompanyType: user?.managementCompany?.type,
  });

  const handleClick = () => {
    router.navigate(FundManagerRoutes.OperationCreate);
  };

  return (
    <Stack spacing={2}>
      <Spacer justify="end">
        <ActionButton
          variant="submit"
          onClick={handleClick}
          data-test-id="create-operation-button"
        >
          {t('pages.fundManager.operation.createNewOperation')}
        </ActionButton>
      </Spacer>
      <AlternativeTable<
        GetOperationsWithDetailsQuery['getOperationsWithDetails'][number]
      >
        fileName="operation-list"
        columnDefs={headers}
        rowData={data?.getOperationsWithDetails ?? []}
        loading={loading}
        domLayout="autoHeight"
        displaySidebar
      />
    </Stack>
  );
};
