import 'technical/error-reporting'; // Import it because datadog want it initialized first
import ReactDOM from 'react-dom/client';

import './translations';
import 'technical/analytics/autolog-page-viewed';
import { App } from 'business/app';

const root = document.getElementById('root');
if (!root) {
  throw new Error('Root not found');
}

ReactDOM.createRoot(root).render(<App />);
