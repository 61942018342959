import { FeatureFlagEnum } from 'business/shared/services/types';
import { useConfigClientQuery } from 'generated/graphql';
import logger from 'technical/logger';

const isFeatureFlagEnum = (featureFlag: any): featureFlag is FeatureFlagEnum =>
  Object.values(FeatureFlagEnum).includes(featureFlag);

export const useLoadConfig = () => {
  const { data, loading, error } = useConfigClientQuery();

  if (error) {
    logger.error('[Failed] load feature flags', error?.message);
  }

  if (loading || !data) {
    return {
      configLoaded: false,
      activeFeatureFlags: [] as FeatureFlagEnum[],
      configClient: {},
    };
  }

  return {
    configLoaded: true,
    activeFeatureFlags: data.featureFlag
      .map(({ key }) => key)
      .filter(isFeatureFlagEnum),
    configClient: data.configClientVariables.reduce<Record<string, string>>(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    ),
  };
};
