import { GetLpCountryQuery, RiskAssessmentListQuery } from 'generated/graphql';

import { getCountryRiskRegion } from './get-country-risk';
import { LpAmlRiskForm } from './types';

const createData = (
  name: string,
  value?: string,
  riskvalue?: number,
  vigilanceValue?: string,
) => {
  return { name, value, riskvalue, vigilanceValue };
};

export const createAmlTableRows = ({
  riskAssessmentFormValues,
  lpCountryCode,
  data,
}: {
  riskAssessmentFormValues: LpAmlRiskForm;
  lpCountryCode: GetLpCountryQuery['web_portal_lpView'][0]['country'];
  data?: RiskAssessmentListQuery;
}) => {
  const interactionsWithInvestors = data?.interactionsWithInvestors.find(
    ({ id }: { id: string }) =>
      id === riskAssessmentFormValues.interactionsWithInvestors,
  );

  const lpTransparency = data?.lpTransparency.find(
    ({ id }: { id: string }) => id === riskAssessmentFormValues.lpTransparency,
  );

  const structureTransparency = data?.structureTransparency.find(
    ({ id }: { id: string }) =>
      id === riskAssessmentFormValues.structureTransparency,
  );

  const lpNature = data?.lpNature.find(
    ({ id }: { id: string }) => id === riskAssessmentFormValues.lpNature,
  );

  const countryRiskRegion = getCountryRiskRegion(lpCountryCode);

  const lpCountry = data?.lpCountry.find(
    (country) => country.fieldName === countryRiskRegion,
  );

  return [
    createData(
      'lpNature',
      lpNature?.fieldName ?? undefined,
      lpNature?.riskValue ?? undefined,
      lpNature?.vigilanceValue ?? undefined,
    ),
    createData(
      'lpCountry',
      lpCountryCode ?? undefined,
      lpCountry?.riskValue ?? undefined,
      lpCountry?.vigilanceValue ?? undefined,
    ),
    createData(
      'structureTransparency',
      structureTransparency?.fieldName ?? undefined,
      structureTransparency?.riskValue ?? undefined,
      structureTransparency?.vigilanceValue ?? undefined,
    ),
    createData(
      'lpTransparency',
      lpTransparency?.fieldName ?? undefined,
      lpTransparency?.riskValue ?? undefined,
      lpTransparency?.vigilanceValue ?? undefined,
    ),
    createData(
      'interactionsWithInvestors',
      interactionsWithInvestors?.fieldName ?? undefined,
      interactionsWithInvestors?.riskValue ?? undefined,
      interactionsWithInvestors?.vigilanceValue ?? undefined,
    ),
  ];
};
