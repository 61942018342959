import { ColDef } from 'ag-grid-community';
import { TFunction, useTranslation } from 'translations/hook';

import { getCurrencyEnumFromWebPortalEnum } from 'business/shared/services/types';
import {
  CurrencyEnum,
  useGetLpEscrowAccountHistoryQuery,
  Web_Portal_CashflowEmittedBy_Enum,
  Web_Portal_CashflowType_Enum,
} from 'generated/graphql';
import { AgGridColumnTypesEnum } from 'technical/ag-grid/constants';
import { AlternativeTable } from 'ui/alternative-table';
import { QueryStateDisplay } from 'ui/query-state-display';

type EscrowCashFlowData = {
  date: Date;
  fundName: string;
  shareName: string;
  identifier: string;
  numericAmount: number;
  currency?: CurrencyEnum;
};

const getLpEscrowHistoryCol = (t: TFunction): ColDef<EscrowCashFlowData>[] => [
  {
    headerName: t('pages.fundManager.lps.escrowAccount.table.dueDate'),
    type: AgGridColumnTypesEnum.DATE,
    field: 'date',
    filter: true,
  },
  {
    field: 'fundName',
    filter: true,
    headerName: t('pages.fundManager.lps.escrowAccount.table.fund'),
  },
  {
    field: 'shareName',
    filter: true,
    headerName: t('pages.fundManager.lps.escrowAccount.table.share'),
  },
  {
    field: 'identifier',
    filter: true,
    headerName: t(
      'pages.fundManager.lps.escrowAccount.table.operationIdentifier',
    ),
  },
  {
    field: 'numericAmount',
    headerName: t('pages.fundManager.lps.escrowAccount.table.amount'),
    type: AgGridColumnTypesEnum.AMOUNT_WITH_10X8_DIVIDER,
  },
];

const getWhereClause = ({
  lpId,
  shareId,
  fundId,
}: EscrowCashflowsHistoryProps) => {
  const baseWhere = {
    operationShareLp: {
      lpId: { _eq: lpId },
    },
    type: { _eq: Web_Portal_CashflowType_Enum.EscrowAccountUse },
  };

  if (shareId) {
    return {
      ...baseWhere,
      operationShareLp: {
        ...baseWhere.operationShareLp,
        operationShare: {
          shareId: { _eq: shareId },
        },
      },
    };
  }

  if (fundId) {
    return {
      ...baseWhere,
      operationShareLp: {
        ...baseWhere.operationShareLp,
        operationShare: {
          share: { fundId: { _eq: fundId } },
        },
      },
    };
  }

  return baseWhere;
};

type EscrowCashflowsHistoryProps = {
  lpId: string;
  shareId?: string;
  fundId?: string;
};

export const EscrowCashflowsHistory = (props: EscrowCashflowsHistoryProps) => {
  const { t } = useTranslation();
  const { loading, error, data } = useGetLpEscrowAccountHistoryQuery({
    variables: {
      where: getWhereClause(props),
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <QueryStateDisplay error={error} />;
  }

  const formattedChartData = data?.cashflowsHistory
    ? data.cashflowsHistory.map((cashflow) => {
        return {
          date: cashflow.operationShareLp.operationShare.operation.date,
          fundName: cashflow.operationShareLp.operationShare.share.fund.name,
          shareName: cashflow.operationShareLp.operationShare.share.name,
          identifier:
            cashflow.operationShareLp.operationShare.operation.identifier,
          numericAmount:
            cashflow.emittedBy === Web_Portal_CashflowEmittedBy_Enum.Investor
              ? -1 * cashflow.numericAmount
              : cashflow.numericAmount ?? 0,
          currency: getCurrencyEnumFromWebPortalEnum(
            cashflow.operationShareLp.operationShare.share.fund.currency,
          ),
        };
      })
    : [];

  const headers = getLpEscrowHistoryCol(t);

  return (
    <AlternativeTable<EscrowCashFlowData>
      fileName="cashflow-history"
      rowData={formattedChartData}
      columnDefs={headers}
      domLayout="autoHeight"
      loading={loading}
      displaySidebar
    />
  );
};
