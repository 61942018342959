/**
 * Checks if the given value is a number or a string representation of a number.
 *
 * @param value - The value to be checked.
 * @returns `true` if the value is a number or a string that can be converted to a valid number,
 *          `false` otherwise.
 */
export function isNumber(value: unknown): value is number {
  if (typeof value === 'number' && !isNaN(value)) {
    return true;
  }
  if (typeof value === 'string') {
    const valueToNumber = parseInt(value);
    return !isNaN(valueToNumber);
  }
  return false;
}
