import { PropsWithChildren } from 'react';

import { FundContext, FundContextValue } from './context';

interface FundProviderProps extends PropsWithChildren {
  fund: FundContextValue;
}
export const FundProvider = ({ fund, children }: FundProviderProps) => (
  <FundContext.Provider value={fund}>{children}</FundContext.Provider>
);
