import { CurrencyEnum } from 'generated/graphql';
import { Amount } from 'ui/amount';
import { TypoProps } from 'ui/typo';

interface Props extends TypoProps {
  value: number;
  currency: CurrencyEnum;
}

export const StatusCardAmount = ({
  value,
  currency,
  ...restTypoProps
}: Props) => {
  return (
    <Amount value={value} currency={currency} size="xl" {...restTypoProps} />
  );
};
