import { useTranslation } from 'translations/hook';

import { FundShareTransferForm } from 'business/fund-manager/fund/pages/fund-share-transfer-form';
import useModal from 'technical/modal/use-modal';
import { ActionButton } from 'ui/action-button';
import { CustomModal } from 'ui/custom-modal';

interface Props {
  fundId: string;
  onCompleted?: () => void;
}

export const AddShareTransferButton = ({ fundId, onCompleted }: Props) => {
  const { t } = useTranslation();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <ActionButton variant="submit" onClick={handleOpenModal}>
        {t('pages.fundManager.shareTransfer.add')}
      </ActionButton>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        component={
          <FundShareTransferForm
            fundId={fundId}
            handleClose={handleCloseModal}
            onCompleted={onCompleted}
          />
        }
      />
    </>
  );
};
