import * as yup from 'yup';

import { addressRequiredSchema } from 'business/shared/services/validation';
import {
  CompanyDocumentTypeEnum,
  DefaultDataRankEnum,
  DefaultPeriodTypeEnum,
  WebCompanyInstrumentTypeEnum,
  WebCompanyTransactionCreationModeEnum,
  WebCompanyTransactionTypeEnum,
  Web_Portal_CompanyDealSourcingEnum_Enum,
  Web_Portal_CompanyDealTypeEnum_Enum,
  Web_Portal_CompanyFundStakeEnum_Enum,
  Web_Portal_CompanyListedOnStockExchangeEnum_Enum,
  Web_Portal_CompanyOperationTypeEnum_Enum,
} from 'generated/graphql';
import { IFile } from 'technical/file-management/types';
import { uploadedFileSchema } from 'technical/file-management/validation';
import { nonEmptyStringSchema } from 'technical/validation';
import { ValidationErrors } from 'technical/validation/types';

import { Quarters } from './types';

export const companyInvestmentSchema = yup.object({
  currency: nonEmptyStringSchema.defined(),
  listedOnStockExchange: yup
    .mixed<Web_Portal_CompanyListedOnStockExchangeEnum_Enum>()
    .oneOf(Object.values(Web_Portal_CompanyListedOnStockExchangeEnum_Enum))
    .required(),
  dealSourcing: yup
    .mixed<Web_Portal_CompanyDealSourcingEnum_Enum>()
    .oneOf(Object.values(Web_Portal_CompanyDealSourcingEnum_Enum))
    .required(),
  dealType: yup
    .mixed<Web_Portal_CompanyDealTypeEnum_Enum>()
    .oneOf(Object.values(Web_Portal_CompanyDealTypeEnum_Enum))
    .required(),
  operationType: yup
    .mixed<Web_Portal_CompanyOperationTypeEnum_Enum>()
    .oneOf(Object.values(Web_Portal_CompanyOperationTypeEnum_Enum))
    .required(),
  fundStake: yup
    .mixed<Web_Portal_CompanyFundStakeEnum_Enum>()
    .oneOf(Object.values(Web_Portal_CompanyFundStakeEnum_Enum))
    .required(),
  fundOnwershipShareHolding: yup.number().positive().defined().required(),
});

const instrumentTypeSchema = yup
  .mixed<WebCompanyInstrumentTypeEnum>()
  .oneOf(Object.values(WebCompanyInstrumentTypeEnum))
  .required('errors.required');

const transactionTypeSchema = yup
  .mixed<WebCompanyTransactionTypeEnum>()
  .oneOf(Object.values(WebCompanyTransactionTypeEnum))
  .required('errors.required');

const transactionCreationModeSchema = yup
  .mixed<WebCompanyTransactionCreationModeEnum>()
  .oneOf(Object.values(WebCompanyTransactionCreationModeEnum))
  .required('errors.required');

export const instrumentInputSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  companyId: nonEmptyStringSchema,
  identifier: nonEmptyStringSchema,
  type: instrumentTypeSchema,
});

export const transactionInputSchema = yup.object({
  quantity: yup
    .number()
    .min(1)
    .required('errors.required')
    .test(
      'transaction-quantity-validation',
      'errors.transaction-quantity-validation',
      (quantity, context: { parent: { totalQuantity: number } }) => {
        return quantity > context.parent.totalQuantity ? false : true;
      },
    ),
  totalQuantity: yup.number().min(1).required('errors.required'),
  nominalValue: yup.number().min(0).required('errors.required'),
  mode: transactionCreationModeSchema,
  type: transactionTypeSchema.test(
    'transaction-type-validation',
    'errors.transaction-type-validation',
    (
      type,
      context: { parent: { mode: WebCompanyTransactionCreationModeEnum } },
    ) => {
      return context.parent.mode ===
        WebCompanyTransactionCreationModeEnum.Create &&
        type === WebCompanyTransactionTypeEnum.Transfer
        ? false
        : true;
    },
  ),
  date: yup.date().required('errors.required'),
});

export const companyTransactionCreationInputSchema = yup.object({
  instrument: instrumentInputSchema,
  transaction: transactionInputSchema,
});

export const companyTransactionUpdateInputSchema = yup.object({
  id: nonEmptyStringSchema,
  quantity: yup
    .number()
    .min(1)
    .required('errors.required')
    .test(
      'transaction-quantity-validation',
      'errors.transaction-quantity-validation',
      (quantity, context: { parent: { totalQuantity: number } }) => {
        return quantity > context.parent.totalQuantity ? false : true;
      },
    ),
  totalQuantity: yup.number().min(1).required('errors.required'),
  nominalValue: yup.number().min(0).required('errors.required'),
  type: yup
    .string()
    .oneOf(
      Object.values(WebCompanyTransactionTypeEnum) as string[],
      'errors.invalid-type',
    )
    .required('errors.required'),
  date: yup.date().required('errors.required'),
});

export const companyValuationUpdateInputSchema = yup.object({
  id: nonEmptyStringSchema,
  fairValue: yup.number().required('errors.required'),
  accruedCoupon: yup.number().required('errors.required'),
});

export const companyFileUploadFormSchema = yup.object().shape({
  type: yup
    .string()
    .required()
    .oneOf(
      Object.values(CompanyDocumentTypeEnum).filter(
        (type) => type !== CompanyDocumentTypeEnum.Logo,
      ),
    ),
  fileName: nonEmptyStringSchema,
  filePath: nonEmptyStringSchema,
  uploadDate: yup.date().required(),
});

export const contactFileUploadFormSchema = yup.object({
  fileName: nonEmptyStringSchema,
  filePath: nonEmptyStringSchema,
  uploadDate: yup.date().required(),
});

export const companyLogoUploadFormSchema = yup.object().shape({
  filePath: nonEmptyStringSchema,
  uploadDate: yup.date().required(),
});

export const companyProfileSchema = yup.object({
  isAutocomplete: yup.boolean(),
  name: nonEmptyStringSchema,
  identifier: nonEmptyStringSchema,
  address: addressRequiredSchema,
  directorBoard: yup.string(),
  codeNace: nonEmptyStringSchema,
  geography: nonEmptyStringSchema,
  holdingName: yup.string(),
  websiteUrl: yup.string(),
  linkedInUrl: yup.string(),
});

export const companyMetricFormSchema = yup.object({
  companyMetricsTypeId: nonEmptyStringSchema,
  value: yup.number().required(),
  year: yup.number().required(),
  quarter: yup
    .mixed<Quarters>()
    .oneOf(Object.values(Quarters))
    .when('periodType', {
      is: (value: DefaultPeriodTypeEnum) =>
        value === DefaultPeriodTypeEnum.Quarterly,
      then: (schema) => schema.required(ValidationErrors.REQUIRED),
    }),
  month: yup
    .number()
    .min(1)
    .max(12)
    .when('periodType', {
      is: (value: DefaultPeriodTypeEnum) =>
        value === DefaultPeriodTypeEnum.Monthly,
      then: (schema) => schema.required(ValidationErrors.REQUIRED),
    }),
  periodType: yup
    .mixed<DefaultPeriodTypeEnum>()
    .oneOf(Object.values(DefaultPeriodTypeEnum))
    .required(ValidationErrors.REQUIRED),
  dataRank: yup
    .mixed<DefaultDataRankEnum>()
    .oneOf(Object.values(DefaultDataRankEnum))
    .required(ValidationErrors.REQUIRED),
  files: yup.array().of(uploadedFileSchema).optional().default([]),
  fileName: yup.string().when('files', {
    is: (files: IFile[]) => files.length > 0,
    then: () => nonEmptyStringSchema,
  }),
  fileDate: yup.date().when('files', {
    is: (files: IFile[]) => files.length > 0,
    then: (schema) => schema.required(ValidationErrors.REQUIRED),
  }),
});
