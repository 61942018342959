import { Chip, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { OnboardingSubscriptionTypeEnum } from 'generated/graphql';
import { getFieldName } from 'technical/get-field-name';
import { Card } from 'ui/card';
import { ErrorLabel } from 'ui/error-label';
import { Option } from 'ui/types';
import { Typo } from 'ui/typo';

import { ShareFormPart } from './share-form-part';
import { ShareForm } from './types';

const getDefaultShareValue = ({
  shareId,
  taxOption,
}: Pick<ShareForm, 'shareId' | 'taxOption'>): ShareForm => ({
  shareId,
  type: OnboardingSubscriptionTypeEnum.Fixed,
  taxOption,
  amount: 0,
  amountMin: 0,
  amountMax: 0,
});

interface OnboardingSubscriptionLpFormPartProps {
  type?: 'existing' | 'new';
  lpName: string;
  contactFullName: string;
  contactEmail: string;
  shares: Option[];
  name?: string;
  taxOption?: boolean;
}
export const OnboardingSubscriptionLpFormPart = ({
  type,
  lpName,
  contactFullName,
  contactEmail,
  taxOption,
  shares,
  name,
}: OnboardingSubscriptionLpFormPartProps) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const { watch, setValue, trigger } = useFormContext();
  const {
    field: { name: sharesName, value: valueShares },
    fieldState: { error },
    formState: { isSubmitted },
  } = useController({
    name: getFieldName('shares', name),
  });

  // exec validation @ change only when form is already submitted once
  const triggerValidation = () => isSubmitted && trigger(sharesName);

  const haveError = !!error?.message;
  const onAdd = (value: ShareForm) => {
    setValue(sharesName, [...watch(sharesName), value]);
    // force validation to remove card warning
    triggerValidation();
  };

  const onRemove = (shareId: string) => {
    const values = watch(sharesName);
    const newValues = values.filter(
      (s: { shareId: string }) => s.shareId !== shareId,
    );
    setValue(sharesName, newValues);
    // force validation to add card warning
    triggerValidation();
  };

  return (
    <Card
      sx={haveError ? { border: 1, borderColor: palette.dangerLight } : null}
    >
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="space-between"
        padding={2}
      >
        <Stack direction="column">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typo size="xl" color={haveError ? 'dangerLight' : undefined}>
              {lpName}
            </Typo>
            {type === 'new' ? (
              <Chip
                color="secondary"
                size="small"
                label={t(
                  'pages.fundManager.onboardingSubscriptionCreate.form.newLp',
                )}
              />
            ) : null}
          </Stack>
          {error?.message ? <ErrorLabel label={error.message} /> : null}
        </Stack>
        <Stack alignItems="end">
          <Typo>{contactFullName}</Typo>
          <Typo size="sm">{contactEmail}</Typo>
        </Stack>
      </Stack>

      {shares.map((share) => {
        const index = valueShares.findIndex(
          (s: { shareId: string }) => s.shareId === share.id,
        );

        return (
          <ShareFormPart
            {...share}
            key={share.id}
            name={`${sharesName}.${index}`}
            index={index > -1 ? index : undefined}
            onAdd={onAdd}
            onRemove={onRemove}
            defaultShareValue={getDefaultShareValue({
              shareId: share.id,
              taxOption: taxOption ?? false,
            })}
          />
        );
      })}
    </Card>
  );
};
