import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CustomAnswers } from 'business/shared/lp-creation-module/services/types';
import { customAnswersSchema } from 'business/shared/lp-creation-module/services/validation';
import { useUpsertLpInvestorProfileMutation } from 'generated/graphql';

export const useInvestorProfileForm = ({
  lpId,
  onCompleted,
}: {
  lpId: string;
  onCompleted: () => void;
}) => {
  const [upsertInvestorProfileMutation, { loading: saving, error }] =
    useUpsertLpInvestorProfileMutation({
      onCompleted,
    });

  const methods = useForm<CustomAnswers>({
    resolver: yupResolver<CustomAnswers>(customAnswersSchema),
  });

  const onSubmit = async ({ customAnswers }: CustomAnswers) =>
    upsertInvestorProfileMutation({
      variables: {
        input: {
          lpId,
          customAnswers: customAnswers ?? [],
        },
      },
      // catch to avoid react-hook-form error
    }).catch(() => undefined);

  return {
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
    saving,
    error,
  };
};
