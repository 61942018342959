import { EditOutlined, AddCircleOutline } from '@mui/icons-material';

import { getEmailTemplateLink } from 'business/fund-manager/settings/template/services/get-email-template-link';
import { GetTemplatesByManagementCompanyQuery } from 'generated/graphql';
import Link from 'ui/link';

import styles from './index.module.scss';

interface AddOrEditEmailProps {
  data: GetTemplatesByManagementCompanyQuery['templatesByManagementCompany'][number];
  routePrefix: string;
}
export const AddOrEditEmail = ({ data, routePrefix }: AddOrEditEmailProps) => {
  const link = getEmailTemplateLink(data, routePrefix);

  return (
    <Link to={link} className={styles.alignCenter}>
      {data.emailTemplate ? <EditOutlined /> : <AddCircleOutline />}
    </Link>
  );
};
