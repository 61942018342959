import { Stack } from '@mui/material';
import { useTranslation } from 'translations/hook';

import { getLpSubscriptionsValues } from 'business/fund-manager/lp/services/get-lp-subscriptions-values';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { SubscriptionCardData } from 'business/shared/components/subscriptions-card/types';
import { CurrencyEnum, useGetLpSubscriptionsQuery } from 'generated/graphql';
import { ChartIcon, FileIcon, RightArrowIcon } from 'ui/icons';
import { QueryStateDisplay } from 'ui/query-state-display';
import { StatNumericAmountCard } from 'ui/stat-numeric-amount-card';
import { StatNumericAmountItemProps } from 'ui/stat-numeric-amount-item';
import { Typo } from 'ui/typo';

interface Props {
  lpId: string;
  currency: CurrencyEnum;
}

export const LpSubscriptions = ({ lpId, currency }: Props) => {
  const { t } = useTranslation();

  const { data, loading, error } = useGetLpSubscriptionsQuery({
    variables: {
      lpId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const {
    totalSubscriptions,
    totalDrawn,
    unfundedSubscriptions,
    count,
    pendingValidationItemsCount,
  }: SubscriptionCardData = getLpSubscriptionsValues(data);

  const totalSubscriptionsDescription = (
    <Stack direction="row" spacing={0.5}>
      <Typo>{t('pages.dashboard.cards.subscriptions.totalSubscriptions')}</Typo>
      <Typo bold>
        {t('pages.dashboard.cards.subscriptions.fundCount', {
          count,
        })}
      </Typo>
    </Stack>
  );

  const items: StatNumericAmountItemProps[] = [
    {
      value: totalSubscriptions,
      currency,
      description: totalSubscriptionsDescription,
      imageSource: <ChartIcon />,
      iconColor: 'secondary',
    },
    {
      value: totalDrawn,
      currency,
      description: t('pages.dashboard.cards.subscriptions.totalDrawn'),
      imageSource: <RightArrowIcon />,
      iconColor: 'primary',
    },
    {
      value: unfundedSubscriptions,
      currency,
      description: t(
        'pages.dashboard.cards.subscriptions.unfundedSubscriptions',
      ),
      imageSource: <FileIcon />,
      iconColor: 'secondary',
    },
  ];
  return (
    <StatNumericAmountCard
      items={items}
      title={t('pages.dashboard.cards.subscriptions.title')}
      path={
        lpId
          ? `${FundManagerRoutes.LpIdSubscription.replace(':lpId', lpId)}`
          : undefined
      }
      titleChipContent={pendingValidationItemsCount}
    />
  );
};
