import { Stack, Tabs } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FinancialFlowNavBarTabEnum } from 'business/fund-manager/lp/services/types';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { useGetLpEscrowAccountCountQuery } from 'generated/graphql';
import { LinkTab } from 'ui/link-tab';
import { QueryStateDisplay } from 'ui/query-state-display';

type FinancialFlowNavBarProps = PropsWithChildren<{
  activeTab: FinancialFlowNavBarTabEnum;
}>;

export const FinancialFlowNavBar = ({
  activeTab,
  children,
}: FinancialFlowNavBarProps) => {
  const { lpId = '' } = useParams();
  const { t } = useTranslation();
  const { loading, error, data } = useGetLpEscrowAccountCountQuery({
    variables: {
      lpId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || data === undefined) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <Stack overflow="hidden" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Tabs value={activeTab}>
          <LinkTab
            to={FundManagerRoutes.LpIdFinancialFlow.replace(':lpId', lpId)}
            label={t('pages.fundManager.lps.financialFlows.nav.overview')}
          />

          {(data.lpEscrowAccounts?.aggregate?.count ?? 0) > 0 ? (
            <LinkTab
              to={FundManagerRoutes.LpIdEscrowAccount.replace(':lpId', lpId)}
              label={t(
                'pages.fundManager.lps.financialFlows.nav.escrowAccount',
              )}
            />
          ) : null}
        </Tabs>
      </Stack>
      <Stack overflow="auto">{children}</Stack>
    </Stack>
  );
};
