import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { LpContactManager } from 'business/shared/components/lp-contact-manager';
import { LegalEntityKYCContactForm } from 'business/shared/lp-creation-module/components/legal-entity-kyc-contact-form';
import { useLpContacts } from 'business/shared/lp-creation-module/services/hooks/use-lp-contacts';
import { Web_Portal_ManagementCompanyType_Enum } from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LegalEntityKyc = ({ lpId }: { lpId: string }) => {
  const { loading, contacts, error, refetch } = useLpContacts({
    lpId,
  });

  const isAdvisor = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.Advisor,
  );

  if (loading || error) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <LpContactManager
      lpId={lpId}
      contacts={contacts}
      onAdded={refetch}
      onDeleted={refetch}
      formComponent={LegalEntityKYCContactForm}
      readOnly={isAdvisor}
    />
  );
};
