import { router } from 'business/router/services';
import { ActionButton, ActionButtonProps } from 'ui/action-button';

import styles from './index.module.scss';

interface Props extends Pick<ActionButtonProps, 'variant' | 'disabled'> {
  label: string;
  to: string;
}

export const LpDetailsButton = ({ label, to, ...rest }: Props) => {
  return (
    <ActionButton
      {...rest}
      onClick={() => router.navigate(to)}
      className={styles.buttonMinSize}
      size="small"
    >
      {label}
    </ActionButton>
  );
};
