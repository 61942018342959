import { Box, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'translations/hook';

import PortfolioCountryGraph from 'business/lp-platform/portfolio/components/portfolio-country-graphs';
import PortfolioSectorGraph from 'business/lp-platform/portfolio/components/portfolio-sector-graph';
import { selectCompanyDisplayedValue } from 'business/lp-platform/portfolio/services/select-company-displayed-values';
import {
  CompanyDisplayedValues,
  PortfolioGraph,
} from 'business/lp-platform/portfolio/services/types';
import { usePalette } from 'business/providers/theme/services/hooks/use-palette';
import { GetCompaniesQuery } from 'generated/graphql';
import ToggleButton from 'ui/toggle-button';
import { ToggleButtonOption } from 'ui/toggle-button/type';

import styles from './index.module.scss';

interface Props {
  data?: GetCompaniesQuery;
}

const PortfolioGraphs = ({ data }: Props) => {
  const { t } = useTranslation();
  const palette = usePalette();

  const [sectorGraph, setSectorGraph] = useState<boolean>(true);
  const [valuationGraph, setValuationGraph] = useState<boolean>(false);
  const [displayedValues, setDisplayedValues] = useState<PortfolioGraph[]>([]);

  useEffect(() => {
    if (data) {
      setDisplayedValues(
        selectCompanyDisplayedValue(
          data,
          CompanyDisplayedValues.TOTAL_INVESTED,
        ),
      );
    }
  }, [data]);

  const acquisitionOrValuationToggle: ToggleButtonOption[] = [
    {
      label: t('pages.portfolio.toggle.acquisition'),
      action: () => {
        setValuationGraph(false);

        if (data) {
          setDisplayedValues(
            selectCompanyDisplayedValue(
              data,
              CompanyDisplayedValues.TOTAL_INVESTED,
            ),
          );
        }
      },
    },
    {
      label: t('pages.portfolio.toggle.valuation'),
      action: () => {
        setValuationGraph(true);

        if (data) {
          setDisplayedValues(
            selectCompanyDisplayedValue(
              data,
              CompanyDisplayedValues.LATEST_VALUATION,
            ),
          );
        }
      },
    },
  ];

  const sectorOrCountryToggle: ToggleButtonOption[] = [
    {
      label: t('pages.portfolio.toggle.sector'),
      action: () => {
        setSectorGraph(true);
      },
    },
    {
      label: t('pages.portfolio.toggle.country'),
      action: () => {
        setSectorGraph(false);
      },
    },
  ];

  const graphFileName =
    t(
      `pages.portfolio.graph.fileName.${
        valuationGraph ? 'valuation' : 'acquisition'
      }`,
    ) +
    t(`pages.portfolio.graph.splitMode.${sectorGraph ? 'sector' : 'location'}`);

  return (
    <Stack data-test-id="portfolio-graphs">
      <Box sx={{ bgcolor: palette.backgroundForm }} className={styles.box}>
        <Stack direction={'row'} className={styles.titleBar}>
          <h6 className={styles.title}>{t('pages.portfolio.graph.title')}</h6>
          <Stack direction={'row'} spacing={2}>
            <ToggleButton options={acquisitionOrValuationToggle} />
            <ToggleButton options={sectorOrCountryToggle} />
          </Stack>
        </Stack>
        <Divider light />
        <div className={styles.graphContainer}>
          {sectorGraph ? (
            <PortfolioSectorGraph
              values={displayedValues}
              fileName={graphFileName}
            />
          ) : (
            <PortfolioCountryGraph
              values={displayedValues}
              fileName={graphFileName}
            />
          )}
        </div>
      </Box>
    </Stack>
  );
};

export default PortfolioGraphs;
