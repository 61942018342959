import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'translations/hook';

import { getFundPendingSubscriptionsListColumnDefinition } from 'business/fund-manager/fund/services/get-fund-pending-subscriptions-column-definition';
import { FundSubscription } from 'business/fund-manager/fund/services/types';
import { useManagementCompanyTypeGuard } from 'business/fund-manager/shared/services/hooks/use-management-company-type-guard';
import { useFund } from 'business/providers/fund-provider/use-fund';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { PendingChipCount } from 'business/shared/components/pending-chip-count';
import {
  GetPendingValidationSubscriptionsQueryResult,
  Web_Portal_ManagementCompanyType_Enum,
  useValidateSubscriptionMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { removeDuplicates } from 'technical/remove-duplicates';
import { AlternativeTable } from 'ui/alternative-table';
import { Typo } from 'ui/typo';

interface SubscriptionsTableToValidateProps {
  onCompleted: () => void;
  fundId: string;
  loading: boolean;
  data: GetPendingValidationSubscriptionsQueryResult['data'];
}
export const SubscriptionsTableToValidate = ({
  fundId,
  onCompleted,
  data,
  loading,
}: SubscriptionsTableToValidateProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { currency } = useFund();

  const isFundAdmin = useManagementCompanyTypeGuard(
    Web_Portal_ManagementCompanyType_Enum.FundAdmin,
  );

  const computedData: FundSubscription[] = data
    ? data.getPendingValidationSubscriptions.map(
        ({ id, date, numericAmount, share, lp, fund }) => {
          return {
            id: id,
            date: date ? formatAsDate(date) : null,
            amount: numericAmount ?? 0,
            fundName: fund.name ?? '',
            fundId: fund.id ?? '',
            lpName: lp.lpName ?? '',
            lpId: lp.id ?? '',
            lpCountry: lp.country ?? null,
            shareType: share.name ?? '',
            currency,
          };
        },
      )
    : [];

  const [validateSubscriptionMutation] = useValidateSubscriptionMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.validateSubscription'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      onCompleted();
    },
    onError: () => {
      enqueueSnackbar(t('errors.validateSubscription'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const handleValidateSubscription = async (
    subscriptionId: string,
    lpId: string,
  ) => {
    await validateSubscriptionMutation({
      variables: {
        input: {
          fundId,
          lpId,
          subscriptionId,
        },
      },
    });
  };

  const customFiltersPendingValidation = computedData
    ? {
        lpNames: removeDuplicates(computedData, 'lpName'),
        shares: removeDuplicates(computedData, 'shareType'),
      }
    : {
        lpNames: [],
        shares: [],
      };

  const headersPendingValidation =
    getFundPendingSubscriptionsListColumnDefinition({
      t,
      handleValidateSubscription,
      customFilters: customFiltersPendingValidation,
      shoudlShowValidationButton: isFundAdmin,
    });

  // Do not show table if no pendingValidation subscriptions
  if (data?.getPendingValidationSubscriptions.length === 0) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row">
        <Typo size="xl" color="primary">
          {t('pages.fundManager.subscription.table.titles.toValidate')}
        </Typo>
        <PendingChipCount count={computedData.length} />
      </Stack>
      <AlternativeTable<FundSubscription>
        fileName="fund-subscriptions-to-validate"
        rowData={computedData}
        columnDefs={headersPendingValidation}
        loading={loading}
        domLayout="autoHeight"
        displaySidebar
        context={{ currency: currency }}
      />
    </Stack>
  );
};
