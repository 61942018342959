import { Box, Stack, Typography } from '@mui/material';

import { usePalette } from 'business/providers/theme/services/hooks/use-palette';

import { toolbarContainerId } from './container-id';
import styles from './index.module.scss';

interface Props {
  toolbarRef: any;
  filterPlaceholders: string[];
}

export const ToolbarContainer = ({ toolbarRef, filterPlaceholders }: Props) => {
  const palette = usePalette();
  return (
    <Stack>
      <Box
        className={styles.toolbarContainer}
        id={toolbarContainerId}
        ref={toolbarRef}
      />

      <Stack className={styles.placeholderContainer}>
        {
          <Typography
            className={styles.placeholder}
            sx={{ color: palette.primary[900] }}
          >
            {filterPlaceholders.join(' | ')}
          </Typography>
        }
      </Stack>
    </Stack>
  );
};
