import { TFunction } from 'translations/hook';

import { DeclineReason } from 'business/shared/lp-creation-module/providers/draft-edition-steps-provider';
import { ClientTypeEnum } from 'generated/graphql';

import { getCreationSteps } from './get-creation-steps';
import { LpCreationStep } from './types';

interface StepDetails {
  steps: LpCreationStep[];
  activeStepIndex: number;
  nextStepPath?: string;
  previousStepPath?: string;
}

interface GetCreationStepDetails {
  currentUrl: string;
  clientType: ClientTypeEnum;
  t: TFunction;
  lpId?: string;
  declineReasons?: DeclineReason[];
  displayLpInvestorProfileStep: boolean;
  displayLpContactsStep: boolean;
}
export const getCreationStepDetails = ({
  currentUrl,
  clientType,
  lpId,
  declineReasons,
  t,
  displayLpInvestorProfileStep,
  displayLpContactsStep,
}: GetCreationStepDetails): StepDetails => {
  const steps = getCreationSteps({
    clientType,
    displayLpInvestorProfileStep,
    displayLpContactsStep,
  }).map(({ label, url, step }) => ({
    url: lpId ? url.replace(':lpId', lpId ?? '') : url,
    label: t(label),
    step,
    declineComment: declineReasons?.find((reason) => step === reason.step)
      ?.comment,
  }));

  const activeStepIndex = steps.findIndex((step) =>
    currentUrl.endsWith(step.url),
  );

  return {
    steps,
    activeStepIndex,
    nextStepPath: steps?.[activeStepIndex + 1]?.url,
    previousStepPath: steps?.[activeStepIndex - 1]?.url,
  };
};
