import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { CompanyProfile } from 'business/fund-manager/portfolio/company/services/types';
import { companyProfileSchema } from 'business/fund-manager/portfolio/company/services/validation';
import { FileFormData } from 'business/fund-manager/shared/services/types';
import {
  CompanyDocumentTypeEnum,
  CompanyProfileQuery,
  useCreateCompanyProfileMutation,
  useUpdateCompanyProfileMutation,
  useWebDocumentDownloadUrlQuery,
} from 'generated/graphql';

import { useCompanyDocumentUpload } from './use-company-document-upload';

const getDefaultValues = (
  company?: NonNullable<CompanyProfileQuery['company']>,
): CompanyProfile => {
  if (!company) {
    return {
      name: '',
      identifier: '',
      codeNace: '',
      geography: '',
      address: {
        streetLine: '',
        zipCode: '',
        city: '',
        country: '',
      },
    };
  }

  return {
    name: company.name,
    identifier: company.identifier,
    directorBoard: company.directorBoard ?? '',
    holdingName: company.holdingName ?? '',
    websiteUrl: company.websiteUrl ?? '',
    linkedInUrl: company.linkedInUrl ?? '',
    codeNace: company.codeNace ?? '',
    geography: company.geography ?? '',
    address: {
      streetLine: company.address.streetLine,
      streetLine2: company.address.streetLine2 ?? undefined,
      city: company.address.city,
      zipCode: company.address.zipCode,
      country: company.address.country,
    },
  };
};

export const useCompanyProfileForm = ({
  companyId,
  company,
  fundId,
  onCompleted,
}: {
  companyId?: string;
  fundId: string;
  onCompleted: (id: string) => void;
  company?: NonNullable<CompanyProfileQuery['company']>;
}) => {
  const [fileToUpload, setFileToUpload] = useState<FileFormData | undefined>();
  const [shouldDeleteExistingLogo, setShouldDeleteExistingLogo] =
    useState<boolean>(false);

  const [saving, setSaving] = useState(false);
  const { control, handleSubmit, setValue } = useForm<CompanyProfile>({
    defaultValues: getDefaultValues(company),
    resolver: yupResolver<CompanyProfile>(companyProfileSchema),
  });

  const existingCompanyLogo = company?.logos?.[0]?.document;

  const { data: logoData } = useWebDocumentDownloadUrlQuery({
    variables: { input: { ids: [existingCompanyLogo?.id ?? ''] } },
    skip: !existingCompanyLogo?.id,
  });

  const existingLogoFound =
    !!logoData?.documentDownloadUrl?.url && !!existingCompanyLogo?.name
      ? {
          url: logoData?.documentDownloadUrl.url,
          name: existingCompanyLogo.name,
        }
      : undefined;

  const { uploadDocument } = useCompanyDocumentUpload();

  const handleCompleted = async (newCompanyId: string) => {
    try {
      if (fileToUpload) {
        await uploadDocument({
          ...fileToUpload,
          companyId: newCompanyId,
          type: CompanyDocumentTypeEnum.Logo,
          fileName: 'Logo',
        });
      }
      setSaving(false);
      onCompleted(newCompanyId);
    } catch {
      setSaving(false);
    }
  };

  const [createProfile, createResult] = useCreateCompanyProfileMutation({
    onCompleted: (data) => handleCompleted(data?.company?.id ?? ''),
    onError: () => setSaving(false),
  });

  const [updateProfile, updateResult] = useUpdateCompanyProfileMutation({
    onCompleted: (data) => handleCompleted(data?.company?.id ?? ''),
    onError: () => setSaving(false),
  });

  const onCreateSubmit = ({ ...input }: CompanyProfile) => {
    setSaving(true);
    return createProfile({
      variables: {
        input: {
          ...input,
          fundId,
        },
      },
    });
  };

  const onUpdateSubmit = (input: CompanyProfile) => {
    setSaving(true);
    return updateProfile({
      variables: {
        input: {
          company: { id: companyId ?? '', ...input },
          companyLogoId: shouldDeleteExistingLogo
            ? undefined
            : existingCompanyLogo?.id,
        },
      },
    });
  };

  const handleExistingLogoDeletion = () => {
    setShouldDeleteExistingLogo(true);
  };

  return {
    ...(companyId
      ? {
          ...updateResult,
          onSubmit: handleSubmit(onUpdateSubmit),
        }
      : {
          ...createResult,
          onSubmit: handleSubmit(onCreateSubmit),
        }),
    saving,
    control,
    onFileUpload: (d: FileFormData) => setFileToUpload(d),
    onExistingLogoDelete: () => handleExistingLogoDeletion(),
    existingLogo: shouldDeleteExistingLogo ? undefined : existingLogoFound,
    setValue,
  };
};
