import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { CompanyInvestementForm } from 'business/fund-manager/portfolio/company/components/company-investment-form';
import { useCompanyInvestmentForm } from 'business/fund-manager/portfolio/company/services/hooks/use-company-investment-form';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import {
  CompanyInvestmentQuery,
  useCompanyInvestmentQuery,
} from 'generated/graphql';
import { ActionButton } from 'ui/action-button';
import { QueryStateDisplay } from 'ui/query-state-display';

interface CompanyInvestmentsEditionContentProps {
  data: NonNullable<CompanyInvestmentQuery['company']>;
}

const CompanyInvestmentsEditionContent = ({
  data,
}: CompanyInvestmentsEditionContentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { onSubmit, loading, error, control } = useCompanyInvestmentForm({
    company: data,
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.generic'), {
        variant: VariantTypeEnum.SUCCESS,
      });
    },
  });

  const action = (
    <ActionButton variant="submit" type="submit" disabled={loading}>
      {t('common.actions.save')}
    </ActionButton>
  );

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <CompanyInvestementForm
          control={control}
          error={error}
          action={action}
        />
        {action}
      </Stack>
    </form>
  );
};

export const CompanyInvestmentsEdition = () => {
  const { companyId = '' } = useParams();
  const { data, loading, error } = useCompanyInvestmentQuery({
    variables: { id: companyId },
  });

  if (loading || error || !data?.company) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return <CompanyInvestmentsEditionContent data={data.company} />;
};
