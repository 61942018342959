import { PropsWithChildren } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'translations/hook';

import { FundManagerRoutes } from 'business/fund-manager/router/routes';
import { useFeatureFlags } from 'business/shared/services/hooks/use-feature-flag';
import { FeatureFlagEnum } from 'business/shared/services/types';
import { ClientTypeEnum, useLpViewQuery } from 'generated/graphql';
import { LinkTabs } from 'ui/link-tabs';
import { QueryStateDisplay } from 'ui/query-state-display';

export const LpDiligenceTabs = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { lpId = '' } = useParams();

  const [displayInvestorProfile, displayKYBAutocompletion] = useFeatureFlags([
    FeatureFlagEnum.DISPLAY_LP_INVESTOR_PROFILE_STEP,
    FeatureFlagEnum.DISPLAY_KYB_AUTOCOMPLETION,
  ]);

  const { data, loading, error } = useLpViewQuery({ variables: { lpId } });

  if (loading || error || !data?.lpView?.[0].client) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  const clientType = data.lpView[0].client;

  const tabs = [
    {
      to: FundManagerRoutes.LpIdDiligenceAmlRiskAssessment.replace(
        ':lpId',
        lpId,
      ),
      label: t('pages.fundManager.lpCreation.steps.aml'),
    },
    ...(clientType === ClientTypeEnum.LegalEntity
      ? [
          {
            to: FundManagerRoutes.LpIdDiligenceLegalEntityKyb.replace(
              ':lpId',
              lpId,
            ),
            label: t('pages.fundManager.lpCreation.steps.KYB'),
          },
        ]
      : []),
    ...(displayInvestorProfile
      ? [
          {
            to: FundManagerRoutes.LpIdDiligenceInvestorProfile.replace(
              ':lpId',
              lpId,
            ),
            label: t('pages.fundManager.lpCreation.steps.investorProfile'),
          },
        ]
      : []),
    {
      to: FundManagerRoutes.LpIdDiligenceKYC.replace(':lpId', lpId),
      label: t('pages.fundManager.lpCreation.steps.KYC'),
    },
    {
      to: FundManagerRoutes.LpIdDiligenceKYCDocumentation.replace(
        ':lpId',
        lpId,
      ),
      label: t('pages.fundManager.lpCreation.steps.KYCDocumentation'),
    },
    ...(clientType === ClientTypeEnum.LegalEntity && displayKYBAutocompletion
      ? [
          {
            to: FundManagerRoutes.LpIdDiligenceKYCKYBAutocompleteUpdate.replace(
              ':lpId',
              lpId,
            ),
            label: t(
              'pages.fundManager.lpCreation.steps.KYCKYBAutocompleteUpdate',
            ),
          },
        ]
      : []),
  ];
  const activeTab = tabs.findIndex((tab) => pathname === tab.to);

  return (
    <LinkTabs activeTab={activeTab} tabs={tabs}>
      {children}
    </LinkTabs>
  );
};
